const systemManager = {
  systemSponsors: {
    gettingSponsors: false,
    acmisSponsors: [],
    emisSponsors: [],
    getSponsorError: {},

    submittingSponsor: false,
    submitSuccess: [],
    submitError: {},
  },
  systemCampuses: {
    gettingCampuses: false,
    acmisCampuses: [],
    emisCampuses: [],
    getCampusError: {},

    submittingCampus: false,
    submitSuccess: [],
    submitError: {},

    gettingAdminRegions: false,
    adminRegions: [],
    gettingAdminDistricts: false,
    adminDistricts: [],
    gettingAdminCounties: false,
    adminCounties: [],
    gettingAdminSubCounties: false,
    adminSubCounties: [],
    gettingAdminParishes: false,
    adminParishes: [],
    getAdminUnitError: {},

    updatingCampus: false,
    updateSuccess: [],
    updateError: {},
  },
  systemProgrammes: {
    gettingAcmisProgrammes: false,
    acmisProgrammes: [],
    emisProgrammes: [],
    getProgrammeError: {},

    gettingCategories: false,
    emisCourseCategories: [],
    getCategoriesError: {},

    submittingProgramme: false,
    submitSuccess: [],
    submitError: {},

    updatingProgramme: false,
    updateSuccess: [],
    updateError: {},
  },
  systemSchemes: {
    gettingSystemSchemas: false,
    getSystemSchemeError: {},
    entrySchemas: [],

    submittingEntrySchemas: false,
    submitEntrySchemaError: {},
    submitEntrySchemaResponse: {},

    showSubmitModal: false,
  },
  systemStudyLevels: {
    gettingStudyLevels: false,
    acmisStudyLevels: [],
    emisStudyLevels: [],
    getStudyLevelError: {},

    submittingStudyLevel: false,
    submitSuccess: [],
    submitError: {},
  },
  emisStudentRecords: {
    gettingStudents: false,
    emisStudents: [],
    getStudentError: {},

    submittingStudents: false,
    submitSuccess: [],
    submitError: {},
  },
};

export default systemManager;
