import update from 'immutability-helper';
import { academicDocumentActions } from '../../actions';
import initialState from '../../initialState';

const academicDocument = (state = initialState.academicDocument, actions) => {
  switch (actions.type) {
    case academicDocumentActions.GET_STUDENT_TESTIMONIAL_REQUEST:
      return {
        ...state,
        getTestimonialError: {},
        gettingTestimonial: true,
      };
    case academicDocumentActions.GET_STUDENT_TESTIMONIAL_SUCCESS: {
      const { studentNumber, data } = actions;
      const { singleTestimonials } = state;

      const findContextIndex = singleTestimonials.findIndex(
        (list) => list.studentNumber === studentNumber
      );

      const contextData = { studentNumber, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          singleTestimonials: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          singleTestimonials: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingTestimonial: false,
        singleTestimonial: data,
      };
    }
    case academicDocumentActions.GET_STUDENT_TESTIMONIAL_ERROR:
      return {
        ...state,
        getTestimonialError: actions.error,
        gettingTestimonial: false,
      };

    case academicDocumentActions.GENERATE_TRANSCRIPT_REQUEST:
      return {
        ...state,
        transcriptError: {},
        generatingTranscript: true,
      };
    case academicDocumentActions.GENERATE_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        generatingTranscript: false,
      };
    case academicDocumentActions.GENERATE_TRANSCRIPT_ERROR:
      return {
        ...state,
        transcriptError: actions.error,
        generatingTranscript: false,
      };

    case academicDocumentActions.GET_GRADUATION_PROGRAMMES_REQUEST:
      return {
        ...state,
        getProgrammeError: {},
        gettingProgrammes: true,
      };
    case academicDocumentActions.GET_GRADUATION_PROGRAMMES_SUCCESS: {
      const { academicYear, data } = actions;
      const { graduationProgrammes } = state;

      const findContextIndex = graduationProgrammes.findIndex(
        (list) => list.academicYear === academicYear
      );

      const contextData = { academicYear, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          graduationProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          graduationProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingProgrammes: false,
      };
    }
    case academicDocumentActions.GET_GRADUATION_PROGRAMMES_ERROR:
      return {
        ...state,
        getProgrammeError: actions.error,
        gettingProgrammes: false,
      };

    case academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_REQUEST:
      return {
        ...state,
        printDocumentError: {},
        printingDocument: true,
      };
    case academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_SUCCESS:
      return {
        ...state,
        printedDocument: actions.data,
        printingDocument: false,
      };
    case academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_ERROR:
      return {
        ...state,
        printDocumentError: actions.error,
        printingDocument: false,
      };

    case academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_REQUEST:
      return {
        ...state,
        downloadDocumentError: {},
        downloadingDocuments: true,
      };
    case academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_SUCCESS:
      return {
        ...state,
        downloadedDocument: actions.data,
        downloadingDocuments: false,
      };
    case academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_ERROR:
      return {
        ...state,
        downloadDocumentError: actions.error,
        downloadingDocuments: false,
      };

    case academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_REQUEST:
      return {
        ...state,
        printSingleDocumentError: {},
        printingSingleDocument: true,
      };
    case academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_SUCCESS:
      return {
        ...state,
        printedSingleDocument: actions.data,
        printingSingleDocument: false,
      };
    case academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_ERROR:
      return {
        ...state,
        printSingleDocumentError: actions.error,
        printingSingleDocument: false,
      };

    case academicDocumentActions.SET_TESTIMONIAL_STUDENT:
      return {
        ...state,
        studentNoOrReg: actions.data,
      };

    case academicDocumentActions.SET_SELECTED_DOCUMENT_PROGRAMME:
      return {
        ...state,
        selectedProgramme: actions.data,
      };

    case academicDocumentActions.SET_SELECTED_DOCUMENT_ACADEMIC_YEAR:
      return {
        ...state,
        selectedAcademicYear: actions.data,
      };

    default:
      return state;
  }
};

export default academicDocument;
