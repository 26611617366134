const studentSupportActions = {
  GENERATE_STUDENT_OTP_REQUEST: 'GENERATE_STUDENT_OTP_REQUEST',
  GENERATE_STUDENT_OTP_SUCCESS: 'GENERATE_STUDENT_OTP_SUCCESS',
  GENERATE_STUDENT_OTP_ERROR: 'GENERATE_STUDENT_OTP_ERROR',

  GET_STUDENT_FOR_OTP_REQUEST: 'GET_STUDENT_FOR_OTP_REQUEST',
  GET_STUDENT_FOR_OTP_SUCCESS: 'GET_STUDENT_FOR_OTP_SUCCESS',
  GET_STUDENT_FOR_OTP_ERROR: 'GET_STUDENT_FOR_OTP_ERROR',

  GET_STAFF_FOR_OTP_REQUEST: 'GET_STAFF_FOR_OTP_REQUEST',
  GET_STAFF_FOR_OTP_SUCCESS: 'GET_STAFF_FOR_OTP_SUCCESS',
  GET_STAFF_FOR_OTP_ERROR: 'GET_STAFF_FOR_OTP_ERROR',

  UPDATING_STUDENT_CONTACTS_REQUEST: 'UPDATING_STUDENT_CONTACTS_REQUEST',
  UPDATE_STUDENT_CONTACTS_SUCCESS: 'UPDATE_STUDENT_CONTACTS_SUCCESS',
  UPDATE_STUDENT_CONTACTS_ERROR: 'UPDATE_STUDENT_CONTACTS_ERROR',

  generateStudentOTP: (data) => ({
    type: studentSupportActions.GENERATE_STUDENT_OTP_REQUEST,
    data,
  }),

  getStudentForOTP: (data) => ({
    type: studentSupportActions.GET_STUDENT_FOR_OTP_REQUEST,
    data,
  }),

  getStaffForOTP: (email) => ({
    type: studentSupportActions.GET_STAFF_FOR_OTP_REQUEST,
    data: { email },
  }),

  updateStudentContacts: (data, id, currentFetchedStudentData) => ({
    type: studentSupportActions.UPDATING_STUDENT_CONTACTS_REQUEST,
    id,
    currentFetchedStudentData,
    data,
  }),
};

export default studentSupportActions;
