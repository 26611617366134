const lecturerCourseActions = {
  GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST:
    'GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST',
  GET_COURSE_ASSIGNMENT_LECTURER_COURSES_SUCCESS:
    'GET_COURSE_ASSIGNMENT_LECTURER_COURSES_SUCCESS',
  GET_COURSE_ASSIGNMENT_LECTURER_COURSES_ERROR:
    'GET_COURSE_ASSIGNMENT_LECTURER_COURSES_ERROR',

  GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST:
    'GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST',
  GET_COURSE_ASSIGNMENT_STUDENTS_SUCCESS:
    'GET_COURSE_ASSIGNMENT_STUDENTS_SUCCESS',
  GET_COURSE_ASSIGNMENT_STUDENTS_ERROR: 'GET_COURSE_ASSIGNMENT_STUDENTS_ERROR',

  SET_SELECTED_COURSE: 'SET_SELECTED_COURSE',

  GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST:
    'GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST',
  GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_SUCCESS:
    'GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_SUCCESS',
  GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_ERROR:
    'GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_ERROR',

  GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_REQUEST:
    'GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_REQUEST',
  GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_SUCCESS:
    'GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_SUCCESS',
  GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_ERROR:
    'GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_ERROR',

  DELETE_BATCH_RESULTS_REQUEST:
    'DELETE_COURSE_ASSIGNMENT_BATCH_RESULTS_REQUEST',
  DELETE_BATCH_RESULTS_SUCCESS:
    'DELETE_COURSE_ASSIGNMENT_BATCH_RESULTS_SUCCESS',
  DELETE_BATCH_RESULTS_ERROR: 'DELETE_COURSE_ASSIGNMENT_BATCH_RESULTS_ERROR',

  UPDATE_RESULTS_REQUEST: 'UPDATE_RESULTS_REQUEST',
  UPDATE_RESULTS_SUCCESS: 'UPDATE_RESULTS_SUCCESS',
  UPDATE_RESULTS_ERROR: 'UPDATE_RESULTS_ERROR',

  GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST:
    'GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST',
  GET_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS:
    'GET_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS',
  GET_LECTURER_RESULT_CATEGORY_ALIAS_ERROR:
    'GET_LECTURER_RESULT_CATEGORY_ALIAS_ERROR',

  SET_SELECTED_RESULT_ALIAS: 'SET_SELECTED_RESULT_ALIAS',

  CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST:
    'CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST',
  CREATE_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS:
    'CREATE_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS',
  CREATE_LECTURER_RESULT_CATEGORY_ALIAS_ERROR:
    'CREATE_LECTURER_RESULT_CATEGORY_ALIAS_ERROR',

  SUBMIT_COMPUTED_RESULTS_REQUEST: 'SUBMIT_COMPUTED_RESULTS_REQUEST',
  SUBMIT_COMPUTED_RESULTS_SUCCESS: 'SUBMIT_COMPUTED_RESULTS_SUCCESS',
  SUBMIT_COMPUTED_RESULTS_ERROR: 'SUBMIT_COMPUTED_RESULTS_ERROR',

  EDIT_RESULT_CATEGORY_ALIAS_REQUEST: 'EDIT_RESULT_CATEGORY_ALIAS_REQUEST',
  EDIT_RESULT_CATEGORY_ALIAS_SUCCESS: 'EDIT_RESULT_CATEGORY_ALIAS_SUCCESS',
  EDIT_RESULT_CATEGORY_ALIAS_ERROR: 'EDIT_RESULT_CATEGORY_ALIAS_ERROR',

  SET_EDIT_RESULT_CATEGORY_ALIAS: 'SET_EDIT_RESULT_CATEGORY_ALIAS',

  SET_SHOW_RESULT_CATEGORY_ALIAS: 'SET_SHOW_RESULT_CATEGORY_ALIAS',

  SET_SHOW_RESULT_COMPUTE_FINAL_MARKS: 'SET_SHOW_RESULT_COMPUTE_FINAL_MARKS',

  SET_SHOW_EDIT_RESULT_MODAL: 'SET_SHOW_EDIT_RESULT_MODAL',

  SET_SHOW_DELETE_RAW_RESULT_MODAL: 'SET_SHOW_DELETE_RAW_RESULT_MODAL',

  SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL:
    'SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL',
  SET_SELECTED_RESULT_CATEGORY_ALIASES: 'SET_SELECTED_RESULT_CATEGORY_ALIASES',

  DELETE_RESULT_CATEGORY_ALIAS_REQUEST: 'DELETE_RESULT_CATEGORY_ALIAS_REQUEST',
  DELETE_RESULT_CATEGORY_ALIAS_SUCCESS: 'DELETE_RESULT_CATEGORY_ALIAS_SUCCESS',
  DELETE_RESULT_CATEGORY_ALIAS_ERROR: 'DELETE_RESULT_CATEGORY_ALIAS_ERROR',

  COMPUTE_STUDENTS_RESULT_REQUEST: 'COMPUTE_STUDENTS_RESULT_REQUEST',
  COMPUTE_STUDENTS_RESULT_SUCCESS: 'COMPUTE_STUDENTS_RESULT_SUCCESS',
  COMPUTE_STUDENTS_RESULT_ERROR: 'COMPUTE_STUDENTS_RESULT_ERROR',

  SHOW_EDIT_COMPUTED_RESULTS_MODAL: 'SHOW_EDIT_COMPUTED_RESULTS_MODAL',
  SHOW_SUBMIT_COMPUTED_RESULTS_MODAL: 'SHOW_SUBMIT_COMPUTED_RESULTS_MODAL',

  GET_LECTURER_ASSIGNMENT_ANALYTICS_REQUEST:
    'GET_LECTURER_ASSIGNMENT_ANALYTICS_REQUEST',
  GET_LECTURER_ASSIGNMENT_ANALYTICS_SUCCESS:
    'GET_LECTURER_ASSIGNMENT_ANALYTICS_SUCCESS',
  GET_LECTURER_ASSIGNMENT_ANALYTICS_ERROR:
    'GET_LECTURER_ASSIGNMENT_ANALYTICS_ERROR',

  getLecturerCourses: (params) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST,
    params,
  }),
  getLecturerAssignmentAnalytics: (context) => ({
    type: lecturerCourseActions.GET_LECTURER_ASSIGNMENT_ANALYTICS_REQUEST,
    context,
  }),
  deleteResultCategoryAlias: (resultCategoryAliasIds, courseAssignmentId) => ({
    type: lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_REQUEST,
    resultCategoryAliasIds,
    courseAssignmentId,
  }),
  setShowDeleteResultCategoryAliasModal: (show) => ({
    type: lecturerCourseActions.SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL,
    data: show,
  }),
  setResultCategoryAliases: (data) => ({
    type: lecturerCourseActions.SET_SELECTED_RESULT_CATEGORY_ALIASES,
    data,
  }),
  createLecturerResultCategoryAlias: (data, courseAssignmentId) => ({
    type: lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST,
    data,
    courseAssignmentId,
  }),
  editResultCategoryAlias: (
    data,
    resultCategoryAliasId,
    courseAssignmentId
  ) => ({
    type: lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_REQUEST,
    data,
    courseAssignmentId,
    resultCategoryAliasId,
  }),
  setEditResultCategoryAlias: (data) => ({
    type: lecturerCourseActions.SET_EDIT_RESULT_CATEGORY_ALIAS,
    data,
  }),
  showEditComputedResultsModal: (show) => ({
    type: lecturerCourseActions.SHOW_EDIT_COMPUTED_RESULTS_MODAL,
    data: show,
  }),
  showSubmitComputedResultsModal: (show) => ({
    type: lecturerCourseActions.SHOW_SUBMIT_COMPUTED_RESULTS_MODAL,
    data: show,
  }),
  submitComputedResults: (data, params) => ({
    type: lecturerCourseActions.SUBMIT_COMPUTED_RESULTS_REQUEST,
    data,
    params,
  }),
  setShowAliasModal: (payload = false, aliasData = {}) => ({
    type: lecturerCourseActions.SET_SHOW_RESULT_CATEGORY_ALIAS,
    payload,
    aliasData,
  }),

  setComputeModal: (payload = false) => ({
    type: lecturerCourseActions.SET_SHOW_RESULT_COMPUTE_FINAL_MARKS,
    payload,
  }),

  showEditResultModal: (payload = false, dataToEdit = {}) => ({
    type: lecturerCourseActions.SET_SHOW_EDIT_RESULT_MODAL,
    payload,
    dataToEdit,
  }),

  getLecturerResultCategoryAliases: (courseAssignmentId) => ({
    type: lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST,
    courseAssignmentId,
  }),

  computeResults: (courseAssignmentId) => ({
    type: lecturerCourseActions.COMPUTE_STUDENTS_RESULT_REQUEST,
    courseAssignmentId,
  }),

  getLecturerCourseRawResults: (courseAssignmentId, params) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST,
    courseAssignmentId,
    params,
  }),

  getComputedResults: (courseAssignmentId, params) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_COMPUTED_RESULTS_REQUEST,
    courseAssignmentId,
    params,
  }),

  setSelectedResultAlias: (resultAlias) => ({
    type: lecturerCourseActions.SET_SELECTED_RESULT_ALIAS,
    resultAlias,
  }),

  getStudentsRegisteredForCourse: (params, courseAssignmentId) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST,
    params,
    courseAssignmentId,
  }),

  editResults: (data, rawResultCategory, assignmentContext) => ({
    type: lecturerCourseActions.UPDATE_RESULTS_REQUEST,
    data,
    rawResultCategory,
    assignmentContext,
  }),

  deleteBatchResults: (courseAssignmentId, data, resultCategory) => ({
    type: lecturerCourseActions.DELETE_BATCH_RESULTS_REQUEST,
    courseAssignmentId,
    data,
    resultCategory,
  }),

  setSelectedCourse: (course) => ({
    type: lecturerCourseActions.SET_SELECTED_COURSE,
    course,
  }),
  setShowDeleteRawResultsModal: (payload) => ({
    type: lecturerCourseActions.SET_SHOW_DELETE_RAW_RESULT_MODAL,
    payload,
  }),
};

export default lecturerCourseActions;
