import { gradingActions } from '../../actions';
import initialState from '../../initialState';

function grading(state = initialState.grading, actions) {
  switch (actions.type) {
    case gradingActions.GET_GRADING_REQUEST:
      return {
        ...state,
        gradingError: {},
        grading: {},
        loading: true,
      };
    case gradingActions.GET_GRADING_SUCCESS:
      return {
        ...state,
        gradings: actions.data,
        gradingError: {},
        loading: false,
      };
    case gradingActions.GET_GRADING_ERROR:
      return {
        ...state,
        gradingError: actions.error,
        loading: false,
      };
    case gradingActions.GET_SINGLE_GRADING_REQUEST:
      return {
        ...state,
        gradingError: {},
        grading: {},
        fetching: true,
      };
    case gradingActions.GET_SINGLE_GRADING_SUCCESS:
      return {
        ...state,
        gradings: actions.data,
        gradingError: {},
        fetching: false,
      };
    case gradingActions.GET_SINGLE_GRADING_ERROR:
      return {
        ...state,
        gradingError: actions.error,
        fetching: false,
      };
    case gradingActions.CREATE_GRADING_REQUEST:
      return {
        ...state,
        creating: true,
        gradingError: {},
        grading: {},
      };
    case gradingActions.CREATE_GRADING_SUCCESS:
      return {
        ...state,
        creating: false,
        grading: actions.data,
      };
    case gradingActions.CREATE_GRADING_ERROR:
      return {
        ...state,
        creating: false,
        gradingError: actions.error,
      };
    case gradingActions.UPDATE_GRADING_REQUEST:
      return {
        ...state,
        updating: true,
        gradingError: {},
        grading: {},
      };
    case gradingActions.UPDATE_GRADING_SUCCESS:
      return {
        ...state,
        updating: false,
        grading: actions.data,
      };
    case gradingActions.UPDATE_GRADING_ERROR:
      return {
        ...state,
        updating: false,
        gradingError: actions.error,
      };

    case gradingActions.SET_ACTIVE_GRADING_TAB:
      return {
        ...state,
        activeTab: actions.payload,
      };
    default:
      return state;
  }
}

export default grading;
