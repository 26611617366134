import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { filter, isObject, map } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React from 'react';

function DropdownOption({
  title,
  icon,
  titleIcon,
  menuItems,
  loading,
  loadingText,
  ...props
}) {
  const items = map(
    filter(menuItems, (menuItem) => isObject(menuItem) && !menuItem.hidden),
    (menuItem) => ({
      ...menuItem,
      key: menuItem.title,
      label: menuItem.title,
      className: 'text-sm fw-bold text-capitalize border-bottom',
    })
  );

  return (
    <Dropdown.Button
      icon={titleIcon || <DownOutlined />}
      trigger={['click']}
      menu={{ items }}
      placement="bottomRight"
      type="primary"
      arrow
      size="small"
      loading={loading}
      {...props}
    >
      <span className="text-sm text-uppercase fw-bold">
        {loading ? loadingText : title}
      </span>
    </Dropdown.Button>
  );
}

DropdownOption.defaultProps = {
  title: 'Actions',
  loadingText: 'Please Wait...',
  loading: false,
  icon: null,
  titleIcon: null,
};

DropdownOption.propTypes = {
  menuItems: PropTypes.oneOfType([any]).isRequired,
  icon: PropTypes.oneOfType([any]),
  titleIcon: PropTypes.oneOfType([any]),
  title: PropTypes.string,
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
};

export default DropdownOption;
