import update from 'immutability-helper';
import { feesStructureActions } from '../../actions';
import initialState from '../../initialState';

const feesStructure = (state = initialState.feesStructure, actions) => {
  switch (actions.type) {
    case feesStructureActions.GET_FEES_STRUCTURE_REQUEST:
      return {
        ...state,
        gettingFeesStructure: true,
        feesStructureError: {},
      };
    case feesStructureActions.GET_FEES_STRUCTURE_SUCCESS: {
      const { data, studentProgrammeId } = actions;

      const { feesStructures } = state;

      let newState = state;

      const studentData = {
        studentProgrammeId,
        feesStructure: data,
      };

      const feesStructureIndex = feesStructures.findIndex(
        (structure) => structure.studentProgrammeId === studentProgrammeId
      );

      if (feesStructureIndex === -1) {
        newState = update(newState, {
          feesStructures: { $push: [studentData] },
        });
      } else {
        newState = update(newState, {
          feesStructures: {
            [feesStructureIndex]: { $set: studentData },
          },
        });
      }

      return { ...newState, gettingFeesStructure: false };
    }
    case feesStructureActions.GET_FEES_STRUCTURE_ERROR:
      return {
        ...state,
        gettingFeesStructure: false,
        feesStructureError: actions.data,
      };
    default:
      return state;
  }
};

export default feesStructure;
