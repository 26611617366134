const resultNodeActions = {
  GET_ASSIGNED_COURSES_REQUEST: 'GET_ASSIGNED_COURSES_REQUEST',
  GET_ASSIGNED_COURSES_SUCCESS: 'GET_ASSIGNED_COURSES_SUCCESS',
  GET_ASSIGNED_COURSES_ERROR: 'GET_ASSIGNED_COURSES_ERROR',

  GET_COURSE_NODES_REQUEST: 'GET_COURSE_NODES_REQUEST',
  GET_COURSE_NODES_SUCCESS: 'GET_COURSE_NODES_SUCCESS',
  GET_COURSE_NODES_ERROR: 'GET_COURSE_NODES_ERROR',

  CREATE_RESULT_NODES_REQUEST: 'CREATE_RESULT_NODES_REQUEST',
  CREATE_RESULT_NODES_SUCCESS: 'CREATE_RESULT_NODES_SUCCESS',
  CREATE_RESULT_NODES_ERROR: 'CREATE_RESULT_NODES_ERROR',

  GET_ASSIGNED_LECTURERS_REQUEST: 'GET_ASSIGNED_LECTURERS_REQUEST',
  GET_ASSIGNED_LECTURERS_SUCCESS: 'GET_ASSIGNED_LECTURERS_SUCCESS',
  GET_ASSIGNED_LECTURERS_ERROR: 'GET_ASSIGNED_LECTURERS_ERROR',

  DOWNLOAD_NODES_RESULTS_REQUEST: 'DOWNLOAD_NODES_RESULTS_REQUEST',
  DOWNLOAD_NODES_RESULTS_SUCCESS: 'DOWNLOAD_NODES_RESULTS_SUCCESS',
  DOWNLOAD_NODES_RESULTS_ERROR: 'DOWNLOAD_NODES_RESULTS_ERROR',

  DOWNLOAD_RESULTS_TEMPLATE_REQUEST: 'DOWNLOAD_RESULTS_TEMPLATE_REQUEST',
  DOWNLOAD_RESULTS_TEMPLATE_SUCCESS: 'DOWNLOAD_RESULTS_TEMPLATE_SUCCESS',
  DOWNLOAD_RESULTS_TEMPLATE_ERROR: 'DOWNLOAD_RESULTS_TEMPLATE_ERROR',

  DOWNLOAD_GPA_TEMPLATE_REQUEST: 'DOWNLOAD_GPA_TEMPLATE_REQUEST',
  DOWNLOAD_GPA_TEMPLATE_SUCCESS: 'DOWNLOAD_GPA_TEMPLATE_SUCCESS',
  DOWNLOAD_GPA_TEMPLATE_ERROR: 'DOWNLOAD_GPA_TEMPLATE_ERROR',

  UPLOAD_NODES_RESULTS_REQUEST: 'UPLOAD_NODES_RESULTS_REQUEST',
  UPLOAD_NODES_RESULTS_SUCCESS: 'UPLOAD_NODES_RESULTS_SUCCESS',
  UPLOAD_NODES_RESULTS_ERROR: 'UPLOAD_NODES_RESULTS_ERROR',

  GET_NODE_RESULTS_REQUEST: 'GET_NODE_RESULTS_REQUEST',
  GET_NODE_RESULTS_SUCCESS: 'GET_NODE_RESULTS_SUCCESS',
  GET_NODE_RESULTS_ERROR: 'GET_NODE_RESULTS_ERROR',

  GET_NODE_DETAILS_REQUEST: 'GET_NODE_DETAILS_REQUEST',
  GET_NODE_DETAILS_SUCCESS: 'GET_NODE_DETAILS_SUCCESS',
  GET_NODE_DETAILS_ERROR: 'GET_NODE_DETAILS_ERROR',

  GET_CONTEXT_RESULTS_REQUEST: 'GET_CONTEXT_RESULTS_REQUEST',
  GET_CONTEXT_RESULTS_SUCCESS: 'GET_CONTEXT_RESULTS_SUCCESS',
  GET_CONTEXT_RESULTS_ERROR: 'GET_CONTEXT_RESULTS_ERROR',

  UPLOAD_RESULTS_TEMPLATE_REQUEST: 'UPLOAD_RESULTS_TEMPLATE_REQUEST',
  UPLOAD_RESULTS_TEMPLATE_SUCCESS: 'UPLOAD_RESULTS_TEMPLATE_SUCCESS',
  UPLOAD_RESULTS_TEMPLATE_ERROR: 'UPLOAD_RESULTS_TEMPLATE_ERROR',

  UPLOAD_GPA_TEMPLATE_REQUEST: 'UPLOAD_GPA_TEMPLATE_REQUEST',
  UPLOAD_GPA_TEMPLATE_SUCCESS: 'UPLOAD_GPA_TEMPLATE_SUCCESS',
  UPLOAD_GPA_TEMPLATE_ERROR: 'UPLOAD_GPA_TEMPLATE_ERROR',

  DELETE_SINGLE_RESULT_REQUEST: 'DELETE_SINGLE_RESULT_REQUEST',
  DELETE_SINGLE_RESULT_SUCCESS: 'DELETE_SINGLE_RESULT_SUCCESS',
  DELETE_SINGLE_RESULT_ERROR: 'DELETE_SINGLE_RESULT_ERROR',

  UPDATE_SINGLE_RESULT_REQUEST: 'UPDATE_SINGLE_RESULT_REQUEST',
  UPDATE_SINGLE_RESULT_SUCCESS: 'UPDATE_SINGLE_RESULT_SUCCESS',
  UPDATE_SINGLE_RESULT_ERROR: 'UPDATE_SINGLE_RESULT_ERROR',

  EVALUATE_COURSE_RESULT_REMARKS_REQUEST:
    'EVALUATE_COURSE_RESULT_REMARKS_REQUEST',
  EVALUATE_COURSE_RESULT_REMARKS_SUCCESS:
    'EVALUATE_COURSE_RESULT_REMARKS_SUCCESS',
  EVALUATE_COURSE_RESULT_REMARKS_ERROR: 'VALUATE_COURSE_RESULT_REMARKS_ERROR',

  UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_REQUEST:
    'UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_REQUEST',
  UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_SUCCESS:
    'UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_SUCCESS',
  UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_ERROR:
    'UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_ERROR',

  SUBMIT_NODE_RESULTS_REQUEST: 'SUBMIT_NODE_RESULTS_REQUEST',
  SUBMIT_NODE_RESULTS_SUCCESS: 'SUBMIT_NODE_RESULTS_SUCCESS',
  SUBMIT_NODE_RESULTS_ERROR: 'SUBMIT_NODE_RESULTS_ERROR',

  GET_RESULT_PROGRAMME_COURSES_REQUEST: 'GET_RESULT_PROGRAMME_COURSES_REQUEST',
  GET_RESULT_PROGRAMME_COURSES_SUCCESS: 'GET_RESULT_PROGRAMME_COURSES_SUCCESS',
  GET_RESULT_PROGRAMME_COURSES_ERROR: 'GET_RESULT_PROGRAMME_COURSES_ERROR',

  GET_COURSE_RESULTS_REQUEST: 'GET_COURSE_RESULTS_REQUEST',
  GET_COURSE_RESULTS_SUCCESS: 'GET_COURSE_RESULTS_SUCCESS',
  GET_COURSE_RESULTS_ERROR: 'GET_COURSE_RESULTS_ERROR',

  PUBLISH_COURSE_RESULT_REQUEST: 'PUBLISH_COURSE_RESULT_REQUEST',
  PUBLISH_COURSE_RESULT_SUCCESS: 'PUBLISH_COURSE_RESULT_SUCCESS',
  PUBLISH_COURSE_RESULT_ERROR: 'PUBLISH_COURSE_RESULT_ERROR',

  SET_SELECTED_NODE: 'SET_SELECTED_NODE',
  SET_NODES_CONTEXT: 'SET_NODES_CONTEXT',
  SET_RESULTS_CONTEXT: 'SET_RESULTS_CONTEXT',
  SET_PUBLISH_RESULTS_CONTEXT: 'SET_PUBLISH_RESULTS_CONTEXT',
  SET_DATA_TO_EDIT: 'SET_DATA_TO_EDIT',
  SET_CONTEXT_PROGRAMME: 'SET_CONTEXT_PROGRAMME',

  getAssignedCourses: (params) => ({
    type: resultNodeActions.GET_ASSIGNED_COURSES_REQUEST,
    params,
  }),

  getCourseNodes: (params) => ({
    type: resultNodeActions.GET_COURSE_NODES_REQUEST,
    params,
  }),

  getNodesDetails: (params) => ({
    type: resultNodeActions.GET_NODE_DETAILS_REQUEST,
    params,
  }),

  createResultNode: (courseId, data) => ({
    type: resultNodeActions.CREATE_RESULT_NODES_REQUEST,
    data,
    courseId,
  }),

  getAssignedLecturers: (params) => ({
    type: resultNodeActions.GET_ASSIGNED_LECTURERS_REQUEST,
    params,
  }),

  downloadNodesResultsTemplate: (id) => ({
    type: resultNodeActions.DOWNLOAD_NODES_RESULTS_REQUEST,
    id,
  }),

  downloadResultsTemplate: () => ({
    type: resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_REQUEST,
  }),

  downloadGPATemplate: () => ({
    type: resultNodeActions.DOWNLOAD_GPA_TEMPLATE_REQUEST,
  }),

  uploadNodesResultsTemplate: (nodeId, data) => ({
    type: resultNodeActions.UPLOAD_NODES_RESULTS_REQUEST,
    data,
    nodeId,
  }),

  uploadResultsTemplate: (token, operation, data) => ({
    type: resultNodeActions.UPLOAD_RESULTS_TEMPLATE_REQUEST,
    token,
    operation,
    data,
  }),

  uploadGPATemplate: (token, operation, data) => ({
    type: resultNodeActions.UPLOAD_GPA_TEMPLATE_REQUEST,
    token,
    operation,
    data,
  }),

  deleteSingleResult: (id, params, data) => ({
    type: resultNodeActions.DELETE_SINGLE_RESULT_REQUEST,
    id,
    params,
    data,
  }),

  editSingleResult: (id, data, params) => ({
    type: resultNodeActions.UPDATE_SINGLE_RESULT_REQUEST,
    id,
    data,
    params,
  }),

  evaluateResultRemark: (context, params) => ({
    type: resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_REQUEST,
    context,
    params,
  }),

  updateResultAcademicYear: (data, studentNumber) => ({
    type: resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_REQUEST,
    data,
    studentNumber,
  }),

  setSelectedNode: (node) => ({
    type: resultNodeActions.SET_SELECTED_NODE,
    node,
  }),

  getNodeResults: (params) => ({
    type: resultNodeActions.GET_NODE_RESULTS_REQUEST,
    params,
  }),

  setNodesContext: (context) => ({
    type: resultNodeActions.SET_NODES_CONTEXT,
    context,
  }),

  setResultsContext: (context) => ({
    type: resultNodeActions.SET_RESULTS_CONTEXT,
    context,
  }),

  setPublishResultsContext: (context) => ({
    type: resultNodeActions.SET_PUBLISH_RESULTS_CONTEXT,
    context,
  }),

  setDataToEdit: (data) => ({
    type: resultNodeActions.SET_DATA_TO_EDIT,
    data,
  }),

  submitNodeResults: (nodeId) => ({
    type: resultNodeActions.SUBMIT_NODE_RESULTS_REQUEST,
    nodeId,
  }),

  getResultProgrammeCourses: (data) => ({
    type: resultNodeActions.GET_RESULT_PROGRAMME_COURSES_REQUEST,
    data,
  }),

  getCourseResults: (data) => ({
    type: resultNodeActions.GET_COURSE_RESULTS_REQUEST,
    data,
  }),

  publishCourseResults: (data) => ({
    type: resultNodeActions.PUBLISH_COURSE_RESULT_REQUEST,
    data,
  }),

  getContextResults: (params) => ({
    type: resultNodeActions.GET_CONTEXT_RESULTS_REQUEST,
    params,
  }),

  setSelectedProgramme: (data) => ({
    type: resultNodeActions.SET_CONTEXT_PROGRAMME,
    data,
  }),
};

export default resultNodeActions;
