const academicFeesPolicyActions = {
  GET_ACADEMIC_FEES_POLICIES_REQUEST: 'GET_ACADEMIC_FEES_POLICIES_REQUEST',
  GET_ACADEMIC_FEES_POLICIES_SUCCESS: 'GET_ACADEMIC_FEES_POLICIES_SUCCESS',
  GET_ACADEMIC_FEES_POLICIES_ERROR: 'GET_ACADEMIC_FEES_POLICIES_ERROR',

  CREATE_ACADEMIC_FEES_POLICY_REQUEST: 'CREATE_ACADEMIC_FEES_POLICY_REQUEST',
  CREATE_ACADEMIC_FEES_POLICY_SUCCESS: 'CREATE_ACADEMIC_FEES_POLICY_SUCCESS',
  CREATE_ACADEMIC_FEES_POLICY_ERROR: 'CREATE_ACADEMIC_FEES_POLICY_ERROR',

  DELETE_ACADEMIC_FEES_POLICY_REQUEST: 'DELETE_ACADEMIC_FEES_POLICY_REQUEST',
  DELETE_ACADEMIC_FEES_POLICY_SUCCESS: 'DELETE_ACADEMIC_FEES_POLICY_SUCCESS',
  DELETE_ACADEMIC_FEES_POLICY_ERROR: 'DELETE_ACADEMIC_FEES_POLICY_ERROR',

  UPDATE_ACADEMIC_FEES_POLICY_REQUEST: 'UPDATE_ACADEMIC_FEES_POLICY_REQUEST',
  UPDATE_ACADEMIC_FEES_POLICY_SUCCESS: 'UPDATE_ACADEMIC_FEES_POLICY_SUCCESS',
  UPDATE_ACADEMIC_FEES_POLICY_ERROR: 'UPDATE_ACADEMIC_FEES_POLICY_ERROR',

  SET_ACADEMIC_FEES_POLICY_TO_EDIT: 'SET_ACADEMIC_FEES_POLICY_TO_EDIT',
  SET_SELECTED_POLICY: 'SET_SELECTED_POLICY',

  getAcademicFeesPolicies: () => ({
    type: academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_REQUEST,
  }),

  createAcademicFeesPolicy: (data) => ({
    type: academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_REQUEST,
    data,
  }),

  deleteAcademicFeesPolicy: (policyId) => ({
    type: academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_REQUEST,
    policyId,
  }),

  updateAcademicFeesPolicy: (policyId, data) => ({
    type: academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_REQUEST,
    policyId,
    data,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: academicFeesPolicyActions.SET_ACADEMIC_FEES_POLICY_TO_EDIT,
    policyToEdit,
  }),

  setSelectedPolicy: (selectedPolicy) => ({
    type: academicFeesPolicyActions.SET_SELECTED_POLICY,
    selectedPolicy,
  }),
};

export default academicFeesPolicyActions;
