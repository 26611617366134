import { securityProfileActions } from '../../actions';
import initialState from '../../initialState';

function securityProfile(state = initialState.securityProfile, actions) {
  switch (actions.type) {
    case securityProfileActions.GET_SECURITY_PROFILE_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case securityProfileActions.GET_SECURITY_PROFILE_SUCCESS:
      return {
        ...state,
        securityProfiles: actions.data.securityProfiles,
        error: {},
        loading: false,
      };
    case securityProfileActions.GET_SECURITY_PROFILE_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };
    case securityProfileActions.CREATE_SECURITY_PROFILE_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
        created: {},
      };
    case securityProfileActions.CREATE_SECURITY_PROFILE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case securityProfileActions.CREATE_SECURITY_PROFILE_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case securityProfileActions.UPDATE_SECURITY_PROFILE_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updatedSecurityProfile: {},
      };
    case securityProfileActions.UPDATE_SECURITY_PROFILE_SUCCESS:
      return {
        ...state,
        updating: false,
        updatedSecurityProfile: actions.data,
      };
    case securityProfileActions.UPDATE_SECURITY_PROFILE_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    default:
      return state;
  }
}

export default securityProfile;
