import { Image } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import DefaultLogo from '../../assets/images/default.png';
import DataSpinner from './DataSpinner';

function InstitutionHeader({ setSelectedMenu }) {
  const { institutionStructure, gettingInstitutionStructure } = useSelector(
    (state) => state.institutionStructure
  );

  return (
    <>
      {gettingInstitutionStructure ? (
        <DataSpinner text="Please Wait..." />
      ) : (
        <>
          <Image
            src={`${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`}
            fallback={DefaultLogo}
            alt="University Logo"
            height={100}
            width={100}
            className="text-center mx-auto mb-2"
            onClick={setSelectedMenu}
          />
          <div className="block align-centre mt-2 fw-bold fs-2 h1 text-uppercase">
            {institutionStructure?.institution_name ||
              process.env.REACT_APP_NAME}
          </div>
        </>
      )}
    </>
  );
}

InstitutionHeader.defaultProps = {
  setSelectedMenu: null,
};

InstitutionHeader.propTypes = {
  setSelectedMenu: PropTypes.func,
};

export default InstitutionHeader;
