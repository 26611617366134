import { feesBillingPolicyActions } from '../../actions';
import initialState from '../../initialState';

const feesBillingPolicy = (state = initialState.feesBillingPolicy, actions) => {
  switch (actions.type) {
    case feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_SUCCESS:
      return {
        ...state,
        feesBillingPolicies: actions.data.data,
        loading: false,
      };
    case feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        feesBillingPolicy: actions.data,
        creating: false,
      };
    case feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_SUCCESS:
      return {
        ...state,
        moduleFeesPolicies: actions.data.data,
        loading: false,
      };
    case feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        moduleFeesPolicy: actions.data,
        creating: false,
      };
    case feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case feesBillingPolicyActions.UPDATE_FEES_BILLING_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case feesBillingPolicyActions.UPDATE_FEES_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case feesBillingPolicyActions.UPDATE_FEES_BILLING_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_REQUEST:
      return {
        ...state,
        approveError: {},
        approving: true,
      };
    case feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        approveResponse: actions.data,
        approving: false,
      };
    case feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    case feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_REQUEST:
      return {
        ...state,
        activateError: {},
        activating: true,
      };
    case feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        activateResponse: actions.data,
        activating: false,
      };
    case feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_ERROR:
      return {
        ...state,
        activateError: actions.error,
        activating: false,
      };

    case feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };
    case feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case feesBillingPolicyActions.SET_FEES_BILLING_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.feesBillingPolicy,
      };
    case feesBillingPolicyActions.ADDING_FEES_BILLING_POLICY_CONTEXT:
      return {
        ...state,
        addingContext: actions.payload,
      };
    default:
      return state;
  }
};

export default feesBillingPolicy;
