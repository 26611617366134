import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { applicationFeesPolicyActions, tabActions } from '../../actions';

function* getApplicationFeesPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/application-fees-policies',
      method: 'GET',
    });
    yield put({
      type: applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createApplicationFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/application-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateApplicationFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/application-fees-policies/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: tabActions.CURRENT_APPLICATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteApplicationFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/application-fees-policies/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetApplicationFeesPolicies() {
  yield takeLatest(
    applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_REQUEST,
    getApplicationFeesPolicies
  );
}

function* watchCreateApplicationFeesPolicies() {
  yield takeLatest(
    applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_REQUEST,
    createApplicationFeesPolicies
  );
}

function* watchUpdateApplicationFeesPolicies() {
  yield takeLatest(
    applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_REQUEST,
    updateApplicationFeesPolicies
  );
}

function* watchDeleteApplicationFeesPolicies() {
  yield takeLatest(
    applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_REQUEST,
    deleteApplicationFeesPolicies
  );
}

export default [
  fork(watchGetApplicationFeesPolicies),
  fork(watchCreateApplicationFeesPolicies),
  fork(watchUpdateApplicationFeesPolicies),
  fork(watchDeleteApplicationFeesPolicies),
];
