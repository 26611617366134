import { universalInvoiceActions } from '../../actions';
import initialState from '../../initialState';

function universalInvoice(state = initialState.universalInvoice, actions) {
  switch (actions.type) {
    case universalInvoiceActions.GET_UNIVERSAL_INVOICES_REQUEST:
      return {
        ...state,
        getInvoiceError: {},
        gettingInvoices: true,
      };
    case universalInvoiceActions.GET_UNIVERSAL_INVOICES_SUCCESS: {
      let { universalInvoices } = state;
      const { context, data } = actions;
      const findIndex = universalInvoices.findIndex(
        (contextInvoice) =>
          contextInvoice.start_date === context.start_date &&
          contextInvoice.end_date === context.end_date
      );

      if (findIndex === -1) {
        universalInvoices = [...universalInvoices, { ...context, data }];
      } else {
        universalInvoices[findIndex] = { ...context, data };
      }

      return {
        ...state,
        universalInvoices,
        gettingInvoices: false,
      };
    }
    case universalInvoiceActions.GET_UNIVERSAL_INVOICES_ERROR:
      return {
        ...state,
        getInvoiceError: actions.error,
        gettingInvoices: false,
      };

    case universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_REQUEST:
      return {
        ...state,
        getInvoiceError: {},
        gettingInvoices: true,
        staffGeneratedInvoiceContext: actions.data,
      };
    case universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_SUCCESS: {
      let { staffGeneratedInvoices, staffVoidedInvoices } = state;
      const { context, data } = actions;

      if (context.voided) {
        staffVoidedInvoices = data;
      } else {
        const findIndex = staffGeneratedInvoices.findIndex(
          (contextInvoice) =>
            contextInvoice.start_date === context.start_date &&
            contextInvoice.end_date === context.end_date
        );

        if (findIndex === -1) {
          staffGeneratedInvoices = [
            ...staffGeneratedInvoices,
            { ...context, data },
          ];
        } else {
          staffGeneratedInvoices[findIndex] = { ...context, data };
        }
      }

      return {
        ...state,
        staffGeneratedInvoices,
        staffVoidedInvoices,
        gettingInvoices: false,
      };
    }
    case universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_ERROR:
      return {
        ...state,
        getInvoiceError: actions.error,
        gettingInvoices: false,
      };

    case universalInvoiceActions.VOID_OR_APPROVE_STAFF_INVOICE_REQUEST:
      return {
        ...state,
        voidingOrApproving: true,
        voidOrApproveError: {},
      };
    case universalInvoiceActions.VOID_OR_APPROVE_STAFF_INVOICE_SUCCESS:
      return {
        ...state,
        voidingOrApproving: false,
        voidOrApproveSuccess: actions.data,
      };
    case universalInvoiceActions.VOID_OR_APPROVE_STAFF_INVOICE_ERROR:
      return {
        ...state,
        voidingOrApproving: false,
        voidOrApproveError: actions.error,
      };

    case universalInvoiceActions.CREATE_UNIVERSAL_INVOICE_REQUEST:
      return {
        ...state,
        creatingInvoice: true,
        createInvoiceError: {},
      };
    case universalInvoiceActions.CREATE_UNIVERSAL_INVOICE_SUCCESS:
      return {
        ...state,
        creatingInvoice: false,
        createdInvoice: actions.data,
      };
    case universalInvoiceActions.CREATE_UNIVERSAL_INVOICE_ERROR:
      return {
        ...state,
        creatingInvoice: false,
        createInvoiceError: actions.error,
      };

    case universalInvoiceActions.UPDATE_UNIVERSAL_INVOICE_REQUEST:
      return {
        ...state,
        updatingInvoice: true,
        updateInvoiceError: {},
        updatedInvoice: {},
      };
    case universalInvoiceActions.UPDATE_UNIVERSAL_INVOICE_SUCCESS:
      return {
        ...state,
        updatingInvoice: false,
        updatedInvoice: actions.data,
      };
    case universalInvoiceActions.UPDATE_UNIVERSAL_INVOICE_ERROR:
      return {
        ...state,
        updatingInvoice: false,
        updateInvoiceError: actions.error,
      };

    case universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_REQUEST:
      return {
        ...state,
        gettingOneInvoice: true,
        getOneInvoiceError: {},
        universalInvoice: {},
      };
    case universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_SUCCESS:
      return {
        ...state,
        gettingOneInvoice: false,
        universalInvoice: actions.data,
      };
    case universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_ERROR:
      return {
        ...state,
        gettingOneInvoice: false,
        getOneInvoiceError: actions.error,
      };

    case universalInvoiceActions.DELETE_UNIVERSAL_INVOICE_REQUEST:
      return {
        ...state,
        deleteInvoiceError: {},
        deletingInvoice: true,
        deletedInvoice: {},
      };
    case universalInvoiceActions.DELETE_UNIVERSAL_INVOICE_SUCCESS:
      return {
        ...state,
        deletingInvoice: false,
        deletedInvoice: actions.data,
      };
    case universalInvoiceActions.DELETE_UNIVERSAL_INVOICE_ERROR:
      return {
        ...state,
        deletingInvoice: false,
        deleteInvoiceError: actions.error,
      };

    case universalInvoiceActions.SET_CURRENT_UNIVERSAL_INVOICE_TAB:
      return {
        ...state,
        currentTab: actions.currentTab,
      };

    case universalInvoiceActions.SHOW_DELETE_UNIVERSAL_INVOICE_MODAL:
      return {
        ...state,
        showDeleteInvoiceModal: actions.payload,
      };

    case universalInvoiceActions.SET_SELECTED_INVOICE_CONTEXT:
      return {
        ...state,
        selectedInvoiceContext: actions.context,
      };

    case universalInvoiceActions.SET_SELECTED_STAFF_INVOICE_CONTEXT:
      return {
        ...state,
        staffInvoiceContext: actions.context,
      };

    default:
      return state;
  }
}

export default universalInvoice;
