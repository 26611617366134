const systemProgrammeActions = {
  GET_ACMIS_PROGRAMMES_REQUEST: 'GET_ACMIS_PROGRAMMES_REQUEST',
  GET_ACMIS_PROGRAMMES_SUCCESS: 'GET_ACMIS_PROGRAMMES_SUCCESS',
  GET_ACMIS_PROGRAMMES_ERROR: 'GET_ACMIS_PROGRAMMES_ERROR',

  GET_EMIS_PROGRAMMES_REQUEST: 'GET_EMIS_PROGRAMMES_REQUEST',
  GET_EMIS_PROGRAMMES_SUCCESS: 'GET_EMIS_PROGRAMMES_SUCCESS',
  GET_EMIS_PROGRAMMES_ERROR: 'GET_EMIS_PROGRAMMES_ERROR',

  GET_EMIS_COURSE_CATEGORIES_REQUEST: 'GET_EMIS_COURSE_CATEGORIES_REQUEST',
  GET_EMIS_COURSE_CATEGORIES_SUCCESS: 'GET_EMIS_COURSE_CATEGORIES_SUCCESS',
  GET_EMIS_COURSE_CATEGORIES_ERROR: 'GET_EMIS_COURSE_CATEGORIES_ERROR',

  UPDATE_ACMIS_PROGRAMMES_REQUEST: 'UPDATE_ACMIS_PROGRAMMES_REQUEST',
  UPDATE_ACMIS_PROGRAMMES_SUCCESS: 'UPDATE_ACMIS_PROGRAMMES_SUCCESS',
  UPDATE_ACMIS_PROGRAMMES_ERROR: 'UPDATE_ACMIS_PROGRAMMES_ERROR',

  SUBMIT_SYSTEM_PROGRAMMES_REQUEST: 'SUBMIT_SYSTEM_PROGRAMMES_REQUEST',
  SUBMIT_SYSTEM_PROGRAMMES_SUCCESS: 'SUBMIT_SYSTEM_PROGRAMMES_SUCCESS',
  SUBMIT_SYSTEM_PROGRAMMES_ERROR: 'SUBMIT_SYSTEM_PROGRAMMES_ERROR',

  getAcmisProgrammes: () => ({
    type: systemProgrammeActions.GET_ACMIS_PROGRAMMES_REQUEST,
  }),

  getEmisProgrammes: () => ({
    type: systemProgrammeActions.GET_EMIS_PROGRAMMES_REQUEST,
  }),

  getEmisCourseCategories: () => ({
    type: systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_REQUEST,
  }),

  submitProgrammes: (data) => ({
    type: systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_REQUEST,
    data,
  }),

  manageProgrammes: (data) => ({
    type: systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_REQUEST,
    data,
  }),
};

export default systemProgrammeActions;
