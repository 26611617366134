const collegeActions = {
  GET_COLLEGES_REQUEST: 'GET_COLLEGES_REQUEST',
  GET_COLLEGES_SUCCESS: 'GET_COLLEGES_SUCCESS',
  GET_COLLEGES_ERROR: 'GET_COLLEGES_ERROR',

  CREATE_COLLEGE_SUCCESS: 'CREATE_COLLEGE_SUCCESS',
  CREATE_COLLEGE_REQUEST: 'CREATE_COLLEGE_REQUEST',
  CREATE_COLLEGE_ERROR: 'CREATE_COLLEGE_ERROR',

  DELETE_COLLEGE_SUCCESS: 'DELETE_COLLEGE_SUCCESS',
  DELETE_COLLEGE_REQUEST: 'DELETE_COLLEGE_REQUEST',
  DELETE_COLLEGE_ERROR: 'DELETE_COLLEGE_ERROR',

  UPDATE_COLLEGE_SUCCESS: 'UPDATE_COLLEGE_SUCCESS',
  UPDATE_COLLEGE_REQUEST: 'UPDATE_COLLEGE_REQUEST',
  UPDATE_COLLEGE_ERROR: 'UPDATE_COLLEGE_ERROR',

  DOWNLOAD_COLLEGE_TEMPLATE_REQUEST: 'DOWNLOAD_COLLEGE_TEMPLATE_REQUEST',
  DOWNLOAD_COLLEGE_TEMPLATE_SUCCESS: 'DOWNLOAD_COLLEGE_TEMPLATE_SUCCESS',
  DOWNLOAD_COLLEGE_TEMPLATE_ERROR: 'DOWNLOAD_COLLEGE_TEMPLATE_ERROR',

  UPLOAD_COLLEGE_TEMPLATE_REQUEST: 'UPLOAD_COLLEGE_TEMPLATE_REQUEST',
  UPLOAD_COLLEGE_TEMPLATE_SUCCESS: 'UPLOAD_COLLEGE_TEMPLATE_SUCCESS',
  UPLOAD_COLLEGE_TEMPLATE_ERROR: 'UPLOAD_COLLEGE_TEMPLATE_ERROR',

  getColleges: () => ({
    type: collegeActions.GET_COLLEGES_REQUEST,
  }),

  createCollege: (data) => ({
    type: collegeActions.CREATE_COLLEGE_REQUEST,
    data,
  }),

  deleteCollege: (id) => ({
    type: collegeActions.DELETE_COLLEGE_REQUEST,
    id,
  }),

  updateCollege: (id, data) => ({
    type: collegeActions.UPDATE_COLLEGE_REQUEST,
    id,
    data,
  }),

  downloadCollegeTemplate: () => ({
    type: collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_REQUEST,
  }),

  uploadCollegeTemplate: (data) => ({
    type: collegeActions.UPLOAD_COLLEGE_TEMPLATE_REQUEST,
    data,
  }),
};

export default collegeActions;
