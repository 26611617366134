import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { itsDataActions } from '../../actions';

function* getItsStatement(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/its-statements`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: itsDataActions.SEARCH_ITS_DATA_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: itsDataActions.SEARCH_ITS_DATA_ERROR,
      error: error.data,
    });
  }
}

function* downloadItsStatement(actions) {
  try {
    yield axios({
      url: `/universal-payments/reports/pdf-its-statement`,
      method: 'POST',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${actions?.params?.student || ''} STUDENT-FINANCIAL-STATEMENT.pdf`
      );
    });
    yield put({
      type: itsDataActions.DOWNLOAD_ITS_STATEMENT_SUCCESS,
      data: {
        server: { message: 'Financial Statement downloaded!', status: true },
      },
    });
  } catch (error) {
    yield put({
      type: itsDataActions.DOWNLOAD_ITS_STATEMENT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetItsStatement() {
  yield takeLatest(itsDataActions.SEARCH_ITS_DATA_REQUEST, getItsStatement);
}

function* watchDownloadItsStatement() {
  yield takeLatest(
    itsDataActions.DOWNLOAD_ITS_STATEMENT_REQUEST,
    downloadItsStatement
  );
}

export default [fork(watchGetItsStatement), fork(watchDownloadItsStatement)];
