import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { collegeActions } from '../../actions';

function* getColleges(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/colleges',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: collegeActions.GET_COLLEGES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: collegeActions.GET_COLLEGES_ERROR,
      error: error.data,
    });
  }
}

function* createColleges(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/colleges',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: collegeActions.CREATE_COLLEGE_SUCCESS,
      data: response,
    });
    yield put({ type: collegeActions.GET_COLLEGES_REQUEST });
    yield put({
      type: 'SHOW_COLLEGE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: collegeActions.CREATE_COLLEGE_ERROR,
      error: error.data,
    });
  }
}

function* deleteCollege(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/colleges/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: collegeActions.DELETE_COLLEGE_SUCCESS,
      data: response,
    });
    yield put({
      type: collegeActions.GET_COLLEGES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: collegeActions.DELETE_COLLEGE_ERROR,
      error: error.data,
    });
  }
}
function* updateCollege(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/colleges/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: collegeActions.UPDATE_COLLEGE_SUCCESS,
      data: response,
    });
    yield put({
      type: collegeActions.GET_COLLEGES_REQUEST,
    });
    yield put({
      type: 'SHOW_COLLEGE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: collegeActions.UPDATE_COLLEGE_ERROR,
      error: error.data,
    });
  }
}

function* downloadCollegeTemplate() {
  try {
    yield axios({
      url: '/programme-mgt/colleges/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-COLLEGE-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadCollegeTemplate(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/colleges/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: collegeActions.UPLOAD_COLLEGE_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({ type: collegeActions.GET_COLLEGES_REQUEST });
    yield put({ type: 'SHOW_UPLOAD_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: collegeActions.UPLOAD_COLLEGE_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetColleges() {
  yield takeLatest(collegeActions.GET_COLLEGES_REQUEST, getColleges);
}

function* watchCreateColleges() {
  yield takeLatest(collegeActions.CREATE_COLLEGE_REQUEST, createColleges);
}

function* watchDeleteCollege() {
  yield takeLatest(collegeActions.DELETE_COLLEGE_REQUEST, deleteCollege);
}
function* watchUpdateCollege() {
  yield takeLatest(collegeActions.UPDATE_COLLEGE_REQUEST, updateCollege);
}
function* watchDownloadCollegeTemplate() {
  yield takeLatest(
    collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_REQUEST,
    downloadCollegeTemplate
  );
}
function* watchUploadCollegeTemplate() {
  yield takeLatest(
    collegeActions.UPLOAD_COLLEGE_TEMPLATE_REQUEST,
    uploadCollegeTemplate
  );
}

export default [
  fork(watchGetColleges),
  fork(watchCreateColleges),
  fork(watchDeleteCollege),
  fork(watchUpdateCollege),
  fork(watchDownloadCollegeTemplate),
  fork(watchUploadCollegeTemplate),
];
