const auth = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_ERROR: 'GET_USERS_ERROR',

  GET_SINGLE_USER_SUCCESS: 'GET_SINGLE_USER_SUCCESS',
  GET_SINGLE_USER_REQUEST: 'GET_SINGLE_USER_REQUEST',
  GET_SINGLE_USER_ERROR: 'GET_SINGLE_USER_ERROR',

  EMAIL_VERIFICATION_LINK_SUCCESS: 'EMAIL_VERIFICATION_LINK_SUCCESS',
  EMAIL_VERIFICATION_LINK_REQUEST: 'EMAIL_VERIFICATION_LINK_REQUEST',
  EMAIL_VERIFICATION_LINK_ERROR: 'EMAIL_VERIFICATION_LINK_ERROR',

  PHONE_VERIFICATION_OTP_SUCCESS: 'PHONE_VERIFICATION_OTP_SUCCESS',
  PHONE_VERIFICATION_OTP_REQUEST: 'PHONE_VERIFICATION_OTP_REQUEST',
  PHONE_VERIFICATION_OTP_ERROR: 'PHONE_VERIFICATION_OTP_ERROR',

  VERIFY_PHONE_SUCCESS: 'VERIFY_PHONE_SUCCESS',
  VERIFY_PHONE_REQUEST: 'VERIFY_PHONE_REQUEST',
  VERIFY_PHONE_ERROR: 'VERIFY_PHONE_ERROR',

  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  CHANGE_DEFAULT_PASSWORD_SUCCESS: 'CHANGE_DEFAULT_PASSWORD_SUCCESS',
  CHANGE_DEFAULT_PASSWORD_REQUEST: 'CHANGE_DEFAULT_PASSWORD_REQUEST',
  CHANGE_DEFAULT_PASSWORD_ERROR: 'CHANGE_DEFAULT_PASSWORD_ERROR',

  VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_ERROR: 'VERIFY_EMAIL_ERROR',

  GET_AUTH_USER_SUCCESS: 'GET_AUTH_USER_SUCCESS',
  GET_AUTH_USER_REQUEST: 'GET_AUTH_USER_REQUEST',
  GET_AUTH_USER_ERROR: 'GET_AUTH_USER_ERROR',

  GET_AUTH_USER_APPS_SUCCESS: 'GET_AUTH_USER_APPS_SUCCESS',
  GET_AUTH_USER_APPS_REQUEST: 'GET_AUTH_USER_APPS_REQUEST',
  GET_AUTH_USER_APPS_ERROR: 'GET_AUTH_USER_APPS_ERROR',

  GET_AUTH_USER_DEPARTMENTS_SUCCESS: 'GET_AUTH_USER_DEPARTMENTS_SUCCESS',
  GET_AUTH_USER_DEPARTMENTS_REQUEST: 'GET_AUTH_USER_DEPARTMENTS_REQUEST',
  GET_AUTH_USER_DEPARTMENTS_ERROR: 'GET_AUTH_USER_DEPARTMENTS_ERROR',

  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_REQUEST: 'SEND_OTP_REQUEST',
  SEND_OTP_ERROR: 'SEND_OTP_ERROR',

  RESET_OTP_PASSWORD_SUCCESS: 'RESET_OTP_PASSWORD_SUCCESS',
  RESET_OTP_PASSWORD_REQUEST: 'RESET_OTP_PASSWORD_REQUEST',
  RESET_OTP_PASSWORD_ERROR: 'RESET_OTP_PASSWORD_ERROR',

  GET_USER_ROLE_BOUND_SUCCESS: 'GET_USER_ROLE_BOUND_SUCCESS',
  GET_USER_ROLE_BOUND_REQUEST: 'GET_USER_ROLE_BOUND_REQUEST',
  GET_USER_ROLE_BOUND_ERROR: 'GET_USER_ROLE_BOUND_ERROR',

  AUTHENTICATE_USER_ACTION_SUCCESS: 'AUTHENTICATE_USER_ACTION_SUCCESS',
  AUTHENTICATE_USER_ACTION_REQUEST: 'AUTHENTICATE_USER_ACTION_REQUEST',
  AUTHENTICATE_USER_ACTION_ERROR: 'AUTHENTICATE_USER_ACTION_ERROR',

  CHECK_PASSWORD_EXPIRY_SUCCESS: 'CHECK_PASSWORD_EXPIRY_SUCCESS',
  CHECK_PASSWORD_EXPIRY_REQUEST: 'CHECK_PASSWORD_EXPIRY_REQUEST',
  CHECK_PASSWORD_EXPIRY_ERROR: 'CHECK_PASSWORD_EXPIRY_ERROR',

  SET_AUTH_USER: 'SET_AUTH_USER',
  REMOVE_AUTH_USER: 'REMOVE_AUTH_USER',
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
  SET_CURRENT_ROLE: 'SET_CURRENT_ROLE',
  SET_MY_APPS: 'SET_MY_APPS',

  loginUser: (data) => ({
    type: auth.LOGIN_REQUEST,
    data,
  }),
  checkPasswordExpiryStatus: () => ({
    type: auth.CHECK_PASSWORD_EXPIRY_REQUEST,
  }),
  getAuthUser: () => ({
    type: auth.GET_AUTH_USER_REQUEST,
  }),

  getAuthUserApps: () => ({
    type: auth.GET_AUTH_USER_APPS_REQUEST,
  }),

  changePassword: (data) => ({
    type: auth.CHANGE_PASSWORD_REQUEST,
    data,
  }),

  changeDefaultPassword: (data) => ({
    type: auth.CHANGE_DEFAULT_PASSWORD_REQUEST,
    data,
  }),

  getAuthUserDepartments: () => ({
    type: auth.GET_AUTH_USER_DEPARTMENTS_REQUEST,
  }),

  sendOTP: (data) => ({
    type: auth.SEND_OTP_REQUEST,
    data,
  }),

  resetOPTPassword: (data) => ({
    type: auth.RESET_OTP_PASSWORD_REQUEST,
    data,
  }),

  logoutUser: () => ({
    type: auth.LOGOUT_REQUEST,
  }),

  fetchAllUsers: (options) => ({
    type: auth.GET_USERS_REQUEST,
    options,
  }),

  fetchSingleUser: (userId) => ({
    type: auth.GET_SINGLE_USER_REQUEST,
    userId,
  }),

  requestEmailVerificationLink: (data) => ({
    type: auth.EMAIL_VERIFICATION_LINK_REQUEST,
    data,
  }),

  sendPhoneVerificationOTP: () => ({
    type: auth.PHONE_VERIFICATION_OTP_REQUEST,
  }),

  verifyPhone: (data) => ({
    type: auth.VERIFY_PHONE_REQUEST,
    data,
  }),

  verifyEmail: (token) => ({
    type: auth.VERIFY_EMAIL_REQUEST,
    token,
  }),

  setAuthUser: (user) => ({
    type: auth.SET_AUTH_USER,
    user,
  }),

  removeAuthUser: () => ({
    type: auth.REMOVE_AUTH_USER,
  }),

  setIsAuthenticated: (payload = false) => ({
    type: auth.SET_IS_AUTHENTICATED,
    payload,
  }),

  setMyApps: (payload = []) => ({
    type: auth.SET_MY_APPS,
    payload,
  }),

  setCurrentRole: (payload = {}) => ({
    type: auth.SET_CURRENT_ROLE,
    payload,
  }),

  getUserRoleBounds: (roleId) => ({
    type: auth.GET_USER_ROLE_BOUND_REQUEST,
    roleId,
  }),

  authenticateUserAction: (data) => ({
    type: auth.AUTHENTICATE_USER_ACTION_REQUEST,
    data,
  }),
};

export default auth;
