const weightingAndSelection = {
  CREATE_WEIGHTING_CRITERIA_REQUEST: 'CREATE_WEIGHTING_CRITERIA_REQUEST',
  CREATE_WEIGHTING_CRITERIA_SUCCESS: 'CREATE_WEIGHTING_CRITERIA_SUCCESS',
  CREATE_WEIGHTING_CRITERIA_ERROR: 'CREATE_WEIGHTING_CRITERIA_ERROR',

  CREATE_SELECTION_CRITERIA_REQUEST: 'CREATE_SELECTION_CRITERIA_REQUEST',
  CREATE_SELECTION_CRITERIA_SUCCESS: 'CREATE_SELECTION_CRITERIA_SUCCESS',
  CREATE_SELECTION_CRITERIA_ERROR: 'CREATE_SELECTION_CRITERIA_ERROR',

  GET_SELECTION_CRITERIA_REQUEST: 'GET_SELECTION_CRITERIA_REQUEST',
  GET_SELECTION_CRITERIA_SUCCESS: 'GET_SELECTION_CRITERIA_SUCCESS',
  GET_SELECTION_CRITERIA_ERROR: 'GET_SELECTION_CRITERIA_ERROR',

  GET_WEIGHTING_CRITERIA_REQUEST: 'GET_WEIGHTING_CRITERIA_REQUEST',
  GET_WEIGHTING_CRITERIA_SUCCESS: 'GET_WEIGHTING_CRITERIA_SUCCESS',
  GET_WEIGHTING_CRITERIA_ERROR: 'GET_WEIGHTING_CRITERIA_ERROR',

  ADD_UNEB_SUBJECTS_REQUEST: 'ADD_UNEB_SUBJECTS_REQUEST',
  ADD_UNEB_SUBJECTS_SUCCESS: 'ADD_UNEB_SUBJECTS_SUCCESS',
  ADD_UNEB_SUBJECTS_ERROR: 'ADD_UNEB_SUBJECTS_ERROR',

  ADD_NEW_CATEGORY_REQUEST: 'ADD_NEW_CATEGORY_REQUEST',
  ADD_NEW_CATEGORY_SUCCESS: 'ADD_NEW_CATEGORY_SUCCESS',
  ADD_NEW_CATEGORY_ERROR: 'ADD_NEW_CATEGORY_ERROR',

  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',

  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

  SET_SELECTED_SELECTION_CRITERIA: 'SET_SELECTED_SELECTION_CRITERIA',
  SET_CRITERIA_TO_ADD_SUBJECTS: 'SET_CRITERIA_TO_ADD_SUBJECTS',
  SET_CURRENT_SELECTION_TAB: 'SET_CURRENT_SELECTION_TAB',
  SET_CURRENT_CRITERIA_TAB: 'SET_CURRENT_CRITERIA_TAB',
  SET_SELECTED_CRITERIA: 'SET_SELECTED_CRITERIA',
  SET_ACTION_TO_PERFORM: 'SET_ACTION_TO_PERFORM',
  SET_CATEGORY_VIEW: 'SET_CATEGORY_VIEW',

  createWeightingCriteria: (data) => ({
    type: weightingAndSelection.CREATE_WEIGHTING_CRITERIA_REQUEST,
    data,
  }),

  createSelectionCriteria: (data) => ({
    type: weightingAndSelection.CREATE_SELECTION_CRITERIA_REQUEST,
    data,
  }),

  getWeightingCriteria: () => ({
    type: weightingAndSelection.GET_WEIGHTING_CRITERIA_REQUEST,
  }),

  getSelectionCriteria: () => ({
    type: weightingAndSelection.GET_SELECTION_CRITERIA_REQUEST,
  }),

  addUnebSubjects: (data) => ({
    type: weightingAndSelection.ADD_UNEB_SUBJECTS_REQUEST,
    data,
  }),

  addNewCategory: (data) => ({
    type: weightingAndSelection.ADD_NEW_CATEGORY_REQUEST,
    data,
  }),

  updateCategory: (criteriaId, data) => ({
    type: weightingAndSelection.UPDATE_CATEGORY_REQUEST,
    criteriaId,
    data,
  }),

  deleteCategory: (categoryId) => ({
    type: weightingAndSelection.DELETE_CATEGORY_REQUEST,
    categoryId,
  }),

  setCurrentCriteriaTab: (tabName) => ({
    type: weightingAndSelection.SET_CURRENT_CRITERIA_TAB,
    tabName,
  }),

  setCurrentSelectionTab: (tabName) => ({
    type: weightingAndSelection.SET_CURRENT_SELECTION_TAB,
    tabName,
  }),

  setSelectedCriteria: (data) => ({
    type: weightingAndSelection.SET_SELECTED_CRITERIA,
    data,
  }),

  setSelectedSelectionCriteria: (data) => ({
    type: weightingAndSelection.SET_SELECTED_SELECTION_CRITERIA,
    data,
  }),

  setCriteriaToAddSubjects: (data) => ({
    type: weightingAndSelection.SET_CRITERIA_TO_ADD_SUBJECTS,
    data,
  }),

  setCategoryView: (tabName) => ({
    type: weightingAndSelection.SET_CATEGORY_VIEW,
    tabName,
  }),

  setActionToPerform: (actionName) => ({
    type: weightingAndSelection.SET_ACTION_TO_PERFORM,
    actionName,
  }),
};

export default weightingAndSelection;
