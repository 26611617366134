import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { servicesPolicyActions, tabActions } from '../../actions';

const servicePolicyURL = '/institution-policy-mgt/student-service-policies';

function* getServicePolicies() {
  try {
    const response = yield axios({
      url: servicePolicyURL,
      method: 'GET',
    });
    yield put({
      type: servicesPolicyActions.GET_SERVICE_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: servicesPolicyActions.GET_SERVICE_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createServicePolicy(actions) {
  try {
    const response = yield axios({
      url: servicePolicyURL,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: servicesPolicyActions.CREATE_SERVICE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: servicesPolicyActions.GET_SERVICE_POLICIES_REQUEST,
    });
  } catch (error) {
    yield put({
      type: servicesPolicyActions.CREATE_SERVICE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updatePolicy(actions) {
  try {
    const response = yield axios({
      url: `${servicePolicyURL}/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: servicesPolicyActions.UPDATE_SERVICE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: servicesPolicyActions.GET_SERVICE_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: servicesPolicyActions.UPDATE_SERVICE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deletePolicy(actions) {
  try {
    const response = yield axios({
      url: `${servicePolicyURL}/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: servicesPolicyActions.DELETE_SERVICE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: servicesPolicyActions.GET_SERVICE_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: servicesPolicyActions.DELETE_SERVICE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetServicePolicies() {
  yield takeLatest(
    servicesPolicyActions.GET_SERVICE_POLICIES_REQUEST,
    getServicePolicies
  );
}

function* watchCreateServicePolicy() {
  yield takeLatest(
    servicesPolicyActions.CREATE_SERVICE_POLICY_REQUEST,
    createServicePolicy
  );
}

function* watchUpdatePolicy() {
  yield takeLatest(
    servicesPolicyActions.UPDATE_SERVICE_POLICY_REQUEST,
    updatePolicy
  );
}

function* watchDeletePolicy() {
  yield takeLatest(
    servicesPolicyActions.DELETE_SERVICE_POLICY_REQUEST,
    deletePolicy
  );
}

export default [
  fork(watchGetServicePolicies),
  fork(watchCreateServicePolicy),
  fork(watchUpdatePolicy),
  fork(watchDeletePolicy),
];
