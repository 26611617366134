import { specializationActions } from '../../actions';
import initialState from '../../initialState';

function specialization(state = initialState.specialization, actions) {
  switch (actions.type) {
    case specializationActions.GET_SPECIALIZATIONS_REQUEST:
      return {
        ...state,
        getSpecializationError: {},
        createSpecializationError: {},
        loading: true,
      };
    case specializationActions.GET_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        specializations: actions.data.specializations,
        loading: false,
      };
    case specializationActions.GET_SPECIALIZATIONS_ERROR:
      return {
        ...state,
        getSpecializationError: actions.error,
        loading: false,
      };
    case specializationActions.CREATE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        creating: true,
        createdSpecialization: {},
        getSpecializationError: {},
        createSpecializationError: {},
      };
    case specializationActions.CREATE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        creating: false,
        createdSpecialization: actions.data,
      };
    case specializationActions.CREATE_SPECIALIZATION_ERROR:
      return {
        ...state,
        creating: false,
        createSpecializationError: actions.error,
      };
    case specializationActions.DELETE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case specializationActions.DELETE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case specializationActions.DELETE_SPECIALIZATION_ERROR:
      return {
        ...state,
        deleting: false,
        specializationError: actions.error,
      };
    case specializationActions.UPDATE_SPECIALIZATION_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case specializationActions.UPDATE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case specializationActions.UPDATE_SPECIALIZATION_ERROR:
      return {
        ...state,
        updating: false,
        specializationError: actions.error,
      };
    default:
      return state;
  }
}

export default specialization;
