const resultNodeActions = {
  CREATE_NTC_STUDENT_REQUEST: 'CREATE_NTC_STUDENT_REQUEST',
  CREATE_NTC_STUDENT_SUCCESS: 'CREATE_NTC_STUDENT_SUCCESS',
  CREATE_NTC_STUDENT_ERROR: 'CREATE_NTC_STUDENT_ERROR',

  DOWNLOAD_NTC_STUDENTS_TEMPLATE_REQUEST:
    'DOWNLOAD_NTC_STUDENTS_TEMPLATE_REQUEST',
  DOWNLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS:
    'DOWNLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS',
  DOWNLOAD_NTC_STUDENTS_TEMPLATE_ERROR: 'DOWNLOAD_NTC_STUDENTS_TEMPLATE_ERROR',

  GET_NTC_STUDENTS_REQUEST: 'GET_NTC_STUDENTS_REQUEST',
  GET_NTC_STUDENTS_SUCCESS: 'GET_NTC_STUDENTS_SUCCESS',
  GET_NTC_STUDENTS_ERROR: 'GET_NTC_STUDENTS_ERROR',

  UPLOAD_NTC_STUDENTS_TEMPLATE_REQUEST: 'UPLOAD_NTC_STUDENTS_TEMPLATE_REQUEST',
  UPLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS: 'UPLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS',
  UPLOAD_NTC_STUDENTS_TEMPLATE_ERROR: 'UPLOAD_NTC_STUDENTS_TEMPLATE_ERROR',

  DELETE_NTC_STUDENTS_REQUEST: 'DELETE_NTC_STUDENTS_REQUEST',
  DELETE_NTC_STUDENTS_SUCCESS: 'DELETE_NTC_STUDENTS_SUCCESS',
  DELETE_NTC_STUDENTS_ERROR: 'DELETE_NTC_STUDENTS_ERROR',

  UPDATE_NTC_STUDENT_REQUEST: 'UPDATE_NTC_STUDENT_REQUEST',
  UPDATE_NTC_STUDENT_SUCCESS: 'UPDATE_NTC_STUDENT_SUCCESS',
  UPDATE_NTC_STUDENT_ERROR: 'UPDATE_NTC_STUDENT_ERROR',

  getNTCStudents: (params) => ({
    type: resultNodeActions.GET_NTC_STUDENTS_REQUEST,
    params,
  }),

  downloadNTCStudentsTemplate: (params) => ({
    type: resultNodeActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_REQUEST,
    params,
  }),

  uploadNTCStudentsTemplate: (data) => ({
    type: resultNodeActions.UPLOAD_NTC_STUDENTS_TEMPLATE_REQUEST,
    data,
  }),

  createNTCStudent: (data) => ({
    type: resultNodeActions.CREATE_NTC_STUDENT_REQUEST,
    data,
  }),

  updateNTCStudent: (studentId, data) => ({
    type: resultNodeActions.UPDATE_NTC_STUDENT_REQUEST,
    data,
    studentId,
  }),

  deleteNTCStudent: (studentId, data) => ({
    type: resultNodeActions.DELETE_NTC_STUDENTS_REQUEST,
    data,
    studentId,
  }),
};

export default resultNodeActions;
