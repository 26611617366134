import axios from 'axios';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { resultApprovalActions, settingActions } from '../../actions';

function* getResultApprovalPolicy() {
  try {
    const response = yield axios({
      url: 'institution-policy-mgt/results-policies/result-approval-hierarchies',
      method: 'GET',
    });

    yield put({
      type: resultApprovalActions.GET_RESULT_APPROVAL_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: resultApprovalActions.GET_RESULT_APPROVAL_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteResultApprovalPolicy(actions) {
  try {
    yield axios({
      url: `institution-policy-mgt/results-policies/result-approval-hierarchies/${actions.hierarchyId}`,
      method: 'DELETE',
    });

    yield put({
      type: resultApprovalActions.DELETE_RESULT_APPROVAL_POLICY_SUCCESS,
    });

    yield put({
      type: resultApprovalActions.GET_RESULT_APPROVAL_POLICY_REQUEST,
    });
    yield put({
      type: resultApprovalActions.setDeleteResultApprovalPolicy(),
    });
  } catch (error) {
    yield put({
      type: resultApprovalActions.DELETE_RESULT_APPROVAL_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createResultApprovalPolicy({ data, hierarchyId }) {
  try {
    let url =
      'institution-policy-mgt/results-policies/result-approval-hierarchies';
    if (hierarchyId)
      url = `institution-policy-mgt/results-policies/result-approval-hierarchies/${hierarchyId}`;
    const response = yield axios({
      url,
      method: isEmpty(hierarchyId) ? 'POST' : 'PUT',
      data,
    });

    yield put({
      type: resultApprovalActions.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_SUCCESS,
      data: response,
    });
    yield put(resultApprovalActions.getResultApprovalPolicy());
    yield put(settingActions.showModal(false));
  } catch (error) {
    yield put({
      type: resultApprovalActions.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetResultApprovalPolicy() {
  yield takeLatest(
    resultApprovalActions.GET_RESULT_APPROVAL_POLICY_REQUEST,
    getResultApprovalPolicy
  );
}

function* watchDeleteResultApprovalPolicy() {
  yield takeLatest(
    resultApprovalActions.DELETE_RESULT_APPROVAL_POLICY_REQUEST,
    deleteResultApprovalPolicy
  );
}

function* watchCreateResultApprovalPolicy() {
  yield takeLatest(
    resultApprovalActions.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_REQUEST,
    createResultApprovalPolicy
  );
}

export default [
  fork(watchGetResultApprovalPolicy),
  fork(watchDeleteResultApprovalPolicy),
  fork(watchCreateResultApprovalPolicy),
];
