import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  runningAdmissionActions,
  runningAdmissionProgrammeActions,
  tabActions,
} from '../../actions';

function* getRunningAdmissionProgrammes(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admission-programmes',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* getRunningAdmissionProgrammeSpecialFees(actions) {
  try {
    const { runningAdmissionProgrammeId } = actions;
    const response = yield axios({
      url: `admissions/running-admission-programme-campuses/special-fees/${runningAdmissionProgrammeId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_SUCCESS,
      data: response.data,
      runningAdmissionProgrammeId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* createRunningAdmissionProgrammeSpecialFees(actions) {
  try {
    const { runningAdmissionProgrammeId, data } = actions;
    const response = yield axios({
      url: `admissions/running-admission-programme-campuses/special-fees`,
      method: 'POST',
      data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.CREATE_PROGRAMME_SPECIAL_FEES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_REQUEST,
      runningAdmissionProgrammeId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.CREATE_PROGRAMME_SPECIAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* updateRunningAdmissionProgrammeSpecialFees(actions) {
  try {
    const { specialFeeId, data, runningAdmissionProgrammeId } = actions;
    const response = yield axios({
      url: `admissions/running-admission-programme-campuses/update-special-fees/${specialFeeId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SPECIAL_FEES_SUCCESS,
      data: response.data,
      specialFeeId,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_REQUEST,
      runningAdmissionProgrammeId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SPECIAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* deleteRunningAdmissionProgrammeSpecialFees(actions) {
  try {
    const { specialFeeId, runningAdmissionProgrammeId } = actions;
    const response = yield axios({
      url: `admissions/running-admission-programme-campuses/delete-special-fee/${specialFeeId}`,
      method: 'DELETE',
    });
    yield put({
      type: runningAdmissionProgrammeActions.DELETE_PROGRAMME_SPECIAL_FEES_SUCCESS,
      data: response.data,
      specialFeeId,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_REQUEST,
      runningAdmissionProgrammeId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.DELETE_PROGRAMME_SPECIAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* createRunningAdmissionProgramme(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admission-programmes',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.CREATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.data.running_admission_id,
    });

    yield put({
      type: 'REMOVE_ALL_PROGRAMMES_TO_ADVERTISE',
      payload: false,
    });

    yield put({
      type: tabActions.CURRENT_TAB,
      activeTab: 'advertised-programmes',
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.CREATE_RUNNING_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* getSingleRunningAdmissionProgramme(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/${actions.runningAdmissionProgrammeId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* updateRunningAdmissionProgramme(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/${actions.runningAdmissionProgrammeId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_RUNNING_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* deleteRunningAdmissionProgramme(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/${actions.runningAdmissionProgrammeId}`,
      method: 'DELETE',
    });
    yield put({
      type: runningAdmissionProgrammeActions.DELETE_RUNNING_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
      runningAdmissionProgrammeId: actions.runningAdmissionProgrammeId,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.DELETE_RUNNING_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* uploadManageAdmissionsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/upload-template/${actions.id}`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: runningAdmissionProgrammeActions.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.id,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadManageAdmissionsTemplate(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-programmes/download-template/${actions.runningAdmissionId}`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-MANAGE-ADMISSIONS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: runningAdmissionProgrammeActions.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* updateProgrammeStatus(actions) {
  const { data } = actions;
  let type = 'activate';

  if (data.is_active === false) {
    type = 'de-activate';
  }

  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/${type}/${actions.programmeId}`,
      method: 'PUT',
    });

    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_STATUS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_STATUS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getWeightingCriteria(actions) {
  try {
    const response = yield axios({
      url: `/admissions/weighting-criteria/fetch-by-programme/${actions.programmeId}`,
      method: 'GET',
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_WEIGHTING_CRITERIA_ERROR,
      error: error.data,
    });
  }
}
function* getSelectionCriteria(actions) {
  try {
    const response = yield axios({
      url: `/admissions/selection-criteria/fetch-by-programme/${actions.programmeId}`,
      method: 'GET',
    });
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SELECTION_CRITERIA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.GET_PROGRAMME_SELECTION_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* updateProgrammeWeightingCriteria(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/add-weighting-criteria/${actions.programmeId}/${actions.criteriaId}`,
      method: 'PUT',
    });
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_ADD_PROGRAMME_CRITERIA_MODAL',
      payload: false,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* updateProgrammeSelectionCriteria(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programmes/add-selection-criteria/${actions.programmeId}/${actions.criteriaId}`,
      method: 'PUT',
    });
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SELECTION_CRITERIA_SUCCESS,
      data: response,
    });
    yield put({ type: 'SHOW_ADD_PROGRAMME_CRITERIA_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SELECTION_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* runSelection(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/run-selections/${actions.runningAdmissionId}`,
      method: 'POST',
      timeout: 21600000,
    });
    yield put({
      type: runningAdmissionProgrammeActions.RUN_SELECTION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.RUN_SELECTION_ERROR,
      error: error.data,
    });
  }
}

function* runBulkWeighting(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/generate-multiple-weights/${actions.runningAdmissionId}`,
      method: 'POST',
      timeout: 21600000,
    });
    yield put({
      type: runningAdmissionProgrammeActions.RUN_BULK_WEIGHTING_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.RUN_BULK_WEIGHTING_ERROR,
      error: error.data,
    });
  }
}

function* runWeighting(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/generate-weights/${actions.advertisedProgrammeId}`,
      method: 'POST',
      timeout: 12000000,
    });
    yield put({
      type: runningAdmissionProgrammeActions.RUN_WEIGHTING_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
    yield put({ type: 'SHOW_ADD_PROGRAMME_CRITERIA_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: runningAdmissionProgrammeActions.RUN_WEIGHTING_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRunningAdmissionProgrammes() {
  yield takeLatest(
    runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    getRunningAdmissionProgrammes
  );
}

function* watchGetRunningAdmissionProgrammeSpecialFees() {
  yield takeLatest(
    runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_REQUEST,
    getRunningAdmissionProgrammeSpecialFees
  );
}

function* watchCreateRunningAdmissionProgrammeSpecialFees() {
  yield takeLatest(
    runningAdmissionProgrammeActions.CREATE_PROGRAMME_SPECIAL_FEES_REQUEST,
    createRunningAdmissionProgrammeSpecialFees
  );
}

function* watchUpdateRunningAdmissionProgrammeSpecialFees() {
  yield takeLatest(
    runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SPECIAL_FEES_REQUEST,
    updateRunningAdmissionProgrammeSpecialFees
  );
}

function* watchDeleteRunningAdmissionProgrammeSpecialFees() {
  yield takeLatest(
    runningAdmissionProgrammeActions.DELETE_PROGRAMME_SPECIAL_FEES_REQUEST,
    deleteRunningAdmissionProgrammeSpecialFees
  );
}

function* watchCreateRunningAdmissionProgramme() {
  yield takeLatest(
    runningAdmissionProgrammeActions.CREATE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    createRunningAdmissionProgramme
  );
}

function* watchGetSingleRunningAdmissionProgramme() {
  yield takeLatest(
    runningAdmissionProgrammeActions.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    getSingleRunningAdmissionProgramme
  );
}

function* watchUpdateRunningAdmissionProgramme() {
  yield takeLatest(
    runningAdmissionProgrammeActions.UPDATE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    updateRunningAdmissionProgramme
  );
}

function* watchDeleteRunningAdmissionProgramme() {
  yield takeLatest(
    runningAdmissionProgrammeActions.DELETE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    deleteRunningAdmissionProgramme
  );
}

function* watchDownloadManageAdmissionsTemplate() {
  yield takeLatest(
    runningAdmissionProgrammeActions.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST,
    downloadManageAdmissionsTemplate
  );
}

function* watchUploadManageAdmissionsTemplate() {
  yield takeLatest(
    runningAdmissionProgrammeActions.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST,
    uploadManageAdmissionsTemplate
  );
}

function* watchUpdateProgrammeStatus() {
  yield takeLatest(
    runningAdmissionProgrammeActions.UPDATE_PROGRAMME_STATUS_REQUEST,
    updateProgrammeStatus
  );
}

function* watchGetSelectionCriteria() {
  yield takeLatest(
    runningAdmissionProgrammeActions.GET_PROGRAMME_SELECTION_CRITERIA_REQUEST,
    getSelectionCriteria
  );
}

function* watchGetWeightingCriteria() {
  yield takeLatest(
    runningAdmissionProgrammeActions.GET_PROGRAMME_WEIGHTING_CRITERIA_REQUEST,
    getWeightingCriteria
  );
}

function* watchUpdateProgrammeWeightingCriteria() {
  yield takeLatest(
    runningAdmissionProgrammeActions.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_REQUEST,
    updateProgrammeWeightingCriteria
  );
}

function* watchUpdateProgrammeSelectionCriteria() {
  yield takeLatest(
    runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SELECTION_CRITERIA_REQUEST,
    updateProgrammeSelectionCriteria
  );
}

function* watchRunSelection() {
  yield takeLatest(
    runningAdmissionProgrammeActions.RUN_SELECTION_REQUEST,
    runSelection
  );
}

function* watchRunBulkWeighting() {
  yield takeLatest(
    runningAdmissionProgrammeActions.RUN_BULK_WEIGHTING_REQUEST,
    runBulkWeighting
  );
}

function* watchRunWeighting() {
  yield takeLatest(
    runningAdmissionProgrammeActions.RUN_WEIGHTING_REQUEST,
    runWeighting
  );
}

export default [
  fork(watchGetRunningAdmissionProgrammes),
  fork(watchGetRunningAdmissionProgrammeSpecialFees),
  fork(watchCreateRunningAdmissionProgrammeSpecialFees),
  fork(watchUpdateRunningAdmissionProgrammeSpecialFees),
  fork(watchDeleteRunningAdmissionProgrammeSpecialFees),
  fork(watchCreateRunningAdmissionProgramme),
  fork(watchUpdateRunningAdmissionProgramme),
  fork(watchGetSingleRunningAdmissionProgramme),
  fork(watchDeleteRunningAdmissionProgramme),
  fork(watchDownloadManageAdmissionsTemplate),
  fork(watchUploadManageAdmissionsTemplate),
  fork(watchUpdateProgrammeStatus),
  fork(watchGetSelectionCriteria),
  fork(watchGetWeightingCriteria),
  fork(watchUpdateProgrammeWeightingCriteria),
  fork(watchUpdateProgrammeSelectionCriteria),
  fork(watchRunSelection),
  fork(watchRunWeighting),
  fork(watchRunBulkWeighting),
];
