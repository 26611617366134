import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { userRoleActions } from '../../actions';
import initialState from '../../initialState';

function userRole(state = initialState.userRole, actions) {
  switch (actions.type) {
    case userRoleActions.GET_USER_ROLES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case userRoleActions.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRoles: actions.data.userRoles,
        error: {},
        loading: false,
      };
    case userRoleActions.GET_USER_ROLES_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case userRoleActions.GET_SINGLE_USER_ROLE_REQUEST:
      return {
        ...state,
        fetchingOne: true,
        singleRoleError: {},
        singleRole: {},
      };
    case userRoleActions.GET_SINGLE_USER_ROLE_SUCCESS: {
      const { userRole: currentRole } = actions.data;
      const { singleRoles } = state;

      const currentRoleIndex = findIndex(
        singleRoles,
        (roleGroup) => roleGroup.id === currentRole.id
      );

      let newSingleRoles = [];

      if (currentRoleIndex === -1) {
        newSingleRoles = update(singleRoles, { $push: [currentRole] });
      } else {
        newSingleRoles = update(singleRoles, {
          [currentRoleIndex]: { $set: currentRole },
        });
      }

      return {
        ...state,
        singleRoles: newSingleRoles,
        singleRole: currentRole,
        fetchingOne: false,
      };
    }

    case userRoleActions.GET_SINGLE_USER_ROLE_ERROR:
      return {
        ...state,
        singleRoleError: actions.error,
        fetchingOne: false,
      };

    case userRoleActions.CREATE_USER_ROLE_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
        created: {},
      };
    case userRoleActions.CREATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case userRoleActions.CREATE_USER_ROLE_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };
    case userRoleActions.UPDATE_USER_ROLE_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case userRoleActions.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case userRoleActions.UPDATE_USER_ROLE_ERROR:
      return {
        ...state,
        updating: false,
        error: actions.error,
      };
    //
    case userRoleActions.UPDATE_USER_ROLE_PROFILE_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case userRoleActions.UPDATE_USER_ROLE_PROFILE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case userRoleActions.UPDATE_USER_ROLE_PROFILE_ERROR:
      return {
        ...state,
        updating: false,
        error: actions.error,
      };

    case userRoleActions.DELETE_USER_ROLE_REQUEST:
      return {
        ...state,
        deletingUserRole: true,
        deleteUserRoleError: {},
        deletingId: actions.id,
      };
    case userRoleActions.DELETE_USER_ROLE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: actions.data,
      };
    case userRoleActions.DELETE_USER_ROLE_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case userRoleActions.INSERT_USER_ROLE_APPS_REQUEST:
      return {
        ...state,
        addingApps: true,
        addAppError: {},
        addedApps: [],
      };
    case userRoleActions.INSERT_USER_ROLE_APPS_SUCCESS:
      return {
        ...state,
        addingApps: false,
        addedApps: actions.data,
      };
    case userRoleActions.INSERT_USER_ROLE_APPS_ERROR:
      return {
        ...state,
        addingApps: false,
        addAppError: actions.error,
      };

    case userRoleActions.DROP_USER_ROLE_APPS_REQUEST:
      return {
        ...state,
        droppingRoleApps: true,
        dropRoleAppError: {},
        dropRoleApps: {},
      };
    case userRoleActions.DROP_USER_ROLE_APPS_SUCCESS:
      return {
        ...state,
        droppingRoleApps: false,
        dropRoleApps: actions.data,
      };
    case userRoleActions.DROP_USER_ROLE_APPS_ERROR:
      return {
        ...state,
        droppingRoleApps: false,
        dropRoleAppError: actions.error,
      };

    case userRoleActions.DROP_USER_ROLE_FUNCTIONS_REQUEST:
      return {
        ...state,
        droppingAppFunctions: true,
        dropRoleId: actions.userRoleId,
        droppedAppFunctionSuccess: {},
        dropAppFunctionError: {},
      };
    case userRoleActions.DROP_USER_ROLE_FUNCTIONS_SUCCESS:
      return {
        ...state,
        droppingAppFunctions: false,
        droppedAppFunctionSuccess: actions.data,
      };
    case userRoleActions.DROP_USER_ROLE_FUNCTIONS_ERROR:
      return {
        ...state,
        droppingAppFunctions: false,
        dropAppFunctionError: actions.error,
      };

    case userRoleActions.ASSIGN_ROLE_USER_REQUEST:
      return {
        ...state,
        assigningUserRoles: true,
        assignUserRoleError: {},
        assignUserRoleSuccess: {},
      };
    case userRoleActions.ASSIGN_ROLE_USER_SUCCESS:
      return {
        ...state,
        assigningUserRoles: false,
        assignUserRoleSuccess: actions.data,
      };

    case userRoleActions.ASSIGN_ROLE_USER_ERROR:
      return {
        ...state,
        assigningUserRoles: false,
        assignUserRoleError: actions.error,
      };

    case userRoleActions.REMOVE_USER_ROLES_REQUEST:
      return {
        ...state,
        removingUserRoles: true,
        removeUserRoleError: {},
        removeUserRoleSuccess: {},
      };
    case userRoleActions.REMOVE_USER_ROLES_SUCCESS:
      return {
        ...state,
        removingUserRoles: false,
        removeUserRoleSuccess: actions.data,
      };
    case userRoleActions.REMOVE_USER_ROLES_ERROR:
      return {
        ...state,
        removingUserRoles: false,
        removeUserRoleError: actions.error,
      };
    case userRoleActions.SHOW_REMOVE_USER_ROLE_MODAL:
      return {
        ...state,
        showRemoveUserRoleModal: actions.payload,
      };

    case userRoleActions.UPDATE_ACCESS_DOMAIN_REQUEST:
      return {
        ...state,
        updatingAccessDomain: true,
        updateAccessDomainError: {},
      };
    case userRoleActions.UPDATE_ACCESS_DOMAIN_SUCCESS:
      return {
        ...state,
        updatingAccessDomain: false,
        updatedAccessDomain: actions.data,
      };
    case userRoleActions.UPDATE_ACCESS_DOMAIN_ERROR:
      return {
        ...state,
        updatingAccessDomain: false,
        updateAccessDomainError: actions.error,
      };

    case userRoleActions.GET_GROUP_USER_ROLE_BOUND_REQUEST:
      return {
        ...state,
        gettingUserRoleBounds: true,
        userRoleBoundError: {},
      };
    case userRoleActions.GET_GROUP_USER_ROLE_BOUND_SUCCESS: {
      const { userRoleBounds } = state;
      const { roleId, userId, data } = actions;
      const newData = { userId, roleId, data };

      const findBoundIndex = findIndex(
        userRoleBounds,
        (bound) => bound.roleId === roleId && bound.userId === userId
      );

      let newRoleBounds = userRoleBounds;

      if (findBoundIndex === -1) {
        newRoleBounds = update(userRoleBounds, { $push: [newData] });
      } else {
        newRoleBounds = update(userRoleBounds, {
          [findBoundIndex]: { $set: newData },
        });
      }

      return {
        ...state,
        userRoleBounds: newRoleBounds,
        gettingUserRoleBounds: false,
      };
    }
    case userRoleActions.GET_GROUP_USER_ROLE_BOUND_ERROR:
      return {
        ...state,
        gettingUserRoleBounds: false,
        userRoleBoundError: actions.error,
      };

    default:
      return state;
  }
}

export default userRole;
