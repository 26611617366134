import axios from 'axios';
import FileSaver from 'file-saver';
import { kebabCase } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import admissionStudentService from '../../actions/admission-mgt/admissionStudentService';

function* getStudentServices(actions) {
  try {
    const { context, service } = actions;

    const response = yield axios({
      url: `/admissions/${service}`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: admissionStudentService.GET_STUDENTS_SERVICES_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.GET_STUDENTS_SERVICES_ERROR,
      error: error.data,
    });
  }
}

function* getServiceProgrammes() {
  try {
    const response = yield axios({
      url: '/admissions/change-of-programmes/programmes',
      method: 'GET',
    });
    yield put({
      type: admissionStudentService.GET_SERVICE_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.GET_SERVICE_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getServiceEvent(actions) {
  try {
    const { service, studentId } = actions;
    const response = yield axios({
      url: `/admissions/change-of-programmes/event/${studentId}`,
      method: 'GET',
      params: { service: kebabCase(service) },
    });
    yield put({
      type: admissionStudentService.GET_SERVICES_EVENT_SUCCESS,
      data: response.data,
      studentId,
      service,
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.GET_SERVICES_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* addAdmissionSchemeData(actions) {
  try {
    const response = yield axios({
      url: `/admissions/change-of-programmes/create/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: admissionStudentService.ADD_STUDENTS_SERVICES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionStudentService.GET_STUDENTS_SERVICES_REQUEST,
    });
    yield put({
      type: admissionStudentService.SET_SHOW_CREATE_FORM,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.ADD_STUDENTS_SERVICES_ERROR,
      error: error.data,
    });
  }
}

function* approveServiceRequests(actions) {
  try {
    const response = yield axios({
      url: `/admissions/change-of-programmes/approve`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: admissionStudentService.APPROVE_SERVICE_REQUESTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionStudentService.GET_STUDENTS_SERVICES_REQUEST,
    });
    yield put({
      type: admissionStudentService.SET_SHOW_CREATE_FORM,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.APPROVE_SERVICE_REQUESTS_ERROR,
      error: error.data,
    });
  }
}

function* editAcademicYear(actions) {
  try {
    const { context, service } = actions;
    const response = yield axios({
      url: `/admissions/change-of-programmes/edit`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: admissionStudentService.EDIT_SERVICE_ACADEMIC_YEAR_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionStudentService.GET_STUDENTS_SERVICES_REQUEST,
      service,
      context,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: admissionStudentService.EDIT_SERVICE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* acceptOrDeclineServiceRequests(actions) {
  try {
    const response = yield axios({
      url: `/admissions/change-of-programmes/accept-or-decline`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: admissionStudentService.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionStudentService.GET_STUDENTS_SERVICES_REQUEST,
    });
    yield put({
      type: admissionStudentService.SET_SHOW_CREATE_FORM,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadServiceRequestReport(actions) {
  try {
    yield axios({
      url: `/admissions/change-of-programmes/download-report`,
      method: 'POST',
      data: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SERVICE-REQUEST-REPORTS.xlsx`
      );
    });
    yield put({
      type: admissionStudentService.DOWNLOAD_SERVICE_REQUEST_REPORT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admissionStudentService.DOWNLOAD_SERVICE_REQUEST_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetStudentServices() {
  yield takeLatest(
    admissionStudentService.GET_STUDENTS_SERVICES_REQUEST,
    getStudentServices
  );
}

function* watchAddAdmissionSchemeData() {
  yield takeLatest(
    admissionStudentService.ADD_STUDENTS_SERVICES_REQUEST,
    addAdmissionSchemeData
  );
}

function* watchApproveServiceRequests() {
  yield takeLatest(
    admissionStudentService.APPROVE_SERVICE_REQUESTS_REQUEST,
    approveServiceRequests
  );
}

function* watchEditAcademicYear() {
  yield takeLatest(
    admissionStudentService.EDIT_SERVICE_ACADEMIC_YEAR_REQUEST,
    editAcademicYear
  );
}

function* watchAcceptOrDeclineServiceRequests() {
  yield takeLatest(
    admissionStudentService.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_REQUEST,
    acceptOrDeclineServiceRequests
  );
}

function* watchGetServiceEvent() {
  yield takeLatest(
    admissionStudentService.GET_SERVICES_EVENT_REQUEST,
    getServiceEvent
  );
}

function* watchGetServiceProgrammes() {
  yield takeLatest(
    admissionStudentService.GET_SERVICE_PROGRAMMES_REQUEST,
    getServiceProgrammes
  );
}

function* watchDownloadServiceRequestReport() {
  yield takeLatest(
    admissionStudentService.DOWNLOAD_SERVICE_REQUEST_REPORT_REQUEST,
    downloadServiceRequestReport
  );
}

export default [
  fork(watchGetStudentServices),
  fork(watchGetServiceProgrammes),
  fork(watchApproveServiceRequests),
  fork(watchEditAcademicYear),
  fork(watchAcceptOrDeclineServiceRequests),
  fork(watchGetServiceEvent),
  fork(watchAddAdmissionSchemeData),
  fork(watchDownloadServiceRequestReport),
];
