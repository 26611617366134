const systemApps = {
  GET_SYSTEM_APP_REQUEST: 'GET_SYSTEM_APP_REQUEST',
  GET_SYSTEM_APP_SUCCESS: 'GET_SYSTEM_APP_SUCCESS',
  GET_SYSTEM_APP_ERROR: 'GET_SYSTEM_APP_ERROR',

  CREATE_SYSTEM_APP_SUCCESS: 'CREATE_SYSTEM_APP_SUCCESS',
  CREATE_SYSTEM_APP_REQUEST: 'CREATE_SYSTEM_APP_REQUEST',
  CREATE_SYSTEM_APP_ERROR: 'CREATE_SYSTEM_APP_ERROR',

  getSystemApps: (data) => ({
    type: systemApps.GET_SYSTEM_APP_REQUEST,
    data,
  }),

  createApp: (data) => ({
    type: systemApps.CREATE_SYSTEM_APP_REQUEST,
    data,
  }),
};

export default systemApps;
