import { documentSettingActions } from '../../actions';
import initialState from '../../initialState';

const documentSetting = (state = initialState.documentSetting, actions) => {
  switch (actions.type) {
    case documentSettingActions.GET_DOCUMENT_SETTING_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case documentSettingActions.GET_DOCUMENT_SETTING_SUCCESS:
      return {
        ...state,
        documentSettings: actions.data || [],
        loading: false,
      };
    case documentSettingActions.GET_DOCUMENT_SETTING_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };
    case documentSettingActions.STUDENT_DOCUMENT_PREVIEW_REQUEST:
      return {
        ...state,
        loadPreviewError: {},
        loadingPreview: true,
      };
    case documentSettingActions.STUDENT_DOCUMENT_PREVIEW_SUCCESS: {
      const { category, data, params } = actions;

      return {
        ...state,
        documentPreview: { category, data, params },
        loadingPreview: false,
      };
    }

    case documentSettingActions.STUDENT_DOCUMENT_PREVIEW_ERROR: {
      const { error, params, category } = actions;
      return {
        ...state,
        loadPreviewError: { error, params, category },
        loadingPreview: false,
      };
    }

    case documentSettingActions.CREATE_DOCUMENT_SETTING_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case documentSettingActions.CREATE_DOCUMENT_SETTING_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case documentSettingActions.CREATE_DOCUMENT_SETTING_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    default:
      return state;
  }
};

export default documentSetting;
