import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { notificationActions } from '../../actions';

function* getReport(actions) {
  try {
    const { context, category } = actions;
    const response = yield axios({
      url: `/bi/notifications/${category}`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: notificationActions.GET_REPORT_SUCCESS,
      data: response.data,
      context,
      category,
    });
  } catch (error) {
    yield put({
      type: notificationActions.GET_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetReport() {
  yield takeLatest(notificationActions.GET_REPORT_REQUEST, getReport);
}

export default [fork(watchGetReport)];
