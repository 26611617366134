import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { semesterEventActions } from '../../actions';

function* getCurrentStudentSemesterEvent(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/registration/enrollments/current-semester-events/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_SUCCESS,
      data: response.data,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetCurrentStudentSemesterEvents() {
  yield takeLatest(
    semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
    getCurrentStudentSemesterEvent
  );
}

export default [fork(watchGetCurrentStudentSemesterEvents)];
