const applicationHistory = {
  GET_ADMISSION_HISTORY_REQUEST: 'GET_ADMISSION_HISTORY_REQUEST',
  GET_ADMISSION_HISTORY_SUCCESS: 'GET_ADMISSION_HISTORY_SUCCESS',
  GET_ADMISSION_HISTORY_ERROR: 'GET_ADMISSION_HISTORY_ERROR',

  GET_SINGLE_ADMISSION_HISTORY_REQUEST: 'GET_SINGLE_ADMISSION_HISTORY_REQUEST',
  GET_SINGLE_ADMISSION_HISTORY_SUCCESS: 'GET_SINGLE_ADMISSION_HISTORY_SUCCESS',
  GET_SINGLE_ADMISSION_HISTORY_ERROR: 'GET_SINGLE_ADMISSION_HISTORY_ERROR',

  UPDATE_ADMISSION_HISTORY_SUCCESS: 'UPDATE_ADMISSION_HISTORY_SUCCESS',
  UPDATE_ADMISSION_HISTORY_REQUEST: 'UPDATE_ADMISSION_HISTORY_REQUEST',
  UPDATE_ADMISSION_HISTORY_ERROR: 'UPDATE_ADMISSION_HISTORY_ERROR',

  DELETE_ADMISSION_HISTORY_REQUEST: 'DELETE_ADMISSION_HISTORY_REQUEST',
  DELETE_ADMISSION_HISTORY_SUCCESS: 'DELETE_ADMISSION_HISTORY_SUCCESS',
  DELETE_ADMISSION_HISTORY_ERROR: 'DELETE_ADMISSION_HISTORY_ERROR',

  DOWNLOAD_ADMISSION_HISTORY_REQUEST: 'DOWNLOAD_ADMISSION_HISTORY_REQUEST',
  DOWNLOAD_ADMISSION_HISTORY_SUCCESS: 'DOWNLOAD_ADMISSION_HISTORY_SUCCESS',
  DOWNLOAD_ADMISSION_HISTORY_ERROR: 'DOWNLOAD_ADMISSION_HISTORY_ERROR',

  UPLOAD_ADMISSION_HISTORY_REQUEST: 'UPLOAD_ADMISSION_HISTORY_REQUEST',
  UPLOAD_ADMISSION_HISTORY_SUCCESS: 'UPLOAD_ADMISSION_HISTORY_SUCCESS',
  UPLOAD_ADMISSION_HISTORY_ERROR: 'UPLOAD_ADMISSION_HISTORY_ERROR',

  getApplicationHistory: (context) => ({
    type: applicationHistory.GET_ADMISSION_HISTORY_REQUEST,
    context,
  }),

  getOneApplicationHistory: (data) => ({
    type: applicationHistory.GET_SINGLE_ADMISSION_HISTORY_REQUEST,
    data,
  }),

  updateApplicationHistory: (data, id) => ({
    type: applicationHistory.UPDATE_ADMISSION_HISTORY_REQUEST,
    data,
    id,
  }),

  deleteApplicationHistory: (id) => ({
    type: applicationHistory.DELETE_ADMISSION_HISTORY_REQUEST,
    id,
  }),

  downloadApplicationHistoryTemplate: () => ({
    type: applicationHistory.DOWNLOAD_ADMISSION_HISTORY_REQUEST,
  }),

  uploadApplicationHistoryTemplate: (data) => ({
    type: applicationHistory.UPLOAD_ADMISSION_HISTORY_REQUEST,
    data,
  }),
};

export default applicationHistory;
