import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { creditNoteActions } from '../../actions';

function* getCreditNotes() {
  try {
    const response = yield axios({
      url: `/registration/invoices/credit-notes/all-approved`,
      method: 'GET',
    });
    yield put({
      type: creditNoteActions.GET_CREDIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.GET_CREDIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* getCreditNoteReport(actions) {
  const { start, end } = actions.data;
  try {
    const response = yield axios({
      url: `/registration/invoices/credit-notes/report?date_from=${start}&date_to=${end}`,
      method: 'GET',
    });
    yield put({
      type: creditNoteActions.GET_CREDIT_NOTE_REPORTS_SUCCESS,
      data: response.result,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.GET_CREDIT_NOTE_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getPendingCreditNotes() {
  try {
    const response = yield axios({
      url: `/registration/invoices/credit-notes/bulk-pending`,
      method: 'GET',
    });
    yield put({
      type: creditNoteActions.GET_PENDING_CREDIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.GET_PENDING_CREDIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* getStudentPendingCreditNotes(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/credit-notes/all-pending/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: creditNoteActions.GET_STUDENT_PENDING_CREDIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.GET_STUDENT_PENDING_CREDIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* createCreditNote(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/credit-notes/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: creditNoteActions.CREATE_CREDIT_NOTE_SUCCESS,
      data: response,
    });
    yield put({
      type: creditNoteActions.GET_PENDING_CREDIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.CREATE_CREDIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingCreditNote(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/credit-notes/approve',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: creditNoteActions.APPROVE_CREDIT_NOTE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: creditNoteActions.GET_PENDING_CREDIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.APPROVE_CREDIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* declinePendingCreditNote(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/credit-notes/decline',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: creditNoteActions.DECLINE_CREDIT_NOTE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: creditNoteActions.GET_PENDING_CREDIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.DECLINE_CREDIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* downloadCreditNoteReport(actions) {
  try {
    yield axios({
      url: `/registration/invoices/credit-notes/download-report`,
      method: 'GET',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${actions?.params?.student || ''} CREDIT-NOTES-REPORT.xlsx`
      );
    });
    yield put({
      type: creditNoteActions.DOWNLOAD_CREDIT_NOTE_REPORT_SUCCESS,
      data: {
        server: { message: 'Report downloaded!', status: true },
      },
    });
  } catch (error) {
    yield put({
      type: creditNoteActions.DOWNLOAD_CREDIT_NOTE_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetCreditNotes() {
  yield takeLatest(creditNoteActions.GET_CREDIT_NOTE_REQUEST, getCreditNotes);
}

function* watchGetCreditNoteReport() {
  yield takeLatest(
    creditNoteActions.GET_CREDIT_NOTE_REPORTS_REQUEST,
    getCreditNoteReport
  );
}

function* watchGetPendingCreditNotes() {
  yield takeLatest(
    creditNoteActions.GET_PENDING_CREDIT_NOTE_REQUEST,
    getPendingCreditNotes
  );
}

function* watchGetStudentPendingCreditNotes() {
  yield takeLatest(
    creditNoteActions.GET_STUDENT_PENDING_CREDIT_NOTE_REQUEST,
    getStudentPendingCreditNotes
  );
}

function* watchCreateCreditNote() {
  yield takeLatest(
    creditNoteActions.CREATE_CREDIT_NOTE_REQUEST,
    createCreditNote
  );
}

function* watchApprovePendingCreditNote() {
  yield takeLatest(
    creditNoteActions.APPROVE_CREDIT_NOTE_REQUEST,
    approvePendingCreditNote
  );
}
function* watchDeclinePendingCreditNote() {
  yield takeLatest(
    creditNoteActions.DECLINE_CREDIT_NOTE_REQUEST,
    declinePendingCreditNote
  );
}

function* watchDownloadCreditNoteReport() {
  yield takeLatest(
    creditNoteActions.DOWNLOAD_CREDIT_NOTE_REPORT_REQUEST,
    downloadCreditNoteReport
  );
}

export default [
  fork(watchGetCreditNotes),
  fork(watchGetCreditNoteReport),
  fork(watchGetPendingCreditNotes),
  fork(watchGetStudentPendingCreditNotes),
  fork(watchCreateCreditNote),
  fork(watchApprovePendingCreditNote),
  fork(watchDeclinePendingCreditNote),
  fork(watchDownloadCreditNoteReport),
];
