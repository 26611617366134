const feeInitialState = {
  feesElements: {
    feesElements: [],
    created: {},
    deleteError: {},
    updateError: {},
    downloadElementsError: {},
    uploadElementsError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    downloadElementsResponse: {},
    uploadElementsResponse: {},
    creating: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    downloading: false,
    uploading: false,

    copying: false,
    feesCopyResponse: {},
    feesCopyError: {},
  },
  tuition: {
    tuition: [],
    tuitionContext: {},
    selectedProgramme: {},
    created: {},
    createError: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    addingElementSuccessResponse: {},
    addingElement: false,
    creating: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    downloadingTemplate: false,
    errorDownloadingTemplate: {},
    successDownloadingTemplate: {},

    uploading: false,
    uploadError: {},
    uploadSuccess: {},

    gettingExemptions: false,
    tuitionExemptions: [],
    tuitionExemptionsError: {},

    addingTuitionExemptions: false,
    addedTuitionExemptions: [],
    addTuitionExemptionsError: {},

    deletingTuitionExemption: false,
    deletedTuitionExemption: {},
    deleteTuitionExemptionsError: {},
  },
  // Functional fees initial state.
  functionalFees: {
    functionalFees: [],
    uniqueFunctionalFees: [],
    functionalFeesElements: [],
    selectedTypeAmounts: [],
    functionalFeesContext: {},
    uniqueFunctionalFeesContext: {},
    feesPreview: {},
    created: {},
    previewError: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    addingElementSuccessResponse: {},
    addingElement: false,
    creating: false,
    loading: false,
    loadingPreview: false,
    fetching: false,
    updating: false,
    deleting: false,

    downloadTemplateSuccess: {},
    downloadTemplateError: {},
    downloadingTemplate: false,

    dataToEdit: {},
  },
  // Fees waivers initial state
  feesWaiver: {
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    updating: false,
    feesWaiver: [],
    feesWaiversContext: {},
    error: {},
    created: {},
    deleteError: {},
    updateError: {},
    deleteResponse: {},
  },

  // This is initial state for other otherFees.

  otherFees: {
    otherFees: [],
    otherFeesElements: [],
    otherFeesContext: {},
    pendingContext: {},
    loadedFees: new Map(),
    filteredOtherFees: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    addingElementSuccessResponse: {},
    addingElement: false,
    creating: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    fetchingByContext: false,
    updatingAmount: false,
    deletingAmount: false,
  },
  // This is initial state for other otherFees.

  waiverDiscounts: {
    waiverDiscounts: [],
    waiverDiscountsContext: {},
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    addingElementSuccessResponse: {},
    addingElement: false,
    editingElementSuccessResponse: {},
    editingElement: false,
    creating: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },

  feesWaivers: {
    feesWaivers: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    pendingContext: {},
    creating: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  feesApprovals: {
    feesToApprove: [],
    pendingFunctionalFees: [],
    pendingOtherFees: [],
    pendingFeesWaivers: [],
    pendingStudentFeesWaivers: [],
    pendingFeesCopy: [],
    loading: false,
    gettingPendingFees: false,
    gettingPendingOtherFees: false,
    gettingPendingFeesWaivers: false,
    gettingPendingStudentFeesWaivers: false,
    approving: false,
    loadError: {},
    approveError: {},
    approveSuccess: {},

    gettingPendingTuitionFees: false,
    pendingTuitionFees: [],
    getPendingError: {},

    approveFunctionalError: {},
    approvalFunctionalResponse: {},
  },
  graduationFees: {
    gettingGraduationFees: false,
    graduationFees: [],
    graduationFeesError: {},

    creatingFee: false,
    createdFee: {},
    createError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    deletingElement: false,
    deleteElementResponse: {},
    deleteElementError: {},

    graduationFeesContext: {},
    graduationFeesTab: 'view',
  },
};
export default feeInitialState;
