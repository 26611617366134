import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { academicRegistrarActions } from '../../actions';
import initialState from '../../initialState';

const academicRegistrar = (state = initialState.academicRegistrar, actions) => {
  switch (actions.type) {
    case academicRegistrarActions.SET_GRADUATION_CONTEXT:
      return {
        ...state,
        graduationContext: actions.context,
      };
    case academicRegistrarActions.GET_GRADUATION_STATS_REPORT_REQUEST:
      return {
        ...state,
        gettingGraduationStats: true,
        graduationStatsError: {},
      };
    case academicRegistrarActions.GET_GRADUATION_STATS_REPORT_SUCCESS: {
      const { graduationStats } = state;
      const { context } = actions;
      const findContextIndex = findIndex(
        graduationStats,
        (report) =>
          report.academic_year_id === context.academic_year_id &&
          report.report_category === context.report_category
      );

      const contextData = { ...context, data: actions.data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          graduationStats: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          graduationStats: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingGraduationStats: false,
      };
    }
    case academicRegistrarActions.GET_GRADUATION_STATS_REPORT_ERROR:
      return {
        ...state,
        graduationStatsError: actions.error,
        gettingGraduationStats: false,
      };

    case academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_REQUEST:
      return {
        ...state,
        gettingGraduationReport: true,
        graduationReportError: {},
      };
    case academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_SUCCESS: {
      const { graduationReport } = state;
      const { context } = actions;
      const findContextIndex = findIndex(
        graduationReport,
        (report) =>
          report.academic_year_id === context.academic_year_id &&
          report.report_category === context.report_category
      );

      const contextData = { ...context, data: actions.data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          graduationReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          graduationReport: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingGraduationReport: false,
      };
    }
    case academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_ERROR:
      return {
        ...state,
        graduationReportError: actions.error,
        gettingGraduationReport: false,
      };

    case academicRegistrarActions.GET_ENROLLMENT_REPORT_REQUEST:
      return {
        ...state,
        gettingEnrollmentReport: true,
        enrollmentReportError: {},
      };
    case academicRegistrarActions.GET_ENROLLMENT_REPORT_SUCCESS: {
      const { enrollmentReport } = state;
      const { context } = actions;
      const findContextIndex = findIndex(
        enrollmentReport,
        (report) => report.academic_year_id === context.academic_year_id
      );

      const contextData = { ...context, data: actions.data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          enrollmentReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          enrollmentReport: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingEnrollmentReport: false,
      };
    }
    case academicRegistrarActions.GET_ENROLLMENT_REPORT_ERROR:
      return {
        ...state,
        enrollmentReportError: actions.error,
        gettingEnrollmentReport: false,
      };

    case academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_REQUEST:
      return {
        ...state,
        gettingDemographicReport: true,
        demographicReportError: {},
      };
    case academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_SUCCESS: {
      const { demographicReport } = state;
      const { context } = actions;
      const findContextIndex = findIndex(
        demographicReport,
        (report) =>
          report.academic_year_id === context.academic_year_id &&
          report.semester_id === context.semester_id
      );

      const contextData = { ...context, data: actions.data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          demographicReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          demographicReport: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingDemographicReport: false,
      };
    }
    case academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_ERROR:
      return {
        ...state,
        gettingDemographicReport: false,
        demographicReportError: actions.error,
      };

    case academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
      };
    case academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_SUCCESS:
      return {
        ...state,
        downloadingReport: false,
        downloadedReport: actions.data,
      };
    case academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_ERROR:
      return {
        ...state,
        downloadingReport: false,
        downloadError: actions.error,
      };

    case academicRegistrarActions.SET_DEMOGRAPHIC_REPORT_CONTEXT_REQUEST:
      return {
        ...state,
        downloadContext: actions.data,
      };

    case academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
      };
    case academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_SUCCESS:
      return {
        ...state,
        downloadedReport: actions.data,
        downloadingReport: false,
      };
    case academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloadingReport: false,
      };

    case academicRegistrarActions.DOWNLOAD_SPONSORSHIP_GRADUATION_STATISTICS_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
      };
    case academicRegistrarActions.DOWNLOAD_SPONSORSHIP_GRADUATION_STATISTICS_SUCCESS:
      return {
        ...state,
        downloadedReport: actions.data,
        downloadingReport: false,
      };
    case academicRegistrarActions.DOWNLOAD_SPONSORSHIP_GRADUATION_STATISTICS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloadingReport: false,
      };

    case academicRegistrarActions.DOWNLOAD_GRADUATION_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
      };
    case academicRegistrarActions.DOWNLOAD_GRADUATION_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        downloadedReport: actions.data,
        downloadingReport: false,
      };
    case academicRegistrarActions.DOWNLOAD_GRADUATION_REPORT_DETAILS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloadingReport: false,
      };

    default:
      return state;
  }
};

export default academicRegistrar;
