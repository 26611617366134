import { waiverDiscountActions } from '../../actions';
import initialState from '../../initialState';

const waiverDiscounts = (state = initialState.waiverDiscounts, actions) => {
  switch (actions.type) {
    case waiverDiscountActions.GET_WAIVER_DISCOUNTS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case waiverDiscountActions.GET_WAIVER_DISCOUNTS_SUCCESS:
      return {
        ...state,
        waiverDiscounts: actions.data.waiverDiscounts,
        error: {},
        loading: false,
      };
    case waiverDiscountActions.GET_WAIVER_DISCOUNTS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case waiverDiscountActions.CREATE_WAIVER_DISCOUNTS_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };
    case waiverDiscountActions.CREATE_WAIVER_DISCOUNTS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case waiverDiscountActions.CREATE_WAIVER_DISCOUNTS_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case waiverDiscountActions.ADD_WAIVER_ELEMENTS_REQUEST:
      return {
        ...state,
        addingElement: true,
        error: {},
      };
    case waiverDiscountActions.ADD_WAIVER_ELEMENTS_SUCCESS:
      return {
        ...state,
        addingElement: false,
        addingElementSuccessResponse: actions.data,
      };
    case waiverDiscountActions.ADD_WAIVER_ELEMENTS_ERROR:
      return {
        ...state,
        addingElement: false,
        error: actions.error,
      };

    case waiverDiscountActions.EDIT_WAIVER_DISCOUNTS_REQUEST:
      return {
        ...state,
        editingElement: true,
        error: {},
      };
    case waiverDiscountActions.EDIT_WAIVER_DISCOUNTS_SUCCESS:
      return {
        ...state,
        editingElement: false,
        editingElementSuccessResponse: actions.data,
      };
    case waiverDiscountActions.EDIT_WAIVER_DISCOUNTS_ERROR:
      return {
        ...state,
        editingElement: false,
        error: actions.error,
      };

    case waiverDiscountActions.DELETE_WAIVER_DISCOUNTS_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case waiverDiscountActions.DELETE_WAIVER_DISCOUNTS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case waiverDiscountActions.DELETE_WAIVER_DISCOUNTS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case waiverDiscountActions.UPDATE_WAIVER_DISCOUNTS_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case waiverDiscountActions.UPDATE_WAIVER_DISCOUNTS_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case waiverDiscountActions.UPDATE_WAIVER_DISCOUNTS_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case waiverDiscountActions.SET_WAIVER_DISCOUNTS_CONTEXT:
      return {
        ...state,
        waiverDiscountsContext: actions.context,
      };

    default:
      return state;
  }
};
// registers to index.js of the reducer
export default waiverDiscounts;
