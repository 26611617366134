const studentGradeActions = {
  GENERATE_STUDENT_GRADES_REQUEST: 'GENERATE_STUDENT_GRADES_REQUEST',
  GENERATE_STUDENT_GRADES_SUCCESS: 'GENERATE_STUDENT_GRADES_SUCCESS',
  GENERATE_STUDENT_GRADES_ERROR: 'GENERATE_STUDENT_GRADES_ERROR',

  GENERATE_SINGLE_STUDENT_GRADES_REQUEST:
    'GENERATE_SINGLE_STUDENT_GRADES_REQUEST',
  GENERATE_SINGLE_STUDENT_GRADES_SUCCESS:
    'GENERATE_SINGLE_STUDENT_GRADES_SUCCESS',
  GENERATE_SINGLE_STUDENT_GRADES_ERROR: 'GENERATE_SINGLE_STUDENT_GRADES_ERROR',

  GET_STUDENT_GRADES_REQUEST: 'GET_STUDENT_GRADES_REQUEST',
  GET_STUDENT_GRADES_SUCCESS: 'GET_STUDENT_GRADES_SUCCESS',
  GET_STUDENT_GRADES_ERROR: 'GET_STUDENT_GRADES_ERROR',

  SET_SELECTED_STUDENT_GRADE_CONTEXT: 'SET_SELECTED_STUDENT_GRADE_CONTEXT',
  SET_SELECTED_STUDENT_GRADE_PROGRAMME: 'SET_SELECTED_STUDENT_GRADE_PROGRAMME',

  getStudentGrades: (context) => ({
    type: studentGradeActions.GET_STUDENT_GRADES_REQUEST,
    context,
  }),

  generateGrades: (context) => ({
    type: studentGradeActions.GENERATE_STUDENT_GRADES_REQUEST,
    context,
  }),

  generateStudentGrades: (context) => ({
    type: studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_REQUEST,
    context,
  }),

  setSelectedContext: (context) => ({
    type: studentGradeActions.SET_SELECTED_STUDENT_GRADE_CONTEXT,
    context,
  }),

  setSelectedProgramme: (data) => ({
    type: studentGradeActions.SET_SELECTED_STUDENT_GRADE_PROGRAMME,
    data,
  }),
};

export default studentGradeActions;
