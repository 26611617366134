import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { financialStatementActions } from '../../actions';

function* getFinancialStatement(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/universal-payments/reports/financial-statement/${studentProgrammeId}`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: financialStatementActions.GET_FINANCIAL_STATEMENT_SUCCESS,
      data: response.data,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.GET_FINANCIAL_STATEMENT_ERROR,
      error: error.data,
    });
  }
}

function* getStudentAccountStatement(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/universal-payments/reports/student-account-statement/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_SUCCESS,
      data: response.data,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_ERROR,
      error: error.data,
    });
  }
}

function* getPendingPushedStudentCredit() {
  try {
    const response = yield axios({
      url: `universal-payments/reports/pending-pushed-payments`,
      method: 'GET',
    });
    yield put({
      type: financialStatementActions.GET_PENDING_PUSHED_CREDIT_SUCCESS,
      data: response.payments,
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.GET_PENDING_PUSHED_CREDIT_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingPushedStudentCredit(actions) {
  try {
    const { studentProgrammeId } = actions;
    const response = yield axios({
      url: `/universal-payments/reports/approve-pushed-payments`,
      data: actions.data,
      method: 'POST',
    });
    yield put({
      type: financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_SUCCESS,
      data: response.data,
      studentProgrammeId,
    });
    yield put({
      type: financialStatementActions.GET_PENDING_PUSHED_CREDIT_REQUEST,
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_ERROR,
      error: error.data,
    });
  }
}

function* pushStudentCredit(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/add-deallocated-credit`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* downloadFinancialStatement(actions) {
  try {
    const { studentProgrammeId } = actions;
    yield axios({
      url: `/universal-payments/reports/pdf-financial-statement/${studentProgrammeId}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${actions?.params?.student || ''} STUDENT-FINANCIAL-STATEMENT.pdf`
      );
    });
    yield put({
      type: financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS,
      data: {
        server: { message: 'Financial Statement downloaded!', status: true },
      },
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetFinancialStatement() {
  yield takeLatest(
    financialStatementActions.GET_FINANCIAL_STATEMENT_REQUEST,
    getFinancialStatement
  );
}

function* watchGetStudentAccountStatement() {
  yield takeLatest(
    financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_REQUEST,
    getStudentAccountStatement
  );
}

function* watchPushStudentCredit() {
  yield takeLatest(
    financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_REQUEST,
    pushStudentCredit
  );
}

function* watchGetPendingPushedStudentCredit() {
  yield takeLatest(
    financialStatementActions.GET_PENDING_PUSHED_CREDIT_REQUEST,
    getPendingPushedStudentCredit
  );
}

function* watchApprovePendingStudentCredit() {
  yield takeLatest(
    financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_REQUEST,
    approvePendingPushedStudentCredit
  );
}

function* watchDownloadFinancialStatement() {
  yield takeLatest(
    financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_REQUEST,
    downloadFinancialStatement
  );
}

export default [
  fork(watchPushStudentCredit),
  fork(watchGetFinancialStatement),
  fork(watchGetStudentAccountStatement),
  fork(watchDownloadFinancialStatement),
  fork(watchGetPendingPushedStudentCredit),
  fork(watchApprovePendingStudentCredit),
];
