import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { admissionFeesPolicyActions, tabActions } from '../../actions';

function* getAdmissionFeesPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/admission-fees-policies',
      method: 'GET',
    });
    yield put({
      type: admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createAdmissionFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/admission-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateAdmissionFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/admission-fees-policies/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteAdmissionFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/admission-fees-policies/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdmissionFeesPolicies() {
  yield takeLatest(
    admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_REQUEST,
    getAdmissionFeesPolicies
  );
}

function* watchCreateAdmissionFeesPolicies() {
  yield takeLatest(
    admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_REQUEST,
    createAdmissionFeesPolicies
  );
}

function* watchUpdateAdmissionFeesPolicies() {
  yield takeLatest(
    admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_REQUEST,
    updateAdmissionFeesPolicies
  );
}

function* watchDeleteAdmissionFeesPolicies() {
  yield takeLatest(
    admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_REQUEST,
    deleteAdmissionFeesPolicies
  );
}

export default [
  fork(watchGetAdmissionFeesPolicies),
  fork(watchCreateAdmissionFeesPolicies),
  fork(watchUpdateAdmissionFeesPolicies),
  fork(watchDeleteAdmissionFeesPolicies),
];
