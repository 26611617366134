import { otherFeesPolicyActions } from '../../actions';
import initialState from '../../initialState';

const otherFeesPolicy = (state = initialState.otherFeesPolicy, actions) => {
  switch (actions.type) {
    case otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_SUCCESS:
      return {
        ...state,
        otherFeesPolicies: actions.data.data,
        loading: false,
      };
    case otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };

    case otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_SUCCESS:
      return {
        ...state,
        otherFeesPolicy: actions.data.otherFeesPolicy,
        creating: false,
      };

    case otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };

    case otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };

    case otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        deleting: true,
      };

    case otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };

    case otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case otherFeesPolicyActions.SET_OTHER_FEES_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.otherFeesPolicy,
      };

    default:
      return state;
  }
};

export default otherFeesPolicy;
