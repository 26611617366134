import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { specialEntryReportActions } from '../../actions';

function* getSpecialEntryApplicantsReport(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/mature-age/running-admissions/applicants/report',
      method: 'GET',
      params,
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadSpecialEntryApplicantsReport(actions) {
  try {
    const { params } = actions;

    yield axios({
      url: `/mature-age/running-admissions/applicants/download-report`,
      method: 'POST',
      data: params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SEE-REPORT.xlsx`
      );
    });
    yield put({
      type: specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS,
      data: { message: 'Report Downloaded successfully' },
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR,
      error,
    });
  }
}

function* getSpecialEntryReportDetails(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/mature-age/running-admissions/applicants/report-details',
      method: 'GET',
      params,
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getResults(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/mature-age/running-admissions/applicants/results',
      method: 'GET',
      params,
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getAppeals(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/mature-age/running-admissions/applicants/appeals',
      method: 'GET',
      params,
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_ERROR,
      error: error.data,
    });
  }
}

function* getRoomAllocations(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/mature-age/running-admissions/applicants/room-allocations',
      method: 'GET',
      params,
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_ERROR,
      error: error.data,
    });
  }
}

function* getSpecialEntryPaymentReport(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/mature-age/running-admissions/applicants/payments-report',
      method: 'GET',
      params,
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeChoices(actions) {
  try {
    const { applicationId } = actions;

    const response = yield axios({
      url: `/mature-age/running-admissions/applicants/programme-choices/${applicationId}`,
      method: 'GET',
    });
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_SUCCESS,
      data: response.data,
      applicationId,
    });
  } catch (error) {
    yield put({
      type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSpecialEntryApplicantsReport() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST,
    getSpecialEntryApplicantsReport
  );
}

function* watchDownloadSpecialEntryApplicantsReport() {
  yield takeLatest(
    specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST,
    downloadSpecialEntryApplicantsReport
  );
}

function* watchGetSpecialEntryReportDetails() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_REQUEST,
    getSpecialEntryReportDetails
  );
}

function* watchGetSpecialEntryResults() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_REQUEST,
    getResults
  );
}

function* watchGetSpecialEntryAppeals() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_REQUEST,
    getAppeals
  );
}

function* watchGetSpecialEntryRoomAllocations() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_REQUEST,
    getRoomAllocations
  );
}

function* watchGetSpecialEntryPaymentReport() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_REQUEST,
    getSpecialEntryPaymentReport
  );
}

function* watchGetSpecialEntryProgrammeChoices() {
  yield takeLatest(
    specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_REQUEST,
    getProgrammeChoices
  );
}

export default [
  fork(watchGetSpecialEntryApplicantsReport),
  fork(watchGetSpecialEntryReportDetails),
  fork(watchGetSpecialEntryResults),
  fork(watchGetSpecialEntryAppeals),
  fork(watchGetSpecialEntryPaymentReport),
  fork(watchGetSpecialEntryRoomAllocations),
  fork(watchDownloadSpecialEntryApplicantsReport),
  fork(watchGetSpecialEntryProgrammeChoices),
];
