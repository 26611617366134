const specialEntryApplicantActions = {
  GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_APPLICANTS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_APPLICANTS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_APPLICANTS_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_APPLICANTS_ERROR',

  GET_PROPOSED_ADMISSIONS_REQUEST: 'GET_PROPOSED_ADMISSIONS_REQUEST',
  GET_PROPOSED_ADMISSIONS_SUCCESS: 'GET_PROPOSED_ADMISSIONS_SUCCESS',
  GET_PROPOSED_ADMISSIONS_ERROR: 'GET_PROPOSED_ADMISSIONS_ERROR',

  UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_REQUEST:
    'UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_REQUEST',
  UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_SUCCESS:
    'UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_SUCCESS',
  UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_ERROR:
    'UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_ERROR',

  UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST:
    'UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST',
  UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS:
    'UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS',
  UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR:
    'UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR',

  DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST:
    'DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST',
  DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS:
    'DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS',
  DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR:
    'DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR',

  UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
    'UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST',
  UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
    'UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS',
  UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
    'UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR',

  DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
    'DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST',
  DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
    'DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS',
  DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
    'DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR',

  getSpecialEntryApplicants: () => ({
    type: specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST,
  }),

  getProposedAdmissions: () => ({
    type: specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST,
  }),

  uploadApplicants: (data) => ({
    type: specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST,
    data,
  }),

  updateSpecialEntryApplicantAdmission: (id, data, applicantType) => ({
    type: specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_REQUEST,
    id,
    data,
    applicantType,
  }),

  downloadApplicantsTemplate: () => ({
    type: specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST,
  }),

  uploadProposedAdmissions: (data) => ({
    type: specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST,
    data,
  }),

  downloadProposedAdmissionsTemplate: () => ({
    type: specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST,
  }),
};

export default specialEntryApplicantActions;
