import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { buildingActions } from '../../actions';

function* addBuilding(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/buildings/create-building',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: buildingActions.ADD_BUILDING_SUCCESS,
      data: response.data,
    });
    yield put({
      type: buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST,
      campusId: actions.data.campus_id,
    });
    yield put({
      type: 'SHOW_BUILDING_MODAL',
      payload: 'viewBuilding',
    });
  } catch (error) {
    yield put({
      type: buildingActions.ADD_BUILDING_ERROR,
      error: error.data,
    });
  }
}

function* getBuildings(action) {
  try {
    const response = yield axios({
      url: `/course-assignment/buildings`,
      method: 'GET',
      params: action.params,
    });
    yield put({
      type: buildingActions.GET_BUILDINGS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: buildingActions.GET_BUILDINGS_ERROR,
      error: error.data,
    });
  }
}

function* getBuildingsByCampus(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/buildings/buildings-by-campus/${actions.campusId}`,
      method: 'GET',
    });
    yield put({
      type: buildingActions.GET_BUILDINGS_BY_CAMPUS_SUCCESS,
      data: response.data,
      campusId: actions.campusId,
    });
  } catch (error) {
    yield put({
      type: buildingActions.GET_BUILDINGS_BY_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* downloadBuildingsTemplate() {
  try {
    yield axios({
      url: '/course-assignment/buildings/download-buildings-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-BUILDINGS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: buildingActions.DOWNLOAD_BUILDINGS_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: buildingActions.DOWNLOAD_BUILDINGS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* downloadRoomsTemplate(actions) {
  try {
    yield axios({
      url: `/course-assignment/buildings/download-rooms-template/${actions.campusId}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ROOMS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: buildingActions.DOWNLOAD_ROOMS_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: buildingActions.DOWNLOAD_ROOMS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadBuildings(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/buildings/upload-buildings-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: buildingActions.UPLOAD_BUILDINGS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_BUILDING_MODAL',
      payload: 'viewBuilding',
    });
  } catch (error) {
    yield put({
      type: buildingActions.UPLOAD_BUILDINGS_ERROR,
      error: error.data,
    });
  }
}

function* uploadRooms(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/buildings/upload-rooms-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: buildingActions.UPLOAD_ROOMS_SUCCESS,
      data: response.data,
    });
    if (actions.campusId) {
      yield put({
        type: buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST,
        campusId: actions.campusId,
      });
    }
    yield put({
      type: 'SHOW_BUILDING_MODAL',
      payload: 'viewBuilding',
    });
    yield put({
      type: 'SHOW_ROOMS_MODAL',
      payload: null,
    });
  } catch (error) {
    yield put({
      type: buildingActions.UPLOAD_ROOMS_ERROR,
      error: error.data,
    });
  }
}

function* deleteBuilding(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/buildings/delete-building/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: buildingActions.DELETE_BUILDINGS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST,
      campusId: actions.campusId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: buildingActions.DELETE_BUILDINGS_ERROR,
      error: error.data,
    });
  }
}

function* deleteRoom(actions) {
  try {
    yield axios({
      url: `/course-assignment/buildings/delete-room/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: buildingActions.DELETE_ROOM_SUCCESS,
      deletedID: actions.id,
      campusID: actions.campusID,
    });
    yield put({
      type: 'SHOW_DELETE_ROOM',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: buildingActions.DELETE_ROOM_ERROR,
      error: error.data,
    });
  }
}

function* editBuilding(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/buildings/update-building/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: buildingActions.EDIT_BUILDINGS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST,
      campusId: actions.campusId,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: buildingActions.EDIT_BUILDINGS_ERROR,
      error: error.data,
    });
  }
}

function* editRoom(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/buildings/update-room/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: buildingActions.EDIT_ROOM_SUCCESS,
      data: response.data,
    });
    yield put({
      type: buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST,
      campusId: actions.campusId,
    });
    yield put({
      type: 'SHOW_EDIT_ROOM',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: buildingActions.EDIT_ROOM_ERROR,
      error: error.data,
    });
  }
}

function* getRoomAllocationReport(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/buildings/room-allocation-report',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: buildingActions.GET_ROOM_ALLOCATION_REPORT_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: buildingActions.GET_ROOM_ALLOCATION_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRoomAllocationReport() {
  yield takeLatest(
    buildingActions.GET_ROOM_ALLOCATION_REPORT_REQUEST,
    getRoomAllocationReport
  );
}

function* watchAddBuilding() {
  yield takeLatest(buildingActions.ADD_BUILDING_REQUEST, addBuilding);
}

function* watchGetBuildings() {
  yield takeLatest(buildingActions.GET_BUILDINGS_REQUEST, getBuildings);
}

function* watchGetBuildingsByCampus() {
  yield takeLatest(
    buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST,
    getBuildingsByCampus
  );
}

function* watchDownloadBuildingsTemplate() {
  yield takeLatest(
    buildingActions.DOWNLOAD_BUILDINGS_TEMPLATE_REQUEST,
    downloadBuildingsTemplate
  );
}

function* watchDownloadRoomsTemplate() {
  yield takeLatest(
    buildingActions.DOWNLOAD_ROOMS_TEMPLATE_REQUEST,
    downloadRoomsTemplate
  );
}

function* watchUploadBuildings() {
  yield takeLatest(buildingActions.UPLOAD_BUILDINGS_REQUEST, uploadBuildings);
}

function* watchUploadRooms() {
  yield takeLatest(buildingActions.UPLOAD_ROOMS_REQUEST, uploadRooms);
}

function* watchDeleteBuilding() {
  yield takeLatest(buildingActions.DELETE_BUILDINGS_REQUEST, deleteBuilding);
}

function* watchDeleteRoom() {
  yield takeLatest(buildingActions.DELETE_ROOM_REQUEST, deleteRoom);
}

function* watchEditBuilding() {
  yield takeLatest(buildingActions.EDIT_BUILDINGS_REQUEST, editBuilding);
}

function* watchEditRoom() {
  yield takeLatest(buildingActions.EDIT_ROOM_REQUEST, editRoom);
}

export default [
  fork(watchAddBuilding),
  fork(watchGetBuildings),
  fork(watchGetBuildingsByCampus),
  fork(watchUploadBuildings),
  fork(watchUploadRooms),
  fork(watchDeleteBuilding),
  fork(watchDeleteRoom),
  fork(watchEditBuilding),
  fork(watchDownloadBuildingsTemplate),
  fork(watchDownloadRoomsTemplate),
  fork(watchEditRoom),
  fork(watchGetRoomAllocationReport),
];
