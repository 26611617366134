import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { feesElementsActions } from '../../actions';

function* getFeesElements(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-elements',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: feesElementsActions.GET_FEES_ELEMENTS_SUCCESS,
      data: response.feesElements,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.GET_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getFeesPreview(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-amount-preview`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: feesElementsActions.GET_FEES_PREVIEW_SUCCESS,
      data: response.response,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.GET_FEES_PREVIEW_ERROR,
      error: error.data,
    });
  }
}

function* createFeesElements(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-elements',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: feesElementsActions.CREATE_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: feesElementsActions.GET_FEES_ELEMENTS_REQUEST,
    });
    yield put({
      type: 'SHOW_CREATE_FEES_ELEMENTS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.CREATE_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* deleteFeesElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-elements/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: feesElementsActions.DELETE_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: feesElementsActions.GET_FEES_ELEMENTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.DELETE_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}
function* updateFeesElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-elements/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: feesElementsActions.UPDATE_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: feesElementsActions.GET_FEES_ELEMENTS_REQUEST,
    });
    yield put({
      type: 'SHOW_CREATE_FEES_ELEMENTS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.UPDATE_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* makeFeesCopyByContext(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-amount-preview/fees-copy/create',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: feesElementsActions.MAKE_FEES_COPY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.MAKE_FEES_COPY_ERROR,
      error: error.data,
    });
  }
}

function* downloadFeesElementsTemplate() {
  try {
    yield axios({
      url: '/fees-mgt/fees-elements/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ELEMENTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadFeesElements(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-elements/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: feesElementsActions.UPLOAD_FEES_ELEMENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: feesElementsActions.GET_FEES_ELEMENTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DETAILS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesElementsActions.UPLOAD_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFeesElements() {
  yield takeLatest(
    feesElementsActions.GET_FEES_ELEMENTS_REQUEST,
    getFeesElements
  );
}

function* watchGetFeesPreview() {
  yield takeLatest(
    feesElementsActions.GET_FEES_PREVIEW_REQUEST,
    getFeesPreview
  );
}

function* watchCreateFeesElements() {
  yield takeLatest(
    feesElementsActions.CREATE_FEES_ELEMENTS_REQUEST,
    createFeesElements
  );
}

function* watchDeleteFeesElements() {
  yield takeLatest(
    feesElementsActions.DELETE_FEES_ELEMENTS_REQUEST,
    deleteFeesElements
  );
}
function* watchUpdateFeesElements() {
  yield takeLatest(
    feesElementsActions.UPDATE_FEES_ELEMENTS_REQUEST,
    updateFeesElements
  );
}
function* watchMakeFeesCopy() {
  yield takeLatest(
    feesElementsActions.MAKE_FEES_COPY_REQUEST,
    makeFeesCopyByContext
  );
}

function* watchDownloadFeesElementsTemplate() {
  yield takeLatest(
    feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_REQUEST,
    downloadFeesElementsTemplate
  );
}

function* watchUploadFeesElements() {
  yield takeLatest(
    feesElementsActions.UPLOAD_FEES_ELEMENTS_REQUEST,
    uploadFeesElements
  );
}

export default [
  fork(watchGetFeesElements),
  fork(watchGetFeesPreview),
  fork(watchCreateFeesElements),
  fork(watchDeleteFeesElements),
  fork(watchUpdateFeesElements),
  fork(watchMakeFeesCopy),
  fork(watchDownloadFeesElementsTemplate),
  fork(watchUploadFeesElements),
];
