import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { otherFeesActions, tabActions } from '../../actions';

function* getOtherFees(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/other-fees-amounts',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_SUCCESS,
      data: response.otherFeesAmounts,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}

function* getOtherFeesElements(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/other-fees-amounts/other-fees-elements',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_ELEMENTS_SUCCESS,
      data: response.otherFeesElements,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getOtherFeesByContext(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/other-fees-amounts/filter',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_SUCCESS,
      data: response.otherFeesAmounts,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_ERROR,
      error: error.data,
    });
  }
}

function* createOtherFees(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/other-fees-amounts',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: otherFeesActions.CREATE_OTHER_FEES_SUCCESS,
      data: response,
    });
    yield put({
      type: tabActions.OTHER_FEES_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.CREATE_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}

function* addOtherFeesElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/other-fees-amounts/add-element-amounts/${actions.contextId}`,
      method: 'POST',
      data: { otherFeesAmountFeesElements: actions.data },
    });
    yield put({
      type: otherFeesActions.ADD_OTHER_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_REQUEST,
      context: actions.context,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.ADD_OTHER_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* deleteOtherFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/other-fees-amounts/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: otherFeesActions.DELETE_OTHER_FEES_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.DELETE_OTHER_FEES_ERROR,
      error: error.data,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  }
}

function* deleteOtherFeesAmount(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/other-fees-amounts/delete-element-amount/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: otherFeesActions.DELETE_OTHER_FEES_AMOUNT_SUCCESS,
      data: response,
    });
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_REQUEST,
      context: actions.context,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.DELETE_OTHER_FEES_AMOUNT_ERROR,
      error: error.data,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  }
}
function* updateOtherFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/other-fees-amounts/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: otherFeesActions.UPDATE_OTHER_FEES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.UPDATE_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}
function* addOtherFeesToStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/other-fees-invoice`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: otherFeesActions.ADD_OTHER_FEES_TO_STUDENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.ADD_OTHER_FEES_TO_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* updateOtherFeesAmount(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/other-fees-amounts/update-element-amount/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_SUCCESS,
      data: response,
    });
    yield put({
      type: otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_REQUEST,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_ERROR,
      error: error.data,
    });
  }
}

function* downloadOtherFeesTemplate() {
  try {
    yield axios({
      url: '/fees-mgt/other-fees-amounts/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-OTHER-FEES-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: otherFeesActions.DOWNLOAD_OTHER_FEES_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.DOWNLOAD_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}

function* uploadOtherFees(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/other-fees-amounts/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: otherFeesActions.UPLOAD_OTHER_FEES_SUCCESS,
      data: response.data,
    });
    // if (!isEmpty(actions.context)) {
    //   yield put({
    //     type: otherFeesActions.GET_TUITION_REQUEST,
    //     context: actions.context,
    //   });
    // }
    yield put({
      type: 'SHOW_UPLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: otherFeesActions.UPLOAD_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetOtherFees() {
  yield takeLatest(otherFeesActions.GET_OTHER_FEES_REQUEST, getOtherFees);
}

function* watchGetOtherFeesElements() {
  yield takeLatest(
    otherFeesActions.GET_OTHER_FEES_ELEMENTS_REQUEST,
    getOtherFeesElements
  );
}

function* watchGetOtherFeesByContext() {
  yield takeLatest(
    otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_REQUEST,
    getOtherFeesByContext
  );
}

function* watchCreateOtherFees() {
  yield takeLatest(otherFeesActions.CREATE_OTHER_FEES_REQUEST, createOtherFees);
}

function* watchAddOtherFeesElements() {
  yield takeLatest(
    otherFeesActions.ADD_OTHER_FEES_ELEMENTS_REQUEST,
    addOtherFeesElements
  );
}

function* watchDeleteOtherFees() {
  yield takeLatest(otherFeesActions.DELETE_OTHER_FEES_REQUEST, deleteOtherFees);
}
function* watchUpdateOtherFees() {
  yield takeLatest(otherFeesActions.UPDATE_OTHER_FEES_REQUEST, updateOtherFees);
}
function* watchDeleteOtherFeesAmount() {
  yield takeLatest(
    otherFeesActions.DELETE_OTHER_FEES_AMOUNT_REQUEST,
    deleteOtherFeesAmount
  );
}
function* watchUpdateOtherFeesAmount() {
  yield takeLatest(
    otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_REQUEST,
    updateOtherFeesAmount
  );
}
function* watchAddOtherFeesToStudent() {
  yield takeLatest(
    otherFeesActions.ADD_OTHER_FEES_TO_STUDENT_REQUEST,
    addOtherFeesToStudent
  );
}

function* watchDownloadOtherFeesTemplate() {
  yield takeLatest(
    otherFeesActions.DOWNLOAD_OTHER_FEES_REQUEST,
    downloadOtherFeesTemplate
  );
}

function* watchUploadOtherFeesTemplate() {
  yield takeLatest(otherFeesActions.UPLOAD_OTHER_FEES_REQUEST, uploadOtherFees);
}

export default [
  fork(watchGetOtherFees),
  fork(watchGetOtherFeesElements),
  fork(watchGetOtherFeesByContext),
  fork(watchCreateOtherFees),
  fork(watchAddOtherFeesElements),
  fork(watchDeleteOtherFees),
  fork(watchUpdateOtherFees),
  fork(watchDeleteOtherFeesAmount),
  fork(watchUpdateOtherFeesAmount),
  fork(watchAddOtherFeesToStudent),
  fork(watchDownloadOtherFeesTemplate),
  fork(watchUploadOtherFeesTemplate),
];
