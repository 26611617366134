import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { studentSupportActions } from '../../actions';

function* generateStudentOTP(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/password-reset-token/${actions.data}`,
      method: 'POST',
    });
    yield put({
      type: studentSupportActions.GENERATE_STUDENT_OTP_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentSupportActions.GENERATE_STUDENT_OTP_ERROR,
      error: error.data,
    });
  }
}

function* fetchStudentForOTP(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-records/fetch-student?student=${actions.data}`,
      method: 'GET',
    });
    yield put({
      type: studentSupportActions.GET_STUDENT_FOR_OTP_SUCCESS,
      data: response.data || {},
    });
  } catch (error) {
    yield put({
      type: studentSupportActions.GET_STUDENT_FOR_OTP_ERROR,
      error: error.data,
    });
  }
}

function* fetchStaffForOTP(actions) {
  const { data } = actions;
  try {
    const response = yield axios({
      url: `/users/auth/user-search`,
      method: 'POST',
      data,
    });
    yield put({
      type: studentSupportActions.GET_STAFF_FOR_OTP_SUCCESS,
      data: response.user,
    });
  } catch (error) {
    yield put({
      type: studentSupportActions.GET_STAFF_FOR_OTP_ERROR,
      error: error.data,
    });
  }
}

function* updateStudentContacts(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/personal-details/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });

    yield put({
      type: studentSupportActions.UPDATE_STUDENT_CONTACTS_SUCCESS,
      data: response,
    });

    yield put({
      type: studentSupportActions.GET_STUDENT_FOR_OTP_SUCCESS,
      data: actions.currentFetchedStudentData || {},
    });
  } catch (error) {
    yield put({
      type: studentSupportActions.UPDATE_STUDENT_CONTACTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGenerateStudentOTP() {
  yield takeLatest(
    studentSupportActions.GENERATE_STUDENT_OTP_REQUEST,
    generateStudentOTP
  );
}

function* watchFetchStudentForOTP() {
  yield takeLatest(
    studentSupportActions.GET_STUDENT_FOR_OTP_REQUEST,
    fetchStudentForOTP
  );
}

function* watchFetchStaffForOTP() {
  yield takeLatest(
    studentSupportActions.GET_STAFF_FOR_OTP_REQUEST,
    fetchStaffForOTP
  );
}

function* watchUpdateStudentData() {
  yield takeLatest(
    studentSupportActions.UPDATING_STUDENT_CONTACTS_REQUEST,
    updateStudentContacts
  );
}

export default [
  fork(watchGenerateStudentOTP),
  fork(watchFetchStudentForOTP),
  fork(watchFetchStaffForOTP),
  fork(watchUpdateStudentData),
];
