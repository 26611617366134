const financialStatementActions = {
  GET_FINANCIAL_STATEMENT_REQUEST: 'GET_FINANCIAL_STATEMENT_REQUEST',
  GET_FINANCIAL_STATEMENT_SUCCESS: 'GET_FINANCIAL_STATEMENT_SUCCESS',
  GET_FINANCIAL_STATEMENT_ERROR: 'GET_FINANCIAL_STATEMENT_ERROR',

  GET_STUDENT_ACCOUNT_STATEMENT_REQUEST:
    'GET_STUDENT_ACCOUNT_STATEMENT_REQUEST',
  GET_STUDENT_ACCOUNT_STATEMENT_SUCCESS:
    'GET_STUDENT_ACCOUNT_STATEMENT_SUCCESS',
  GET_STUDENT_ACCOUNT_STATEMENT_ERROR: 'GET_STUDENT_ACCOUNT_STATEMENT_ERROR',

  PUSH_STUDENT_CREDIT_TO_ACCOUNT_REQUEST:
    'PUSH_STUDENT_CREDIT_TO_ACCOUNT_REQUEST',
  PUSH_STUDENT_CREDIT_TO_ACCOUNT_SUCCESS:
    'PUSH_STUDENT_CREDIT_TO_ACCOUNT_SUCCESS',
  PUSH_STUDENT_CREDIT_TO_ACCOUNT_ERROR: 'PUSH_STUDENT_CREDIT_TO_ACCOUNT_ERROR',

  GET_PENDING_PUSHED_CREDIT_REQUEST: 'GET_PENDING_PUSHED_CREDIT_REQUEST',
  GET_PENDING_PUSHED_CREDIT_SUCCESS: 'GET_PENDING_PUSHED_CREDIT_SUCCESS',
  GET_PENDING_PUSHED_CREDIT_ERROR: 'GET_PENDING_PUSHED_CREDIT_ERROR',

  APPROVE_PENDING_PUSHED_CREDIT_REQUEST:
    'APPROVE_PENDING_PUSHED_CREDIT_REQUEST',
  APPROVE_PENDING_PUSHED_CREDIT_SUCCESS:
    'APPROVE_PENDING_PUSHED_CREDIT_SUCCESS',
  APPROVE_PENDING_PUSHED_CREDIT_ERROR: 'APPROVE_PENDING_PUSHED_CREDIT_ERROR',

  DOWNLOAD_FINANCIAL_STATEMENT_REQUEST: 'DOWNLOAD_FINANCIAL_STATEMENT_REQUEST',
  DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS: 'DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS',
  DOWNLOAD_FINANCIAL_STATEMENT_ERROR: 'DOWNLOAD_FINANCIAL_STATEMENT_ERROR',

  getFinancialStatement: (studentProgrammeId) => ({
    type: financialStatementActions.GET_FINANCIAL_STATEMENT_REQUEST,
    studentProgrammeId,
  }),

  getStudentAccountStatement: (studentProgrammeId) => ({
    type: financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_REQUEST,
    studentProgrammeId,
  }),

  getPendingPushedCreditTransactions: () => ({
    type: financialStatementActions.GET_PENDING_PUSHED_CREDIT_REQUEST,
  }),

  approvePendingPushedCreditRequests: (data) => ({
    type: financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_REQUEST,
    data,
  }),

  pushStudentCreditToAccount: (data) => ({
    type: financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_REQUEST,
    data,
  }),

  downloadFinancialStatement: (studentProgrammeId) => ({
    type: financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_REQUEST,
    studentProgrammeId,
  }),
};

export default financialStatementActions;
