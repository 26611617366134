const universalPayments = {
  chartOfAccount: {
    loading: false,
    loaError: {},
    chartOfAccounts: [],

    creating: false,
    createError: {},
    createSuccess: {},

    updating: false,
    updateError: {},
    updateSuccess: {},

    deleting: false,
    deleteError: {},
    deleteSuccess: {},

    currentTab: 'home',
    showDeleteChartOfAccountModal: false,
    showCreateChartOfAccountModal: false,

    approving: false,
    approveError: {},
    approveSuccess: {},

    downloading: false,
    downloadResponse: {},
    downloadError: {},

    uploading: false,
    uploadResponse: {},
    uploadError: {},
  },
  receivables: {
    loading: false,
    loaError: {},
    receivables: [],

    receivablesContext: {},
    receivablesReport: [],

    creating: false,
    createError: {},
    createSuccess: {},

    updating: false,
    updateError: {},
    updateSuccess: {},

    deleting: false,
    deleteError: {},
    deleteSuccess: {},

    downloading: false,
    downloadError: {},
    downloadSuccess: {},

    currentTab: 'home',
    showCreateReceivableModal: false,

    showDeleteReceivableModal: false,

    approving: false,
    approveError: {},
    approveSuccess: {},
  },
  transaction: {
    searchingUniversal: false,
    searchUniversalSuccess: {},
    searchUniversalError: {},

    gettingCollectionReports: false,
    collectionReports: [],
    getCollectionReportError: {},

    gettingRevenueReports: false,
    studentRevenueReport: [],
    outstandingBalanceReport: [],
    studentWaiverReports: [],
    studentWaiverReportError: {},
    outstandingBalanceReportError: {},
    studentRevenueReportError: {},
    globalRevenueReport: [],
    globalRevenueReportError: {},

    globalDetailsReport: [],
    globalDetailsError: {},

    gettingDetailedRevenueReport: false,
    detailedRevenueReport: [],
    detailedRevenueReportError: {},

    downloadingRevenueReport: false,
    downloadSuccess: {},
    downloadError: {},

    gettingMonthlyCollections: false,
    monthlyCollections: [],
    monthlyCollectionError: {},

    gettingSponsorReports: false,
    sponsorReports: [],
    sponsorReportError: {},

    gettingSponsorAllocation: false,
    sponsorAllocation: [],
    sponsorAllocationError: {},

    gettingTransactionReports: false,
    transactionReports: [],
    transactionReportError: {},

    universalReports: {},
    getUniversalReportError: {},
    gettingUniversalReports: false,

    financialYearReports: {},
    financialYearReportDetails: {},
    getFinancialYrReportError: {},
    gettingFinancialYrReports: false,

    getUniversalReportDetailError: {},
    universalReportDetails: {},
    gettingUniversalReportDetails: false,

    getPeriodicReportError: {},
    periodicReportDetails: {},
    gettingPeriodicReportDetails: false,

    generateSelectedQuarterError: {},
    generateQuarterReportSuccess: {},
    generatingSelectedQuarter: false,

    gettingQuarterReport: false,
    quarterlyReports: [],
    getQuarterlyReportsError: {},

    gettingUnallocatedQuarterReport: false,
    unAllocatedQuarterlyReports: [],
    getUnallocatedReportsError: {},

    gettingOpeningReceivablesReport: false,
    openingReceivableReport: [],
    getOpeningReceivableReportsError: {},

    gettingQuarterlyBillingReport: false,
    quarterlyBilling: [],
    getQuarterlyBillingError: {},

    gettingQuarterlyStudentReport: false,
    quarterlyStudentReport: [],
    getQuarterlyStudentReportError: {},

    gettingUnitReport: false,
    academicUnitReport: [],
    getUnitReportError: {},

    gettingQuarterlyPaymentTypeReport: false,
    quarterlyPaymentTypeReport: [],
    getQuarterlyPaymentTypeReportError: {},

    downloadedUniversalReport: {},
    downloadUniversalReportDetailError: {},
    downloadingUniversalReportDetails: false,

    downloadedTransactionReport: {},
    downloadTransactionReportDetailError: {},
    downloadingTransactionReportDetails: false,

    downloadedAccountTransactions: {},
    downloadAllAccountTransactionsError: {},
    downloadingAllAccountTransactions: false,

    downloadedPeriodicReport: {},
    downloadPeriodicReportError: {},
    downloadingPeriodicReport: false,

    currentReport: {},
    selectedReportContext: {},
    outstandingReportContext: {},
    selectedFinancialYear: {},
    universalReportsContext: {},

    downloadReportSummeryResponse: {},
    downloadReportSummeryError: {},
    downloadingReportSummeryDetails: false,
  },
  universalInvoice: {
    gettingInvoices: false,
    universalInvoices: [],
    staffGeneratedInvoices: [],
    staffVoidedInvoices: [],
    getInvoiceError: {},

    voidingOrApproving: false,
    voidOrApproveError: {},
    voidOrApproveSuccess: {},

    gettingSingleInvoice: false,
    singlesInvoice: {},
    singlesInvoiceError: {},

    generatingInvoice: false,
    generatedInvoice: {},
    generateInvoiceError: {},

    showDeleteInvoiceModal: false,
    currentTab: 'invoices',

    staffInvoiceContext: {},
    selectedInvoiceContext: {},
  },
};

export default universalPayments;
