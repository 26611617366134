import axios from 'axios';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import graduationFeesActions from '../../actions/fees-mgt/graduationFees';

function* getGraduationFeesAmounts(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/graduation-fees/elements',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: graduationFeesActions.GET_GRADUATION_FEES_SUCCESS,
      data: response.element,
    });
  } catch (error) {
    yield put({
      type: graduationFeesActions.GET_GRADUATION_FEES_ERROR,
      error: error.data,
    });
  }
}

function* createGraduationFees(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/graduation-fees/bulk-create',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: graduationFeesActions.CREATE_GRADUATION_FEES_SUCCESS,
      data: response,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({
      type: graduationFeesActions.SET_GRADUATION_FEES_TAB,
      data: 'view',
    });
    if (!isEmpty(actions.context)) {
      yield put({
        type: graduationFeesActions.GET_GRADUATION_FEES_REQUEST,
        data: actions.context,
      });
    }
  } catch (error) {
    yield put({
      type: graduationFeesActions.CREATE_GRADUATION_FEES_ERROR,
      error: error.data,
    });
  }
}

function* updateGraduationFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/graduation-fees/update-element-amount/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: graduationFeesActions.UPDATE_GRADUATION_FEES_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_EDIT_OTHER_FEES_AMOUNT_MODAL',
      payload: false,
    });
    yield put({
      type: graduationFeesActions.GET_GRADUATION_FEES_REQUEST,
      data: actions.context,
    });
  } catch (error) {
    yield put({
      type: graduationFeesActions.UPDATE_GRADUATION_FEES_ERROR,
      error: error.data,
    });
  }
}

function* deleteGraduationFeesElement(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/graduation-fees/delete-element-amount/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: graduationFeesActions.GET_GRADUATION_FEES_REQUEST,
      data: actions.context,
    });
  } catch (error) {
    yield put({
      type: graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetGraduationFeesAmounts() {
  yield takeLatest(
    graduationFeesActions.GET_GRADUATION_FEES_REQUEST,
    getGraduationFeesAmounts
  );
}

function* watchCreateGraduationFees() {
  yield takeLatest(
    graduationFeesActions.CREATE_GRADUATION_FEES_REQUEST,
    createGraduationFees
  );
}

function* watchUpdateGraduationFees() {
  yield takeLatest(
    graduationFeesActions.UPDATE_GRADUATION_FEES_REQUEST,
    updateGraduationFees
  );
}

function* watchDeleteGraduationFeesElement() {
  yield takeLatest(
    graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_REQUEST,
    deleteGraduationFeesElement
  );
}

export default [
  fork(watchGetGraduationFeesAmounts),
  fork(watchCreateGraduationFees),
  fork(watchUpdateGraduationFees),
  fork(watchDeleteGraduationFeesElement),
];
