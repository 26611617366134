import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { feesBillingPolicyActions, tabActions } from '../../actions';

function* getFeesBillingPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/fees-billing-policies/policies',
      method: 'GET',
    });
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createFeesBillingPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/fees-billing-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: feesBillingPolicyActions.ADDING_FEES_BILLING_POLICY_CONTEXT,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_ERROR,
      error: error.data,
    });
  }
}
function* getModuleFeesBillingPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/fees-billing-policies/module-fees-policy',
      method: 'GET',
    });
    yield put({
      type: feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createModuleFeesBillingPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/fees-billing-policies/module-fees-policy ',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: feesBillingPolicyActions.ADDING_FEES_BILLING_POLICY_CONTEXT,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateFeesBillingPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/fees-billing-policies/${actions.feesBillingPolicyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: feesBillingPolicyActions.UPDATE_FEES_BILLING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* approveFeesBillingPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/fees-billing-policies/approve`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* activateFeesBillingPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/fees-billing-policies/activate-policy`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteFeesBillingPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/fees-billing-policies/${actions.feesBillingPolicyId}`,
      method: 'DELETE',
    });
    yield put({
      type: feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFeesBillingPolicies() {
  yield takeLatest(
    feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
    getFeesBillingPolicies
  );
}

function* watchCreateFeesBillingPolicy() {
  yield takeLatest(
    feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_REQUEST,
    createFeesBillingPolicy
  );
}

function* watchGetModuleFeesBillingPolicies() {
  yield takeLatest(
    feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_REQUEST,
    getModuleFeesBillingPolicies
  );
}

function* watchCreateModuleFeesBillingPolicy() {
  yield takeLatest(
    feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_REQUEST,
    createModuleFeesBillingPolicy
  );
}

function* watchUpdateFeesBillingPolicy() {
  yield takeLatest(
    feesBillingPolicyActions.UPDATE_FEES_BILLING_POLICY_REQUEST,
    updateFeesBillingPolicy
  );
}

function* watchDeleteFeesBillingPolicy() {
  yield takeLatest(
    feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_REQUEST,
    deleteFeesBillingPolicy
  );
}

function* watchApproveFeesBillingPolicy() {
  yield takeLatest(
    feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_REQUEST,
    approveFeesBillingPolicy
  );
}

function* watchActivateFeesBillingPolicy() {
  yield takeLatest(
    feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_REQUEST,
    activateFeesBillingPolicy
  );
}

export default [
  fork(watchGetFeesBillingPolicies),
  fork(watchCreateFeesBillingPolicy),
  fork(watchGetModuleFeesBillingPolicies),
  fork(watchCreateModuleFeesBillingPolicy),
  fork(watchUpdateFeesBillingPolicy),
  fork(watchApproveFeesBillingPolicy),
  fork(watchActivateFeesBillingPolicy),
  fork(watchDeleteFeesBillingPolicy),
];
