const resultApproval = {
  GET_RESULT_APPROVAL_POLICY_REQUEST: 'GET_RESULT_APPROVAL_POLICY_REQUEST',
  GET_RESULT_APPROVAL_POLICY_SUCCESS: 'GET_RESULT_APPROVAL_POLICY_SUCCESS',
  GET_RESULT_APPROVAL_POLICY_ERROR: 'GET_RESULT_APPROVAL_POLICY_ERROR',

  DELETE_RESULT_APPROVAL_POLICY_REQUEST:
    'DELETE_RESULT_APPROVAL_POLICY_REQUEST',
  DELETE_RESULT_APPROVAL_POLICY_SUCCESS:
    'DELETE_RESULT_APPROVAL_POLICY_SUCCESS',
  DELETE_RESULT_APPROVAL_POLICY_ERROR: 'DELETE_RESULT_APPROVAL_POLICY_ERROR',

  SET_RESULT_POLICY_ACTIVE_TAB: 'SET_RESULT_POLICY_ACTIVE_TAB',
  SET_DELETE_RESULT_POLICY: 'SET_DELETE_RESULT_POLICY',

  CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_REQUEST:
    'CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_REQUEST',
  CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_SUCCESS:
    'CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_SUCCESS',
  CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_ERROR:
    'CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_ERROR',

  getResultApprovalPolicy: () => ({
    type: resultApproval.GET_RESULT_APPROVAL_POLICY_REQUEST,
  }),

  deleteResultApprovalPolicy: (hierarchyId) => ({
    type: resultApproval.DELETE_RESULT_APPROVAL_POLICY_REQUEST,
    hierarchyId,
  }),

  setResultApprovalPolicyTab: (view) => ({
    type: resultApproval.SET_RESULT_POLICY_ACTIVE_TAB,
    view,
  }),

  setDeleteResultApprovalPolicy: (view = {}) => ({
    type: resultApproval.SET_DELETE_RESULT_POLICY,
    view,
  }),

  createResultApprovalPolicy: (data, hierarchyId = null) => ({
    type: resultApproval.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_REQUEST,
    data,
    hierarchyId,
  }),
};

export default resultApproval;
