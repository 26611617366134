const programme = {
  GET_PROGRAMME_REQUEST: 'GET_PROGRAMME_REQUEST',
  GET_PROGRAMME_SUCCESS: 'GET_PROGRAMME_SUCCESS',
  GET_PROGRAMME_ERROR: 'GET_PROGRAMME_ERROR',

  GET_PROGRAMME_WITH_YEARS_REQUEST: 'GET_PROGRAMME_WITH_YEARS_REQUEST',
  GET_PROGRAMME_WITH_YEARS_SUCCESS: 'GET_PROGRAMME_WITH_YEARS_SUCCESS',
  GET_PROGRAMME_WITH_YEARS_ERROR: 'GET_PROGRAMME_WITH_YEARS_ERROR',

  GET_PROGRAMME_DOWNLOAD_REQUEST: 'GET_PROGRAMME_DOWNLOAD_REQUEST',
  GET_PROGRAMME_DOWNLOAD_SUCCESS: 'GET_PROGRAMME_DOWNLOAD_SUCCESS',
  GET_PROGRAMME_DOWNLOAD_ERROR: 'GET_PROGRAMME_DOWNLOAD_ERROR',

  GET_PROGRAMME_WITH_CATEGORY_REQUEST: 'GET_PROGRAMME_WITH_CATEGORY_REQUEST',
  GET_PROGRAMME_WITH_CATEGORY_SUCCESS: 'GET_PROGRAMME_WITH_CATEGORY_SUCCESS',
  GET_PROGRAMME_WITH_CATEGORY_ERROR: 'GET_PROGRAMME_WITH_CATEGORY_ERROR',

  GET_PROGRAMME_BY_DEPARTMENT_REQUEST: 'GET_PROGRAMME_BY_DEPARTMENT_REQUEST',
  GET_PROGRAMME_BY_DEPARTMENT_SUCCESS: 'GET_PROGRAMME_BY_DEPARTMENT_SUCCESS',
  GET_PROGRAMME_BY_DEPARTMENT_ERROR: 'GET_PROGRAMME_BY_DEPARTMENT_ERROR',

  GET_ADMISSIONS_PROGRAMMES_REQUEST: 'GET_ADMISSIONS_PROGRAMMES_REQUEST',
  GET_ADMISSIONS_PROGRAMMES_SUCCESS: 'GET_ADMISSIONS_PROGRAMMES_SUCCESS',
  GET_ADMISSIONS_PROGRAMMES_ERROR: 'GET_ADMISSIONS_PROGRAMMES_ERROR',

  GET_NEW_ACADEMIC_UNIT_PROGRAMMES_REQUEST:
    'GET_NEW_ACADEMIC_UNIT_PROGRAMMES_REQUEST',
  GET_NEW_ACADEMIC_UNIT_PROGRAMMES_SUCCESS:
    'GET_NEW_ACADEMIC_UNIT_PROGRAMMES_SUCCESS',
  GET_NEW_ACADEMIC_UNIT_PROGRAMMES_ERROR:
    'GET_NEW_ACADEMIC_UNIT_PROGRAMMES_ERROR',

  UPLOAD_PROGRAMME_SUCCESS: 'UPLOAD_PROGRAMME_SUCCESS',
  UPLOAD_PROGRAMME_REQUEST: 'UPLOAD_PROGRAMME_REQUEST',
  UPLOAD_PROGRAMME_ERROR: 'UPLOAD_PROGRAMME_ERROR',

  CREATE_PROGRAMME_SUCCESS: 'CREATE_PROGRAMME_SUCCESS',
  CREATE_PROGRAMME_REQUEST: 'CREATE_PROGRAMME_REQUEST',
  CREATE_PROGRAMME_ERROR: 'CREATE_PROGRAMME_ERROR',

  GET_SINGLE_PROGRAMME_SUCCESS: 'GET_SINGLE_PROGRAMME_SUCCESS',
  GET_SINGLE_PROGRAMME_REQUEST: 'GET_SINGLE_PROGRAMME_REQUEST',
  GET_SINGLE_PROGRAMME_ERROR: 'GET_SINGLE_PROGRAMME_ERROR',

  GET_PROGRAMME_DETAILS_SUCCESS: 'GET_PROGRAMME_DETAILS_SUCCESS',
  GET_PROGRAMME_DETAILS_REQUEST: 'GET_PROGRAMME_DETAILS_REQUEST',
  GET_PROGRAMME_DETAILS_ERROR: 'GET_PROGRAMME_DETAILS_ERROR',

  UPDATE_PROGRAMME_SUCCESS: 'UPDATE_PROGRAMME_SUCCESS',
  UPDATE_PROGRAMME_REQUEST: 'UPDATE_PROGRAMME_REQUEST',
  UPDATE_PROGRAMME_ERROR: 'UPDATE_PROGRAMME_ERROR',

  DOWNLOAD_TEMPLATE_SUCCESS: 'DOWNLOAD_TEMPLATE_SUCCESS',
  DOWNLOAD_TEMPLATE_REQUEST: 'DOWNLOAD_TEMPLATE_REQUEST',
  DOWNLOAD_TEMPLATE_ERROR: 'DOWNLOAD_TEMPLATE_ERROR',

  GET_DASHBOARD_DATA_REQUEST: 'GET_DASHBOARD_DATA_REQUEST',
  GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
  GET_DASHBOARD_DATA_ERROR: 'GET_DASHBOARD_DATA_ERROR',

  DELETE_PROGRAMME_REQUEST: 'DELETE_PROGRAMME_REQUEST',
  DELETE_PROGRAMME_SUCCESS: 'DELETE_PROGRAMME_SUCCESS',
  DELETE_PROGRAMME_ERROR: 'DELETE_PROGRAMME_ERROR',

  SET_PROGRAMME_STUDY_YEAR_SEMESTERS_REQUEST:
    'SET_PROGRAMME_STUDY_YEAR_SEMESTERS_REQUEST',
  SET_PROGRAMME_STUDY_YEAR_SEMESTERS_SUCCESS:
    'SET_PROGRAMME_STUDY_YEAR_SEMESTERS_SUCCESS',
  SET_PROGRAMME_STUDY_YEAR_SEMESTERS_ERROR:
    'SET_PROGRAMME_STUDY_YEAR_SEMESTERS_ERROR',

  SET_CURRENT_PROGRAMME_DETAIL_TAB: 'SET_CURRENT_PROGRAMME_DETAIL_TAB',

  getProgrammes: () => ({
    type: programme.GET_PROGRAMME_REQUEST,
  }),

  deleteProgramme: (id) => ({
    type: programme.DELETE_PROGRAMME_REQUEST,
    id,
  }),

  getDashboardData: () => ({
    type: programme.GET_DASHBOARD_DATA_REQUEST,
  }),

  getProgrammeDownload: (data) => ({
    type: programme.GET_PROGRAMME_DOWNLOAD_REQUEST,
    data,
  }),

  setStudyYearSemesters: (data) => ({
    type: programme.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_REQUEST,
    data,
  }),

  setCurrentProgrammeDetailTab: (currentTab) => ({
    type: programme.SET_CURRENT_PROGRAMME_DETAIL_TAB,
    currentTab,
  }),

  getProgrammesWithYears: () => ({
    type: programme.GET_PROGRAMME_WITH_YEARS_REQUEST,
  }),

  getProgrammesWithCategories: (data) => ({
    type: programme.GET_PROGRAMME_WITH_CATEGORY_REQUEST,
    data,
  }),

  getProgrammesByDepartment: () => ({
    type: programme.GET_PROGRAMME_BY_DEPARTMENT_REQUEST,
  }),

  getAdmissionsProgrammes: () => ({
    type: programme.GET_ADMISSIONS_PROGRAMMES_REQUEST,
  }),

  getAcademicUnitProgrammes: () => ({
    type: programme.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_REQUEST,
  }),

  createProgramme: (data) => ({
    type: programme.CREATE_PROGRAMME_REQUEST,
    data,
  }),

  uploadProgrammes: (data) => ({
    type: programme.UPLOAD_PROGRAMME_REQUEST,
    data,
  }),

  downloadProgrammeTemplate: () => ({
    type: programme.DOWNLOAD_TEMPLATE_REQUEST,
  }),

  getSingleProgramme: (programmeId) => ({
    type: programme.GET_SINGLE_PROGRAMME_REQUEST,
    programmeId,
  }),

  getProgrammeDetails: (programmeId) => ({
    type: programme.GET_PROGRAMME_DETAILS_REQUEST,
    programmeId,
  }),

  updateProgramme: (programmeId, data) => ({
    type: programme.UPDATE_PROGRAMME_REQUEST,
    programmeId,
    data,
  }),
};

export default programme;
