import { systemStudyLevelActions } from '../../actions';
import systemManager from '../../initialState/system-mgt';

const acmisStudyLevels = (state = systemManager.systemStudyLevels, actions) => {
  switch (actions.type) {
    case systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_REQUEST:
      return {
        ...state,
        gettingStudyLevels: true,
        getStudyLevelError: {},
      };
    case systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_SUCCESS:
      return {
        ...state,
        gettingStudyLevels: false,
        acmisStudyLevels: actions.data,
      };
    case systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_ERROR:
      return {
        ...state,
        gettingStudyLevels: false,
        getStudyLevelError: actions.error,
      };
    case systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_REQUEST:
      return {
        ...state,
        gettingStudyLevels: true,
        getStudyLevelError: {},
      };
    case systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_SUCCESS:
      return {
        ...state,
        gettingStudyLevels: false,
        emisStudyLevels: actions.data,
      };
    case systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_ERROR:
      return {
        ...state,
        gettingStudyLevels: false,
        getStudyLevelError: actions.error,
      };

    case systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_REQUEST:
      return {
        ...state,
        submittingStudyLevel: true,
        submitError: {},
      };
    case systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_SUCCESS:
      return {
        ...state,
        submittingStudyLevel: false,
        submitSuccess: actions.data,
      };
    case systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_ERROR:
      return {
        ...state,
        submittingStudyLevel: false,
        submitError: actions.error,
      };

    default:
      return state;
  }
};

export default acmisStudyLevels;
