import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { teachingTimetableActions } from '../../actions';

function* assignTeachingTimetable(actions) {
  try {
    const { data, context, actionType } = actions;

    let url = '/course-assignment/teaching-timetable';

    if (actionType === 'programme') url = `${url}/multiple`;

    const response = yield axios({
      url,
      method: 'POST',
      data,
    });
    yield put({
      type: teachingTimetableActions.ASSIGN_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
      context,
    });
    yield put({
      type: teachingTimetableActions.SHOW_ASSIGN_LECTURER_MODAL,
      data: false,
    });
    yield put({
      type: teachingTimetableActions.GET_TEACHING_TIMETABLE_REQUEST,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.ASSIGN_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* getTeachingTimetable(action) {
  try {
    const { context } = action;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: teachingTimetableActions.GET_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.GET_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* getMyTeachingTimetable(action) {
  try {
    const { context } = action;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable/my-teaching-timetable`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: teachingTimetableActions.GET_MY_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.GET_MY_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* searchTeachingTimetable(action) {
  try {
    const { context } = action;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable/search`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: teachingTimetableActions.SEARCH_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.SEARCH_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* teachingTimetableByDepartment(action) {
  try {
    const { context } = action;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable/faculty`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: teachingTimetableActions.TEACHING_TIMETABLE_BY_DEPARTMENT_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.TEACHING_TIMETABLE_BY_DEPARTMENT_ERROR,
      error: error.data,
    });
  }
}

function* teachingTimetableByFaculty(action) {
  try {
    const { context } = action;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable/faculty`,
      method: 'GET',
      params: context,
    });
    yield put({
      type: teachingTimetableActions.TEACHING_TIMETABLE_BY_FACULTY_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.TEACHING_TIMETABLE_BY_FACULTY_ERROR,
      error: error.data,
    });
  }
}

function* downloadTeachingTimetableTemplate() {
  try {
    yield axios({
      url: '/course-assignment/teaching-timetable/download-teachingTimetables-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-BUILDINGS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: teachingTimetableActions.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadTeachingTimetable(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/teaching-timetable/upload-teachingTimetables-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: teachingTimetableActions.UPLOAD_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_TEACHING_TIMETABLE_MODAL',
      payload: 'viewTeachingTimetable',
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.UPLOAD_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* deleteTeachingTimetable(actions) {
  try {
    const { context, id } = actions;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable/${id}`,
      method: 'DELETE',
    });
    yield put({
      type: teachingTimetableActions.DELETE_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: teachingTimetableActions.GET_TEACHING_TIMETABLE_REQUEST,
      context,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.DELETE_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* editTeachingTimetable(actions) {
  try {
    const { context, id } = actions;
    const response = yield axios({
      url: `/course-assignment/teaching-timetable/${id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: teachingTimetableActions.EDIT_TEACHING_TIMETABLE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: teachingTimetableActions.GET_TEACHING_TIMETABLE_REQUEST,
      context,
    });
    yield put({
      type: teachingTimetableActions.SHOW_ASSIGN_LECTURER_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.EDIT_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* getProjectedLecturerTeachingLoad(action) {
  try {
    const { context } = action;
    const response = yield axios({
      url: '/course-assignment/teaching-timetable/projected-lecturer-teaching-load',
      method: 'GET',
      params: context,
    });
    yield put({
      type: teachingTimetableActions.GET_PROJECTED_LECTURER_TEACHING_LOAD_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.GET_PROJECTED_LECTURER_TEACHING_LOAD_ERROR,
      error: error.data,
    });
  }
}

function* downloadProgrammeTeachingTimetable(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/teaching-timetable/download-programme-teaching-timetable',
      method: 'GET',
      params: context,
      responseType: 'blob',
    });
    FileSaver.saveAs(
      new Blob([response.data], {
        type: 'pdf',
      }),
      `${process.env.REACT_APP_INSTITUTION_NAME}-${
        context?.programme_name || ''
      }-PROGRAMME-TEACHING-TIMETABLE.pdf`
    );
    yield put({
      type: teachingTimetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: teachingTimetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadProgrammeTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST,
    downloadProgrammeTeachingTimetable
  );
}

function* watchGetMyTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.GET_MY_TEACHING_TIMETABLE_REQUEST,
    getMyTeachingTimetable
  );
}

function* watchAssignTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.ASSIGN_TEACHING_TIMETABLE_REQUEST,
    assignTeachingTimetable
  );
}

function* watchGetTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.GET_TEACHING_TIMETABLE_REQUEST,
    getTeachingTimetable
  );
}

function* watchSearchTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.SEARCH_TEACHING_TIMETABLE_REQUEST,
    searchTeachingTimetable
  );
}

function* watchTeachingTimetableByDepartment() {
  yield takeLatest(
    teachingTimetableActions.TEACHING_TIMETABLE_BY_DEPARTMENT_REQUEST,
    teachingTimetableByDepartment
  );
}

function* watchTeachingTimetableByFaculty() {
  yield takeLatest(
    teachingTimetableActions.TEACHING_TIMETABLE_BY_FACULTY_REQUEST,
    teachingTimetableByFaculty
  );
}

function* watchDownloadTeachingTimetableTemplate() {
  yield takeLatest(
    teachingTimetableActions.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_REQUEST,
    downloadTeachingTimetableTemplate
  );
}

function* watchUploadTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.UPLOAD_TEACHING_TIMETABLE_REQUEST,
    uploadTeachingTimetable
  );
}

function* watchDeleteTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.DELETE_TEACHING_TIMETABLE_REQUEST,
    deleteTeachingTimetable
  );
}

function* watchEditTeachingTimetable() {
  yield takeLatest(
    teachingTimetableActions.EDIT_TEACHING_TIMETABLE_REQUEST,
    editTeachingTimetable
  );
}

function* watchGetProjectedLecturerTeachingLoad() {
  yield takeLatest(
    teachingTimetableActions.GET_PROJECTED_LECTURER_TEACHING_LOAD_REQUEST,
    getProjectedLecturerTeachingLoad
  );
}

export default [
  fork(watchAssignTeachingTimetable),
  fork(watchGetTeachingTimetable),
  fork(watchSearchTeachingTimetable),
  fork(watchTeachingTimetableByDepartment),
  fork(watchTeachingTimetableByFaculty),
  fork(watchUploadTeachingTimetable),
  fork(watchDeleteTeachingTimetable),
  fork(watchEditTeachingTimetable),
  fork(watchDownloadTeachingTimetableTemplate),
  fork(watchGetProjectedLecturerTeachingLoad),
  fork(watchDownloadProgrammeTeachingTimetable),
  fork(watchGetMyTeachingTimetable),
];
