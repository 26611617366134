import update from 'immutability-helper';
import { electivePositionActions } from '../../actions';
import eVotingInitialState from '../../initialState/eVoting';

const electivePosition = (
  state = eVotingInitialState.electivePosition,
  actions
) => {
  switch (actions.type) {
    case electivePositionActions.SET_CURRENT_ELECTIVE_POSITION_TAB:
      return {
        ...state,
        currentTab: actions.data,
      };
    case electivePositionActions.SET_SELECTED_ELECTIVE_POSITION_ROW:
      return {
        ...state,
        selectedRow: actions.data,
      };
    case electivePositionActions.SET_SELECTED_ELECTIVE_POSITION_PROGRAMME:
      return {
        ...state,
        selectedProgramme: actions.data,
      };

    case electivePositionActions.GET_ELECTIVE_POSITION_REQUEST:
      return {
        ...state,
        gettingElectivePositions: true,
        getElectivePositionError: {},
      };
    case electivePositionActions.GET_ELECTIVE_POSITION_SUCCESS:
      return {
        ...state,
        gettingElectivePositions: false,
        electivePositions: actions.data,
      };
    case electivePositionActions.GET_ELECTIVE_POSITION_ERROR:
      return {
        ...state,
        gettingElectivePositions: false,
        getElectivePositionError: actions.error,
      };

    case electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_REQUEST:
      return {
        ...state,
        gettingSingleElectivePosition: true,
        getSingleElectivePositionError: {},
      };
    case electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_SUCCESS: {
      const { positionId, data } = actions;
      const { singleElectivePositions } = state;

      const findContextIndex = singleElectivePositions.findIndex(
        (item) => item.positionId === positionId
      );

      const contextData = { positionId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          singleElectivePositions: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          singleElectivePositions: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingSingleElectivePosition: false,
      };
    }
    case electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_ERROR:
      return {
        ...state,
        gettingSingleElectivePosition: false,
        getSingleElectivePositionError: actions.error,
      };

    case electivePositionActions.GET_ELIGIBLE_VOTERS_REQUEST:
      return {
        ...state,
        gettingEligibleVoters: true,
        getEligibleVoterError: {},
      };
    case electivePositionActions.GET_ELIGIBLE_VOTERS_SUCCESS: {
      const { positionId, programmeId, data } = actions;
      const { eligibleVoters } = state;

      const findContextIndex = eligibleVoters.findIndex(
        (item) =>
          item.positionId === positionId && item.programmeId === programmeId
      );

      const contextData = { positionId, programmeId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          eligibleVoters: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          eligibleVoters: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingEligibleVoters: false,
      };
    }
    case electivePositionActions.GET_ELIGIBLE_VOTERS_ERROR:
      return {
        ...state,
        gettingEligibleVoters: false,
        getEligibleVoterError: actions.error,
      };

    case electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_REQUEST:
      return {
        ...state,
        gettingVerifiedVoters: true,
        getVerifiedVoterError: {},
      };
    case electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_SUCCESS: {
      const { positionId, programmeId, data } = actions;
      const { verifiedVoters } = state;

      const findContextIndex = verifiedVoters.findIndex(
        (item) =>
          item.positionId === positionId && item.programmeId === programmeId
      );

      const contextData = { positionId, programmeId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          verifiedVoters: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          verifiedVoters: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingVerifiedVoters: false,
      };
    }
    case electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_ERROR:
      return {
        ...state,
        gettingVerifiedVoters: false,
        getVerifiedVoterError: actions.error,
      };

    case electivePositionActions.ADD_ELECTIVE_POSITION_REQUEST:
      return {
        ...state,
        addingElectivePosition: true,
        addError: {},
      };
    case electivePositionActions.ADD_ELECTIVE_POSITION_SUCCESS:
      return {
        ...state,
        addingElectivePosition: false,
        addSuccess: actions.data,
      };
    case electivePositionActions.ADD_ELECTIVE_POSITION_ERROR:
      return {
        ...state,
        addingElectivePosition: false,
        addError: actions.error,
      };

    case electivePositionActions.MARK_STUDENT_VERIFIED_REQUEST:
      return {
        ...state,
        verifying: true,
        verifyError: {},
      };
    case electivePositionActions.MARK_STUDENT_VERIFIED_SUCCESS:
      return {
        ...state,
        verifying: false,
        verified: actions.data,
      };
    case electivePositionActions.MARK_STUDENT_VERIFIED_ERROR:
      return {
        ...state,
        verifying: false,
        verifyError: actions.error,
      };

    case electivePositionActions.UPDATE_ELECTIVE_POSITION_REQUEST:
      return {
        ...state,
        updatingElectivePosition: true,
        updateError: {},
      };
    case electivePositionActions.UPDATE_ELECTIVE_POSITION_SUCCESS:
      return {
        ...state,
        updatingElectivePosition: false,
        updateSuccess: actions.data,
      };
    case electivePositionActions.UPDATE_ELECTIVE_POSITION_ERROR:
      return {
        ...state,
        updatingElectivePosition: false,
        updateError: actions.error,
      };

    case electivePositionActions.DELETE_ELECTIVE_POSITION_REQUEST:
      return {
        ...state,
        deletingElectivePosition: true,
        deleteError: {},
      };
    case electivePositionActions.DELETE_ELECTIVE_POSITION_SUCCESS:
      return {
        ...state,
        deletingElectivePosition: false,
        deleteSuccess: actions.data,
      };
    case electivePositionActions.DELETE_ELECTIVE_POSITION_ERROR:
      return {
        ...state,
        deletingElectivePosition: false,
        deleteError: actions.error,
      };

    case electivePositionActions.UPLOAD_ELECTIVE_POSITION_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case electivePositionActions.UPLOAD_ELECTIVE_POSITION_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case electivePositionActions.UPLOAD_ELECTIVE_POSITION_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };
    default:
      return state;
  }
};

export default electivePosition;
