import { collegeActions } from '../../actions';
import initialState from '../../initialState';

function college(state = initialState.college, actions) {
  switch (actions.type) {
    case collegeActions.GET_COLLEGES_REQUEST:
      return {
        ...state,
        collegeError: {},
        loading: true,
        createdCollege: {},
      };
    case collegeActions.GET_COLLEGES_SUCCESS:
      return {
        ...state,
        colleges: actions.data.colleges,
        collegeError: {},
        loading: false,
      };
    case collegeActions.GET_COLLEGES_ERROR:
      return {
        ...state,
        collegeError: actions.error,
        loading: false,
      };
    case collegeActions.CREATE_COLLEGE_REQUEST:
      return {
        ...state,
        creating: true,
        collegeError: {},
        createdCollege: {},
      };
    case collegeActions.CREATE_COLLEGE_SUCCESS:
      return {
        ...state,
        creating: false,
        createdCollege: actions.data,
      };
    case collegeActions.CREATE_COLLEGE_ERROR:
      return {
        ...state,
        creating: false,
        collegeError: actions.error,
      };
    case collegeActions.DELETE_COLLEGE_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case collegeActions.DELETE_COLLEGE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case collegeActions.DELETE_COLLEGE_ERROR:
      return {
        ...state,
        deleting: false,
        collegeError: actions.error,
      };
    case collegeActions.UPDATE_COLLEGE_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case collegeActions.UPDATE_COLLEGE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case collegeActions.UPDATE_COLLEGE_ERROR:
      return {
        ...state,
        updating: false,
        collegeError: actions.error,
      };

    case collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadResponse: {},
        downloading: true,
        downloadError: {},
      };
    case collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
        downloadResponse: actions.data,
      };
    case collegeActions.DOWNLOAD_COLLEGE_TEMPLATE_ERROR:
      return {
        ...state,
        downloading: false,
        downloadError: actions.error,
      };

    case collegeActions.UPLOAD_COLLEGE_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case collegeActions.UPLOAD_COLLEGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadResponse: actions.data,
      };
    case collegeActions.UPLOAD_COLLEGE_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    default:
      return state;
  }
}

export default college;
