import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { pujabAdmissionActions } from '../../actions';

function* getPujabAdmissions() {
  try {
    const response = yield axios({
      url: '/pujab/running-admissions',
      method: 'GET',
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* getPujabAdmissionInstitution(actions) {
  try {
    const response = yield axios({
      url: `/pujab/running-admissions/pujab-admission-institution/${actions.admissionInstitutionId}`,
      method: 'GET',
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_SUCCESS,
      data: response.record,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_ERROR,
      error: error.data,
    });
  }
}

function* createPujabAdmission(actions) {
  try {
    const response = yield axios({
      url: '/pujab/running-admissions',
      method: 'POST',
      data: actions.payload,
    });
    yield put({
      type: pujabAdmissionActions.CREATE_PUJAB_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.CREATE_PUJAB_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* updatePujabAdmission(actions) {
  try {
    const response = yield axios({
      url: `/pujab/running-admissions/${actions.admissionId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_CURRENT_PUJAB_TAB',
      payload: 'all-admissions',
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_ERROR,
      error: error.data,
    });
  }
}
function* updatePujabAdmissionStatus(actions) {
  try {
    const response = yield axios({
      url: `/pujab/running-admissions/${actions.status}/${actions.admissionId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_SUCCESS,
      data: response,
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* deletePujabAdmission(actions) {
  try {
    const response = yield axios({
      url: `/pujab/running-admissions/${actions.admissionId}`,
      method: 'DELETE',
    });
    yield put({
      type: pujabAdmissionActions.DELETE_PUJAB_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.DELETE_PUJAB_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* deletePujabAdmissionProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/pujab/running-admissions/pujab-admission-institution-programmes`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_REQUEST,
      admissionInstitutionId: actions.admissionInstitutionId,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* assignPujabAdmissionProgramme(actions) {
  try {
    const response = yield axios({
      url: `/pujab/running-admissions/add-institution-programmes`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_PUJAB_PROGRAMMES_MODAL',
      payload: false,
    });
    yield put({
      type: pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_REQUEST,
      admissionInstitutionId: actions.admissionInstitutionId,
    });
  } catch (error) {
    yield put({
      type: pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPujabAdmissions() {
  yield takeLatest(
    pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST,
    getPujabAdmissions
  );
}

function* watchGetPujabAdmissionInstitution() {
  yield takeLatest(
    pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_REQUEST,
    getPujabAdmissionInstitution
  );
}

function* watchCreatePujabAdmission() {
  yield takeLatest(
    pujabAdmissionActions.CREATE_PUJAB_ADMISSION_REQUEST,
    createPujabAdmission
  );
}

function* watchUpdatePujabAdmission() {
  yield takeLatest(
    pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_REQUEST,
    updatePujabAdmission
  );
}

function* watchUpdatePujabAdmissionStatus() {
  yield takeLatest(
    pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_REQUEST,
    updatePujabAdmissionStatus
  );
}

function* watchDeletePujabAdmission() {
  yield takeLatest(
    pujabAdmissionActions.DELETE_PUJAB_ADMISSION_REQUEST,
    deletePujabAdmission
  );
}

function* watchDeletePujabAdmissionProgrammes() {
  yield takeLatest(
    pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_REQUEST,
    deletePujabAdmissionProgrammes
  );
}

function* watchAssignPujabAdmissionProgramme() {
  yield takeLatest(
    pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_REQUEST,
    assignPujabAdmissionProgramme
  );
}

export default [
  fork(watchGetPujabAdmissions),
  fork(watchCreatePujabAdmission),
  fork(watchUpdatePujabAdmission),
  fork(watchDeletePujabAdmission),
  fork(watchUpdatePujabAdmissionStatus),
  fork(watchGetPujabAdmissionInstitution),
  fork(watchAssignPujabAdmissionProgramme),
  fork(watchDeletePujabAdmissionProgrammes),
];
