import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { semesterLoadActions } from '../../actions';

function* getSemesterLoads(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/semester-load',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: semesterLoadActions.GET_SEMESTER_LOADS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: semesterLoadActions.GET_SEMESTER_LOADS_ERROR,
      error: error.data,
    });
  }
}

function* createSemesterLoads(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/semester-load',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: semesterLoadActions.CREATE_SEMESTER_LOAD_SUCCESS,
      data: response,
    });
    yield put({
      type: semesterLoadActions.GET_SEMESTER_LOADS_REQUEST,
      data: '',
    });
  } catch (error) {
    yield put({
      type: semesterLoadActions.CREATE_SEMESTER_LOAD_ERROR,
      error: error.data,
    });
  }
}
function* deleteSemesterLoad(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/semester-load/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: semesterLoadActions.DELETE_SEMESTER_LOAD_SUCCESS,
      data: response,
    });
    yield put({
      type: semesterLoadActions.GET_SEMESTER_LOADS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: semesterLoadActions.DELETE_SEMESTER_LOAD_ERROR,
      error: error.data,
    });
  }
}
function* updateSemesterLoad(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/semester-load/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: semesterLoadActions.UPDATE_SEMESTER_LOAD_SUCCESS,
      data: response,
    });

    yield put({
      type: semesterLoadActions.GET_SEMESTER_LOADS_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_SEMESTER_LOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: semesterLoadActions.UPDATE_SEMESTER_LOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSemesterLoads() {
  yield takeLatest(
    semesterLoadActions.GET_SEMESTER_LOADS_REQUEST,
    getSemesterLoads
  );
}

function* watchCreateSemesterLoads() {
  yield takeLatest(
    semesterLoadActions.CREATE_SEMESTER_LOAD_REQUEST,
    createSemesterLoads
  );
}
function* watchDeleteSemesterLoad() {
  yield takeLatest(
    semesterLoadActions.DELETE_SEMESTER_LOAD_REQUEST,
    deleteSemesterLoad
  );
}
function* watchUpdateSemesterLoad() {
  yield takeLatest(
    semesterLoadActions.UPDATE_SEMESTER_LOAD_REQUEST,
    updateSemesterLoad
  );
}

export default [
  fork(watchGetSemesterLoads),
  fork(watchCreateSemesterLoads),
  fork(watchDeleteSemesterLoad),
  fork(watchUpdateSemesterLoad),
];
