import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { academicDocumentActions, resultNodeActions } from '../../actions';

function* getAssignedCourses(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/upload-courses/upload-courses',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultNodeActions.GET_ASSIGNED_COURSES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_ASSIGNED_COURSES_ERROR,
      error: error.data,
    });
  }
}

function* getCourseNodes(actions) {
  try {
    const response = yield axios({
      url: 'results-mgt/nodes/course-nodes',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultNodeActions.GET_COURSE_NODES_SUCCESS,
      data: response.nodes,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_COURSE_NODES_ERROR,
      error: error.data,
    });
  }
}

function* getNodeDetails(actions) {
  try {
    const response = yield axios({
      url: 'results-mgt/nodes/assessment-nodes',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultNodeActions.GET_NODE_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_NODE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* createResultNode(actions) {
  try {
    const response = yield axios({
      url: 'results-mgt/nodes',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: resultNodeActions.CREATE_RESULT_NODES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'GET_COURSE_NODES_REQUEST',
      params: { course_assignment_id: actions.courseId },
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.CREATE_RESULT_NODES_ERROR,
      error: error.data,
    });
  }
}

function* getAssignedLecturers(actions) {
  try {
    const response = yield axios({
      url: 'results-mgt/nodes/upload-lecturers',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultNodeActions.GET_ASSIGNED_LECTURERS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_ASSIGNED_LECTURERS_ERROR,
      error: error.data,
    });
  }
}

function* downloadNodesResultsTemplate(actions) {
  try {
    yield axios({
      url: `/results-mgt/nodes/download-template/${actions.id}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-NODES-RESULTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: resultNodeActions.DOWNLOAD_NODES_RESULTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.DOWNLOAD_NODES_RESULTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadResultsTemplate(actions) {
  try {
    yield axios({
      url: '/results-mgt/results/download-template',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DIRECT-RESULTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadGPATemplate(actions) {
  try {
    yield axios({
      url: '/results-mgt/results/download-academic-assessment-template',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-GPA-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: resultNodeActions.DOWNLOAD_GPA_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.DOWNLOAD_GPA_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadNodesResultsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/nodes/upload-template/${actions.nodeId}`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: resultNodeActions.UPLOAD_NODES_RESULTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_UPLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.UPLOAD_NODES_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* uploadResultsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/results/upload-template/${actions.token}/${actions.operation}`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: resultNodeActions.UPLOAD_RESULTS_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultNodeActions.UPLOAD_RESULTS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadGPATemplate(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/results/upload-academic-assessment-template/${actions.token}/${actions.operation}`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: resultNodeActions.UPLOAD_GPA_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultNodeActions.UPLOAD_GPA_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* deleteSingleResult(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/results/${actions.id}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: resultNodeActions.DELETE_SINGLE_RESULT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultNodeActions.GET_CONTEXT_RESULTS_REQUEST,
      params: actions.params,
    });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
    yield put({ type: 'SHOW_EDIT_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultNodeActions.DELETE_SINGLE_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* editSingleResult(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/results/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: resultNodeActions.UPDATE_SINGLE_RESULT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultNodeActions.GET_CONTEXT_RESULTS_REQUEST,
      params: actions.params,
    });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
    yield put({ type: 'SHOW_EDIT_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultNodeActions.UPDATE_SINGLE_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* evaluateResultRemarks(actions) {
  try {
    const { context, params } = actions;
    const response = yield axios({
      url: `/results-mgt/results/evaluate-remark`,
      method: 'POST',
      data: context,
    });
    yield put({
      type: resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultNodeActions.GET_CONTEXT_RESULTS_REQUEST,
      params,
    });
    yield put({ type: 'SHOW_EDIT_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_ERROR,
      error: error.data,
    });
  }
}

function* updateSingleResultAcademicYear(actions) {
  try {
    const { data, studentNumber } = actions;

    const response = yield axios({
      url: `/results-mgt/results/update/academic-years`,
      method: 'PUT',
      data,
    });
    yield put({
      type: resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_SUCCESS,
      data: response.data,
    });
    yield put({
      type: academicDocumentActions.GET_STUDENT_TESTIMONIAL_REQUEST,
      params: { student: studentNumber },
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* getNodeResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/nodes/node-marks',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultNodeActions.GET_NODE_RESULTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_NODE_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* submitNodeResults(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/nodes/submit-node-by-lecturer/${actions.nodeId}`,
      method: 'POST',
    });
    yield put({
      type: resultNodeActions.SUBMIT_NODE_RESULTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.SUBMIT_NODE_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getContextResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/nodes/results-view',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultNodeActions.GET_CONTEXT_RESULTS_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_CONTEXT_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* publishCourseResult(actions) {
  const { data, context } = actions.data;
  try {
    const response = yield axios({
      url: '/results-mgt/batch/publish-result',
      method: 'POST',
      data,
    });
    yield put({
      type: resultNodeActions.PUBLISH_COURSE_RESULT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultNodeActions.GET_COURSE_RESULTS_REQUEST,
      data: context,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.PUBLISH_COURSE_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* getResultProgrammeCourses(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/batch/approval-courses',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: resultNodeActions.GET_RESULT_PROGRAMME_COURSES_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_RESULT_PROGRAMME_COURSES_ERROR,
      error: error.data,
    });
  }
}

function* getCourseResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/batch/approval-results',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: resultNodeActions.GET_COURSE_RESULTS_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: resultNodeActions.GET_COURSE_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAssignedCourses() {
  yield takeLatest(
    resultNodeActions.GET_ASSIGNED_COURSES_REQUEST,
    getAssignedCourses
  );
}

function* watchGetCourseNodes() {
  yield takeLatest(resultNodeActions.GET_COURSE_NODES_REQUEST, getCourseNodes);
}

function* watchGetNodeDetails() {
  yield takeLatest(resultNodeActions.GET_NODE_DETAILS_REQUEST, getNodeDetails);
}

function* watchCreateResultNode() {
  yield takeLatest(
    resultNodeActions.CREATE_RESULT_NODES_REQUEST,
    createResultNode
  );
}

function* watchGetAssignedLecturers() {
  yield takeLatest(
    resultNodeActions.GET_ASSIGNED_LECTURERS_REQUEST,
    getAssignedLecturers
  );
}

function* watchDownloadNodesResultsTemplate() {
  yield takeLatest(
    resultNodeActions.DOWNLOAD_NODES_RESULTS_REQUEST,
    downloadNodesResultsTemplate
  );
}

function* watchDownloadResultsTemplate() {
  yield takeLatest(
    resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_REQUEST,
    downloadResultsTemplate
  );
}

function* watchDownloadGPATemplate() {
  yield takeLatest(
    resultNodeActions.DOWNLOAD_GPA_TEMPLATE_REQUEST,
    downloadGPATemplate
  );
}

function* watchUploadNodesResultsTemplate() {
  yield takeLatest(
    resultNodeActions.UPLOAD_NODES_RESULTS_REQUEST,
    uploadNodesResultsTemplate
  );
}

function* watchUploadResultsTemplate() {
  yield takeLatest(
    resultNodeActions.UPLOAD_RESULTS_TEMPLATE_REQUEST,
    uploadResultsTemplate
  );
}

function* watchUploadGPATemplate() {
  yield takeLatest(
    resultNodeActions.UPLOAD_GPA_TEMPLATE_REQUEST,
    uploadGPATemplate
  );
}

function* watchDeleteSingleResult() {
  yield takeLatest(
    resultNodeActions.DELETE_SINGLE_RESULT_REQUEST,
    deleteSingleResult
  );
}

function* watchEditSingleResult() {
  yield takeLatest(
    resultNodeActions.UPDATE_SINGLE_RESULT_REQUEST,
    editSingleResult
  );
}

function* watchEvaluateResultRemarks() {
  yield takeLatest(
    resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_REQUEST,
    evaluateResultRemarks
  );
}

function* watchUpdateSingleResultAcademicYear() {
  yield takeLatest(
    resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_REQUEST,
    updateSingleResultAcademicYear
  );
}

function* watchGetNodeResults() {
  yield takeLatest(resultNodeActions.GET_NODE_RESULTS_REQUEST, getNodeResults);
}

function* watchSubmitNodeResults() {
  yield takeLatest(
    resultNodeActions.SUBMIT_NODE_RESULTS_REQUEST,
    submitNodeResults
  );
}

function* watchPublishCourseResult() {
  yield takeLatest(
    resultNodeActions.PUBLISH_COURSE_RESULT_REQUEST,
    publishCourseResult
  );
}

function* watchGetResultProgrammeCourses() {
  yield takeLatest(
    resultNodeActions.GET_RESULT_PROGRAMME_COURSES_REQUEST,
    getResultProgrammeCourses
  );
}

function* watchGetCourseResults() {
  yield takeLatest(
    resultNodeActions.GET_COURSE_RESULTS_REQUEST,
    getCourseResults
  );
}

function* watchGetContextResults() {
  yield takeLatest(
    resultNodeActions.GET_CONTEXT_RESULTS_REQUEST,
    getContextResults
  );
}

export default [
  fork(watchGetAssignedCourses),
  fork(watchGetCourseNodes),
  fork(watchCreateResultNode),
  fork(watchGetAssignedLecturers),
  fork(watchDownloadNodesResultsTemplate),
  fork(watchUploadNodesResultsTemplate),
  fork(watchGetNodeResults),
  fork(watchGetContextResults),
  fork(watchDownloadResultsTemplate),
  fork(watchDownloadGPATemplate),
  fork(watchUploadResultsTemplate),
  fork(watchUploadGPATemplate),
  fork(watchDeleteSingleResult),
  fork(watchEditSingleResult),
  fork(watchEvaluateResultRemarks),
  fork(watchUpdateSingleResultAcademicYear),
  fork(watchGetNodeDetails),
  fork(watchSubmitNodeResults),
  fork(watchPublishCourseResult),
  fork(watchGetCourseResults),
  fork(watchGetResultProgrammeCourses),
];
