import { timetablePolicyActions } from '../../actions';
import initialState from '../../initialState';

const timetablePolicy = (state = initialState.timetablePolicy, actions) => {
  switch (actions.type) {
    case timetablePolicyActions.GET_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        getTeachingLoadPolicy: true,
        teachingLoadPolicyError: {},
      };

    case timetablePolicyActions.GET_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        teachingLoadPolicies: actions.data.data,
        getTeachingLoadPolicy: false,
        teachingLoadPolicyError: {},
      };
    case timetablePolicyActions.GET_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        getTeachingLoadPolicy: false,
        teachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        createTeachingLoadPolicy: true,
        createTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        createTeachingLoadPolicy: false,
        createTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.CREATE_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        createTeachingLoadPolicy: false,
        createTeachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        updateTeachingLoadPolicy: true,
        updateTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        updateTeachingLoadPolicy: false,
        updateTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.UPDATE_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        updateTeachingLoadPolicy: false,
        updateTeachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        deleteTeachingLoadPolicy: true,
        deleteTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        deleteTeachingLoadPolicy: false,
        deleteTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.DELETE_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        deleteTeachingLoadPolicy: false,
        deleteTeachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.SET_SELECTED_TIMETABLE_POLICY:
      return {
        ...state,
        selectedTimetablePolicy: actions.data,
      };
    case timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        getExtraTeachingLoadPolicy: true,
        extraTeachingPoliciesLoadError: {},
      };

    case timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        extraTeachingLoadPolicies: actions.data.data,
        getExtraTeachingLoadPolicy: false,
        extraTeachingLoadPolicyError: {},
      };
    case timetablePolicyActions.GET_EXTRA_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        getExtraTeachingLoadPolicy: false,
        extraTeachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.CREATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        createExtraTeachingLoadPolicy: true,
        createExtraTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.CREATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        createExtraTeachingLoadPolicy: false,
        createExtraTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.CREATE_EXTRA_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        createExtraTeachingLoadPolicy: false,
        createExtraTeachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        updateExtraTeachingLoadPolicy: true,
        updateExtraTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        updateExtraTeachingLoadPolicy: false,
        updateExtraTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.UPDATE_EXTRA_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        updateExtraTeachingLoadPolicy: false,
        updateExtraTeachingLoadPolicyError: actions.error,
      };

    case timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_REQUEST:
      return {
        ...state,
        deleteExtraTeachingLoadPolicy: true,
        deleteExtraTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_SUCCESS:
      return {
        ...state,
        deleteExtraTeachingLoadPolicy: false,
        deleteExtraTeachingLoadPolicyError: {},
      };

    case timetablePolicyActions.DELETE_EXTRA_TEACHING_LOAD_POLICY_ERROR:
      return {
        ...state,
        deleteExtraTeachingLoadPolicy: false,
        deleteExtraTeachingLoadPolicyError: actions?.error,
      };

    default:
      return state;
  }
};

export default timetablePolicy;
