import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { documentPolicyActions, tabActions } from '../../actions';

function* getDocumentPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/document-verification-policies',
      method: 'GET',
    });
    yield put({
      type: documentPolicyActions.GET_DOCUMENT_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.GET_DOCUMENT_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* getRegHistoryPolicy() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/enrollment-and-registration-history-policies',
      method: 'GET',
    });
    yield put({
      type: documentPolicyActions.GET_REG_HISTORY_POLICY_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.GET_REG_HISTORY_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createDocumentPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/document-verification-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: documentPolicyActions.CREATE_DOCUMENT_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: documentPolicyActions.GET_DOCUMENT_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.CREATE_DOCUMENT_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* createRegHistoryPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/enrollment-and-registration-history-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: documentPolicyActions.CREATE_REGISTRATION_HISTORY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: documentPolicyActions.GET_REG_HISTORY_POLICY_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.CREATE_REGISTRATION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* updateDocumentPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/document-verification-policies/update/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: documentPolicyActions.UPDATE_DOCUMENT_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: documentPolicyActions.GET_DOCUMENT_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.UPDATE_DOCUMENT_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateRegHistoryPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/enrollment-and-registration-history-policies/update/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: documentPolicyActions.UPDATE_REGISTRATION_HISTORY_SUCCESS,
      data: response,
    });
    yield put({
      type: documentPolicyActions.GET_REG_HISTORY_POLICY_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.UPDATE_REGISTRATION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* deleteDocumentPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/document-verification-policies/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: documentPolicyActions.DELETE_DOCUMENT_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: documentPolicyActions.GET_DOCUMENT_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.DELETE_DOCUMENT_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteRegistrationHistory(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/enrollment-and-registration-history-policies/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: documentPolicyActions.DELETE_REGISTRATION_HISTORY_SUCCESS,
      data: response,
    });
    yield put({
      type: documentPolicyActions.GET_REG_HISTORY_POLICY_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: documentPolicyActions.DELETE_REGISTRATION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetDocumentPolicies() {
  yield takeLatest(
    documentPolicyActions.GET_DOCUMENT_POLICIES_REQUEST,
    getDocumentPolicies
  );
}

function* watchGetRegHistoryPolicy() {
  yield takeLatest(
    documentPolicyActions.GET_REG_HISTORY_POLICY_REQUEST,
    getRegHistoryPolicy
  );
}

function* watchCreateDocumentPolicy() {
  yield takeLatest(
    documentPolicyActions.CREATE_DOCUMENT_POLICY_REQUEST,
    createDocumentPolicy
  );
}

function* watchRegHistoryPolicy() {
  yield takeLatest(
    documentPolicyActions.CREATE_REGISTRATION_HISTORY_REQUEST,
    createRegHistoryPolicy
  );
}

function* watchUpdateDocumentPolicy() {
  yield takeLatest(
    documentPolicyActions.UPDATE_DOCUMENT_POLICY_REQUEST,
    updateDocumentPolicy
  );
}

function* watchUpdateRegHistoryPolicy() {
  yield takeLatest(
    documentPolicyActions.UPDATE_REGISTRATION_HISTORY_REQUEST,
    updateRegHistoryPolicy
  );
}

function* watchDeleteDocumentPolicy() {
  yield takeLatest(
    documentPolicyActions.DELETE_DOCUMENT_POLICY_REQUEST,
    deleteDocumentPolicy
  );
}

function* watchRegistrationHistory() {
  yield takeLatest(
    documentPolicyActions.DELETE_REGISTRATION_HISTORY_REQUEST,
    deleteRegistrationHistory
  );
}

export default [
  fork(watchGetDocumentPolicies),
  fork(watchGetRegHistoryPolicy),
  fork(watchCreateDocumentPolicy),
  fork(watchRegHistoryPolicy),
  fork(watchUpdateDocumentPolicy),
  fork(watchUpdateRegHistoryPolicy),
  fork(watchDeleteDocumentPolicy),
  fork(watchRegistrationHistory),
];
