import { studentGradeActions } from '../../actions';
import initialState from '../../initialState';

const studentGrade = (state = initialState.studentGrade, actions) => {
  switch (actions.type) {
    case studentGradeActions.GET_STUDENT_GRADES_REQUEST:
      return {
        ...state,
        studentGradeError: {},
        gettingStudentGrades: true,
        selectedContext: actions.context,
      };
    case studentGradeActions.GET_STUDENT_GRADES_SUCCESS: {
      let { studentGrades } = state;
      const { context } = actions;
      const findIndex = studentGrades.findIndex(
        (report) =>
          report.study_year_id === context.study_year_id &&
          report.programme_id === context.programme_id &&
          report.campus_id === context.campus_id &&
          report.intake_id === context.intake_id &&
          report.semester_id === context.semester_id &&
          report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        studentGrades = [...studentGrades, { ...context, data: actions.data }];
      } else {
        studentGrades[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        studentGrades,
        gettingStudentGrades: false,
      };
    }
    case studentGradeActions.GET_STUDENT_GRADES_ERROR:
      return {
        ...state,
        studentGradeError: actions.error,
        gettingStudentGrades: false,
      };

    case studentGradeActions.GENERATE_STUDENT_GRADES_REQUEST:
      return {
        ...state,
        computeGradeError: {},
        computingGrades: true,
        selectedContext: actions.context,
      };
    case studentGradeActions.GENERATE_STUDENT_GRADES_SUCCESS:
      return {
        ...state,
        computeGradeSuccess: actions.data,
        computingGrades: false,
      };
    case studentGradeActions.GENERATE_STUDENT_GRADES_ERROR:
      return {
        ...state,
        computeGradeError: actions.error,
        computingGrades: false,
      };

    case studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_REQUEST:
      return {
        ...state,
        computeSingleGradeError: {},
        computingSingleGrades: true,
      };
    case studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_SUCCESS:
      return {
        ...state,
        computeSingleGradeSuccess: actions.data,
        computingSingleGrades: false,
      };
    case studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_ERROR:
      return {
        ...state,
        computeSingleGradeError: actions.error,
        computingSingleGrades: false,
      };

    case studentGradeActions.SET_SELECTED_STUDENT_GRADE_CONTEXT:
      return {
        ...state,
        selectedContext: actions.context,
      };

    case studentGradeActions.SET_SELECTED_STUDENT_GRADE_PROGRAMME:
      return {
        ...state,
        selectedProgramme: actions.data || {},
      };

    default:
      return state;
  }
};

export default studentGrade;
