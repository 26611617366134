import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

function DataTableProgressComponent({ text }) {
  return (
    <div className="d-flex align-items-center p-4">
      <div className="text-center my-auto mx-auto text-warning">
        <Spinner
          animation="border"
          size="md"
          variant="warning"
          className="mb-3"
        />
        <div className="h6 fw-bold text-uppercase text-sm">{text}</div>
      </div>
    </div>
  );
}
DataTableProgressComponent.defaultProps = {
  text: 'Loading data...',
};
DataTableProgressComponent.propTypes = {
  text: PropTypes.string,
};

export default DataTableProgressComponent;
