const extensionPolicyActions = {
  GET_EXTENSION_POLICIES_REQUEST: 'GET_EXTENSION_POLICIES_REQUEST',
  GET_EXTENSION_POLICIES_SUCCESS: 'GET_EXTENSION_POLICIES_SUCCESS',
  GET_EXTENSION_POLICIES_ERROR: 'GET_EXTENSION_POLICIES_ERROR',

  CREATE_EXTENSION_POLICY_REQUEST: 'CREATE_EXTENSION_POLICY_REQUEST',
  CREATE_EXTENSION_POLICY_SUCCESS: 'CREATE_EXTENSION_POLICY_SUCCESS',
  CREATE_EXTENSION_POLICY_ERROR: 'CREATE_EXTENSION_POLICY_ERROR',

  UPDATE_EXTENSION_POLICY_REQUEST: 'UPDATE_EXTENSION_POLICY_REQUEST',
  UPDATE_EXTENSION_POLICY_SUCCESS: 'UPDATE_EXTENSION_POLICY_SUCCESS',
  UPDATE_EXTENSION_POLICY_ERROR: 'UPDATE_EXTENSION_POLICY_ERROR',

  ADD_EXTENSION_POLICY_FEES_ELEMENTS_REQUEST:
    'ADD_EXTENSION_POLICY_FEES_ELEMENTS_REQUEST',
  ADD_EXTENSION_POLICY_FEES_ELEMENTS_SUCCESS:
    'ADD_EXTENSION_POLICY_FEES_ELEMENTS_SUCCESS',
  ADD_EXTENSION_POLICY_FEES_ELEMENTS_ERROR:
    'ADD_EXTENSION_POLICY_FEES_ELEMENTS_ERROR',

  DELETE_EXTENSION_POLICY_REQUEST: 'DELETE_EXTENSION_POLICY_REQUEST',
  DELETE_EXTENSION_POLICY_SUCCESS: 'DELETE_EXTENSION_POLICY_SUCCESS',
  DELETE_EXTENSION_POLICY_ERROR: 'DELETE_EXTENSION_POLICY_ERROR',

  DELETE_EXTENSION_POLICY_ELEMENT_REQUEST:
    'DELETE_EXTENSION_POLICY_ELEMENT_REQUEST',
  DELETE_EXTENSION_POLICY_ELEMENT_SUCCESS:
    'DELETE_EXTENSION_POLICY_ELEMENT_SUCCESS',
  DELETE_EXTENSION_POLICY_ELEMENT_ERROR:
    'DELETE_EXTENSION_POLICY_ELEMENT_ERROR',

  SET_EXTENSION_POLICY_TO_EDIT: 'SET_EXTENSION_POLICY_TO_EDIT',

  getExtensionPolicies: (params) => ({
    type: extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    params,
  }),

  createExtensionPolicy: (data) => ({
    type: extensionPolicyActions.CREATE_EXTENSION_POLICY_REQUEST,
    data,
  }),

  updateExtensionPolicy: (extensionPolicyId, data) => ({
    type: extensionPolicyActions.UPDATE_EXTENSION_POLICY_REQUEST,
    data,
    extensionPolicyId,
  }),

  addExtensionPolicyElement: (extensionPolicyId, data) => ({
    type: extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_REQUEST,
    data,
    extensionPolicyId,
  }),

  deleteExtensionPolicy: (extensionPolicyId) => ({
    type: extensionPolicyActions.DELETE_EXTENSION_POLICY_REQUEST,
    extensionPolicyId,
  }),

  deleteExtensionPolicyElement: (extensionPolicyId, elementsToDelete) => ({
    type: extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_REQUEST,
    extensionPolicyId,
    elementsToDelete,
  }),

  setPolicyToEdit: (extensionPolicy) => ({
    type: extensionPolicyActions.SET_EXTENSION_POLICY_TO_EDIT,
    extensionPolicy,
  }),
};

export default extensionPolicyActions;
