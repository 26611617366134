import { subjectActions } from '../../actions';
import initialState from '../../initialState';

function subject(state = initialState.subject, actions) {
  switch (actions.type) {
    case subjectActions.GET_SUBJECTS_REQUEST:
      return {
        ...state,
        subjectError: {},
        loading: true,
      };
    case subjectActions.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: actions.data.subjects,
        subjectError: {},
        loading: false,
      };
    case subjectActions.GET_SUBJECTS_ERROR:
      return {
        ...state,
        subjectError: actions.error,
        loading: false,
      };
    case subjectActions.CREATE_SUBJECT_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        createdData: {},
      };
    case subjectActions.CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        creating: false,
        createError: {},
        createdData: actions.data,
      };
    case subjectActions.CREATE_SUBJECT_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };
    case subjectActions.DELETE_SUBJECT_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case subjectActions.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case subjectActions.DELETE_SUBJECT_ERROR:
      return {
        ...state,
        deleting: false,
        subjectError: actions.error,
      };
    case subjectActions.UPDATE_SUBJECT_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case subjectActions.UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case subjectActions.UPDATE_SUBJECT_ERROR:
      return {
        ...state,
        updating: false,
        subjectError: actions.error,
      };

    case subjectActions.GET_SUBJECT_COMBINATIONS_REQUEST:
      return {
        ...state,
        subjectCombinationError: {},
        loading: true,
      };
    case subjectActions.GET_SUBJECT_COMBINATIONS_SUCCESS:
      return {
        ...state,
        subjectCombinations: actions.data,
        loading: false,
      };
    case subjectActions.GET_SUBJECT_COMBINATIONS_ERROR:
      return {
        ...state,
        subjectCombinationError: actions.error,
        loading: false,
      };

    case subjectActions.CREATE_SUBJECT_COMBINATION_REQUEST:
      return {
        ...state,
        creating: true,
        subjectCombinationError: {},
        createdData: {},
      };
    case subjectActions.CREATE_SUBJECT_COMBINATION_SUCCESS:
      return {
        ...state,
        creating: false,
        createdData: actions.data,
      };
    case subjectActions.CREATE_SUBJECT_COMBINATION_ERROR:
      return {
        ...state,
        creating: false,
        subjectCombinationError: actions.error,
      };

    case subjectActions.DELETE_SUBJECT_COMBINATION_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case subjectActions.DELETE_SUBJECT_COMBINATION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case subjectActions.DELETE_SUBJECT_COMBINATION_ERROR:
      return {
        ...state,
        deleting: false,
        subjectCombinationError: actions.error,
      };

    case subjectActions.GET_VERSION_COMBINATIONS_REQUEST:
      return {
        ...state,
        loadingCombinations: true,
      };
    case subjectActions.GET_VERSION_COMBINATIONS_SUCCESS:
      return {
        ...state,
        loadingCombinations: false,
        versionSubjectCombinations: actions.data,
      };
    case subjectActions.GET_VERSION_COMBINATIONS_ERROR:
      return {
        ...state,
        loadingCombinations: false,
        versionCombinationError: actions.error,
      };

    case subjectActions.UPDATE_SUBJECT_COMBINATION_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case subjectActions.UPDATE_SUBJECT_COMBINATION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case subjectActions.UPDATE_SUBJECT_COMBINATION_ERROR:
      return {
        ...state,
        updating: false,
        subjectCombinationError: actions.error,
      };

    default:
      return state;
  }
}

export default subject;
