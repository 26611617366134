const systemSchemeActions = {
  GET_SYSTEM_SCHEMES_REQUEST: 'GET_SYSTEM_SCHEMES_REQUEST',
  GET_SYSTEM_SCHEMES_SUCCESS: 'GET_SYSTEM_SCHEMES_SUCCESS',
  GET_SYSTEM_SCHEMES_ERROR: 'GET_SYSTEM_SCHEMES_ERROR',

  ON_SUBMIT_SCHEMES_REQUEST: 'ON_SUBMIT_SCHEMES_REQUEST',
  ON_SUBMIT_SCHEMES_SUCCESS: 'ON_SUBMIT_SCHEMES_SUCCESS',
  ON_SUBMIT_SCHEMES_ERROR: 'ON_SUBMIT_SCHEMES_ERROR',

  SHOW_SUBMIT_SCHEME_MODAL: 'SHOW_SUBMIT_SCHEME_MODAL',

  getSystemSchemes: () => ({
    type: systemSchemeActions.GET_SYSTEM_SCHEMES_REQUEST,
  }),

  showSubmitModal: (payload) => ({
    type: systemSchemeActions.SHOW_SUBMIT_SCHEME_MODAL,
    payload,
  }),

  onSubmitSchemes: (payload) => ({
    type: systemSchemeActions.ON_SUBMIT_SCHEMES_REQUEST,
    payload,
  }),
};

export default systemSchemeActions;
