import { facultyActions } from '../../actions';
import initialState from '../../initialState';

function faculty(state = initialState.faculty, actions) {
  switch (actions.type) {
    case facultyActions.GET_FACULTIES_REQUEST:
      return {
        ...state,
        facultyError: {},
        loading: true,
      };
    case facultyActions.GET_FACULTIES_SUCCESS:
      return {
        ...state,
        faculties: actions.data.faculties,
        facultyError: {},
        loading: false,
      };
    case facultyActions.GET_FACULTIES_ERROR:
      return {
        ...state,
        facultyError: actions.error,
        loading: false,
      };
    case facultyActions.CREATE_FACULTY_REQUEST:
      return {
        ...state,
        creating: true,
        facultyData: {},
        facultyError: {},
        createdData: {},
      };
    case facultyActions.CREATE_FACULTY_SUCCESS:
      return {
        ...state,
        creating: false,
        createdData: actions.data,
      };
    case facultyActions.CREATE_FACULTY_ERROR:
      return {
        ...state,
        creating: false,
        facultyError: actions.error,
      };
    case facultyActions.DELETE_FACULTY_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case facultyActions.DELETE_FACULTY_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case facultyActions.DELETE_FACULTY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };
    case facultyActions.UPDATE_FACULTY_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case facultyActions.UPDATE_FACULTY_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case facultyActions.UPDATE_FACULTY_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case facultyActions.DOWNLOAD_FACULTY_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadResponse: {},
        downloading: true,
        downloadError: {},
      };
    case facultyActions.DOWNLOAD_FACULTY_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
        downloadResponse: actions.data,
      };
    case facultyActions.DOWNLOAD_FACULTY_TEMPLATE_ERROR:
      return {
        ...state,
        downloading: false,
        downloadError: actions.error,
      };

    case facultyActions.UPLOAD_FACULTY_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case facultyActions.UPLOAD_FACULTY_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadResponse: actions.data,
      };
    case facultyActions.UPLOAD_FACULTY_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    default:
      return state;
  }
}

export default faculty;
