import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { unebSubjectActions } from '../../actions';

function* getUnebSubjects(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/uneb-subjects',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECTS_SUCCESS,
      data: response.unebSubjects,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* updateUnebSubject(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/uneb-subjects/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: unebSubjectActions.UPDATE_UNEB_SUBJECT_SUCCESS,
      data: response,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.UPDATE_UNEB_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* getSingleUnebSubject(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/uneb-subjects/${actions.unebSubjectId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: unebSubjectActions.GET_SINGLE_UNEB_SUBJECT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.GET_SINGLE_UNEB_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* createUnebSubject(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/uneb-subjects',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: unebSubjectActions.CREATE_UNEB_SUBJECT_SUCCESS,
      data: response,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.CREATE_UNEB_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* deleteUnebSubject(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/uneb-subjects/${actions.id}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: unebSubjectActions.DELETE_UNEB_SUBJECT_SUCCESS,
      data: response,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.DELETE_UNEB_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* downloadUnebSubjectsTemplate() {
  try {
    yield axios({
      url: '/programme-mgt/uneb-subjects/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-UNEB-SUBJECTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: unebSubjectActions.DOWNLOAD_UNEB_SUBJECTS_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.DOWNLOAD_UNEB_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* uploadUnebSubjectsTemplate(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/uneb-subjects/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: unebSubjectActions.UPLOAD_UNEB_SUBJECTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: unebSubjectActions.GET_UNEB_SUBJECTS_REQUEST,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: unebSubjectActions.UPLOAD_UNEB_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUnebSubjects() {
  yield takeLatest(
    unebSubjectActions.GET_UNEB_SUBJECTS_REQUEST,
    getUnebSubjects
  );
}

function* watchCreateUnebSubject() {
  yield takeLatest(
    unebSubjectActions.CREATE_UNEB_SUBJECT_REQUEST,
    createUnebSubject
  );
}

function* watchUpdateUnebSubject() {
  yield takeLatest(
    unebSubjectActions.UPDATE_UNEB_SUBJECT_REQUEST,
    updateUnebSubject
  );
}

function* watchDeleteUnebSubject() {
  yield takeLatest(
    unebSubjectActions.DELETE_UNEB_SUBJECT_REQUEST,
    deleteUnebSubject
  );
}

function* watchGetSingleUnebSubject() {
  yield takeLatest(
    unebSubjectActions.GET_SINGLE_UNEB_SUBJECT_REQUEST,
    getSingleUnebSubject
  );
}

function* watchDownloadUnebSubjectsTemplate() {
  yield takeLatest(
    unebSubjectActions.DOWNLOAD_UNEB_SUBJECTS_REQUEST,
    downloadUnebSubjectsTemplate
  );
}

function* watchUploadUnebSubjectsTemplate() {
  yield takeLatest(
    unebSubjectActions.UPLOAD_UNEB_SUBJECTS_REQUEST,
    uploadUnebSubjectsTemplate
  );
}

export default [
  fork(watchGetUnebSubjects),
  fork(watchCreateUnebSubject),
  fork(watchUpdateUnebSubject),
  fork(watchGetSingleUnebSubject),
  fork(watchDeleteUnebSubject),
  fork(watchUploadUnebSubjectsTemplate),
  fork(watchDownloadUnebSubjectsTemplate),
];
