import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { institutionStructureActions } from '../../actions';

function* getInstitutionStructures(actions) {
  try {
    const response = yield axios({
      url: '/app/institution-structure',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* getDashboardData() {
  try {
    const response = yield axios({
      url: '/app/institution-setup/report',
      method: 'GET',
    });
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_DASHBOARD_DATA_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_DASHBOARD_DATA_ERROR,
      error: error.data,
    });
  }
}

function* createInstitutionStructure(actions) {
  try {
    const response = yield axios({
      url: '/app/institution-structure',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: institutionStructureActions.CREATE_INSTITUTION_STRUCTURE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: institutionStructureActions.CREATE_INSTITUTION_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* updateInstitutionStructure(actions) {
  try {
    const response = yield axios({
      url: `/app/institution-structure/${actions.institutionId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: institutionStructureActions.UPDATE_INSTITUTION_STRUCTURE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: institutionStructureActions.UPDATE_INSTITUTION_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* uploadInstitutionLogo(actions) {
  try {
    const response = yield axios({
      url: `/app/institution-structure/upload-logo`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    yield put({
      type: institutionStructureActions.UPLOAD_INSTITUTION_LOGO_SUCCESS,
      data: response.data,
    });
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: institutionStructureActions.UPLOAD_INSTITUTION_LOGO_ERROR,
      error: error.data,
    });
  }
}

function* watchGetInstitutionStructures() {
  yield takeLatest(
    institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST,
    getInstitutionStructures
  );
}

function* watchGetDashboardData() {
  yield takeLatest(
    institutionStructureActions.GET_INSTITUTION_DASHBOARD_DATA_REQUEST,
    getDashboardData
  );
}

function* watchCreateInstitutionStructure() {
  yield takeLatest(
    institutionStructureActions.CREATE_INSTITUTION_STRUCTURE_REQUEST,
    createInstitutionStructure
  );
}

function* watchUpdateInstitutionStructure() {
  yield takeLatest(
    institutionStructureActions.UPDATE_INSTITUTION_STRUCTURE_REQUEST,
    updateInstitutionStructure
  );
}

function* watchUploadInstitutionLogo() {
  yield takeLatest(
    institutionStructureActions.UPLOAD_INSTITUTION_LOGO_REQUEST,
    uploadInstitutionLogo
  );
}

export default [
  fork(watchGetInstitutionStructures),
  fork(watchCreateInstitutionStructure),
  fork(watchUpdateInstitutionStructure),
  fork(watchUploadInstitutionLogo),
  fork(watchGetDashboardData),
];
