import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { universalInvoiceActions } from '../../actions';

function* getInvoiceData(actions) {
  try {
    yield put({
      type: universalInvoiceActions.SET_SELECTED_INVOICE_CONTEXT,
      context: actions.data,
    });

    const response = yield axios({
      url: '/universal-payments/invoices/fetch-invoices',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: universalInvoiceActions.GET_UNIVERSAL_INVOICES_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.GET_UNIVERSAL_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getStaffInvoices(actions) {
  try {
    if (!actions.data.voided) {
      yield put({
        type: universalInvoiceActions.SET_SELECTED_STAFF_INVOICE_CONTEXT,
        context: actions.data,
      });
    }

    const response = yield axios({
      url: '/universal-payments/invoices/staff-invoices',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* voidOrApproveStaffInvoices(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/invoices/void/${actions.context.id}`,
      method: 'PUT',
      params: actions.params,
      data: actions.data,
    });
    yield put({
      type: universalInvoiceActions.VOID_OR_APPROVE_STAFF_INVOICE_SUCCESS,
      data: response.data,
      context: actions.data,
    });
    yield put({
      type: 'SHOW_INVOICE_ACTIONS_MODAL',
      show: false,
      invoices: [],
    });
    yield put({
      type: universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_REQUEST,
      data: actions.context.context,
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.VOID_OR_APPROVE_STAFF_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* createInvoice(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/invoices/generate-invoice',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: universalInvoiceActions.CREATE_UNIVERSAL_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: universalInvoiceActions.SET_CURRENT_UNIVERSAL_INVOICE_TAB,
      currentTab: 'invoices',
    });
    yield put({
      type: universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_REQUEST,
      invoiceId: response?.data?.id,
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.CREATE_UNIVERSAL_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* getSingleInvoice(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/invoices/${actions.invoiceId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* updateInvoice(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/invoices/${actions.invoiceId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: universalInvoiceActions.UPDATE_UNIVERSAL_INVOICE_SUCCESS,
      data: response,
    });
    yield put({
      type: universalInvoiceActions.SET_CURRENT_UNIVERSAL_INVOICE_TAB,
      currentTab: 'home',
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.UPDATE_UNIVERSAL_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* deleteInvoice(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/invoices/${actions.invoiceId}`,
      method: 'DELETE',
    });
    yield put({
      type: universalInvoiceActions.DELETE_UNIVERSAL_INVOICE_SUCCESS,
      data: response,
    });
    yield put({
      type: universalInvoiceActions.SHOW_DELETE_UNIVERSAL_INVOICE_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: universalInvoiceActions.DELETE_UNIVERSAL_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetInvoiceData() {
  yield takeLatest(
    universalInvoiceActions.GET_UNIVERSAL_INVOICES_REQUEST,
    getInvoiceData
  );
}

function* watchGetStaffGeneratedInvoices() {
  yield takeLatest(
    universalInvoiceActions.GET_STAFF_GENERATED_INVOICE_REQUEST,
    getStaffInvoices
  );
}

function* watchVoidOrApproveStaffInvoices() {
  yield takeLatest(
    universalInvoiceActions.VOID_OR_APPROVE_STAFF_INVOICE_REQUEST,
    voidOrApproveStaffInvoices
  );
}

function* watchCreateInvoice() {
  yield takeLatest(
    universalInvoiceActions.CREATE_UNIVERSAL_INVOICE_REQUEST,
    createInvoice
  );
}

function* watchGetSingleInvoice() {
  yield takeLatest(
    universalInvoiceActions.GET_SINGLE_UNIVERSAL_INVOICE_REQUEST,
    getSingleInvoice
  );
}

function* watchUpdateInvoice() {
  yield takeLatest(
    universalInvoiceActions.UPDATE_UNIVERSAL_INVOICE_REQUEST,
    updateInvoice
  );
}

function* watchDeleteInvoice() {
  yield takeLatest(
    universalInvoiceActions.DELETE_UNIVERSAL_INVOICE_REQUEST,
    deleteInvoice
  );
}

export default [
  fork(watchCreateInvoice),
  fork(watchGetInvoiceData),
  fork(watchUpdateInvoice),
  fork(watchGetSingleInvoice),
  fork(watchDeleteInvoice),
  fork(watchGetStaffGeneratedInvoices),
  fork(watchVoidOrApproveStaffInvoices),
];
