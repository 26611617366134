const systemCampusActions = {
  GET_ACMIS_CAMPUS_REQUEST: 'GET_ACMIS_CAMPUS_REQUEST',
  GET_ACMIS_CAMPUS_SUCCESS: 'GET_ACMIS_CAMPUS_SUCCESS',
  GET_ACMIS_CAMPUS_ERROR: 'GET_ACMIS_CAMPUS_ERROR',

  GET_EMIS_CAMPUSES_REQUEST: 'GET_EMIS_CAMPUSES_REQUEST',
  GET_EMIS_CAMPUSES_SUCCESS: 'GET_EMIS_CAMPUSES_SUCCESS',
  GET_EMIS_CAMPUSES_ERROR: 'GET_EMIS_CAMPUSES_ERROR',

  GET_EMIS_ADMIN_REGIONS_REQUEST: 'GET_EMIS_ADMIN_REGIONS_REQUEST',
  GET_EMIS_ADMIN_REGIONS_SUCCESS: 'GET_EMIS_ADMIN_REGIONS_SUCCESS',
  GET_EMIS_ADMIN_REGIONS_ERROR: 'GET_EMIS_ADMIN_REGIONS_ERROR',

  GET_EMIS_ADMIN_DISTRICTS_REQUEST: 'GET_EMIS_ADMIN_DISTRICTS_REQUEST',
  GET_EMIS_ADMIN_DISTRICTS_SUCCESS: 'GET_EMIS_ADMIN_DISTRICTS_SUCCESS',
  GET_EMIS_ADMIN_DISTRICTS_ERROR: 'GET_EMIS_ADMIN_DISTRICTS_ERROR',

  GET_EMIS_ADMIN_COUNTIES_REQUEST: 'GET_EMIS_ADMIN_COUNTIES_REQUEST',
  GET_EMIS_ADMIN_COUNTIES_SUCCESS: 'GET_EMIS_ADMIN_COUNTIES_SUCCESS',
  GET_EMIS_ADMIN_COUNTIES_ERROR: 'GET_EMIS_ADMIN_COUNTIES_ERROR',

  GET_EMIS_ADMIN_SUB_COUNTIES_REQUEST: 'GET_EMIS_ADMIN_SUB_COUNTIES_REQUEST',
  GET_EMIS_ADMIN_SUB_COUNTIES_SUCCESS: 'GET_EMIS_ADMIN_SUB_COUNTIES_SUCCESS',
  GET_EMIS_ADMIN_SUB_COUNTIES_ERROR: 'GET_EMIS_ADMIN_SUB_COUNTIES_ERROR',

  GET_EMIS_ADMIN_PARISHES_REQUEST: 'GET_EMIS_ADMIN_PARISHES_REQUEST',
  GET_EMIS_ADMIN_PARISHES_SUCCESS: 'GET_EMIS_ADMIN_PARISHES_SUCCESS',
  GET_EMIS_ADMIN_PARISHES_ERROR: 'GET_EMIS_ADMIN_PARISHES_ERROR',

  SUBMIT_SYSTEM_CAMPUS_REQUEST: 'SUBMIT_SYSTEM_CAMPUS_REQUEST',
  SUBMIT_SYSTEM_CAMPUS_SUCCESS: 'SUBMIT_SYSTEM_CAMPUS_SUCCESS',
  SUBMIT_SYSTEM_CAMPUS_ERROR: 'SUBMIT_SYSTEM_CAMPUS_ERROR',

  UPDATE_ACMIS_CAMPUS_REQUEST: 'UPDATE_ACMIS_CAMPUS_REQUEST',
  UPDATE_ACMIS_CAMPUS_SUCCESS: 'UPDATE_ACMIS_CAMPUS_SUCCESS',
  UPDATE_ACMIS_CAMPUS_ERROR: 'UPDATE_ACMIS_CAMPUS_ERROR',

  getAcmisCampuses: () => ({
    type: systemCampusActions.GET_ACMIS_CAMPUS_REQUEST,
  }),

  getEmisCampuses: () => ({
    type: systemCampusActions.GET_EMIS_CAMPUSES_REQUEST,
  }),

  submitCampus: (data) => ({
    type: systemCampusActions.SUBMIT_SYSTEM_CAMPUS_REQUEST,
    data,
  }),

  updateCampus: (data) => ({
    type: systemCampusActions.UPDATE_ACMIS_CAMPUS_REQUEST,
    data,
  }),

  getEmisAdminRegions: () => ({
    type: systemCampusActions.GET_EMIS_ADMIN_REGIONS_REQUEST,
  }),

  getEmisAdminDistricts: (context) => ({
    type: systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_REQUEST,
    context,
  }),

  getEmisAdminCounties: (context) => ({
    type: systemCampusActions.GET_EMIS_ADMIN_COUNTIES_REQUEST,
    context,
  }),

  getEmisAdminSubCounties: (context) => ({
    type: systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_REQUEST,
    context,
  }),

  getEmisAdminParishes: (context) => ({
    type: systemCampusActions.GET_EMIS_ADMIN_PARISHES_REQUEST,
    context,
  }),
};

export default systemCampusActions;
