const resultsReportActions = {
  GET_RESULTS_UNIT_BI_REPORT_REQUEST: 'GET_RESULTS_UNIT_BI_REPORT_REQUEST',
  GET_RESULTS_UNIT_BI_REPORT_SUCCESS: 'GET_RESULTS_UNIT_BI_REPORT_SUCCESS',
  GET_RESULTS_UNIT_BI_REPORT_ERROR: 'GET_RESULTS_UNIT_BI_REPORT_ERROR',

  getResultsBiReport: (params) => ({
    type: resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_REQUEST,
    params,
  }),
};

export default resultsReportActions;
