import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { graduationListActions } from '../../actions';
import initialState from '../../initialState';

const graduationList = (state = initialState.graduationList, actions) => {
  switch (actions.type) {
    case graduationListActions.GET_FACULTY_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case graduationListActions.GET_FACULTY_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: actions.data,
        loading: false,
      };
    case graduationListActions.GET_FACULTY_DEPARTMENTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case graduationListActions.GET_DEPARTMENT_PROGRAMMES_REQUEST:
      return {
        ...state,
        programmeError: {},
        fetchingProgramme: true,
      };
    case graduationListActions.GET_DEPARTMENT_PROGRAMMES_SUCCESS:
      return {
        ...state,
        programmes: actions.data,
        fetchingProgramme: false,
      };
    case graduationListActions.GET_DEPARTMENT_PROGRAMMES_ERROR:
      return {
        ...state,
        programmeError: actions.error,
        fetchingProgramme: false,
      };
    case graduationListActions.SET_DEPARTMENT_PROGRAMMES:
      return {
        ...state,
        programmes: actions.data,
      };

    case graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_REQUEST:
      return {
        ...state,
        unitProgrammeError: {},
        fetchingUnitProgramme: true,
      };
    case graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_SUCCESS:
      return {
        ...state,
        unitProgrammes: actions.data,
        fetchingUnitProgramme: false,
      };
    case graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_ERROR:
      return {
        ...state,
        programmeError: actions.error,
        fetchingUnitProgramme: false,
      };

    case graduationListActions.GENERATE_PROVISIONAL_LIST_REQUEST:
      return {
        ...state,
        generateProvListError: {},
        generatingProvList: true,
      };
    case graduationListActions.GENERATE_PROVISIONAL_LIST_SUCCESS:
      return {
        ...state,
        generateProvListSuccess: actions.data,
        generatingProvList: false,
      };
    case graduationListActions.GENERATE_PROVISIONAL_LIST_ERROR:
      return {
        ...state,
        generateProvListError: actions.error,
        generatingProvList: false,
      };

    case graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_REQUEST:
      return {
        ...state,
        updateGraduationYearError: {},
        updatingGraduationYear: true,
      };
    case graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_SUCCESS:
      return {
        ...state,
        updateGraduationYearSuccess: actions.data,
        updatingGraduationYear: false,
      };
    case graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_ERROR:
      return {
        ...state,
        updateGraduationYearError: actions.error,
        updatingGraduationYear: false,
      };

    case graduationListActions.ADD_TO_PROVISIONAL_LIST_REQUEST:
      return {
        ...state,
        addToProvListError: {},
        addingToProvList: true,
      };
    case graduationListActions.ADD_TO_PROVISIONAL_LIST_SUCCESS:
      return {
        ...state,
        addToProvListSuccess: actions.data,
        addingToProvList: false,
      };
    case graduationListActions.ADD_TO_PROVISIONAL_LIST_ERROR:
      return {
        ...state,
        addToProvListError: actions.error,
        addingToProvList: false,
      };
    case graduationListActions.ADD_PHD_TO_PROVISIONAL_LIST_REQUEST:
      return {
        ...state,
        addToProvListError: {},
        addingToProvList: true,
      };
    case graduationListActions.ADD_PHD_TO_PROVISIONAL_LIST_SUCCESS:
      return {
        ...state,
        addToProvListSuccess: actions.data,
        addingToProvList: false,
      };
    case graduationListActions.ADD_PHD_TO_PROVISIONAL_LIST_ERROR:
      return {
        ...state,
        addToProvListError: actions.error,
        addingToProvList: false,
      };

    case graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_REQUEST:
      return {
        ...state,
        removeFromProvisionalListError: {},
        removingFromProvisionalList: true,
      };
    case graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_SUCCESS:
      return {
        ...state,
        removedFromProvisionalList: actions.data,
        removingFromProvisionalList: false,
      };
    case graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_ERROR:
      return {
        ...state,
        removeFromProvisionalListError: actions.error,
        removingFromProvisionalList: false,
      };

    case graduationListActions.REMOVE_FROM_FINAL_LIST_REQUEST:
      return {
        ...state,
        removeFromFinalListError: {},
        removingFromFinalList: true,
      };
    case graduationListActions.REMOVE_FROM_FINAL_LIST_SUCCESS:
      return {
        ...state,
        removedFromFinalList: actions.data,
        removingFromFinalList: false,
      };
    case graduationListActions.REMOVE_FROM_FINAL_LIST_ERROR:
      return {
        ...state,
        removeFromFinalListError: actions.error,
        removingFromFinalList: false,
      };

    case graduationListActions.SEARCH_FINALIST_REQUEST:
      return {
        ...state,
        fetchFinalistError: {},
        fetchingFinalist: true,
      };
    case graduationListActions.SEARCH_FINALIST_SUCCESS:
      return {
        ...state,
        fetchFinalistSuccess: actions.data,
        fetchingFinalist: false,
      };
    case graduationListActions.SEARCH_FINALIST_ERROR:
      return {
        ...state,
        fetchFinalistError: actions.error,
        fetchFinalistSuccess: {},
        fetchingFinalist: false,
      };

    case graduationListActions.ADD_TO_GRAD_LIST_REQUEST:
      return {
        ...state,
        addToGradListError: {},
        addingToGradList: true,
      };
    case graduationListActions.ADD_TO_GRAD_LIST_SUCCESS:
      return {
        ...state,
        addToGradListSuccess: actions.data,
        addingToGradList: false,
      };
    case graduationListActions.ADD_TO_GRAD_LIST_ERROR:
      return {
        ...state,
        addToGradListError: actions.error,
        addingToGradList: false,
      };

    case graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_LIST_REQUEST:
      return {
        ...state,
        addCategoryError: {},
        addingCategoryToProvList: true,
      };
    case graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_LIST_SUCCESS:
      return {
        ...state,
        addCategoryToProvListSuccess: actions.data,
        addingCategoryToProvList: false,
      };
    case graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_ERROR:
      return {
        ...state,
        addCategoryError: actions.error,
        addingCategoryToProvList: false,
      };

    case graduationListActions.GET_PROVISIONAL_LIST_REQUEST:
      return {
        ...state,
        listError: {},
        fetchingList: true,
      };
    case graduationListActions.GET_PROVISIONAL_LIST_SUCCESS: {
      const { context, data } = actions;
      const { provisionalList } = state;

      const findContextIndex = provisionalList.findIndex(
        (list) =>
          list.academic_year === context.academic_year &&
          list.campus_id === context.campus_id &&
          list.intake_id === context.intake_id &&
          list.programme_id === context.programme_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          provisionalList: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          provisionalList: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        fetchingList: false,
      };
    }
    case graduationListActions.GET_PROVISIONAL_LIST_ERROR:
      return {
        ...state,
        listError: actions.error,
        fetchingList: false,
      };

    case graduationListActions.GET_FINALISTS_LIST_REQUEST:
      return {
        ...state,
        finalistListError: {},
        gettingFinalists: true,
      };
    case graduationListActions.GET_FINALISTS_LIST_SUCCESS: {
      const { context, data } = actions;
      const { finalistsList } = state;

      const findContextIndex = finalistsList.findIndex(
        (list) =>
          list.academic_year === context.academic_year &&
          list.campus_id === context.campus_id &&
          list.intake_id === context.intake_id &&
          list.programme_id === context.programme_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          finalistsList: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          finalistsList: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingFinalists: false,
      };
    }
    case graduationListActions.GET_FINALISTS_LIST_ERROR:
      return {
        ...state,
        finalistListError: actions.error,
        gettingFinalists: false,
      };

    case graduationListActions.GET_PHD_FINALISTS_LIST_REQUEST:
      return {
        ...state,
        finalistListError: {},
        gettingFinalists: true,
      };
    case graduationListActions.GET_PHD_FINALISTS_LIST_SUCCESS: {
      const { context, data } = actions;
      const { phdFinalistsList } = state;

      const findContextIndex = phdFinalistsList.findIndex(
        (list) => list.entry_academic_year_id === context.entry_academic_year_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          phdFinalistsList: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          phdFinalistsList: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingFinalists: false,
      };
    }
    case graduationListActions.GET_PHD_FINALISTS_LIST_ERROR:
      return {
        ...state,
        finalistListError: actions.error,
        gettingFinalists: false,
      };

    case graduationListActions.GET_RESULT_LIST_REQUEST:
      return {
        ...state,
        resultListError: {},
        resultListContext: actions.params,
        gettingResultList: true,
      };
    case graduationListActions.GET_RESULT_LIST_SUCCESS: {
      const { context, data } = actions;
      const { resultsList } = state;

      const findContextIndex = findIndex(
        resultsList,
        (list) =>
          list.academic_year === context.academic_year &&
          list.study_years === context.study_years &&
          list.semesters === context.semesters &&
          list.graduation_type === context.graduation_type &&
          list.programme_id === context.programme_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          resultsList: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          resultsList: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingResultList: false,
      };
    }
    case graduationListActions.GET_RESULT_LIST_ERROR:
      return {
        ...state,
        resultListError: actions.error,
        gettingResultList: false,
      };

    case graduationListActions.SET_RESULT_LIST_CONTEXT:
      return {
        ...state,
        resultListContext: actions.context,
      };

    case graduationListActions.GET_GRADUATION_LIST_REQUEST:
      return {
        ...state,
        fetchingGraduationList: true,
        graduationListError: {},
      };
    case graduationListActions.GET_GRADUATION_LIST_SUCCESS: {
      const { gradListDetails } = state;
      const { data, context } = actions;
      const getIndex = gradListDetails.findIndex(
        (list) =>
          list.academic_year_id === context.academic_year_id &&
          list.campus_id === context.campus_id &&
          list.intake_id === context.intake_id &&
          list.programme_id === context.programme_id
      );
      const newData = { ...context, data };

      let newState = state;

      if (getIndex === -1) {
        newState = update(state, { gradListDetails: { $push: [newData] } });
      } else {
        newState = update(state, {
          gradListDetails: { [getIndex]: { $set: newData } },
        });
      }

      return {
        ...newState,
        fetchingGraduationList: false,
      };
    }
    case graduationListActions.GET_GRADUATION_LIST_ERROR:
      return {
        ...state,
        graduationListError: actions.error,
        fetchingGraduationList: false,
      };

    case graduationListActions.GET_GRADUATED_STUDENTS_REQUEST:
      return {
        ...state,
        gettingGraduatedStudents: true,
        getGraduatedStudentError: {},
      };
    case graduationListActions.GET_GRADUATED_STUDENTS_SUCCESS: {
      const { graduatedStudents } = state;
      const { context, data } = actions;
      const findDataIndex = findIndex(
        graduatedStudents,
        (list) =>
          list.academic_year_id === context.academic_year_id &&
          list.programme_id === context.programme_id
      );

      const newGraduatedStudents = { ...context, data };

      let newState = state;

      if (findDataIndex !== -1) {
        newState = update(state, {
          graduatedStudents: {
            [findDataIndex]: { $set: newGraduatedStudents },
          },
        });
      } else {
        newState = update(state, {
          graduatedStudents: {
            $push: [newGraduatedStudents],
          },
        });
      }

      return {
        ...newState,
        gettingGraduatedStudents: false,
      };
    }
    case graduationListActions.GET_GRADUATED_STUDENTS_ERROR:
      return {
        ...state,
        getGraduatedStudentError: actions.error,
        gettingGraduatedStudents: false,
      };

    case graduationListActions.DOWNLOAD_DRAFT_RESULTS_REQUEST:
      return {
        ...state,
        downloadError: {},
        downloading: true,
      };
    case graduationListActions.DOWNLOAD_DRAFT_RESULTS_SUCCESS:
      return {
        ...state,
        downloadResponse: actions.data,
        downloading: false,
      };
    case graduationListActions.DOWNLOAD_DRAFT_RESULTS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloading: false,
      };

    case graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_REQUEST:
      return {
        ...state,
        downloadFinalListError: {},
        downloadingFinalList: true,
      };
    case graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_SUCCESS:
      return {
        ...state,
        downloadFinalListResponse: actions.data,
        downloadingFinalList: false,
      };
    case graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_ERROR:
      return {
        ...state,
        downloadFinalListError: actions.error,
        downloadingFinalList: false,
      };

    case graduationListActions.GRADUATE_STUDENTS_REQUEST:
      return {
        ...state,
        graduatingStudents: true,
      };
    case graduationListActions.GRADUATE_STUDENTS_SUCCESS:
      return {
        ...state,
        graduateStudentsSuccess: actions.data,
        graduatingStudents: false,
      };
    case graduationListActions.GRADUATE_STUDENTS_ERROR:
      return {
        ...state,
        graduateStudentsError: actions.error,
        graduatingStudents: false,
      };

    case graduationListActions.BILL_GRAD_LIST_STUDENTS_REQUEST:
      return {
        ...state,
        billingStudents: true,
        billingError: {},
      };
    case graduationListActions.BILL_GRAD_LIST_STUDENTS_SUCCESS:
      return {
        ...state,
        billingSuccess: actions.data,
        billingStudents: false,
      };
    case graduationListActions.BILL_GRAD_LIST_STUDENTS_ERROR:
      return {
        ...state,
        billingError: actions.error,
        billingStudents: false,
      };

    case graduationListActions.SET_FACULTY_CONTEXT:
      return {
        ...state,
        schoolContext: actions.context,
      };

    case graduationListActions.SET_SELECTED_GRADUATION_PROGRAMME:
      return {
        ...state,
        selectedProgramme: actions.data || {},
      };

    case graduationListActions.SET_SELECTED_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: actions.context,
      };

    case graduationListActions.SET_SELECTED_ACADEMIC_YEAR:
      return {
        ...state,
        selectedAcadYear: actions.year,
      };

    case graduationListActions.SET_SELECTED_PHD_CONTEXT:
      return {
        ...state,
        selectedPhdYear: actions.year,
      };

    case graduationListActions.SHOW_PRINT_MODAL:
      return {
        ...state,
        showPrintModal: actions.e,
        printModalValue: actions.value,
      };

    case graduationListActions.SHOW_PREVIEW_MODAL:
      return {
        ...state,
        showPreviewModal: actions.e,
      };

    case graduationListActions.SET_PROVISIONAL_LIST_CONTEXT:
      return {
        ...state,
        provisionalListContext: actions.context,
      };

    case graduationListActions.SET_FINAL_LIST_CONTEXT:
      return {
        ...state,
        finalListContext: actions.context,
      };

    case graduationListActions.SET_SHOW_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: actions.payload,
      };

    case graduationListActions.SET_SELECTED_STUDENT_LIST:
      return {
        ...state,
        selectedStudentList: actions.payload,
      };

    default:
      return state;
  }
};

export default graduationList;
