import { Input } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';

const { Search } = Input;
function SearchField({ onChange, placeholder, ...props }) {
  const debouncedChangeHandler = useMemo(() => debounce(onChange, 400));

  useEffect(() => {
    return () => {
      if (onChange) debouncedChangeHandler.cancel();
    };
  }, []);

  const handleOnChange = (e) => {
    if (onChange) debouncedChangeHandler(e);
  };

  return (
    <Search
      name="searchText"
      onChange={handleOnChange}
      autoComplete="off"
      placeholder={placeholder}
      {...props}
    />
  );
}

SearchField.defaultProps = {
  onChange: null,
  placeholder: 'Enter your search key here',
};

SearchField.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SearchField;
