import { pujabApplicantActions } from '../../actions';
import pujabMgtInitialState from '../../initialState/pujab-mgt';

const pujabApplicants = (
  state = pujabMgtInitialState.pujabApplicants,
  actions
) => {
  switch (actions.type) {
    case pujabApplicantActions.GET_PUJAB_APPLICANTS_REQUEST:
      return {
        ...state,
        gettingApplicants: true,
        getApplicantsError: {},
      };
    case pujabApplicantActions.GET_PUJAB_APPLICANTS_SUCCESS:
      return {
        ...state,
        gettingApplicants: false,
        pujabApplicants: actions.data,
      };
    case pujabApplicantActions.GET_PUJAB_APPLICANTS_ERROR:
      return {
        ...state,
        gettingApplicants: false,
        getApplicantsError: actions.error,
      };

    case pujabApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST:
      return {
        ...state,
        gettingMeritAdmissions: true,
        getMeritAdmissionsError: {},
      };
    case pujabApplicantActions.GET_PROPOSED_ADMISSIONS_SUCCESS:
      return {
        ...state,
        gettingMeritAdmissions: false,
        meritAdmissions: actions.data,
      };
    case pujabApplicantActions.GET_PROPOSED_ADMISSIONS_ERROR:
      return {
        ...state,
        gettingMeritAdmissions: false,
        getMeritAdmissionsError: actions.error,
      };

    case pujabApplicantActions.UPDATE_PUJAB_APPLICANT_ADMISSION_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case pujabApplicantActions.UPDATE_PUJAB_APPLICANT_ADMISSION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: actions.data,
      };
    case pujabApplicantActions.UPDATE_PUJAB_APPLICANT_ADMISSION_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case pujabApplicantActions.UPLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case pujabApplicantActions.UPLOAD_PUJAB_APPLICANTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case pujabApplicantActions.UPLOAD_PUJAB_APPLICANTS_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case pujabApplicantActions.DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case pujabApplicantActions.DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case pujabApplicantActions.DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case pujabApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case pujabApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case pujabApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case pujabApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case pujabApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case pujabApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    default:
      return state;
  }
};

export default pujabApplicants;
