const documentPolicyActions = {
  GET_DOCUMENT_POLICIES_REQUEST: 'GET_DOCUMENT_POLICIES_REQUEST',
  GET_DOCUMENT_POLICIES_SUCCESS: 'GET_DOCUMENT_POLICIES_SUCCESS',
  GET_DOCUMENT_POLICIES_ERROR: 'GET_DOCUMENT_POLICIES_ERROR',

  GET_REG_HISTORY_POLICY_REQUEST: 'GET_REG_HISTORY_POLICY_REQUEST',
  GET_REG_HISTORY_POLICY_SUCCESS: 'GET_REG_HISTORY_POLICY_SUCCESS',
  GET_REG_HISTORY_POLICY_ERROR: 'GET_REG_HISTORY_POLICY_ERROR',

  CREATE_DOCUMENT_POLICY_REQUEST: 'CREATE_DOCUMENT_POLICY_REQUEST',
  CREATE_DOCUMENT_POLICY_SUCCESS: 'CREATE_DOCUMENT_POLICY_SUCCESS',
  CREATE_DOCUMENT_POLICY_ERROR: 'CREATE_DOCUMENT_POLICY_ERROR',

  CREATE_REGISTRATION_HISTORY_REQUEST: 'CREATE_REGISTRATION_HISTORY_REQUEST',
  CREATE_REGISTRATION_HISTORY_SUCCESS: 'CREATE_REGISTRATION_HISTORY_SUCCESS',
  CREATE_REGISTRATION_HISTORY_ERROR: 'CREATE_REGISTRATION_HISTORY_ERROR',

  UPDATE_DOCUMENT_POLICY_REQUEST: 'UPDATE_DOCUMENT_POLICY_REQUEST',
  UPDATE_DOCUMENT_POLICY_SUCCESS: 'UPDATE_DOCUMENT_POLICY_SUCCESS',
  UPDATE_DOCUMENT_POLICY_ERROR: 'UPDATE_DOCUMENT_POLICY_ERROR',

  UPDATE_REGISTRATION_HISTORY_REQUEST: 'UPDATE_REGISTRATION_HISTORY_REQUEST',
  UPDATE_REGISTRATION_HISTORY_SUCCESS: 'UPDATE_REGISTRATION_HISTORY_SUCCESS',
  UPDATE_REGISTRATION_HISTORY_ERROR: 'UPDATE_REGISTRATION_HISTORY_ERROR',

  DELETE_DOCUMENT_POLICY_REQUEST: 'DELETE_DOCUMENT_POLICY_REQUEST',
  DELETE_DOCUMENT_POLICY_SUCCESS: 'DELETE_DOCUMENT_POLICY_SUCCESS',
  DELETE_DOCUMENT_POLICY_ERROR: 'DELETE_DOCUMENT_POLICY_ERROR',

  DELETE_REGISTRATION_HISTORY_REQUEST: 'DELETE_REGISTRATION_HISTORY_REQUEST',
  DELETE_REGISTRATION_HISTORY_SUCCESS: 'DELETE_REGISTRATION_HISTORY_SUCCESS',
  DELETE_REGISTRATION_HISTORY_ERROR: 'DELETE_REGISTRATION_HISTORY_ERROR',

  SET_DOCUMENT_POLICY_TO_EDIT: 'SET_DOCUMENT_POLICY_TO_EDIT',

  getDocumentPolicies: () => ({
    type: documentPolicyActions.GET_DOCUMENT_POLICIES_REQUEST,
  }),

  getRegHistoryPolicy: () => ({
    type: documentPolicyActions.GET_REG_HISTORY_POLICY_REQUEST,
  }),

  createDocumentPolicy: (data) => ({
    type: documentPolicyActions.CREATE_DOCUMENT_POLICY_REQUEST,
    data,
  }),

  createRegHistoryPolicy: (data) => ({
    type: documentPolicyActions.CREATE_REGISTRATION_HISTORY_REQUEST,
    data,
  }),

  updateDocumentPolicy: (policyId, data) => ({
    type: documentPolicyActions.UPDATE_DOCUMENT_POLICY_REQUEST,
    policyId,
    data,
  }),

  updateRegHistoryPolicy: (policyId, data) => ({
    type: documentPolicyActions.UPDATE_REGISTRATION_HISTORY_REQUEST,
    policyId,
    data,
  }),

  deleteDocumentPolicy: (policyId) => ({
    type: documentPolicyActions.DELETE_DOCUMENT_POLICY_REQUEST,
    policyId,
  }),

  deleteRegHistory: (policyId) => ({
    type: documentPolicyActions.DELETE_REGISTRATION_HISTORY_REQUEST,
    policyId,
  }),

  setPolicyToEdit: (policy) => ({
    type: documentPolicyActions.SET_DOCUMENT_POLICY_TO_EDIT,
    policy,
  }),
};

export default documentPolicyActions;
