const previousRegistration = {
  DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST:
    'DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST',
  DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS:
    'DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS',
  DOWNLOAD_REGISTRATION_TEMPLATE_ERROR: 'DOWNLOAD_REGISTRATION_TEMPLATE_ERROR',

  UPLOAD_REGISTRATION_TEMPLATE_REQUEST: 'UPLOAD_REGISTRATION_TEMPLATE_REQUEST',
  UPLOAD_REGISTRATION_TEMPLATE_SUCCESS: 'UPLOAD_REGISTRATION_TEMPLATE_SUCCESS',
  UPLOAD_REGISTRATION_TEMPLATE_ERROR: 'UPLOAD_REGISTRATION_TEMPLATE_ERROR',

  EDIT_PREVIOUS_ENROLLMENT_RECORD_REQUEST:
    'EDIT_PREVIOUS_ENROLLMENT_RECORD_REQUEST',
  EDIT_PREVIOUS_ENROLLMENT_RECORD_SUCCESS:
    'EDIT_PREVIOUS_ENROLLMENT_RECORD_SUCCESS',
  EDIT_PREVIOUS_ENROLLMENT_RECORD_ERROR:
    'EDIT_PREVIOUS_ENROLLMENT_RECORD_ERROR',

  DELETE_PREVIOUS_ENROLLMENT_RECORD_REQUEST:
    'DELETE_PREVIOUS_ENROLLMENT_RECORD_REQUEST',
  DELETE_PREVIOUS_ENROLLMENT_RECORD_SUCCESS:
    'DELETE_PREVIOUS_ENROLLMENT_RECORD_SUCCESS',
  DELETE_PREVIOUS_ENROLLMENT_RECORD_ERROR:
    'DELETE_PREVIOUS_ENROLLMENT_RECORD_ERROR',

  downloadPreviousRegistration: () => ({
    type: previousRegistration.DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST,
  }),

  uploadPreviousRegistration: (data) => ({
    type: previousRegistration.UPLOAD_REGISTRATION_TEMPLATE_REQUEST,
    data,
  }),

  editMigratedEnrollmentRecord: (id, studentNumber, data) => ({
    type: previousRegistration.EDIT_PREVIOUS_ENROLLMENT_RECORD_REQUEST,
    id,
    studentNumber,
    data,
  }),

  deleteMigratedEnrollmentRecord: (studentNumber, id) => ({
    type: previousRegistration.DELETE_PREVIOUS_ENROLLMENT_RECORD_REQUEST,
    studentNumber,
    id,
  }),
};

export default previousRegistration;
