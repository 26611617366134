import { refundTransactionActions } from '../../actions';
import initialState from '../../initialState';

const refundTransaction = (state = initialState.refundTransaction, actions) => {
  switch (actions.type) {
    case refundTransactionActions.SEARCH_REFUND_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchingTransaction: true,
        fetchTransactionError: {},
      };
    case refundTransactionActions.SEARCH_REFUND_TRANSACTION_SUCCESS:
      return {
        ...state,
        fetchTransaction: actions.data,
        fetchingTransaction: false,
      };

    case refundTransactionActions.SEARCH_REFUND_TRANSACTION_ERROR:
      return {
        ...state,
        fetchTransactionError: actions.error,
        fetchingTransaction: false,
      };

    case refundTransactionActions.FETCH_REFUNDED_TRANSACTIONS_REQUEST:
      return {
        ...state,
        fetchingRefundedTransactions: true,
        fetchRefundedTransactionsError: {},
      };
    case refundTransactionActions.FETCH_REFUNDED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        fetchedRefundedTransactions: actions.data,
        fetchingRefundedTransactions: false,
      };
    case refundTransactionActions.FETCH_REFUNDED_TRANSACTIONS_ERROR:
      return {
        ...state,
        fetchRefundedTransactionsError: actions.error,
        fetchingRefundedTransactions: false,
      };

    case refundTransactionActions.REQUEST_PAYMENT_REFUND_REQUEST:
      return {
        ...state,
        requestingPaymentRefund: true,
        requestPaymentRefundError: {},
      };
    case refundTransactionActions.REQUEST_PAYMENT_REFUND_SUCCESS:
      return {
        ...state,
        requestRefundSuccess: actions.data,
        requestingPaymentRefund: false,
      };
    case refundTransactionActions.REQUEST_PAYMENT_REFUND_ERROR:
      return {
        ...state,
        requestPaymentRefundError: actions.error,
        requestingPaymentRefund: false,
      };

    case refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_REQUEST:
      return {
        ...state,
        gettingPendingPaymentRefunds: true,
        pendingPaymentRefundsError: {},
      };
    case refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_SUCCESS:
      return {
        ...state,
        pendingPaymentRefunds: actions.data,
        gettingPendingPaymentRefunds: false,
      };
    case refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_ERROR:
      return {
        ...state,
        pendingPaymentRefundsError: actions.error,
        gettingPendingPaymentRefunds: false,
      };

    case refundTransactionActions.APPROVE_PENDING_REFUND_REQUEST:
      return {
        ...state,
        approvingPaymentRefundsRequest: true,
        approvingPaymentRefundsError: {},
      };
    case refundTransactionActions.APPROVE_PENDING_REFUND_SUCCESS:
      return {
        ...state,
        approvedPaymentRefunds: actions.data,
        approvingPaymentRefundsRequest: false,
      };
    case refundTransactionActions.APPROVE_PENDING_REFUND_ERROR:
      return {
        ...state,
        approvingPaymentRefundsError: actions.error,
        approvingPaymentRefundsRequest: false,
      };

    case refundTransactionActions.DELETE_A_PENDING_REFUND_REQUEST:
      return {
        ...state,
        deletingPendingPaymentRefunds: true,
        deletePaymentRefundsError: {},
      };
    case refundTransactionActions.DELETE_A_PENDING_REFUND_SUCCESS:
      return {
        ...state,
        deletedPaymentRefunds: actions.data,
        deletingPendingPaymentRefunds: false,
      };
    case refundTransactionActions.DELETE_A_PENDING_REFUND_ERROR:
      return {
        ...state,
        deletePaymentRefundsError: actions.error,
        deletingPendingPaymentRefunds: false,
      };

    case refundTransactionActions.SET_SHOW_REFUND_PAYMENT_MODAL:
      return {
        ...state,
        showRefundModal: actions.payload,
      };

    case refundTransactionActions.SET_PAYMENT_REFUND_APPROVAL_DATA:
      return {
        ...state,
        refundApproval: actions.data,
      };

    case refundTransactionActions.SET_CURRENT_STUDENT_PAYMENT_REFUNDS:
      return {
        ...state,
        currentStudentPaymentRefunds: actions.data,
      };

    default:
      return state;
  }
};

export default refundTransaction;
