import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { subjectActions } from '../../actions';

function* getSubjects(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/subjects',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: subjectActions.GET_SUBJECTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectActions.GET_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* createSubjects(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/subjects',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: subjectActions.CREATE_SUBJECT_SUCCESS,
      data: response,
    });

    yield put({
      type: subjectActions.GET_SUBJECTS_REQUEST,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectActions.CREATE_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* deleteSubject(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/subjects/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: subjectActions.DELETE_SUBJECT_SUCCESS,
      data: response,
    });

    yield put({
      type: subjectActions.GET_SUBJECTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: subjectActions.DELETE_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* getVersionSubjectCombination(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/subject-combinations/version-subject-combination',
      method: 'GET',
      params: { version_id: actions.id },
    });
    yield put({
      type: subjectActions.GET_VERSION_COMBINATIONS_SUCCESS,
      data: response.versionSubjectCombinations,
    });
    yield put({
      type: subjectActions.GET_SUBJECTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: subjectActions.GET_VERSION_COMBINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* updateSubject(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/subjects/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: subjectActions.UPDATE_SUBJECT_SUCCESS,
      data: response,
    });

    yield put({
      type: subjectActions.GET_SUBJECTS_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_SUBJECT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: subjectActions.UPDATE_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

// SubjectCombinations
function* getSubjectCombinations() {
  try {
    const response = yield axios({
      url: '/programme-mgt/subject-combinations/with-programmes',
      method: 'GET',
    });
    yield put({
      type: subjectActions.GET_SUBJECT_COMBINATIONS_SUCCESS,
      data: response.subjectCombinationsProgrammes,
    });
  } catch (error) {
    yield put({
      type: subjectActions.GET_SUBJECT_COMBINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* createSubjectCombinations(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/subject-combinations',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: subjectActions.CREATE_SUBJECT_COMBINATION_SUCCESS,
      data: response,
    });
    yield put({
      type: subjectActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    });
    yield put({
      type: 'SHOW_CREATE_SUBJECT_COMBINATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: subjectActions.CREATE_SUBJECT_COMBINATION_ERROR,
      error: error.data,
    });
  }
}

function* updateSubjectCombination(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/subject-combination/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: subjectActions.UPDATE_SUBJECT_COMBINATION_SUCCESS,
      data: response,
    });

    yield put({
      type: subjectActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_SUBJECT_COMBINATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: subjectActions.UPDATE_SUBJECT_COMBINATION_ERROR,
      error: error.data,
    });
  }
}

function* deleteSubjectCombination(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/subject-combination/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: subjectActions.DELETE_SUBJECT_COMBINATION_SUCCESS,
      data: response,
    });
    yield put({
      type: subjectActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: subjectActions.DELETE_SUBJECT_COMBINATION_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSubjects() {
  yield takeLatest(subjectActions.GET_SUBJECTS_REQUEST, getSubjects);
}

function* watchCreateSubjects() {
  yield takeLatest(subjectActions.CREATE_SUBJECT_REQUEST, createSubjects);
}

function* watchDeleteSubject() {
  yield takeLatest(subjectActions.DELETE_SUBJECT_REQUEST, deleteSubject);
}

function* watchGetVersionSubjectCombination() {
  yield takeLatest(
    subjectActions.GET_VERSION_COMBINATIONS_REQUEST,
    getVersionSubjectCombination
  );
}

function* watchUpdateSubject() {
  yield takeLatest(subjectActions.UPDATE_SUBJECT_REQUEST, updateSubject);
}

function* watchGetSubjectCombinations() {
  yield takeLatest(
    subjectActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    getSubjectCombinations
  );
}

function* watchCreateSubjectCombinations() {
  yield takeLatest(
    subjectActions.CREATE_SUBJECT_COMBINATION_REQUEST,
    createSubjectCombinations
  );
}

function* watchDeleteSubjectCombination() {
  yield takeLatest(
    subjectActions.DELETE_SUBJECT_COMBINATION_REQUEST,
    deleteSubjectCombination
  );
}

function* watchUpdateSubjectCombination() {
  yield takeLatest(
    subjectActions.UPDATE_SUBJECT_COMBINATION_REQUEST,
    updateSubjectCombination
  );
}

export default [
  fork(watchGetSubjects),
  fork(watchCreateSubjects),
  fork(watchDeleteSubject),
  fork(watchUpdateSubject),
  fork(watchGetSubjectCombinations),
  fork(watchCreateSubjectCombinations),
  fork(watchDeleteSubjectCombination),
  fork(watchGetVersionSubjectCombination),
  fork(watchUpdateSubjectCombination),
];
