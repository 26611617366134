import { academicFeesPolicyActions } from '../../actions';
import initialState from '../../initialState';

const academicFeesPolicy = (
  state = initialState.academicFeesPolicy,
  actions
) => {
  switch (actions.type) {
    case academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_SUCCESS:
      return {
        ...state,
        academicFeesPolicies: actions.data,
        loading: false,
      };
    case academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
        created: {},
      };
    case academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
        created: {},
      };
    case academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
        created: {},
      };
    case academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case academicFeesPolicyActions.SET_ACADEMIC_FEES_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policyToEdit,
      };

    case academicFeesPolicyActions.SET_SELECTED_POLICY:
      return {
        ...state,
        selectedPolicy: actions.selectedPolicy,
      };

    default:
      return state;
  }
};

export default academicFeesPolicy;
