import { concededPassPolicyActions } from '../../actions';
import initialState from '../../initialState';

const concededPassPolicy = (
  state = initialState.concededPassPolicy,
  actions
) => {
  switch (actions.type) {
    case concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creatingPolicy: true,
      };
    case concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creatingPolicy: false,
      };
    case concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creatingPolicy: false,
      };

    case concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_SUCCESS:
      return {
        ...state,
        concededPassPolicies: actions.data,
        loading: false,
      };
    case concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updatingPolicy: true,
      };
    case concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updatingPolicy: false,
      };
    case concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updatingPolicy: false,
      };

    case concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deletingPolicy: true,
      };
    case concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deletingPolicy: false,
      };
    case concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deletingPolicy: false,
      };

    case concededPassPolicyActions.SET_CONCEDED_PASS_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policyToEdit,
      };

    case concededPassPolicyActions.SHOW_CONCEDED_PASS_POLICY_MODAL:
      return {
        ...state,
        showModal: actions.payload,
      };

    default:
      return state;
  }
};

export default concededPassPolicy;
