const eVotingInitialState = {
  electivePosition: {
    gettingElectivePositions: false,
    electivePositions: [],
    getElectivePositionError: {},

    gettingSingleElectivePosition: false,
    singleElectivePositions: [],
    getSingleElectivePositionError: {},

    gettingVerifiedVoters: false,
    verifiedVoters: [],
    getVerifiedVoterError: {},

    gettingEligibleVoters: false,
    eligibleVoters: [],
    getEligibleVoterError: {},

    selectedProgramme: {},

    addingElectivePosition: false,
    addSuccess: {},
    addError: {},

    verifying: false,
    verifyError: {},
    verified: {},

    updatingElectivePosition: false,
    updateSuccess: {},
    updateError: {},

    currentTab: 'all-positions',
    selectedRow: {},

    deletingElectivePosition: false,
    deleteSuccess: {},
    deleteError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},
  },
};

export default eVotingInitialState;
