import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { pujabReportActions } from '../../actions';

function* getPujabApplicantsReport(actions) {
  try {
    const { academicYear } = actions;

    const response = yield axios({
      url: `/pujab/running-admissions/applicants/report/${academicYear}`,
      method: 'GET',
    });
    yield put({
      type: pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_SUCCESS,
      data: response.data,
      academicYear,
    });
  } catch (error) {
    yield put({
      type: pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadPujabApplicantsReport(actions) {
  try {
    const { academicYear, category } = actions;

    yield axios({
      url: `/pujab/running-admissions/applicants/download-report/${academicYear}`,
      method: 'POST',
      data: { category },
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PUJAB-APPLICATIONS.xlsx`
      );
    });
    yield put({
      type: pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_SUCCESS,
      data: { message: 'Report Downloaded successfuly' },
      academicYear,
    });
  } catch (error) {
    yield put({
      type: pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_ERROR,
      error,
    });
  }
}

function* getPujabReportDetails(actions) {
  try {
    const { academicYear } = actions;

    const response = yield axios({
      url: `/pujab/running-admissions/applicants/report-details/${academicYear}`,
      method: 'GET',
    });
    yield put({
      type: pujabReportActions.GET_PUJAB_REPORT_DETAILS_SUCCESS,
      data: response.data,
      academicYear,
    });
  } catch (error) {
    yield put({
      type: pujabReportActions.GET_PUJAB_REPORT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeChoices(actions) {
  try {
    const { applicationId } = actions;

    const response = yield axios({
      url: `/pujab/running-admissions/applicants/programme-choices/${applicationId}`,
      method: 'GET',
    });
    yield put({
      type: pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_SUCCESS,
      data: response.data,
      applicationId,
    });
  } catch (error) {
    yield put({
      type: pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPujabApplicantsReport() {
  yield takeLatest(
    pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_REQUEST,
    getPujabApplicantsReport
  );
}

function* watchDownloadPujabApplicantsReport() {
  yield takeLatest(
    pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_REQUEST,
    downloadPujabApplicantsReport
  );
}

function* watchGetPujabReportDetails() {
  yield takeLatest(
    pujabReportActions.GET_PUJAB_REPORT_DETAILS_REQUEST,
    getPujabReportDetails
  );
}

function* watchGetPujabProgrammeChoices() {
  yield takeLatest(
    pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_REQUEST,
    getProgrammeChoices
  );
}

export default [
  fork(watchGetPujabApplicantsReport),
  fork(watchGetPujabReportDetails),
  fork(watchDownloadPujabApplicantsReport),
  fork(watchGetPujabProgrammeChoices),
];
