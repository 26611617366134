import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { buildingActions } from '../../actions';
import initialState from '../../initialState';

function building(state = initialState.building, actions) {
  switch (actions.type) {
    case buildingActions.ADD_BUILDING_REQUEST:
      return {
        ...state,
        creatingBuilding: true,
        creatingBuildingError: {},
      };
    case buildingActions.ADD_BUILDING_SUCCESS:
      return {
        ...state,
        creatingBuildingSuccess: actions.data,
        creatingBuilding: false,
      };
    case buildingActions.ADD_BUILDING_ERROR:
      return {
        ...state,
        creatingBuildingError: actions.error,
        creatingBuilding: false,
      };

    case buildingActions.GET_BUILDINGS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: {},
      };
    case buildingActions.GET_BUILDINGS_SUCCESS:
      return {
        ...state,
        buildings: actions.data,
        fetching: false,
      };
    case buildingActions.GET_BUILDINGS_ERROR:
      return {
        ...state,
        error: actions.error,
        fetching: false,
      };

    case buildingActions.GET_BUILDINGS_BY_CAMPUS_REQUEST:
      return {
        ...state,
        gettingByCampus: true,
        getByCampusError: {},
      };
    case buildingActions.GET_BUILDINGS_BY_CAMPUS_SUCCESS: {
      const { campusId, data } = actions;
      const { buildingsByCampus } = state;

      const findContextIndex = findIndex(
        buildingsByCampus,
        (list) => list.campusId === campusId
      );

      const contextData = { campusId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          buildingsByCampus: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          buildingsByCampus: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingByCampus: false,
      };
    }

    case buildingActions.GET_BUILDINGS_BY_CAMPUS_ERROR:
      return {
        ...state,
        getByCampusError: actions.error,
        gettingByCampus: false,
      };
    case buildingActions.SET_ROOM_ALLOCATION_REPORT_CONTEXT:
      return {
        ...state,
        roomAllocationContext: actions.data,
      };
    case buildingActions.GET_ROOM_ALLOCATION_REPORT_REQUEST:
      return {
        ...state,
        roomAllocationContext: actions.context,
        gettingRoomAllocationReport: true,
        getRoomAllocationReportError: {},
      };
    case buildingActions.GET_ROOM_ALLOCATION_REPORT_SUCCESS: {
      const { context, data } = actions;
      const { roomAllocationReport } = state;

      const findContextIndex = findIndex(
        roomAllocationReport,
        (list) =>
          list.academic_year_id === context.academic_year_id &&
          list.semester_id === context.semester_id &&
          list.campus_id === context.campus_id &&
          list.intake_id === context.intake_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          roomAllocationReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          roomAllocationReport: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingRoomAllocationReport: false,
      };
    }

    case buildingActions.GET_ROOM_ALLOCATION_REPORT_ERROR:
      return {
        ...state,
        getRoomAllocationReportError: actions.error,
        gettingRoomAllocationReport: false,
      };
    case buildingActions.SET_VIEW_ROOM_ALLOCATIONS:
      return {
        ...state,
        roomAllocations: actions.data,
      };

    case buildingActions.DOWNLOAD_BUILDINGS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        errorDownloadingTemplate: {},
      };
    case buildingActions.DOWNLOAD_BUILDINGS_TEMPLATE_SUCCESS:
      return {
        ...state,
        successDownloadingTemplate: actions.data,
        downloadingTemplate: false,
      };
    case buildingActions.DOWNLOAD_BUILDINGS_TEMPLATE_ERROR:
      return {
        ...state,
        errorDownloadingTemplate: actions.error,
        downloadingTemplate: false,
      };

    case buildingActions.UPLOAD_BUILDINGS_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case buildingActions.UPLOAD_BUILDINGS_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadResponse: actions.data,
      };
    case buildingActions.UPLOAD_BUILDINGS_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case buildingActions.UPLOAD_ROOMS_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case buildingActions.UPLOAD_ROOMS_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadResponse: actions.data,
      };
    case buildingActions.UPLOAD_ROOMS_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case buildingActions.SET_BUILDING_CAMPUS:
      return {
        ...state,
        selectedCampusId: actions.data,
      };

    case buildingActions.DELETE_BUILDINGS_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteResponse: {},
        deleteError: {},
      };
    case buildingActions.DELETE_BUILDINGS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case buildingActions.DELETE_BUILDINGS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case buildingActions.DELETE_ROOM_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteError: {},
      };
    // todo
    case buildingActions.DELETE_ROOM_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case buildingActions.DELETE_ROOM_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case buildingActions.EDIT_BUILDINGS_REQUEST:
      return {
        ...state,
        editing: true,
        editResponse: {},
        editError: {},
      };
    case buildingActions.EDIT_BUILDINGS_SUCCESS:
      return {
        ...state,
        editing: false,
        editResponse: actions.data,
      };
    case buildingActions.EDIT_BUILDINGS_ERROR:
      return {
        ...state,
        editing: false,
        editError: actions.error,
      };
    case buildingActions.EDIT_ROOM_REQUEST:
      return {
        ...state,
        editingRoom: true,
        editResponse: {},
        editError: {},
      };
    case buildingActions.EDIT_ROOM_SUCCESS:
      return {
        ...state,
        editingRoom: false,
        editResponse: actions.data,
      };
    case buildingActions.EDIT_ROOM_ERROR:
      return {
        ...state,
        editingRoom: false,
        editError: actions.error,
      };

    default:
      return state;
  }
}

export default building;
