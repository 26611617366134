import PropTypes, { array, object } from 'prop-types';
import { get } from 'lodash';
import React from 'react';
import { Col } from 'react-bootstrap';
import SelectInput from './SelectInput';

function EventContext({
  studyYearOptions,
  semesterOptions,
  academicYearOptions,
  register,
  errors,
  ...props
}) {
  return (
    <>
      <Col md={4}>
        <SelectInput
          label="STUDY YEAR"
          selectOptions={studyYearOptions}
          register={register({
            required: 'This field is required',
          })}
          name="study_year_id"
          error={get(errors, 'study_year_id.message')}
          {...props}
        />
      </Col>
      <Col md={4}>
        <SelectInput
          label="SEMESTER"
          selectOptions={semesterOptions}
          register={register({
            required: 'This field is required',
          })}
          name="semester_id"
          error={get(errors, 'semester_id.message')}
          {...props}
        />
      </Col>
      <Col md={4}>
        <SelectInput
          label="ACADEMIC YEAR"
          selectOptions={academicYearOptions}
          register={register({
            required: 'This field is required',
          })}
          name="academic_year_id"
          error={get(errors, 'academic_year_id.message')}
          {...props}
        />
      </Col>
    </>
  );
}

EventContext.defaultProps = {
  studyYearOptions: [],
  semesterOptions: [],
  academicYearOptions: [],
  register: null,
  errors: {},
};

EventContext.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.oneOfType([object]),
  studyYearOptions: PropTypes.oneOfType([array]),
  semesterOptions: PropTypes.oneOfType([array]),
  academicYearOptions: PropTypes.oneOfType([array]),
};

export default EventContext;
