const specialization = {
  // Specializations
  GET_SPECIALIZATIONS_REQUEST: 'GET_SPECIALIZATIONS_REQUEST',
  GET_SPECIALIZATIONS_SUCCESS: 'GET_SPECIALIZATIONS_SUCCESS',
  GET_SPECIALIZATIONS_ERROR: 'GET_SPECIALIZATIONS_ERROR',

  CREATE_SPECIALIZATION_SUCCESS: 'CREATE_SPECIALIZATION_SUCCESS',
  CREATE_SPECIALIZATION_REQUEST: 'CREATE_SPECIALIZATION_REQUEST',
  CREATE_SPECIALIZATION_ERROR: 'CREATE_SPECIALIZATION_ERROR',

  DELETE_SPECIALIZATION_SUCCESS: 'DELETE_SPECIALIZATION_SUCCESS',
  DELETE_SPECIALIZATION_REQUEST: 'DELETE_SPECIALIZATION_REQUEST',
  DELETE_SPECIALIZATION_ERROR: 'DELETE_SPECIALIZATION_ERROR',

  UPDATE_SPECIALIZATION_SUCCESS: 'UPDATE_SPECIALIZATION_SUCCESS',
  UPDATE_SPECIALIZATION_REQUEST: 'UPDATE_SPECIALIZATION_REQUEST',
  UPDATE_SPECIALIZATION_ERROR: 'UPDATE_SPECIALIZATION_ERROR',

  getSpecializations: (data) => ({
    type: specialization.GET_SPECIALIZATIONS_REQUEST,
    data,
  }),

  createSpecialization: (data) => ({
    type: specialization.CREATE_SPECIALIZATION_REQUEST,
    data,
  }),
  deleteSpecialization: (id) => ({
    type: specialization.DELETE_SPECIALIZATION_REQUEST,
    id,
  }),

  updateSpecialization: (id, data) => ({
    type: specialization.UPDATE_SPECIALIZATION_REQUEST,
    id,
    data,
  }),
};
export default specialization;
