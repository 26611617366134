import { institutionStructureActions } from '../../actions';
import initialState from '../../initialState';

const institutionStructure = (
  state = initialState.institutionStructure,
  actions
) => {
  switch (actions.type) {
    case institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case institutionStructureActions.GET_INSTITUTION_STRUCTURE_SUCCESS:
      return {
        ...state,
        institutionStructure: actions.data || {},
        loading: false,
      };
    case institutionStructureActions.GET_INSTITUTION_STRUCTURE_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case institutionStructureActions.GET_INSTITUTION_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        dashboardError: {},
      };
    case institutionStructureActions.GET_INSTITUTION_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: actions.data,
        loading: false,
      };
    case institutionStructureActions.GET_INSTITUTION_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        dashboardError: actions.error,
        loading: false,
      };

    case institutionStructureActions.CREATE_INSTITUTION_STRUCTURE_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case institutionStructureActions.CREATE_INSTITUTION_STRUCTURE_SUCCESS:
      return {
        ...state,
        institutionStructure: actions.data,
        creating: false,
      };
    case institutionStructureActions.CREATE_INSTITUTION_STRUCTURE_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case institutionStructureActions.UPDATE_INSTITUTION_STRUCTURE_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case institutionStructureActions.UPDATE_INSTITUTION_STRUCTURE_SUCCESS:
      return {
        ...state,
        institutionStructure: actions.data,
        updateResponse: actions.data,
        updating: false,
      };
    case institutionStructureActions.UPDATE_INSTITUTION_STRUCTURE_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case institutionStructureActions.UPLOAD_INSTITUTION_LOGO_REQUEST:
      return {
        ...state,
        uploadError: {},
        uploading: true,
      };
    case institutionStructureActions.UPLOAD_INSTITUTION_LOGO_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case institutionStructureActions.UPLOAD_INSTITUTION_LOGO_ERROR:
      return {
        ...state,
        uploadError: actions.error,
        uploading: false,
      };

    default:
      return state;
  }
};

export default institutionStructure;
