import { emisStudentActions } from '../../actions';
import systemManager from '../../initialState/system-mgt';

const emisStudents = (state = systemManager.emisStudentRecords, actions) => {
  switch (actions.type) {
    case emisStudentActions.GET_EMIS_STUDENTS_REQUEST:
      return {
        ...state,
        gettingStudents: true,
        getStudentError: {},
      };
    case emisStudentActions.GET_EMIS_STUDENTS_SUCCESS:
      return {
        ...state,
        gettingStudents: false,
        emisStudents: actions.data,
      };
    case emisStudentActions.GET_EMIS_STUDENTS_ERROR:
      return {
        ...state,
        gettingStudents: false,
        getStudentError: actions.error,
      };

    case emisStudentActions.SUBMIT_EMIS_STUDENT_REQUEST:
      return {
        ...state,
        submittingStudents: true,
        submitError: {},
      };
    case emisStudentActions.SUBMIT_EMIS_STUDENT_SUCCESS:
      return {
        ...state,
        submittingStudents: false,
        submitSuccess: actions.data,
      };
    case emisStudentActions.SUBMIT_EMIS_STUDENT_ERROR:
      return {
        ...state,
        submittingStudents: false,
        submitError: actions.error,
      };

    default:
      return state;
  }
};

export default emisStudents;
