const securityProfile = {
  GET_SECURITY_PROFILE_REQUEST: 'GET_SECURITY_PROFILE_REQUEST',
  GET_SECURITY_PROFILE_SUCCESS: 'GET_SECURITY_PROFILE_SUCCESS',
  GET_SECURITY_PROFILE_ERROR: 'GET_SECURITY_PROFILE_ERROR',

  UPDATE_SECURITY_PROFILE_SUCCESS: 'UPDATE_SECURITY_PROFILE_SUCCESS',
  UPDATE_SECURITY_PROFILE_REQUEST: 'UPDATE_SECURITY_PROFILE_REQUEST',
  UPDATE_SECURITY_PROFILE_ERROR: 'UPDATE_SECURITY_PROFILE_ERROR',

  CREATE_SECURITY_PROFILE_SUCCESS: 'CREATE_SECURITY_PROFILE_SUCCESS',
  CREATE_SECURITY_PROFILE_REQUEST: 'CREATE_SECURITY_PROFILE_REQUEST',
  CREATE_SECURITY_PROFILE_ERROR: 'CREATE_SECURITY_PROFILE_ERROR',

  getSecurityProfile: (data) => ({
    type: securityProfile.GET_SECURITY_PROFILE_REQUEST,
    data,
  }),

  createSecurityProfile: (data) => ({
    type: securityProfile.CREATE_SECURITY_PROFILE_REQUEST,
    data,
  }),

  updateSecurityProfile: (data, securityProfileId) => ({
    type: securityProfile.UPDATE_SECURITY_PROFILE_REQUEST,
    data,
    securityProfileId,
  }),
};

export default securityProfile;
