import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { concededPassPolicyActions } from '../../actions';

function* createConcededPassPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/conceded-pass-policies`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* getConcededPassPolicy() {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/conceded-pass-policies`,
      method: 'GET',
    });
    yield put({
      type: concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* editConcededPassPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/conceded-pass-policies/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: concededPassPolicyActions.SET_CONCEDED_PASS_POLICY_TO_EDIT,
      policyToEdit: {},
    });
    yield put({
      type: concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_REQUEST,
    });
  } catch (error) {
    yield put({
      type: concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteConcededPassPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/conceded-pass-policies/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_REQUEST,
    });

    yield put({
      type: concededPassPolicyActions.SHOW_CONCEDED_PASS_POLICY_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateConcededPassPolicy() {
  yield takeLatest(
    concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_REQUEST,
    createConcededPassPolicy
  );
}

function* watchGetConcededPassPolicy() {
  yield takeLatest(
    concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_REQUEST,
    getConcededPassPolicy
  );
}

function* watchEditConcededPassPolicy() {
  yield takeLatest(
    concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_REQUEST,
    editConcededPassPolicy
  );
}

function* watchDeleteConcededPassPolicy() {
  yield takeLatest(
    concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_REQUEST,
    deleteConcededPassPolicy
  );
}

export default [
  fork(watchCreateConcededPassPolicy),
  fork(watchGetConcededPassPolicy),
  fork(watchEditConcededPassPolicy),
  fork(watchDeleteConcededPassPolicy),
];
