import axios from 'axios';
import FileSaver from 'file-saver';
import { toUpper } from 'lodash';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { transactionActions } from '../../actions';

function* searchTransaction(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/search-transactions`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: transactionActions.SEARCH_UNIVERSAL_TRANSACTION_SUCCESS,
      data: {
        data: response.data,
        ...actions?.data,
      },
    });
  } catch (error) {
    yield put({
      type: transactionActions.SEARCH_UNIVERSAL_TRANSACTION_ERROR,
      error: error.data,
      payload: actions.data,
    });
  }
}

function* getSponsorReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/sponsor-payments`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: transactionActions.GET_SPONSOR_PAYMENTS_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_SPONSOR_PAYMENTS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getSponsorAllocations(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/allocation-report`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: transactionActions.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_ERROR,
      error: error.data,
    });
  }
}

function* getTransactionReports(actions) {
  yield put({
    type: transactionActions.SELECTED_REPORT_CONTEXT,
    context: actions.context,
  });

  try {
    const response = yield axios({
      url: `/universal-payments/reports`,
      method: 'GET',
      params: actions.context,
      timeout: 12000000,
    });
    yield put({
      type: transactionActions.GET_TRANSACTION_REPORTS_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_TRANSACTION_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getCollectionReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/${actions.url}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: transactionActions.GET_COLLECTION_REPORTS_SUCCESS,
      data: response.data,
      reportCategory: actions.reportCategory,
    });
    if (actions.url.includes('duration=annual')) {
      yield put({
        type: transactionActions.GET_COLLECTION_REPORTS_REQUEST,
        reportCategory: 'fn-annual',
        url: 'quarterly-annually?duration=fn-annual',
      });
    }
  } catch (error) {
    yield put({
      type: transactionActions.GET_COLLECTION_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getStudentRevenueCollectionReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/revenue-item-report`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_STUDENT_REVENUE_REPORTS_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_STUDENT_REVENUE_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getOutstandingBalanceReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/outstanding-balances-reports`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_OUTSTANDING_BALANCES_REPORT_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_OUTSTANDING_BALANCES_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getStudentWaiversReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/waiver-reports`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_STUDENT_WAIVERS_REPORT_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_STUDENT_WAIVERS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getGlobalRevenueCollectionReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/global-payment-revenue`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_GLOBAL_REVENUE_REPORTS_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_GLOBAL_REVENUE_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getGlobalDetailedReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/global-payment-revenue`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_DETAILED_GLOBAL_REVENUE_REPORTS_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_DETAILED_GLOBAL_REVENUE_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadGlobalRevenueCollectionReport(actions) {
  try {
    yield axios({
      url: `/universal-payments/reports/download-global`,
      method: 'POST',
      data: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-GLOBAL-REVENUE-COLLECTIONS-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_GLOBAL_REVENUE_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_GLOBAL_REVENUE_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getMonthlyCollections() {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/month-collections`,
      method: 'GET',
    });
    yield put({
      type: transactionActions.GET_MONTHLY_COLLECTIONS_REPORTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_MONTHLY_COLLECTIONS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getUniversalPaymentsReports(actions) {
  const { payments_from: from, payments_to: to } = actions.context;
  try {
    const response = yield axios({
      url: `/universal-payments/reports/account-report?payments_from=${from}&payments_to=${to}`,
      method: 'GET',
      timeout: 1200000,
    });
    yield put({
      type: transactionActions.GET_UNIVERSAL_PAYMENTS_REPORTS_SUCCESS,
      data: response.data,
      reportCategory: actions.reportCategory,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_UNIVERSAL_PAYMENTS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getFinancialYrReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-generated-fy`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getFinancialYrUnallocatedReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-unallocated-details`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getQuarterlyOpeningReceivablesReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-receivable-details`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_SUCCESS,
      data: response.filtered,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getQuarterlyBillingDetailsReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-billing-details`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS,
      data: response.filtered,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getQuarterlyStudentDetailsReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-students-details`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getQuarterlyFacultyReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-academic-units`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_QUARTERLY_FACULTY_REPORT_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_QUARTERLY_FACULTY_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getQuarterlyPaymentTypeDetailsReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-payments-details`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_QUARTERLY_PAYMENT_TYPE_DETAILS_REPORTS_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_QUARTERLY_PAYMENT_TYPE_DETAILS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* generateFinancialYrReports(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/generate-fy-staff`,
      method: 'GET',
      params: actions.context,
      timeout: 1800000,
    });
    yield put({
      type: transactionActions.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS,
      data: response.result,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getSelectedQuarterReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/view-generated-fy`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_SELECTED_QUARTER_REPORT_SUCCESS,
      data: response.result,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_SELECTED_QUARTER_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* generateSelectedQuarterReport(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/generate-${actions.quarter}-quarter`,
      method: 'GET',
      params: actions.context,
      timeout: 1800000,
    });
    yield put({
      type: transactionActions.GENERATE_SELECTED_QUARTER_REPORT_SUCCESS,
      data: response.result,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GENERATE_SELECTED_QUARTER_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getReportsDetails(actions) {
  const {
    payments_from: from,
    payments_to: to,
    account_id: id,
  } = actions.context;
  try {
    const response = yield axios({
      url: `/universal-payments/reports/account-details?payments_from=${from}&payments_to=${to}&account_id=${id}`,
      method: 'GET',
    });
    yield put({
      type: transactionActions.GET_UNIVERSAL_REPORTS_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_UNIVERSAL_REPORTS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getPeriodicReportsDetails(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: transactionActions.GET_DETAILED_PERIOD_REPORTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: transactionActions.GET_DETAILED_PERIOD_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadDetailedReport(actions) {
  const {
    payments_from: from,
    payments_to: to,
    account_id: id,
  } = actions.context;
  try {
    yield axios({
      url: `/universal-payments/chart-of-accounts/download-report?payments_from=${from}&payments_to=${to}&account_id=${id}&transaction_category=UNIVERSAL`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-UNIVERSAL-REPORTS.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadReportSummery(actions) {
  const { context, reportType } = actions;
  let endPoint;
  if (reportType === 'pdf')
    endPoint =
      '/universal-payments/chart-of-accounts/download-summary-report-pdf';
  else endPoint = '/universal-payments/chart-of-accounts/download-summary';

  try {
    yield axios({
      url: endPoint,
      method: 'POST',
      data: actions.data,
      params: context,
      timeout: 300000,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-UNIVERSAL-SUMMERY-REPORTS.${reportType}`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadDetailedTransactionReports(actions) {
  try {
    yield axios({
      url: `/universal-payments/reports/download-report`,
      method: 'POST',
      params: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DETAILED-TRANSACTION-REPORTS.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_TRANSACTION_REPORT_DETAIL_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_TRANSACTION_REPORT_DETAIL_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadAllAccountTransactionsReport(actions) {
  try {
    yield axios({
      url: `/universal-payments/reports/download-all-details`,
      method: 'POST',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ACCOUNT-TRANSACTIONS-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadPeriodicDetailedReport(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-report`,
      method: 'POST',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DETAILED-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_DETAILED_PERIOD_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_DETAILED_PERIOD_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyFeesAllocations(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-account-report`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-${
          actions.context.financialYear
        } - ${toUpper(actions.context.quarter)} FEES ALLOCATIONS-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyFeesAllocationDetails(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-account-details`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-FEES ELEMENT ALLOCATION DETAILED-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadOutstandingBalancesReport(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-outstanding-balance`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-OUTSTANDING BALANCES-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_OUTSTANDING_BALANCES_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_OUTSTANDING_BALANCES_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadStudentWaiversReport(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-waiver-report`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-STUDENT-WAIVERS-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_STUDENT_WAIVERS_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_STUDENT_WAIVERS_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyBillingDetails(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-billing-details`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-QUARTER BILLING-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyStudentDetails(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-students-details`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 600000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-QUARTER STUDENT-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyFacultyReport(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-units-report`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 600000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-QUARTER UNIT-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_FACULTY_REPORT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_FACULTY_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyReceivableDetails(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-receivable-details`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 600000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-QUARTER RECEIVABLES-REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_SUCCESS,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadQuarterlyDebitNoteDetails(actions) {
  try {
    yield axios({
      url: `universal-payments/reports/download-debit-details`,
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
      timeout: 300000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-QUARTER DEBIT NOTE DETAILS REPORT.xlsx`
      );
    });
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: transactionActions.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchSearchTransaction() {
  yield takeLatest(
    transactionActions.SEARCH_UNIVERSAL_TRANSACTION_REQUEST,
    searchTransaction
  );
}

function* watchGetSponsorReports() {
  yield takeLatest(
    transactionActions.GET_SPONSOR_PAYMENTS_REPORT_REQUEST,
    getSponsorReports
  );
}

function* watchGetSponsorAllocations() {
  yield takeLatest(
    transactionActions.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_REQUEST,
    getSponsorAllocations
  );
}

function* watchGetTransactionReports() {
  yield takeLatest(
    transactionActions.GET_TRANSACTION_REPORTS_REQUEST,
    getTransactionReports
  );
}

function* watchGetCollectionReports() {
  yield takeEvery(
    transactionActions.GET_COLLECTION_REPORTS_REQUEST,
    getCollectionReports
  );
}

function* watchGetStudentRevenueCollectionReports() {
  yield takeEvery(
    transactionActions.GET_STUDENT_REVENUE_REPORTS_REQUEST,
    getStudentRevenueCollectionReports
  );
}

function* watchGetOutstandingBalanceReports() {
  yield takeEvery(
    transactionActions.GET_OUTSTANDING_BALANCES_REPORT_REQUEST,
    getOutstandingBalanceReports
  );
}

function* watchGetStudentWaiversReport() {
  yield takeEvery(
    transactionActions.GET_STUDENT_WAIVERS_REPORT_REQUEST,
    getStudentWaiversReport
  );
}

function* watchGetGlobalRevenueCollectionReports() {
  yield takeEvery(
    transactionActions.GET_GLOBAL_REVENUE_REPORTS_REQUEST,
    getGlobalRevenueCollectionReports
  );
}

function* watchGetGlobalDetailedReports() {
  yield takeEvery(
    transactionActions.GET_DETAILED_GLOBAL_REVENUE_REPORTS_REQUEST,
    getGlobalDetailedReports
  );
}

function* watchGetMonthlyCollections() {
  yield takeEvery(
    transactionActions.GET_MONTHLY_COLLECTIONS_REPORTS_REQUEST,
    getMonthlyCollections
  );
}

function* watchGetUniversalPaymentsReports() {
  yield takeEvery(
    transactionActions.GET_UNIVERSAL_PAYMENTS_REPORTS_REQUEST,
    getUniversalPaymentsReports
  );
}

function* watchGetFinancialYearReports() {
  yield takeEvery(
    transactionActions.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST,
    getFinancialYrReports
  );
}

function* watchGetFinancialYrUnallocatedReports() {
  yield takeEvery(
    transactionActions.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_REQUEST,
    getFinancialYrUnallocatedReports
  );
}

function* watchGetQuarterlyOpeningReceivablesReport() {
  yield takeEvery(
    transactionActions.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_REQUEST,
    getQuarterlyOpeningReceivablesReport
  );
}

function* watchGetQuarterlyBillingDetailsReport() {
  yield takeEvery(
    transactionActions.GET_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST,
    getQuarterlyBillingDetailsReport
  );
}

function* watchGetQuarterlyStudentDetailsReport() {
  yield takeEvery(
    transactionActions.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST,
    getQuarterlyStudentDetailsReport
  );
}

function* watchGetQuarterlyFacultyReport() {
  yield takeEvery(
    transactionActions.GET_QUARTERLY_FACULTY_REPORT_REQUEST,
    getQuarterlyFacultyReport
  );
}

function* watchGetQuarterlyPaymentTypeDetailsReport() {
  yield takeLatest(
    transactionActions.GET_QUARTERLY_PAYMENT_TYPE_DETAILS_REPORTS_REQUEST,
    getQuarterlyPaymentTypeDetailsReport
  );
}

function* watchGenerateFinancialYearReports() {
  yield takeEvery(
    transactionActions.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST,
    generateFinancialYrReports
  );
}

function* watchGetSelectedQuarterReport() {
  yield takeEvery(
    transactionActions.GET_SELECTED_QUARTER_REPORT_REQUEST,
    getSelectedQuarterReport
  );
}

function* watchGenerateSelectedQuarterReport() {
  yield takeEvery(
    transactionActions.GENERATE_SELECTED_QUARTER_REPORT_REQUEST,
    generateSelectedQuarterReport
  );
}

function* watchGetReportsDetails() {
  yield takeEvery(
    transactionActions.GET_UNIVERSAL_REPORTS_DETAILS_REQUEST,
    getReportsDetails
  );
}

function* watchGetPeriodicReportsDetails() {
  yield takeEvery(
    transactionActions.GET_DETAILED_PERIOD_REPORTS_REQUEST,
    getPeriodicReportsDetails
  );
}

function* watchDownloadDetailedReport() {
  yield takeEvery(
    transactionActions.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_REQUEST,
    downloadDetailedReport
  );
}
function* watchDownloadGlobalRevenueCollectionReport() {
  yield takeEvery(
    transactionActions.DOWNLOAD_GLOBAL_REVENUE_REPORTS_REQUEST,
    downloadGlobalRevenueCollectionReport
  );
}

function* watchDownloadReportSummery() {
  yield takeEvery(
    transactionActions.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_REQUEST,
    downloadReportSummery
  );
}

function* watchDownloadDetailedTransactionReports() {
  yield takeLatest(
    transactionActions.DOWNLOAD_TRANSACTION_REPORT_DETAIL_REQUEST,
    downloadDetailedTransactionReports
  );
}

function* watchDownloadAllAccountTransactionsReport() {
  yield takeLatest(
    transactionActions.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_REQUEST,
    downloadAllAccountTransactionsReport
  );
}

function* watchDownloadPeriodicDetailedReport() {
  yield takeLatest(
    transactionActions.DOWNLOAD_DETAILED_PERIOD_REPORTS_REQUEST,
    downloadPeriodicDetailedReport
  );
}

function* watchDownloadQuarterlyFeesAllocations() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_REQUEST,
    downloadQuarterlyFeesAllocations
  );
}

function* watchDownloadQuarterlyFeesAllocationDetails() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_REQUEST,
    downloadQuarterlyFeesAllocationDetails
  );
}

function* watchDownloadOutstandingBalancesReport() {
  yield takeLatest(
    transactionActions.DOWNLOAD_OUTSTANDING_BALANCES_REPORT_REQUEST,
    downloadOutstandingBalancesReport
  );
}

function* watchDownloadStudentWaiversReport() {
  yield takeLatest(
    transactionActions.DOWNLOAD_STUDENT_WAIVERS_REPORT_REQUEST,
    downloadStudentWaiversReport
  );
}

function* watchDownloadQuarterlyBillingDetails() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST,
    downloadQuarterlyBillingDetails
  );
}

function* watchDownloadQuarterlyStudentDetails() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST,
    downloadQuarterlyStudentDetails
  );
}

function* watchDownloadQuarterlyFacultyReport() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_FACULTY_REPORT_REQUEST,
    downloadQuarterlyFacultyReport
  );
}

function* watchDownloadQuarterlyReceivableDetails() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_REQUEST,
    downloadQuarterlyReceivableDetails
  );
}

function* watchDownloadQuarterlyDebitNoteDetails() {
  yield takeLatest(
    transactionActions.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_REQUEST,
    downloadQuarterlyDebitNoteDetails
  );
}

export default [
  fork(watchSearchTransaction),
  fork(watchGetSponsorReports),
  fork(watchGetReportsDetails),
  fork(watchGetCollectionReports),
  fork(watchGetMonthlyCollections),
  fork(watchGetSponsorAllocations),
  fork(watchGetTransactionReports),
  fork(watchDownloadReportSummery),
  fork(watchDownloadDetailedReport),
  fork(watchGetFinancialYearReports),
  fork(watchGetStudentWaiversReport),
  fork(watchGetSelectedQuarterReport),
  fork(watchGetGlobalDetailedReports),
  fork(watchGetPeriodicReportsDetails),
  fork(watchGetQuarterlyFacultyReport),
  fork(watchGetUniversalPaymentsReports),
  fork(watchGetOutstandingBalanceReports),
  fork(watchGenerateFinancialYearReports),
  fork(watchDownloadStudentWaiversReport),
  fork(watchGenerateSelectedQuarterReport),
  fork(watchDownloadPeriodicDetailedReport),
  fork(watchDownloadQuarterlyFacultyReport),
  fork(watchDownloadQuarterlyStudentDetails),
  fork(watchDownloadQuarterlyBillingDetails),
  fork(watchDownloadQuarterlyFeesAllocations),
  fork(watchGetQuarterlyBillingDetailsReport),
  fork(watchGetQuarterlyStudentDetailsReport),
  fork(watchGetFinancialYrUnallocatedReports),
  fork(watchGetGlobalRevenueCollectionReports),
  fork(watchDownloadQuarterlyDebitNoteDetails),
  fork(watchDownloadOutstandingBalancesReport),
  fork(watchGetStudentRevenueCollectionReports),
  fork(watchDownloadQuarterlyReceivableDetails),
  fork(watchDownloadDetailedTransactionReports),
  fork(watchGetQuarterlyPaymentTypeDetailsReport),
  fork(watchDownloadAllAccountTransactionsReport),
  fork(watchGetQuarterlyOpeningReceivablesReport),
  fork(watchDownloadGlobalRevenueCollectionReport),
  fork(watchDownloadQuarterlyFeesAllocationDetails),
];
