import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { resultsReportActions } from '../../actions';
import initialState from '../../initialState';

const resultReport = (state = initialState.resultReport, actions) => {
  switch (actions.type) {
    case resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_REQUEST:
      return {
        ...state,
        gettingResultsReport: true,
        reportError: {},
      };
    case resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_SUCCESS: {
      const { resultsReport } = state;
      const { context } = actions;
      const findDataIndex = findIndex(
        resultsReport,
        (report) =>
          toString(report.academic_year_id) ===
          toString(context.academic_year_id)
      );

      const contextData = { ...context, data: actions.data };

      let newState = state;

      if (findDataIndex === -1) {
        newState = update(newState, {
          roleGroupUsers: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          roleGroupUsers: { [findDataIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingResultsReport: false,
      };
    }
    case resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_ERROR:
      return {
        ...state,
        reportError: actions.error,
        gettingResultsReport: false,
      };

    default:
      return state;
  }
};

export default resultReport;
