import { otherFeesActions } from '../../actions';
import initialState from '../../initialState';

const otherFees = (state = initialState.otherFees, actions) => {
  switch (actions.type) {
    case otherFeesActions.GET_OTHER_FEES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case otherFeesActions.GET_OTHER_FEES_SUCCESS:
      return {
        ...state,
        otherFees: actions.data,
        error: {},
        loading: false,
      };

    case otherFeesActions.GET_OTHER_FEES_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case otherFeesActions.GET_OTHER_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
      };

    case otherFeesActions.GET_OTHER_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        otherFeesElements: actions.data,
        error: {},
        loading: false,
      };

    case otherFeesActions.GET_OTHER_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_REQUEST:
      return {
        ...state,
        error: {},
        fetchingByContext: true,
        created: {},
      };

    case otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_SUCCESS:
      return {
        ...state,
        filteredOtherFees: actions.data,
        error: {},
        fetchingByContext: false,
      };

    case otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_ERROR:
      return {
        ...state,
        error: actions.error,
        fetchingByContext: false,
      };

    case otherFeesActions.CREATE_OTHER_FEES_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };

    case otherFeesActions.CREATE_OTHER_FEES_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };

    case otherFeesActions.CREATE_OTHER_FEES_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case otherFeesActions.ADD_OTHER_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        addingElement: true,
        error: {},
      };

    case otherFeesActions.ADD_OTHER_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        addingElement: false,
        addingElementSuccessResponse: actions.data,
      };

    case otherFeesActions.ADD_OTHER_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        addingElement: false,
        error: actions.error,
      };

    case otherFeesActions.DELETE_OTHER_FEES_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case otherFeesActions.DELETE_OTHER_FEES_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case otherFeesActions.DELETE_OTHER_FEES_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };
    case otherFeesActions.DELETE_OTHER_FEES_AMOUNT_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deletingAmount: true,
      };

    case otherFeesActions.DELETE_OTHER_FEES_AMOUNT_SUCCESS:
      return {
        ...state,
        deletingAmount: false,
        deleteResponse: actions.data,
      };

    case otherFeesActions.DELETE_OTHER_FEES_AMOUNT_ERROR:
      return {
        ...state,
        deletingAmount: false,
        deleteError: actions.error,
      };
    case otherFeesActions.UPDATE_OTHER_FEES_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case otherFeesActions.UPDATE_OTHER_FEES_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case otherFeesActions.UPDATE_OTHER_FEES_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };
    case otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updatingAmount: true,
        updatingId: actions.id,
      };

    case otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_SUCCESS:
      return {
        ...state,
        updatingAmount: false,
        updateResponse: actions.data,
      };

    case otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_ERROR:
      return {
        ...state,
        updatingAmount: false,
        updateError: actions.error,
      };

    case otherFeesActions.SET_LOADED_FEES:
      return {
        ...state,
        loadedFees: actions.data,
      };

    case otherFeesActions.SET_OTHER_FEES_CONTEXT:
      return {
        ...state,
        otherFeesContext: actions.context,
      };

    case otherFeesActions.SET_PENDING_FEES_CONTEXT:
      return {
        ...state,
        pendingContext: actions.context,
      };
    default:
      return state;
  }
};

export default otherFees;
