const specialEntryMgtInitialState = {
  specialEntryExamProgramme: {
    specialEntryInstitution: {},

    gettingProgrammes: false,
    institutionProgrammes: [],
    getProgrammeError: [],

    addingProgramme: false,
    addProgrammeSuccess: {},
    addProgrammeError: {},

    updatingProgramme: false,
    updateSuccess: {},
    updateError: {},

    deletingProgramme: false,
    deleteSuccess: {},
    deleteError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},
  },
  specialEntryAdmission: {
    selectedAdmission: {},
    selectedInstitution: {},

    gettingAdmissions: false,
    admissions: [],
    getAdmissionError: [],

    gettingProgrammes: false,
    programmes: [],
    getProgrammeError: [],

    gettingExamRooms: false,
    examRooms: [],
    getExamRoomError: [],

    creatingAdmission: false,
    createSuccess: {},
    createError: {},

    updatingAdmission: false,
    updateSuccess: {},
    updateError: {},

    gettingAdvertisedProgrammes: false,
    getAdvertisedProgrammeError: {},
    advertisedProgrammes: [],

    updatingStatus: false,
    admissionStatus: {},
    updateStatusError: {},

    deletingAdmissionProgramme: false,

    assigningProgramme: false,
    assignSuccess: {},
    assignError: {},
  },
  specialEntryReport: {
    gettingApplicantsReport: false,
    applicantsReportError: {},
    applicantsReport: [],

    downloadingApplicantsReport: false,
    downloadApplicantsReportError: {},

    gettingReportDetails: false,
    reportDetailError: {},
    reportDetails: [],

    gettingResults: false,
    reportResultError: {},
    results: [],

    gettingRoomAllocations: false,
    roomAllocationError: {},
    roomAllocations: [],

    gettingAppeals: false,
    applicantsAppealError: {},
    appeals: [],

    gettingPaymentReport: false,
    paymentReportError: {},
    paymentReports: [],

    gettingProgrammeChoices: false,
    programmeChoiceError: {},
    programmeChoices: [],
  },
  specialEntryApplicants: {
    gettingApplicants: false,
    specialEntryApplicants: [],
    getApplicantsError: {},

    gettingMeritAdmissions: false,
    meritAdmissions: [],
    getMeritAdmissionsError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},

    updating: false,
    updateSuccess: {},
    updateError: {},
  },
};

export default specialEntryMgtInitialState;
