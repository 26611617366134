import { serverActions } from '../../actions';
import initialState from '../../initialState';

const server = (state = initialState.server, actions) => {
  switch (actions.type) {
    case serverActions.SERVER_REQUEST:
      return {
        serverSuccess: {},
        serverError: {},
      };

    case serverActions.SERVER_ERROR:
      return {
        serverSuccess: {},
        serverError:
          actions.error?.data || actions.error?.error || actions.error?.message,
      };

    case serverActions.SERVER_SUCCESS:
      return {
        serverSuccess: actions.data,
        serverError: {},
      };

    default:
      return state;
  }
};

export default server;
