import PropTypes, { any, array } from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userCan } from '../../helpers/userRoles';

function HasPermission({ permissions, appCodes, children }) {
  const { currentRole } = useSelector((state) => state.auth);
  const userCanAct = useMemo(() => userCan(permissions, appCodes, currentRole));

  if (userCanAct !== true) return null;

  return children;
}

HasPermission.propTypes = {
  permissions: PropTypes.oneOfType([array]).isRequired,
  appCodes: PropTypes.oneOfType([array]).isRequired,
  children: PropTypes.oneOfType([any]).isRequired,
};

export default HasPermission;
