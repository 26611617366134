const unebSubject = {
  GET_UNEB_SUBJECTS_REQUEST: 'GET_UNEB_SUBJECTS_REQUEST',
  GET_UNEB_SUBJECTS_SUCCESS: 'GET_UNEB_SUBJECTS_SUCCESS',
  GET_UNEB_SUBJECTS_ERROR: 'GET_UNEB_SUBJECTS_ERROR',

  GET_SINGLE_UNEB_SUBJECT_REQUEST: 'GET_SINGLE_UNEB_SUBJECT_REQUEST',
  GET_SINGLE_UNEB_SUBJECT_SUCCESS: 'GET_SINGLE_UNEB_SUBJECT_SUCCESS',
  GET_SINGLE_UNEB_SUBJECT_ERROR: 'GET_SINGLE_UNEB_SUBJECT_ERROR',

  CREATE_UNEB_SUBJECT_SUCCESS: 'CREATE_UNEB_SUBJECT_SUCCESS',
  CREATE_UNEB_SUBJECT_REQUEST: 'CREATE_UNEB_SUBJECT_REQUEST',
  CREATE_UNEB_SUBJECT_ERROR: 'CREATE_UNEB_SUBJECT_ERROR',

  UPDATE_UNEB_SUBJECT_SUCCESS: 'UPDATE_UNEB_SUBJECT_SUCCESS',
  UPDATE_UNEB_SUBJECT_REQUEST: 'UPDATE_UNEB_SUBJECT_REQUEST',
  UPDATE_UNEB_SUBJECT_ERROR: 'UPDATE_UNEB_SUBJECT_ERROR',

  DELETE_UNEB_SUBJECT_REQUEST: 'DELETE_UNEB_SUBJECT_REQUEST',
  DELETE_UNEB_SUBJECT_SUCCESS: 'DELETE_UNEB_SUBJECT_SUCCESS',
  DELETE_UNEB_SUBJECT_ERROR: 'DELETE_UNEB_SUBJECT_ERROR',

  DOWNLOAD_UNEB_SUBJECTS_REQUEST: 'DOWNLOAD_UNEB_SUBJECTS_REQUEST',
  DOWNLOAD_UNEB_SUBJECTS_SUCCESS: 'DOWNLOAD_UNEB_SUBJECTS_SUCCESS',
  DOWNLOAD_UNEB_SUBJECTS_ERROR: 'DOWNLOAD_UNEB_SUBJECTS_ERROR',

  UPLOAD_UNEB_SUBJECTS_REQUEST: 'UPLOAD_UNEB_SUBJECTS_REQUEST',
  UPLOAD_UNEB_SUBJECTS_SUCCESS: 'UPLOAD_UNEB_SUBJECTS_SUCCESS',
  UPLOAD_UNEB_SUBJECTS_ERROR: 'UPLOAD_UNEB_SUBJECTS_ERROR',

  getUnebSubjects: (data) => ({
    type: unebSubject.GET_UNEB_SUBJECTS_REQUEST,
    data,
  }),

  getOneUnebSubject: (data) => ({
    type: unebSubject.GET_SINGLE_UNEB_SUBJECT_REQUEST,
    data,
  }),

  createUnebSubject: (data) => ({
    type: unebSubject.CREATE_UNEB_SUBJECT_REQUEST,
    data,
  }),

  updateUnebSubject: (data, id) => ({
    type: unebSubject.UPDATE_UNEB_SUBJECT_REQUEST,
    data,
    id,
  }),

  deleteUnebSubject: (id) => ({
    type: unebSubject.DELETE_UNEB_SUBJECT_REQUEST,
    id,
  }),

  downloadUnebSubjectTemplate: () => ({
    type: unebSubject.DOWNLOAD_UNEB_SUBJECTS_REQUEST,
  }),

  uploadUnebSubjectTemplate: (data) => ({
    type: unebSubject.UPLOAD_UNEB_SUBJECTS_REQUEST,
    data,
  }),
};

export default unebSubject;
