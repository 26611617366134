const pendingVoidedInvoice = {
  GET_PENDING_VOIDED_INVOICE_REQUEST: 'GET_PENDING_VOIDED_INVOICE_REQUEST',
  GET_PENDING_VOIDED_INVOICE_SUCCESS: 'GET_PENDING_VOIDED_INVOICE_SUCCESS',
  GET_PENDING_VOIDED_INVOICE_ERROR: 'GET_PENDING_VOIDED_INVOICE_ERROR',

  APPROVE_PENDING_VOIDED_INVOICE_REQUEST:
    'APPROVE_PENDING_VOIDED_INVOICE_REQUEST',
  APPROVE_PENDING_VOIDED_INVOICE_SUCCESS:
    'APPROVE_PENDING_VOIDED_INVOICE_SUCCESS',
  APPROVE_PENDING_VOIDED_INVOICE_ERROR: 'APPROVE_PENDING_VOIDED_INVOICE_ERROR',

  REJECT_PENDING_VOIDED_INVOICE_REQUEST:
    'REJECT_PENDING_VOIDED_INVOICE_REQUEST',
  REJECT_PENDING_VOIDED_INVOICE_SUCCESS:
    'REJECT_PENDING_VOIDED_INVOICE_SUCCESS',
  REJECT_PENDING_VOIDED_INVOICE_ERROR: 'REJECT_PENDING_VOIDED_INVOICE_ERROR',

  SET_PENDING_VOIDED_INVOICE: 'SET_PENDING_VOIDED_INVOICE',

  getPendingVoidedInvoices: (studentId) => ({
    type: pendingVoidedInvoice.GET_PENDING_VOIDED_INVOICE_REQUEST,
    studentId,
  }),

  approvePendingVoidedInvoice: (data) => ({
    type: pendingVoidedInvoice.APPROVE_PENDING_VOIDED_INVOICE_REQUEST,
    data,
  }),

  rejectPendingVoidedInvoice: (data) => ({
    type: pendingVoidedInvoice.REJECT_PENDING_VOIDED_INVOICE_REQUEST,
    data,
  }),
};
export default pendingVoidedInvoice;
