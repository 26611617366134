import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  programmeAliasActions,
  programmeActions,
  tabActions,
} from '../../actions';

function* createProgrammeAlias(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programme-alias',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: programmeAliasActions.CREATE_PROGRAMME_ALIAS_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: response.programmeAlias.programme_id,
    });
    yield put({
      type: tabActions.PROGRAMME_VERSION_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: programmeAliasActions.CREATE_PROGRAMME_ALIAS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* updateProgrammeAlias(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-alias/${actions.programmeAliasId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: programmeAliasActions.UPDATE_PROGRAMME_ALIAS_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: response.programmeAlias.programme_id,
    });
    yield put({
      type: 'SHOW_EDIT_PROGRAMME_ALIAS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: programmeAliasActions.UPDATE_PROGRAMME_ALIAS_ERROR,
      error: error.data,
    });
  }
}

function* deleteProgrammeAlias(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-alias/${actions.programmeAliasId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: programmeAliasActions.DELETE_PROGRAMME_ALIAS_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: actions.programmeId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: programmeAliasActions.DELETE_PROGRAMME_ALIAS_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateProgrammeAlias() {
  yield takeLatest(
    programmeAliasActions.CREATE_PROGRAMME_ALIAS_REQUEST,
    createProgrammeAlias
  );
}

function* watchUpdateProgrammeAlias() {
  yield takeLatest(
    programmeAliasActions.UPDATE_PROGRAMME_ALIAS_REQUEST,
    updateProgrammeAlias
  );
}

function* watchDeleteProgrammeAlias() {
  yield takeLatest(
    programmeAliasActions.DELETE_PROGRAMME_ALIAS_REQUEST,
    deleteProgrammeAlias
  );
}

export default [
  fork(watchCreateProgrammeAlias),
  fork(watchUpdateProgrammeAlias),
  fork(watchDeleteProgrammeAlias),
];
