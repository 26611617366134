import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { resultsBlockingPolicyActions } from '../../actions';

function* getResultBlockingPolicy(actions) {
  try {
    const { category } = actions;
    const response = yield axios({
      url: `/institution-policy-mgt/results-blocking-policies/${category}`,
      method: 'GET',
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_SUCCESS,
      data: response.data,
      category,
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateOrCreateResultBlockingPolicy(actions) {
  try {
    const { category } = actions;
    const response = yield axios({
      url: '/institution-policy-mgt/results-blocking-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_REQUEST,
      category,
    });
    yield put({
      type: resultsBlockingPolicyActions.SET_POLICY_ACTIVE_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* getViewResultsRegistrationPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/results-blocking-policies/registration',
      method: 'GET',
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateViewResultsRegistrationPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/results-blocking-policies/request-update-registration',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST,
    });
    yield put({
      type: resultsBlockingPolicyActions.SET_POLICY_ACTIVE_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* getViewResultsFeesBalancePolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/results-blocking-policies/fees-balance',
      method: 'GET',
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateViewResultsFeesBalancePolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/results-blocking-policies/request-update-fees-balance',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST,
    });
    yield put({
      type: resultsBlockingPolicyActions.SET_POLICY_ACTIVE_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* getPendingResultsBlockingPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/results-blocking-policies/approval-requests',
      method: 'GET',
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingResultsBlockingPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-blocking-policies/approve-request/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetViewResultBlockingPolicy() {
  yield takeLatest(
    resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_REQUEST,
    getResultBlockingPolicy
  );
}

function* watchUpdateResultBlockingPolicy() {
  yield takeLatest(
    resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_REQUEST,
    updateOrCreateResultBlockingPolicy
  );
}

function* watchGetViewResultsRegistrationPolicies() {
  yield takeLatest(
    resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST,
    getViewResultsRegistrationPolicies
  );
}

function* watchUpdateViewResultsRegistrationPolicies() {
  yield takeLatest(
    resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST,
    updateViewResultsRegistrationPolicies
  );
}

function* watchGetViewResultsFeesBalancePolicies() {
  yield takeLatest(
    resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST,
    getViewResultsFeesBalancePolicies
  );
}

function* watchUpdateViewResultsFeesBalancePolicies() {
  yield takeLatest(
    resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST,
    updateViewResultsFeesBalancePolicies
  );
}

function* watchGetPendingResultsBlockingPolicies() {
  yield takeLatest(
    resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_REQUEST,
    getPendingResultsBlockingPolicies
  );
}

function* watchApprovePendingResultsBlockingPolicy() {
  yield takeLatest(
    resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_REQUEST,
    approvePendingResultsBlockingPolicy
  );
}

export default [
  fork(watchGetViewResultBlockingPolicy),
  fork(watchUpdateResultBlockingPolicy),
  fork(watchGetViewResultsRegistrationPolicies),
  fork(watchUpdateViewResultsRegistrationPolicies),
  fork(watchGetViewResultsFeesBalancePolicies),
  fork(watchUpdateViewResultsFeesBalancePolicies),
  fork(watchGetPendingResultsBlockingPolicies),
  fork(watchApprovePendingResultsBlockingPolicy),
];
