import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { admissionSchemeActions } from '../../actions';

function* getAdmissionSchemeData(actions) {
  try {
    const response = yield axios({
      url: '/admissions/admission-schemes',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: admissionSchemeActions.GET_ADMISSION_SCHEME_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: admissionSchemeActions.GET_ADMISSION_SCHEME_ERROR,
      error: error.data,
    });
  }
}

function* createAdmissionScheme(actions) {
  try {
    const response = yield axios({
      url: '/admissions/admission-schemes',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: admissionSchemeActions.CREATE_ADMISSION_SCHEME_SUCCESS,
      data: response,
    });
    yield put({
      type: admissionSchemeActions.GET_ADMISSION_SCHEME_REQUEST,
    });
  } catch (error) {
    yield put({
      type: admissionSchemeActions.CREATE_ADMISSION_SCHEME_ERROR,
      error: error.data,
    });
  }
}

function* getSingleAdmissionScheme(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-schemes/${actions.admissionSchemeId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: admissionSchemeActions.GET_SINGLE_ADMISSION_SCHEME_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: admissionSchemeActions.GET_SINGLE_ADMISSION_SCHEME_ERROR,
      error: error.data,
    });
  }
}

function* updateAdmissionScheme(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-schemes/${actions.admissionSchemeId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: admissionSchemeActions.UPDATE_ADMISSION_SCHEME_SUCCESS,
      data: response,
    });
    yield put({
      type: admissionSchemeActions.GET_ADMISSION_SCHEME_REQUEST,
    });
  } catch (error) {
    yield put({
      type: admissionSchemeActions.UPDATE_ADMISSION_SCHEME_ERROR,
      error: error.data,
    });
  }
}

function* deleteAdmissionScheme(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-schemes/${actions.admissionSchemeId}`,
      method: 'DELETE',
    });
    yield put({
      type: admissionSchemeActions.DELETE_ADMISSION_SCHEME_SUCCESS,
      data: response,
    });
    yield put({
      type: admissionSchemeActions.GET_ADMISSION_SCHEME_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionSchemeActions.DELETE_ADMISSION_SCHEME_ERROR,
      error: error.data,
    });
  }
}

function* getEmisAdmissionSchemeCategories() {
  try {
    const response = yield axios({
      url: '/developer/courses/admission-categories',
      method: 'GET',
    });
    yield put({
      type: admissionSchemeActions.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: admissionSchemeActions.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdmissionSchemeData() {
  yield takeLatest(
    admissionSchemeActions.GET_ADMISSION_SCHEME_REQUEST,
    getAdmissionSchemeData
  );
}

function* watchCreateAdmissionScheme() {
  yield takeLatest(
    admissionSchemeActions.CREATE_ADMISSION_SCHEME_REQUEST,
    createAdmissionScheme
  );
}

function* watchGetSingleAdmissionScheme() {
  yield takeLatest(
    admissionSchemeActions.GET_SINGLE_ADMISSION_SCHEME_REQUEST,
    getSingleAdmissionScheme
  );
}

function* watchUpdateAdmissionScheme() {
  yield takeLatest(
    admissionSchemeActions.UPDATE_ADMISSION_SCHEME_REQUEST,
    updateAdmissionScheme
  );
}

function* watchDeleteAdmissionScheme() {
  yield takeLatest(
    admissionSchemeActions.DELETE_ADMISSION_SCHEME_REQUEST,
    deleteAdmissionScheme
  );
}

function* watchGetEmisAdmissionSchemeCategories() {
  yield takeLatest(
    admissionSchemeActions.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_REQUEST,
    getEmisAdmissionSchemeCategories
  );
}

export default [
  fork(watchCreateAdmissionScheme),
  fork(watchGetAdmissionSchemeData),
  fork(watchUpdateAdmissionScheme),
  fork(watchGetSingleAdmissionScheme),
  fork(watchDeleteAdmissionScheme),
  fork(watchGetEmisAdmissionSchemeCategories),
];
