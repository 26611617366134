import { previousPaymentsActions } from '../../actions';
import initialState from '../../initialState';

const previousPayments = (state = initialState.previousPayments, actions) => {
  switch (actions.type) {
    case previousPaymentsActions.DOWNLOAD_PAYMENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        DownloadTemplateError: {},
        DownloadTemplateSuccess: {},
      };
    case previousPaymentsActions.DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        DownloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case previousPaymentsActions.DOWNLOAD_PAYMENTS_TEMPLATE_ERROR:
      return {
        ...state,
        DownloadTemplateError: actions.error,
        downloadingTemplate: false,
      };

    case previousPaymentsActions.UPLOAD_PAYMENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
        uploadTemplateSuccess: {},
      };
    case previousPaymentsActions.UPLOAD_PAYMENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadTemplateSuccess: actions.data,
        uploadingTemplate: false,
      };
    case previousPaymentsActions.UPLOAD_PAYMENTS_TEMPLATE_ERROR:
      return {
        ...state,
        uploadTemplateError: actions.error,
        uploadingTemplate: false,
      };

    case previousPaymentsActions.GET_PREVIOUS_PAYMENTS_REQUEST:
      return {
        ...state,
        gettingPreviousTuitionPayments: true,
        fetchPaymentsError: {},
      };
    case previousPaymentsActions.GET_PREVIOUS_PAYMENTS_SUCCESS:
      return {
        ...state,
        previousTuitionPayments: actions.data,
        gettingPreviousTuitionPayments: false,
      };
    case previousPaymentsActions.GET_PREVIOUS_PAYMENTS_ERROR:
      return {
        ...state,
        fetchPaymentsError: actions.error,
        gettingPreviousTuitionPayments: false,
      };

    case previousPaymentsActions.PUSH_TO_STUDENT_ACCOUNT_REQUEST:
      return {
        ...state,
        pushingPayments: true,
        pushPaymentsError: {},
      };
    case previousPaymentsActions.PUSH_TO_STUDENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        pushPaymentsSuccess: actions.data,
        pushingPayments: false,
      };
    case previousPaymentsActions.PUSH_TO_STUDENT_ACCOUNT_ERROR:
      return {
        ...state,
        pushPaymentsError: actions.error,
        pushingPayments: false,
      };

    case previousPaymentsActions.PUSH_TO_PREPAYMENTS_REQUEST:
      return {
        ...state,
        pushingPayments: true,
        pushPrepaymentsError: {},
      };
    case previousPaymentsActions.PUSH_TO_PREPAYMENTS_SUCCESS:
      return {
        ...state,
        pushPrepaymentsSuccess: actions.data,
        pushingPayments: false,
      };
    case previousPaymentsActions.PUSH_TO_PREPAYMENTS_ERROR:
      return {
        ...state,
        pushPrepaymentsError: actions.error,
        pushingPayments: false,
      };

    case previousPaymentsActions.DELETE_PREVIOUS_TUITION_REQUEST:
      return {
        ...state,
        deletingTuitionPayments: true,
        deleteTuitionPaymentsError: {},
      };
    case previousPaymentsActions.DELETE_PREVIOUS_TUITION_SUCCESS:
      return {
        ...state,
        deleteTuitionPaymentsSuccess: actions.data,
        deletingTuitionPayments: false,
      };
    case previousPaymentsActions.DELETE_PREVIOUS_TUITION_ERROR:
      return {
        ...state,
        deleteTuitionPaymentsError: actions.error,
        deletingTuitionPayments: false,
      };

    case previousPaymentsActions.DELETE_PREVIOUS_PREPAYMENTS_REQUEST:
      return {
        ...state,
        deletingPrePayments: true,
        deletePrePaymentsError: {},
      };
    case previousPaymentsActions.DELETE_PREVIOUS_PREPAYMENTS_SUCCESS:
      return {
        ...state,
        deletePrePaymentsSuccess: actions.data,
        deletingPrePayments: false,
      };
    case previousPaymentsActions.DELETE_PREVIOUS_PREPAYMENTS_ERROR:
      return {
        ...state,
        deletePrePaymentsError: actions.error,
        deletingPrePayments: false,
      };

    case previousPaymentsActions.GET_PREVIOUS_DEPOSITS_REQUEST:
      return {
        ...state,
        gettingPreviousDeposits: true,
        fetchPrevDepositsError: {},
      };
    case previousPaymentsActions.GET_PREVIOUS_DEPOSITS_SUCCESS:
      return {
        ...state,
        previousStudentDeposits: actions.data,
        gettingPreviousDeposits: false,
      };
    case previousPaymentsActions.GET_PREVIOUS_DEPOSITS_ERROR:
      return {
        ...state,
        fetchPrevDepositsError: actions.error,
        gettingPreviousDeposits: false,
      };

    case previousPaymentsActions.SET_PREVIOUS_TUITION_TO_PUSH:
      return {
        ...state,
        paymentToPush: actions.dataToPush,
      };

    default:
      return state;
  }
};
export default previousPayments;
