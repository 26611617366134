import { includes, map } from 'lodash';
import { functionalFeesActions } from '../../actions';
import initialState from '../../initialState';

const functionalFees = (state = initialState.functionalFees, actions) => {
  switch (actions.type) {
    case functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
      };

    case functionalFeesActions.GET_FUNCTIONAL_FEES_SUCCESS: {
      const functionalFeesData = actions.data;

      if (actions.category === 'uniq-fees-amounts') {
        const newState = {
          ...state,
          uniqueFunctionalFees: actions.data.data,
          error: {},
          loading: false,
        };
        return newState;
      }

      const newState = {
        ...state,
        functionalFees: actions.data.functionalFeesAmounts,
        error: {},
        loading: false,
      };
      if (actions?.id) {
        const elem = functionalFeesData?.functionalFeesAmounts?.find((fees) => {
          return includes(map(fees.functional_amount, 'id'), actions.id);
        });
        newState.selectedTypeAmounts = elem.functional_amount;
      }
      return newState;
    }

    case functionalFeesActions.GET_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
      };
    case functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        functionalFeesElements: actions.data.functionalFeesElements,
        error: {},
        loading: false,
      };
    case functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case functionalFeesActions.CREATE_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };

    case functionalFeesActions.CREATE_FUNCTIONAL_FEES_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };

    case functionalFeesActions.CREATE_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        addingElement: true,
        error: {},
      };
    case functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        addingElement: false,
        addingElementSuccessResponse: actions.data,
      };
    case functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        addingElement: false,
        error: actions.error,
      };

    case functionalFeesActions.DELETE_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case functionalFeesActions.DELETE_FUNCTIONAL_FEES_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case functionalFeesActions.DELETE_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        downloadTemplateError: {},
        downloadingTemplate: true,
      };

    case functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };

    case functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case functionalFeesActions.UPDATE_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case functionalFeesActions.UPDATE_FUNCTIONAL_FEES_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case functionalFeesActions.UPDATE_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
      };

    case functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_REQUEST:
      return {
        ...state,
        approveFunctionalError: {},
        approving: true,
      };
    case functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_SUCCESS:
      return {
        ...state,
        approvalFunctionalResponse: actions.data.pendingFees,
        approving: false,
      };
    case functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_ERROR:
      return {
        ...state,
        approveFunctionalError: actions.error,
        approving: false,
      };

    case functionalFeesActions.SET_FUNCTIONAL_FEES_CONTEXT:
      return {
        ...state,
        functionalFeesContext: actions.context,
      };

    case functionalFeesActions.SET_UNIQUE_FUNCTIONAL_FEES_CONTEXT:
      return {
        ...state,
        uniqueFunctionalFeesContext: actions.context,
      };

    case functionalFeesActions.SET_SELECTED_TYPE_AMOUNTS:
      return {
        ...state,
        selectedTypeAmounts: actions.data,
      };

    case functionalFeesActions.SET_FUNCTIONAL_FEES_TO_EDIT:
      return {
        ...state,
        dataToEdit: actions.data,
      };

    default:
      return state;
  }
};
export default functionalFees;
