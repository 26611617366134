const eventActions = {
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',

  GET_ACADEMIC_YEAR_EVENTS_REQUEST: 'GET_ACADEMIC_YEAR_EVENTS_REQUEST',
  GET_ACADEMIC_YEAR_EVENTS_SUCCESS: 'GET_ACADEMIC_YEAR_EVENTS_SUCCESS',
  GET_ACADEMIC_YEAR_EVENTS_ERROR: 'GET_ACADEMIC_YEAR_EVENTS_ERROR',

  GET_SEMESTER_EVENTS_REQUEST: 'GET_SEMESTER_EVENTS_REQUEST',
  GET_SEMESTER_EVENTS_SUCCESS: 'GET_SEMESTER_EVENTS_SUCCESS',
  GET_SEMESTER_EVENTS_ERROR: 'GET_SEMESTER_EVENTS_ERROR',

  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_ERROR: 'CREATE_EVENT_ERROR',

  EDIT_EVENT_REQUEST: 'EDIT_EVENT_REQUEST',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
  EDIT_EVENT_ERROR: 'EDIT_EVENT_ERROR',

  EDIT_EVENT_ENTRY_ACADEMIC_YEARS_REQUEST:
    'EDIT_EVENT_ENTRY_ACADEMIC_YEARS_REQUEST',
  EDIT_EVENT_ENTRY_ACADEMIC_YEARS_SUCCESS:
    'EDIT_EVENT_ENTRY_ACADEMIC_YEARS_SUCCESS',
  EDIT_EVENT_ENTRY_ACADEMIC_YEARS_ERROR:
    'EDIT_EVENT_ENTRY_ACADEMIC_YEARS_ERROR',

  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',

  GET_RESULT_EVENTS_REQUEST: 'GET_RESULT_EVENTS_REQUEST',
  GET_RESULT_EVENTS_SUCCESS: 'GET_RESULT_EVENTS_SUCCESS',
  GET_RESULT_EVENTS_ERROR: 'GET_RESULT_EVENTS_ERROR',

  CREATE_RESULT_EVENT_REQUEST: 'CREATE_RESULT_EVENT_REQUEST',
  CREATE_RESULT_EVENT_SUCCESS: 'CREATE_RESULT_EVENT_SUCCESS',
  CREATE_RESULT_EVENT_ERROR: 'CREATE_RESULT_EVENT_ERROR',

  EDIT_RESULT_EVENT_REQUEST: 'EDIT_RESULT_EVENT_REQUEST',
  EDIT_RESULT_EVENT_SUCCESS: 'EDIT_RESULT_EVENT_SUCCESS',
  EDIT_RESULT_EVENT_ERROR: 'EDIT_RESULT_EVENT_ERROR',

  DELETE_RESULT_EVENT_REQUEST: 'DELETE_RESULT_EVENT_REQUEST',
  DELETE_RESULT_EVENT_SUCCESS: 'DELETE_RESULT_EVENT_SUCCESS',
  DELETE_RESULT_EVENT_ERROR: 'DELETE_RESULT_EVENT_ERROR',

  SET_SEMESTER_EVENT_DATA: 'SET_SEMESTER_EVENT_DATA',

  SET_RESULT_ACADEMIC_YEAR_CONTEXT: 'SET_RESULT_ACADEMIC_YEAR_CONTEXT',

  getEvents: (params) => ({
    type: eventActions.GET_EVENTS_REQUEST,
    params,
  }),

  setAcademicYearContext: (params = {}) => ({
    type: eventActions.SET_RESULT_ACADEMIC_YEAR_CONTEXT,
    params,
  }),

  getAcademicYearEvents: (academicYearId, params) => ({
    type: eventActions.GET_ACADEMIC_YEAR_EVENTS_REQUEST,
    academicYearId,
    params,
  }),

  getSemesterEvents: (semesterId, params) => ({
    type: eventActions.GET_SEMESTER_EVENTS_REQUEST,
    semesterId,
    params,
  }),

  createEvent: (data) => ({
    type: eventActions.CREATE_EVENT_REQUEST,
    data,
  }),

  editEvent: (eventId, data) => ({
    type: eventActions.EDIT_EVENT_REQUEST,
    eventId,
    data,
  }),

  editEventEntryAcademicYears: (data) => ({
    type: eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_REQUEST,
    data,
  }),

  deleteEvent: (eventId, semesterId, academicYearId) => ({
    type: eventActions.DELETE_EVENT_REQUEST,
    eventId,
    semesterId,
    academicYearId,
  }),

  getResultEvents: (academicYearId) => ({
    type: eventActions.GET_RESULT_EVENTS_REQUEST,
    academicYearId,
  }),

  createResultEvent: (academicYearId, data) => ({
    type: eventActions.CREATE_RESULT_EVENT_REQUEST,
    academicYearId,
    data,
  }),

  editResultEvent: (academicYearId, data) => ({
    type: eventActions.EDIT_RESULT_EVENT_REQUEST,
    academicYearId,
    data,
  }),

  deleteResultEvent: (academicYearId) => ({
    type: eventActions.DELETE_RESULT_EVENT_REQUEST,
    academicYearId,
  }),

  setSemesterEventData: (data) => ({
    type: eventActions.SET_SEMESTER_EVENT_DATA,
    data,
  }),
};

export default eventActions;
