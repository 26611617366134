import React from 'react';
import PropTypes, { any } from 'prop-types';
import { Empty } from 'antd';

function DataNotFound({ message, children, ...props }) {
  return (
    <div className="p-4 text-danger fw-bold text-uppercase">
      <Empty
        description={<div className="text-danger">{message}</div>}
        {...props}
      />
      <div className="text-center my-3">{children}</div>
    </div>
  );
}
DataNotFound.defaultProps = {
  message: 'Data Not Found',
  children: null,
};

DataNotFound.propTypes = {
  message: PropTypes.string,
  children: PropTypes.oneOfType([any]),
};

export default DataNotFound;
