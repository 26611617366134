const tuitionActions = {
  GET_TUITION_REQUEST: 'GET_TUITION_REQUEST',
  GET_TUITION_SUCCESS: 'GET_TUITION_SUCCESS',
  GET_TUITION_ERROR: 'GET_TUITION_ERROR',

  CREATE_TUITION_REQUEST: 'CREATE_TUITION_REQUEST',
  CREATE_TUITION_SUCCESS: 'CREATE_TUITION_SUCCESS',
  CREATE_TUITION_ERROR: 'CREATE_TUITION_ERROR',

  ADD_TUITION_ELEMENTS_REQUEST: 'ADD_TUITION_ELEMENTS_REQUEST',
  ADD_TUITION_ELEMENTS_SUCCESS: 'ADD_TUITION_ELEMENTS_SUCCESS',
  ADD_TUITION_ELEMENTS_ERROR: 'ADD_TUITION_ELEMENTS_ERROR',

  UPDATE_TUITION_REQUEST: 'UPDATE_TUITION_REQUEST',
  UPDATE_TUITION_SUCCESS: 'UPDATE_TUITION_SUCCESS',
  UPDATE_TUITION_ERROR: 'UPDATE_TUITION_ERROR',

  UPDATE_TUITION_AMOUNTS_CONTEXT_REQUEST:
    'UPDATE_TUITION_AMOUNTS_CONTEXT_REQUEST',
  UPDATE_TUITION_AMOUNTS_CONTEXT_SUCCESS:
    'UPDATE_TUITION_AMOUNTS_CONTEXT_SUCCESS',
  UPDATE_TUITION_AMOUNTS_CONTEXT_ERROR: 'UPDATE_TUITION_AMOUNTS_CONTEXT_ERROR',

  DELETE_TUITION_REQUEST: 'DELETE_TUITION_REQUEST',
  DELETE_TUITION_SUCCESS: 'DELETE_TUITION_SUCCESS',
  DELETE_TUITION_ERROR: 'DELETE_TUITION_ERROR',

  DOWNLOAD_TUITION_REQUEST: 'DOWNLOAD_TUITION_REQUEST',
  DOWNLOAD_TUITION_SUCCESS: 'DOWNLOAD_TUITION_SUCCESS',
  DOWNLOAD_TUITION_ERROR: 'DOWNLOAD_TUITION_ERROR',

  UPLOAD_TUITION_REQUEST: 'UPLOAD_TUITION_REQUEST',
  UPLOAD_TUITION_SUCCESS: 'UPLOAD_TUITION_SUCCESS',
  UPLOAD_TUITION_ERROR: 'UPLOAD_TUITION_ERROR',

  GET_TUITION_EXEMPTIONS_REQUEST: 'GET_TUITION_EXEMPTIONS_REQUEST',
  GET_TUITION_EXEMPTIONS_SUCCESS: 'GET_TUITION_EXEMPTIONS_SUCCESS',
  GET_TUITION_EXEMPTIONS_ERROR: 'GET_TUITION_EXEMPTIONS_ERROR',

  ADD_TUITION_EXEMPTION_REQUEST: 'ADD_TUITION_EXEMPTION_REQUEST',
  ADD_TUITION_EXEMPTION_SUCCESS: 'ADD_TUITION_EXEMPTION_SUCCESS',
  ADD_TUITION_EXEMPTION_ERROR: 'ADD_TUITION_EXEMPTION_ERROR',

  DELETE_TUITION_EXEMPTION_REQUEST: 'DELETE_TUITION_EXEMPTION_REQUEST',
  DELETE_TUITION_EXEMPTION_SUCCESS: 'DELETE_TUITION_EXEMPTION_SUCCESS',
  DELETE_TUITION_EXEMPTION_ERROR: 'DELETE_TUITION_EXEMPTION_ERROR',

  SET_TUITION_CONTEXT: 'SET_TUITION_CONTEXT',

  SET_SELECTED_TUITION_PROGRAMME_CONTEXT:
    'SET_SELECTED_TUITION_PROGRAMME_CONTEXT',

  getTuitionAmounts: (context) => ({
    type: tuitionActions.GET_TUITION_REQUEST,
    context,
  }),

  setTuitionContext: (context) => ({
    type: tuitionActions.SET_TUITION_CONTEXT,
    context,
  }),

  setSelectedProgramme: (programme) => ({
    type: tuitionActions.SET_SELECTED_TUITION_PROGRAMME_CONTEXT,
    programme,
  }),

  createTuitionAmounts: (data, currentContext) => ({
    type: tuitionActions.CREATE_TUITION_REQUEST,
    data,
    currentContext,
  }),

  updateTuitionAmount: (id, data, currentContext) => ({
    type: tuitionActions.UPDATE_TUITION_REQUEST,
    id,
    data,
    currentContext,
  }),

  updateTuitionAmountsContext: (id, data, currentContext) => ({
    type: tuitionActions.UPDATE_TUITION_AMOUNTS_CONTEXT_REQUEST,
    id,
    data,
    currentContext,
  }),

  deleteTuitionAmount: (id, currentContext) => ({
    type: tuitionActions.DELETE_TUITION_REQUEST,
    id,
    currentContext,
  }),

  addTuitionElements: (contextID, tuitionContext, data) => ({
    type: tuitionActions.ADD_TUITION_ELEMENTS_REQUEST,
    contextID,
    data,
    tuitionContext,
  }),

  downloadTuitionTemplate: () => ({
    type: tuitionActions.DOWNLOAD_TUITION_REQUEST,
  }),

  uploadTuitionAmounts: (data, context) => ({
    type: tuitionActions.UPLOAD_TUITION_REQUEST,
    data,
    context,
  }),

  getTuitionExemptions: () => ({
    type: tuitionActions.GET_TUITION_EXEMPTIONS_REQUEST,
  }),

  addTuitionExemptions: (data) => ({
    type: tuitionActions.ADD_TUITION_EXEMPTION_REQUEST,
    data,
  }),

  deleteTuitionExemption: (id) => ({
    type: tuitionActions.DELETE_TUITION_EXEMPTION_REQUEST,
    id,
  }),
};

export default tuitionActions;
