import { tuitionFeesPolicyActions } from '../../actions';
import initialState from '../../initialState';

const tuitionFeesPolicy = (state = initialState.tuitionFeesPolicy, actions) => {
  switch (actions.type) {
    case tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_SUCCESS:
      return {
        ...state,
        tuitionFeesPolicies: actions.data.data,
        loading: false,
      };
    case tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case tuitionFeesPolicyActions.CREATE_TUITION_FEES_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };

    case tuitionFeesPolicyActions.CREATE_TUITION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        tuitionFeesPolicy: actions.data.tuitionFeesPolicy,
        creating: false,
      };

    case tuitionFeesPolicyActions.CREATE_TUITION_FEES_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case tuitionFeesPolicyActions.UPDATE_TUITION_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };

    case tuitionFeesPolicyActions.UPDATE_TUITION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };

    case tuitionFeesPolicyActions.UPDATE_TUITION_FEES_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case tuitionFeesPolicyActions.DELETE_TUITION_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        deleting: true,
      };

    case tuitionFeesPolicyActions.DELETE_TUITION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };

    case tuitionFeesPolicyActions.DELETE_TUITION_FEES_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case tuitionFeesPolicyActions.SET_TUITION_FEES_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.tuitionFeesPolicyToEdit,
      };

    default:
      return state;
  }
};

export default tuitionFeesPolicy;
