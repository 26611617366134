import { specialEntryAdmissionActions } from '../../actions';
import specialEntryMgtInitialState from '../../initialState/specialEntry';

const specialEntryAdmissions = (
  state = specialEntryMgtInitialState.specialEntryAdmission,
  actions
) => {
  switch (actions.type) {
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST:
      return {
        ...state,
        gettingAdmissions: true,
        getAdmissionError: {},
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_SUCCESS:
      return {
        ...state,
        gettingAdmissions: false,
        admissions: actions.data,
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_ERROR:
      return {
        ...state,
        gettingAdmissions: false,
        getAdmissionError: actions.error,
      };

    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_REQUEST:
      return {
        ...state,
        gettingProgrammes: true,
        getProgrammeError: {},
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_SUCCESS:
      return {
        ...state,
        gettingProgrammes: false,
        programmes: actions.data,
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_ERROR:
      return {
        ...state,
        gettingProgrammes: false,
        getProgrammeError: actions.error,
      };

    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_REQUEST:
      return {
        ...state,
        gettingExamRooms: true,
        getExamRoomError: {},
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_SUCCESS:
      return {
        ...state,
        gettingExamRooms: false,
        examRooms: actions.data,
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_ERROR:
      return {
        ...state,
        gettingExamRooms: false,
        getExamRoomError: actions.error,
      };

    case specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST:
      return {
        ...state,
        creatingAdmission: true,
        createError: {},
      };
    case specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS:
      return {
        ...state,
        creatingAdmission: false,
        createSuccess: actions.data,
      };
    case specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR:
      return {
        ...state,
        creatingAdmission: false,
        createError: actions.error,
      };

    case specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST:
      return {
        ...state,
        updatingAdmission: true,
        updateError: {},
      };
    case specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS:
      return {
        ...state,
        updatingAdmission: false,
        updateSuccess: actions.data,
      };
    case specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR:
      return {
        ...state,
        updatingAdmission: false,
        updateError: actions.error,
      };

    case specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST:
      return {
        ...state,
        deletingAdmission: true,
        deleteError: {},
      };
    case specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS:
      return {
        ...state,
        deletingAdmission: false,
        deleteSuccess: actions.data,
      };
    case specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR:
      return {
        ...state,
        deletingAdmission: false,
        deleteError: actions.error,
      };

    case specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        assigningProgramme: true,
        assignError: {},
      };
    case specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        assigningProgramme: false,
        assignSuccess: actions.data,
      };
    case specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        assigningProgramme: false,
        assignError: actions.error,
      };

    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingAdvertisedProgrammes: true,
        getAdvertisedProgrammeError: {},
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_SUCCESS:
      return {
        ...state,
        gettingAdvertisedProgrammes: false,
        advertisedProgrammes: actions.data,
      };
    case specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingAdvertisedProgrammes: false,
        getAdvertisedProgrammeError: actions.error,
      };

    case specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_REQUEST:
      return {
        ...state,
        updatingStatus: true,
        updateStatusError: {},
      };
    case specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_SUCCESS:
      return {
        ...state,
        updatingStatus: false,
        admissionStatus: actions.data,
      };
    case specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_ERROR:
      return {
        ...state,
        updatingStatus: false,
        updateStatusError: actions.error,
      };

    case specialEntryAdmissionActions.SET_SELECTED_SPECIAL_ENTRY_EXAM_ADMISSION:
      return {
        ...state,
        selectedAdmission: actions.data,
      };

    case specialEntryAdmissionActions.SET_SELECTED_ADMISSION_INSTITUTION:
      return {
        ...state,
        selectedInstitution: actions.data,
      };

    default:
      return state;
  }
};

export default specialEntryAdmissions;
