import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { academicFeesPolicyActions, tabActions } from '../../actions';

function* getAcademicFeesPolicies() {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/academic-year-fees-policies',
      method: 'GET',
    });
    yield put({
      type: academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* CreateAcademicFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/academic-year-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* UpdateAcademicFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/academic-year-fees-policies/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: academicFeesPolicyActions.SET_SELECTED_POLICY,
      selectedPolicy: {},
    });
  } catch (error) {
    yield put({
      type: academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* DeleteAcademicFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/academic-year-fees-policies/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: academicFeesPolicyActions.SET_SELECTED_POLICY,
      selectedPolicy: {},
    });
  } catch (error) {
    yield put({
      type: academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcademicFeesPolicies() {
  yield takeLatest(
    academicFeesPolicyActions.GET_ACADEMIC_FEES_POLICIES_REQUEST,
    getAcademicFeesPolicies
  );
}

function* watchCreateAcademicFeesPolicies() {
  yield takeLatest(
    academicFeesPolicyActions.CREATE_ACADEMIC_FEES_POLICY_REQUEST,
    CreateAcademicFeesPolicy
  );
}

function* watchUpdateAcademicFeesPolicies() {
  yield takeLatest(
    academicFeesPolicyActions.UPDATE_ACADEMIC_FEES_POLICY_REQUEST,
    UpdateAcademicFeesPolicy
  );
}

function* watchDeleteAcademicFeesPolicies() {
  yield takeLatest(
    academicFeesPolicyActions.DELETE_ACADEMIC_FEES_POLICY_REQUEST,
    DeleteAcademicFeesPolicy
  );
}

export default [
  fork(watchGetAcademicFeesPolicies),
  fork(watchCreateAcademicFeesPolicies),
  fork(watchUpdateAcademicFeesPolicies),
  fork(watchDeleteAcademicFeesPolicies),
];
