const registrationPolicyActions = {
  GET_REGISTRATION_POLICIES_REQUEST: 'GET_REGISTRATION_POLICIES_REQUEST',
  GET_REGISTRATION_POLICIES_SUCCESS: 'GET_REGISTRATION_POLICIES_SUCCESS',
  GET_REGISTRATION_POLICIES_ERROR: 'GET_REGISTRATION_POLICIES_ERROR',

  CREATE_REGISTRATION_POLICY_REQUEST: 'CREATE_REGISTRATION_POLICY_REQUEST',
  CREATE_REGISTRATION_POLICY_SUCCESS: 'CREATE_REGISTRATION_POLICY_SUCCESS',
  CREATE_REGISTRATION_POLICY_ERROR: 'CREATE_REGISTRATION_POLICY_ERROR',

  UPDATE_REGISTRATION_POLICY_REQUEST: 'UPDATE_REGISTRATION_POLICY_REQUEST',
  UPDATE_REGISTRATION_POLICY_SUCCESS: 'UPDATE_REGISTRATION_POLICY_SUCCESS',
  UPDATE_REGISTRATION_POLICY_ERROR: 'UPDATE_REGISTRATION_POLICY_ERROR',

  DELETE_REGISTRATION_POLICY_REQUEST: 'DELETE_REGISTRATION_POLICY_REQUEST',
  DELETE_REGISTRATION_POLICY_SUCCESS: 'DELETE_REGISTRATION_POLICY_SUCCESS',
  DELETE_REGISTRATION_POLICY_ERROR: 'DELETE_REGISTRATION_POLICY_ERROR',

  SET_REGISTRATION_POLICY_TO_EDIT: 'SET_REGISTRATION_POLICY_TO_EDIT',

  getRegistrationPolicies: (params) => ({
    type: registrationPolicyActions.GET_REGISTRATION_POLICIES_REQUEST,
    params,
  }),

  createRegistrationPolicy: (data) => ({
    type: registrationPolicyActions.CREATE_REGISTRATION_POLICY_REQUEST,
    data,
  }),

  updateRegistrationPolicy: (registrationPolicyId, data) => ({
    type: registrationPolicyActions.UPDATE_REGISTRATION_POLICY_REQUEST,
    data,
    registrationPolicyId,
  }),

  deleteRegistrationPolicy: (registrationPolicyId) => ({
    type: registrationPolicyActions.DELETE_REGISTRATION_POLICY_REQUEST,
    registrationPolicyId,
  }),

  setPolicyToEdit: (registrationPolicy) => ({
    type: registrationPolicyActions.SET_REGISTRATION_POLICY_TO_EDIT,
    registrationPolicy,
  }),
};

export default registrationPolicyActions;
