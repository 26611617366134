const runningAdmissionCampus = {
  GET_RUNNING_ADMISSION_CAMPUSES_REQUEST:
    'GET_RUNNING_ADMISSION_CAMPUSES_REQUEST',
  GET_RUNNING_ADMISSION_CAMPUSES_SUCCESS:
    'GET_RUNNING_ADMISSION_CAMPUSES_SUCCESS',
  GET_RUNNING_ADMISSION_CAMPUSES_ERROR: 'GET_RUNNING_ADMISSION_CAMPUSES_ERROR',

  GET_SINGLE_RUNNING_ADMISSION_CAMPUS_REQUEST:
    'GET_SINGLE_RUNNING_ADMISSION_CAMPUS_REQUEST',
  GET_SINGLE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
    'GET_SINGLE_RUNNING_ADMISSION_CAMPUS_SUCCESS',
  GET_SINGLE_RUNNING_ADMISSION_CAMPUS_ERROR:
    'GET_SINGLE_RUNNING_ADMISSION_CAMPUS_ERROR',

  CREATE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
    'CREATE_RUNNING_ADMISSION_CAMPUS_SUCCESS',
  CREATE_RUNNING_ADMISSION_CAMPUS_REQUEST:
    'CREATE_RUNNING_ADMISSION_CAMPUS_REQUEST',
  CREATE_RUNNING_ADMISSION_CAMPUS_ERROR:
    'CREATE_RUNNING_ADMISSION_CAMPUS_ERROR',

  UPDATE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
    'UPDATE_RUNNING_ADMISSION_CAMPUS_SUCCESS',
  UPDATE_RUNNING_ADMISSION_CAMPUS_REQUEST:
    'UPDATE_RUNNING_ADMISSION_CAMPUS_REQUEST',
  UPDATE_RUNNING_ADMISSION_CAMPUS_ERROR:
    'UPDATE_RUNNING_ADMISSION_CAMPUS_ERROR',

  DELETE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
    'DELETE_RUNNING_ADMISSION_CAMPUS_SUCCESS',
  DELETE_RUNNING_ADMISSION_CAMPUS_REQUEST:
    'DELETE_RUNNING_ADMISSION_CAMPUS_REQUEST',
  DELETE_RUNNING_ADMISSION_CAMPUS_ERROR:
    'DELETE_RUNNING_ADMISSION_CAMPUS_ERROR',

  UPDATE_CAPACITY_SETTING_REQUEST: 'UPDATE_CAPACITY_SETTING_REQUEST',
  UPDATE_CAPACITY_SETTING_SUCCESS: 'UPDATE_CAPACITY_SETTING_SUCCESS',
  UPDATE_CAPACITY_SETTING_ERROR: 'UPDATE_CAPACITY_SETTING_ERROR',

  CREATE_CAPACITY_SETTING_REQUEST: 'CREATE_CAPACITY_SETTING_REQUEST',
  CREATE_CAPACITY_SETTING_SUCCESS: 'CREATE_CAPACITY_SETTING_SUCCESS',
  CREATE_CAPACITY_SETTING_ERROR: 'CREATE_CAPACITY_SETTING_ERROR',

  DELETE_CAPACITY_SETTING_REQUEST: 'DELETE_CAPACITY_SETTING_REQUEST',
  DELETE_CAPACITY_SETTING_SUCCESS: 'DELETE_CAPACITY_SETTING_SUCCESS',
  DELETE_CAPACITY_SETTING_ERROR: 'DELETE_CAPACITY_SETTING_ERROR',

  EDIT_SPECIAL_REMARKS_REQUEST: 'EDIT_SPECIAL_REMARKS_REQUEST',
  EDIT_SPECIAL_REMARKS_SUCCESS: 'EDIT_SPECIAL_REMARKS_SUCCESS',
  EDIT_SPECIAL_REMARKS_ERROR: 'EDIT_SPECIAL_REMARKS_ERROR',

  SET_ALIAS_DETAILS: 'SET_ALIAS_DETAILS',

  getRunningAdmissionCampuses: (data) => ({
    type: runningAdmissionCampus.GET_RUNNING_ADMISSION_CAMPUSES_REQUEST,
    data,
  }),

  createRunningAdmissionCampus: (data, admissionId) => ({
    type: runningAdmissionCampus.CREATE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    data,
    admissionId,
  }),

  updateRemarks: (runningProgrammeId, data) => ({
    type: runningAdmissionCampus.EDIT_SPECIAL_REMARKS_REQUEST,
    runningProgrammeId,
    data,
  }),

  getSingleRunningAdmissionCampus: (runningAdmissionCampusId, data) => ({
    type: runningAdmissionCampus.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    runningAdmissionCampusId,
    data,
  }),

  updateRunningAdmissionCampus: (runningAdmissionCampusId, data) => ({
    type: runningAdmissionCampus.UPDATE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    runningAdmissionCampusId,
    data,
  }),

  updateSingleCapacitySetting: (
    capacitySettingId,
    data,
    runningAdmissionProgrammeId
  ) => ({
    type: runningAdmissionCampus.UPDATE_CAPACITY_SETTING_REQUEST,
    capacitySettingId,
    data,
    runningAdmissionProgrammeId,
  }),

  createSingleCapacitySetting: (data) => ({
    type: runningAdmissionCampus.CREATE_CAPACITY_SETTING_REQUEST,
    data,
  }),

  deleteSingleCapacitySetting: (capacitySettingId, runningAdmissionId) => ({
    type: runningAdmissionCampus.DELETE_CAPACITY_SETTING_REQUEST,
    capacitySettingId,
    runningAdmissionId,
  }),

  deleteRunningAdmissionCampus: (runningAdmissionCampusId) => ({
    type: runningAdmissionCampus.DELETE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    runningAdmissionCampusId,
  }),

  setAliasDetails: (data) => ({
    type: runningAdmissionCampus.SET_ALIAS_DETAILS,
    data,
  }),
};

export default runningAdmissionCampus;
