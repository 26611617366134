const NTCSubject = {
  GET_NTC_SUBJECTS_REQUEST: 'GET_NTC_SUBJECTS_REQUEST',
  GET_NTC_SUBJECTS_SUCCESS: 'GET_NTC_SUBJECTS_SUCCESS',
  GET_NTC_SUBJECTS_ERROR: 'GET_NTC_SUBJECTS_ERROR',

  GET_SINGLE_NTC_SUBJECT_REQUEST: 'GET_SINGLE_NTC_SUBJECT_REQUEST',
  GET_SINGLE_NTC_SUBJECT_SUCCESS: 'GET_SINGLE_NTC_SUBJECT_SUCCESS',
  GET_SINGLE_NTC_SUBJECT_ERROR: 'GET_SINGLE_NTC_SUBJECT_ERROR',

  CREATE_NTC_SUBJECT_SUCCESS: 'CREATE_NTC_SUBJECT_SUCCESS',
  CREATE_NTC_SUBJECT_REQUEST: 'CREATE_NTC_SUBJECT_REQUEST',
  CREATE_NTC_SUBJECT_ERROR: 'CREATE_NTC_SUBJECT_ERROR',

  UPDATE_NTC_SUBJECT_SUCCESS: 'UPDATE_NTC_SUBJECT_SUCCESS',
  UPDATE_NTC_SUBJECT_REQUEST: 'UPDATE_NTC_SUBJECT_REQUEST',
  UPDATE_NTC_SUBJECT_ERROR: 'UPDATE_NTC_SUBJECT_ERROR',

  DELETE_NTC_SUBJECT_REQUEST: 'DELETE_NTC_SUBJECT_REQUEST',
  DELETE_NTC_SUBJECT_SUCCESS: 'DELETE_NTC_SUBJECT_SUCCESS',
  DELETE_NTC_SUBJECT_ERROR: 'DELETE_NTC_SUBJECT_ERROR',

  DOWNLOAD_NTC_SUBJECTS_REQUEST: 'DOWNLOAD_NTC_SUBJECTS_REQUEST',
  DOWNLOAD_NTC_SUBJECTS_SUCCESS: 'DOWNLOAD_NTC_SUBJECTS_SUCCESS',
  DOWNLOAD_NTC_SUBJECTS_ERROR: 'DOWNLOAD_NTC_SUBJECTS_ERROR',

  UPLOAD_NTC_SUBJECTS_REQUEST: 'UPLOAD_NTC_SUBJECTS_REQUEST',
  UPLOAD_NTC_SUBJECTS_SUCCESS: 'UPLOAD_NTC_SUBJECTS_SUCCESS',
  UPLOAD_NTC_SUBJECTS_ERROR: 'UPLOAD_NTC_SUBJECTS_ERROR',

  getNTCSubjects: (data) => ({
    type: NTCSubject.GET_NTC_SUBJECTS_REQUEST,
    data,
  }),

  getOneNTCSubject: (data) => ({
    type: NTCSubject.GET_SINGLE_NTC_SUBJECT_REQUEST,
    data,
  }),

  createNTCSubject: (data) => ({
    type: NTCSubject.CREATE_NTC_SUBJECT_REQUEST,
    data,
  }),

  updateNTCSubject: (data, id) => ({
    type: NTCSubject.UPDATE_NTC_SUBJECT_REQUEST,
    data,
    id,
  }),

  deleteNTCSubject: (id) => ({
    type: NTCSubject.DELETE_NTC_SUBJECT_REQUEST,
    id,
  }),

  downloadNTCSubjectTemplate: () => ({
    type: NTCSubject.DOWNLOAD_NTC_SUBJECTS_REQUEST,
  }),

  uploadNTCSubjectTemplate: (data) => ({
    type: NTCSubject.UPLOAD_NTC_SUBJECTS_REQUEST,
    data,
  }),
};

export default NTCSubject;
