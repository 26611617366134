const admissionReportActions = {
  GET_ADMISSION_BI_REPORT_REQUEST: 'GET_ADMISSION_BI_REPORT_REQUEST',
  GET_ADMISSION_BI_REPORT_SUCCESS: 'GET_ADMISSION_BI_REPORT_SUCCESS',
  GET_ADMISSION_BI_REPORT_ERROR: 'GET_ADMISSION_BI_REPORT_ERROR',

  GET_ADMISSION_BI_AGE_REPORT_REQUEST: 'GET_ADMISSION_BI_AGE_REPORT_REQUEST',
  GET_ADMISSION_BI_AGE_REPORT_SUCCESS: 'GET_ADMISSION_BI_AGE_REPORT_SUCCESS',
  GET_ADMISSION_BI_AGE_REPORT_ERROR: 'GET_ADMISSION_BI_AGE_REPORT_ERROR',

  GET_ADMISSION_BI_PROGRAMME_REPORT_REQUEST:
    'GET_ADMISSION_BI_PROGRAMME_REPORT_REQUEST',
  GET_ADMISSION_BI_PROGRAMME_REPORT_SUCCESS:
    'GET_ADMISSION_BI_PROGRAMME_REPORT_SUCCESS',
  GET_ADMISSION_BI_PROGRAMME_REPORT_ERROR:
    'GET_ADMISSION_BI_PROGRAMME_REPORT_ERROR',

  getAdmissionBiReport: (params) => ({
    type: admissionReportActions.GET_ADMISSION_BI_REPORT_REQUEST,
    params,
  }),

  getAdmissionProgrammesBiReport: (params) => ({
    type: admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_REQUEST,
    params,
  }),

  getAdmissionAgesBiReport: (params) => ({
    type: admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_REQUEST,
    params,
  }),
};

export default admissionReportActions;
