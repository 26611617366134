import update from 'immutability-helper';
import { studentLedgerActions } from '../../actions';
import initialState from '../../initialState';

const studentLedger = (state = initialState.studentLedger, actions) => {
  switch (actions.type) {
    case studentLedgerActions.GET_STUDENT_LEDGER_REQUEST:
      return {
        ...state,
        gettingStudentLedger: true,
        studentLedgerError: {},
      };
    case studentLedgerActions.GET_STUDENT_LEDGER_SUCCESS: {
      const { data, student } = actions;
      const { studentLedgers: ledgers } = state;

      let newState = state;
      const ledgerData = {
        student,
        studentLedgers: data,
      };
      const ledgerIndex = ledgers.findIndex(
        (ledger) => ledger.student === student
      );
      if (ledgerIndex === -1) {
        newState = update(newState, {
          studentLedgers: { $push: [ledgerData] },
        });
      } else {
        newState = update(newState, {
          studentLedgers: {
            [ledgerIndex]: { $set: ledgerData },
          },
        });
      }

      return { ...newState, gettingStudentLedger: false };
    }
    case studentLedgerActions.GET_STUDENT_LEDGER_ERROR:
      return {
        ...state,
        gettingStudentLedger: false,
        studentLedgerError: actions.error,
      };
    default:
      return state;
  }
};

export default studentLedger;
