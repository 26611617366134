const userRole = {
  GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_ERROR: 'GET_USER_ROLES_ERROR',

  GET_SINGLE_USER_ROLE_REQUEST: 'GET_SINGLE_USER_ROLE_REQUEST',
  GET_SINGLE_USER_ROLE_SUCCESS: 'GET_SINGLE_USER_ROLE_SUCCESS',
  GET_SINGLE_USER_ROLE_ERROR: 'GET_SINGLE_USER_ROLE_ERROR',

  CREATE_USER_ROLE_SUCCESS: 'CREATE_USER_ROLE_SUCCESS',
  CREATE_USER_ROLE_REQUEST: 'CREATE_USER_ROLE_REQUEST',
  CREATE_USER_ROLE_ERROR: 'CREATE_USER_ROLE_ERROR',

  UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
  UPDATE_USER_ROLE_REQUEST: 'UPDATE_USER_ROLE_REQUEST',
  UPDATE_USER_ROLE_ERROR: 'UPDATE_USER_ROLE_ERROR',

  UPDATE_USER_ROLE_PROFILE_SUCCESS: 'UPDATE_USER_ROLE_PROFILE_SUCCESS',
  UPDATE_USER_ROLE_PROFILE_REQUEST: 'UPDATE_USER_ROLE_PROFILE_REQUEST',
  UPDATE_USER_ROLE_PROFILE_ERROR: 'UPDATE_USER_ROLE_PROFILE_ERROR',

  DELETE_USER_ROLE_SUCCESS: 'DELETE_USER_ROLE_SUCCESS',
  DELETE_USER_ROLE_REQUEST: 'DELETE_USER_ROLE_REQUEST',
  DELETE_USER_ROLE_ERROR: 'DELETE_USER_ROLE_ERROR',

  INSERT_USER_ROLE_APPS_SUCCESS: 'INSERT_USER_ROLE_APPS_SUCCESS',
  INSERT_USER_ROLE_APPS_REQUEST: 'INSERT_USER_ROLE_APPS_REQUEST',
  INSERT_USER_ROLE_APPS_ERROR: 'INSERT_USER_ROLE_APPS_ERROR',

  DROP_USER_ROLE_APPS_SUCCESS: 'DROP_USER_ROLE_APPS_SUCCESS',
  DROP_USER_ROLE_APPS_REQUEST: 'DROP_USER_ROLE_APPS_REQUEST',
  DROP_USER_ROLE_APPS_ERROR: 'DROP_USER_ROLE_APPS_ERROR',

  DROP_USER_ROLE_FUNCTIONS_SUCCESS: 'DROP_USER_ROLE_FUNCTIONS_SUCCESS',
  DROP_USER_ROLE_FUNCTIONS_REQUEST: 'DROP_USER_ROLE_FUNCTIONS_REQUEST',
  DROP_USER_ROLE_FUNCTIONS_ERROR: 'DROP_USER_ROLE_FUNCTIONS_ERROR',

  ASSIGN_ROLE_USER_SUCCESS: 'ASSIGN_ROLE_USER_SUCCESS',
  ASSIGN_ROLE_USER_REQUEST: 'ASSIGN_ROLE_USER_REQUEST',
  ASSIGN_ROLE_USER_ERROR: 'ASSIGN_ROLE_USER_ERROR',

  REMOVE_USER_ROLES_SUCCESS: 'REMOVE_USER_ROLES_SUCCESS',
  REMOVE_USER_ROLES_REQUEST: 'REMOVE_USER_ROLES_REQUEST',
  REMOVE_USER_ROLES_ERROR: 'REMOVE_USER_ROLES_ERROR',

  SHOW_REMOVE_USER_ROLE_MODAL: 'SHOW_REMOVE_USER_ROLE_MODAL',

  UPDATE_ACCESS_DOMAIN_SUCCESS: 'UPDATE_ACCESS_DOMAIN_SUCCESS',
  UPDATE_ACCESS_DOMAIN_REQUEST: 'UPDATE_ACCESS_DOMAIN_REQUEST',
  UPDATE_ACCESS_DOMAIN_ERROR: 'UPDATE_ACCESS_DOMAIN_ERROR',

  GET_GROUP_USER_ROLE_BOUND_SUCCESS: 'GET_GROUP_USER_ROLE_BOUND_SUCCESS',
  GET_GROUP_USER_ROLE_BOUND_REQUEST: 'GET_GROUP_USER_ROLE_BOUND_REQUEST',
  GET_GROUP_USER_ROLE_BOUND_ERROR: 'GET_GROUP_USER_ROLE_BOUND_ERROR',

  getUserRole: (data) => ({
    type: userRole.GET_USER_ROLES_REQUEST,
    data,
  }),

  getSingleUserRole: (roleId) => ({
    type: userRole.GET_SINGLE_USER_ROLE_REQUEST,
    roleId,
  }),

  createUserRole: (data) => ({
    type: userRole.CREATE_USER_ROLE_REQUEST,
    data,
  }),

  updateUserRole: (id, data, roleGroupId) => ({
    type: userRole.UPDATE_USER_ROLE_REQUEST,
    id,
    data,
    roleGroupId,
  }),

  updateRoleProfile: (id, data) => ({
    type: userRole.UPDATE_USER_ROLE_PROFILE_REQUEST,
    id,
    data,
  }),

  deleteRole: (id, roleGroupId) => ({
    type: userRole.DELETE_USER_ROLE_REQUEST,
    id,
    roleGroupId,
  }),

  insertUserRoleApps: (data, userRoleId) => ({
    type: userRole.INSERT_USER_ROLE_APPS_REQUEST,
    data,
    userRoleId,
  }),

  dropRoleAppFunctions: (data, userRoleId) => ({
    type: userRole.DROP_USER_ROLE_FUNCTIONS_REQUEST,
    data,
    userRoleId,
  }),

  dropUserRoleApps: (data, userRoleId) => ({
    type: userRole.DROP_USER_ROLE_APPS_REQUEST,
    data,
    userRoleId,
  }),

  assignUserRoles: (data, roleId, roleGroupId) => ({
    type: userRole.ASSIGN_ROLE_USER_REQUEST,
    data,
    roleId,
    roleGroupId,
  }),

  removeUserRoles: (data, userId, roleGroupId) => ({
    type: userRole.REMOVE_USER_ROLES_REQUEST,
    data,
    userId,
    roleGroupId,
  }),

  showRemoveUserRoleModal: (payload) => ({
    type: userRole.SHOW_REMOVE_USER_ROLE_MODAL,
    payload,
  }),

  updateAccessDomain: (data, roleGroupId) => ({
    type: userRole.UPDATE_ACCESS_DOMAIN_REQUEST,
    data,
    roleGroupId,
  }),

  getUserRoleBounds: (roleId, userId) => ({
    type: userRole.GET_GROUP_USER_ROLE_BOUND_REQUEST,
    roleId,
    userId,
  }),
};

export default userRole;
