import { pendingVoidedInvoiceActions } from '../../actions';
import initialState from '../../initialState';

const pendingVoidedInvoice = (
  state = initialState.pendingVoidedInvoice,
  actions
) => {
  switch (actions.type) {
    case pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };

    case pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_SUCCESS:
      return {
        ...state,
        pendingVoidedInvoices: actions.data,
        error: {},
        loading: false,
      };

    case pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case pendingVoidedInvoiceActions.APPROVE_PENDING_VOIDED_INVOICE_REQUEST:
      return {
        ...state,
        approveError: {},
        approving: true,
      };

    case pendingVoidedInvoiceActions.APPROVE_PENDING_VOIDED_INVOICE_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };

    case pendingVoidedInvoiceActions.APPROVE_PENDING_VOIDED_INVOICE_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    case pendingVoidedInvoiceActions.REJECT_PENDING_VOIDED_INVOICE_REQUEST:
      return {
        ...state,
        rejectError: {},
        rejecting: true,
      };

    case pendingVoidedInvoiceActions.REJECT_PENDING_VOIDED_INVOICE_SUCCESS:
      return {
        ...state,
        rejectSuccess: actions.data,
        rejecting: false,
      };

    case pendingVoidedInvoiceActions.REJECT_PENDING_VOIDED_INVOICE_ERROR:
      return {
        ...state,
        rejectError: actions.error,
        rejecting: false,
      };

    default:
      return state;
  }
};
export default pendingVoidedInvoice;
