const feesBillingPolicyActions = {
  GET_FEES_BILLING_POLICIES_REQUEST: 'GET_FEES_BILLING_POLICIES_REQUEST',
  GET_FEES_BILLING_POLICIES_SUCCESS: 'GET_FEES_BILLING_POLICIES_SUCCESS',
  GET_FEES_BILLING_POLICIES_ERROR: 'GET_FEES_BILLING_POLICIES_ERROR',

  CREATE_FEES_BILLING_POLICY_REQUEST: 'CREATE_FEES_BILLING_POLICY_REQUEST',
  CREATE_FEES_BILLING_POLICY_SUCCESS: 'CREATE_FEES_BILLING_POLICY_SUCCESS',
  CREATE_FEES_BILLING_POLICY_ERROR: 'CREATE_FEES_BILLING_POLICY_ERROR',

  GET_MODULE_FEES_BILLING_POLICIES_REQUEST:
    'GET_MODULE_FEES_BILLING_POLICIES_REQUEST',
  GET_MODULE_FEES_BILLING_POLICIES_SUCCESS:
    'GET_MODULE_FEES_BILLING_POLICIES_SUCCESS',
  GET_MODULE_FEES_BILLING_POLICIES_ERROR:
    'GET_MODULE_FEES_BILLING_POLICIES_ERROR',

  CREATE_MODULE_FEES_BILLING_POLICY_REQUEST:
    'CREATE_MODULE_FEES_BILLING_POLICY_REQUEST',
  CREATE_MODULE_FEES_BILLING_POLICY_SUCCESS:
    'CREATE_MODULE_FEES_BILLING_POLICY_SUCCESS',
  CREATE_MODULE_FEES_BILLING_POLICY_ERROR:
    'CREATE_MODULE_FEES_BILLING_POLICY_ERROR',

  UPDATE_FEES_BILLING_POLICY_REQUEST: 'UPDATE_FEES_BILLING_POLICY_REQUEST',
  UPDATE_FEES_BILLING_POLICY_SUCCESS: 'UPDATE_FEES_BILLING_POLICY_SUCCESS',
  UPDATE_FEES_BILLING_POLICY_ERROR: 'UPDATE_FEES_BILLING_POLICY_ERROR',

  APPROVE_FEES_BILLING_POLICY_REQUEST: 'APPROVE_FEES_BILLING_POLICY_REQUEST',
  APPROVE_FEES_BILLING_POLICY_SUCCESS: 'APPROVE_FEES_BILLING_POLICY_SUCCESS',
  APPROVE_FEES_BILLING_POLICY_ERROR: 'APPROVE_FEES_BILLING_POLICY_ERROR',

  ACTIVATE_FEES_BILLING_POLICY_REQUEST: 'ACTIVATE_FEES_BILLING_POLICY_REQUEST',
  ACTIVATE_FEES_BILLING_POLICY_SUCCESS: 'ACTIVATE_FEES_BILLING_POLICY_SUCCESS',
  ACTIVATE_FEES_BILLING_POLICY_ERROR: 'ACTIVATE_FEES_BILLING_POLICY_ERROR',

  DELETE_FEES_BILLING_POLICY_REQUEST: 'DELETE_FEES_BILLING_POLICY_REQUEST',
  DELETE_FEES_BILLING_POLICY_SUCCESS: 'DELETE_FEES_BILLING_POLICY_SUCCESS',
  DELETE_FEES_BILLING_POLICY_ERROR: 'DELETE_FEES_BILLING_POLICY_ERROR',

  SET_FEES_BILLING_POLICY_TO_EDIT: 'SET_FEES_BILLING_POLICY_TO_EDIT',
  ADDING_FEES_BILLING_POLICY_CONTEXT: 'ADDING_FEES_BILLING_POLICY_CONTEXT',

  getFeesBillingPolicies: () => ({
    type: feesBillingPolicyActions.GET_FEES_BILLING_POLICIES_REQUEST,
  }),

  createFeesBillingPolicy: (data) => ({
    type: feesBillingPolicyActions.CREATE_FEES_BILLING_POLICY_REQUEST,
    data,
  }),

  getModuleFeesBillingPolicies: () => ({
    type: feesBillingPolicyActions.GET_MODULE_FEES_BILLING_POLICIES_REQUEST,
  }),

  createModuleFeesBillingPolicy: (data) => ({
    type: feesBillingPolicyActions.CREATE_MODULE_FEES_BILLING_POLICY_REQUEST,
    data,
  }),

  updateFeesBillingPolicy: (feesBillingPolicyId, data) => ({
    type: feesBillingPolicyActions.UPDATE_FEES_BILLING_POLICY_REQUEST,
    data,
    feesBillingPolicyId,
  }),

  approveFeesBillingPolicy: (context) => ({
    type: feesBillingPolicyActions.APPROVE_FEES_BILLING_POLICY_REQUEST,
    context,
  }),

  activateFeesBillingPolicy: (context) => ({
    type: feesBillingPolicyActions.ACTIVATE_FEES_BILLING_POLICY_REQUEST,
    context,
  }),

  deleteFeesBillingPolicy: (feesBillingPolicyId) => ({
    type: feesBillingPolicyActions.DELETE_FEES_BILLING_POLICY_REQUEST,
    feesBillingPolicyId,
  }),

  setPolicyToEdit: (feesBillingPolicy) => ({
    type: feesBillingPolicyActions.SET_FEES_BILLING_POLICY_TO_EDIT,
    feesBillingPolicy,
  }),

  setIsAddingContext: (payload) => ({
    type: feesBillingPolicyActions.ADDING_FEES_BILLING_POLICY_CONTEXT,
    payload,
  }),
};

export default feesBillingPolicyActions;
