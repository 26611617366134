const courseAssignmentState = {
  courseAssignment: {
    departmentProgrammes: [],
    gettingDepartmentProgrammes: false,
    departmentProgrammeError: {},
    departmentContext: {},
    assignmentContext: {},

    assignmentCourses: [],
    gettingAssignmentCourses: false,
    getAssignmentCourseError: {},

    selectedCourse: {},
    selectedLecturers: [],

    lecturers: [],
    gettingLecturers: false,
    getLecturerError: {},

    assignSuccess: {},
    assigningLecturers: false,
    assignError: {},

    addingLecturer: false,
    addLecturerError: {},

    addingGroups: false,
    addGroupError: {},

    editing: false,
    dataToEdit: {},
    resultCategoriesToEdit: [],

    editingLecturer: false,
    editLecturerError: {},

    removingLecturers: false,
    removeLecturerError: {},

    showAddLecturerModal: false,
    showAddGroupModal: false,
    showEditResultCategoriesModal: false,
    showDeleteModal: false,

    editCourseAssignmentResultCategories: {},
    editCourseAssignmentResultCategoriesError: {},
    editingCourseAssignmentResultCategories: false,

    contextAssignments: [],
    contextAssignment: {},
    contextAssignmentError: {},
    gettingContextAssignments: false,

    submittedResults: [],
    gettingSubmittedResults: false,
    getSubmittedResultsError: {},

    unSubmittingResults: false,
    unSubmitResultsError: {},
    unSubmitResultModal: false,

    showViewSubmittedResult: false,

    makingResultsProvisional: false,
    makeResultsProvisionalError: {},
    resultsToMakeProvisional: [],

    submittingResultsToFaculty: false,
    submitResultsToFacultyError: {},

    gettingProvisionalResults: false,
    facultyReportResults: [],
    viewProvisionalResultsError: {},
    facultyResultsContext: {},

    hodStatisticsContext: {},
    hodStatistics: [],
    gettingHODStatistics: false,
    hodStatisticsError: {},

    showViewAnalyticsResults: false,
    analyticsResults: {},

    facultyAnalytics: [],
    gettingFacultyAnalytics: false,
    facultyAnalyticsError: {},

    quatecAnalytics: [],
    gettingQuatecAnalytics: false,
    quatecAnalyticsError: {},

    quatecResultsContext: {},
    quatecResults: [],
    gettingQuatecResults: false,
    quatecResultsError: {},

    submitResultsToSenateError: {},
    submittingResultsToSenate: false,

    senateResultsContext: {},
    senateResults: [],
    gettingSenateResults: false,
    senateResultsError: {},

    resultToView: {},
    showViewSubmittedResultModal: false,

    submittingResults: false,
    submitResultsError: {},

    activeApprovalHierarchy: {},

    courseUnitResultApprovals: [],
    gettingCourseUnitResultApprovals: false,
    getCourseUnitResultApprovalsError: {},

    programmeResultApprovals: [],
    gettingProgrammeResultApprovals: false,
    getProgrammeResultApprovalsError: {},

    lecturerCategoriesContext: {},
    lecturerCategories: [],
    gettingLecturerCategories: false,
    getLecturerCategoriesError: {},

    creatingLecturerCategory: false,
    createLecturerCategoryError: {},
    showLecturerCategoryForm: false,

    editingLecturerCategory: false,
    editLecturerCategoryError: {},
    lecturerCategoryToEdit: {},

    deletingLecturerCategory: false,
    deleteLecturerCategoryError: {},
    deleteLecturerCategoryResponse: {},
    lecturerCategoryToDelete: {},

    copyingLecturerCategories: false,
    copyLecturerCategoriesError: null,
    showCopyLecturerCategoryForm: false,
    showUploadLecturerCategories: false,

    editingCourseAssignmentGroups: false,
    editCourseAssignmentGroupsError: {},
    courseAssignmentGroupToEdit: {},

    deletingCourseAssignmentGroup: false,
    deleteCourseAssignmentGroupError: {},
    courseAssignmentGroupToDelete: {},
  },
  building: {
    fetching: false,
    buildings: [],
    error: {},

    editingRoom: false,
    editRoomError: {},
    editRoomResponse: {},

    gettingByCampus: false,
    buildingsByCampus: [],
    getByCampusError: {},

    creatingBuilding: false,
    creatingBuildingError: {},
    creatingBuildingSuccess: {},

    downloadingTemplate: false,
    errorDownloadingTemplate: {},
    successDownloadingTemplate: {},

    uploading: false,
    uploadError: {},
    uploadResponse: {},

    deleting: false,
    deleteError: {},
    deleteResponse: {},

    editing: false,
    editError: {},
    editResponse: {},

    selectedCampusId: null,

    programmeContextId: null,

    roomAllocationContext: {},
    roomAllocationReport: [],
    gettingRoomAllocationReport: false,
    getRoomAllocationReportError: {},

    roomAllocations: {},
  },
  teachingTimetable: {
    loading: false,
    teachingTimetable: [],
    loadError: {},

    searching: false,
    searchedTeachingTimetable: [],
    searchError: {},
    searchContext: {},

    searchingByDepartment: false,
    departmentTimetable: [],
    byDepartmentError: {},

    searchingByFaculty: false,
    facultyTimetable: [],
    byFacultyError: {},
    facultyContext: {},

    showAssignLecturerModal: false,

    assigningLecturer: false,
    assigLectureError: {},
    assigedLecture: {},

    downloadingTemplate: false,
    errorDownloadingTemplate: {},
    successDownloadingTemplate: {},

    projectedLecturerTeachingLoadContext: {},
    projectedLecturerTeachingLoad: [],
    gettingProjectedLecturerTeachingLoad: false,
    getProjectedLecturerTeachingLoadError: {},

    lecturerTeachingLoad: {},
    showLecturerTeachingLoadModal: false,

    uploading: false,
    uploadError: {},
    uploadResponse: {},

    deleting: false,
    deleteError: {},
    deleteResponse: {},

    editing: false,
    editError: {},
    editResponse: {},

    downloadingProgrammeTimetable: false,
    downloadProgrammeTimetableError: {},

    lecturersToView: {},

    myTeachingTimetableContext: {},
    gettingMyTeachingTimetable: false,
    myTeachingTimetable: [],
    getMyTeachingTimetableError: {},
  },
  semesterCourseLoad: {
    semesterCourseLoads: [],
    loading: false,
    loadError: {},

    updating: false,
    updateError: {},
    updatedData: {},

    creating: false,
    createError: {},
    createdData: {},

    deleting: false,
    deleteError: {},
    deleteResponse: {},

    context: {},

    currentTab: 'semester-course-loads',

    showEditModal: false,
  },
};

export default courseAssignmentState;
