import { applicationFeesPolicyActions } from '../../actions';
import initialState from '../../initialState';

const applicationFeesPolicy = (
  state = initialState.applicationFeesPolicy,
  actions
) => {
  switch (actions.type) {
    case applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
      };
    case applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_SUCCESS:
      return {
        ...state,
        applicationFeesPolicies: actions.data,
        loading: false,
      };
    case applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateApplicationPolicyError: {},
        updatingApplicationPolicy: true,
      };
    case applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        updateApplicationPolicyResponse: actions.data,
        updatingApplicationPolicy: false,
      };
    case applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_ERROR:
      return {
        ...state,
        updateApplicationPolicyError: actions.error,
        updatingApplicationPolicy: false,
      };

    case applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_REQUEST:
      return {
        ...state,
        deleteApplicationPolicyError: {},
        deletingApplicationPolicy: true,
      };
    case applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        deleteApplicationPolicyResponse: actions.data,
        deletingApplicationPolicy: false,
      };
    case applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_ERROR:
      return {
        ...state,
        deleteApplicationPolicyError: actions.error,
        deletingApplicationPolicy: false,
      };

    case applicationFeesPolicyActions.SET_SELECTED_APPLICATION_POLICY:
      return {
        ...state,
        selectedPolicy: actions.selectedPolicy,
      };

    case applicationFeesPolicyActions.SET_APPLICATION_FEES_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policyToEdit,
      };

    default:
      return state;
  }
};

export default applicationFeesPolicy;
