import { specialEntryApplicantActions } from '../../actions';
import specialEntryMgtInitialState from '../../initialState/specialEntry';

const specialEntryApplicants = (
  state = specialEntryMgtInitialState.specialEntryApplicants,
  actions
) => {
  switch (actions.type) {
    case specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST:
      return {
        ...state,
        gettingApplicants: true,
        getApplicantsError: {},
      };
    case specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_SUCCESS:
      return {
        ...state,
        gettingApplicants: false,
        specialEntryApplicants: actions.data,
      };
    case specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_ERROR:
      return {
        ...state,
        gettingApplicants: false,
        getApplicantsError: actions.error,
      };

    case specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST:
      return {
        ...state,
        gettingMeritAdmissions: true,
        getMeritAdmissionsError: {},
      };
    case specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_SUCCESS:
      return {
        ...state,
        gettingMeritAdmissions: false,
        meritAdmissions: actions.data,
      };
    case specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_ERROR:
      return {
        ...state,
        gettingMeritAdmissions: false,
        getMeritAdmissionsError: actions.error,
      };

    case specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: actions.data,
      };
    case specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    default:
      return state;
  }
};

export default specialEntryApplicants;
