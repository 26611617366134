const functionalFeesActions = {
  GET_FUNCTIONAL_FEES_REQUEST: 'GET_FUNCTIONAL_FEES_REQUEST',
  GET_FUNCTIONAL_FEES_SUCCESS: 'GET_FUNCTIONAL_FEES_SUCCESS',
  GET_FUNCTIONAL_FEES_ERROR: 'GET_FUNCTIONAL_FEES_ERROR',

  GET_FUNCTIONAL_FEES_ELEMENTS_REQUEST: 'GET_FUNCTIONAL_FEES_ELEMENTS_REQUEST',
  GET_FUNCTIONAL_FEES_ELEMENTS_SUCCESS: 'GET_FUNCTIONAL_FEES_ELEMENTS_SUCCESS',
  GET_FUNCTIONAL_FEES_ELEMENTS_ERROR: 'GET_FUNCTIONAL_FEES_ELEMENTS_ERROR',

  CREATE_FUNCTIONAL_FEES_REQUEST: 'CREATE_FUNCTIONAL_FEES_REQUEST',
  CREATE_FUNCTIONAL_FEES_SUCCESS: 'CREATE_FUNCTIONAL_FEES_SUCCESS',
  CREATE_FUNCTIONAL_FEES_ERROR: 'CREATE_FUNCTIONAL_FEES_ERROR',

  ADD_FUNCTIONAL_FEES_ELEMENTS_REQUEST: 'ADD_FUNCTIONAL_FEES_ELEMENTS_REQUEST',
  ADD_FUNCTIONAL_FEES_ELEMENTS_SUCCESS: 'ADD_FUNCTIONAL_FEES_ELEMENTS_SUCCESS',
  ADD_FUNCTIONAL_FEES_ELEMENTS_ERROR: 'ADD_FUNCTIONAL_FEES_ELEMENTS_ERROR',

  UPDATE_FUNCTIONAL_FEES_REQUEST: 'UPDATE_FUNCTIONAL_FEES_REQUEST',
  UPDATE_FUNCTIONAL_FEES_SUCCESS: 'UPDATE_FUNCTIONAL_FEES_SUCCESS',
  UPDATE_FUNCTIONAL_FEES_ERROR: 'UPDATE_FUNCTIONAL_FEES_ERROR',

  UPDATE_UNIQUE_FUNCTIONAL_FEES_REQUEST:
    'UPDATE_UNIQUE_FUNCTIONAL_FEES_REQUEST',
  UPDATE_UNIQUE_FUNCTIONAL_FEES_SUCCESS:
    'UPDATE_UNIQUE_FUNCTIONAL_FEES_SUCCESS',
  UPDATE_UNIQUE_FUNCTIONAL_FEES_ERROR: 'UPDATE_UNIQUE_FUNCTIONAL_FEES_ERROR',

  DELETE_FUNCTIONAL_FEES_REQUEST: 'DELETE_FUNCTIONAL_FEES_REQUEST',
  DELETE_FUNCTIONAL_FEES_SUCCESS: 'DELETE_FUNCTIONAL_FEES_SUCCESS',
  DELETE_FUNCTIONAL_FEES_ERROR: 'DELETE_FUNCTIONAL_FEES_ERROR',

  GET_PENDING_FUNCTIONAL_FEES_REQUEST: 'GET_PENDING_FUNCTIONAL_FEES_REQUEST',
  GET_PENDING_FUNCTIONAL_FEES_SUCCESS: 'GET_PENDING_FUNCTIONAL_FEES_SUCCESS',
  GET_PENDING_FUNCTIONAL_FEES_ERROR: 'GET_PENDING_FUNCTIONAL_FEES_ERROR',

  DOWNLOAD_FUNCTIONAL_FEES_REQUEST: 'DOWNLOAD_FUNCTIONAL_FEES_REQUEST',
  DOWNLOAD_FUNCTIONAL_FEES_SUCCESS: 'DOWNLOAD_FUNCTIONAL_FEES_SUCCESS',
  DOWNLOAD_FUNCTIONAL_FEES_ERROR: 'DOWNLOAD_FUNCTIONAL_FEES_ERROR',

  UPLOAD_FUNCTIONAL_FEES_REQUEST: 'UPLOAD_FUNCTIONAL_FEES_REQUEST',
  UPLOAD_FUNCTIONAL_FEES_SUCCESS: 'UPLOAD_FUNCTIONAL_FEES_SUCCESS',
  UPLOAD_FUNCTIONAL_FEES_ERROR: 'UPLOAD_FUNCTIONAL_FEES_ERROR',

  SET_FUNCTIONAL_FEES_CONTEXT: 'SET_FUNCTIONAL_FEES_CONTEXT',
  SET_UNIQUE_FUNCTIONAL_FEES_CONTEXT: 'SET_UNIQUE_FUNCTIONAL_FEES_CONTEXT',
  SET_FUNCTIONAL_FEES_TO_EDIT: 'SET_FUNCTIONAL_FEES_TO_EDIT',
  SET_SELECTED_TYPE_AMOUNTS: 'SET_SELECTED_TYPE_AMOUNTS',

  getFunctionalFeesAmounts: (category, context) => ({
    type: functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST,
    category,
    context,
  }),

  getFunctionalFeesElements: (context) => ({
    type: functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_REQUEST,
    context,
  }),

  setFunctionalFeesContext: (context) => ({
    type: functionalFeesActions.SET_FUNCTIONAL_FEES_CONTEXT,
    context,
  }),

  setUniqueFunctionalFeesContext: (context) => ({
    type: functionalFeesActions.SET_UNIQUE_FUNCTIONAL_FEES_CONTEXT,
    context,
  }),

  setSelectedTypeAmounts: (data) => ({
    type: functionalFeesActions.SET_SELECTED_TYPE_AMOUNTS,
    data,
  }),

  setDataToEdit: (data) => ({
    type: functionalFeesActions.SET_FUNCTIONAL_FEES_TO_EDIT,
    data,
  }),

  createFunctionalFeesAmounts: (category, data) => ({
    type: functionalFeesActions.CREATE_FUNCTIONAL_FEES_REQUEST,
    category,
    data,
  }),

  addFunctionalFeesElements: (contextId, data, currentContext) => ({
    type: functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_REQUEST,
    contextId,
    data,
    currentContext,
  }),

  updateFunctionalFeesAmount: (id, data, currentContext) => ({
    type: functionalFeesActions.UPDATE_FUNCTIONAL_FEES_REQUEST,
    id,
    data,
    currentContext,
  }),

  updateUniqueFunctionalFeesAmount: (data, currentContext) => ({
    type: functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_REQUEST,
    data,
    currentContext,
  }),

  deleteFunctionalFeesAmount: (id, currentContext) => ({
    type: functionalFeesActions.DELETE_FUNCTIONAL_FEES_REQUEST,
    id,
    currentContext,
  }),

  getPendingFunctionalFeesAmounts: (context) => ({
    type: functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_REQUEST,
    context,
  }),

  downloadFunctionalFeesTemplate: () => ({
    type: functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_REQUEST,
  }),

  uploadFunctionalFeesAmounts: (data) => ({
    type: functionalFeesActions.UPLOAD_FUNCTIONAL_FEES_REQUEST,
    data,
  }),
};
export default functionalFeesActions;
