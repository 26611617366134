const bulkPayments = {
  GET_PENDING_BULK_PAYMENTS_REQUEST: 'GET_PENDING_BULK_PAYMENTS_REQUEST',
  GET_PENDING_BULK_PAYMENTS_SUCCESS: 'GET_PENDING_BULK_PAYMENTS_SUCCESS',
  GET_PENDING_BULK_PAYMENTS_ERROR: 'GET_PENDING_BULK_PAYMENTS_ERROR',

  ACKNOWLEDGE_BULK_PAYMENTS_REQUEST: 'ACKNOWLEDGE_BULK_PAYMENTS_REQUEST',
  ACKNOWLEDGE_BULK_PAYMENTS_SUCCESS: 'ACKNOWLEDGE_BULK_PAYMENTS_SUCCESS',
  ACKNOWLEDGE_BULK_PAYMENTS_ERROR: 'ACKNOWLEDGE_BULK_PAYMENTS_ERROR',

  GET_ACKNOWLEDGED_PAYMENTS_REQUEST: 'GET_ACKNOWLEDGED_PAYMENTS_REQUEST',
  GET_ACKNOWLEDGED_PAYMENTS_SUCCESS: 'GET_ACKNOWLEDGED_PAYMENTS_SUCCESS',
  GET_ACKNOWLEDGED_PAYMENTS_ERROR: 'GET_ACKNOWLEDGED_PAYMENTS_ERROR',

  getPendingBulkPayments: () => ({
    type: bulkPayments.GET_PENDING_BULK_PAYMENTS_REQUEST,
  }),

  acknowledgeBulkPayments: (paymentId) => ({
    type: bulkPayments.ACKNOWLEDGE_BULK_PAYMENTS_REQUEST,
    paymentId,
  }),

  getAcknowledgedBulkPayments: () => ({
    type: bulkPayments.GET_ACKNOWLEDGED_PAYMENTS_REQUEST,
  }),
};

export default bulkPayments;
