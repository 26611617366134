import { admissionReportActions } from '../../actions';
import initialState from '../../initialState';

const admissionReport = (state = initialState.admissionReport, actions) => {
  switch (actions.type) {
    case admissionReportActions.GET_ADMISSION_BI_REPORT_REQUEST:
      return {
        ...state,
        gettingReport: true,
        admissionReportError: {},
      };
    case admissionReportActions.GET_ADMISSION_BI_REPORT_SUCCESS: {
      let { admissionsReport } = state;
      const { context } = actions;
      const findIndex = admissionsReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        admissionsReport = [
          ...admissionsReport,
          { ...context, data: actions.data.report },
        ];
      } else {
        admissionsReport[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        admissionsReport,
        gettingReport: false,
      };
    }

    case admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_REQUEST:
      return {
        ...state,
        gettingProgrammeReport: true,
        programmeReportError: {},
      };
    case admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_SUCCESS: {
      let { admissionsProgrammesReport } = state;
      const { context } = actions;
      const findIndex = admissionsProgrammesReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        admissionsProgrammesReport = [
          ...admissionsProgrammesReport,
          { ...context, data: actions.data },
        ];
      } else {
        admissionsProgrammesReport[findIndex] = {
          ...context,
          data: actions.data,
        };
      }

      return {
        ...state,
        admissionsProgrammesReport,
        gettingProgrammeReport: false,
      };
    }
    case admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_ERROR:
      return {
        ...state,
        programmeReportError: actions.error,
        gettingProgrammeReport: false,
      };

    case admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_REQUEST:
      return {
        ...state,
        gettingAgeReport: true,
        ageReportError: {},
      };
    case admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_SUCCESS: {
      let { admissionsAgeReport } = state;
      const { context } = actions;
      const findIndex = admissionsAgeReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        admissionsAgeReport = [
          ...admissionsAgeReport,
          { ...context, data: actions.data },
        ];
      } else {
        admissionsAgeReport[findIndex] = {
          ...context,
          data: actions.data,
        };
      }

      return {
        ...state,
        admissionsAgeReport,
        gettingAgeReport: false,
      };
    }
    case admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_ERROR:
      return {
        ...state,
        ageReportError: actions.error,
        gettingAgeReport: false,
      };

    default:
      return state;
  }
};

export default admissionReport;
