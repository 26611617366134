import React from 'react';
import PropTypes, { any } from 'prop-types';
import { Alert } from 'react-bootstrap';

function AlertMessage({ message, icon, type, ...props }) {
  return (
    <Alert variant={type} {...props}>
      {icon}
      {message}
    </Alert>
  );
}

AlertMessage.defaultProps = {
  type: 'danger',
  message: null,
  show: true,
  icon: null,
};

AlertMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  show: PropTypes.bool,
  icon: PropTypes.oneOfType([any]),
};

export default AlertMessage;
