import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  runningAdmissionActions,
  runningAdmissionCampusActions,
  tabActions,
} from '../../actions';

function* getRunningAdmissionCampuses(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admission-programme-campuses',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_ERROR,
      error: error.data,
    });
  }
}

function* createRunningAdmissionCampus(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admission-programme-campuses',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.CREATE_RUNNING_ADMISSION_CAMPUS_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.admissionId,
    });

    yield put({
      type: 'SHOW_ADVERTISING_PROGRAM_MODAL',
      payload: false,
    });

    yield put({
      type: tabActions.CURRENT_TAB,
      activeTab: 'advertised-programmes',
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.CREATE_RUNNING_ADMISSION_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* updateRemarks(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/update-special-remarks/${actions.runningProgrammeId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.EDIT_SPECIAL_REMARKS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: runningAdmissionActions.GET_MANAGED_PROG_DETAILS_REQUEST,
      contextId: actions.runningProgrammeId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.EDIT_SPECIAL_REMARKS_ERROR,
      error: error.data,
    });
  }
}

function* getSingleRunningAdmissionCampus(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/${actions.runningAdmissionCampusId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* updateRunningAdmissionCampus(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/${actions.runningAdmissionCampusId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.UPDATE_RUNNING_ADMISSION_CAMPUS_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_ADVERTISING_PROGRAM_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.UPDATE_RUNNING_ADMISSION_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* updateSingleCapacitySetting(actions) {
  try {
    const { capacitySettingId, runningAdmissionProgrammeId } = actions;
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/${capacitySettingId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.UPDATE_CAPACITY_SETTING_SUCCESS,
      data: response.data,
    });
    yield put({
      type: runningAdmissionActions.GET_MANAGED_PROG_DETAILS_REQUEST,
      contextId: runningAdmissionProgrammeId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.UPDATE_CAPACITY_SETTING_ERROR,
      error: error.data,
    });
  }
}

function* createSingleCapacitySetting(actions) {
  try {
    const { data } = actions;
    const response = yield axios({
      url: 'admissions/running-admission-programme-campuses/capacity-setting',
      method: 'POST',
      data,
    });
    yield put({
      type: runningAdmissionCampusActions.CREATE_CAPACITY_SETTING_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_MANAGED_PROG_DETAILS_REQUEST,
      contextId: data.running_admission_programme_id,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.CREATE_CAPACITY_SETTING_ERROR,
      error: error.data,
    });
  }
}

function* deleteSingleCapacitySetting(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/${actions.capacitySettingId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionCampusActions.DELETE_CAPACITY_SETTING_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
      runningAdmissionId: actions.runningAdmissionId,
    });
    yield put({
      type: 'SHOW_ADVERTISING_PROGRAM_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.DELETE_CAPACITY_SETTING_ERROR,
      error: error.data,
    });
  }
}

function* deleteRunningAdmissionCampus(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/${actions.runningAdmissionCampusId}`,
      method: 'DELETE',
    });
    yield put({
      type: runningAdmissionCampusActions.DELETE_RUNNING_ADMISSION_CAMPUS_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionCampusActions.DELETE_RUNNING_ADMISSION_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRunningAdmissionCampuses() {
  yield takeLatest(
    runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_REQUEST,
    getRunningAdmissionCampuses
  );
}

function* watchCreateRunningAdmissionCampus() {
  yield takeLatest(
    runningAdmissionCampusActions.CREATE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    createRunningAdmissionCampus
  );
}

function* watchUpdateRemarks() {
  yield takeLatest(
    runningAdmissionCampusActions.EDIT_SPECIAL_REMARKS_REQUEST,
    updateRemarks
  );
}

function* watchGetSingleRunningAdmissionCampus() {
  yield takeLatest(
    runningAdmissionCampusActions.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    getSingleRunningAdmissionCampus
  );
}

function* watchUpdateRunningAdmissionCampus() {
  yield takeLatest(
    runningAdmissionCampusActions.UPDATE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    updateRunningAdmissionCampus
  );
}

function* watchUpdateSingleCapacitySetting() {
  yield takeLatest(
    runningAdmissionCampusActions.UPDATE_CAPACITY_SETTING_REQUEST,
    updateSingleCapacitySetting
  );
}

function* watchCreateSingleCapacitySetting() {
  yield takeLatest(
    runningAdmissionCampusActions.CREATE_CAPACITY_SETTING_REQUEST,
    createSingleCapacitySetting
  );
}

function* watchDeleteSingleCapacitySetting() {
  yield takeLatest(
    runningAdmissionCampusActions.DELETE_CAPACITY_SETTING_REQUEST,
    deleteSingleCapacitySetting
  );
}

function* watchDeleteRunningAdmissionCampus() {
  yield takeLatest(
    runningAdmissionCampusActions.DELETE_RUNNING_ADMISSION_CAMPUS_REQUEST,
    deleteRunningAdmissionCampus
  );
}

export default [
  fork(watchGetRunningAdmissionCampuses),
  fork(watchCreateRunningAdmissionCampus),
  fork(watchUpdateRunningAdmissionCampus),
  fork(watchGetSingleRunningAdmissionCampus),
  fork(watchDeleteRunningAdmissionCampus),
  fork(watchCreateSingleCapacitySetting),
  fork(watchUpdateSingleCapacitySetting),
  fork(watchDeleteSingleCapacitySetting),
  fork(watchUpdateRemarks),
];
