import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { unebCenterActions } from '../../actions';

function* getUnebCenters(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/uneb-centers',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: unebCenterActions.GET_UNEB_CENTERS_SUCCESS,
      data: response.data,
      category: actions?.data?.category,
    });
  } catch (error) {
    yield put({
      type: unebCenterActions.GET_UNEB_CENTERS_ERROR,
      error: error.data,
    });
  }
}

function* updateUnebCenter(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/uneb-centers/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: unebCenterActions.UPDATE_UNEB_CENTER_SUCCESS,
      data: response,
    });
    yield put({
      type: unebCenterActions.GET_UNEB_CENTERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: unebCenterActions.UPDATE_UNEB_CENTER_ERROR,
      error: error.data,
    });
  }
}

function* getSingleUnebCenter(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/uneb-centers/${actions.unebCenterId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: unebCenterActions.GET_SINGLE_UNEB_CENTER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: unebCenterActions.GET_SINGLE_UNEB_CENTER_ERROR,
      error: error.data,
    });
  }
}

function* createUnebCenter(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/uneb-centers',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: unebCenterActions.CREATE_UNEB_CENTER_SUCCESS,
      data: response,
    });
    yield put({
      type: unebCenterActions.GET_UNEB_CENTERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: unebCenterActions.CREATE_UNEB_CENTER_ERROR,
      error: error.data,
    });
  }
}

function* deleteUnebCenter(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/uneb-centers/${actions.id}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: unebCenterActions.DELETE_UNEB_CENTER_SUCCESS,
      data: response,
    });
    yield put({
      type: unebCenterActions.GET_UNEB_CENTERS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: unebCenterActions.DELETE_UNEB_CENTER_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUnebCenters() {
  yield takeLatest(unebCenterActions.GET_UNEB_CENTERS_REQUEST, getUnebCenters);
}

function* watchCreateUnebCenter() {
  yield takeLatest(
    unebCenterActions.CREATE_UNEB_CENTER_REQUEST,
    createUnebCenter
  );
}

function* watchUpdateUnebCenter() {
  yield takeLatest(
    unebCenterActions.UPDATE_UNEB_CENTER_REQUEST,
    updateUnebCenter
  );
}

function* watchDeleteUnebCenter() {
  yield takeLatest(
    unebCenterActions.DELETE_UNEB_CENTER_REQUEST,
    deleteUnebCenter
  );
}

function* watchGetSingleUnebCenter() {
  yield takeLatest(
    unebCenterActions.GET_SINGLE_UNEB_CENTER_REQUEST,
    getSingleUnebCenter
  );
}

export default [
  fork(watchGetUnebCenters),
  fork(watchCreateUnebCenter),
  fork(watchUpdateUnebCenter),
  fork(watchGetSingleUnebCenter),
  fork(watchDeleteUnebCenter),
];
