const emisStudentActions = {
  GET_EMIS_STUDENTS_REQUEST: 'GET_EMIS_STUDENTS_REQUEST',
  GET_EMIS_STUDENTS_SUCCESS: 'GET_EMIS_STUDENTS_SUCCESS',
  GET_EMIS_STUDENTS_ERROR: 'GET_EMIS_STUDENTS_ERROR',

  SUBMIT_EMIS_STUDENT_REQUEST: 'SUBMIT_EMIS_STUDENT_REQUEST',
  SUBMIT_EMIS_STUDENT_SUCCESS: 'SUBMIT_EMIS_STUDENT_SUCCESS',
  SUBMIT_EMIS_STUDENT_ERROR: 'SUBMIT_EMIS_STUDENT_ERROR',

  getEmisStudents: (context) => ({
    type: emisStudentActions.GET_EMIS_STUDENTS_REQUEST,
    context,
  }),

  submitStudents: (data, context) => ({
    type: emisStudentActions.SUBMIT_EMIS_STUDENT_REQUEST,
    data,
    context,
  }),
};

export default emisStudentActions;
