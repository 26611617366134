import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { systemSchemeActions } from '../../actions';

function* getSystemSchemes() {
  try {
    const response = yield axios({
      url: `/developer/courses/acmis-schemes`,
      method: 'GET',
    });
    yield put({
      type: systemSchemeActions.GET_SYSTEM_SCHEMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: systemSchemeActions.GET_SYSTEM_SCHEMES_ERROR,
      error: error.data,
    });
  }
}

function* onSubmitSchemes(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/entry-schemes`,
      method: 'POST',
      data: { requests: actions.payload },
    });
    yield put({
      type: systemSchemeActions.ON_SUBMIT_SCHEMES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: systemSchemeActions.GET_SYSTEM_SCHEMES_REQUEST,
    });
    yield put({
      type: systemSchemeActions.SHOW_SUBMIT_SCHEME_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: systemSchemeActions.ON_SUBMIT_SCHEMES_ERROR,
      error: error.data,
    });
    yield put({
      type: systemSchemeActions.SHOW_SUBMIT_SCHEME_MODAL,
      payload: false,
    });
  }
}

function* watchGetSystemSchemes() {
  yield takeLatest(
    systemSchemeActions.GET_SYSTEM_SCHEMES_REQUEST,
    getSystemSchemes
  );
}

function* watchOnSubmitSchemes() {
  yield takeLatest(
    systemSchemeActions.ON_SUBMIT_SCHEMES_REQUEST,
    onSubmitSchemes
  );
}

export default [fork(watchGetSystemSchemes), fork(watchOnSubmitSchemes)];
