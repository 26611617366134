const userLogsActions = {
  GET_USER_LOGS_REQUEST: 'GET_USER_LOGS_REQUEST',
  GET_USER_LOGS_SUCCESS: 'GET_USER_LOGS_SUCCESS',
  GET_USER_LOGS_ERROR: 'GET_USER_LOGS_ERROR',

  GET_APPLICATION_LOGS_REQUEST: 'GET_APPLICATION_LOGS_REQUEST',
  GET_APPLICATION_LOGS_SUCCESS: 'GET_APPLICATION_LOGS_SUCCESS',
  GET_APPLICATION_LOGS_ERROR: 'GET_APPLICATION_LOGS_ERROR',

  getUserLogs: () => ({
    type: userLogsActions.GET_USER_LOGS_REQUEST,
  }),

  getApplicationLogs: (context) => ({
    type: userLogsActions.GET_APPLICATION_LOGS_REQUEST,
    context,
  }),
};

export default userLogsActions;
