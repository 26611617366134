import { programmeAliasActions } from '../../actions';
import initialState from '../../initialState';

function programmeAlias(state = initialState.programmeAlias, actions) {
  switch (actions.type) {
    case programmeAliasActions.CREATE_PROGRAMME_ALIAS_REQUEST:
      return {
        ...state,
        programmeAlias: {},
        createError: {},
        creating: true,
      };
    case programmeAliasActions.CREATE_PROGRAMME_ALIAS_SUCCESS:
      return {
        ...state,
        programmeAlias: actions.data,
        creatingError: {},
        creating: false,
      };
    case programmeAliasActions.CREATE_PROGRAMME_ALIAS_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };
    case programmeAliasActions.UPDATE_PROGRAMME_ALIAS_REQUEST:
      return {
        ...state,
        updateError: {},
        programmeAlias: {},
        updating: true,
      };
    case programmeAliasActions.UPDATE_PROGRAMME_ALIAS_SUCCESS:
      return {
        ...state,
        programmeAlias: actions.data,
        updating: false,
      };
    case programmeAliasActions.UPDATE_PROGRAMME_ALIAS_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };
    case programmeAliasActions.DELETE_PROGRAMME_ALIAS_REQUEST:
      return {
        ...state,
        deleteError: {},
        programmeAlias: {},
        deleteResponse: {},
        deleting: true,
      };
    case programmeAliasActions.DELETE_PROGRAMME_ALIAS_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case programmeAliasActions.DELETE_PROGRAMME_ALIAS_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };
    default:
      return state;
  }
}

export default programmeAlias;
