import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';

import { systemAppActions } from '../../actions';

function* getSystemApps(actions) {
  try {
    const response = yield axios({
      url: '/users/apps',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemAppActions.GET_SYSTEM_APP_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: systemAppActions.GET_SYSTEM_APP_ERROR,
      error: error.data,
    });
  }
}

function* watchgetSystemApps() {
  yield takeLatest(systemAppActions.GET_SYSTEM_APP_REQUEST, getSystemApps);
}

export default [fork(watchgetSystemApps)];
