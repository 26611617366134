import { upperFirst } from 'lodash';
import { feesApprovalsActions } from '../../actions';
import initialState from '../../initialState';

const feesApprovals = (state = initialState.feesApprovals, actions) => {
  switch (actions.type) {
    case feesApprovalsActions.GET_PENDING_FEES_REQUEST:
      return {
        ...state,
        getPendingError: {},
        gettingPendingFees: true,
      };
    case feesApprovalsActions.GET_PENDING_FEES_SUCCESS:
      return {
        ...state,
        [`pending${upperFirst(actions.data.feesType)}`]:
          actions.data.pendingFees,
        approveError: {},
        gettingPendingFees: false,
      };
    case feesApprovalsActions.GET_PENDING_FEES_ERROR:
      return {
        ...state,
        getPendingError: actions.error,
        gettingPendingFees: false,
      };

    case feesApprovalsActions.GET_PENDING_OTHER_FEES_REQUEST:
      return {
        ...state,
        getPendingError: {},
        gettingPendingOtherFees: true,
      };
    case feesApprovalsActions.GET_PENDING_OTHER_FEES_SUCCESS:
      return {
        ...state,
        pendingOtherFees: actions.data,
        approveError: {},
        gettingPendingOtherFees: false,
      };
    case feesApprovalsActions.GET_PENDING_OTHER_FEES_ERROR:
      return {
        ...state,
        getPendingError: actions.error,
        gettingPendingOtherFees: false,
      };

    case feesApprovalsActions.GET_PENDING_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        getPendingError: {},
        gettingPendingFeesWaivers: true,
      };
    case feesApprovalsActions.GET_PENDING_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        pendingFeesWaivers: actions.data,
        approveError: {},
        gettingPendingFeesWaivers: false,
      };
    case feesApprovalsActions.GET_PENDING_FEES_WAIVERS_ERROR:
      return {
        ...state,
        getPendingError: actions.error,
        gettingPendingFeesWaivers: false,
      };

    case feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        getPendingError: {},
        gettingPendingStudentFeesWaivers: true,
      };
    case feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        pendingStudentFeesWaivers: actions.data,
        approveError: {},
        gettingPendingStudentFeesWaivers: false,
      };
    case feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_ERROR:
      return {
        ...state,
        getPendingError: actions.error,
        gettingPendingStudentFeesWaivers: false,
      };

    case feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        approveError: {},
        approvalResponse: {},
        approving: true,
      };
    case feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        approvalResponse: actions.data,
        approving: false,
      };
    case feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    case feesApprovalsActions.GET_PENDING_TUITION_FEES_REQUEST:
      return {
        ...state,
        getPendingError: {},
        gettingPendingTuitionFees: true,
      };
    case feesApprovalsActions.GET_PENDING_TUITION_FEES_SUCCESS:
      return {
        ...state,
        pendingTuitionFees: actions.data,
        gettingPendingTuitionFees: false,
      };
    case feesApprovalsActions.GET_PENDING_TUITION_FEES_ERROR:
      return {
        ...state,
        getPendingError: actions.error,
        gettingPendingTuitionFees: false,
      };

    case feesApprovalsActions.APPROVE_PENDING_FEES_REQUEST:
      return {
        ...state,
        approveError: {},
        approvalResponse: {},
        approving: true,
      };
    case feesApprovalsActions.APPROVE_PENDING_FEES_SUCCESS:
      return {
        ...state,
        approvalResponse: actions.data,
        approving: false,
      };
    case feesApprovalsActions.APPROVE_PENDING_FEES_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };
    case feesApprovalsActions.SET_PENDING_FEES_TO_APPROVE:
      return {
        ...state,
        feesToApprove: actions.data,
      };
    default:
      return state;
  }
};

export default feesApprovals;
