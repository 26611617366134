import axios from 'axios';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { authActions, roleGroupActions } from '../../actions';

function* getRoleGroups(actions) {
  try {
    const response = yield axios({
      url: '/users/user-role-groups',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_SUCCESS,
      data: response?.userRoleGroups || [],
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* getRoleGroupUsers(actions) {
  try {
    const response = yield axios({
      url: `/users/user-role-groups/users/${actions.roleGroupId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_SUCCESS,
      data: response.data,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_ERROR,
      error: error.data,
    });
  }
}

function* getSingleRoleGroup(actions) {
  try {
    const response = yield axios({
      url: `/users/user-role-groups/${actions.roleGroupId}`,
      method: 'GET',
      timeout: 1200000,
    });
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_SUCCESS,
      data: response.data,
    });
    yield put({
      type: roleGroupActions.SET_MY_SELECTED_ROLE_GROUP,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* createRoleGroup(actions) {
  try {
    const response = yield axios({
      url: '/users/user-role-groups',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: roleGroupActions.CREATE_ROLE_GROUP_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_REQUEST,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: 'SHOW_CREATE_ROLE_GROUP_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.CREATE_ROLE_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* updateRoleGroup(actions) {
  try {
    const response = yield axios({
      url: `/users/user-role-groups/${actions.roleGroupId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: roleGroupActions.UPDATE_ROLE_GROUP_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_ROLE_GROUP_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.UPDATE_ROLE_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* deleteRoleGroup(actions) {
  try {
    const response = yield axios({
      url: `/users/user-role-groups/${actions.roleGroupId}`,
      method: 'DELETE',
    });
    yield put({
      type: roleGroupActions.DELETE_ROLE_GROUP_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_REQUEST,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.DELETE_ROLE_GROUP_ERROR,
      error: error.data,
    });
  }
}

function* addRoleGroupApps(actions) {
  try {
    const response = yield axios({
      url: `/users/user-role-groups/apps/${actions.roleGroupId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: roleGroupActions.INSERT_ROLE_GROUP_APPS_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_REQUEST,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.INSERT_ROLE_GROUP_APPS_ERROR,
      error: error.data,
    });
  }
}

function* dropRoleGroupApps(actions) {
  try {
    const response = yield axios({
      url: `/users/user-role-groups/apps/${actions.roleGroupId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: roleGroupActions.DROP_ROLE_GROUP_APPS_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_REQUEST,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.DROP_ROLE_GROUP_APPS_ERROR,
      error: error.data,
    });
  }
}

function* addRoleGroupAdmins(actions) {
  try {
    const { roleGroupId, adminId } = actions;
    let url = `/users/user-role-groups/admins/${roleGroupId}`;
    let method = 'POST';

    if (adminId) {
      url = `/users/user-role-groups/admins/${roleGroupId}/${adminId}`;
      method = 'DELETE';
    }

    const response = yield axios({
      url,
      method,
      data: actions.data,
    });
    yield put({
      type: roleGroupActions.ADD_ROLE_GROUP_ADMIN_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_REQUEST,
    });
    yield put({
      type: 'SHOW_ADD_ROLE_GROUP_ADMIN_MODAL',
      payload: false,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.ADD_ROLE_GROUP_ADMIN_ERROR,
      error: error.data,
    });
  }
}

function* activateDeactivateUser(actions) {
  try {
    const response = yield axios({
      url: `/users/user-data/${actions.category}-users`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: roleGroupActions.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DEACTIVATE_USER_MODAL',
      payload: false,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: roleGroupActions.ACTIVATE_OR_DEACTIVATE_USER_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRoleGroups() {
  yield takeLatest(roleGroupActions.GET_ROLE_GROUP_REQUEST, getRoleGroups);
}

function* watchGetRoleGroupUsers() {
  yield takeEvery(
    roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
    getRoleGroupUsers
  );
}

function* watchGetSingleRoleGroups() {
  yield takeEvery(
    roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST,
    getSingleRoleGroup
  );
}

function* watchCreateRoleGroup() {
  yield takeLatest(roleGroupActions.CREATE_ROLE_GROUP_REQUEST, createRoleGroup);
}

function* watchUpdateRoleGroup() {
  yield takeLatest(roleGroupActions.UPDATE_ROLE_GROUP_REQUEST, updateRoleGroup);
}

function* watchDeleteRoleGroup() {
  yield takeLatest(roleGroupActions.DELETE_ROLE_GROUP_REQUEST, deleteRoleGroup);
}

function* watchAddRoleGroupApps() {
  yield takeLatest(
    roleGroupActions.INSERT_ROLE_GROUP_APPS_REQUEST,
    addRoleGroupApps
  );
}

function* watchDropRoleGroupApps() {
  yield takeLatest(
    roleGroupActions.DROP_ROLE_GROUP_APPS_REQUEST,
    dropRoleGroupApps
  );
}

function* watchAddRoleGroupAdmins() {
  yield takeLatest(
    roleGroupActions.ADD_ROLE_GROUP_ADMIN_REQUEST,
    addRoleGroupAdmins
  );
}

function* watchActivateDeactivateUser() {
  yield takeLatest(
    roleGroupActions.ACTIVATE_OR_DEACTIVATE_USER_REQUEST,
    activateDeactivateUser
  );
}

export default [
  fork(watchGetRoleGroups),
  fork(watchGetRoleGroupUsers),
  fork(watchUpdateRoleGroup),
  fork(watchDeleteRoleGroup),
  fork(watchCreateRoleGroup),
  fork(watchAddRoleGroupApps),
  fork(watchDropRoleGroupApps),
  fork(watchAddRoleGroupAdmins),
  fork(watchGetSingleRoleGroups),
  fork(watchActivateDeactivateUser),
];
