import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  previousRegistrationActions,
  studentRegistrationActions,
} from '../../actions';

function* downloadPreviousRegistration(actions) {
  try {
    yield axios({
      url: `/students-mgt/students-records/previous-enrollment-records-template`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PREVIOUS-REGISTRATION-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadPreviousRegistration(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students-records/upload-previous-enrollment-records-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* editMigratedEnrollment(actions) {
  try {
    const response = yield axios({
      url: `students-mgt/students-records/edit-previous-enrollment-record/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: previousRegistrationActions.EDIT_PREVIOUS_ENROLLMENT_RECORD_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_PREVIOUS_REGISTRATION_REQUEST,
      studentNo: actions.studentNumber,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: previousRegistrationActions.EDIT_PREVIOUS_ENROLLMENT_RECORD_ERROR,
      error: error.data,
    });
  }
}

function* deleteMigratedEnrollment(actions) {
  try {
    const response = yield axios({
      url: `students-mgt/students-records/delete-previous-enrollment-record/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: previousRegistrationActions.DELETE_PREVIOUS_ENROLLMENT_RECORD_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_PREVIOUS_REGISTRATION_REQUEST,
      studentNo: actions.studentNumber,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: previousRegistrationActions.DELETE_PREVIOUS_ENROLLMENT_RECORD_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadPreviousRegistration() {
  yield takeLatest(
    previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST,
    downloadPreviousRegistration
  );
}

function* watchUploadPreviousRegistration() {
  yield takeLatest(
    previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_REQUEST,
    uploadPreviousRegistration
  );
}

function* watchEditMigratedEnrollment() {
  yield takeLatest(
    previousRegistrationActions.EDIT_PREVIOUS_ENROLLMENT_RECORD_REQUEST,
    editMigratedEnrollment
  );
}

function* watchDeleteMigratedEnrollment() {
  yield takeLatest(
    previousRegistrationActions.DELETE_PREVIOUS_ENROLLMENT_RECORD_REQUEST,
    deleteMigratedEnrollment
  );
}

export default [
  fork(watchDownloadPreviousRegistration),
  fork(watchUploadPreviousRegistration),
  fork(watchEditMigratedEnrollment),
  fork(watchDeleteMigratedEnrollment),
];
