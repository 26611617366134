import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { debitNoteActions } from '../../actions';

function* getDebitNotes() {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/all-approved`,
      method: 'GET',
    });
    yield put({
      type: debitNoteActions.GET_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* getDebitNoteReport(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/credit-notes/report`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: debitNoteActions.GET_DEBIT_NOTE_REPORTS_SUCCESS,
      data: response.result,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_DEBIT_NOTE_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getPendingDebitNotes() {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/bulk-pending`,
      method: 'GET',
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* getStudentPendingDebitNotes(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/all-pending/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* createDebitNote(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: debitNoteActions.CREATE_DEBIT_NOTE_SUCCESS,
      data: response,
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.CREATE_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingDebitNote(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/debit-notes/approve',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: debitNoteActions.APPROVE_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.APPROVE_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* declinePendingDebitNote(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/debit-notes/decline',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: debitNoteActions.DECLINE_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.DECLINE_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* downloadDebitNoteReport(actions) {
  try {
    yield axios({
      url: `/registration/invoices/credit-notes/download-report`,
      method: 'GET',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${actions?.params?.student || ''} DEBIT-NOTES-REPORT.xlsx`
      );
    });
    yield put({
      type: debitNoteActions.DOWNLOAD_DEBIT_NOTE_REPORT_SUCCESS,
      data: {
        server: { message: 'Report downloaded!', status: true },
      },
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.DOWNLOAD_DEBIT_NOTE_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetDebitNotes() {
  yield takeLatest(debitNoteActions.GET_DEBIT_NOTE_REQUEST, getDebitNotes);
}

function* watchGetDebitNoteReport() {
  yield takeLatest(
    debitNoteActions.GET_DEBIT_NOTE_REPORTS_REQUEST,
    getDebitNoteReport
  );
}

function* watchGetPendingDebitNotes() {
  yield takeLatest(
    debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
    getPendingDebitNotes
  );
}

function* watchGetStudentPendingDebitNotes() {
  yield takeLatest(
    debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_REQUEST,
    getStudentPendingDebitNotes
  );
}

function* watchCreateDebitNote() {
  yield takeLatest(debitNoteActions.CREATE_DEBIT_NOTE_REQUEST, createDebitNote);
}

function* watchApprovePendingDebitNote() {
  yield takeLatest(
    debitNoteActions.APPROVE_DEBIT_NOTE_REQUEST,
    approvePendingDebitNote
  );
}
function* watchDeclinePendingDebitNote() {
  yield takeLatest(
    debitNoteActions.DECLINE_DEBIT_NOTE_REQUEST,
    declinePendingDebitNote
  );
}

function* watchDownloadDebitNoteReport() {
  yield takeLatest(
    debitNoteActions.DOWNLOAD_DEBIT_NOTE_REPORT_REQUEST,
    downloadDebitNoteReport
  );
}

export default [
  fork(watchGetDebitNotes),
  fork(watchGetPendingDebitNotes),
  fork(watchGetDebitNoteReport),
  fork(watchGetStudentPendingDebitNotes),
  fork(watchCreateDebitNote),
  fork(watchApprovePendingDebitNote),
  fork(watchDeclinePendingDebitNote),
  fork(watchDownloadDebitNoteReport),
];
