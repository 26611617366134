const senate = {
  graduationContext: {},

  graduatingStudents: false,
  graduatedStudents: [],
  graduateStudentsError: {},

  gettingGradList: false,
  senateGradList: [],
  getGradListError: {},

  showPrintModal: false,
  showPreviewModal: false,
};

export default senate;
