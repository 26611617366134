import update from 'immutability-helper';
import { searchResultsActions } from '../../actions';
import initialState from '../../initialState';

const resultsUserLogs = (
  state = initialState.searchedStudentResult,
  actions
) => {
  switch (actions.type) {
    case searchResultsActions.SEARCH_STUDENT_RESULTS_REQUEST:
      return {
        ...state,
        searchingResult: true,
        searchError: {},
      };
    case searchResultsActions.SEARCH_STUDENT_RESULTS_SUCCESS: {
      const { searchResults } = state;
      const { context, data } = actions;

      const findContextIndex = searchResults.findIndex(
        (currentContext) => context.student === currentContext.student
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          searchResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          searchResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        searchingResult: false,
      };
    }
    case searchResultsActions.SEARCH_STUDENT_RESULTS_ERROR:
      return {
        ...state,
        searchingResult: false,
        searchError: actions.error,
      };

    case searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_REQUEST:
      return {
        ...state,
        updatingResult: true,
        updateError: {},
      };

    case searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_SUCCESS:
      return {
        ...state,
        updatingResult: false,
        updateSuccess: actions.data,
      };
    case searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_ERROR:
      return {
        ...state,
        updatingResult: false,
        updateError: actions.data,
      };

    case searchResultsActions.COMPUTE_SEARCHED_RESULT_REQUEST:
      return {
        ...state,
        computingResults: true,
        computeError: {},
      };

    case searchResultsActions.COMPUTE_SEARCHED_RESULT_SUCCESS:
      return {
        ...state,
        computingResults: false,
        computeSuccess: actions.data,
      };
    case searchResultsActions.COMPUTE_SEARCHED_RESULT_ERROR:
      return {
        ...state,
        computingResults: false,
        computeError: actions.data,
      };

    case searchResultsActions.SET_CURRENT_STUDENT_SEARCHED:
      return {
        ...state,
        currentStudent: actions.data,
      };

    default:
      return state;
  }
};

export default resultsUserLogs;
