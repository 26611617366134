const courseResittingPolicyActions = {
  GET_COURSE_SITTING_POLICIES_REQUEST: 'GET_COURSE_RESITTING_POLICIES_REQUEST',
  GET_COURSE_SITTING_POLICIES_SUCCESS: 'GET_COURSE_RESITTING_POLICIES_SUCCESS',
  GET_COURSE_SITTING_POLICIES_ERROR: 'GET_COURSE_RESITTING_POLICIES_ERROR',

  CREATE_RESITTING_POLICY_REQUEST: 'CREATE_RESITTING_POLICY_REQUEST',
  CREATE_RESITTING_POLICY_SUCCESS: 'CREATE_RESITTING_POLICY_SUCCESS',
  CREATE_RESITTING_POLICY_ERROR: 'CREATE_RESITTING_POLICY_ERROR',

  UPDATE_RESITTING_POLICY_REQUEST: 'UPDATE_RESITTING_POLICY_REQUEST',
  UPDATE_RESITTING_POLICY_SUCCESS: 'UPDATE_RESITTING_POLICY_SUCCESS',
  UPDATE_RESITTING_POLICY_ERROR: 'UPDATE_RESITTING_POLICY_ERROR',

  DELETE_RESITTING_POLICY_REQUEST: 'DELETE_RESITTING_POLICY_REQUEST',
  DELETE_RESITTING_POLICY_SUCCESS: 'DELETE_RESITTING_POLICY_SUCCESS',
  DELETE_RESITTING_POLICY_ERROR: 'DELETE_RESITTING_POLICY_ERROR',

  SET_RESITTING_POLICY_TO_EDIT: 'SET_RESITTING_POLICY_TO_EDIT',

  getCourseResittingPolicies: () => ({
    type: courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_REQUEST,
  }),

  createPolicy: (data) => ({
    type: courseResittingPolicyActions.CREATE_RESITTING_POLICY_REQUEST,
    data,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: courseResittingPolicyActions.SET_RESITTING_POLICY_TO_EDIT,
    policyToEdit,
  }),

  updatePolicy: (policyId, data) => ({
    type: courseResittingPolicyActions.UPDATE_RESITTING_POLICY_REQUEST,
    policyId,
    data,
  }),

  deletePolicy: (policyId) => ({
    type: courseResittingPolicyActions.DELETE_RESITTING_POLICY_REQUEST,
    policyId,
  }),
};

export default courseResittingPolicyActions;
