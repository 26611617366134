import { revenueReportActions } from '../../actions';
import initialState from '../../initialState';

const revenueReport = (state = initialState.revenueReport, actions) => {
  switch (actions.type) {
    case revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST:
      return {
        ...state,
        gettingYearReport: true,
        yearReportError: {},
      };
    case revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS: {
      let { academicYearReport } = state;
      const { context } = actions;
      const findIndex = academicYearReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        academicYearReport = [
          ...academicYearReport,
          { ...context, data: actions.data },
        ];
      } else {
        academicYearReport[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        academicYearReport,
        gettingYearReport: false,
      };
    }
    case revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR:
      return {
        ...state,
        yearReportError: actions.error,
        gettingYearReport: false,
      };

    case revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST:
      return {
        ...state,
        gettingUnitReport: true,
        unitReportError: {},
      };
    case revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS: {
      let { academicUnitReport } = state;
      const { context } = actions;
      const findIndex = academicUnitReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        academicUnitReport = [
          ...academicUnitReport,
          { ...context, data: actions.data },
        ];
      } else {
        academicUnitReport[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        academicUnitReport,
        gettingUnitReport: false,
      };
    }
    case revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR:
      return {
        ...state,
        unitReportError: actions.error,
        gettingUnitReport: false,
      };

    case revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST:
      return {
        ...state,
        gettingUnitDetailsReport: true,
        unitDetailsReportError: {},
      };
    case revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS: {
      let { academicUnitDetailsReport } = state;
      const { context } = actions;
      const findIndex = academicUnitDetailsReport.findIndex(
        (report) =>
          report.academic_year_id === context.academic_year_id &&
          report.academic_unit_id === context.academic_unit_id
      );

      if (findIndex === -1) {
        academicUnitDetailsReport = [
          ...academicUnitDetailsReport,
          { ...context, data: actions.data },
        ];
      } else {
        academicUnitDetailsReport[findIndex] = {
          ...context,
          data: actions.data,
        };
      }

      return {
        ...state,
        academicUnitDetailsReport,
        gettingUnitDetailsReport: false,
      };
    }
    case revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR:
      return {
        ...state,
        unitDetailsReportError: actions.error,
        gettingUnitDetailsReport: false,
      };

    case revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
      };
    case revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS:
      return {
        ...state,
        downloadedReport: actions.data,
        downloadingReport: false,
      };
    case revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloadingReport: false,
      };

    default:
      return state;
  }
};

export default revenueReport;
