import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { chartOfAccountActions } from '../../actions';

function* getChartOfAccountData(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/chart-of-accounts',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: chartOfAccountActions.GET_CHART_OF_ACCOUNTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.GET_CHART_OF_ACCOUNTS_ERROR,
      error: error.data,
    });
  }
}

function* createChartOfAccount(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/chart-of-accounts',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: chartOfAccountActions.CREATE_CHART_OF_ACCOUNT_SUCCESS,
      data: response,
    });
    yield put({
      type: chartOfAccountActions.GET_CHART_OF_ACCOUNTS_REQUEST,
    });
    yield put({
      type: chartOfAccountActions.SHOW_CREATE_CHART_OF_ACCOUNT_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.CREATE_CHART_OF_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* getSingleChartOfAccount(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/chart-of-accounts/${actions.chartOfAccountId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: chartOfAccountActions.GET_SINGLE_CHART_OF_ACCOUNT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.GET_SINGLE_CHART_OF_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* updateChartOfAccount(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/chart-of-accounts/${actions.chartOfAccountId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: chartOfAccountActions.UPDATE_CHART_OF_ACCOUNT_SUCCESS,
      data: response,
    });
    yield put({
      type: chartOfAccountActions.GET_CHART_OF_ACCOUNTS_REQUEST,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.UPDATE_CHART_OF_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* deleteChartOfAccount(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/chart-of-accounts/${actions.chartOfAccountId}`,
      method: 'DELETE',
    });
    yield put({
      type: chartOfAccountActions.DELETE_CHART_OF_ACCOUNT_SUCCESS,
      data: response,
    });
    yield put({
      type: chartOfAccountActions.GET_CHART_OF_ACCOUNTS_REQUEST,
    });
    yield put({
      type: chartOfAccountActions.SHOW_DELETE_CHART_OF_ACCOUNT_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.DELETE_CHART_OF_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* downloadChartTemplate() {
  try {
    yield axios({
      url: '/universal-payments/chart-of-accounts/download-upload-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ACCOUNTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: chartOfAccountActions.DOWNLOAD_CHART_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.DOWNLOAD_CHART_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadChartTemplate(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/chart-of-accounts/upload-accounts',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: chartOfAccountActions.UPLOAD_CHART_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_UPLOAD_MODAL', payload: false });
    yield put({
      type: chartOfAccountActions.GET_CHART_OF_ACCOUNTS_REQUEST,
    });
    yield put({
      type: chartOfAccountActions.SET_CURRENT_CHART_OF_ACCOUNT_TAB,
      currentTab: 'home',
    });
  } catch (error) {
    yield put({
      type: chartOfAccountActions.UPLOAD_CHART_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetChartOfAccountData() {
  yield takeLatest(
    chartOfAccountActions.GET_CHART_OF_ACCOUNTS_REQUEST,
    getChartOfAccountData
  );
}

function* watchCreateChartOfAccount() {
  yield takeLatest(
    chartOfAccountActions.CREATE_CHART_OF_ACCOUNT_REQUEST,
    createChartOfAccount
  );
}

function* watchGetSingleChartOfAccount() {
  yield takeLatest(
    chartOfAccountActions.GET_SINGLE_CHART_OF_ACCOUNT_REQUEST,
    getSingleChartOfAccount
  );
}

function* watchUpdateChartOfAccount() {
  yield takeLatest(
    chartOfAccountActions.UPDATE_CHART_OF_ACCOUNT_REQUEST,
    updateChartOfAccount
  );
}

function* watchDeleteChartOfAccount() {
  yield takeLatest(
    chartOfAccountActions.DELETE_CHART_OF_ACCOUNT_REQUEST,
    deleteChartOfAccount
  );
}

function* watchDownloadChartTemplate() {
  yield takeLatest(
    chartOfAccountActions.DOWNLOAD_CHART_TEMPLATE_REQUEST,
    downloadChartTemplate
  );
}

function* watchUploadChartTemplate() {
  yield takeLatest(
    chartOfAccountActions.UPLOAD_CHART_TEMPLATE_REQUEST,
    uploadChartTemplate
  );
}

export default [
  fork(watchCreateChartOfAccount),
  fork(watchGetChartOfAccountData),
  fork(watchUpdateChartOfAccount),
  fork(watchGetSingleChartOfAccount),
  fork(watchDeleteChartOfAccount),
  fork(watchDownloadChartTemplate),
  fork(watchUploadChartTemplate),
];
