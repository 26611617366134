import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { NTCResultActions } from '../../actions';

function* getNTCResults(actions) {
  try {
    const response = yield axios({
      url: '/ntc-mgt/ntc-results',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: NTCResultActions.GET_NTC_RESULTS_SUCCESS,
      results: response.results,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.GET_NTC_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getNTCProgrammes(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/ntc-mgt/ntc-results/programmes',
      method: 'GET',
      params: context,
    });
    yield put({
      type: NTCResultActions.GET_NTC_PROGRAMMES_SUCCESS,
      programmes: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.GET_NTC_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getNTCTermlyResults(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/ntc-mgt/ntc-results/termly',
      method: 'GET',
      params: context,
    });
    yield put({
      type: NTCResultActions.GET_NTC_TERMLY_RESULTS_SUCCESS,
      results: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.GET_NTC_TERMLY_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getNTCStudentsDocuments(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/ntc-mgt/ntc-students/student-documents',
      method: 'GET',
      params: context,
    });
    yield put({
      type: NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* generateNTCStudentsDocuments(actions) {
  try {
    const { context, studentIDs, actionType } = actions;
    const response = yield axios({
      url: `/ntc-mgt/ntc-students/generate-documents/${actionType}`,
      method: 'POST',
      data: studentIDs,
    });
    yield put({
      type: NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_ERROR,
      data: response.data,
      context,
    });
    yield put({
      type: NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_REQUEST,
      context,
    });
    yield put({
      type: NTCResultActions.SHOW_NTC_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_SUCCESS,
      error: error.data,
    });
  }
}

function* downloadNTCStudentsDocuments(actions) {
  try {
    const { context, studentIDs, actionType } = actions;
    const response = yield axios({
      url: `/ntc-mgt/ntc-students/download-documents/${actionType}`,
      method: 'POST',
      data: { studentIDs },
    });
    yield put({
      type: NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_SUCCESS,
      data: response.data,
      context,
    });
    yield put({
      type: NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_REQUEST,
      context,
    });
    yield put({
      type: NTCResultActions.SHOW_NTC_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* createNTCResult(actions) {
  try {
    const response = yield axios({
      url: 'ntc-mgt/ntc-results',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: NTCResultActions.CREATE_NTC_RESULT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: NTCResultActions.GET_NTC_RESULTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.CREATE_NTC_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* downloadNTCResultsTemplate() {
  try {
    yield axios({
      url: `/ntc-mgt/ntc-results/download-template`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-NTC-RESULTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadNTCResultsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/ntc-results/upload-template`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_UPLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* deleteNTCResult(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/results/${actions.resultId}`,
      method: 'DELETE',
    });
    yield put({
      type: NTCResultActions.DELETE_NTC_RESULTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: NTCResultActions.GET_NTC_RESULTS_REQUEST,
      params: actions.params,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: NTCResultActions.DELETE_NTC_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* updateNTCResult(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/results/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: NTCResultActions.UPDATE_NTC_RESULT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: NTCResultActions.UPDATE_NTC_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetNTCResults() {
  yield takeLatest(NTCResultActions.GET_NTC_RESULTS_REQUEST, getNTCResults);
}

function* watchGetNTCTermlyResults() {
  yield takeLatest(
    NTCResultActions.GET_NTC_TERMLY_RESULTS_REQUEST,
    getNTCTermlyResults
  );
}

function* watchGetNTCStudentsDocuments() {
  yield takeLatest(
    NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_REQUEST,
    getNTCStudentsDocuments
  );
}

function* watchGenerateNTCStudentsDocuments() {
  yield takeLatest(
    NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_REQUEST,
    generateNTCStudentsDocuments
  );
}

function* watchDownloadNTCStudentsDocuments() {
  yield takeLatest(
    NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_REQUEST,
    downloadNTCStudentsDocuments
  );
}

function* watchGetNTCProgrammes() {
  yield takeLatest(
    NTCResultActions.GET_NTC_PROGRAMMES_REQUEST,
    getNTCProgrammes
  );
}

function* watchCreateNTCResult() {
  yield takeLatest(NTCResultActions.CREATE_NTC_RESULT_REQUEST, createNTCResult);
}

function* watchDownloadNTCResultsTemplate() {
  yield takeLatest(
    NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_REQUEST,
    downloadNTCResultsTemplate
  );
}

function* watchUploadNTCResultsTemplate() {
  yield takeLatest(
    NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_REQUEST,
    uploadNTCResultsTemplate
  );
}

function* watchDeleteNTCResult() {
  yield takeLatest(
    NTCResultActions.DELETE_NTC_RESULTS_REQUEST,
    deleteNTCResult
  );
}

function* watchUpdateNTCResult() {
  yield takeLatest(NTCResultActions.UPDATE_NTC_RESULT_REQUEST, updateNTCResult);
}

export default [
  fork(watchGetNTCResults),
  fork(watchGetNTCProgrammes),
  fork(watchGetNTCTermlyResults),
  fork(watchCreateNTCResult),
  fork(watchDownloadNTCResultsTemplate),
  fork(watchUploadNTCResultsTemplate),
  fork(watchDeleteNTCResult),
  fork(watchGetNTCStudentsDocuments),
  fork(watchGenerateNTCStudentsDocuments),
  fork(watchDownloadNTCStudentsDocuments),
  fork(watchUpdateNTCResult),
];
