import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { bulkPaymentsActions } from '../../actions';

function* getPendingBulkPayments() {
  try {
    const response = yield axios({
      url: `/universal-payments/bulk-payments/pending-bulk-payments`,
      method: 'GET',
    });
    yield put({
      type: bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* acknowledgeBulkPayments(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/bulk-payments/generate-prn/${actions.paymentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: bulkPaymentsActions.ACKNOWLEDGE_BULK_PAYMENTS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_MODAL', payload: true });
    yield put({
      type: bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: bulkPaymentsActions.ACKNOWLEDGE_BULK_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getAcknowledgedBulkPayments() {
  try {
    const response = yield axios({
      url: `/universal-payments/bulk-payments/acknowledged-bulk-payments`,
      method: 'GET',
    });
    yield put({
      type: bulkPaymentsActions.GET_ACKNOWLEDGED_PAYMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: bulkPaymentsActions.GET_ACKNOWLEDGED_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPendingBulkPayments() {
  yield takeLatest(
    bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_REQUEST,
    getPendingBulkPayments
  );
}

function* watchAcknowledgeBulkPayments() {
  yield takeLatest(
    bulkPaymentsActions.ACKNOWLEDGE_BULK_PAYMENTS_REQUEST,
    acknowledgeBulkPayments
  );
}

function* watchGetAcknowledgedBulkPayments() {
  yield takeLatest(
    bulkPaymentsActions.GET_ACKNOWLEDGED_PAYMENTS_REQUEST,
    getAcknowledgedBulkPayments
  );
}

export default [
  fork(watchGetPendingBulkPayments),
  fork(watchAcknowledgeBulkPayments),
  fork(watchGetAcknowledgedBulkPayments),
];
