import { isEmpty } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

function InputText({
  label,
  error,
  register,
  name,
  type,
  rows,
  handleChange,
  placeholder,
  className,
  selectOptions,
  selectType,
  selectHandler,
  isMulti,
  isSearchable,
  inline,
  autoComplete,
  requiredField,
  ...props
}) {
  const renderSelect = (selector) => {
    return selector === 'creatable' ? (
      <CreatableSelect
        options={selectOptions}
        ref={register}
        placeholder={placeholder}
        isInvalid={!!error}
        isClearable
        isMulti={isMulti}
        isSearchable={isSearchable}
        onChange={selectHandler}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          padding: '2px',
          fontSize: '10px',
        })}
        {...props}
      />
    ) : (
      <Select
        options={selectOptions}
        ref={register}
        placeholder={placeholder}
        isInvalid={!!error}
        isClearable
        isSearchable={isSearchable}
        isMulti={isMulti}
        onChange={selectHandler}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          padding: '2px',
          fontSize: '10px',
        })}
        {...props}
      />
    );
  };

  const setClassName = () => {
    let newClassName = `text-muted ${className}`;
    if (error) newClassName = `text-muted ${className} is-invalid`;
    return newClassName;
  };

  return (
    <Form.Group
      className={inline ? 'row my-2' : 'form-group my-2'}
      controlId={name}
    >
      {label && (
        <Form.Label
          className={`fw-bold text-sm ${inline ? 'col-md-4' : ''}`}
          style={{ textAlign: 'left !important' }}
        >
          {label}
          {requiredField && <strong className="text-danger ms-1">*</strong>}
        </Form.Label>
      )}
      <div className={inline ? 'col-md-8' : ''}>
        {(type === 'select' && renderSelect(selectType)) ||
          (type === 'textarea' && (
            <Form.Control
              as="textarea"
              name={name}
              rows={rows}
              ref={register}
              size="sm"
              onChange={handleChange}
              className={setClassName()}
              placeholder={placeholder}
              {...props}
            />
          )) ||
          (type === 'tel' && (
            <Controller
              as={PhoneInput}
              country="ug"
              className="rounded-0"
              inputProps={{
                className: `form-control rounded-0 form-control-sm ${setClassName()}`,
                name,
              }}
              name={name}
              enableSearch
              {...props}
            />
          )) ||
          (type === 'amount' && (
            <Controller
              placeholder={placeholder}
              thousandSeparator
              as={NumberFormat}
              className={`form-control form-control-sm ${setClassName()}`}
              name={name}
              {...props}
            />
          )) || (
            <Form.Control
              type={type}
              name={name}
              size="sm"
              ref={register}
              onChange={handleChange}
              className={setClassName()}
              autoComplete={autoComplete}
              placeholder={placeholder}
              {...props}
            />
          )}
        {!isEmpty(error) && (
          <div
            className={
              type === 'select' ? 'mt-1' : 'invalid-feedback text-sm mt-1'
            }
            style={type === 'select' ? { color: '#ff3838' } : {}}
          >
            {error}
          </div>
        )}
      </div>
    </Form.Group>
  );
}

InputText.defaultProps = {
  type: 'text',
  label: null,
  error: null,
  handleChange: null,
  autoComplete: 'off',
  placeholder: null,
  className: null,
  isSearchable: true,
  isMulti: false,
  selectHandler: null,
  selectType: null,
  selectOptions: [],
  inline: false,
  register: null,
  name: '',
  rows: 3,
  requiredField: false,
};

InputText.propTypes = {
  type: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectOptions: PropTypes.oneOfType([array]),
  selectType: PropTypes.string,
  selectHandler: PropTypes.func,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  inline: PropTypes.bool,
  autoComplete: PropTypes.string,
  rows: PropTypes.number,
  requiredField: PropTypes.bool,
};

export default InputText;
