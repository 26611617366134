import { findIndex } from 'lodash';
import update from 'immutability-helper';
import { userLogsActions } from '../../actions';
import initialState from '../../initialState';

const userLogs = (state = initialState.auditTrails, actions) => {
  switch (actions.type) {
    case userLogsActions.GET_USER_LOGS_REQUEST:
      return {
        ...state,
        gettingUserLogs: true,
        getUserLogsError: {},
      };

    case userLogsActions.GET_USER_LOGS_SUCCESS:
      return {
        ...state,
        gettingUserLogs: false,
        userLogs: actions.data,
      };

    case userLogsActions.GET_USER_LOGS_ERROR:
      return {
        ...state,
        gettingUserLogs: false,
        getUserLogsError: actions.data,
      };

    case userLogsActions.GET_APPLICATION_LOGS_REQUEST:
      return {
        ...state,
        gettingAppLogs: true,
        getAppLogsError: {},
      };

    case userLogsActions.GET_APPLICATION_LOGS_SUCCESS: {
      const { context, data } = actions;
      const { applicationLogs } = state;

      const findContextIndex = findIndex(
        applicationLogs,
        (item) => item.applicationCode === context.app_code
      );

      const contextData = { applicationCode: context.app_code, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          applicationLogs: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          applicationLogs: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingAppLogs: false,
      };
    }

    case userLogsActions.GET_APPLICATION_LOGS_ERROR:
      return {
        ...state,
        gettingAppLogs: false,
        getAppLogsError: actions.data,
      };

    default:
      return state;
  }
};

export default userLogs;
