import update from 'immutability-helper';
import { NTCStudentActions } from '../../actions';
import initialState from '../../initialState';

const NTCStudent = (state = initialState.NTCStudent, actions) => {
  switch (actions.type) {
    case NTCStudentActions.GET_NTC_STUDENTS_REQUEST:
      return {
        ...state,
        getNTCStudentsError: {},
        gettingNTCStudents: true,
        NTCStudentContext: actions.params,
      };
    case NTCStudentActions.GET_NTC_STUDENTS_SUCCESS: {
      const { NTCStudents } = state;
      const { context, students } = actions;

      const findContextIndex = NTCStudents.findIndex(
        (currentContext) =>
          parseInt(context.current_study_year_id, 10) ===
          parseInt(currentContext.current_study_year_id, 10)
      );

      const contextData = { ...context, students };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          NTCStudents: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          NTCStudents: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingNTCStudents: false,
      };
    }
    case NTCStudentActions.GET_NTC_STUDENTS_ERROR:
      return {
        ...state,
        getNTCStudentsError: actions.error,
        gettingNTCStudents: false,
      };

    case NTCStudentActions.CREATE_NTC_STUDENT_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case NTCStudentActions.CREATE_NTC_STUDENT_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case NTCStudentActions.CREATE_NTC_STUDENT_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case NTCStudentActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case NTCStudentActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case NTCStudentActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case NTCStudentActions.UPLOAD_NTC_STUDENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case NTCStudentActions.UPLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case NTCStudentActions.UPLOAD_NTC_STUDENTS_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case NTCStudentActions.DELETE_NTC_STUDENTS_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteError: {},
      };
    case NTCStudentActions.DELETE_NTC_STUDENTS_SUCCESS:
      return {
        ...state,
        deleteSuccess: actions.data,
        deleting: false,
      };
    case NTCStudentActions.DELETE_NTC_STUDENTS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case NTCStudentActions.UPDATE_NTC_STUDENT_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case NTCStudentActions.UPDATE_NTC_STUDENT_SUCCESS:
      return {
        ...state,
        updateSuccess: actions.data,
        updating: false,
      };
    case NTCStudentActions.UPDATE_NTC_STUDENT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    default:
      return state;
  }
};

export default NTCStudent;
