import { isEmpty } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';

function SelectInput({
  label,
  name,
  register,
  selectOptions,
  className,
  error,
  placeholder,
  inline,
  requiredField,
  labelClassName,
  ...props
}) {
  const getClassName = () => {
    let newClassName = `form-control custom-select text-muted w-100 ${className}`;
    if (error)
      newClassName = `form-control custom-select text-muted w-100 ${className} is-invalid`;
    return newClassName;
  };

  return (
    <Form.Group
      controlId={name}
      className={`my-2 ${inline ? 'row my-0' : 'form-group'}`}
    >
      {label && (
        <Form.Label
          className={`fw-bold text-sm ${labelClassName} ${
            inline ? 'col-md-4 my-auto' : ''
          }`}
          style={{ textAlign: 'left !important' }}
        >
          {label}
          {requiredField && <strong className="text-danger ms-1">*</strong>}
        </Form.Label>
      )}

      <div className={inline ? 'col-md-8' : ''}>
        <Form.Select
          name={name}
          className={getClassName()}
          ref={register}
          placeholder={placeholder}
          size="sm"
          {...props}
        >
          <option value="">{placeholder}</option>
          {selectOptions.map((option, index) => (
            <Fragment key={option.value || index + 1}>
              {option && <option value={option.value}>{option.label}</option>}
            </Fragment>
          ))}
        </Form.Select>
        {!isEmpty(error) && <div className="invalid-feedback">{error}</div>}
      </div>
    </Form.Group>
  );
}

SelectInput.defaultProps = {
  selectOptions: [],
  placeholder: 'Select ----',
  label: null,
  register: null,
  className: null,
  name: null,
  error: null,
  inline: false,
  requiredField: null,
  labelClassName: null,
};

SelectInput.propTypes = {
  selectOptions: PropTypes.oneOfType([array]),
  label: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  inline: PropTypes.bool,
  requiredField: PropTypes.bool,
};

export default SelectInput;
