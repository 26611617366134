import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { functionalFeesActions, tabActions } from '../../actions';

function* getFunctionalFeesAmounts(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/functional-fees-amounts/${actions.category}`,
      method: 'POST',
      data: actions.context,
    });
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_SUCCESS,
      data: response,
      id: actions.id,
      category: actions.category,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* getPendingFunctionalFeesAmounts(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-approvals?fees_category=functionalFees`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* addFunctionalFeesElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/functional-fees-amounts/add-element-amounts/${actions.contextId}`,
      method: 'POST',
      data: { functionalFeesAmountFeesElements: actions.data },
    });
    yield put({
      type: functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST,
      context: actions.currentContext,
      category: 'functional-fees-elements-amounts',
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getFunctionalFeesElements(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/functional-fees-amounts/functional-fees-elements',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* createFunctionalFeesAmounts(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/functional-fees-amounts/${actions.category}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: functionalFeesActions.CREATE_FUNCTIONAL_FEES_SUCCESS,
      data: response,
    });

    yield put({
      type: tabActions.FUNCTIONAL_FEES_TAB,
      activeTab: 'unique-functional-fees',
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.CREATE_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* deleteFunctionalFeesAmount(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/functional-fees-amounts/delete-element-amount/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: functionalFeesActions.DELETE_FUNCTIONAL_FEES_SUCCESS,
      data: response,
    });
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST,
      context: actions.currentContext,
      category: 'functional-fees-elements-amounts',
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.DELETE_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}
function* updateFunctionalFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/functional-fees-amounts/update-element-amount/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: functionalFeesActions.UPDATE_FUNCTIONAL_FEES_SUCCESS,
      data: response,
    });
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST,
      context: actions.currentContext,
      category: 'functional-fees-elements-amounts',
      id: actions.id,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.UPDATE_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* updateUniqueFunctionalFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/functional-fees-amounts/uniq-update`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_SUCCESS,
      data: response,
    });
    yield put({
      type: functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST,
      context: actions.currentContext,
      category: 'uniq-fees-amounts',
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* downloadFunctionalFeesTemplate() {
  try {
    yield axios({
      url: '/fees-mgt/functional-fees-amounts/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-FUNCTIONAL-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* uploadFunctionalFees(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/functional-fees-amounts/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: functionalFeesActions.UPLOAD_FUNCTIONAL_FEES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_UPLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: functionalFeesActions.UPLOAD_FUNCTIONAL_FEES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFunctionalFeesAmounts() {
  yield takeLatest(
    functionalFeesActions.GET_FUNCTIONAL_FEES_REQUEST,
    getFunctionalFeesAmounts
  );
}

function* watchGetPendingFunctionalFeesAmounts() {
  yield takeLatest(
    functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_REQUEST,
    getPendingFunctionalFeesAmounts
  );
}

function* watchAddFunctionalFeesElements() {
  yield takeLatest(
    functionalFeesActions.ADD_FUNCTIONAL_FEES_ELEMENTS_REQUEST,
    addFunctionalFeesElements
  );
}

function* watchGetFunctionalFeesElements() {
  yield takeLatest(
    functionalFeesActions.GET_FUNCTIONAL_FEES_ELEMENTS_REQUEST,
    getFunctionalFeesElements
  );
}

function* watchCreateFunctionalFeesAmounts() {
  yield takeLatest(
    functionalFeesActions.CREATE_FUNCTIONAL_FEES_REQUEST,
    createFunctionalFeesAmounts
  );
}
function* watchUpdateFunctionalFeesAmounts() {
  yield takeLatest(
    functionalFeesActions.UPDATE_FUNCTIONAL_FEES_REQUEST,
    updateFunctionalFees
  );
}
function* watchUpdateUniqueFunctionalFeesAmounts() {
  yield takeLatest(
    functionalFeesActions.UPDATE_UNIQUE_FUNCTIONAL_FEES_REQUEST,
    updateUniqueFunctionalFees
  );
}
function* watchDeleteFunctionalFeesAmounts() {
  yield takeLatest(
    functionalFeesActions.DELETE_FUNCTIONAL_FEES_REQUEST,
    deleteFunctionalFeesAmount
  );
}
function* watchDownloadFunctionalFeesTemplate() {
  yield takeLatest(
    functionalFeesActions.DOWNLOAD_FUNCTIONAL_FEES_REQUEST,
    downloadFunctionalFeesTemplate
  );
}
function* watchUploadFunctionalFeesTemplate() {
  yield takeLatest(
    functionalFeesActions.UPLOAD_FUNCTIONAL_FEES_REQUEST,
    uploadFunctionalFees
  );
}

export default [
  fork(watchGetFunctionalFeesAmounts),
  fork(watchGetPendingFunctionalFeesAmounts),
  fork(watchGetFunctionalFeesElements),
  fork(watchCreateFunctionalFeesAmounts),
  fork(watchAddFunctionalFeesElements),
  fork(watchDeleteFunctionalFeesAmounts),
  fork(watchUpdateFunctionalFeesAmounts),
  fork(watchDownloadFunctionalFeesTemplate),
  fork(watchUploadFunctionalFeesTemplate),
  fork(watchUpdateUniqueFunctionalFeesAmounts),
];
