import { CodeOutlined, LogoutOutlined } from '@ant-design/icons';
import { Alert, Image, Space, message } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneIcon from '../../assets/images/undraw_confirmed_re_sef7.svg';
import usePrevious from '../../components/Hooks/usePrevious';
import { SubmitButton } from '../../components/common';
import { authActions } from '../../config/actions';

function PhoneVerificationRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authUser, requestingOTP, loggingOut, verifyingPhone, logoutData } =
    useSelector((state) => state.auth);

  const [code, setCode] = useState('');
  const { serverSuccess, serverError } = useSelector((state) => state.server);
  const prevState = usePrevious({ serverSuccess, serverError });

  useEffect(() => {
    if (isEmpty(authUser)) navigate('/');
  }, []);

  useEffect(() => {
    if (
      !isEmpty(logoutData) &&
      isEmpty(authUser) &&
      logoutData.server.status === true
    ) {
      navigate('/');
    }
    if (authUser?.phone_number_verified === true) navigate('/');
  }, [logoutData, authUser]);

  useEffect(() => {
    if (!isEmpty(prevState)) {
      if (!isEmpty(serverError) && prevState.serverError !== serverError) {
        message.destroy();
        message.error(
          serverError?.error?.message ||
            serverError?.error ||
            serverError?.server?.message,
          10
        );
      }
      if (
        !isEmpty(serverSuccess) &&
        prevState.serverSuccess !== serverSuccess
      ) {
        message.destroy();
        message.success(serverSuccess?.server?.message, 10);
      }
    }
  }, [serverError, serverSuccess]);

  const resendVerificationLink = () => {
    dispatch(authActions.sendPhoneVerificationOTP());
  };

  const logoutUser = () => {
    dispatch(authActions.logoutUser());
  };

  const verifyPhone = () => dispatch(authActions.verifyPhone({ code }));

  const otpLength = 6;

  return (
    <div className="d-flex min-vh-100">
      <Helmet>
        <title>Phone Number Verification</title>
      </Helmet>
      <div className="h-100 my-auto p-4 w-100 text-sm">
        <Row className="justify-content-center">
          <Col md={4}>
            <div className="text-center">
              <Image
                height={80}
                size="large"
                preview={false}
                draggable={false}
                src={PhoneIcon}
              />
              <h6 className="my-3 fw-bold text-uppercase text-primary">
                Phone Number Verification
              </h6>

              <Alert
                type="error"
                className="mb-3 text-uppercase text-sm bordered"
                message={
                  <div className="fw-bold fs-4 text-danger">
                    ACTION REQUIRED!
                  </div>
                }
                description={`Verify your Telephone Number(${authUser.phone}) before you can proceed to use the system`}
              />

              <OtpInput
                value={code}
                onChange={setCode}
                numInputs={otpLength}
                inputStyle="otpInput"
                isInputNum
                shouldAutoFocus
                containerStyle="my-4 justify-content-center"
              />

              <SubmitButton
                loading={verifyingPhone}
                text="Verify Phone OTP"
                loadingText="Verifying Phone..."
                block
                danger
                size="large"
                onClick={verifyPhone}
                disabled={verifyingPhone || otpLength !== code?.length}
              />

              <div className="mt-4">
                <Space>
                  <SubmitButton
                    loading={requestingOTP}
                    htmlType="button"
                    onClick={resendVerificationLink}
                    text="REQUEST NEW OTP CODE"
                    type="link"
                    loadingText="Sending OTP..."
                    iconBefore={<CodeOutlined />}
                  />
                  <SubmitButton
                    htmlType="button"
                    danger
                    type="link"
                    onClick={logoutUser}
                    loading={loggingOut}
                    iconBefore={<LogoutOutlined />}
                    text="Log me out"
                    loadingText="Logging out..."
                  />
                </Space>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default PhoneVerificationRequest;
