import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { revenueReportActions } from '../../actions';

function* getAcadYrCollectionsReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/trans`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getAcadUnitCollectionsReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/trans-unit`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS,
      data: response.result,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getAcadUnitCollectionDetails(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/academic-years-billing-details`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS,
      data: response.result,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* downloadAcadUnitCollectionDetails(actions) {
  try {
    yield axios({
      url: `/bi/admission-reports/download-billing-details`,
      method: 'POST',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-COLLECTION-DETAILS-REPORT.xlsx`
      );
    });
    yield put({
      type: revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetAcadYrCollectionsReport() {
  yield takeLatest(
    revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST,
    getAcadYrCollectionsReport
  );
}

function* watchGetAcadUnitCollectionsReport() {
  yield takeLatest(
    revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST,
    getAcadUnitCollectionsReport
  );
}

function* watchGetAcadUnitCollectionDetails() {
  yield takeLatest(
    revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST,
    getAcadUnitCollectionDetails
  );
}

function* watchDownloadAcadUnitCollectionDetails() {
  yield takeLatest(
    revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST,
    downloadAcadUnitCollectionDetails
  );
}

export default [
  fork(watchGetAcadYrCollectionsReport),
  fork(watchGetAcadUnitCollectionsReport),
  fork(watchGetAcadUnitCollectionDetails),
  fork(watchDownloadAcadUnitCollectionDetails),
];
