import React from 'react';
import PropTypes from 'prop-types';
import { Card, Placeholder } from 'react-bootstrap';
import { times } from 'lodash';

function CardPlaceholder({ rows, ...props }) {
  return (
    <Card body className="bg-white">
      {times(rows).map((number) => (
        <Placeholder
          animation="wave"
          as={Card.Title}
          bg="secondary"
          size="lg"
          {...props}
          className="mb-3"
          key={number}
        >
          <Placeholder xs={12} />
        </Placeholder>
      ))}
    </Card>
  );
}

CardPlaceholder.defaultProps = {
  rows: 1,
};

CardPlaceholder.propTypes = {
  rows: PropTypes.number,
};

export default CardPlaceholder;
