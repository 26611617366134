const programmeSearch = {
  SEARCH_BY_PROG_CODE_REQUEST: 'SEARCH_BY_PROG_CODE_REQUEST',
  SEARCH_BY_PROG_CODE_SUCCESS: 'SEARCH_BY_PROG_CODE_SUCCESS',
  SEARCH_BY_PROG_CODE_ERROR: 'SEARCH_BY_PROG_CODE_ERROR',

  SEARCH_BY_COURSE_CODE_REQUEST: 'SEARCH_BY_COURSE_CODE_REQUEST',
  SEARCH_BY_COURSE_CODE_SUCCESS: 'SEARCH_BY_COURSE_CODE_SUCCESS',
  SEARCH_BY_COURSE_CODE_ERROR: 'SEARCH_BY_COURSE_CODE_ERROR',

  SET_PROG_SEARCH_PARAMETER: 'SET_PROG_SEARCH_PARAMETER',

  searchByProgCode: (data) => ({
    type: programmeSearch.SEARCH_BY_PROG_CODE_REQUEST,
    data,
  }),

  searchByCourseCode: (data) => ({
    type: programmeSearch.SEARCH_BY_COURSE_CODE_REQUEST,
    data,
  }),

  setProgSearchCode: (data) => {
    return {
      type: programmeSearch.SET_PROG_SEARCH_PARAMETER,
      data,
    };
  },
};

export default programmeSearch;
