import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { emisStudentActions } from '../../actions';

function* getEmisStudents(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/acmis-students`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: emisStudentActions.GET_EMIS_STUDENTS_SUCCESS,
      data: response?.data,
    });
  } catch (error) {
    yield put({
      type: emisStudentActions.GET_EMIS_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* submitAcmisStudents(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/students`,
      method: 'POST',
      data: { ...actions.data, ...actions.context },
    });
    yield put({
      type: emisStudentActions.SUBMIT_EMIS_STUDENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: emisStudentActions.GET_EMIS_STUDENTS_REQUEST,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: emisStudentActions.SUBMIT_EMIS_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetEmisStudents() {
  yield takeLatest(
    emisStudentActions.GET_EMIS_STUDENTS_REQUEST,
    getEmisStudents
  );
}
function* watchSubmitAcmisStudents() {
  yield takeLatest(
    emisStudentActions.SUBMIT_EMIS_STUDENT_REQUEST,
    submitAcmisStudents
  );
}

export default [fork(watchGetEmisStudents), fork(watchSubmitAcmisStudents)];
