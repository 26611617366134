import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { systemSponsorActions } from '../../actions';

function* getAcmisSponsors(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/acmis-sponsors`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemSponsorActions.GET_ACMIS_SPONSORS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: systemSponsorActions.GET_ACMIS_SPONSORS_ERROR,
      error: error.data,
    });
  }
}
function* getEmisSponsors(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/sponsors`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemSponsorActions.GET_EMIS_SPONSORS_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemSponsorActions.GET_EMIS_SPONSORS_ERROR,
      error: error.data,
    });
  }
}

function* submitSponsors(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/sponsors`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: systemSponsorActions.GET_ACMIS_SPONSORS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcmisSponsors() {
  yield takeLatest(
    systemSponsorActions.GET_ACMIS_SPONSORS_REQUEST,
    getAcmisSponsors
  );
}
function* watchGetEmisSponsors() {
  yield takeLatest(
    systemSponsorActions.GET_EMIS_SPONSORS_REQUEST,
    getEmisSponsors
  );
}
function* watchSubmitSponsors() {
  yield takeLatest(
    systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_REQUEST,
    submitSponsors
  );
}

export default [
  fork(watchGetAcmisSponsors),
  fork(watchSubmitSponsors),
  fork(watchGetEmisSponsors),
];
