import { previousRegistrationActions } from '../../actions';
import initialState from '../../initialState';

const previousRegistration = (
  state = initialState.previousRegistration,
  actions
) => {
  switch (actions.type) {
    case previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_ERROR:
      return {
        ...state,
        downloadTemplateError: actions.error,
        downloadingTemplate: false,
      };

    case previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateSuccess: {},
        uploadTemplateError: {},
      };
    case previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case previousRegistrationActions.EDIT_PREVIOUS_ENROLLMENT_RECORD_REQUEST:
      return {
        ...state,
        editingRecord: true,
        editRecordSuccess: {},
        editRecordError: {},
      };
    case previousRegistrationActions.EDIT_PREVIOUS_ENROLLMENT_RECORD_SUCCESS:
      return {
        ...state,
        editingRecord: false,
        editRecordSuccess: actions.data,
      };
    case previousRegistrationActions.EDIT_PREVIOUS_ENROLLMENT_RECORD_ERROR:
      return {
        ...state,
        editingRecord: false,
        editRecordError: actions.error,
      };

    case previousRegistrationActions.DELETE_PREVIOUS_ENROLLMENT_RECORD_REQUEST:
      return {
        ...state,
        deletingRecord: true,
        deleteRecordSuccess: {},
        deleteRecordError: {},
      };
    case previousRegistrationActions.DELETE_PREVIOUS_ENROLLMENT_RECORD_SUCCESS:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordSuccess: actions.data,
      };
    case previousRegistrationActions.DELETE_PREVIOUS_ENROLLMENT_RECORD_ERROR:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: actions.error,
      };

    default:
      return state;
  }
};
export default previousRegistration;
