import { findIndex, isEmpty } from 'lodash';
import update from 'immutability-helper';
import { courseUnitActions } from '../../actions';
import initialState from '../../initialState';

function courseUnit(state = initialState.courseUnit, actions) {
  switch (actions.type) {
    case courseUnitActions.GET_COURSE_UNITS_REQUEST:
      return {
        ...state,
        courseUnitError: {},
        loading: true,
      };
    case courseUnitActions.GET_COURSE_UNITS_SUCCESS:
      return {
        ...state,
        courseUnits: actions.data,
        courseUnitError: {},
        loading: false,
      };
    case courseUnitActions.GET_COURSE_UNITS_ERROR:
      return {
        ...state,
        courseUnitError: actions.error,
        loading: false,
      };

    case courseUnitActions.GET_COURSE_DETAILS_REQUEST:
      return {
        ...state,
        fetching: true,
        courseDetailsError: {},
      };
    case courseUnitActions.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        courseDetails: actions.data,
      };
    case courseUnitActions.GET_COURSE_DETAILS_ERROR:
      return {
        ...state,
        fetching: false,
        courseDetailsError: actions.error,
      };

    case courseUnitActions.GET_COURSE_UNITS_DOWNLOAD_REQUEST:
      return {
        ...state,
        downloadError: {},
      };
    case courseUnitActions.GET_COURSE_UNITS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        courseUnitDownloadData: actions.data,
        downloadError: {},
      };
    case courseUnitActions.GET_COURSE_UNITS_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadError: actions.error,
      };

    case courseUnitActions.GET_COURSE_UNIT_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingProgrammes: false,
        downloadError: {},
      };
    case courseUnitActions.GET_COURSE_UNIT_PROGRAMMES_SUCCESS: {
      const { context, data } = actions;
      const { courseUnitProgrammes } = state;

      const findContextIndex = findIndex(
        courseUnitProgrammes,
        (list) =>
          list.courseUnitId === context.courseUnitId &&
          list.semester === context.semster &&
          list.studyYear === context.studyYear
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          courseUnitProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          courseUnitProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingProgrammes: false,
      };
    }
    case courseUnitActions.GET_COURSE_UNIT_PROGRAMMES_ERROR:
      return {
        ...state,
        downloadError: actions.error,
      };

    case courseUnitActions.CREATE_COURSE_UNIT_REQUEST:
      return {
        ...state,
        creating: true,
        courseUnitError: {},
        createCourseUnitError: {},
        createdCourseUnitData: {},
      };
    case courseUnitActions.CREATE_COURSE_UNIT_SUCCESS:
      return {
        ...state,
        creating: false,
        createdCourseUnitData: actions.data,
      };
    case courseUnitActions.CREATE_COURSE_UNIT_ERROR:
      return {
        ...state,
        creating: false,
        createCourseUnitError: actions.error,
      };

    case courseUnitActions.EDIT_REPO_COURSE_REQUEST:
      return {
        ...state,
        editing: true,
        editError: {},
      };
    case courseUnitActions.EDIT_REPO_COURSE_SUCCESS:
      return {
        ...state,
        editing: false,
        editSuccess: actions.data,
      };
    case courseUnitActions.EDIT_REPO_COURSE_ERROR:
      return {
        ...state,
        editing: false,
        editError: actions.error,
      };

    case courseUnitActions.DELETE_COURSE_UNIT_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case courseUnitActions.DELETE_COURSE_UNIT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case courseUnitActions.DELETE_COURSE_UNIT_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case courseUnitActions.DELETE_REPO_COURSE_UNIT_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };

    case courseUnitActions.DELETE_REPO_COURSE_UNIT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case courseUnitActions.DELETE_REPO_COURSE_UNIT_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case courseUnitActions.UPDATE_COURSE_UNIT_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case courseUnitActions.UPDATE_COURSE_UNIT_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case courseUnitActions.UPDATE_COURSE_UNIT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case courseUnitActions.UPDATE_COURSE_DETAILS_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case courseUnitActions.UPDATE_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case courseUnitActions.UPDATE_COURSE_DETAILS_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case courseUnitActions.UPLOAD_COURSE_UNIT_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case courseUnitActions.UPLOAD_COURSE_UNIT_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadSuccess: actions.data,
      };
    case courseUnitActions.UPLOAD_COURSE_UNIT_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case courseUnitActions.DOWNLOAD_COURSE_UNIT_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateSuccess: {},
        downloadTemplateError: {},
      };
    case courseUnitActions.DOWNLOAD_COURSE_UNIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case courseUnitActions.DOWNLOAD_COURSE_UNIT_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case courseUnitActions.ASSIGN_COURSE_UNIT_DEPARTMENT_REQUEST:
      return {
        ...state,
        assigningDepartment: true,
        assignSuccess: {},
        assignError: {},
      };
    case courseUnitActions.ASSIGN_COURSE_UNIT_DEPARTMENT_SUCCESS:
      return {
        ...state,
        assigningDepartment: false,
        assignSuccess: actions.data,
      };
    case courseUnitActions.ASSIGN_COURSE_UNIT_DEPARTMENT_ERROR:
      return {
        ...state,
        assigningDepartment: false,
        assignError: actions.error,
      };

    case courseUnitActions.SET_COURSE_UNIT_EDIT_TAB:
      return {
        ...state,
        courseToEdit: actions.data,
        editCourseTabVisible: actions.tabState,
      };

    case courseUnitActions.SET_EDIT_COURSE_DETAILS:
      return {
        ...state,
        dataToEdit: actions.course,
      };

    case courseUnitActions.SET_REPO_COURSE_TO_EDIT:
      return {
        ...state,
        repoCourse: actions.data,
        isEditing: !isEmpty(actions.data),
      };
    default:
      return state;
  }
}

export default courseUnit;
