const systemStudyLevelActions = {
  GET_ACMIS_STUDY_LEVEL_REQUEST: 'GET_ACMIS_STUDY_LEVEL_REQUEST',
  GET_ACMIS_STUDY_LEVEL_SUCCESS: 'GET_ACMIS_STUDY_LEVEL_SUCCESS',
  GET_ACMIS_STUDY_LEVEL_ERROR: 'GET_ACMIS_STUDY_LEVEL_ERROR',

  GET_EMIS_STUDY_LEVEL_REQUEST: 'GET_EMIS_STUDY_LEVEL_REQUEST',
  GET_EMIS_STUDY_LEVEL_SUCCESS: 'GET_EMIS_STUDY_LEVEL_SUCCESS',
  GET_EMIS_STUDY_LEVEL_ERROR: 'GET_EMIS_STUDY_LEVEL_ERROR',

  SUBMIT_SYSTEM_STUDY_LEVEL_REQUEST: 'SUBMIT_SYSTEM_STUDY_LEVEL_REQUEST',
  SUBMIT_SYSTEM_STUDY_LEVEL_SUCCESS: 'SUBMIT_SYSTEM_STUDY_LEVEL_SUCCESS',
  SUBMIT_SYSTEM_STUDY_LEVEL_ERROR: 'SUBMIT_SYSTEM_STUDY_LEVEL_ERROR',

  getAcmisStudyLevels: () => ({
    type: systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_REQUEST,
  }),

  getEmisStudyLevels: () => ({
    type: systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_REQUEST,
  }),

  submitStudyLevels: (data) => ({
    type: systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_REQUEST,
    data,
  }),
};

export default systemStudyLevelActions;
