const NTCManager = {
  NTCStudent: {
    creating: false,
    createResponse: {},
    createError: {},

    downloadTemplateSuccess: {},
    downloadTemplateError: {},
    downloadingTemplate: false,

    downloading: false,
    downloadError: {},
    downloadSuccess: {},

    gettingNTCStudents: false,
    NTCStudents: [],
    getNTCStudentsError: {},

    updating: false,
    updateSuccess: {},
    updateError: {},

    NTCStudentContext: {},
  },

  NTCResult: {
    creating: false,
    createResponse: {},
    createError: {},

    downloadTemplateSuccess: {},
    downloadTemplateError: {},
    downloadingTemplate: false,

    downloading: false,
    downloadError: {},
    downloadSuccess: {},

    gettingNTCResults: false,
    NTCResults: [],
    getNTCResultError: {},

    gettingNTCTermlyResults: false,
    NTCTermlyResults: [],
    getNTCTermlyResultError: {},

    updating: false,
    updateSuccess: {},
    updateError: {},

    NTCResultContext: {},
    NTCTermlyResultContext: {},

    NTCProgrammes: [],

    NTCDocumentContext: {},
    NTCStudentsDocuments: [],
    gettingNTCStudentDocuments: false,
    getNTCStudentsDocumentsError: {},

    generatingDocument: false,
    downloadingDocument: false,
  },
  NTCSubject: {
    NTCSubjects: [],
    NTCSubject: {},
    subjectError: {},

    createdData: {},
    loading: false,
    creating: false,
    updating: false,
    deleting: false,

    deletingNTCSubject: false,
    deleteNTCSubjectError: {},
    deleteNTCSubjectSuccess: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},
  },
};

export default NTCManager;
