import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, Upload } from 'antd';
import { isEmpty, isFunction } from 'lodash';
import PropTypes, { any, object } from 'prop-types';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { FaFileCsv } from 'react-icons/fa';
import SubmitButton from './SubmitButton';

function FileUploader({
  fileName,
  title,
  accept,
  customMessage,
  onUpload,
  loadingUpload,
  loadingDownload,
  handleTemplateDownload,
  disabledSubmit,
  uploadButtonProps,
  templateButtonProps,
  errorMessage,
  successMessage,
  ...props
}) {
  const [fileList, setFileList] = useState([]);

  const handleUpload = () => {
    const formData = new FormData();
    formData.append(fileName, fileList[0]);

    if (onUpload && isFunction(onUpload)) {
      onUpload(formData);
    }
  };

  const uploadProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    maxCount: 1,
    fileList,
    accept,
  };

  return (
    <>
      <Card className="border-0">
        <>
          <div className="my-2">
            {(!isEmpty(customMessage) && customMessage) ||
              (errorMessage && (
                <Alert showIcon type="error" message={errorMessage} />
              )) ||
              (successMessage && (
                <Alert showIcon type="success" message={successMessage} />
              ))}
          </div>
          <div className="text-center mb-4">
            <Card.Header className="fw-bold border bg-light mx-auto py-2 fw-bold text-underline text-info">
              {title}
            </Card.Header>
          </div>
          <Upload {...uploadProps} className="w-100" {...props}>
            <SubmitButton
              className="w-100"
              block
              icon={<InboxOutlined />}
              type="dashed"
              text="Click to Select Upload File"
              danger
            />
          </Upload>
          <SubmitButton
            block
            text="UPLOAD"
            loadingText="UPLOADING..."
            onClick={handleUpload}
            loading={loadingUpload}
            disabled={disabledSubmit || fileList.length === 0}
            iconBefore={<UploadOutlined />}
            style={{
              marginTop: 18,
            }}
            {...uploadButtonProps}
          />
          <div className="template-downloader-wrapper">
            {handleTemplateDownload && isFunction(handleTemplateDownload) && (
              <SubmitButton
                onClick={handleTemplateDownload}
                type="link"
                className="mt-5 d-flex mx-auto fw-bold text-uppercase"
                loading={loadingDownload}
                iconBefore={<FaFileCsv className="me-1 my-auto" />}
                text="Download Template"
                loadingText="Downloading..."
                {...templateButtonProps}
              />
            )}
          </div>
        </>
      </Card>
    </>
  );
}

FileUploader.defaultProps = {
  fileName: 'file',
  title: 'FILE UPLOADER TITILE',
  accept:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsm, .xlsx',
  onUpload: undefined,
  handleTemplateDownload: undefined,
  customMessage: null,
  loadingUpload: false,
  loadingDownload: false,
  disabledSubmit: false,
  templateButtonProps: {},
  uploadButtonProps: {},
  errorMessage: null,
  successMessage: null,
};

FileUploader.propTypes = {
  fileName: PropTypes.string,
  title: PropTypes.oneOfType([any]),
  accept: PropTypes.string,
  onUpload: PropTypes.func,
  handleTemplateDownload: PropTypes.func,
  customMessage: PropTypes.oneOfType([any]),
  loadingUpload: PropTypes.bool,
  loadingDownload: PropTypes.bool,
  disabledSubmit: PropTypes.bool,
  templateButtonProps: PropTypes.oneOfType([object]),
  uploadButtonProps: PropTypes.oneOfType([object]),
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
};
export default FileUploader;
