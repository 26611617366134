import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { securityProfileActions } from '../../actions';

function* getSecurityProfiles(actions) {
  try {
    const response = yield axios({
      url: '/users/security-profiles',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: securityProfileActions.GET_SECURITY_PROFILE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: securityProfileActions.GET_SECURITY_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* createSecurityProfile(actions) {
  try {
    const response = yield axios({
      url: '/users/security-profiles',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: securityProfileActions.CREATE_SECURITY_PROFILE_SUCCESS,
      data: response,
    });
    yield put({
      type: securityProfileActions.GET_SECURITY_PROFILE_REQUEST,
      data: '',
    });
    yield put({
      type: 'SHOW_CREATE_SECURITY_PROFILE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: securityProfileActions.CREATE_SECURITY_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* updateSecurityProfile(actions) {
  try {
    const response = yield axios({
      url: `/users/security-profiles/${actions.securityProfileId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: securityProfileActions.UPDATE_SECURITY_PROFILE_SUCCESS,
      data: response,
    });
    yield put({
      type: securityProfileActions.GET_SECURITY_PROFILE_REQUEST,
      data: '',
    });
  } catch (error) {
    yield put({
      type: securityProfileActions.UPDATE_SECURITY_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSecurityProfiles() {
  yield takeLatest(
    securityProfileActions.GET_SECURITY_PROFILE_REQUEST,
    getSecurityProfiles
  );
}

function* watchCreateSecurityProfile() {
  yield takeLatest(
    securityProfileActions.CREATE_SECURITY_PROFILE_REQUEST,
    createSecurityProfile
  );
}

function* watchUpdateSecurityProfile() {
  yield takeLatest(
    securityProfileActions.UPDATE_SECURITY_PROFILE_REQUEST,
    updateSecurityProfile
  );
}

export default [
  fork(watchGetSecurityProfiles),
  fork(watchCreateSecurityProfile),
  fork(watchUpdateSecurityProfile),
];
