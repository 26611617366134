import { admissionSchemeActions } from '../../actions';
import initialState from '../../initialState';

function admissionScheme(state = initialState.admissionScheme, actions) {
  switch (actions.type) {
    case admissionSchemeActions.GET_ADMISSION_SCHEME_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case admissionSchemeActions.GET_ADMISSION_SCHEME_SUCCESS:
      return {
        ...state,
        admissionSchemes: actions.data.admissionSchemes,
        loading: false,
      };
    case admissionSchemeActions.GET_ADMISSION_SCHEME_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case admissionSchemeActions.CREATE_ADMISSION_SCHEME_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        created: {},
      };
    case admissionSchemeActions.CREATE_ADMISSION_SCHEME_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case admissionSchemeActions.CREATE_ADMISSION_SCHEME_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case admissionSchemeActions.UPDATE_ADMISSION_SCHEME_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updated: {},
      };
    case admissionSchemeActions.UPDATE_ADMISSION_SCHEME_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: actions.data,
      };
    case admissionSchemeActions.UPDATE_ADMISSION_SCHEME_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case admissionSchemeActions.GET_SINGLE_ADMISSION_SCHEME_REQUEST:
      return {
        ...state,
        gettingOne: false,
        getOneError: {},
        admissionScheme: {},
      };
    case admissionSchemeActions.GET_SINGLE_ADMISSION_SCHEME_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        admissionScheme: actions.data.admissionScheme,
      };
    case admissionSchemeActions.GET_SINGLE_ADMISSION_SCHEME_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case admissionSchemeActions.DELETE_ADMISSION_SCHEME_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: false,
        deleteResponse: {},
      };
    case admissionSchemeActions.DELETE_ADMISSION_SCHEME_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case admissionSchemeActions.DELETE_ADMISSION_SCHEME_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };
    case admissionSchemeActions.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case admissionSchemeActions.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_SUCCESS:
      return {
        ...state,
        emisAdmissionSchemeCategories: actions.data,
        loading: false,
      };
    case admissionSchemeActions.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    default:
      return state;
  }
}

export default admissionScheme;
