const resultLogActions = {
  GET_USER_RESULT_LOGS_REQUEST: 'GET_USER_RESULT_LOGS_REQUEST',
  GET_USER_RESULT_LOGS_SUCCESS: 'GET_USER_RESULT_LOGS_SUCCESS',
  GET_USER_RESULT_LOGS_ERROR: 'GET_USER_RESULT_LOGS_ERROR',

  getUserResultLogs: () => ({
    type: resultLogActions.GET_USER_RESULT_LOGS_REQUEST,
  }),
};

export default resultLogActions;
