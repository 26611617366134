const pujabMgtInitialState = {
  pujabInstitution: {
    gettingInstitutions: false,
    institutions: [],
    getInstitutionError: {},

    addingInstitution: false,
    addSuccess: {},
    addError: {},

    updatingInstitution: false,
    updateSuccess: {},
    updateError: {},

    deletingInstitution: false,
    deleteSuccess: {},
    deleteError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},
  },
  pujabProgramme: {
    pujabInstitution: {},

    gettingProgrammes: false,
    institutionProgrammes: [],
    getProgrammeError: [],

    addingProgramme: false,
    addProgrammeSuccess: {},
    addProgrammeError: {},

    updatingProgramme: false,
    updateSuccess: {},
    updateError: {},

    deletingProgramme: false,
    deleteSuccess: {},
    deleteError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},
  },
  pujabAdmission: {
    selectedAdmission: {},
    selectedInstitution: {},

    gettingAdmissions: false,
    admissions: [],
    getAdmissionError: [],

    gettingAdmissionInstitution: false,
    admissionInstitution: {},
    getAdmissionInstitutionError: [],

    creatingAdmission: false,
    createSuccess: {},
    createError: {},

    updatingAdmission: false,
    updateSuccess: {},
    updateError: {},

    deletingAdmission: false,
    deleteSuccess: {},
    deleteError: {},

    updatingStatus: false,
    admissionStatus: {},
    updateStatusError: {},

    deletingAdmissionProgramme: false,

    assigningProgramme: false,
    assignSuccess: {},
    assignError: {},
  },
  pujabReport: {
    gettingApplicantsReport: false,
    applicantsReportError: {},
    applicantsReport: [],

    downloadingApplicantsReport: false,
    downloadApplicantsReportError: {},

    gettingReportDetails: false,
    reportDetailError: {},
    reportDetails: [],

    gettingProgrammeChoices: false,
    programmeChoiceError: {},
    programmeChoices: [],
  },
  pujabSchoolAdmin: {
    gettingSchoolAdmins: false,
    getSchoolAdminError: {},
    schoolAdmins: [],

    currentTab: 'view',

    creating: false,
    createError: {},
  },
  pujabApplicants: {
    gettingApplicants: false,
    pujabApplicants: [],
    getApplicantsError: {},

    gettingMeritAdmissions: false,
    meritAdmissions: [],
    getMeritAdmissionsError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},

    updating: false,
    updateSuccess: {},
    updateError: {},
  },
};

export default pujabMgtInitialState;
