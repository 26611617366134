import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { admittedStudentsActions } from '../../actions';
import initialState from '../../initialState';

const admittedStudent = (state = initialState.admittedStudent, actions) => {
  switch (actions.type) {
    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingAdmittedStudentsTemplate: true,
        downloadAdmittedStudentsTemplateError: {},
      };

    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadAdmittedStudentsTemplateSuccess: actions.data,
        downloadingAdmittedStudentsTemplate: false,
      };

    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadAdmittedStudentsTemplateError: actions.error,
        downloadingAdmittedStudentsTemplate: false,
      };

    case admittedStudentsActions.DOWNLOAD_ALL_CAMPUS_REPORTS_REQUEST:
      return {
        ...state,
        downloadingAllCampusReports: true,
        downloadAllCampusReportsError: {},
      };

    case admittedStudentsActions.DOWNLOAD_ALL_CAMPUS_REPORTS_SUCCESS:
      return {
        ...state,
        downloadAllCampusReportsSuccess: actions.data,
        downloadingAllCampusReports: false,
      };

    case admittedStudentsActions.DOWNLOAD_ALL_CAMPUS_REPORTS_ERROR:
      return {
        ...state,
        downloadAllCampusReportsError: actions.error,
        downloadingAllCampusReports: false,
      };

    case admittedStudentsActions.UPLOAD_ADMITTED_STUDENTS_REQUEST:
      return {
        ...state,
        uploadingAdmittedStudents: true,
        uploadAdmittedStudentsError: {},
      };

    case admittedStudentsActions.UPLOAD_ADMITTED_STUDENTS_SUCCESS:
      return {
        ...state,
        uploadingAdmittedStudents: false,
        uploadAdmittedStudentsSuccess: actions.data,
      };
    case admittedStudentsActions.UPLOAD_ADMITTED_STUDENTS_ERROR:
      return {
        ...state,
        uploadingAdmittedStudents: false,
        uploadAdmittedStudentsError: actions.error,
      };

    case admittedStudentsActions.GET_ADMITTED_STUDENTS_REQUEST:
      return {
        ...state,
        gettingAdmittedStudents: true,
        getAdmittedStudentsError: {},
      };
    case admittedStudentsActions.GET_ADMITTED_STUDENTS_SUCCESS: {
      const { admittedStudents } = state;
      const { context, data } = actions;

      const findContextIndex = admittedStudents.findIndex(
        (currentContext) =>
          context.programme_id === currentContext.programme_id &&
          context.academic_year_id === currentContext.academic_year_id &&
          context.intake_id === currentContext.intake_id &&
          context.admission_scheme_id === currentContext.admission_scheme_id &&
          context.degree_category_id === currentContext.degree_category_id
      );

      const contextData = { ...context, admittedStudents: data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          admittedStudents: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          admittedStudents: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingAdmittedStudents: false,
      };
    }
    case admittedStudentsActions.GET_ADMITTED_STUDENTS_ERROR:
      return {
        ...state,
        gettingAdmittedStudents: false,
        getAdmittedStudentsError: actions.error,
      };

    case admittedStudentsActions.GET_ADMITTED_BY_CAMPUS_REQUEST:
      return {
        ...state,
        campusContext: actions.context,
        gettingAdmittedByCampus: true,
        getAdmittedByCampusError: {},
      };
    case admittedStudentsActions.GET_ADMITTED_BY_CAMPUS_SUCCESS: {
      const { admittedByCampus } = state;
      const { context, data } = actions;

      const findContextIndex = findIndex(
        admittedByCampus,
        (currentContext) =>
          context.programme_id === currentContext.programme_id &&
          context.in_srm === currentContext.in_srm &&
          context.entry_academic_year_id ===
            currentContext.entry_academic_year_id &&
          context.campus_id === currentContext.campus_id
      );

      const contextData = { ...context, admittedStudents: data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          admittedByCampus: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          admittedByCampus: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingAdmittedByCampus: false,
      };
    }
    case admittedStudentsActions.GET_ADMITTED_BY_CAMPUS_ERROR:
      return {
        ...state,
        gettingAdmittedByCampus: false,
        getAdmittedByCampusError: actions.error,
      };

    case admittedStudentsActions.DELETE_ADMITTED_STUDENTS_REQUEST:
      return {
        ...state,
        deletingStudents: true,
        deleteStudentsError: {},
      };
    case admittedStudentsActions.DELETE_ADMITTED_STUDENTS_SUCCESS:
      return {
        ...state,
        deletingStudents: false,
      };
    case admittedStudentsActions.DELETE_ADMITTED_STUDENTS_ERROR:
      return {
        ...state,
        deletingStudents: false,
        deleteStudentsError: actions.error,
      };

    case admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_REQUEST:
      return {
        ...state,
        searchingAdmittedStudent: true,
        searchAdmittedStudentError: {},
      };
    case admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_SUCCESS: {
      return {
        ...state,
        searchingAdmittedStudent: false,
        searchedAdmittedStudents: actions.data,
      };
    }
    case admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_ERROR:
      return {
        ...state,
        searchingAdmittedStudent: false,
        searchAdmittedStudentError: actions.error,
      };

    case admittedStudentsActions.EDIT_ADMITTED_STUDENT_REQUEST:
      return {
        ...state,
        editingAdmittedStudent: true,
        editingAdmittedStudentError: {},
      };
    case admittedStudentsActions.EDIT_ADMITTED_STUDENT_SUCCESS:
      return {
        ...state,
        editingAdmittedStudent: false,
        editingAdmittedStudentSuccess: actions.data,
      };
    case admittedStudentsActions.EDIT_ADMITTED_STUDENT_ERROR:
      return {
        ...state,
        editingAdmittedStudent: false,
        editingAdmittedStudentError: actions.data,
      };
    case admittedStudentsActions.UPDATE_HALL_OF_ATTACHMENT_REQUEST:
      return {
        ...state,
        updatingHallOfAttachment: true,
        editingAdmittedStudentError: {},
      };
    case admittedStudentsActions.UPDATE_HALL_OF_ATTACHMENT_SUCCESS:
      return {
        ...state,
        updatingHallOfAttachment: false,
        editingAdmittedStudentSuccess: actions.data,
      };
    case admittedStudentsActions.UPDATE_HALL_OF_ATTACHMENT_ERROR:
      return {
        ...state,
        updatingHallOfAttachment: false,
        editingAdmittedStudentError: actions.data,
      };

    case admittedStudentsActions.GET_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        gettingAdmissionProgrammes: true,
        admissionProgrammeError: {},
      };
    case admittedStudentsActions.GET_ADMISSION_PROGRAMME_SUCCESS: {
      const { admissionProgrammes } = state;
      const { context, data } = actions;

      const findContextIndex = admissionProgrammes.findIndex(
        (currentContext) =>
          context.academic_year_id === currentContext.academic_year_id &&
          context.intake_id === currentContext.intake_id &&
          context.admission_scheme_id === currentContext.admission_scheme_id &&
          context.category === currentContext.category
      );

      const contextData = { ...context, admissionProgrammes: data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          admissionProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          admissionProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingAdmissionProgrammes: false,
      };
    }
    case admittedStudentsActions.GET_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        gettingAdmissionProgrammes: false,
        admissionProgrammeError: actions.error,
      };

    case admittedStudentsActions.GENERATE_REGISTRATION_NUMBER_REQUEST:
      return {
        ...state,
        generatingRegistrationNumber: true,
        generateRegistrationNumberError: {},
      };
    case admittedStudentsActions.GENERATE_REGISTRATION_NUMBER_SUCCESS:
      return {
        ...state,
        generateRegistrationNumberSuccess: actions.data,
        generatingRegistrationNumber: false,
      };
    case admittedStudentsActions.GENERATE_REGISTRATION_NUMBER_ERROR:
      return {
        ...state,
        generatingRegistrationNumber: false,
        generateRegistrationNumberError: actions.error,
      };

    case admittedStudentsActions.GENERATE_ADMISSION_LETTERS_REQUEST:
      return {
        ...state,
        generatingAdmissionLetters: true,
        generateAdmissionLetterError: {},
      };
    case admittedStudentsActions.GENERATE_ADMISSION_LETTERS_SUCCESS:
      return {
        ...state,
        generateAdmissionLetter: actions.data,
        generatingAdmissionLetters: false,
      };
    case admittedStudentsActions.GENERATE_ADMISSION_LETTERS_ERROR:
      return {
        ...state,
        generatingAdmissionLetters: false,
        generateAdmissionLetterError: actions.error,
      };

    case admittedStudentsActions.PRINT_ADMISSION_LETTERS_REQUEST:
      return {
        ...state,
        printingAdmissionLetters: true,
        printAdmissionLetterError: {},
      };
    case admittedStudentsActions.PRINT_ADMISSION_LETTERS_SUCCESS:
      return {
        ...state,
        printAdmissionLetter: actions.data,
        printingAdmissionLetters: false,
      };
    case admittedStudentsActions.PRINT_ADMISSION_LETTERS_ERROR:
      return {
        ...state,
        printingAdmissionLetters: false,
        printAdmissionLetterError: actions.error,
      };

    case admittedStudentsActions.GENERATE_STUDENT_NUMBER_REQUEST:
      return {
        ...state,
        generatingStudentNumber: true,
        generateStudentNumberError: {},
      };

    case admittedStudentsActions.GENERATE_STUDENT_NUMBER_SUCCESS:
      return {
        ...state,
        generateStudentNumberSuccess: actions.data,
        generatingStudentNumber: false,
      };

    case admittedStudentsActions.GENERATE_STUDENT_NUMBER_ERROR:
      return {
        ...state,
        generatingStudentNumber: false,
        generateStudentNumberError: actions.error,
      };

    case admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_RECORDS_REQUEST:
      return {
        ...state,
        pushingAdmittedApplicants: true,
        pushAdmittedApplicantsError: {},
      };

    case admittedStudentsActions.PUSH_ADMITTED_APPLICANTS__RECORDS_SUCCESS:
      return {
        ...state,
        pushAdmittedApplicantsSuccess: actions.data,
        pushingAdmittedApplicants: false,
      };

    case admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_RECORDS_ERROR:
      return {
        ...state,
        pushingAdmittedApplicants: false,
        pushAdmittedApplicantsError: actions.error,
      };

    case admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_REQUEST:
      return {
        ...state,
        pushingPreviouslyAdmittedApplicants: true,
        pushPreviouslyAdmittedApplicantsError: {},
      };

    case admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_SUCCESS:
      return {
        ...state,
        pushingPreviouslyAdmittedApplicants: false,
        pushPreviouslyAdmittedApplicantsSuccess: actions.data,
      };

    case admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_ERROR:
      return {
        ...state,
        pushingPreviouslyAdmittedApplicants: false,
        pushPreviouslyAdmittedApplicantsError: actions.error,
      };

    case admittedStudentsActions.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_REQUEST:
      return {
        ...state,
        downloadingNotPushedReport: true,
        downloadNotPushedReportError: {},
      };
    case admittedStudentsActions.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_SUCCESS:
      return {
        ...state,
        downloadingNotPushedReport: false,
        downloadNotPushedReportSuccess: actions.data,
      };
    case admittedStudentsActions.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_ERROR:
      return {
        ...state,
        downloadingNotPushedReport: false,
        downloadNotPushedReportError: actions.data,
      };

    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_REQUEST:
      return {
        ...state,
        downloadingAdmittedStudents: true,
        downloadAdmittedStudentsError: {},
      };
    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_SUCCESS:
      return {
        ...state,
        downloadingAdmittedStudents: false,
        downloadAdmittedStudentsSuccess: actions.data,
      };
    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_ERROR:
      return {
        ...state,
        downloadingAdmittedStudents: false,
        downloadAdmittedStudentsError: actions.data,
      };

    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_REQUEST:
      return {
        ...state,
        downloadingAdmittedStudentsPdf: true,
        downloadAdmittedStudentsPdfError: {},
      };
    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_SUCCESS:
      return {
        ...state,
        downloadingAdmittedStudentsPdf: false,
        downloadAdmittedStudentsPdfSuccess: actions.data,
      };
    case admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_ERROR:
      return {
        ...state,
        downloadingAdmittedStudentsPdf: false,
        downloadAdmittedStudentsPdfError: actions.data,
      };

    case admittedStudentsActions.GET_ADMITTED_STUDENTS_REPORTS_REQUEST:
      return {
        ...state,
        gettingAdmittedReports: true,
        admittedReportsError: {},
      };
    case admittedStudentsActions.GET_ADMITTED_STUDENTS_REPORTS_SUCCESS: {
      const { admittedReports } = state;
      const { context, data } = actions;

      let findContextIndex;

      if (context?.campus_id) {
        findContextIndex = admittedReports.findIndex(
          (report) =>
            report.academic_year_id === context.academic_year_id &&
            report.intake_id === context.intake_id &&
            report.campus_id === context.campus_id &&
            report.degree_category_id === context.degree_category_id &&
            report.report_category === context.report_category
        );
      } else {
        findContextIndex = admittedReports.findIndex(
          (currentContext) =>
            context.academic_year_id === currentContext.academic_year_id &&
            context.intake_id === currentContext.intake_id &&
            context.admission_scheme_id ===
              currentContext.admission_scheme_id &&
            context.degree_category_id === currentContext.degree_category_id &&
            context.report_category === currentContext.report_category
        );
      }

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          admittedReports: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          admittedReports: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingAdmittedReports: false,
      };
    }
    case admittedStudentsActions.GET_ADMITTED_STUDENTS_REPORTS_ERROR:
      return {
        ...state,
        gettingAdmittedReports: false,
        admittedReportsError: actions.error,
      };

    case admittedStudentsActions.GET_ADMISSION_STATISTICS_REQUEST:
      return {
        ...state,
        gettingAdmissionStatistics: true,
        admissionStatisticsError: {},
      };
    case admittedStudentsActions.GET_ADMISSION_STATISTICS_SUCCESS: {
      return {
        ...state,
        gettingAdmissionStatistics: false,
        admissionStatistics: actions.data,
      };
    }
    case admittedStudentsActions.GET_ADMISSION_STATISTICS_ERROR:
      return {
        ...state,
        gettingAdmissionStatistics: false,
        admissionStatisticsError: actions.data,
      };

    case admittedStudentsActions.SET_ADMITTED_STUDENTS_CONTEXT: {
      return {
        ...state,
        admittedStudentsContext: actions.data,
      };
    }

    case admittedStudentsActions.SHOW_GENERATE_STUDENT_NUMBERS_MODAL: {
      return {
        ...state,
        showingGenerateModal: actions.payload,
      };
    }
    case admittedStudentsActions.SHOW_DOWNLOAD_ADMITTED_STUDENTS_MODAL: {
      return {
        ...state,
        showingDownloadModal: actions.payload,
      };
    }

    case admittedStudentsActions.SET_ADMISSION_STATISTICS_CONTEXT: {
      return {
        ...state,
        staticsContext: actions.payload,
      };
    }

    case admittedStudentsActions.SET_SELECTED_ADMITTED_STUDENT_PROGRAMME: {
      return {
        ...state,
        selectedProgramme: actions.payload || {},
      };
    }

    default:
      return state;
  }
};
export default admittedStudent;
