const institutionStructure = {
  GET_INSTITUTION_STRUCTURE_REQUEST: 'GET_INSTITUTION_STRUCTURE_REQUEST',
  GET_INSTITUTION_STRUCTURE_SUCCESS: 'GET_INSTITUTION_STRUCTURE_SUCCESS',
  GET_INSTITUTION_STRUCTURE_ERROR: 'GET_INSTITUTION_STRUCTURE_ERROR',

  CREATE_INSTITUTION_STRUCTURE_REQUEST: 'CREATE_INSTITUTION_STRUCTURE_REQUEST',
  CREATE_INSTITUTION_STRUCTURE_SUCCESS: 'CREATE_INSTITUTION_STRUCTURE_SUCCESS',
  CREATE_INSTITUTION_STRUCTURE_ERROR: 'CREATE_INSTITUTION_STRUCTURE_ERROR',

  UPDATE_INSTITUTION_STRUCTURE_REQUEST: 'UPDATE_INSTITUTION_STRUCTURE_REQUEST',
  UPDATE_INSTITUTION_STRUCTURE_SUCCESS: 'UPDATE_INSTITUTION_STRUCTURE_SUCCESS',
  UPDATE_INSTITUTION_STRUCTURE_ERROR: 'UPDATE_INSTITUTION_STRUCTURE_ERROR',

  UPLOAD_INSTITUTION_LOGO_REQUEST: 'UPLOAD_INSTITUTION_LOGO_REQUEST',
  UPLOAD_INSTITUTION_LOGO_SUCCESS: 'UPLOAD_INSTITUTION_LOGO_SUCCESS',
  UPLOAD_INSTITUTION_LOGO_ERROR: 'UPLOAD_INSTITUTION_LOGO_ERROR',

  GET_INSTITUTION_DASHBOARD_DATA_REQUEST: 'GET_INSTITUTION_DASHBOARD_REQUEST',
  GET_INSTITUTION_DASHBOARD_DATA_SUCCESS: 'GET_INSTITUTION_DASHBOARD_SUCCESS',
  GET_INSTITUTION_DASHBOARD_DATA_ERROR: 'GET_INSTITUTION_DASHBOARD_ERROR',

  getInstitutionStructure: (params) => ({
    type: institutionStructure.GET_INSTITUTION_STRUCTURE_REQUEST,
    params,
  }),

  getDashboardData: () => ({
    type: institutionStructure.GET_INSTITUTION_DASHBOARD_DATA_REQUEST,
  }),

  createInstitutionStructure: (data) => ({
    type: institutionStructure.CREATE_INSTITUTION_STRUCTURE_REQUEST,
    data,
  }),

  updateInstitutionStructure: (institutionId, data) => ({
    type: institutionStructure.UPDATE_INSTITUTION_STRUCTURE_REQUEST,
    data,
    institutionId,
  }),

  uploadInstitutionLogo: (data) => ({
    type: institutionStructure.UPLOAD_INSTITUTION_LOGO_REQUEST,
    data,
  }),
};

export default institutionStructure;
