import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { senateActions } from '../../actions';

function* getSenateGraduationList(actions) {
  const { params } = actions;
  try {
    const response = yield axios({
      url: '/results-mgt/graduation/senate-graduates',
      method: 'GET',
      params,
    });
    yield put({
      type: senateActions.GET_SENATE_GRADUATION_LIST_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: senateActions.GET_SENATE_GRADUATION_LIST_ERROR,
      error: error.data,
    });
  }
}

function* graduateSenateGraduates(actions) {
  try {
    const { params, data, context } = actions;
    const response = yield axios({
      url: `/results-mgt/graduation/graduate-students`,
      method: 'POST',
      data,
      params,
      timeout: 1200000,
    });

    yield put({
      type: senateActions.GRADUATE_SENATE_STUDENTS_SUCCESS,
      data: response.data,
      context: actions.params,
    });
    yield put({
      type: senateActions.GET_SENATE_GRADUATION_LIST_REQUEST,
      params: context,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: senateActions.GRADUATE_SENATE_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSenateGraduationList() {
  yield takeLatest(
    senateActions.GET_SENATE_GRADUATION_LIST_REQUEST,
    getSenateGraduationList
  );
}

function* watchGraduateSenateGraduates() {
  yield takeLatest(
    senateActions.GRADUATE_SENATE_STUDENTS_REQUEST,
    graduateSenateGraduates
  );
}

export default [
  fork(watchGetSenateGraduationList),
  fork(watchGraduateSenateGraduates),
];
