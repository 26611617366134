const refundTransaction = {
  SEARCH_REFUND_TRANSACTION_REQUEST: 'SEARCH_REFUND_TRANSACTION_REQUEST',
  SEARCH_REFUND_TRANSACTION_SUCCESS: 'SEARCH_REFUND_TRANSACTION_SUCCESS',
  SEARCH_REFUND_TRANSACTION_ERROR: 'SEARCH_REFUND_TRANSACTION_ERROR',

  FETCH_REFUNDED_TRANSACTIONS_REQUEST: 'FETCH_REFUNDED_TRANSACTIONS_REQUEST',
  FETCH_REFUNDED_TRANSACTIONS_SUCCESS: 'FETCH_REFUNDED_TRANSACTIONS_SUCCESS',
  FETCH_REFUNDED_TRANSACTIONS_ERROR: 'FETCH_REFUNDED_TRANSACTIONS_ERROR',

  REQUEST_PAYMENT_REFUND_REQUEST: 'REQUEST_PAYMENT_REFUND_REQUEST',
  REQUEST_PAYMENT_REFUND_SUCCESS: 'REQUEST_PAYMENT_REFUND_SUCCESS',
  REQUEST_PAYMENT_REFUND_ERROR: 'REQUEST_PAYMENT_REFUND_ERROR',

  GET_PENDING_PAYMENT_REFUNDS_REQUEST: 'GET_PENDING_PAYMENT_REFUNDS_REQUEST',
  GET_PENDING_PAYMENT_REFUNDS_SUCCESS: 'GET_PENDING_PAYMENT_REFUNDS_SUCCESS',
  GET_PENDING_PAYMENT_REFUNDS_ERROR: 'GET_PENDING_PAYMENT_REFUNDS_ERROR',

  APPROVE_PENDING_REFUND_REQUEST: 'APPROVE_PENDING_REFUND_REQUEST',
  APPROVE_PENDING_REFUND_SUCCESS: 'APPROVE_PENDING_REFUND_SUCCESS',
  APPROVE_PENDING_REFUND_ERROR: 'APPROVE_PENDING_REFUND_ERROR',

  DELETE_A_PENDING_REFUND_REQUEST: 'DELETE_A_PENDING_REFUND_REQUEST',
  DELETE_A_PENDING_REFUND_SUCCESS: 'DELETE_A_PENDING_REFUND_SUCCESS',
  DELETE_A_PENDING_REFUND_ERROR: 'DELETE_A_PENDING_REFUND_ERROR',

  SET_SHOW_REFUND_PAYMENT_MODAL: 'SET_SHOW_REFUND_PAYMENT_MODAL',
  SET_CURRENT_STUDENT_PAYMENT_REFUNDS: 'SET_CURRENT_STUDENT_PAYMENT_REFUNDS',
  SET_PAYMENT_REFUND_APPROVAL_DATA: 'SET_PAYMENT_REFUND_APPROVAL_DATA',

  searchRefundTransaction: (studentId, data) => ({
    type: refundTransaction.SEARCH_REFUND_TRANSACTION_REQUEST,
    studentId,
    data,
  }),

  fetchRefundedTransactions: (studentId) => ({
    type: refundTransaction.FETCH_REFUNDED_TRANSACTIONS_REQUEST,
    studentId,
  }),

  requestPaymentRefund: (studentId, data) => ({
    type: refundTransaction.REQUEST_PAYMENT_REFUND_REQUEST,
    studentId,
    data,
  }),

  getPendingPaymentRefunds: () => ({
    type: refundTransaction.GET_PENDING_PAYMENT_REFUNDS_REQUEST,
  }),

  approvePendingPaymentRefund: (data) => ({
    type: refundTransaction.APPROVE_PENDING_REFUND_REQUEST,
    data,
  }),

  deletePendingRefund: (id) => ({
    type: refundTransaction.DELETE_A_PENDING_REFUND_REQUEST,
    id,
  }),

  setShowRefundModal: (payload) => ({
    type: refundTransaction.SET_SHOW_REFUND_PAYMENT_MODAL,
    payload,
  }),

  setPaymentRefundApproval: (data) => ({
    type: refundTransaction.SET_PAYMENT_REFUND_APPROVAL_DATA,
    data,
  }),

  setCurrentStudentTransactions: (data) => ({
    type: refundTransaction.SET_CURRENT_STUDENT_PAYMENT_REFUNDS,
    data,
  }),
};

export default refundTransaction;
