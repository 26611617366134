import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { pujabProgrammeActions } from '../../actions';

function* getProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/pujab/programmes/programmes-by-institution/${actions.institutionId}`,
      method: 'GET',
    });
    yield put({
      type: pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_SUCCESS,
      data: response.data,
      context: { institutionId: actions.institutionId },
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getSingleProgramme(actions) {
  try {
    const response = yield axios({
      url: `/pujab/programmes/${actions.programmeId}`,
      method: 'GET',
    });
    yield put({
      type: pujabProgrammeActions.GET_SINGLE_PUJAB_PROGRAMME_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.GET_SINGLE_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* createProgramme(actions) {
  try {
    const response = yield axios({
      url: '/pujab/programmes',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_REQUEST,
      institutionId: actions.institutionId,
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* updateProgramme(actions) {
  try {
    const response = yield axios({
      url: `/pujab/programmes/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_REQUEST,
      institutionId: actions.institutionId,
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* deleteProgramme(actions) {
  try {
    const response = yield axios({
      url: `/pujab/programmes/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_REQUEST,
      institutionId: actions.institutionId,
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* uploadProgrammes(actions) {
  try {
    const response = yield axios({
      url: '/pujab/programmes/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadProgrammeTemplate() {
  try {
    yield axios({
      url: '/pujab/programmes/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PUJAB-PROGRAMME-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetProgrammes() {
  yield takeLatest(
    pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_REQUEST,
    getProgrammes
  );
}

function* watchGetSingleProgramme() {
  yield takeLatest(
    pujabProgrammeActions.GET_SINGLE_PUJAB_PROGRAMME_REQUEST,
    getSingleProgramme
  );
}

function* watchCreateProgramme() {
  yield takeLatest(
    pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_REQUEST,
    createProgramme
  );
}

function* watchUpdateProgramme() {
  yield takeLatest(
    pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_REQUEST,
    updateProgramme
  );
}

function* watchDeleteProgramme() {
  yield takeLatest(
    pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_REQUEST,
    deleteProgramme
  );
}

function* watchUploadProgrammes() {
  yield takeLatest(
    pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_REQUEST,
    uploadProgrammes
  );
}

function* watchDownloadProgrammeTemplate() {
  yield takeLatest(
    pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_REQUEST,
    downloadProgrammeTemplate
  );
}

export default [
  fork(watchGetProgrammes),
  fork(watchGetSingleProgramme),
  fork(watchCreateProgramme),
  fork(watchUpdateProgramme),
  fork(watchDeleteProgramme),
  fork(watchUploadProgrammes),
  fork(watchDownloadProgrammeTemplate),
];
