const documentSetting = {
  GET_DOCUMENT_SETTING_REQUEST: 'GET_DOCUMENT_SETTING_REQUEST',
  GET_DOCUMENT_SETTING_SUCCESS: 'GET_DOCUMENT_SETTING_SUCCESS',
  GET_DOCUMENT_SETTING_ERROR: 'GET_DOCUMENT_SETTING_ERROR',

  STUDENT_DOCUMENT_PREVIEW_REQUEST: 'STUDENT_DOCUMENT_PREVIEW_REQUEST',
  STUDENT_DOCUMENT_PREVIEW_SUCCESS: 'STUDENT_DOCUMENT_PREVIEW_SUCCESS',
  STUDENT_DOCUMENT_PREVIEW_ERROR: 'STUDENT_DOCUMENT_PREVIEW_ERROR',

  CREATE_DOCUMENT_SETTING_REQUEST: 'CREATE_DOCUMENT_SETTING_REQUEST',
  CREATE_DOCUMENT_SETTING_SUCCESS: 'CREATE_DOCUMENT_SETTING_SUCCESS',
  CREATE_DOCUMENT_SETTING_ERROR: 'CREATE_DOCUMENT_SETTING_ERROR',

  getDocumentSetting: (params) => ({
    type: documentSetting.GET_DOCUMENT_SETTING_REQUEST,
    params,
  }),

  getDocumentPreview: (category, params) => ({
    type: documentSetting.STUDENT_DOCUMENT_PREVIEW_REQUEST,
    category,
    params,
  }),

  createDocumentSetting: (data) => ({
    type: documentSetting.CREATE_DOCUMENT_SETTING_REQUEST,
    data,
  }),
};

export default documentSetting;
