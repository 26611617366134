import update from 'immutability-helper';
import { studentActions } from '../../actions';
import initialState from '../../initialState';

const student = (state = initialState.student, actions) => {
  switch (actions.type) {
    case studentActions.DOWNLOAD_STUDENT_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case studentActions.DOWNLOAD_STUDENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case studentActions.DOWNLOAD_STUDENT_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case studentActions.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingUpdateTemplate: true,
        downloadUpdateError: {},
      };
    case studentActions.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadUpdateTemplateSuccess: actions.data,
        downloadingUpdateTemplate: false,
      };
    case studentActions.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingUpdateTemplate: false,
        downloadUpdateError: actions.error,
      };

    case studentActions.UPLOAD_STUDENT_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case studentActions.UPLOAD_STUDENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case studentActions.UPLOAD_STUDENT_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case studentActions.VERIFY_UPLOAD_STUDENT_TEMPLATE_REQUEST:
      return {
        ...state,
        verifyingTemplate: true,
        verifyTemplateError: {},
        verifyTemplateSuccess: [],
      };
    case studentActions.VERIFY_UPLOAD_STUDENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        verifyTemplateSuccess: actions.data,
        verifyingTemplate: false,
      };
    case studentActions.VERIFY_UPLOAD_STUDENT_TEMPLATE_ERROR:
      return {
        ...state,
        verifyingTemplate: false,
        verifyTemplateError: actions.error,
      };

    case studentActions.UPLOAD_BULK_STUDENT_UPDATE_REQUEST:
      return {
        ...state,
        uploadingUpdateTemplate: true,
        uploadUpdateError: {},
      };
    case studentActions.UPLOAD_BULK_STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        uploadUpdateTemplateSuccess: actions.data,
        uploadingUpdateTemplate: false,
      };
    case studentActions.UPLOAD_BULK_STUDENT_UPDATE_ERROR:
      return {
        ...state,
        uploadingUpdateTemplate: false,
        uploadUpdateError: actions.error,
      };

    case studentActions.SEARCH_STUDENT_REQUEST:
      return {
        ...state,
        searching: true,
        searchError: {},
      };
    case studentActions.SEARCH_STUDENT_SUCCESS:
      return {
        ...state,
        searchedStudents: actions.data,
        searchedContext: actions.context,
        searching: false,
      };
    case studentActions.SEARCH_STUDENT_ERROR:
      return {
        ...state,
        searching: false,
        searchError: actions.error,
      };

    case studentActions.GET_PENDING_STUDENTS_REQUEST:
      return {
        ...state,
        gettingPendingStudents: true,
        pendingError: {},
      };
    case studentActions.GET_PENDING_STUDENTS_SUCCESS:
      return {
        ...state,
        pendingStudents: actions.data,
        gettingPendingStudents: false,
      };
    case studentActions.GET_PENDING_STUDENTS_ERROR:
      return {
        ...state,
        gettingPendingStudents: false,
        pendingError: actions.error,
      };

    case studentActions.GET_BATCH_STUDENTS_REQUEST:
      return {
        ...state,
        gettingBatchStudents: true,
        batchError: {},
      };
    case studentActions.GET_BATCH_STUDENTS_SUCCESS:
      return {
        ...state,
        batchStudents: actions.data,
        gettingBatchStudents: false,
      };
    case studentActions.GET_BATCH_STUDENTS_ERROR:
      return {
        ...state,
        gettingBatchStudents: false,
        batchError: actions.error,
      };

    case studentActions.UPDATE_STUDENTS_DISSERTATION_REQUEST:
      return {
        ...state,
        updatingDissertations: true,
        updateDissertationError: {},
        updatedDissertation: false,
      };

    case studentActions.UPDATE_STUDENTS_DISSERTATION_SUCCESS:
      return {
        ...state,
        updatingDissertations: false,
        updatedDissertation: true,
      };

    case studentActions.UPDATE_STUDENTS_DISSERTATION_ERROR:
      return {
        ...state,
        updatingDissertations: false,
        updatedDissertation: false,
        updateDissertationError: actions.error,
      };

    case studentActions.GET_STUDENTS_DISSERTATION_REQUEST:
      return {
        ...state,
        gettingDissertations: true,
        getDissertationError: {},
      };
    case studentActions.GET_STUDENTS_DISSERTATION_SUCCESS: {
      const { programme_id: programmeId, data } = actions;
      const { dissertations } = state;

      const findContextIndex = dissertations.findIndex(
        (list) => list.programmeId === programmeId
      );

      const contextData = { programmeId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          dissertations: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          dissertations: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingDissertations: false,
      };
    }
    case studentActions.GET_STUDENTS_DISSERTATION_ERROR:
      return {
        ...state,
        gettingDissertations: false,
        getDissertationError: actions.error,
      };

    case studentActions.APPROVE_PENDING_STUDENT_REQUEST:
      return {
        ...state,
        approving: true,
        approveError: {},
      };
    case studentActions.APPROVE_PENDING_STUDENT_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };
    case studentActions.APPROVE_PENDING_STUDENT_ERROR:
      return {
        ...state,
        approving: false,
        approveError: actions.error,
      };

    case studentActions.APPROVE_PENDING_BATCH_REQUEST:
      return {
        ...state,
        approving: true,
        approveError: {},
      };
    case studentActions.APPROVE_PENDING_BATCH_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };
    case studentActions.APPROVE_PENDING_BATCH_ERROR:
      return {
        ...state,
        approving: false,
        approveError: actions.error,
      };

    case studentActions.DELETE_PENDING_BATCH_REQUEST:
      return {
        ...state,
        deleting: true,
        error: {},
      };
    case studentActions.DELETE_PENDING_BATCH_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case studentActions.DELETE_PENDING_BATCH_ERROR:
      return {
        ...state,
        deleting: false,
        error: actions.error,
      };

    case studentActions.GET_MY_STUDENT_BATCHES_REQUEST:
      return {
        ...state,
        gettingMyBatch: true,
        myBatchError: {},
      };
    case studentActions.GET_MY_STUDENT_BATCHES_SUCCESS:
      return {
        ...state,
        myStudentBatches: actions.data,
        gettingMyBatch: false,
      };
    case studentActions.GET_MY_STUDENT_BATCHES_ERROR:
      return {
        ...state,
        gettingMyBatch: false,
        myBatchError: actions.error,
      };

    case studentActions.DELETE_STUDENT_FROM_SRM_REQUEST:
      return {
        ...state,
        deletingFromSRM: true,
        deleteFromSRMError: {},
      };
    case studentActions.DELETE_STUDENT_FROM_SRM_SUCCESS:
      return {
        ...state,
        deleteFromSRMSuccess: actions.data,
        deletingFromSRM: false,
      };
    case studentActions.DELETE_STUDENT_FROM_SRM_ERROR:
      return {
        ...state,
        deletingFromSRM: false,
        deleteFromSRMError: actions.error,
      };

    case studentActions.SHOW_DELETE_STUDENT_FROM_SRM_MODAL:
      return {
        ...state,
        showDeleteFromSRMModal: actions.payload,
      };

    case studentActions.SET_MY_BATCH_CONTEXT:
      return {
        ...state,
        myBatchContext: actions.params,
      };

    case studentActions.GET_PRINTED_STUDENT_ID_CARDS_REQUEST:
      return {
        ...state,
        gettingPrintedCards: true,
        myBatchError: {},
      };
    case studentActions.GET_PRINTED_STUDENT_ID_CARDS_SUCCESS:
      return {
        ...state,
        printedCards: actions.data,
        gettingPrintedCards: false,
      };
    case studentActions.GET_PRINTED_STUDENT_ID_CARDS_ERROR:
      return {
        ...state,
        gettingPrintedCards: false,
        printedCardsError: actions.error,
      };

    case studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        gettingPendingStatusChanges: true,
        pendingStatusChangeRequestsError: {},
      };
    case studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        pendingStatusChangeRequests: actions.data,
        gettingPendingStatusChanges: false,
      };
    case studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_ERROR:
      return {
        ...state,
        gettingPendingStatusChanges: false,
        pendingStatusChangeRequestsError: actions.error,
      };

    case studentActions.APPROVE_PENDING_ACCOUNT_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        approving: true,
        approveError: {},
      };
    case studentActions.APPROVE_PENDING_ACCOUNT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };
    case studentActions.APPROVE_PENDING_ACCOUNT_STATUS_CHANGE_ERROR:
      return {
        ...state,
        approving: false,
        approveError: actions.error,
      };
    case studentActions.DELETE_STUDENT_AVATAR_OR_SIGNATURE_REQUEST:
      return {
        ...state,
        deleteAvatarOrSignature: true,
        deleteAvatarOrSignatureError: {},
        deleteAvatarOrSignatureSuccess: {},
      };
    case studentActions.DELETE_STUDENT_AVATAR_OR_SIGNATURE_SUCCESS:
      return {
        ...state,
        deleteAvatarOrSignature: false,
        deleteAvatarOrSignatureSuccess: actions.data,
      };
    case studentActions.DELETE_STUDENT_AVATAR_OR_SIGNATURE_ERROR:
      return {
        ...state,
        deleteAvatarOrSignature: false,
        deleteAvatarOrSignatureError: actions.error,
      };

    default:
      return state;
  }
};
export default student;
