import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { notificationActions } from '../../actions';
import initialState from '../../initialState';

const notification = (state = initialState.notification, actions) => {
  switch (actions.type) {
    case notificationActions.GET_REPORT_REQUEST:
      return {
        ...state,
        gettingReport: true,
        reportContext: actions.context,
        reportError: {},
      };
    case notificationActions.GET_REPORT_SUCCESS: {
      const { reports } = state;
      const { context, category } = actions;
      const findContextIndex = findIndex(
        reports,
        (report) =>
          report.year === context.year &&
          report.quarter === context.quarter &&
          report.category === category
      );

      const contextData = { ...context, data: actions.data, category };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          reports: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          reports: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingReport: false,
      };
    }
    case notificationActions.GET_REPORT_ERROR:
      return {
        ...state,
        reportError: actions.error,
        gettingReport: false,
      };

    default:
      return state;
  }
};

export default notification;
