import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { refundTransactionActions } from '../../actions';

function* searchRefundTransaction(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/search-ura-refunded/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: refundTransactionActions.SEARCH_REFUND_TRANSACTION_SUCCESS,
      studentId: actions.studentId,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: refundTransactionActions.SEARCH_REFUND_TRANSACTION_ERROR,
      error: error.data,
    });
  }
}

function* fetchRefundedTransactions(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/get-approved-refunds/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: refundTransactionActions.FETCH_REFUNDED_TRANSACTIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: refundTransactionActions.FETCH_REFUNDED_TRANSACTIONS_ERROR,
      error: error.data,
    });
  }
}

function* requestPaymentRefund(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/process-ura-paid-refunds/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: refundTransactionActions.REQUEST_PAYMENT_REFUND_SUCCESS,
      data: response.data,
    });
    yield put({
      type: refundTransactionActions.SET_SHOW_REFUND_PAYMENT_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: refundTransactionActions.REQUEST_PAYMENT_REFUND_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingPaymentRefund(actions) {
  try {
    yield put({
      type: refundTransactionActions.SET_PAYMENT_REFUND_APPROVAL_DATA,
      data: actions.data,
    });
    const response = yield axios({
      url: `/registration/payment-transactions/approve-request-to-refund/${actions.data.id}`,
      method: 'POST',
      data: {
        student_id: actions.data.student.id,
        comment: `APPROVED FOR REASON KNOWN TO ${actions.data.student.surname}`,
        void_transactions: true,
      },
    });
    yield put({
      type: refundTransactionActions.APPROVE_PENDING_REFUND_SUCCESS,
      data: response.data,
    });
    yield put({
      type: refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_REQUEST,
    });
    yield put({
      type: refundTransactionActions.SET_PAYMENT_REFUND_APPROVAL_DATA,
      data: {},
    });
  } catch (error) {
    yield put({
      type: refundTransactionActions.APPROVE_PENDING_REFUND_ERROR,
      error: error.data,
    });
    yield put({
      type: refundTransactionActions.SET_PAYMENT_REFUND_APPROVAL_DATA,
      data: {},
    });
  }
}

function* getPendingPaymentRefunds(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/pending-refund`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_ERROR,
      error: error.data,
    });
  }
}

function* deletePendingRefund(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/delete-refund/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: refundTransactionActions.DELETE_A_PENDING_REFUND_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: refundTransactionActions.DELETE_A_PENDING_REFUND_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchRefundTransaction() {
  yield takeLatest(
    refundTransactionActions.SEARCH_REFUND_TRANSACTION_REQUEST,
    searchRefundTransaction
  );
}

function* watchFetchRefundedTransactions() {
  yield takeLatest(
    refundTransactionActions.FETCH_REFUNDED_TRANSACTIONS_REQUEST,
    fetchRefundedTransactions
  );
}

function* watchRequestPaymentRefund() {
  yield takeLatest(
    refundTransactionActions.REQUEST_PAYMENT_REFUND_REQUEST,
    requestPaymentRefund
  );
}

function* watchGetPendingPaymentRefunds() {
  yield takeLatest(
    refundTransactionActions.GET_PENDING_PAYMENT_REFUNDS_REQUEST,
    getPendingPaymentRefunds
  );
}

function* watchApprovePendingPaymentRefund() {
  yield takeLatest(
    refundTransactionActions.APPROVE_PENDING_REFUND_REQUEST,
    approvePendingPaymentRefund
  );
}

function* watchGetDeletePendingRefund() {
  yield takeLatest(
    refundTransactionActions.DELETE_A_PENDING_REFUND_REQUEST,
    deletePendingRefund
  );
}

export default [
  fork(watchSearchRefundTransaction),
  fork(watchFetchRefundedTransactions),
  fork(watchRequestPaymentRefund),
  fork(watchGetPendingPaymentRefunds),
  fork(watchGetDeletePendingRefund),
  fork(watchApprovePendingPaymentRefund),
];
