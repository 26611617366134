const retakerPolicyActions = {
  GET_RETAKER_POLICIES_REQUEST: 'GET_RETAKER_POLICIES_REQUEST',
  GET_RETAKER_POLICIES_SUCCESS: 'GET_RETAKER_POLICIES_SUCCESS',
  GET_RETAKER_POLICIES_ERROR: 'GET_RETAKER_POLICIES_ERROR',

  CREATE_RETAKER_POLICY_REQUEST: 'CREATE_RETAKER_POLICY_REQUEST',
  CREATE_RETAKER_POLICY_SUCCESS: 'CREATE_RETAKER_POLICY_SUCCESS',
  CREATE_RETAKER_POLICY_ERROR: 'CREATE_RETAKER_POLICY_ERROR',

  UPDATE_RETAKER_POLICY_REQUEST: 'UPDATE_RETAKER_POLICY_REQUEST',
  UPDATE_RETAKER_POLICY_SUCCESS: 'UPDATE_RETAKER_POLICY_SUCCESS',
  UPDATE_RETAKER_POLICY_ERROR: 'UPDATE_RETAKER_POLICY_ERROR',

  ADD_RETAKER_POLICY_FEES_ELEMENTS_REQUEST:
    'ADD_RETAKER_POLICY_FEES_ELEMENTS_REQUEST',
  ADD_RETAKER_POLICY_FEES_ELEMENTS_SUCCESS:
    'ADD_RETAKER_POLICY_FEES_ELEMENTS_SUCCESS',
  ADD_RETAKER_POLICY_FEES_ELEMENTS_ERROR:
    'ADD_RETAKER_POLICY_FEES_ELEMENTS_ERROR',

  DELETE_RETAKER_POLICY_REQUEST: 'DELETE_RETAKER_POLICY_REQUEST',
  DELETE_RETAKER_POLICY_SUCCESS: 'DELETE_RETAKER_POLICY_SUCCESS',
  DELETE_RETAKER_POLICY_ERROR: 'DELETE_RETAKER_POLICY_ERROR',

  DELETE_RETAKER_POLICY_ELEMENT_REQUEST:
    'DELETE_RETAKER_POLICY_ELEMENT_REQUEST',
  DELETE_RETAKER_POLICY_ELEMENT_SUCCESS:
    'DELETE_RETAKER_POLICY_ELEMENT_SUCCESS',
  DELETE_RETAKER_POLICY_ELEMENT_ERROR: 'DELETE_RETAKER_POLICY_ELEMENT_ERROR',

  SET_RETAKE_POLICY_TO_EDIT: 'SET_RETAKE_POLICY_TO_EDIT',

  getRetakerPolicies: (params) => ({
    type: retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    params,
  }),

  createRetakerPolicy: (data) => ({
    type: retakerPolicyActions.CREATE_RETAKER_POLICY_REQUEST,
    data,
  }),

  updateRetakerPolicy: (retakerPolicyId, data) => ({
    type: retakerPolicyActions.UPDATE_RETAKER_POLICY_REQUEST,
    data,
    retakerPolicyId,
  }),

  addRetakerPolicyElement: (retakerPolicyId, data) => ({
    type: retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_REQUEST,
    data,
    retakerPolicyId,
  }),

  deleteRetakerPolicy: (retakerPolicyId) => ({
    type: retakerPolicyActions.DELETE_RETAKER_POLICY_REQUEST,
    retakerPolicyId,
  }),

  deleteRetakerPolicyElement: (retakerPolicyId, elementsToDelete) => ({
    type: retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_REQUEST,
    retakerPolicyId,
    elementsToDelete,
  }),

  setPolicyToEdit: (retakerPolicy) => ({
    type: retakerPolicyActions.SET_RETAKE_POLICY_TO_EDIT,
    retakerPolicy,
  }),
};

export default retakerPolicyActions;
