import { applicationHistoryActions } from '../../actions';
import initialState from '../../initialState';

function applicationHistory(state = initialState.applicationHistory, actions) {
  switch (actions.type) {
    case applicationHistoryActions.GET_ADMISSION_HISTORY_REQUEST:
      return {
        ...state,
        applicationHistoryError: {},
        createdApplicationHistory: {},
        loading: true,
      };
    case applicationHistoryActions.GET_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        admissionHistories: actions.data,
        applicationHistoryError: {},
        loading: false,
      };
    case applicationHistoryActions.GET_ADMISSION_HISTORY_ERROR:
      return {
        ...state,
        applicationHistoryError: actions.error,
        loading: false,
      };
    case applicationHistoryActions.GET_SINGLE_ADMISSION_HISTORY_REQUEST:
      return {
        ...state,
        applicationHistoryError: {},
        createdApplicationHistory: {},
        fetching: true,
      };
    case applicationHistoryActions.GET_SINGLE_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        applicationHistory: actions.data,
        applicationHistoryError: {},
        fetching: false,
      };
    case applicationHistoryActions.GET_SINGLE_ADMISSION_HISTORY_ERROR:
      return {
        ...state,
        applicationHistoryError: actions.error,
        fetching: false,
      };

    case applicationHistoryActions.CREATE_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        creating: false,
        createdApplicationHistory: actions.data,
      };
    case applicationHistoryActions.CREATE_ADMISSION_HISTORY_ERROR:
      return {
        ...state,
        creating: false,
        applicationHistoryError: actions.error,
      };

    case applicationHistoryActions.DELETE_ADMISSION_HISTORY_REQUEST:
      return {
        ...state,
        deletingApplicationHistory: true,
        deleteApplicationHistoryError: {},
      };
    case applicationHistoryActions.DELETE_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        deletingApplicationHistory: false,
        deleteApplicationHistorySuccess: actions.data,
      };
    case applicationHistoryActions.DELETE_ADMISSION_HISTORY_ERROR:
      return {
        ...state,
        deletingApplicationHistory: false,
        deleteApplicationHistoryError: actions.error,
      };

    case applicationHistoryActions.UPDATE_ADMISSION_HISTORY_REQUEST:
      return {
        ...state,
        updating: true,
        applicationHistoryError: {},
        createdApplicationHistory: {},
      };
    case applicationHistoryActions.UPDATE_ADMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        updating: false,
        createdApplicationHistory: actions.data,
      };
    case applicationHistoryActions.UPDATE_ADMISSION_HISTORY_ERROR:
      return {
        ...state,
        updating: false,
        applicationHistoryError: actions.error,
      };

    default:
      return state;
  }
}

export default applicationHistory;
