import React from 'react';
import PropTypes from 'prop-types';
import { SyncOutlined } from '@ant-design/icons';

function PageLoader({ message }) {
  return (
    <div className="vh-100 text-center bg-light d-flex m-0 p-4">
      <div className="align-middle my-auto mx-auto">
        <SyncOutlined style={{ fontSize: 64 }} spin className="text-primary" />
        <div className="fw-bold text-uppercase text-sm text-primary mt-1">
          {message}
        </div>
      </div>
    </div>
  );
}

PageLoader.defaultProps = {
  message: 'Please wait while we set you up...',
};

PageLoader.propTypes = {
  message: PropTypes.string,
};

export default PageLoader;
