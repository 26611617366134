const admissionFeesPolicyActions = {
  GET_ADMISSION_FEES_POLICIES_REQUEST: 'GET_ADMISSION_FEES_POLICIES_REQUEST',
  GET_ADMISSION_FEES_POLICIES_SUCCESS: 'GET_ADMISSION_FEES_POLICIES_SUCCESS',
  GET_ADMISSION_FEES_POLICIES_ERROR: 'GET_ADMISSION_FEES_POLICIES_ERROR',

  CREATE_ADMISSION_FEES_POLICY_REQUEST: 'CREATE_ADMISSION_FEES_POLICY_REQUEST',
  CREATE_ADMISSION_FEES_POLICY_SUCCESS: 'CREATE_ADMISSION_FEES_POLICY_SUCCESS',
  CREATE_ADMISSION_FEES_POLICY_ERROR: 'CREATE_ADMISSION_FEES_POLICY_ERROR',

  DELETE_ADMISSION_FEES_POLICY_REQUEST: 'DELETE_ADMISSION_FEES_POLICY_REQUEST',
  DELETE_ADMISSION_FEES_POLICY_SUCCESS: 'DELETE_ADMISSION_FEES_POLICY_SUCCESS',
  DELETE_ADMISSION_FEES_POLICY_ERROR: 'DELETE_ADMISSION_FEES_POLICY_ERROR',

  UPDATE_ADMISSION_FEES_POLICY_REQUEST: 'UPDATE_ADMISSION_FEES_POLICY_REQUEST',
  UPDATE_ADMISSION_FEES_POLICY_SUCCESS: 'UPDATE_ADMISSION_FEES_POLICY_SUCCESS',
  UPDATE_ADMISSION_FEES_POLICY_ERROR: 'UPDATE_ADMISSION_FEES_POLICY_ERROR',

  SET_SELECTED_ADMISSION_POLICY: 'SET_SELECTED_ADMISSION_POLICY',
  SET_ADMISSION_FEES_POLICY_TO_EDIT: 'SET_ADMISSION_FEES_POLICY_TO_EDIT',

  getAdmissionFeesPolicies: () => ({
    type: admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_REQUEST,
  }),

  createAdmissionFeesPolicy: (data) => ({
    type: admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_REQUEST,
    data,
  }),

  setSelectedPolicy: (selectedPolicy) => ({
    type: admissionFeesPolicyActions.SET_SELECTED_ADMISSION_POLICY,
    selectedPolicy,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: admissionFeesPolicyActions.SET_ADMISSION_FEES_POLICY_TO_EDIT,
    policyToEdit,
  }),

  deleteAdmissionFeesPolicy: (policyId) => ({
    type: admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_REQUEST,
    policyId,
  }),

  updateAdmissionFeesPolicy: (id, data) => ({
    type: admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_REQUEST,
    data,
    id,
  }),
};

export default admissionFeesPolicyActions;
