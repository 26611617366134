const feesApprovalsActions = {
  GET_PENDING_FEES_REQUEST: 'GET_PENDING_FEES_REQUEST',
  GET_PENDING_FEES_SUCCESS: 'GET_PENDING_FEES_SUCCESS',
  GET_PENDING_FEES_ERROR: 'GET_PENDING_FEES_ERROR',

  GET_PENDING_OTHER_FEES_REQUEST: 'GET_PENDING_OTHER_FEES_REQUEST',
  GET_PENDING_OTHER_FEES_SUCCESS: 'GET_PENDING_OTHER_FEES_SUCCESS',
  GET_PENDING_OTHER_FEES_ERROR: 'GET_PENDING_OTHER_FEES_ERROR',

  GET_PENDING_TUITION_FEES_REQUEST: 'GET_PENDING_TUITION_FEES_REQUEST',
  GET_PENDING_TUITION_FEES_SUCCESS: 'GET_PENDING_TUITION_FEES_SUCCESS',
  GET_PENDING_TUITION_FEES_ERROR: 'GET_PENDING_TUITION_FEES_ERROR',

  APPROVE_PENDING_FEES_REQUEST: 'APPROVE_PENDING_FEES_REQUEST',
  APPROVE_PENDING_FEES_SUCCESS: 'APPROVE_PENDING_FEES_SUCCESS',
  APPROVE_PENDING_FEES_ERROR: 'APPROVE_PENDING_FEES_ERROR',

  GET_PENDING_FEES_WAIVERS_REQUEST: 'GET_PENDING_FEES_WAIVERS_REQUEST',
  GET_PENDING_FEES_WAIVERS_SUCCESS: 'GET_PENDING_FEES_WAIVERS_SUCCESS',
  GET_PENDING_FEES_WAIVERS_ERROR: 'GET_PENDING_FEES_WAIVERS_ERROR',

  GET_PENDING_STUDENT_FEES_WAIVERS_REQUEST:
    'GET_PENDING_STUDENT_FEES_WAIVERS_REQUEST',
  GET_PENDING_STUDENT_FEES_WAIVERS_SUCCESS:
    'GET_PENDING_STUDENT_FEES_WAIVERS_SUCCESS',
  GET_PENDING_STUDENT_FEES_WAIVERS_ERROR:
    'GET_PENDING_STUDENT_FEES_WAIVERS_ERROR',

  APPROVE_PENDING_STUDENT_FEES_WAIVERS_REQUEST:
    'APPROVE_PENDING_STUDENT_FEES_WAIVERS_REQUEST',
  APPROVE_PENDING_STUDENT_FEES_WAIVERS_SUCCESS:
    'APPROVE_PENDING_STUDENT_FEES_WAIVERS_SUCCESS',
  APPROVE_PENDING_STUDENT_FEES_WAIVERS_ERROR:
    'APPROVE_PENDING_STUDENT_FEES_WAIVERS_ERROR',

  SET_PENDING_FEES_TO_APPROVE: 'SET_PENDING_FEES_TO_APPROVE',

  getPendingFees: (feesType) => ({
    type: feesApprovalsActions.GET_PENDING_FEES_REQUEST,
    feesType,
  }),

  getPendingOtherFeesAmounts: (feesType, context) => ({
    type: feesApprovalsActions.GET_PENDING_OTHER_FEES_REQUEST,
    feesType,
    context,
  }),

  getPendingFeesWaivers: (feesType, context) => ({
    type: feesApprovalsActions.GET_PENDING_FEES_WAIVERS_REQUEST,
    feesType,
    context,
  }),

  getPendingStudentFeesWaivers: () => ({
    type: feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_REQUEST,
  }),

  approvePendingStudentFeesWaivers: (data) => ({
    type: feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_REQUEST,
    data,
  }),

  getPendingTuitionFees: (feesType, context) => ({
    type: feesApprovalsActions.GET_PENDING_TUITION_FEES_REQUEST,
    feesType,
    context,
  }),

  approvePendingFees: (data, feesType, context) => ({
    type: feesApprovalsActions.APPROVE_PENDING_FEES_REQUEST,
    data,
    feesType,
    context,
  }),

  setPendingFeesToApprove: (data) => ({
    type: feesApprovalsActions.SET_PENDING_FEES_TO_APPROVE,
    data,
  }),
};

export default feesApprovalsActions;
