const waiverDiscountsActions = {
  GET_WAIVER_DISCOUNTS_REQUEST: 'GET_WAIVER_DISCOUNTS_REQUEST',
  GET_WAIVER_DISCOUNTS_SUCCESS: 'GET_WAIVER_DISCOUNTS_SUCCESS',
  GET_WAIVER_DISCOUNTS_ERROR: 'GET_WAIVER_DISCOUNTS_ERROR',

  CREATE_WAIVER_DISCOUNTS_REQUEST: 'CREATE_WAIVER_DISCOUNTS_REQUEST',
  CREATE_WAIVER_DISCOUNTS_SUCCESS: 'CREATE_WAIVER_DISCOUNTS_SUCCESS',
  CREATE_WAIVER_DISCOUNTS_ERROR: 'CREATE_WAIVER_DISCOUNTS_ERROR',

  ADD_WAIVER_ELEMENTS_REQUEST: 'ADD_WAIVER_ELEMENTS_REQUEST',
  ADD_WAIVER_ELEMENTS_SUCCESS: 'ADD_WAIVER_ELEMENTS_SUCCESS',
  ADD_WAIVER_ELEMENTS_ERROR: 'ADD_WAIVER_ELEMENTS_ERROR',

  UPDATE_WAIVER_DISCOUNTS_REQUEST: 'UPDATE_WAIVER_DISCOUNTS_REQUEST',
  UPDATE_WAIVER_DISCOUNTS_SUCCESS: 'UPDATE_WAIVER_DISCOUNTS_SUCCESS',
  UPDATE_WAIVER_DISCOUNTS_ERROR: 'UPDATE_WAIVER_DISCOUNTS_ERROR',

  EDIT_WAIVER_DISCOUNTS_REQUEST: 'EDIT_WAIVER_DISCOUNTS_REQUEST',
  EDIT_WAIVER_DISCOUNTS_SUCCESS: 'EDIT_WAIVER_DISCOUNTS_SUCCESS',
  EDIT_WAIVER_DISCOUNTS_ERROR: 'EDIT_WAIVER_DISCOUNTS_ERROR',

  DELETE_WAIVER_DISCOUNTS_REQUEST: 'DELETE_WAIVER_DISCOUNTS_REQUEST',
  DELETE_WAIVER_DISCOUNTS_SUCCESS: 'DELETE_WAIVER_DISCOUNTS_SUCCESS',
  DELETE_WAIVER_DISCOUNTS_ERROR: 'DELETE_WAIVER_DISCOUNTS_ERROR',

  SET_LOADED_WAIVERS: 'SET_LOADED_WAIVERS',

  SET_WAIVER_DISCOUNTS_CONTEXT: 'SET_WAIVER_DISCOUNTS_CONTEXT',

  getWaiverDiscounts: (context) => ({
    type: waiverDiscountsActions.GET_WAIVER_DISCOUNTS_REQUEST,
    context,
  }),

  editWaiverDiscount: (dataId, data, context) => ({
    type: waiverDiscountsActions.EDIT_WAIVER_DISCOUNTS_REQUEST,
    dataId,
    data,
    context,
  }),

  setLoadedWaivers: (data) => ({
    type: waiverDiscountsActions.SET_LOADED_WAIVERS,
    data,
  }),

  setWaiverDiscountsContext: (context) => ({
    type: waiverDiscountsActions.SET_WAIVER_DISCOUNTS_CONTEXT,
    context,
  }),

  createWaiverDiscount: (data, context) => ({
    type: waiverDiscountsActions.CREATE_WAIVER_DISCOUNTS_REQUEST,
    data,
    context,
  }),

  addWaiverDiscountElements: (contextId, data, context) => ({
    type: waiverDiscountsActions.ADD_WAIVER_ELEMENTS_REQUEST,
    contextId,
    data,
    context,
  }),

  deleteWaiverDiscount: (id, context) => ({
    type: waiverDiscountsActions.DELETE_WAIVER_DISCOUNTS_REQUEST,
    id,
    context,
  }),

  updateWaiverDiscount: (id, data, context) => ({
    type: waiverDiscountsActions.UPDATE_WAIVER_DISCOUNTS_REQUEST,
    id,
    data,
    context,
  }),
};
export default waiverDiscountsActions;
