import { runningAdmissionCampusActions } from '../../actions';
import initialState from '../../initialState';

function runningAdmissionProgramme(
  state = initialState.runningAdmissionCampus,
  actions
) {
  switch (actions.type) {
    case runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_SUCCESS:
      return {
        ...state,
        runningAdmissionProgrammes: actions.data.runningAdmissionProgrammes,
        loading: false,
      };
    case runningAdmissionCampusActions.GET_RUNNING_ADMISSION_CAMPUSES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case runningAdmissionCampusActions.CREATE_RUNNING_ADMISSION_CAMPUS_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        created: {},
      };
    case runningAdmissionCampusActions.CREATE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case runningAdmissionCampusActions.CREATE_RUNNING_ADMISSION_CAMPUS_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case runningAdmissionCampusActions.EDIT_SPECIAL_REMARKS_REQUEST:
      return {
        ...state,
        updatingRemarks: true,
        updateRemarksError: {},
        updateRemarksResponse: {},
      };
    case runningAdmissionCampusActions.EDIT_SPECIAL_REMARKS_SUCCESS:
      return {
        ...state,
        updatingRemarks: false,
        updateRemarksResponse: actions.data,
      };
    case runningAdmissionCampusActions.EDIT_SPECIAL_REMARKS_ERROR:
      return {
        ...state,
        updatingRemarks: false,
        updateRemarksError: actions.error,
      };

    case runningAdmissionCampusActions.UPDATE_RUNNING_ADMISSION_CAMPUS_REQUEST:
      return {
        ...state,
        updatingRunningAdmissionCampus: true,
        updateRunningAdmissionCampusError: {},
      };
    case runningAdmissionCampusActions.UPDATE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
      return {
        ...state,
        updatingRunningAdmissionCampus: false,
        updatedRunningAdmissionCampus: actions.data.runningAdmissionProgramme,
      };
    case runningAdmissionCampusActions.UPDATE_RUNNING_ADMISSION_CAMPUS_ERROR:
      return {
        ...state,
        updatingRunningAdmissionCampus: false,
        updateRunningAdmissionCampusError: actions.error,
      };

    case runningAdmissionCampusActions.UPDATE_CAPACITY_SETTING_REQUEST:
      return {
        ...state,
        updatingCapacitySiting: true,
        updateCapacitySitingError: {},
        updatedCapacitySitting: {},
      };
    case runningAdmissionCampusActions.UPDATE_CAPACITY_SETTING_SUCCESS:
      return {
        ...state,
        updatingCapacitySiting: false,
        updatedCapacitySitting: actions.data,
      };
    case runningAdmissionCampusActions.UPDATE_CAPACITY_SETTING_ERROR:
      return {
        ...state,
        updatingCapacitySiting: false,
        updateCapacitySitingError: actions.error,
      };

    case runningAdmissionCampusActions.CREATE_CAPACITY_SETTING_REQUEST:
      return {
        ...state,
        creatingCapacitySitting: true,
        createCapacitySitingError: {},
        createCapacitySitingSuccess: {},
      };
    case runningAdmissionCampusActions.CREATE_CAPACITY_SETTING_SUCCESS:
      return {
        ...state,
        creatingCapacitySitting: false,
        createdCapacitySiting: actions.data,
      };
    case runningAdmissionCampusActions.CREATE_CAPACITY_SETTING_ERROR:
      return {
        ...state,
        creatingCapacitySitting: false,
        createCapacitySitingError: actions.error,
      };

    case runningAdmissionCampusActions.DELETE_CAPACITY_SETTING_REQUEST:
      return {
        ...state,
        deletingCapacitySiting: true,
        deleteCapacitySitingError: {},
        deleteCapacitySitingSuccess: {},
      };
    case runningAdmissionCampusActions.DELETE_CAPACITY_SETTING_SUCCESS:
      return {
        ...state,
        deletingCapacitySiting: false,
        deleteCapacitySitingSuccess: actions.data.runningAdmissionProgramme,
      };
    case runningAdmissionCampusActions.DELETE_CAPACITY_SETTING_ERROR:
      return {
        ...state,
        deletingCapacitySiting: false,
        deleteCapacitySitingError: actions.error,
      };

    case runningAdmissionCampusActions.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_REQUEST:
      return {
        ...state,
        gettingOne: true,
        getOneError: {},
        runningAdmissionCampus: {},
      };
    case runningAdmissionCampusActions.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        runningAdmissionCampus: actions.data.runningAdmissionCampus,
      };
    case runningAdmissionCampusActions.GET_SINGLE_RUNNING_ADMISSION_CAMPUS_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case runningAdmissionCampusActions.DELETE_RUNNING_ADMISSION_CAMPUS_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
        deleteResponse: {},
      };
    case runningAdmissionCampusActions.DELETE_RUNNING_ADMISSION_CAMPUS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case runningAdmissionCampusActions.DELETE_RUNNING_ADMISSION_CAMPUS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case runningAdmissionCampusActions.SET_ALIAS_DETAILS:
      return {
        ...state,
        aliasDetails: actions.data,
      };

    default:
      return state;
  }
}

export default runningAdmissionProgramme;
