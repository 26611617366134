import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { receivableActions } from '../../actions';

function* getReceivableData(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/receivables',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: receivableActions.GET_RECEIVABLES_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: receivableActions.GET_RECEIVABLES_ERROR,
      error: error.data,
    });
  }
}

function* getReceivableReport(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/reports/report-by-receivable',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: receivableActions.GET_RECEIVABLE_REPORT_SUCCESS,
      data: response.result.filtered,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: receivableActions.GET_RECEIVABLE_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* createReceivable(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/receivables',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: receivableActions.CREATE_RECEIVABLE_SUCCESS,
      data: response,
    });
    yield put({
      type: receivableActions.GET_RECEIVABLES_REQUEST,
    });
    yield put({
      type: receivableActions.SHOW_CREATE_RECEIVABLE_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: receivableActions.CREATE_RECEIVABLE_ERROR,
      error: error.data,
    });
  }
}

function* getSingleReceivable(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/receivables/${actions.receivableId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: receivableActions.GET_SINGLE_RECEIVABLE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: receivableActions.GET_SINGLE_RECEIVABLE_ERROR,
      error: error.data,
    });
  }
}

function* updateReceivable(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/receivables/${actions.receivableId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: receivableActions.UPDATE_RECEIVABLE_SUCCESS,
      data: response,
    });
    yield put({
      type: receivableActions.GET_RECEIVABLES_REQUEST,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: receivableActions.UPDATE_RECEIVABLE_ERROR,
      error: error.data,
    });
  }
}

function* deleteReceivable(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/receivables/${actions.receivableId}`,
      method: 'DELETE',
    });
    yield put({
      type: receivableActions.DELETE_RECEIVABLE_SUCCESS,
      data: response,
    });
    yield put({
      type: receivableActions.GET_RECEIVABLES_REQUEST,
    });
    yield put({
      type: receivableActions.SHOW_DELETE_RECEIVABLE_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: receivableActions.DELETE_RECEIVABLE_ERROR,
      error: error.data,
    });
  }
}

function* downloadReceivableReport(actions) {
  try {
    yield axios({
      url: `/universal-payments/reports/download-receivable-report`,
      method: 'GET',
      params: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DETAILED-RECEIVABLES-REPORT.xlsx`
      );
    });
    yield put({
      type: receivableActions.DOWNLOAD_RECEIVABLE_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: receivableActions.DOWNLOAD_RECEIVABLE_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetReceivableData() {
  yield takeLatest(
    receivableActions.GET_RECEIVABLES_REQUEST,
    getReceivableData
  );
}

function* watchGetReceivableReport() {
  yield takeLatest(
    receivableActions.GET_RECEIVABLE_REPORT_REQUEST,
    getReceivableReport
  );
}

function* watchCreateReceivable() {
  yield takeLatest(
    receivableActions.CREATE_RECEIVABLE_REQUEST,
    createReceivable
  );
}

function* watchGetSingleReceivable() {
  yield takeLatest(
    receivableActions.GET_SINGLE_RECEIVABLE_REQUEST,
    getSingleReceivable
  );
}

function* watchUpdateReceivable() {
  yield takeLatest(
    receivableActions.UPDATE_RECEIVABLE_REQUEST,
    updateReceivable
  );
}

function* watchDeleteReceivable() {
  yield takeLatest(
    receivableActions.DELETE_RECEIVABLE_REQUEST,
    deleteReceivable
  );
}

function* watchDownloadReceivableReport() {
  yield takeLatest(
    receivableActions.DOWNLOAD_RECEIVABLE_REPORT_REQUEST,
    downloadReceivableReport
  );
}

export default [
  fork(watchCreateReceivable),
  fork(watchGetReceivableData),
  fork(watchUpdateReceivable),
  fork(watchGetSingleReceivable),
  fork(watchDeleteReceivable),
  fork(watchGetReceivableReport),
  fork(watchDownloadReceivableReport),
];
