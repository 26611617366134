import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { pendingDirectPostActions } from '../../actions';

function* getPendingDirectPosts() {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/pending-direct-posts`,
      method: 'GET',
    });
    yield put({
      type: pendingDirectPostActions.GET_PENDING_DIRECT_POST_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pendingDirectPostActions.GET_PENDING_DIRECT_POST_ERROR,
      error: error.data,
    });
  }
}
function* getDirectPostsReport(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/direct-posts-report`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: pendingDirectPostActions.GET_DIRECT_POSTS_REPORT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pendingDirectPostActions.GET_DIRECT_POSTS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingDirectPost(actions) {
  try {
    const response = yield axios({
      url: '/registration/payment-transactions/approve-pending-direct-posts',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: pendingDirectPostActions.APPROVE_PENDING_DIRECT_POST_SUCCESS,
      data: response.data,
    });
    // yield put({
    //   type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
    //   studentId: actions.studentId,
    //   studentProgrammeId: actions.studentProgrammeId,
    // });
    yield put({
      type: pendingDirectPostActions.GET_PENDING_DIRECT_POST_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pendingDirectPostActions.APPROVE_PENDING_DIRECT_POST_ERROR,
      error: error.data,
    });
  }
}

function* deletePendingDirectPost(actions) {
  try {
    const response = yield axios({
      url: '/registration/payment-transactions/delete-pending-direct-posts',
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: pendingDirectPostActions.DELETE_PENDING_DIRECT_POST_SUCCESS,
      data: response.data,
    });
    yield put({
      type: pendingDirectPostActions.GET_PENDING_DIRECT_POST_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: pendingDirectPostActions.DELETE_PENDING_DIRECT_POST_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPendingDirectPosts() {
  yield takeLatest(
    pendingDirectPostActions.GET_PENDING_DIRECT_POST_REQUEST,
    getPendingDirectPosts
  );
}

function* watchGetDirectPostsReport() {
  yield takeLatest(
    pendingDirectPostActions.GET_DIRECT_POSTS_REPORT_REQUEST,
    getDirectPostsReport
  );
}

function* watchApprovePendingDirectPost() {
  yield takeLatest(
    pendingDirectPostActions.APPROVE_PENDING_DIRECT_POST_REQUEST,
    approvePendingDirectPost
  );
}

function* watchDeletePendingDirectPost() {
  yield takeLatest(
    pendingDirectPostActions.DELETE_PENDING_DIRECT_POST_REQUEST,
    deletePendingDirectPost
  );
}

export default [
  fork(watchGetPendingDirectPosts),
  fork(watchGetDirectPostsReport),
  fork(watchDeletePendingDirectPost),
  fork(watchApprovePendingDirectPost),
];
