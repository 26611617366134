const semesterEvent = {
  GET_STUDENT_SEMESTER_EVENTS_REQUEST: 'GET_STUDENT_SEMESTER_EVENTS_REQUEST',
  GET_STUDENT_SEMESTER_EVENTS_SUCCESS: 'GET_STUDENT_SEMESTER_EVENTS_SUCCESS',
  GET_STUDENT_SEMESTER_EVENTS_ERROR: 'GET_STUDENT_SEMESTER_EVENTS_ERROR',

  getStudentSemesterEvent: (studentProgrammeId) => ({
    type: semesterEvent.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
    studentProgrammeId,
  }),
};
export default semesterEvent;
