import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { facultyActions } from '../../actions';

function* getFaculties(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/faculties',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: facultyActions.GET_FACULTIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: facultyActions.GET_FACULTIES_ERROR,
      error: error.data,
    });
  }
}

function* createFaculties(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/faculties',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: facultyActions.CREATE_FACULTY_SUCCESS,
      data: response,
    });
    yield put({
      type: facultyActions.GET_FACULTIES_REQUEST,
      data: response,
    });
    yield put({
      type: 'SHOW_FACULTY_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: facultyActions.CREATE_FACULTY_ERROR,
      error: error.data,
    });
  }
}

function* deleteFaculty(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/faculties/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: facultyActions.DELETE_FACULTY_SUCCESS,
      data: response,
    });
    yield put({
      type: facultyActions.GET_FACULTIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: facultyActions.DELETE_FACULTY_ERROR,
      error: error.data,
    });
  }
}
function* updateFaculty(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/faculties/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: facultyActions.UPDATE_FACULTY_SUCCESS,
      data: response,
    });
    yield put({
      type: facultyActions.GET_FACULTIES_REQUEST,
    });
    yield put({
      type: 'SHOW_FACULTY_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: facultyActions.UPDATE_FACULTY_ERROR,
      error: error.data,
    });
  }
}

function* downloadFacultyTemplate() {
  try {
    yield axios({
      url: '/programme-mgt/faculties/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SCHOOL-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: facultyActions.DOWNLOAD_FACULTY_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: facultyActions.DOWNLOAD_FACULTY_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadFacultyTemplate(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/faculties/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: facultyActions.UPLOAD_FACULTY_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({ type: facultyActions.GET_FACULTIES_REQUEST });
    yield put({ type: 'SHOW_UPLOAD_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: facultyActions.UPLOAD_FACULTY_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFaculties() {
  yield takeLatest(facultyActions.GET_FACULTIES_REQUEST, getFaculties);
}

function* watchCreateFaculties() {
  yield takeLatest(facultyActions.CREATE_FACULTY_REQUEST, createFaculties);
}

function* watchDeleteFaculty() {
  yield takeLatest(facultyActions.DELETE_FACULTY_REQUEST, deleteFaculty);
}
function* watchUpdateFaculty() {
  yield takeLatest(facultyActions.UPDATE_FACULTY_REQUEST, updateFaculty);
}
function* watchDownloadFacultyTemplate() {
  yield takeLatest(
    facultyActions.DOWNLOAD_FACULTY_TEMPLATE_REQUEST,
    downloadFacultyTemplate
  );
}
function* watchUploadFacultyTemplate() {
  yield takeLatest(
    facultyActions.UPLOAD_FACULTY_TEMPLATE_REQUEST,
    uploadFacultyTemplate
  );
}

export default [
  fork(watchGetFaculties),
  fork(watchCreateFaculties),
  fork(watchDeleteFaculty),
  fork(watchUpdateFaculty),
  fork(watchDownloadFacultyTemplate),
  fork(watchUploadFacultyTemplate),
];
