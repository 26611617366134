import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { feesWaiversActions } from '../../actions';

function* getFeesWaivers() {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-waivers',
      method: 'GET',
    });
    yield put({
      type: feesWaiversActions.GET_FEES_WAIVERS_SUCCESS,
      data: response.feesWaivers,
    });
  } catch (error) {
    yield put({
      type: feesWaiversActions.GET_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}

function* createFeesWaivers(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-waivers',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: feesWaiversActions.CREATE_FEES_WAIVERS_SUCCESS,
      data: response,
    });
    yield put({
      type: feesWaiversActions.GET_FEES_WAIVERS_REQUEST,
    });
    yield put({
      type: 'SHOW_CREATE_FEES_WAIVERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesWaiversActions.CREATE_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}

function* deleteFeesWaivers(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waivers/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: feesWaiversActions.DELETE_FEES_WAIVERS_SUCCESS,
      data: response,
    });
    yield put({
      type: feesWaiversActions.GET_FEES_WAIVERS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: feesWaiversActions.DELETE_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}
function* updateFeesWaivers(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waivers/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: feesWaiversActions.UPDATE_FEES_WAIVERS_SUCCESS,
      data: response,
    });
    yield put({
      type: feesWaiversActions.GET_FEES_WAIVERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: feesWaiversActions.UPDATE_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFeesWaivers() {
  yield takeLatest(feesWaiversActions.GET_FEES_WAIVERS_REQUEST, getFeesWaivers);
}

function* watchCreateFeesWaivers() {
  yield takeLatest(
    feesWaiversActions.CREATE_FEES_WAIVERS_REQUEST,
    createFeesWaivers
  );
}

function* watchDeleteFeesWaivers() {
  yield takeLatest(
    feesWaiversActions.DELETE_FEES_WAIVERS_REQUEST,
    deleteFeesWaivers
  );
}
function* watchUpdateFeesWaivers() {
  yield takeLatest(
    feesWaiversActions.UPDATE_FEES_WAIVERS_REQUEST,
    updateFeesWaivers
  );
}

export default [
  fork(watchGetFeesWaivers),
  fork(watchCreateFeesWaivers),
  fork(watchDeleteFeesWaivers),
  fork(watchUpdateFeesWaivers),
];
