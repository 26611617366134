import { itsDataActions } from '../../actions';
import initialState from '../../initialState';

const itsSearchData = (state = initialState.itsData, actions) => {
  switch (actions.type) {
    case itsDataActions.SEARCH_ITS_DATA_REQUEST:
      return {
        ...state,
        searching: true,
        searchError: {},
      };

    case itsDataActions.SEARCH_ITS_DATA_SUCCESS:
      return {
        ...state,
        searching: false,
        searchResponse: actions.data,
      };

    case itsDataActions.SEARCH_ITS_DATA_ERROR:
      return {
        ...state,
        searching: false,
        searchError: actions.data,
      };

    case itsDataActions.DOWNLOAD_ITS_STATEMENT_REQUEST:
      return {
        ...state,
        downloading: true,
        downloadSuccess: {},
        downloadError: {},
      };
    case itsDataActions.DOWNLOAD_ITS_STATEMENT_SUCCESS:
      return {
        ...state,
        downloading: false,
        downloadSuccess: actions.data,
      };
    case itsDataActions.DOWNLOAD_ITS_STATEMENT_ERROR:
      return {
        ...state,
        downloading: false,
        downloadError: actions.error,
      };

    default:
      return state;
  }
};

export default itsSearchData;
