import { financialTransactionActions } from '../../actions';
import initialState from '../../initialState';

const financialTransaction = (
  state = initialState.financialTransactions,
  actions
) => {
  switch (actions.type) {
    case financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_SUCCESS: {
      let { studentTransactions } = state;
      const findStudentIndex = studentTransactions?.findIndex(
        (invoice) => invoice.studentId === actions.studentId
      );

      if (findStudentIndex !== -1) {
        studentTransactions[findStudentIndex] = {
          transactions:
            actions.context === 'all'
              ? {
                  ...studentTransactions[findStudentIndex].transactions,
                  ...actions.data,
                }
              : actions.data,
          studentId: actions.studentId,
        };
      } else {
        studentTransactions = [
          ...studentTransactions,
          // { transactions: actions.data, studentId: actions.studentId },
          {
            transactions:
              actions.context === 'all' ? { ...actions.data } : actions.data,
            studentId: actions.studentId,
          },
        ];
      }

      return {
        ...state,
        studentTransactions,
        loading: false,
      };
    }

    case financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case financialTransactionActions.CREATE_DIRECT_POST_REQUEST:
      return {
        ...state,
        error: {},
        creatingDirectPost: true,
      };
    case financialTransactionActions.CREATE_DIRECT_POST_SUCCESS:
      return {
        ...state,
        error: {},
        creatingDirectPost: false,
        createdDirectPost: actions.data,
      };
    case financialTransactionActions.CREATE_DIRECT_POST_ERROR:
      return {
        ...state,
        error: {},
        creatingDirectPost: false,
        createDirectPostError: actions.error,
      };

    case financialTransactionActions.UPDATE_DIRECT_POST_REQUEST:
      return {
        ...state,
        error: {},
        updating: true,
        updateError: {},
      };
    case financialTransactionActions.UPDATE_DIRECT_POST_SUCCESS:
      return {
        ...state,
        error: {},
        updating: false,
        updatedDirectPost: actions.data,
      };
    case financialTransactionActions.UPDATE_DIRECT_POST_ERROR:
      return {
        ...state,
        error: {},
        updating: false,
        updateError: actions.error,
      };

    case financialTransactionActions.UPLOAD_DIRECT_POST_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
        uploadTemplateSuccess: {},
      };
    case financialTransactionActions.UPLOAD_DIRECT_POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case financialTransactionActions.UPLOAD_DIRECT_POST_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case financialTransactionActions.DOWNLOAD_DIRECT_POST_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case financialTransactionActions.DOWNLOAD_DIRECT_POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case financialTransactionActions.DOWNLOAD_DIRECT_POST_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case financialTransactionActions.UPLOAD_BULK_BILLING_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
        uploadTemplateSuccess: {},
      };
    case financialTransactionActions.UPLOAD_BULK_BILLING_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case financialTransactionActions.UPLOAD_BULK_BILLING_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case financialTransactionActions.DOWNLOAD_BULK_BILLING_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case financialTransactionActions.DOWNLOAD_BULK_BILLING_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case financialTransactionActions.DOWNLOAD_BULK_BILLING_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case financialTransactionActions.ALLOCATE_TRANSACTION_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        allocating: true,
        allocateError: {},
      };
    case financialTransactionActions.ALLOCATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        allocating: false,
        allocateSuccess: actions.data,
      };
    case financialTransactionActions.ALLOCATE_TRANSACTION_ERROR:
      return {
        ...state,
        error: {},
        allocating: false,
        allocateError: actions.error,
      };

    case financialTransactionActions.TRANSFER_FUNDS_REQUEST:
      return {
        ...state,
        transferringFunds: true,
        transferError: {},
      };
    case financialTransactionActions.TRANSFER_FUNDS_SUCCESS:
      return {
        ...state,
        transferringFunds: false,
        transferredFunds: actions.data,
      };
    case financialTransactionActions.TRANSFER_FUNDS_ERROR:
      return {
        ...state,
        transferringFunds: false,
        transferError: actions.error,
      };

    case financialTransactionActions.GET_PENDING_FUND_TRANSFERS_REQUEST:
      return {
        ...state,
        gettingTransfers: true,
        pendingTransfersError: {},
      };
    case financialTransactionActions.GET_PENDING_FUND_TRANSFERS_SUCCESS:
      return {
        ...state,
        gettingTransfers: false,
        pendingTransfers: actions.data,
      };
    case financialTransactionActions.GET_PENDING_FUND_TRANSFERS_ERROR:
      return {
        ...state,
        gettingTransfers: false,
        pendingTransfersError: actions.error,
      };

    case financialTransactionActions.APPROVE_FUNDS_TRANSFER_REQUEST:
      return {
        ...state,
        approvingTransfer: true,
        approveTransfersError: {},
      };
    case financialTransactionActions.APPROVE_FUNDS_TRANSFER_SUCCESS:
      return {
        ...state,
        approvingTransfer: false,
        approvedTransfers: actions.data,
      };
    case financialTransactionActions.APPROVE_FUNDS_TRANSFER_ERROR:
      return {
        ...state,
        approvingTransfer: false,
        approveTransfersError: actions.error,
      };

    case financialTransactionActions.DELETE_FUNDS_TRANSFER_REQUEST:
      return {
        ...state,
        deletingFundTransfer: true,
        deleteTransfersError: {},
      };
    case financialTransactionActions.DELETE_FUNDS_TRANSFER_SUCCESS:
      return {
        ...state,
        deletingFundTransfer: false,
        deletedTransfer: actions.data,
      };
    case financialTransactionActions.DELETE_FUNDS_TRANSFER_ERROR:
      return {
        ...state,
        deletingFundTransfer: false,
        deleteTransfersError: actions.error,
      };

    default:
      return state;
  }
};
export default financialTransaction;
