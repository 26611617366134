import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  pendingExemptedInvoiceActions,
  semesterEventActions,
} from '../../actions';

function* getPendingExemptedInvoices() {
  try {
    const response = yield axios({
      url: `/registration/invoices/pending-exempted-invoices`,
      method: 'GET',
    });
    yield put({
      type: pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingExemptedInvoice(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/approve-pending-exempted-invoices',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: pendingExemptedInvoiceActions.APPROVE_PENDING_EXEMPTED_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: pendingExemptedInvoiceActions.APPROVE_PENDING_EXEMPTED_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPendingExemptedInvoices() {
  yield takeLatest(
    pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_REQUEST,
    getPendingExemptedInvoices
  );
}

function* watchApprovePendingExemptedInvoice() {
  yield takeLatest(
    pendingExemptedInvoiceActions.APPROVE_PENDING_EXEMPTED_INVOICE_REQUEST,
    approvePendingExemptedInvoice
  );
}

export default [
  fork(watchGetPendingExemptedInvoices),
  fork(watchApprovePendingExemptedInvoice),
];
