const studyLevelPolicyActions = {
  GET_STUDY_LEVEL_POLICIES_REQUEST: 'GET_STUDY_LEVEL_POLICIES_REQUEST',
  GET_STUDY_LEVEL_POLICIES_SUCCESS: 'GET_STUDY_LEVEL_POLICIES_SUCCESS',
  GET_STUDY_LEVEL_POLICIES_ERROR: 'GET_STUDY_LEVEL_POLICIES_ERROR',

  CREATE_STUDY_LEVEL_POLICY_REQUEST: 'CREATE_STUDY_LEVEL_POLICY_REQUEST',
  CREATE_STUDY_LEVEL_POLICY_SUCCESS: 'CREATE_STUDY_LEVEL_POLICY_SUCCESS',
  CREATE_STUDY_LEVEL_POLICY_ERROR: 'CREATE_STUDY_LEVEL_POLICY_ERROR',

  UPDATE_STUDY_LEVEL_POLICY_REQUEST: 'UPDATE_STUDY_LEVEL_POLICY_REQUEST',
  UPDATE_STUDY_LEVEL_POLICY_SUCCESS: 'UPDATE_STUDY_LEVEL_POLICY_SUCCESS',
  UPDATE_STUDY_LEVEL_POLICY_ERROR: 'UPDATE_STUDY_LEVEL_POLICY_ERROR',

  DELETE_STUDY_LEVEL_POLICY_REQUEST: 'DELETE_STUDY_LEVEL_POLICY_REQUEST',
  DELETE_STUDY_LEVEL_POLICY_SUCCESS: 'DELETE_STUDY_LEVEL_POLICY_SUCCESS',
  DELETE_STUDY_LEVEL_POLICY_ERROR: 'DELETE_STUDY_LEVEL_POLICY_ERROR',

  SET_STUDY_LEVEL_POLICY_TO_EDIT: 'SET_STUDY_LEVEL_POLICY_TO_EDIT',

  getStudyLevelPolicies: () => ({
    type: studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_REQUEST,
  }),

  CreatePolicy: (data) => ({
    type: studyLevelPolicyActions.CREATE_STUDY_LEVEL_POLICY_REQUEST,
    data,
  }),

  UpdatePolicy: (policyId, data) => ({
    type: studyLevelPolicyActions.UPDATE_STUDY_LEVEL_POLICY_REQUEST,
    policyId,
    data,
  }),

  deletePolicy: (policyId) => ({
    type: studyLevelPolicyActions.DELETE_STUDY_LEVEL_POLICY_REQUEST,
    policyId,
  }),

  setStudyLevelToEdit: (setStudyPolicy) => ({
    type: studyLevelPolicyActions.SET_STUDY_LEVEL_POLICY_TO_EDIT,
    setStudyPolicy,
  }),
};

export default studyLevelPolicyActions;
