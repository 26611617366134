import update from 'immutability-helper';
import pujabProgrammeActions from '../../actions/pujab-mgt/programmes';
import pujabMgtInitialState from '../../initialState/pujab-mgt';

const pujabProgrammes = (
  state = pujabMgtInitialState.pujabProgramme,
  actions
) => {
  switch (actions.type) {
    case pujabProgrammeActions.SET_SELECTED_INSTITUTION:
      return {
        ...state,
        pujabInstitution: actions.data,
      };
    case pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingProgrammes: true,
        getProgrammeError: {},
      };
    case pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_SUCCESS: {
      const { context, data } = actions;
      const { institutionProgrammes } = state;

      const findContextIndex = institutionProgrammes.findIndex(
        (item) => item.institutionId === context.institutionId
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          institutionProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          institutionProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingProgrammes: false,
      };
    }
    case pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingProgrammes: false,
        getProgrammeError: actions.error,
      };

    case pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_REQUEST:
      return {
        ...state,
        addingProgramme: true,
        addError: {},
      };
    case pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_SUCCESS:
      return {
        ...state,
        addingProgramme: false,
        addSuccess: actions.data,
      };
    case pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_ERROR:
      return {
        ...state,
        addingProgramme: false,
        addError: actions.error,
      };

    case pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_REQUEST:
      return {
        ...state,
        updatingProgramme: true,
        updateError: {},
      };
    case pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_SUCCESS:
      return {
        ...state,
        updatingProgramme: false,
        updateSuccess: actions.data,
      };
    case pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_ERROR:
      return {
        ...state,
        updatingProgramme: false,
        updateError: actions.error,
      };

    case pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_REQUEST:
      return {
        ...state,
        deletingProgramme: true,
        deleteError: {},
      };
    case pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_SUCCESS:
      return {
        ...state,
        deletingProgramme: false,
        deleteSuccess: actions.data,
      };
    case pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_ERROR:
      return {
        ...state,
        deletingProgramme: false,
        deleteError: actions.error,
      };

    case pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };
    default:
      return state;
  }
};

export default pujabProgrammes;
