import { systemProgrammeActions } from '../../actions';
import systemManager from '../../initialState/system-mgt';

const acmisProgrammes = (state = systemManager.systemProgrammes, actions) => {
  switch (actions.type) {
    case systemProgrammeActions.GET_ACMIS_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingAcmisProgrammes: true,
        getProgrammeError: {},
      };
    case systemProgrammeActions.GET_ACMIS_PROGRAMMES_SUCCESS:
      return {
        ...state,
        gettingAcmisProgrammes: false,
        acmisProgrammes: actions.data,
      };
    case systemProgrammeActions.GET_ACMIS_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingAcmisProgrammes: false,
        getProgrammeError: actions.error,
      };

    case systemProgrammeActions.GET_EMIS_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingAcmisProgrammes: true,
        getProgrammeError: {},
      };
    case systemProgrammeActions.GET_EMIS_PROGRAMMES_SUCCESS:
      return {
        ...state,
        gettingAcmisProgrammes: false,
        emisProgrammes: actions.data,
      };
    case systemProgrammeActions.GET_EMIS_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingAcmisProgrammes: false,
        getProgrammeError: actions.error,
      };

    case systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_REQUEST:
      return {
        ...state,
        gettingCategories: true,
        getCategoriesError: {},
      };
    case systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        gettingCategories: false,
        emisCourseCategories: actions.data,
      };
    case systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_ERROR:
      return {
        ...state,
        gettingCategories: false,
        getCategoriesError: actions.error,
      };

    case systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_REQUEST:
      return {
        ...state,
        submittingProgramme: true,
        submitError: {},
      };
    case systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_SUCCESS:
      return {
        ...state,
        submittingProgramme: false,
        submitSuccess: actions.data,
      };
    case systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_ERROR:
      return {
        ...state,
        submittingProgramme: false,
        submitError: actions.error,
      };

    case systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_REQUEST:
      return {
        ...state,
        updatingProgramme: true,
        updateError: {},
      };
    case systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_SUCCESS:
      return {
        ...state,
        updatingProgramme: false,
        updateSuccess: actions.data,
      };
    case systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_ERROR:
      return {
        ...state,
        updatingProgramme: false,
        updateError: actions.error,
      };

    default:
      return state;
  }
};

export default acmisProgrammes;
