const senateActions = {
  GET_SENATE_GRADUATION_LIST_REQUEST: 'GET_SENATE_GRADUATION_LIST_REQUEST',
  GET_SENATE_GRADUATION_LIST_SUCCESS: 'GET_SENATE_GRADUATION_LIST_SUCCESS',
  GET_SENATE_GRADUATION_LIST_ERROR: 'GET_SENATE_GRADUATION_LIST_ERROR',

  GRADUATE_SENATE_STUDENTS_REQUEST: 'GRADUATE_SENATE_STUDENTS_REQUEST',
  GRADUATE_SENATE_STUDENTS_SUCCESS: 'GRADUATE_SENATE_STUDENTS_SUCCESS',
  GRADUATE_SENATE_STUDENTS_ERROR: 'GRADUATE_SENATE_STUDENTS_ERROR',

  SET_SENATE_GRADUATION_CONTEXT: 'SET_SENATE_GRADUATION_CONTEXT',

  getSenateGraduationList: (params) => ({
    type: senateActions.GET_SENATE_GRADUATION_LIST_REQUEST,
    params,
  }),

  graduateStudents: (data, context) => ({
    type: senateActions.GRADUATE_SENATE_STUDENTS_REQUEST,
    context,
    data,
  }),

  setGraduationContext: (context) => ({
    type: senateActions.SET_SENATE_GRADUATION_CONTEXT,
    context,
  }),
};

export default senateActions;
