import React from 'react';
import PropTypes, { array } from 'prop-types';
import { isEmpty, replace } from 'lodash';

function ValidationErrors({ errors }) {
  return (
    <>
      {!isEmpty(errors) && (
        <div className="w-100 bg-light border p-3 ">
          <div>
            <strong className="fw-bold text-danger">
              Invalid Inputs Provided
            </strong>
          </div>
          {errors.map((error, index) => (
            <div key={error.field} className="fw-bold text-danger py-1">
              {`${index + 1}. ${replace(error.message, '_', ' ')
                .replace('_id', ' ')
                .toLocaleLowerCase()}`}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

ValidationErrors.defaultProps = {
  errors: [],
};

ValidationErrors.propTypes = {
  errors: PropTypes.oneOfType([array]),
};

export default ValidationErrors;
