const programmeVersion = {
  CREATE_PROGRAMME_VERSION_SUCCESS: 'CREATE_PROGRAMME_VERSION_SUCCESS',
  CREATE_PROGRAMME_VERSION_REQUEST: 'CREATE_PROGRAMME_VERSION_REQUEST',
  CREATE_PROGRAMME_VERSION_ERROR: 'CREATE_PROGRAMME_VERSION_ERROR',

  GET_SINGLE_PROGRAMME_VERSION_SUCCESS: 'GET_SINGLE_PROGRAMME_VERSION_SUCCESS',
  GET_SINGLE_PROGRAMME_VERSION_REQUEST: 'GET_SINGLE_PROGRAMME_VERSION_REQUEST',
  GET_SINGLE_PROGRAMME_VERSION_ERROR: 'GET_SINGLE_PROGRAMME_VERSION_ERROR',

  GET_VERSION_COURSE_UNITS_SUCCESS: 'GET_VERSION_COURSE_UNITS_SUCCESS',
  GET_VERSION_COURSE_UNITS_REQUEST: 'GET_VERSION_COURSE_UNITS_REQUEST',
  GET_VERSION_COURSE_UNITS_ERROR: 'GET_VERSION_COURSE_UNITS_ERROR',

  GET_VERSION_OPTIONS_SUCCESS: 'GET_VERSION_OPTIONS_SUCCESS',
  GET_VERSION_OPTIONS_REQUEST: 'GET_VERSION_OPTIONS_REQUEST',
  GET_VERSION_OPTIONS_ERROR: 'GET_VERSION_OPTIONS_ERROR',

  UPDATE_PROGRAMME_VERSION_SUCCESS: 'UPDATE_PROGRAMME_VERSION_SUCCESS',
  UPDATE_PROGRAMME_VERSION_REQUEST: 'UPDATE_PROGRAMME_VERSION_REQUEST',
  UPDATE_PROGRAMME_VERSION_ERROR: 'UPDATE_PROGRAMME_VERSION_ERROR',

  DELETE_VERSION_COURSE_UNIT_SUCCESS: 'DELETE_VERSION_COURSE_UNIT_SUCCESS',
  DELETE_VERSION_COURSE_UNIT_REQUEST: 'DELETE_VERSION_COURSE_UNIT_REQUEST',
  DELETE_VERSION_COURSE_UNIT_ERROR: 'DELETE_VERSION_COURSE_UNIT_ERROR',

  createProgrammeVersion: (data) => ({
    type: programmeVersion.CREATE_PROGRAMME_VERSION_REQUEST,
    data,
  }),

  getSingleProgrammeVersion: (programmeVersionId) => ({
    type: programmeVersion.GET_SINGLE_PROGRAMME_VERSION_REQUEST,
    programmeVersionId,
  }),

  getVersionCourseUnits: (programmeVersionId) => ({
    type: programmeVersion.GET_VERSION_COURSE_UNITS_REQUEST,
    programmeVersionId,
  }),

  getVersionOptions: (programmeVersionId, params) => ({
    type: programmeVersion.GET_VERSION_OPTIONS_REQUEST,
    programmeVersionId,
    params,
  }),

  updateProgrammeVersion: (programmeVersionId, data) => ({
    type: programmeVersion.UPDATE_PROGRAMME_VERSION_REQUEST,
    programmeVersionId,
    data,
  }),

  deleteProgrammeVersionCourseUnit: (
    courseUnitId,
    versionId,
    deleteModalAction
  ) => ({
    type: programmeVersion.DELETE_VERSION_COURSE_UNIT_REQUEST,
    courseUnitId,
    versionId,
    deleteModalAction,
  }),
};

export default programmeVersion;
