import { isEmpty } from 'lodash';
import PropTypes, { any, array, object } from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

function InputSelectField({
  name,
  control,
  inline,
  label,
  type,
  selectOptions,
  error,
  requiredField,
  defaultValue,
  ...props
}) {
  return (
    <Form.Group
      controlId={name}
      className={inline ? 'row mb-2' : 'form-group mb-2'}
    >
      {label && (
        <Form.Label
          className={`mt-2 fw-bold text-sm ${inline ? 'col-md-4' : ''}`}
          style={{ textAlign: 'left !important' }}
        >
          {label}
          {requiredField && <strong className="text-danger ms-1">*</strong>}
        </Form.Label>
      )}
      <div className={inline ? 'col-md-8' : ''}>
        {type !== 'creatable' ? (
          <Controller
            as={Select}
            options={selectOptions}
            name={name}
            isInvalid={error}
            control={control}
            defaultValue={defaultValue}
            menuPosition="fixed"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              padding: '2px',
            })}
            {...props}
          />
        ) : (
          <Controller
            as={CreatableSelect}
            options={selectOptions}
            name={name}
            control={control}
            defaultValue={defaultValue}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              padding: '2px',
            })}
            {...props}
          />
        )}
        {!isEmpty(error) && (
          <div className="mt-1 text-sm text-danger">{error}</div>
        )}
      </div>
    </Form.Group>
  );
}

InputSelectField.defaultProps = {
  type: 'select',
  label: null,
  error: null,
  autoComplete: 'off',
  isClearable: true,
  isSearchable: true,
  isMulti: false,
  selectOptions: [],
  inline: false,
  name: null,
  className: 'text-md text-muted',
  selectType: null,
  requiredField: false,
  defaultValue: '',
};

InputSelectField.propTypes = {
  type: PropTypes.string,
  control: PropTypes.oneOfType([object]).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  selectOptions: PropTypes.oneOfType([array]),
  defaultValue: PropTypes.oneOfType([any]),
  selectType: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  inline: PropTypes.bool,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  requiredField: PropTypes.bool,
};

export default InputSelectField;
