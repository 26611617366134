import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { feesStructureActions } from '../../actions';

function* getFeesStructure(actions) {
  try {
    const { studentProgrammeId } = actions;

    const response = yield axios({
      url: `fees-mgt/fees-amount-preview/fees-structure/${studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: feesStructureActions.GET_FEES_STRUCTURE_SUCCESS,
      data: response.feesStructure,
      studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: feesStructureActions.GET_FEES_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFeesStructure() {
  yield takeLatest(
    feesStructureActions.GET_FEES_STRUCTURE_REQUEST,
    getFeesStructure
  );
}

export default [fork(watchGetFeesStructure)];
