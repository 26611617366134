import { resultApprovalActions } from '../../actions';
import initialState from '../../initialState';

const resultApprovalPolicy = (
  state = initialState.resultApprovalPolicy,
  actions
) => {
  switch (actions.type) {
    case resultApprovalActions.GET_RESULT_APPROVAL_POLICY_REQUEST:
      return {
        ...state,
        gettingResultApprovalPolicy: true,
        resultApprovalPolicyError: {},
      };
    case resultApprovalActions.GET_RESULT_APPROVAL_POLICY_SUCCESS:
      return {
        ...state,
        resultApprovalPolicy: actions.data,
        gettingResultApprovalPolicy: false,
      };
    case resultApprovalActions.GET_RESULT_APPROVAL_POLICY_ERROR:
      return {
        ...state,
        resultApprovalPolicyError: actions.error,
        gettingResultApprovalPolicy: false,
      };
    case resultApprovalActions.DELETE_RESULT_APPROVAL_POLICY_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case resultApprovalActions.DELETE_RESULT_APPROVAL_POLICY_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case resultApprovalActions.DELETE_RESULT_APPROVAL_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case resultApprovalActions.SET_RESULT_POLICY_ACTIVE_TAB:
      return {
        ...state,
        activeTab: actions.view,
      };

    case resultApprovalActions.SET_DELETE_RESULT_POLICY:
      return {
        ...state,
        deleteModal: actions.view,
      };

    case resultApprovalActions.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_REQUEST:
      return {
        ...state,
        updatingResultApprovalPolicy: true,
        createResultApprovalPolicyError: {},
      };
    case resultApprovalActions.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_SUCCESS:
      return {
        ...state,
        updatingResultApprovalPolicy: false,
      };
    case resultApprovalActions.CREATE_OR_UPDATE_RESULT_APPROVAL_POLICY_ERROR:
      return {
        ...state,
        createResultApprovalPolicyError: actions.error,
        updatingResultApprovalPolicy: false,
      };

    default:
      return state;
  }
};

export default resultApprovalPolicy;
