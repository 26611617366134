import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { eventActions } from '../../actions';
import initialState from '../../initialState';

const event = (state = initialState.event, actions) => {
  switch (actions.type) {
    case eventActions.GET_EVENTS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case eventActions.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: actions.data.events,
        loading: false,
      };
    case eventActions.GET_EVENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case eventActions.GET_ACADEMIC_YEAR_EVENTS_REQUEST:
      return {
        ...state,
        error: {},
        gettingAcademicYearEvents: true,
      };
    case eventActions.GET_ACADEMIC_YEAR_EVENTS_SUCCESS:
      return {
        ...state,
        academicYearEvents: actions.data.events,
        gettingAcademicYearEvents: false,
      };
    case eventActions.GET_ACADEMIC_YEAR_EVENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        gettingAcademicYearEvents: false,
      };

    case eventActions.GET_SEMESTER_EVENTS_REQUEST:
      return {
        ...state,
        error: {},
        gettingSemesterEvents: true,
      };

    case eventActions.GET_SEMESTER_EVENTS_SUCCESS:
      return {
        ...state,
        semesterEvents: actions.data.events,
        gettingSemesterEvents: false,
      };

    case eventActions.GET_SEMESTER_EVENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        gettingSemesterEvents: false,
      };

    case eventActions.EDIT_EVENT_REQUEST:
      return {
        ...state,
        editError: {},
        editing: true,
        editedEvent: {},
      };
    case eventActions.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        editedEvent: actions.data.event,
        editing: false,
      };
    case eventActions.EDIT_EVENT_ERROR:
      return {
        ...state,
        editError: actions.error,
        editing: false,
      };

    case eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_REQUEST:
      return {
        ...state,
        editError: {},
        editing: true,
        editedEvent: {},
      };

    case eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_SUCCESS:
      return {
        ...state,
        editedEvent: actions.data.event,
        editing: false,
      };

    case eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_ERROR:
      return {
        ...state,
        editError: actions.error,
        editing: false,
      };

    case eventActions.DELETE_EVENT_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };
    case eventActions.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case eventActions.DELETE_EVENT_ERROR:
      return {
        ...state,
        deleteError: actions.error.server,
        deleting: false,
      };

    case eventActions.CREATE_EVENT_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
        createdEvent: {},
      };

    case eventActions.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        createdEvent: actions.data.event,
        creating: false,
      };
    case eventActions.CREATE_EVENT_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };
    case eventActions.SET_SEMESTER_EVENT_DATA:
      return {
        ...state,
        semesterEventData: actions.data,
      };

    case eventActions.SET_RESULT_ACADEMIC_YEAR_CONTEXT:
      return {
        ...state,
        academicYearContext: actions.params,
      };

    case eventActions.CREATE_RESULT_EVENT_REQUEST:
      return {
        ...state,
        createResultEventError: {},
        creatingResultEvent: true,
      };
    case eventActions.CREATE_RESULT_EVENT_SUCCESS:
      return {
        ...state,
        creatingResultEvent: false,
      };
    case eventActions.CREATE_RESULT_EVENT_ERROR:
      return {
        ...state,
        createResultEventError: actions.error,
        creatingResultEvent: false,
      };

    case eventActions.GET_RESULT_EVENTS_REQUEST:
      return {
        ...state,
        loadResultEventError: {},
        loadingResultEvents: true,
      };
    case eventActions.GET_RESULT_EVENTS_SUCCESS: {
      const { academicYearId, data } = actions;
      const { resultEvents } = state;

      const findContextIndex = findIndex(
        resultEvents,
        (item) => item.academicYearId === academicYearId
      );

      const contextData = { academicYearId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          resultEvents: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          resultEvents: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        loadingResultEvents: false,
      };
    }

    case eventActions.GET_RESULT_EVENTS_ERROR:
      return {
        ...state,
        loadResultEventError: actions.error,
        loadingResultEvents: false,
      };

    case eventActions.EDIT_RESULT_EVENT_REQUEST:
      return {
        ...state,
        editResultEventError: {},
        editingResultEvent: true,
      };
    case eventActions.EDIT_RESULT_EVENT_SUCCESS:
      return {
        ...state,
        editingResultEvent: false,
      };
    case eventActions.EDIT_RESULT_EVENT_ERROR:
      return {
        ...state,
        editResultEventError: actions.error,
        editingResultEvent: false,
      };

    case eventActions.DELETE_RESULT_EVENT_REQUEST:
      return {
        ...state,
        deleteResultEventError: {},
        deletingResultEvent: true,
      };
    case eventActions.DELETE_RESULT_EVENT_SUCCESS:
      return {
        ...state,
        deletingResultEvent: false,
      };
    case eventActions.DELETE_RESULT_EVENT_ERROR:
      return {
        ...state,
        deleteResultEventError: actions.error.server,
        deletingResultEvent: false,
      };

    default:
      return state;
  }
};

export default event;
