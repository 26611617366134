const graduationFeesActions = {
  GET_GRADUATION_FEES_REQUEST: 'GET_GRADUATION_FEES_REQUEST',
  GET_GRADUATION_FEES_SUCCESS: 'GET_GRADUATION_FEES_SUCCESS',
  GET_GRADUATION_FEES_ERROR: 'GET_GRADUATION_FEES_ERROR',

  CREATE_GRADUATION_FEES_REQUEST: 'CREATE_GRADUATION_FEES_REQUEST',
  CREATE_GRADUATION_FEES_SUCCESS: 'CREATE_GRADUATION_FEES_SUCCESS',
  CREATE_GRADUATION_FEES_ERROR: 'CREATE_GRADUATION_FEES_ERROR',

  UPDATE_GRADUATION_FEES_REQUEST: 'UPDATE_GRADUATION_FEES_REQUEST',
  UPDATE_GRADUATION_FEES_SUCCESS: 'UPDATE_GRADUATION_FEES_SUCCESS',
  UPDATE_GRADUATION_FEES_ERROR: 'UPDATE_GRADUATION_FEES_ERROR',

  DELETE_GRADUATION_FEES_ELEMENT_REQUEST:
    'DELETE_GRADUATION_FEES_ELEMENT_REQUEST',
  DELETE_GRADUATION_FEES_ELEMENT_SUCCESS:
    'DELETE_GRADUATION_FEES_ELEMENT_SUCCESS',
  DELETE_GRADUATION_FEES_ELEMENT_ERROR: 'DELETE_GRADUATION_FEES_ELEMENT_ERROR',

  SET_GRADUATION_FEES_CONTEXT: 'SET_GRADUATION_FEES_CONTEXT',
  SET_GRADUATION_FEES_TAB: 'SET_GRADUATION_FEES_TAB',

  getGraduationFees: (data) => ({
    type: graduationFeesActions.GET_GRADUATION_FEES_REQUEST,
    data,
  }),

  createGraduationFees: (data, context) => ({
    type: graduationFeesActions.CREATE_GRADUATION_FEES_REQUEST,
    data,
    context,
  }),

  updateGraduationFees: (id, data, context) => ({
    type: graduationFeesActions.UPDATE_GRADUATION_FEES_REQUEST,
    id,
    data,
    context,
  }),

  deleteGraduationFeesElement: (id, context) => ({
    type: graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_REQUEST,
    id,
    context,
  }),

  setGraduationContext: (context) => ({
    type: graduationFeesActions.SET_GRADUATION_FEES_CONTEXT,
    context,
  }),

  setGraduationFeesTab: (data) => ({
    type: graduationFeesActions.SET_GRADUATION_FEES_TAB,
    data,
  }),
};

export default graduationFeesActions;
