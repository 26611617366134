import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  courseUnitActions,
  programmeActions,
  programmeVersionActions,
  tabActions,
} from '../../actions';

function* getCourseUnits(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/course-units',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_ERROR,
      error: error.data,
    });
  }
}

function* getCourseDetails(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-versions/fetch-version-course-unit/${actions.courseId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.GET_COURSE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getCourseUnitsDownload(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/course-units/download',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_DOWNLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_DOWNLOAD_ERROR,
      error: error.data,
    });
  }
}

function* getCourseUnitProgrammes(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: `/programme-mgt/course-units/programmes`,
      method: 'GET',
      params,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNIT_PROGRAMMES_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.GET_COURSE_UNIT_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* createCourseUnits(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/course-units',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: courseUnitActions.CREATE_COURSE_UNIT_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_REQUEST,
      programmeVersionId: actions.data.programme_version_id,
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST,
      programmeVersionId: actions.data.programme_version_id,
    });
    yield put({
      type: programmeActions.SET_CURRENT_PROGRAMME_DETAIL_TAB,
      currentTab: 'view-course',
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.CREATE_COURSE_UNIT_ERROR,
      error: error.data,
    });
  }
}

function* editRepoCourseUnit(actions) {
  try {
    const { courseId } = actions;

    const response = yield axios({
      url: `/programme-mgt/course-units/update-repo-course-unit/${courseId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: courseUnitActions.EDIT_REPO_COURSE_SUCCESS,
      data: response,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_REQUEST,
    });
    yield put({ type: 'SHOW_EDIT_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: courseUnitActions.EDIT_REPO_COURSE_ERROR,
      error: error.data,
    });
  }
}

function* deleteCourseUnit(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/course-units/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: courseUnitActions.DELETE_COURSE_UNIT_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST,
      programmeVersionId: actions.programmeVersionId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.DELETE_COURSE_UNIT_ERROR,
      error: error.data,
    });
  }
}

function* deleteRepoCourseUnit(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/course-units/delete-repo-course-unit/${actions.courseId}`,
      method: 'DELETE',
    });
    yield put({
      type: courseUnitActions.DELETE_REPO_COURSE_UNIT_SUCCESS,
      data: response,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: courseUnitActions.DELETE_REPO_COURSE_UNIT_ERROR,
      error: error.data,
    });
  }
}

function* updateCourseUnit(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/course-units/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: courseUnitActions.UPDATE_COURSE_UNIT_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST,
      programmeVersionId: actions.programmeVersionId,
    });
    yield put({
      type: programmeActions.SET_CURRENT_PROGRAMME_DETAIL_TAB,
      currentTab: 'view-course',
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.UPDATE_COURSE_UNIT_ERROR,
      error: error.data,
    });
  }
}

function* updateCourseDetails(actions) {
  try {
    const { courseId, data } = actions;

    const response = yield axios({
      url: `/programme-mgt/programme-versions/update-version-course-unit/${courseId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: courseUnitActions.UPDATE_COURSE_DETAILS_SUCCESS,
      data: response,
    });
    yield put({
      type: tabActions.PROGRAMME_VERSION_TAB,
      activeTab: 'view',
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST,
      programmeVersionId: data?.programme_version_id,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.UPDATE_COURSE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* assignDepartment(actions) {
  try {
    const { departmentId, courseUnitId } = actions;

    const response = yield axios({
      url: `/programme-mgt/course-units/assign-department/${courseUnitId}/${departmentId}`,
      method: 'PUT',
    });
    yield put({
      type: courseUnitActions.ASSIGN_COURSE_UNIT_DEPARTMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.ASSIGN_COURSE_UNIT_DEPARTMENT_ERROR,
      error: error.data,
    });
  }
}

function* uploadCourseUnits(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/course-units/upload/${actions.programmeId}/${actions.version}`,
      method: 'POST',
      data: actions.files,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: courseUnitActions.UPLOAD_COURSE_UNIT_SUCCESS,
      data: response,
    });
    yield put({
      type: courseUnitActions.GET_COURSE_UNITS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.UPLOAD_COURSE_UNIT_ERROR,
      error: error.data,
    });
  }
}

function* downloadCourseUnitTemplate(actions) {
  try {
    yield axios({
      url: `/programme-mgt/course-units/download-template/${actions.programmeId}/${actions.programmeVersionId}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-COURSE-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: courseUnitActions.DOWNLOAD_COURSE_UNIT_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: courseUnitActions.DOWNLOAD_COURSE_UNIT_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetCourseUnits() {
  yield takeLatest(courseUnitActions.GET_COURSE_UNITS_REQUEST, getCourseUnits);
}

function* watchGetCourseDetails() {
  yield takeLatest(
    courseUnitActions.GET_COURSE_DETAILS_REQUEST,
    getCourseDetails
  );
}

function* watchGetCourseUnitsDownload() {
  yield takeLatest(
    courseUnitActions.GET_COURSE_UNITS_DOWNLOAD_REQUEST,
    getCourseUnitsDownload
  );
}

function* watchGetCourseUnitProgrammes() {
  yield takeLatest(
    courseUnitActions.GET_COURSE_UNIT_PROGRAMMES_REQUEST,
    getCourseUnitProgrammes
  );
}

function* watchCreateCourseUnits() {
  yield takeLatest(
    courseUnitActions.CREATE_COURSE_UNIT_REQUEST,
    createCourseUnits
  );
}
function* watchEditRepoCreateCourse() {
  yield takeLatest(
    courseUnitActions.EDIT_REPO_COURSE_REQUEST,
    editRepoCourseUnit
  );
}

function* watchDeleteCourseUnit() {
  yield takeLatest(
    courseUnitActions.DELETE_COURSE_UNIT_REQUEST,
    deleteCourseUnit
  );
}

function* watchRepoDeleteCourseUnit() {
  yield takeLatest(
    courseUnitActions.DELETE_REPO_COURSE_UNIT_REQUEST,
    deleteRepoCourseUnit
  );
}

function* watchUpdateCourseUnit() {
  yield takeLatest(
    courseUnitActions.UPDATE_COURSE_UNIT_REQUEST,
    updateCourseUnit
  );
}

function* watchUpdateCourseDetails() {
  yield takeLatest(
    courseUnitActions.UPDATE_COURSE_DETAILS_REQUEST,
    updateCourseDetails
  );
}

function* watchAssignDepartment() {
  yield takeLatest(
    courseUnitActions.ASSIGN_COURSE_UNIT_DEPARTMENT_REQUEST,
    assignDepartment
  );
}

function* watchUploadCourseUnit() {
  yield takeLatest(
    courseUnitActions.UPLOAD_COURSE_UNIT_REQUEST,
    uploadCourseUnits
  );
}

function* watchDownloadCourseUnitTemplate() {
  yield takeLatest(
    courseUnitActions.DOWNLOAD_COURSE_UNIT_TEMPLATE_REQUEST,
    downloadCourseUnitTemplate
  );
}
export default [
  fork(watchGetCourseUnits),
  fork(watchGetCourseDetails),
  fork(watchGetCourseUnitsDownload),
  fork(watchGetCourseUnitProgrammes),
  fork(watchCreateCourseUnits),
  fork(watchEditRepoCreateCourse),
  fork(watchDeleteCourseUnit),
  fork(watchRepoDeleteCourseUnit),
  fork(watchUpdateCourseUnit),
  fork(watchUpdateCourseDetails),
  fork(watchAssignDepartment),
  fork(watchUploadCourseUnit),
  fork(watchDownloadCourseUnitTemplate),
];
