import { batchResultActions } from '../../actions';
import initialState from '../../initialState';

const batchResult = (state = initialState.batchResult, actions) => {
  switch (actions.type) {
    case batchResultActions.GET_BATCH_RESULTS_REQUEST:
      return {
        ...state,
        batchResultError: {},
        loadingBatchResult: true,
      };
    case batchResultActions.GET_BATCH_RESULTS_SUCCESS:
      return {
        ...state,
        resultBatches: actions.data,
        loadingBatchResult: false,
      };
    case batchResultActions.GET_BATCH_RESULTS_ERROR:
      return {
        ...state,
        batchResultError: actions.error,
        loadingBatchResult: false,
      };

    case batchResultActions.GET_BATCH_DETAILS_REQUEST:
      return {
        ...state,
        fetchingSingleBatch: true,
        singleBatch: [],
        singleBatchError: {},
      };
    case batchResultActions.GET_BATCH_DETAILS_SUCCESS:
      return {
        ...state,
        singleBatch: actions.data,
        fetchingSingleBatch: false,
      };
    case batchResultActions.GET_BATCH_DETAILS_ERROR:
      return {
        ...state,
        singleBatchError: actions.error,
        fetchingSingleBatch: false,
      };

    case batchResultActions.GET_BATCH_APPROVAL_RESULTS_REQUEST:
      return {
        ...state,
        gettingBatchApprovalResults: true,
        batchApprovalResults: [],
      };
    case batchResultActions.GET_BATCH_APPROVAL_RESULTS_SUCCESS:
      return {
        ...state,
        batchApprovalResults: actions.data,
        gettingBatchApprovalResults: false,
      };
    case batchResultActions.GET_BATCH_APPROVAL_RESULTS_ERROR:
      return {
        ...state,
        getBatchApprovalError: actions.error,
        gettingBatchApprovalResults: false,
      };

    case batchResultActions.APPROVE_BATCH_RESULTS_REQUEST:
      return {
        ...state,
        approvingBatchResults: true,
        approveBatchResultsError: {},
      };
    case batchResultActions.APPROVE_BATCH_RESULTS_SUCCESS:
      return {
        ...state,
        approvingBatchResults: false,
        approvedBatchResults: actions.data,
      };
    case batchResultActions.APPROVE_BATCH_RESULTS_ERROR:
      return {
        ...state,
        approvingBatchResults: false,
        approveBatchResultsError: actions.error,
      };

    case batchResultActions.SUBMIT_BATCH_RESULTS_REQUEST:
      return {
        ...state,
        submittingBatchResults: true,
        submitBatchResultsError: {},
      };
    case batchResultActions.SUBMIT_BATCH_RESULTS_SUCCESS:
      return {
        ...state,
        submittingBatchResults: false,
        submitBatchResults: actions.data,
      };
    case batchResultActions.SUBMIT_BATCH_RESULTS_ERROR:
      return {
        ...state,
        submittingBatchResults: false,
        submitBatchResultsError: actions.error,
      };

    case batchResultActions.APPROVE_SELECTED_RESULTS_REQUEST:
      return {
        ...state,
        approvingSelectedResults: true,
        approveSelectedResultsError: [],
      };
    case batchResultActions.APPROVE_SELECTED_RESULTS_SUCCESS:
      return {
        ...state,
        approvedSelectedResults: actions.data,
        approvingSelectedResults: false,
      };
    case batchResultActions.APPROVE_SELECTED_RESULTS_ERROR:
      return {
        ...state,
        approveSelectedResultsError: actions.error,
        approvingSelectedResults: false,
      };

    case batchResultActions.SUBMIT_SELECTED_RESULTS_REQUEST:
      return {
        ...state,
        submittingSelectedResults: true,
        submitSelectedResultsError: {},
      };
    case batchResultActions.SUBMIT_SELECTED_RESULTS_SUCCESS:
      return {
        ...state,
        submitSelectedResults: actions.data,
        submittingSelectedResults: false,
      };
    case batchResultActions.SUBMIT_SELECTED_RESULTS_ERROR:
      return {
        ...state,
        submitSelectedResultsError: actions.error,
        submittingSelectedResults: false,
      };

    case batchResultActions.DELETE_BATCH_RESULTS_REQUEST:
      return {
        ...state,
        deletingResultBatch: true,
        deleteBatchError: {},
      };
    case batchResultActions.DELETE_BATCH_RESULTS_SUCCESS:
      return {
        ...state,
        deleteBatchResponse: actions.data,
        deletingResultBatch: false,
      };
    case batchResultActions.DELETE_BATCH_RESULTS_ERROR:
      return {
        ...state,
        deleteBatchError: actions.error,
        deletingResultBatch: false,
      };

    case batchResultActions.UPDATE_BATCH_DETAILS_REQUEST:
      return {
        ...state,
        updatingSingleBatch: true,
        updateBatchError: {},
      };
    case batchResultActions.UPDATE_BATCH_DETAILS_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updatingSingleBatch: false,
      };
    case batchResultActions.UPDATE_BATCH_DETAILS_ERROR:
      return {
        ...state,
        updateBatchError: actions.error,
        updatingSingleBatch: false,
      };

    case batchResultActions.DELETE_BATCH_DETAILS_REQUEST:
      return {
        ...state,
        deletingResultDetail: true,
        deleteResultDetailError: {},
      };
    case batchResultActions.DELETE_BATCH_DETAILS_SUCCESS:
      return {
        ...state,
        deleteDetailResponse: actions.data,
        deletingResultDetail: false,
      };
    case batchResultActions.DELETE_BATCH_DETAILS_ERROR:
      return {
        ...state,
        deleteResultDetailError: actions.error,
        deletingResultDetail: false,
      };

    case batchResultActions.SEARCH_RESULT_BATCH_REQUEST:
      return {
        ...state,
        searchingResultBatch: true,
        searchResultBatchError: {},
      };
    case batchResultActions.SEARCH_RESULT_BATCH_SUCCESS:
      return {
        ...state,
        searchedResultBatches: actions.data,
        searchingResultBatch: false,
      };
    case batchResultActions.SEARCH_RESULT_BATCH_ERROR:
      return {
        ...state,
        searchResultBatchError: actions.error,
        searchingResultBatch: false,
      };

    case batchResultActions.DOWNLOAD_BATCH_RESULTS_REQUEST:
      return {
        ...state,
        downloadingBatchResults: true,
        downloadResultError: {},
      };
    case batchResultActions.DOWNLOAD_BATCH_RESULTS_SUCCESS:
      return {
        ...state,
        downloadedResult: actions.data,
        downloadingBatchResults: false,
      };
    case batchResultActions.DOWNLOAD_BATCH_RESULTS_ERROR:
      return {
        ...state,
        downloadResultError: actions.error,
        downloadingBatchResults: false,
      };

    case batchResultActions.SET_BATCH_RESULTS_PARAMS:
      return {
        ...state,
        batchParams: actions.params,
      };

    case batchResultActions.SET_RESULT_TO_EDIT:
      return {
        ...state,
        resultToEdit: actions.data,
      };

    default:
      return state;
  }
};

export default batchResult;
