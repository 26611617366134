import { isEmpty, uniqBy } from 'lodash';
import { activeAppActions } from '../../actions';
import initialState from '../../initialState';
import appRoutes from '../../routes/appRoutes';

const activeApp = (state = initialState.activeApp, actions) => {
  switch (actions.type) {
    case activeAppActions.SET_ACTIVE_APP: {
      if (!isEmpty(actions.app) && actions.app.app_code) {
        return {
          ...state,
          openApps: uniqBy([...state.openApps, actions.app], 'app_code'),
          activeApp: actions.app,
        };
      }
      return {
        ...state,
        activeApp: actions.app,
        openApps: state.openApps,
      };
    }

    case activeAppActions.REMOVE_ACTIVE_APP:
      return {
        ...state,
        openApps: !isEmpty(actions.app?.app_code)
          ? state.openApps.filter(
              (app) => app.app_code !== actions.app?.app_code
            )
          : state.openApps,
      };

    case activeAppActions.RESET_ACTIVE_APPS:
      return {
        ...state,
        openApps: [appRoutes.find((app) => app.app_code === 'DASHBOARD')],
        activeApp: appRoutes.find((app) => app.app_code === 'DASHBOARD') || {},
      };

    case activeAppActions.SET_APP_ERROR:
      return {
        ...state,
        appError: actions.error,
      };

    default:
      return state;
  }
};

export default activeApp;
