import { studentSupportActions } from '../../actions';
import initialState from '../../initialState';

const studentSupport = (state = initialState.studentSupport, actions) => {
  switch (actions.type) {
    case studentSupportActions.GENERATE_STUDENT_OTP_REQUEST:
      return {
        ...state,
        generateOTPError: {},
        generatingOTP: true,
      };
    case studentSupportActions.GENERATE_STUDENT_OTP_SUCCESS:
      return {
        ...state,
        generatedOTP: actions.data,
        generatingOTP: false,
      };
    case studentSupportActions.GENERATE_STUDENT_OTP_ERROR:
      return {
        ...state,
        generateOTPError: actions.error,
        generatingOTP: false,
      };

    case studentSupportActions.GET_STUDENT_FOR_OTP_REQUEST:
      return {
        ...state,
        studentQuery: actions.data,
        fetchingStudent: true,
        fetchStudentError: {},
      };
    case studentSupportActions.GET_STUDENT_FOR_OTP_SUCCESS:
      return {
        ...state,
        fetchedStudent: actions.data,
        fetchingStudent: false,
        fetchStudentError: {},
      };
    case studentSupportActions.GET_STUDENT_FOR_OTP_ERROR:
      return {
        ...state,
        fetchedStudent: {},
        fetchingStudent: false,
        fetchStudentError: actions.data,
      };

    case studentSupportActions.GET_STAFF_FOR_OTP_REQUEST:
      return {
        ...state,
        staffQuery: actions.data,
        fetchingStaff: true,
        fetchStaffError: {},
      };
    case studentSupportActions.GET_STAFF_FOR_OTP_SUCCESS:
      return {
        ...state,
        fetchedStaff: actions.data,
        fetchingStaff: false,
        fetchStaffError: {},
      };
    case studentSupportActions.GET_STAFF_FOR_OTP_ERROR:
      return {
        ...state,
        fetchedStaff: {},
        fetchingStaff: false,
        fetchStaffError: actions.data,
      };
    case studentSupportActions.UPDATING_STUDENT_CONTACTS_REQUEST:
      return {
        ...state,
        updatingStudentSupportContacts: true,
        updateStudentSupportContactsError: {},
      };
    case studentSupportActions.UPDATE_STUDENT_CONTACTS_SUCCESS:
      return {
        ...state,
        updatingStudentSupportContacts: false,
        updateStudentSupportContactsSuccess: actions.data,
      };
    case studentSupportActions.UPDATE_STUDENT_CONTACTS_ERROR:
      return {
        ...state,
        updatingStudentSupportContacts: false,
        updateStudentSupportContactsError: actions.data,
      };
    default:
      return state;
  }
};

export default studentSupport;
