import { all } from 'redux-saga/effects';
import NTCResult from './NTCManager/NTCResult';
import NTCStudent from './NTCManager/NTCStudent';
import NTCSubject from './NTCManager/NTCSubject';
import academicDocument from './academicDocument';
import admissionForm from './admission-mgt/admissionForm';
import admissionScheme from './admission-mgt/admissionScheme';
import admissionStudentService from './admission-mgt/admissionStudentService';
import admittedStudents from './admission-mgt/admittedStudents';
import admissionApplicant from './admission-mgt/applicant';
import applicationHistory from './admission-mgt/applicationHistory';
import runningAdmission from './admission-mgt/runningAdmission';
import runningAdmissionCampus from './admission-mgt/runningAdmissionCampus';
import runningAdmissionProgramme from './admission-mgt/runningAdmissionProgramme';
import weightingAndSelection from './admission-mgt/weightingAndSelection';
import specialization from './app/specialization';
import subject from './app/subject';
import userLogs from './auditTrails/userLogs';
import auth from './auth';
import academicRegistrar from './businessIntelligence/academicRegistrar';
import admissionReport from './businessIntelligence/admission';
import notificationReport from './businessIntelligence/notification';
import resultReport from './businessIntelligence/result';
import revenueReport from './businessIntelligence/revenue';
import courseAssignment from './courseAssignment';
import building from './courseAssignment/building';
import semesterCourseLoad from './courseAssignment/semesterCourseLoad';
import teachingTimetable from './courseAssignment/teachingTimetable';
import electivePosition from './eVoting/electivePosition';
import previousRegistration from './enrollmentAndRegistration/previousRegistration';
import registrationReports from './enrollmentAndRegistration/registrationReports';
import semesterEvent from './enrollmentAndRegistration/semesterEvent';
import studentEnrollment from './enrollmentAndRegistration/studentEnrollment';
import studentRegistration from './enrollmentAndRegistration/studentRegistration';
import academicYears from './events-mgt/academicYears';
import events from './events-mgt/events';
import feesApprovals from './fees-mgt/feesApprovals';
import feesElements from './fees-mgt/feesElements';
import feesWaivers from './fees-mgt/feesWaivers';
import functionalFees from './fees-mgt/functionalFees';
import graduationFees from './fees-mgt/graduationFees';
import otherFees from './fees-mgt/otherFees';
import tuition from './fees-mgt/tuition';
import waiverDiscounts from './fees-mgt/waiverDiscounts';
import academicFeesPolicy from './institutionPolicy/academicFeesPolicy';
import admissionFeesPolicy from './institutionPolicy/admissionFeesPolicy';
import applicationFeesPolicy from './institutionPolicy/applicationFeesPolicy';
import concededPassPolicy from './institutionPolicy/concededPassPolicy';
import courseResittingPolicy from './institutionPolicy/courseResittingPolicy';
import documentPolicy from './institutionPolicy/documentPolicy';
import extensionPolicy from './institutionPolicy/extensionPolicy';
import feesBillingPolicy from './institutionPolicy/feesBillingPolicy';
import otherFeesPolicy from './institutionPolicy/otherFeesPolicy';
import registrationPolicy from './institutionPolicy/registrationPolicy';
import resultApprovalPolicy from './institutionPolicy/resultApprovalPolicy';
import resultCategoryPolicy from './institutionPolicy/resultCategoryPolicy';
import resultsBlockingPolicy from './institutionPolicy/resultsBlockingPolicy';
import retakerPolicy from './institutionPolicy/retakerPolicy';
import servicesPolicy from './institutionPolicy/servicesPolicy';
import studyLevelPolicy from './institutionPolicy/studyLevelPolicy';
import surchargePolicy from './institutionPolicy/surchargePolicy';
import tuitionFeesPolicy from './institutionPolicy/tuitionFeesPolicy';
import college from './institutionSetup/college';
import department from './institutionSetup/department';
import documentSetting from './institutionSetup/documentSetting';
import faculty from './institutionSetup/faculty';
import institutionStructure from './institutionSetup/institutionStructure';
import metadata from './institutionSetup/metadata';
import searchData from './its-data/searchData';
import lecturerCourses from './lecturer-mgt/lecturerCourses';
import bulkPayments from './paymentsAndTransactions/bulkPayments';
import creditNote from './paymentsAndTransactions/creditNote';
import debitNote from './paymentsAndTransactions/debitNote';
import feesStructure from './paymentsAndTransactions/feesStructure';
import financialStatements from './paymentsAndTransactions/financialStatement';
import financialTransactions from './paymentsAndTransactions/financialTransaction';
import paymentReports from './paymentsAndTransactions/paymentReports';
import pendingDirectPost from './paymentsAndTransactions/pendingDirectPost';
import pendingExemptedInvoice from './paymentsAndTransactions/pendingExemptedInvoice';
import pendingVoidedInvoice from './paymentsAndTransactions/pendingVoidedInvoice';
import previousPayments from './paymentsAndTransactions/previousPayments';
import refundTransaction from './paymentsAndTransactions/refundTransaction';
import sponsor from './paymentsAndTransactions/sponsor';
import studentInvoice from './paymentsAndTransactions/studentInvoice';
import studentLedger from './paymentsAndTransactions/studentLedger';
import studentRecord from './paymentsAndTransactions/studentRecord';
import courseUnit from './programme-mgt/courseUnit';
import grading from './programme-mgt/grading';
import programme from './programme-mgt/programme';
import programmeAlias from './programme-mgt/programmeAlias';
import programmeSearch from './programme-mgt/programmeSearch';
import programmeVersion from './programme-mgt/programmeVersion';
import semesterLoad from './programme-mgt/semesterLoad';
import unebCenter from './programme-mgt/unebCenter';
import unebSubject from './programme-mgt/unebSubject';
import pujabAdmissions from './pujab-mgt/admission';
import pujabApplicants from './pujab-mgt/applicants';
import pujabInstitutions from './pujab-mgt/institutions';
import pujabProgrammes from './pujab-mgt/programmes';
import pujabReport from './pujab-mgt/report';
import schoolAdmin from './pujab-mgt/schoolAdmin';
import batchResult from './resultManager/batchResult';
import graduationList from './resultManager/graduationList';
import resultDashboard from './resultManager/resultDashboard';
import resultLogs from './resultManager/resultLogs';
import resultNode from './resultManager/resultNode';
import searchResult from './resultManager/searchResult';
import senate from './resultManager/senate';
import studentGrade from './resultManager/studentGrade';
import shared from './shared';
import specialEntryAdmissions from './specialEntry/admission';
import specialEntryApplicants from './specialEntry/applicants';
import specialEntryReport from './specialEntry/report';
import financeClearance from './studentClearance/financeClearance';
import student from './students-mgt/student';
import studentSupport from './students-mgt/studentSupport';
import students from './students-mgt/students';
import acmisCampus from './systemManager/campus';
import acmisProgrammes from './systemManager/programme';
import systemScheme from './systemManager/schemes';
import acmisSponsors from './systemManager/sponsors';
import emisStudentRecords from './systemManager/studentRecords';
import acmisStudyLevels from './systemManager/studyLevels';
import chartOfAccount from './universalPayment/chartOfAccount';
import receivable from './universalPayment/receivable';
import transaction from './universalPayment/transaction';
import universalInvoice from './universalPayment/universalInvoice';
import roleGroup from './useraccess-mgt/roleGroup';
import securityProfile from './useraccess-mgt/securityProfile';
import systemApps from './useraccess-mgt/systemApps';
import systemUser from './useraccess-mgt/systemUser';
import userRoles from './useraccess-mgt/userRole';
import timetablePolicy from './institutionPolicy/timetablePolicy';

export default function* root() {
  yield all([
    ...auth,
    ...timetablePolicy,
    ...institutionStructure,
    ...documentSetting,
    ...metadata,
    ...faculty,
    ...college,
    ...department,
    ...courseUnit,
    ...programmeSearch,
    ...programme,
    ...subject,
    ...specialization,
    ...grading,
    ...programmeVersion,
    ...unebSubject,
    ...unebCenter,
    ...semesterLoad,
    ...securityProfile,
    ...userRoles,
    ...roleGroup,
    ...programmeAlias,
    ...otherFees,
    ...systemUser,
    ...systemApps,
    ...feesElements,
    ...academicYears,
    ...feesWaivers,
    ...waiverDiscounts,
    ...events,
    ...admissionForm,
    ...weightingAndSelection,
    ...admissionScheme,
    ...admissionStudentService,
    ...tuition,
    ...functionalFees,
    ...graduationFees,
    ...runningAdmission,
    ...runningAdmissionProgramme,
    ...runningAdmissionCampus,
    ...students,
    ...studentSupport,
    ...student,
    ...admissionApplicant,
    ...applicationHistory,
    ...admittedStudents,
    ...registrationPolicy,
    ...studyLevelPolicy,
    ...courseResittingPolicy,
    ...resultCategoryPolicy,
    ...concededPassPolicy,
    ...otherFeesPolicy,
    ...documentPolicy,
    ...academicFeesPolicy,
    ...applicationFeesPolicy,
    ...admissionFeesPolicy,
    ...surchargePolicy,
    ...retakerPolicy,
    ...extensionPolicy,
    ...feesBillingPolicy,
    ...servicesPolicy,
    ...tuitionFeesPolicy,
    ...resultsBlockingPolicy,
    ...financialTransactions,
    ...financialStatements,
    ...studentRecord,
    ...pendingDirectPost,
    ...pendingVoidedInvoice,
    ...pendingExemptedInvoice,
    ...creditNote,
    ...debitNote,
    ...feesStructure,
    ...studentLedger,
    ...studentEnrollment,
    ...studentRegistration,
    ...semesterEvent,
    ...studentInvoice,
    ...registrationReports,
    ...previousRegistration,
    ...paymentReports,
    ...feesApprovals,
    ...courseAssignment,
    ...semesterCourseLoad,
    ...building,
    ...teachingTimetable,
    ...chartOfAccount,
    ...receivable,
    ...universalInvoice,
    ...resultNode,
    ...graduationList,
    ...senate,
    ...resultDashboard,
    ...studentGrade,
    ...batchResult,
    ...bulkPayments,
    ...previousPayments,
    ...sponsor,
    ...academicDocument,
    ...transaction,
    ...NTCStudent,
    ...NTCSubject,
    ...NTCResult,
    ...academicRegistrar,
    ...financeClearance,
    ...admissionReport,
    ...revenueReport,
    ...notificationReport,
    ...refundTransaction,
    ...resultReport,
    ...lecturerCourses,
    ...pujabInstitutions,
    ...pujabProgrammes,
    ...pujabAdmissions,
    ...pujabReport,
    ...pujabApplicants,
    ...schoolAdmin,
    ...acmisSponsors,
    ...systemScheme,
    ...acmisCampus,
    ...acmisProgrammes,
    ...acmisStudyLevels,
    ...emisStudentRecords,
    ...resultLogs,
    ...searchResult,
    ...electivePosition,
    ...searchData,
    ...userLogs,
    ...shared,
    ...specialEntryAdmissions,
    ...specialEntryApplicants,
    ...specialEntryReport,
    ...resultApprovalPolicy,
  ]);
}
