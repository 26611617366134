import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { departmentActions } from '../../actions';

function* getDepartments(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/departments',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: departmentActions.GET_DEPARTMENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: departmentActions.GET_DEPARTMENTS_ERROR,
      error: error.data,
    });
  }
}

function* createDepartments(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/departments',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: departmentActions.CREATE_DEPARTMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: departmentActions.GET_DEPARTMENTS_REQUEST,
      data: '',
    });
    yield put({
      type: 'SHOW_DEPARTMENT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: departmentActions.CREATE_DEPARTMENT_ERROR,
      error: error.data,
    });
  }
}

function* deleteDepartment(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/departments/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: departmentActions.DELETE_DEPARTMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: departmentActions.GET_DEPARTMENTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: departmentActions.DELETE_DEPARTMENT_ERROR,
      error: error.data,
    });
  }
}
function* updateDepartment(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/departments/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: departmentActions.UPDATE_DEPARTMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: departmentActions.GET_DEPARTMENTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DEPARTMENT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: departmentActions.UPDATE_DEPARTMENT_ERROR,
      error: error.data,
    });
  }
}

function* downloadDepartmentTemplate() {
  try {
    yield axios({
      url: '/programme-mgt/departments/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DEPARTMENT-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: departmentActions.DOWNLOAD_DEPARTMENT_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: departmentActions.DOWNLOAD_DEPARTMENT_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadDepartmentTemplate(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/departments/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: departmentActions.UPLOAD_DEPARTMENT_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({ type: departmentActions.GET_DEPARTMENTS_REQUEST });
    yield put({ type: 'SHOW_UPLOAD_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: departmentActions.UPLOAD_DEPARTMENT_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetDepartments() {
  yield takeLatest(departmentActions.GET_DEPARTMENTS_REQUEST, getDepartments);
}

function* watchCreateDepartments() {
  yield takeLatest(
    departmentActions.CREATE_DEPARTMENT_REQUEST,
    createDepartments
  );
}

function* watchDeleteDepartment() {
  yield takeLatest(
    departmentActions.DELETE_DEPARTMENT_REQUEST,
    deleteDepartment
  );
}

function* watchUpdateDepartment() {
  yield takeLatest(
    departmentActions.UPDATE_DEPARTMENT_REQUEST,
    updateDepartment
  );
}

function* watchDownloadDepartmentTemplate() {
  yield takeLatest(
    departmentActions.DOWNLOAD_DEPARTMENT_TEMPLATE_REQUEST,
    downloadDepartmentTemplate
  );
}

function* watchUploadDepartmentTemplate() {
  yield takeLatest(
    departmentActions.UPLOAD_DEPARTMENT_TEMPLATE_REQUEST,
    uploadDepartmentTemplate
  );
}

export default [
  fork(watchGetDepartments),
  fork(watchCreateDepartments),
  fork(watchDeleteDepartment),
  fork(watchUpdateDepartment),
  fork(watchUploadDepartmentTemplate),
  fork(watchDownloadDepartmentTemplate),
];
