const revenueReport = {
  gettingYearReport: false,
  academicYearReport: [],
  yearReportError: {},

  gettingUnitReport: false,
  academicUnitReport: [],
  unitReportError: {},

  gettingUnitDetailsReport: false,
  academicUnitDetailsReport: [],
  unitDetailsReportError: {},

  downloadingReport: false,
  downloadedReport: {},
  downloadError: {},
};

export default revenueReport;
