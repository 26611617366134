import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { pendingVoidedInvoiceActions } from '../../actions';

function* getPendingVoidedInvoices() {
  try {
    const response = yield axios({
      url: `/registration/invoices/fetch-requests-to-void-invoices`,
      method: 'GET',
    });
    yield put({
      type: pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingVoidedInvoice(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/approve-pending-voided-invoices',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: pendingVoidedInvoiceActions.APPROVE_PENDING_VOIDED_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pendingVoidedInvoiceActions.APPROVE_PENDING_VOIDED_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* rejectPendingVoidedInvoice(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/reject-pending-voided-invoices ',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: pendingVoidedInvoiceActions.REJECT_PENDING_VOIDED_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_REQUEST,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: pendingVoidedInvoiceActions.REJECT_PENDING_VOIDED_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPendingVoidedInvoices() {
  yield takeLatest(
    pendingVoidedInvoiceActions.GET_PENDING_VOIDED_INVOICE_REQUEST,
    getPendingVoidedInvoices
  );
}

function* watchApprovePendingVoidedInvoice() {
  yield takeLatest(
    pendingVoidedInvoiceActions.APPROVE_PENDING_VOIDED_INVOICE_REQUEST,
    approvePendingVoidedInvoice
  );
}

function* watchRejectPendingVoidedInvoice() {
  yield takeLatest(
    pendingVoidedInvoiceActions.REJECT_PENDING_VOIDED_INVOICE_REQUEST,
    rejectPendingVoidedInvoice
  );
}

export default [
  fork(watchGetPendingVoidedInvoices),
  fork(watchApprovePendingVoidedInvoice),
  fork(watchRejectPendingVoidedInvoice),
];
