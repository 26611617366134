import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AlertMessage from '../../components/common/AlertMessage';
import DataSpinner from '../../components/common/DataSpinner';
import { authActions } from '../../config/actions';

function VerifyEmail() {
  const dispatch = useDispatch();
  const verifying = useSelector((state) => state.auth.verifying);
  const verifyEmailError = useSelector((state) => state.auth.verifyEmailError);
  const verifyEmailSuccess = useSelector(
    (state) => state.auth.verifyEmailSuccess
  );
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) dispatch(authActions.verifyEmail(token));
  }, []);

  useEffect(() => {
    if (!isEmpty(verifyEmailError) && verifyEmailError.server)
      setErrorMessage(verifyEmailError.server.message);

    if (!isEmpty(verifyEmailSuccess) && verifyEmailSuccess.server) {
      setSuccessMessage(verifyEmailSuccess.server.message);
    }
  }, [verifyEmailError, verifyEmailSuccess]);

  const redirectToDashboard = () => {
    setTimeout(() => {
      return navigate('/');
    }, 3000);
  };

  return (
    <div className="d-flex min-vh-100">
      <Helmet>
        <title>Email Verification</title>
      </Helmet>
      <div className="h-100 my-auto p-4 mx-auto text-center">
        {verifying && <DataSpinner text="Verifying account..." />}
        {errorMessage && <AlertMessage message={errorMessage} />}
        {successMessage && (
          <div className="text-success">
            <i className="fa fa-check-circle text-success fa-3x mb-3" />
            <p>{successMessage}</p>
            Redirecting...
            {redirectToDashboard()}
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
