import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { semesterEventActions } from '../../actions';
import initialState from '../../initialState';

const semesterEvent = (state = initialState.semesterEvent, actions) => {
  switch (actions.type) {
    case semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_SUCCESS: {
      const { allCurrentSemesterEvents } = state;
      const { data, studentProgrammeId } = actions;

      const responseData = { data, studentProgrammeId };

      let newState = state;

      const findStudentIndex = findIndex(
        allCurrentSemesterEvents,
        (record) => record?.studentProgrammeId === studentProgrammeId
      );

      if (findStudentIndex === -1) {
        newState = update(newState, {
          allCurrentSemesterEvents: { $push: [responseData] },
        });
      } else {
        newState = update(newState, {
          allCurrentSemesterEvents: {
            [findStudentIndex]: { $set: responseData },
          },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }
    case semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };
    default:
      return state;
  }
};
export default semesterEvent;
