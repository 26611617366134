import axios from 'axios';
import FileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { batchResultActions, resultNodeActions } from '../../actions';

function* getBatchResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/batch/range',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: batchResultActions.GET_BATCH_RESULTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.GET_BATCH_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* getBatchDetails(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/batch/results',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: batchResultActions.GET_BATCH_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.GET_BATCH_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getBatchApprovalResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/batch/batch-approval',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: batchResultActions.GET_BATCH_APPROVAL_RESULTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.GET_BATCH_APPROVAL_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* approveBatchResults(actions) {
  const { batchContext, batchNumber } = actions.data;
  try {
    const response = yield axios({
      url: '/results-mgt/batch/approve-batch',
      method: 'POST',
      data: { batchNumber },
    });
    yield put({
      type: batchResultActions.APPROVE_BATCH_RESULTS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_RESULTS_APPROVAL_MODAL', payload: false });
    if (!isEmpty(batchContext)) {
      yield put({
        type: batchResultActions.GET_BATCH_APPROVAL_RESULTS_REQUEST,
        params: batchContext,
      });
      yield put({ type: 'SHOW_MODAL', payload: false });
    }
  } catch (error) {
    yield put({
      type: batchResultActions.APPROVE_BATCH_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* approveSelectedResults(actions) {
  const { data, context } = actions.data;
  try {
    const response = yield axios({
      url: '/results-mgt/batch/approve-result',
      method: 'POST',
      data,
    });
    yield put({
      type: batchResultActions.APPROVE_SELECTED_RESULTS_SUCCESS,
      data: response.data,
    });
    if (context.category) {
      yield put({
        type: resultNodeActions.GET_COURSE_RESULTS_REQUEST,
        data: context,
      });
    } else {
      yield put({
        type: batchResultActions.GET_BATCH_DETAILS_REQUEST,
        params: context,
      });
    }
  } catch (error) {
    yield put({
      type: batchResultActions.APPROVE_SELECTED_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* submitSelectedResultsForApproval(actions) {
  const { data, context } = actions.data;
  try {
    const response = yield axios({
      url: '/results-mgt/batch/submit-result',
      method: 'POST',
      data,
    });
    yield put({
      type: batchResultActions.SUBMIT_SELECTED_RESULTS_SUCCESS,
      data: response.data,
    });
    if (context.category) {
      yield put({
        type: resultNodeActions.GET_COURSE_RESULTS_REQUEST,
        data: context,
      });
    } else {
      yield put({
        type: batchResultActions.GET_BATCH_DETAILS_REQUEST,
        params: context,
      });
    }
  } catch (error) {
    yield put({
      type: batchResultActions.SUBMIT_SELECTED_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* submitBatchForApproval(actions) {
  // const { data, context } = actions.data;
  try {
    const response = yield axios({
      url: '/results-mgt/batch/submit-batch',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: batchResultActions.SUBMIT_BATCH_RESULTS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_RESULTS_APPROVAL_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: batchResultActions.SUBMIT_BATCH_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* deleteBatchResults(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/batch/delete-batch/${actions.batchId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: batchResultActions.DELETE_BATCH_RESULTS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
    yield put({
      type: batchResultActions.GET_BATCH_RESULTS_REQUEST,
      params: actions.params,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.DELETE_BATCH_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* updateBatchDetails(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/batch/update-batch-record/${actions.batchId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: batchResultActions.UPDATE_BATCH_DETAILS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_EDIT_MODAL', payload: false });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
    yield put({
      type: batchResultActions.GET_BATCH_DETAILS_REQUEST,
      params: actions.params,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.UPDATE_BATCH_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* searchResultBatch(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/batch/search-batch`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: batchResultActions.SEARCH_RESULT_BATCH_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.SEARCH_RESULT_BATCH_ERROR,
      error: error.data,
    });
  }
}

function* updateResultDetails(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/batch/delete-batch-record/${actions.batchId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: batchResultActions.DELETE_BATCH_DETAILS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_DELETE_ROOM', payload: false });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
    yield put({
      type: batchResultActions.GET_BATCH_DETAILS_REQUEST,
      params: actions.params,
    });
  } catch (error) {
    yield put({
      type: batchResultActions.DELETE_BATCH_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* downloadBatchResults(actions) {
  try {
    yield axios({
      url: `/results-mgt/batch/download-batch/${actions.batchId}`,
      method: 'GET',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-BATCH RESULTS.xlsx`
      );
    });
    yield put({
      type: batchResultActions.DOWNLOAD_BATCH_RESULTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: batchResultActions.DOWNLOAD_BATCH_RESULTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetBatchResults() {
  yield takeLatest(
    batchResultActions.GET_BATCH_RESULTS_REQUEST,
    getBatchResults
  );
}

function* watchGetBatchDetails() {
  yield takeLatest(
    batchResultActions.GET_BATCH_DETAILS_REQUEST,
    getBatchDetails
  );
}

function* watchGetBatchApprovalResults() {
  yield takeLatest(
    batchResultActions.GET_BATCH_APPROVAL_RESULTS_REQUEST,
    getBatchApprovalResults
  );
}

function* watchApproveBatchResults() {
  yield takeLatest(
    batchResultActions.APPROVE_BATCH_RESULTS_REQUEST,
    approveBatchResults
  );
}

function* watchApproveSelectedResults() {
  yield takeLatest(
    batchResultActions.APPROVE_SELECTED_RESULTS_REQUEST,
    approveSelectedResults
  );
}

function* watchUpdateBatchDetails() {
  yield takeLatest(
    batchResultActions.DELETE_BATCH_RESULTS_REQUEST,
    deleteBatchResults
  );
}

function* watchDeleteBatchResults() {
  yield takeLatest(
    batchResultActions.UPDATE_BATCH_DETAILS_REQUEST,
    updateBatchDetails
  );
}

function* watchDeleteResultsDetails() {
  yield takeLatest(
    batchResultActions.DELETE_BATCH_DETAILS_REQUEST,
    updateResultDetails
  );
}

function* watchSearchResultsBatch() {
  yield takeLatest(
    batchResultActions.SEARCH_RESULT_BATCH_REQUEST,
    searchResultBatch
  );
}

function* watchSubmitSelectedResultsForApproval() {
  yield takeLatest(
    batchResultActions.SUBMIT_SELECTED_RESULTS_REQUEST,
    submitSelectedResultsForApproval
  );
}

function* watchSubmitBatchForApproval() {
  yield takeLatest(
    batchResultActions.SUBMIT_BATCH_RESULTS_REQUEST,
    submitBatchForApproval
  );
}

function* watchDownloadBatchResults() {
  yield takeLatest(
    batchResultActions.DOWNLOAD_BATCH_RESULTS_REQUEST,
    downloadBatchResults
  );
}

export default [
  fork(watchGetBatchResults),
  fork(watchGetBatchDetails),
  fork(watchGetBatchApprovalResults),
  fork(watchApproveSelectedResults),
  fork(watchApproveBatchResults),
  fork(watchDeleteBatchResults),
  fork(watchUpdateBatchDetails),
  fork(watchDeleteResultsDetails),
  fork(watchSearchResultsBatch),
  fork(watchDownloadBatchResults),
  fork(watchSubmitBatchForApproval),
  fork(watchSubmitSelectedResultsForApproval),
];
