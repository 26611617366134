const programmeAlias = {
  CREATE_PROGRAMME_ALIAS_SUCCESS: 'CREATE_PROGRAMME_ALIAS_SUCCESS',
  CREATE_PROGRAMME_ALIAS_REQUEST: 'CREATE_PROGRAMME_ALIAS_REQUEST',
  CREATE_PROGRAMME_ALIAS_ERROR: 'CREATE_PROGRAMME_ALIAS_ERROR',

  DELETE_PROGRAMME_ALIAS_SUCCESS: 'DELETE_PROGRAMME_ALIAS_SUCCESS',
  DELETE_PROGRAMME_ALIAS_REQUEST: 'DELETE_PROGRAMME_ALIAS_REQUEST',
  DELETE_PROGRAMME_ALIAS_ERROR: 'DELETE_PROGRAMME_ALIAS_ERROR',

  UPDATE_PROGRAMME_ALIAS_SUCCESS: 'UPDATE_PROGRAMME_ALIAS_SUCCESS',
  UPDATE_PROGRAMME_ALIAS_REQUEST: 'UPDATE_PROGRAMME_ALIAS_REQUEST',
  UPDATE_PROGRAMME_ALIAS_ERROR: 'UPDATE_PROGRAMME_ALIAS_ERROR',

  createProgrammeAlias: (data) => ({
    type: programmeAlias.CREATE_PROGRAMME_ALIAS_REQUEST,
    data,
  }),

  deleteProgrammeAlias: (programmeAliasId, programmeId) => ({
    type: programmeAlias.DELETE_PROGRAMME_ALIAS_REQUEST,
    programmeAliasId,
    programmeId,
  }),

  updateProgrammeAlias: (programmeAliasId, data) => ({
    type: programmeAlias.UPDATE_PROGRAMME_ALIAS_REQUEST,
    programmeAliasId,
    data,
  }),
};

export default programmeAlias;
