import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { resultsCategoryPolicyActions, tabActions } from '../../actions';

function* createResultsCategoryPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/result-category-policies`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_SUCCESS,
      data: response,
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* createDegreeClassPolicy(actions) {
  try {
    const { data, actionType } = actions;

    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-degree-class-policy${
        actionType === 'copy' ? '/copy' : ''
      }`,
      method: 'POST',
      data,
    });
    yield put({
      type: resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_SUCCESS,
      data: response,
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_DEGREE_CLASS_REQUEST,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* getResultsCategoryPolicy() {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/result-category-policies`,
      method: 'GET',
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* getDegreeClassPolicy() {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-degree-class-policy`,
      method: 'GET',
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_DEGREE_CLASS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.GET_DEGREE_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* editResultsCategoryPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/result-category-policies/update-policy-item/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_REQUEST,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* editDegreeClassPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-degree-class-policy-allocation/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.EDIT_DEGREE_CLASS_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_DEGREE_CLASS_REQUEST,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.EDIT_DEGREE_CLASS_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteResultsCategoryPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/result-category-policies/remove-policy-item/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* deleteDegreeCategoryPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-degree-class-policy-allocation/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: resultsCategoryPolicyActions.GET_DEGREE_CLASS_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateResultsCategoryPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_REQUEST,
    createResultsCategoryPolicy
  );
}

function* watchCreateDegreeClassPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_REQUEST,
    createDegreeClassPolicy
  );
}

function* watchGetResultsCategoryPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_REQUEST,
    getResultsCategoryPolicy
  );
}

function* watchGetDegreeClassPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.GET_DEGREE_CLASS_REQUEST,
    getDegreeClassPolicy
  );
}

function* watchEditResultsCategoryPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_REQUEST,
    editResultsCategoryPolicy
  );
}

function* watchEditDegreeCLassPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.EDIT_DEGREE_CLASS_POLICY_REQUEST,
    editDegreeClassPolicy
  );
}

function* watchDeleteResultsCategoryPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_REQUEST,
    deleteResultsCategoryPolicy
  );
}

function* watchDeleteDegreeCategoryPolicy() {
  yield takeLatest(
    resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_REQUEST,
    deleteDegreeCategoryPolicy
  );
}

export default [
  fork(watchCreateResultsCategoryPolicy),
  fork(watchCreateDegreeClassPolicy),
  fork(watchGetResultsCategoryPolicy),
  fork(watchGetDegreeClassPolicy),
  fork(watchEditResultsCategoryPolicy),
  fork(watchEditDegreeCLassPolicy),
  fork(watchDeleteResultsCategoryPolicy),
  fork(watchDeleteDegreeCategoryPolicy),
];
