const tab = {
  PROGRAMME_TAB_REQUEST: 'PROGRAMME_TAB_REQUEST',

  PROGRAMME_VERSION_TAB: 'PROGRAMME_VERSION_TAB',

  FEES_WAIVERS_TAB: 'FEES_WAIVERS_TAB',

  OTHER_FEES_TAB: 'OTHER_FEES_TAB',

  ADVERTISE_PROGRAMME_TAB: 'ADVERTISE_PROGRAMME_TAB',

  WAIVER_DISCOUNTS_TAB: 'WAIVER_DISCOUNTS_TAB',

  ACADEMIC_YEAR_TAB: 'ACADEMIC_YEAR_TAB',

  ACADEMIC_YEAR_EVENT_TAB: 'ACADEMIC_YEAR_EVENT_TAB',

  TUITION_TAB: 'TUITION_TAB',

  FUNCTIONAL_FEES_TAB: 'FUNCTIONAL_FEES_TAB',

  RUNNING_PROGRAMME_TAB: 'RUNNING_PROGRAMME_TAB',

  SELECTION_TAB: 'SELECTION_TAB',

  CURRENT_TAB: 'CURRENT_TAB',

  CURRENT_REGISTRATION_POLICY_TAB: 'CURRENT_REGISTRATION_POLICY_TAB',

  CURRENT_APPLICATION_POLICY_TAB: 'CURRENT_APPLICATION_POLICY_TAB',

  setCurrentTab: (activeTab) => ({
    type: tab.CURRENT_TAB,
    activeTab,
  }),

  switchProgrammeTab: (activeTab) => ({
    type: tab.PROGRAMME_TAB_REQUEST,
    activeTab,
  }),

  switchProgrammeVersionTab: (activeTab) => ({
    type: tab.PROGRAMME_VERSION_TAB,
    activeTab,
  }),

  switchFeesWaiversTab: (activeTab) => ({
    type: tab.FEES_WAIVERS_TAB,
    activeTab,
  }),

  switchOtherFeesTab: (activeTab) => ({
    type: tab.OTHER_FEES_TAB,
    activeTab,
  }),

  switchWaiverDiscountsTab: (activeTab) => ({
    type: tab.WAIVER_DISCOUNTS_TAB,
    activeTab,
  }),

  switchAcademicYearTab: (activeTab) => ({
    type: tab.ACADEMIC_YEAR_TAB,
    activeTab,
  }),

  switchEventTab: (activeTab) => ({
    type: tab.ACADEMIC_YEAR_EVENT_TAB,
    activeTab,
  }),

  switchAdvertiseProgrammeTab: (activeTab) => ({
    type: tab.ADVERTISE_PROGRAMME_TAB,
    activeTab,
  }),

  switchSelectedRunningProgrammeTab: (activeTab) => ({
    type: tab.RUNNING_PROGRAMME_TAB,
    activeTab,
  }),

  switchSelectionTab: (activeTab) => ({
    type: tab.SELECTION_TAB,
    activeTab,
  }),

  switchTuitionTab: (activeTab) => ({
    type: tab.TUITION_TAB,
    activeTab,
  }),

  switchFunctionalFeesTab: (activeTab) => ({
    type: tab.FUNCTIONAL_FEES_TAB,
    activeTab,
  }),

  setActiveRegistrationTab: (activeTab) => ({
    type: tab.CURRENT_REGISTRATION_POLICY_TAB,
    activeTab,
  }),

  setActiveApplicationPolicyTab: (activeTab) => ({
    type: tab.CURRENT_APPLICATION_POLICY_TAB,
    activeTab,
  }),
};

export default tab;
