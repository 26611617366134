import { documentPolicyActions } from '../../actions';
import initialState from '../../initialState';

const documentPolicy = (state = initialState.documentPolicy, actions) => {
  switch (actions.type) {
    case documentPolicyActions.CREATE_DOCUMENT_POLICY_REQUEST:
      return {
        ...state,
        createdPolicyResponse: {},
        createPolicyError: {},
        creatingPolicy: true,
      };
    case documentPolicyActions.CREATE_DOCUMENT_POLICY_SUCCESS:
      return {
        ...state,
        createdPolicyResponse: actions.data,
        creatingPolicy: false,
      };
    case documentPolicyActions.CREATE_DOCUMENT_POLICY_ERROR:
      return {
        ...state,
        createPolicyError: actions.error,
        creatingPolicy: false,
      };

    case documentPolicyActions.CREATE_REGISTRATION_HISTORY_REQUEST:
      return {
        ...state,
        createdRegHistoryPolicyResponse: {},
        createRegHistoryPolicyError: {},
        creatingRegHistoryPolicy: true,
      };
    case documentPolicyActions.CREATE_REGISTRATION_HISTORY_SUCCESS:
      return {
        ...state,
        createdRegHistoryPolicyResponse: actions.data,
        creatingRegHistoryPolicy: false,
      };
    case documentPolicyActions.CREATE_REGISTRATION_HISTORY_ERROR:
      return {
        ...state,
        createRegHistoryPolicyError: actions.error,
        creatingRegHistoryPolicy: false,
      };

    case documentPolicyActions.GET_DOCUMENT_POLICIES_REQUEST:
      return {
        ...state,
        fetchDocumentPolicyError: {},
        fetchingDocumentPolicies: true,
      };
    case documentPolicyActions.GET_DOCUMENT_POLICIES_SUCCESS:
      return {
        ...state,
        documentPolicies: actions.data,
        fetchingDocumentPolicies: false,
      };
    case documentPolicyActions.GET_DOCUMENT_POLICIES_ERROR:
      return {
        ...state,
        fetchingDocumentPolicies: false,
        fetchDocumentPolicyError: actions.error,
      };

    case documentPolicyActions.DELETE_REGISTRATION_HISTORY_REQUEST:
      return {
        ...state,
        deleteRegHistoryError: {},
        deletingRegHistory: true,
      };
    case documentPolicyActions.DELETE_REGISTRATION_HISTORY_SUCCESS:
      return {
        ...state,
        deleteRegHistoryResponse: actions.data,
        deletingRegHistory: false,
      };
    case documentPolicyActions.DELETE_REGISTRATION_HISTORY_ERROR:
      return {
        ...state,
        deleteRegHistoryError: actions.error,
        deletingRegHistory: false,
      };
    case documentPolicyActions.UPDATE_DOCUMENT_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updatingPolicy: true,
      };
    case documentPolicyActions.UPDATE_DOCUMENT_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updatingPolicy: false,
      };
    case documentPolicyActions.UPDATE_DOCUMENT_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updatingPolicy: false,
      };

    case documentPolicyActions.UPDATE_REGISTRATION_HISTORY_REQUEST:
      return {
        ...state,
        updateRegHistoryPolicyError: {},
        updatingRegHistoryPolicy: true,
      };
    case documentPolicyActions.UPDATE_REGISTRATION_HISTORY_SUCCESS:
      return {
        ...state,
        updateRegHistoryResponse: actions.data,
        updatingRegHistoryPolicy: false,
      };
    case documentPolicyActions.UPDATE_REGISTRATION_HISTORY_ERROR:
      return {
        ...state,
        updateRegHistoryPolicyError: actions.error,
        updatingRegHistoryPolicy: false,
      };

    case documentPolicyActions.DELETE_DOCUMENT_POLICY_REQUEST:
      return {
        ...state,
        deletePolicyError: {},
        deletingPolicy: true,
      };
    case documentPolicyActions.DELETE_DOCUMENT_POLICY_SUCCESS:
      return {
        ...state,
        deletePolicyResponse: actions.data,
        deletingPolicy: false,
      };
    case documentPolicyActions.DELETE_DOCUMENT_POLICY_ERROR:
      return {
        ...state,
        deletePolicyError: actions.error,
        deletingPolicy: false,
      };

    case documentPolicyActions.GET_REG_HISTORY_POLICY_REQUEST:
      return {
        ...state,
        fetchRegHistoryPolicyError: {},
        fetchingRegHistoryPolicies: true,
      };
    case documentPolicyActions.GET_REG_HISTORY_POLICY_SUCCESS:
      return {
        ...state,
        regHistoryPolicies: actions.data,
        fetchingRegHistoryPolicies: false,
      };
    case documentPolicyActions.GET_REG_HISTORY_POLICY_ERROR:
      return {
        ...state,
        fetchingRegHistoryPolicies: false,
        fetchRegHistoryPolicyError: actions.error,
      };

    case documentPolicyActions.SET_DOCUMENT_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policy,
      };

    default:
      return state;
  }
};

export default documentPolicy;
