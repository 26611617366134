import { pujabAdmissionActions } from '../../actions';
import pujabMgtInitialState from '../../initialState/pujab-mgt';

const pujabAdmissions = (
  state = pujabMgtInitialState.pujabAdmission,
  actions
) => {
  switch (actions.type) {
    case pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST:
      return {
        ...state,
        gettingAdmissions: true,
        getAdmissionError: {},
      };
    case pujabAdmissionActions.GET_PUJAB_ADMISSIONS_SUCCESS:
      return {
        ...state,
        gettingAdmissions: false,
        admissions: actions.data,
      };
    case pujabAdmissionActions.GET_PUJAB_ADMISSIONS_ERROR:
      return {
        ...state,
        gettingAdmissions: false,
        getAdmissionError: actions.error,
      };

    case pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_REQUEST:
      return {
        ...state,
        gettingAdmissionInstitution: true,
        getAdmissionInstitutionError: {},
      };
    case pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_SUCCESS:
      return {
        ...state,
        gettingAdmissionInstitution: false,
        admissionInstitution: actions.data,
      };
    case pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_ERROR:
      return {
        ...state,
        gettingAdmissionInstitution: false,
        getAdmissionInstitutionError: actions.error,
      };

    case pujabAdmissionActions.CREATE_PUJAB_ADMISSION_REQUEST:
      return {
        ...state,
        creatingAdmission: true,
        createError: {},
      };
    case pujabAdmissionActions.CREATE_PUJAB_ADMISSION_SUCCESS:
      return {
        ...state,
        creatingAdmission: false,
        createSuccess: actions.data,
      };
    case pujabAdmissionActions.CREATE_PUJAB_ADMISSION_ERROR:
      return {
        ...state,
        creatingAdmission: false,
        createError: actions.error,
      };

    case pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_REQUEST:
      return {
        ...state,
        updatingAdmission: true,
        updateError: {},
      };
    case pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_SUCCESS:
      return {
        ...state,
        updatingAdmission: false,
        updateSuccess: actions.data,
      };
    case pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_ERROR:
      return {
        ...state,
        updatingAdmission: false,
        updateError: actions.error,
      };

    case pujabAdmissionActions.DELETE_PUJAB_ADMISSION_REQUEST:
      return {
        ...state,
        deletingAdmission: true,
        deleteError: {},
      };
    case pujabAdmissionActions.DELETE_PUJAB_ADMISSION_SUCCESS:
      return {
        ...state,
        deletingAdmission: false,
        deleteSuccess: actions.data,
      };
    case pujabAdmissionActions.DELETE_PUJAB_ADMISSION_ERROR:
      return {
        ...state,
        deletingAdmission: false,
        deleteError: actions.error,
      };

    case pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        assigningProgramme: true,
        assignError: {},
      };
    case pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        assigningProgramme: false,
        assignSuccess: actions.data,
      };
    case pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        assigningProgramme: false,
        assignError: actions.error,
      };

    case pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_REQUEST:
      return {
        ...state,
        deletingAdmissionProgramme: true,
        deleteError: {},
      };
    case pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS:
      return {
        ...state,
        deletingAdmissionProgramme: false,
        deleteSuccess: actions.data,
      };
    case pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_ERROR:
      return {
        ...state,
        deletingAdmissionProgramme: false,
        deleteError: actions.error,
      };

    case pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_REQUEST:
      return {
        ...state,
        updatingStatus: true,
        updateStatusError: {},
      };
    case pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_SUCCESS:
      return {
        ...state,
        updatingStatus: false,
        admissionStatus: actions.data,
      };
    case pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_ERROR:
      return {
        ...state,
        updatingStatus: false,
        updateStatusError: actions.error,
      };

    case pujabAdmissionActions.SET_SELECTED_PUJAB_ADMISSION:
      return {
        ...state,
        selectedAdmission: actions.data,
      };

    case pujabAdmissionActions.SET_SELECTED_ADMISSION_INSTITUTION:
      return {
        ...state,
        selectedInstitution: actions.data,
      };

    default:
      return state;
  }
};

export default pujabAdmissions;
