const feesElementsActions = {
  GET_FEES_ELEMENTS_REQUEST: 'GET_FEES_ELEMENTS_REQUEST',
  GET_FEES_ELEMENTS_SUCCESS: 'GET_FEES_ELEMENTS_SUCCESS',
  GET_FEES_ELEMENTS_ERROR: 'GET_FEES_ELEMENTS_ERROR',

  GET_FEES_PREVIEW_REQUEST: 'GET_FEES_PREVIEW_REQUEST',
  GET_FEES_PREVIEW_SUCCESS: 'GET_FEES_PREVIEW_SUCCESS',
  GET_FEES_PREVIEW_ERROR: 'GET_FEES_PREVIEW_ERROR',

  CREATE_FEES_ELEMENTS_REQUEST: 'CREATE_FEES_ELEMENTS_REQUEST',
  CREATE_FEES_ELEMENTS_SUCCESS: 'CREATE_FEES_ELEMENTS_SUCCESS',
  CREATE_FEES_ELEMENTS_ERROR: 'CREATE_FEES_ELEMENTS_ERROR',

  UPDATE_FEES_ELEMENTS_REQUEST: 'UPDATE_FEES_ELEMENTS_REQUEST',
  UPDATE_FEES_ELEMENTS_SUCCESS: 'UPDATE_FEES_ELEMENTS_SUCCESS',
  UPDATE_FEES_ELEMENTS_ERROR: 'UPDATE_FEES_ELEMENTS_ERROR',

  DELETE_FEES_ELEMENTS_REQUEST: 'DELETE_FEES_ELEMENTS_REQUEST',
  DELETE_FEES_ELEMENTS_SUCCESS: 'DELETE_FEES_ELEMENTS_SUCCESS',
  DELETE_FEES_ELEMENTS_ERROR: 'DELETE_FEES_ELEMENTS_ERROR',

  MAKE_FEES_COPY_REQUEST: 'MAKE_FEES_COPY_REQUEST',
  MAKE_FEES_COPY_SUCCESS: 'MAKE_FEES_COPY_SUCCESS',
  MAKE_FEES_COPY_ERROR: 'MAKE_FEES_COPY_ERROR',

  DOWNLOAD_ELEMENTS_TEMPLATE_REQUEST: 'DOWNLOAD_ELEMENTS_TEMPLATE_REQUEST',
  DOWNLOAD_ELEMENTS_TEMPLATE_SUCCESS: 'DOWNLOAD_ELEMENTS_TEMPLATE_SUCCESS',
  DOWNLOAD_ELEMENTS_TEMPLATE_ERROR: 'DOWNLOAD_ELEMENTS_TEMPLATE_ERROR',

  UPLOAD_FEES_ELEMENTS_REQUEST: 'UPLOAD_FEES_ELEMENTS_REQUEST',
  UPLOAD_FEES_ELEMENTS_SUCCESS: 'UPLOAD_FEES_ELEMENTS_SUCCESS',
  UPLOAD_FEES_ELEMENTS_ERROR: 'UPLOAD_FEES_ELEMENTS_ERROR',

  getFeesElements: (data) => ({
    type: feesElementsActions.GET_FEES_ELEMENTS_REQUEST,
    data,
  }),

  getFeesPreview: (data) => ({
    type: feesElementsActions.GET_FEES_PREVIEW_REQUEST,
    data,
  }),

  createFeesElement: (data) => ({
    type: feesElementsActions.CREATE_FEES_ELEMENTS_REQUEST,
    data,
  }),
  deleteFeesElement: (id) => ({
    type: feesElementsActions.DELETE_FEES_ELEMENTS_REQUEST,
    id,
  }),
  updateFeesElement: (id, data) => ({
    type: feesElementsActions.UPDATE_FEES_ELEMENTS_REQUEST,
    id,
    data,
  }),
  makeFeesCopy: (data) => ({
    type: feesElementsActions.MAKE_FEES_COPY_REQUEST,
    data,
  }),
  downloadFeesElementsTemplate: () => ({
    type: feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_REQUEST,
  }),
  uploadFeesElements: (data) => ({
    type: feesElementsActions.UPLOAD_FEES_ELEMENTS_REQUEST,
    data,
  }),
};

export default feesElementsActions;
