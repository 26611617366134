import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { systemCampusActions } from '../../actions';

function* getAcmisCampuses() {
  try {
    const response = yield axios({
      url: `/developer/courses/acmis-campuses`,
      method: 'GET',
    });
    yield put({
      type: systemCampusActions.GET_ACMIS_CAMPUS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_ACMIS_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* getEmisCampuses() {
  try {
    const response = yield axios({
      url: `/developer/courses/campus`,
      method: 'GET',
    });
    yield put({
      type: systemCampusActions.GET_EMIS_CAMPUSES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_EMIS_CAMPUSES_ERROR,
      error: error.data,
    });
  }
}

function* submitCampus(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/campus`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemCampusActions.SUBMIT_SYSTEM_CAMPUS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: systemCampusActions.GET_ACMIS_CAMPUS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.SUBMIT_SYSTEM_CAMPUS_ERROR,
      error: error.data,
    });
  }
}
function* updateCampus(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/manage-campuses`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemCampusActions.UPDATE_ACMIS_CAMPUS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
    yield put({
      type: systemCampusActions.GET_ACMIS_CAMPUS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.UPDATE_ACMIS_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* getEmisAdminRegions() {
  try {
    const response = yield axios({
      url: `/developer/courses/regions`,
      method: 'GET',
    });
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_REGIONS_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_REGIONS_ERROR,
      error: error.data,
    });
  }
}

function* getEmisAdminDistricts(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/district`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_ERROR,
      error: error.data,
    });
  }
}

function* getEmisAdminCounties(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/counties`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_COUNTIES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_COUNTIES_ERROR,
      error: error.data,
    });
  }
}

function* getEmisAdminSubCounties(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/sub-counties`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_ERROR,
      error: error.data,
    });
  }
}
function* getEmisAdminParishes(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/parishes`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_PARISHES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemCampusActions.GET_EMIS_ADMIN_PARISHES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcmisCampuses() {
  yield takeLatest(
    systemCampusActions.GET_ACMIS_CAMPUS_REQUEST,
    getAcmisCampuses
  );
}
function* watchGetEmisCampuses() {
  yield takeLatest(
    systemCampusActions.GET_EMIS_CAMPUSES_REQUEST,
    getEmisCampuses
  );
}
function* watchSubmitCampus() {
  yield takeLatest(
    systemCampusActions.SUBMIT_SYSTEM_CAMPUS_REQUEST,
    submitCampus
  );
}
function* watchUpdateCampus() {
  yield takeLatest(
    systemCampusActions.UPDATE_ACMIS_CAMPUS_REQUEST,
    updateCampus
  );
}
function* watchGetEmisAdminRegions() {
  yield takeLatest(
    systemCampusActions.GET_EMIS_ADMIN_REGIONS_REQUEST,
    getEmisAdminRegions
  );
}
function* watchGetEmisAdminDistricts() {
  yield takeLatest(
    systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_REQUEST,
    getEmisAdminDistricts
  );
}
function* watchGetEmisAdminCounties() {
  yield takeLatest(
    systemCampusActions.GET_EMIS_ADMIN_COUNTIES_REQUEST,
    getEmisAdminCounties
  );
}
function* watchGetEmisAdminSubCounties() {
  yield takeLatest(
    systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_REQUEST,
    getEmisAdminSubCounties
  );
}
function* watchGetEmisAdminParishes() {
  yield takeLatest(
    systemCampusActions.GET_EMIS_ADMIN_PARISHES_REQUEST,
    getEmisAdminParishes
  );
}

export default [
  fork(watchGetAcmisCampuses),
  fork(watchSubmitCampus),
  fork(watchGetEmisCampuses),
  fork(watchUpdateCampus),
  fork(watchGetEmisAdminRegions),
  fork(watchGetEmisAdminDistricts),
  fork(watchGetEmisAdminCounties),
  fork(watchGetEmisAdminSubCounties),
  fork(watchGetEmisAdminParishes),
];
