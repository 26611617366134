import axios from 'axios';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { studentGradeActions } from '../../actions';

function* getStudentGrades(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/graduation/student-list',
      method: 'GET',
      params: actions.context,
      timeout: 120000,
    });
    yield put({
      type: studentGradeActions.GET_STUDENT_GRADES_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentGradeActions.GET_STUDENT_GRADES_ERROR,
      error: error.data,
    });
  }
}

function* generateGrades(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/generate-academic-grades`,
      method: 'POST',
      params: actions.context,
      timeout: 3600000,
      timeoutErrorMessage: 'Unable to complete Result Computation',
    });
    yield put({
      type: studentGradeActions.GENERATE_STUDENT_GRADES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentGradeActions.GET_STUDENT_GRADES_REQUEST,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentGradeActions.GENERATE_STUDENT_GRADES_ERROR,
      error: error.data,
    });
  }
}

function* generateStudentGrades(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/generate-student-grades`,
      method: 'POST',
      data: actions.context,
      timeout: 3600000,
      timeoutErrorMessage: 'Unable to complete Result Computation',
    });
    yield put({
      type: studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStudentGrades() {
  yield takeEvery(
    studentGradeActions.GET_STUDENT_GRADES_REQUEST,
    getStudentGrades
  );
}

function* watchGenerateGrades() {
  yield takeLatest(
    studentGradeActions.GENERATE_STUDENT_GRADES_REQUEST,
    generateGrades
  );
}

function* watchGenerateStudentGrades() {
  yield takeLatest(
    studentGradeActions.GENERATE_SINGLE_STUDENT_GRADES_REQUEST,
    generateStudentGrades
  );
}

export default [
  fork(watchGetStudentGrades),
  fork(watchGenerateGrades),
  fork(watchGenerateStudentGrades),
];
