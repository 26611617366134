import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes, { array, object } from 'prop-types';
import React from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import apps from '../../config/routes/apps';
import { userCan } from '../../helpers/userRoles';

function ActionButtons({
  row,
  deleting,
  openDeletePopup,
  handleEditClick,
  permissions,
  editDisabled,
  deleteDisabled,
  ...props
}) {
  const { currentRole } = useSelector((state) => state.auth);
  return (
    <Dropdown.Button
      menu={{
        items: [
          {
            key: 0,
            label: 'Edit',
            icon: <FaPencilAlt />,
            onClick: () => handleEditClick(row),
            className: 'fw-bold text-uppercase',
            disabled: editDisabled,
          },
          {
            key: 1,
            label: 'Delete',
            icon: <FaTrashAlt />,
            onClick: () => openDeletePopup(row),
            danger: true,
            className: 'fw-bold text-uppercase',
            disabled: deleteDisabled,
          },
        ],
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow
      icon={<DownOutlined />}
      disabled={
        !isEmpty(permissions) &&
        !userCan(permissions, [apps.programmeMgt.app_code], currentRole)
      }
      loading={deleting}
      className="text-sm fw-bold"
      style={{ fontWeight: 'bold !important' }}
      type="primary"
      size="small"
      {...props}
    >
      ACTION
    </Dropdown.Button>
  );
}

ActionButtons.defaultProps = {
  permissions: [],
  editDisabled: false,
  deleteDisabled: false,
};

ActionButtons.propTypes = {
  row: PropTypes.oneOfType([object]).isRequired,
  permissions: PropTypes.oneOfType([array]),
  deleting: PropTypes.bool.isRequired,
  openDeletePopup: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  editDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
};

export default ActionButtons;
