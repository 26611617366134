import update from 'immutability-helper';
import { NTCResultActions } from '../../actions';
import initialState from '../../initialState';

const NTCResult = (state = initialState.NTCResult, actions) => {
  switch (actions.type) {
    case NTCResultActions.GET_NTC_RESULTS_REQUEST:
      return {
        ...state,
        getNTCResultsError: {},
        gettingNTCResults: true,
        NTCResultContext: actions.params,
      };
    case NTCResultActions.GET_NTC_RESULTS_SUCCESS: {
      const { NTCResults } = state;
      const { context, results } = actions;

      const findContextIndex = NTCResults.findIndex(
        (currentContext) =>
          parseInt(context.study_year_id, 10) ===
            parseInt(currentContext.study_year_id, 10) &&
          parseInt(context.academic_year_id, 10) ===
            parseInt(currentContext.academic_year_id, 10)
      );

      const contextData = { ...context, results };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          NTCResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          NTCResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingNTCResults: false,
      };
    }
    case NTCResultActions.GET_NTC_RESULTS_ERROR:
      return {
        ...state,
        getNTCResultsError: actions.error,
        gettingNTCResults: false,
      };

    case NTCResultActions.GET_NTC_PROGRAMMES_REQUEST:
      return {
        ...state,
        getNTCProgrammesError: {},
        gettingNTCProgrammes: true,
      };
    case NTCResultActions.GET_NTC_PROGRAMMES_SUCCESS: {
      const { NTCProgrammes } = state;
      const { context, programmes } = actions;

      const findContextIndex = NTCProgrammes.findIndex(
        (currentContext) =>
          parseInt(context.ntc_study_year_id, 10) ===
            parseInt(currentContext.ntc_study_year_id, 10) &&
          parseInt(context.academic_year_id, 10) ===
            parseInt(currentContext.academic_year_id, 10) &&
          parseInt(context.ntc_term_id, 10) ===
            parseInt(currentContext.ntc_term_id, 10)
      );

      const contextData = { ...context, programmes };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          NTCProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          NTCProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingNTCProgrammes: false,
      };
    }
    case NTCResultActions.GET_NTC_PROGRAMMES_ERROR:
      return {
        ...state,
        getNTCProgrammesError: actions.error,
        gettingNTCProgrammes: false,
      };

    case NTCResultActions.GET_NTC_TERMLY_RESULTS_REQUEST: {
      const { context } = actions;
      return {
        ...state,
        getNTCTermlyResultError: {},
        gettingNTCTermlyResults: true,
        NTCTermlyResultContext: context,
      };
    }
    case NTCResultActions.GET_NTC_TERMLY_RESULTS_SUCCESS: {
      const { NTCProgrammes } = state;
      const { context, results } = actions;

      const findContextIndex = NTCProgrammes.findIndex(
        (currentContext) =>
          parseInt(context.ntc_study_year_id, 10) ===
            parseInt(currentContext.ntc_study_year_id, 10) &&
          parseInt(context.academic_year_id, 10) ===
            parseInt(currentContext.academic_year_id, 10) &&
          parseInt(context.ntc_term_id, 10) ===
            parseInt(currentContext.ntc_term_id, 10) &&
          parseInt(context.ntc_programme, 10) ===
            parseInt(currentContext.ntc_programme, 10)
      );

      const contextData = { ...context, results };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          NTCProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          NTCProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingNTCTermlyResults: false,
      };
    }
    case NTCResultActions.GET_NTC_TERMLY_RESULTS_ERROR:
      return {
        ...state,
        getNTCTermlyResultError: actions.error,
        gettingNTCTermlyResults: false,
      };

    case NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_REQUEST: {
      const { context } = actions;
      return {
        ...state,
        getNTCStudentsDocumentsError: {},
        gettingNTCStudentDocuments: true,
        NTCDocumentContext: context,
      };
    }
    case NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_SUCCESS: {
      const { NTCStudentsDocuments } = state;
      const { context, data } = actions;

      const findContextIndex = NTCStudentsDocuments.findIndex(
        (currentContext) =>
          parseInt(context.ntc_study_year_id, 10) ===
            parseInt(currentContext.ntc_study_year_id, 10) &&
          context.completion_year === currentContext.completion_year &&
          context.programme === currentContext.programme
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          NTCStudentsDocuments: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          NTCStudentsDocuments: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingNTCStudentDocuments: false,
      };
    }
    case NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_ERROR:
      return {
        ...state,
        getNTCStudentsDocumentsError: actions.error,
        gettingNTCStudentDocuments: false,
      };

    case NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_REQUEST:
      return {
        ...state,
        generateDocumentError: {},
        generatingDocuments: true,
      };
    case NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        generateDocumentSuccess: actions.data,
        generatingDocuments: false,
      };
    case NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_ERROR:
      return {
        ...state,
        generateDocumentError: actions.error,
        generatingDocuments: false,
      };

    case NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_REQUEST:
      return {
        ...state,
        downloadDocumentError: {},
        downloadingDocument: true,
      };
    case NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        downloadDocumentSuccess: actions.data,
        downloadingDocument: false,
      };
    case NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_ERROR:
      return {
        ...state,
        downloadDocumentError: actions.error,
        downloadingDocument: false,
      };

    case NTCResultActions.CREATE_NTC_RESULT_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case NTCResultActions.CREATE_NTC_RESULT_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case NTCResultActions.CREATE_NTC_RESULT_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case NTCResultActions.DELETE_NTC_RESULTS_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteError: {},
      };
    case NTCResultActions.DELETE_NTC_RESULTS_SUCCESS:
      return {
        ...state,
        deleteSuccess: actions.data,
        deleting: false,
      };
    case NTCResultActions.DELETE_NTC_RESULTS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case NTCResultActions.UPDATE_NTC_RESULT_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case NTCResultActions.UPDATE_NTC_RESULT_SUCCESS:
      return {
        ...state,
        updateSuccess: actions.data,
        updating: false,
      };
    case NTCResultActions.UPDATE_NTC_RESULT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case NTCResultActions.SET_NTC_RESULT_CONTEXT:
      return {
        ...state,
        NTCTermlyResultContext: actions.context,
      };

    case NTCResultActions.SET_NTC_SELECTED_PROGRAMME:
      return {
        ...state,
        NTCSelectedProgramme: actions.programme,
      };

    case NTCResultActions.SHOW_NTC_MODAL:
      return {
        ...state,
        showNTCModal: actions.payload,
      };

    default:
      return state;
  }
};

export default NTCResult;
