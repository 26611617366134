import { systemAppActions } from '../../actions';
import initialState from '../../initialState';

function systemApp(state = initialState.systemApp, actions) {
  switch (actions.type) {
    case systemAppActions.GET_SYSTEM_APP_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
      };
    case systemAppActions.GET_SYSTEM_APP_SUCCESS:
      return {
        ...state,
        apps: actions.data.applications,
        loading: false,
      };
    case systemAppActions.GET_SYSTEM_APP_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    default:
      return state;
  }
}

export default systemApp;
