import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { tuitionFeesPolicyActions, tabActions } from '../../actions';

function* getTuitionFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/enrollment-status/tuition-fees-policies',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createTuitionFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/enrollment-status/tuition-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: tuitionFeesPolicyActions.CREATE_TUITION_FEES_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: tuitionFeesPolicyActions.CREATE_TUITION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateTuitionFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/enrollment-status/tuition-fees-policies/${actions.tuitionFeesPolicyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: tuitionFeesPolicyActions.UPDATE_TUITION_FEES_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: tuitionFeesPolicyActions.UPDATE_TUITION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteTuitionFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/enrollment-status/tuition-fees-policies/${actions.tuitionFeesPolicyId}`,
      method: 'DELETE',
    });
    yield put({
      type: tuitionFeesPolicyActions.DELETE_TUITION_FEES_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: tuitionFeesPolicyActions.DELETE_TUITION_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTuitionFeesPolicies() {
  yield takeLatest(
    tuitionFeesPolicyActions.GET_TUITION_FEES_POLICIES_REQUEST,
    getTuitionFeesPolicies
  );
}

function* watchCreateTuitionFeesPolicy() {
  yield takeLatest(
    tuitionFeesPolicyActions.CREATE_TUITION_FEES_POLICY_REQUEST,
    createTuitionFeesPolicy
  );
}

function* watchUpdateTuitionFeesPolicy() {
  yield takeLatest(
    tuitionFeesPolicyActions.UPDATE_TUITION_FEES_POLICY_REQUEST,
    updateTuitionFeesPolicy
  );
}

function* watchDeleteTuitionFeesPolicy() {
  yield takeLatest(
    tuitionFeesPolicyActions.DELETE_TUITION_FEES_POLICY_REQUEST,
    deleteTuitionFeesPolicy
  );
}

export default [
  fork(watchGetTuitionFeesPolicies),
  fork(watchCreateTuitionFeesPolicy),
  fork(watchUpdateTuitionFeesPolicy),
  fork(watchDeleteTuitionFeesPolicy),
];
