import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { courseResittingPolicyActions, tabActions } from '../../actions';

function* getCourseResittingPolicies() {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/course-resitting-policy`,
      method: 'GET',
    });
    yield put({
      type: courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/course-resitting-policy`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: courseResittingPolicyActions.CREATE_RESITTING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: courseResittingPolicyActions.CREATE_RESITTING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updatePolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/course-resitting-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: courseResittingPolicyActions.UPDATE_RESITTING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: courseResittingPolicyActions.UPDATE_RESITTING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deletePolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/course-resitting-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: courseResittingPolicyActions.DELETE_RESITTING_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: courseResittingPolicyActions.DELETE_RESITTING_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetCourseResittingPolicies() {
  yield takeLatest(
    courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_REQUEST,
    getCourseResittingPolicies
  );
}

function* watchCreatePolicy() {
  yield takeLatest(
    courseResittingPolicyActions.CREATE_RESITTING_POLICY_REQUEST,
    createPolicy
  );
}

function* watchUpdatePolicy() {
  yield takeLatest(
    courseResittingPolicyActions.UPDATE_RESITTING_POLICY_REQUEST,
    updatePolicy
  );
}

function* watchDeletePolicy() {
  yield takeLatest(
    courseResittingPolicyActions.DELETE_RESITTING_POLICY_REQUEST,
    deletePolicy
  );
}

export default [
  fork(watchGetCourseResittingPolicies),
  fork(watchCreatePolicy),
  fork(watchUpdatePolicy),
  fork(watchDeletePolicy),
];
