const specialEntryAdmissionActions = {
  GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_ERROR',

  GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_ERROR',

  GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_ERROR',

  CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST:
    'CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST',
  CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS:
    'CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS',
  CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR:
    'CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR',

  UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST:
    'UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST',
  UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS:
    'UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS',
  UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR:
    'UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR',

  DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST:
    'DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST',
  DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS:
    'DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS',
  DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR:
    'DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR',

  ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_REQUEST:
    'ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_REQUEST',
  ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_SUCCESS:
    'ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_SUCCESS',
  ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_ERROR:
    'ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_ERROR',

  GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_ERROR',

  UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_REQUEST:
    'UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_REQUEST',
  UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_SUCCESS:
    'UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_SUCCESS',
  UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_ERROR:
    'UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_ERROR',

  SET_SELECTED_SPECIAL_ENTRY_EXAM_ADMISSION:
    'SET_SELECTED_SPECIAL_ENTRY_EXAM_ADMISSION',
  SET_SELECTED_ADMISSION_INSTITUTION: 'SET_SELECTED_ADMISSION_INSTITUTION',

  setSelectedAdmission: (data) => ({
    type: specialEntryAdmissionActions.SET_SELECTED_SPECIAL_ENTRY_EXAM_ADMISSION,
    data,
  }),

  setSelectedAdmissionInstitution: (data) => ({
    type: specialEntryAdmissionActions.SET_SELECTED_ADMISSION_INSTITUTION,
    data,
  }),

  getSpecialEntryAdmissions: () => ({
    type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST,
  }),

  getSpecialEntryProgrammes: () => ({
    type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_REQUEST,
  }),

  getSpecialEntryRooms: () => ({
    type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_REQUEST,
  }),

  createSpecialEntryAdmission: (payload) => ({
    type: specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST,
    payload,
  }),

  updateSpecialEntryAdmission: (admissionId, data) => ({
    type: specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST,
    admissionId,
    data,
  }),

  updateSpecialEntryAdmissionStatus: (admissionId, status) => ({
    type: specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_REQUEST,
    admissionId,
    status,
  }),

  deleteSpecialEntryAdmission: (admissionId) => ({
    type: specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST,
    admissionId,
  }),

  assignSpecialEntryAdmissionProgramme: (runningAdmissionId, data) => ({
    type: specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_REQUEST,
    runningAdmissionId,
    data,
  }),

  getSpecialEntryAdmissionProgramme: (runningAdmissionId) => ({
    type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_REQUEST,
    runningAdmissionId,
  }),
};

export default specialEntryAdmissionActions;
