import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import PDFButtons from './PDFButtons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PDFViewer({ pdfDocument, ...props }) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (pages) => {
    const { numPages: Pages } = pages;
    setNumPages(Pages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div className="mx-auto text-center">
      <Document
        {...props}
        file={pdfDocument}
        onLoadSuccess={onDocumentLoadSuccess}
        className="overflow-scroll mx-auto"
      >
        <Page
          pageNumber={pageNumber}
          className="d-flex justify-content-center overflow-scroll text-center"
        />
      </Document>
      <div className="text-center">
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
      </div>
      <PDFButtons
        pageNumber={pageNumber}
        previousPage={previousPage}
        numPages={numPages}
        nextPage={nextPage}
      />
    </div>
  );
}

PDFViewer.propTypes = {
  pdfDocument: PropTypes.string.isRequired,
};

export default PDFViewer;
