const UniversalInvoice = {
  GET_UNIVERSAL_INVOICES_REQUEST: 'GET_UNIVERSAL_INVOICES_REQUEST',
  GET_UNIVERSAL_INVOICES_SUCCESS: 'GET_UNIVERSAL_INVOICES_SUCCESS',
  GET_UNIVERSAL_INVOICES_ERROR: 'GET_UNIVERSAL_INVOICES_ERROR',

  GET_SINGLE_UNIVERSAL_INVOICE_REQUEST: 'GET_SINGLE_UNIVERSAL_INVOICE_REQUEST',
  GET_SINGLE_UNIVERSAL_INVOICE_SUCCESS: 'GET_SINGLE_UNIVERSAL_INVOICE_SUCCESS',
  GET_SINGLE_UNIVERSAL_INVOICE_ERROR: 'GET_SINGLE_UNIVERSAL_INVOICE_ERROR',

  GET_STAFF_GENERATED_INVOICE_REQUEST: 'GET_STAFF_GENERATED_INVOICE_REQUEST',
  GET_STAFF_GENERATED_INVOICE_SUCCESS: 'GET_STAFF_GENERATED_INVOICE_SUCCESS',
  GET_STAFF_GENERATED_INVOICE_ERROR: 'GET_STAFF_GENERATED_INVOICE_ERROR',

  CREATE_UNIVERSAL_INVOICE_SUCCESS: 'CREATE_UNIVERSAL_INVOICE_SUCCESS',
  CREATE_UNIVERSAL_INVOICE_REQUEST: 'CREATE_UNIVERSAL_INVOICE_REQUEST',
  CREATE_UNIVERSAL_INVOICE_ERROR: 'CREATE_UNIVERSAL_INVOICE_ERROR',

  UPDATE_UNIVERSAL_INVOICE_SUCCESS: 'UPDATE_UNIVERSAL_INVOICE_SUCCESS',
  UPDATE_UNIVERSAL_INVOICE_REQUEST: 'UPDATE_UNIVERSAL_INVOICE_REQUEST',
  UPDATE_UNIVERSAL_INVOICE_ERROR: 'UPDATE_UNIVERSAL_INVOICE_ERROR',

  DELETE_UNIVERSAL_INVOICE_SUCCESS: 'DELETE_UNIVERSAL_INVOICE_SUCCESS',
  DELETE_UNIVERSAL_INVOICE_REQUEST: 'DELETE_UNIVERSAL_INVOICE_REQUEST',
  DELETE_UNIVERSAL_INVOICE_ERROR: 'DELETE_UNIVERSAL_INVOICE_ERROR',

  VOID_OR_APPROVE_STAFF_INVOICE_REQUEST:
    'VOID_OR_APPROVE_STAFF_INVOICE_REQUEST',
  VOID_OR_APPROVE_STAFF_INVOICE_SUCCESS:
    'VOID_OR_APPROVE_STAFF_INVOICE_SUCCESS',
  VOID_OR_APPROVE_STAFF_INVOICE_ERROR: 'VOID_OR_APPROVE_STAFF_INVOICE_ERROR',

  SET_CURRENT_UNIVERSAL_INVOICE_TAB: 'SET_CURRENT_UNIVERSAL_INVOICE_TAB',
  SET_SELECTED_INVOICE_CONTEXT: 'SET_SELECTED_INVOICE_CONTEXT',
  SET_SELECTED_STAFF_INVOICE_CONTEXT: 'SET_SELECTED_STAFF_INVOICE_CONTEXT',
  SHOW_DELETE_UNIVERSAL_INVOICE_MODAL: 'SHOW_DELETE_UNIVERSAL_INVOICE_MODAL',

  getUniversalInvoices: (data) => ({
    type: UniversalInvoice.GET_UNIVERSAL_INVOICES_REQUEST,
    data,
  }),

  getStaffGeneratedInvoices: (data) => ({
    type: UniversalInvoice.GET_STAFF_GENERATED_INVOICE_REQUEST,
    data,
  }),

  voidOrApproveStaffInvoices: (context, params, data) => ({
    type: UniversalInvoice.VOID_OR_APPROVE_STAFF_INVOICE_REQUEST,
    context,
    params,
    data,
  }),

  createUniversalInvoice: (data) => ({
    type: UniversalInvoice.CREATE_UNIVERSAL_INVOICE_REQUEST,
    data,
  }),

  getSingleUniversalInvoice: (invoiceId, data) => ({
    type: UniversalInvoice.GET_SINGLE_UNIVERSAL_INVOICE_REQUEST,
    invoiceId,
    data,
  }),

  updateUniversalInvoice: (invoiceId, data) => ({
    type: UniversalInvoice.UPDATE_UNIVERSAL_INVOICE_REQUEST,
    invoiceId,
    data,
  }),

  deleteUniversalInvoice: (invoiceId) => ({
    type: UniversalInvoice.DELETE_UNIVERSAL_INVOICE_REQUEST,
    invoiceId,
  }),

  setCurrentTab: (currentTab) => ({
    type: UniversalInvoice.SET_CURRENT_UNIVERSAL_INVOICE_TAB,
    currentTab,
  }),

  showDeleteModal: (payload) => ({
    type: UniversalInvoice.SHOW_DELETE_UNIVERSAL_INVOICE_MODAL,
    payload,
  }),

  setSelectedInvoiceContext: (context) => ({
    type: UniversalInvoice.SET_SELECTED_INVOICE_CONTEXT,
    context,
  }),
};

export default UniversalInvoice;
