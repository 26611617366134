import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { specialEntryReportActions } from '../../actions';
import specialEntryMgtInitialState from '../../initialState/specialEntry';

const specialEntryAdmissions = (
  state = specialEntryMgtInitialState.specialEntryReport,
  actions
) => {
  switch (actions.type) {
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST:
      return {
        ...state,
        gettingApplicantsReport: true,
        applicantsReportError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS: {
      const { params, data } = actions;
      const { applicantsReport } = state;

      const findContextIndex = findIndex(
        applicantsReport,
        (list) =>
          list.academicYear === params.academicYear &&
          list.admissionName === params.admissionName
      );

      const contextData = { ...params, ...data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          applicantsReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          applicantsReport: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingApplicantsReport: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR:
      return {
        ...state,
        gettingApplicantsReport: false,
        applicantsReportError: actions.error,
      };

    case specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST:
      return {
        ...state,
        downloadingApplicantsReport: true,
        downloadApplicantsReportError: {},
      };
    case specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS:
      return {
        ...state,
        downloadingApplicantsReport: false,
      };

    case specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR:
      return {
        ...state,
        downloadingApplicantsReport: false,
        downloadApplicantsReportError: actions.error,
      };

    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        gettingReportDetails: true,
        applicantsReportError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_SUCCESS: {
      const { params, data } = actions;
      const { reportDetails } = state;

      const findContextIndex = findIndex(
        reportDetails,
        (list) =>
          list.academicYear === params.academicYear &&
          list.admissionName === params.admissionName
      );

      const contextData = { ...params, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          reportDetails: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          reportDetails: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingReportDetails: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_ERROR:
      return {
        ...state,
        gettingReportDetails: false,
        reportDetailError: actions.error,
      };

    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_REQUEST:
      return {
        ...state,
        gettingResults: true,
        applicantsResultError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_SUCCESS: {
      const { params, data } = actions;
      const { results } = state;

      const findContextIndex = findIndex(
        results,
        (list) =>
          list.academicYear === params.academicYear &&
          list.admissionName === params.admissionName
      );

      const contextData = { ...params, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          results: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          results: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingResults: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_ERROR:
      return {
        ...state,
        gettingResults: false,
        applicantsResultError: actions.error,
      };

    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_REQUEST:
      return {
        ...state,
        gettingAppeals: true,
        applicantsAppealError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_SUCCESS: {
      const { params, data } = actions;
      const { appeals } = state;

      const findContextIndex = findIndex(
        appeals,
        (list) =>
          list.academicYear === params.academicYear &&
          list.admissionName === params.admissionName
      );

      const contextData = { ...params, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          appeals: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          appeals: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingAppeals: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_ERROR:
      return {
        ...state,
        gettingAppeals: false,
        applicantsAppealError: actions.error,
      };

    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_REQUEST:
      return {
        ...state,
        gettingRoomAllocations: true,
        roomAllocationError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_SUCCESS: {
      const { params, data } = actions;
      const { roomAllocations } = state;

      const findContextIndex = findIndex(
        roomAllocations,
        (list) =>
          list.academicYear === params.academicYear &&
          list.admissionName === params.admissionName
      );

      const contextData = { ...params, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          roomAllocations: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          roomAllocations: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingRoomAllocations: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_ERROR:
      return {
        ...state,
        gettingRoomAllocations: false,
        roomAllocationError: actions.error,
      };

    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        gettingPaymentReport: true,
        paymentReportError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_SUCCESS: {
      const { params, data } = actions;
      const { paymentReports } = state;

      const findContextIndex = findIndex(
        paymentReports,
        (list) =>
          list.academicYear === params.academicYear &&
          list.admissionName === params.admissionName
      );

      const contextData = { ...params, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          paymentReports: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          paymentReports: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingPaymentReport: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_ERROR:
      return {
        ...state,
        gettingPaymentReport: false,
        paymentReportError: actions.error,
      };

    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_REQUEST:
      return {
        ...state,
        gettingProgrammeChoices: true,
        programmeChoiceError: {},
      };
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_SUCCESS: {
      const { applicationId, data } = actions;
      const { programmeChoices } = state;

      const findContextIndex = findIndex(
        programmeChoices,
        (list) => list.applicationId === applicationId
      );

      const contextData = { applicationId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          programmeChoices: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          programmeChoices: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingProgrammeChoices: false,
      };
    }
    case specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_ERROR:
      return {
        ...state,
        gettingProgrammeChoices: false,
        programmeChoiceError: actions.error,
      };

    default:
      return state;
  }
};

export default specialEntryAdmissions;
