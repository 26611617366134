import React from 'react';
import { Form } from 'antd';
import { Col, Row } from 'react-bootstrap';
import PropTypes, { array, object } from 'prop-types';
import { filter, join, map, split, toLower, toUpper } from 'lodash';
import AntDInputText from './AntDInputText';
import SubmitButton from './SubmitButton';

function ContextSetter({
  form,
  loading,
  formData,
  submitButtonProps,
  onSubmitForm,
  disableForm,
  layout,
  allSameType,
  rowClassName,
  ...props
}) {
  const fieldName = (label) => {
    const name = toLower(label);
    return join(split(name, ' '), '_');
  };

  const formFields = filter(formData, (field) => !field?.hidden);

  return (
    <>
      <Form
        form={form}
        layout={layout}
        onFinish={onSubmitForm}
        disabled={disableForm || loading}
        labelCol={{
          className: 'pb-1 fw-bold text-sm',
        }}
        {...props}
      >
        <Row className={`g-2 ${rowClassName}`}>
          {map(
            formFields,
            (field) =>
              ((allSameType || field?.type) === 'date' && (
                <Col
                  md={field?.col || null}
                  key={field?.label}
                  style={{ paddingLeft: '1px', paddingRight: '1px' }}
                >
                  <AntDInputText
                    name={field?.name || fieldName(field?.label)}
                    rules={[
                      {
                        required: true,
                        message:
                          field?.message ||
                          `Please ${toLower(field?.label)} is required`,
                      },
                    ]}
                    label={toUpper(field?.label)}
                    itemAttributes={{ format: 'YYYY-MM-DD' }}
                    inputAttributes={{ type: 'date' }}
                    {...field?.fieldProps}
                  />
                </Col>
              )) || (
                <Col md={field?.col || null} key={field?.label}>
                  <AntDInputText
                    name={field?.name || fieldName(field?.label)}
                    type={allSameType || field?.type}
                    options={
                      (allSameType || field?.type) === 'select' &&
                      field?.options
                    }
                    rules={[
                      {
                        required: true,
                        message:
                          field?.message ||
                          `Please ${toLower(field.label)} is required`,
                      },
                    ]}
                    inputAttributes={{
                      popupMatchSelectWidth: field?.matchWidth,
                      placeholder: field?.placeholder || 'SELECT OPTION',
                    }}
                    label={toUpper(field?.label)}
                    {...field?.fieldProps}
                  />
                </Col>
              )
          )}
          <Col md={submitButtonProps?.col || 3}>
            <SubmitButton
              text={submitButtonProps?.text || 'load data'}
              className="fw-bold text-sm text-uppercase"
              block
              loading={loading}
              loadingText="Loading..."
              style={{
                marginTop: layout === 'vertical' && '2.2rem',
                ...submitButtonProps?.style,
              }}
              {...submitButtonProps}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

ContextSetter.defaultProps = {
  layout: 'horizontal',
  loading: false,
  submitButtonProps: {},
  form: undefined,
  disableForm: false,
  allSameType: null,
  rowClassName: '',
};

ContextSetter.propTypes = {
  layout: PropTypes.string,
  disableForm: PropTypes.bool,
  formData: PropTypes.oneOfType([array]).isRequired,
  form: PropTypes.oneOfType([object]),
  onSubmitForm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submitButtonProps: PropTypes.oneOfType([object]),
  allSameType: PropTypes.string,
  rowClassName: PropTypes.string,
};
export default ContextSetter;
