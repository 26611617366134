const unebCenter = {
  GET_UNEB_CENTERS_REQUEST: 'GET_UNEB_CENTERS_REQUEST',
  GET_UNEB_CENTERS_SUCCESS: 'GET_UNEB_CENTERS_SUCCESS',
  GET_UNEB_CENTERS_ERROR: 'GET_UNEB_CENTERS_ERROR',

  GET_SINGLE_UNEB_CENTER_REQUEST: 'GET_SINGLE_UNEB_CENTER_REQUEST',
  GET_SINGLE_UNEB_CENTER_SUCCESS: 'GET_SINGLE_UNEB_CENTER_SUCCESS',
  GET_SINGLE_UNEB_CENTER_ERROR: 'GET_SINGLE_UNEB_CENTER_ERROR',

  CREATE_UNEB_CENTER_SUCCESS: 'CREATE_UNEB_CENTER_SUCCESS',
  CREATE_UNEB_CENTER_REQUEST: 'CREATE_UNEB_CENTER_REQUEST',
  CREATE_UNEB_CENTER_ERROR: 'CREATE_UNEB_CENTER_ERROR',

  UPDATE_UNEB_CENTER_SUCCESS: 'UPDATE_UNEB_CENTER_SUCCESS',
  UPDATE_UNEB_CENTER_REQUEST: 'UPDATE_UNEB_CENTER_REQUEST',
  UPDATE_UNEB_CENTER_ERROR: 'UPDATE_UNEB_CENTER_ERROR',

  DELETE_UNEB_CENTER_REQUEST: 'DELETE_UNEB_CENTER_REQUEST',
  DELETE_UNEB_CENTER_SUCCESS: 'DELETE_UNEB_CENTER_SUCCESS',
  DELETE_UNEB_CENTER_ERROR: 'DELETE_UNEB_CENTER_ERROR',

  DOWNLOAD_UNEB_CENTERS_REQUEST: 'DOWNLOAD_UNEB_CENTERS_REQUEST',
  DOWNLOAD_UNEB_CENTERS_SUCCESS: 'DOWNLOAD_UNEB_CENTERS_SUCCESS',
  DOWNLOAD_UNEB_CENTERS_ERROR: 'DOWNLOAD_UNEB_CENTERS_ERROR',

  UPLOAD_UNEB_CENTERS_REQUEST: 'UPLOAD_UNEB_CENTERS_REQUEST',
  UPLOAD_UNEB_CENTERS_SUCCESS: 'UPLOAD_UNEB_CENTERS_SUCCESS',
  UPLOAD_UNEB_CENTERS_ERROR: 'UPLOAD_UNEB_CENTERS_ERROR',

  getUnebCenters: (data) => ({
    type: unebCenter.GET_UNEB_CENTERS_REQUEST,
    data,
  }),

  getOneUnebCenter: (data) => ({
    type: unebCenter.GET_SINGLE_UNEB_CENTER_REQUEST,
    data,
  }),

  createUnebCenter: (data) => ({
    type: unebCenter.CREATE_UNEB_CENTER_REQUEST,
    data,
  }),

  updateUnebCenter: (data, id) => ({
    type: unebCenter.UPDATE_UNEB_CENTER_REQUEST,
    data,
    id,
  }),

  deleteUnebCenter: (id) => ({
    type: unebCenter.DELETE_UNEB_CENTER_REQUEST,
    id,
  }),

  downloadUnebCenterTemplate: () => ({
    type: unebCenter.DOWNLOAD_UNEB_CENTERS_REQUEST,
  }),

  uploadUnebCenterTemplate: (data) => ({
    type: unebCenter.UPLOAD_UNEB_CENTERS_REQUEST,
    data,
  }),
};

export default unebCenter;
