const otherFeesPolicyActions = {
  GET_OTHER_FEES_POLICIES_REQUEST: 'GET_OTHER_FEES_POLICIES_REQUEST',
  GET_OTHER_FEES_POLICIES_SUCCESS: 'GET_OTHER_FEES_POLICIES_SUCCESS',
  GET_OTHER_FEES_POLICIES_ERROR: 'GET_OTHER_FEES_POLICIES_ERROR',

  CREATE_OTHER_FEES_POLICY_REQUEST: 'CREATE_OTHER_FEES_POLICY_REQUEST',
  CREATE_OTHER_FEES_POLICY_SUCCESS: 'CREATE_OTHER_FEES_POLICY_SUCCESS',
  CREATE_OTHER_FEES_POLICY_ERROR: 'CREATE_OTHER_FEES_POLICY_ERROR',

  UPDATE_OTHER_FEES_POLICY_REQUEST: 'UPDATE_OTHER_FEES_POLICY_REQUEST',
  UPDATE_OTHER_FEES_POLICY_SUCCESS: 'UPDATE_OTHER_FEES_POLICY_SUCCESS',
  UPDATE_OTHER_FEES_POLICY_ERROR: 'UPDATE_OTHER_FEES_POLICY_ERROR',

  DELETE_OTHER_FEES_POLICY_REQUEST: 'DELETE_OTHER_FEES_POLICY_REQUEST',
  DELETE_OTHER_FEES_POLICY_SUCCESS: 'DELETE_OTHER_FEES_POLICY_SUCCESS',
  DELETE_OTHER_FEES_POLICY_ERROR: 'DELETE_OTHER_FEES_POLICY_ERROR',

  SET_OTHER_FEES_POLICY_TO_EDIT: 'SET_OTHER_FEES_POLICY_TO_EDIT',

  getOtherFeesPolicies: (params) => ({
    type: otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_REQUEST,
    params,
  }),

  createOtherFeesPolicy: (data) => ({
    type: otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_REQUEST,
    data,
  }),

  updateOtherFeesPolicy: (otherFeesPolicyId, data) => ({
    type: otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_REQUEST,
    data,
    otherFeesPolicyId,
  }),

  deleteOtherFeesPolicy: (otherFeesPolicyId) => ({
    type: otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_REQUEST,
    otherFeesPolicyId,
  }),

  setPolicyToEdit: (otherFeesPolicy) => ({
    type: otherFeesPolicyActions.SET_OTHER_FEES_POLICY_TO_EDIT,
    otherFeesPolicy,
  }),
};

export default otherFeesPolicyActions;
