import { flattenDepth, toUpper } from 'lodash';
import { programmeActions } from '../../actions';
import initialState from '../../initialState';

function programme(state = initialState.programme, actions) {
  switch (actions.type) {
    case programmeActions.SET_CURRENT_PROGRAMME_DETAIL_TAB:
      return {
        ...state,
        currentTab: actions.currentTab,
      };

    case programmeActions.GET_PROGRAMME_REQUEST:
      return {
        ...state,
        programmeError: {},
        loadError: {},
        loading: true,
      };
    case programmeActions.GET_PROGRAMME_SUCCESS: {
      let allProgrammes = [];
      const newState = {
        ...state,
        groupedProgrammes: actions.data,
        loadError: {},
        loading: false,
      };

      if (toUpper(actions.data?.structure).includes('DEPARTMENTS')) {
        const groupedProgrammes = actions.data?.groupedProgrammes;
        allProgrammes = groupedProgrammes.map(({ programmes }) => programmes);
        newState.programmes = flattenDepth(allProgrammes, 1);
      }

      return newState;
    }
    case programmeActions.GET_PROGRAMME_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };
    case programmeActions.GET_PROGRAMME_DOWNLOAD_REQUEST:
      return {
        ...state,
        downloadError: {},
        loadError: {},
      };
    case programmeActions.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_REQUEST:
      return {
        ...state,
        settingCategory: true,
        setCategoryError: {},
      };
    case programmeActions.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_SUCCESS:
      return {
        ...state,
        settingCategory: false,
      };
    case programmeActions.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_ERROR:
      return {
        ...state,
        settingCategory: false,
        setCategoryError: actions.error,
      };
    case programmeActions.GET_PROGRAMME_DOWNLOAD_SUCCESS:
      return {
        ...state,
        programmeDownloadData: actions.data.programmes,
        downloadError: {},
      };
    case programmeActions.GET_PROGRAMME_WITH_YEARS_REQUEST:
      return {
        ...state,
        gettingProgrammesWithYears: true,
        programmeYearsError: {},
      };
    case programmeActions.GET_PROGRAMME_WITH_YEARS_SUCCESS:
      return {
        ...state,
        gettingProgrammesWithYears: false,
        programmesWithYears: actions.data.programmes,
        programmeYearsError: {},
      };
    case programmeActions.GET_PROGRAMME_WITH_YEARS_ERROR:
      return {
        ...state,
        gettingProgrammesWithYears: false,
        programmeYearsError: actions.error,
      };
    case programmeActions.GET_PROGRAMME_WITH_CATEGORY_REQUEST:
      return {
        ...state,
        programmeError: {},
        loading: true,
      };
    case programmeActions.GET_PROGRAMME_WITH_CATEGORY_SUCCESS:
      return {
        ...state,
        programmesWithCategories: actions.data,
        loading: false,
      };
    case programmeActions.GET_PROGRAMME_WITH_CATEGORY_ERROR:
      return {
        ...state,
        programmeError: actions.error,
        loading: false,
      };

    case programmeActions.GET_SINGLE_PROGRAMME_REQUEST:
      return {
        ...state,
        programmeError: {},
        createdProgrammeData: {},
        fetchingOne: true,
      };
    case programmeActions.GET_SINGLE_PROGRAMME_SUCCESS: {
      const { singleProgrammes } = state;
      const targetIndex = singleProgrammes.findIndex(
        (singleProgramme) => singleProgramme.id === actions.data.id
      );

      if (targetIndex !== -1) singleProgrammes[targetIndex] = actions.data;
      return {
        ...state,
        singleProgrammes:
          targetIndex !== -1
            ? singleProgrammes
            : [...singleProgrammes, actions.data],
        programmeError: {},
        fetchingOne: false,
      };
    }
    case programmeActions.GET_SINGLE_PROGRAMME_ERROR:
      return {
        ...state,
        programmeError: actions.error,
        fetchingOne: false,
      };

    case programmeActions.GET_PROGRAMME_DETAILS_REQUEST:
      return {
        ...state,
        programmeError: {},
        fetchingProgDetails: true,
      };
    case programmeActions.GET_PROGRAMME_DETAILS_SUCCESS: {
      const { programmesDetails } = state;
      const targetIndex = programmesDetails.findIndex(
        (singleProgramme) => singleProgramme.id === actions.data.id
      );

      if (targetIndex !== -1) programmesDetails[targetIndex] = actions.data;
      return {
        ...state,
        programmesDetails:
          targetIndex !== -1
            ? programmesDetails
            : [...programmesDetails, actions.data],
        programmeError: {},
        fetchingProgDetails: false,
      };
    }
    case programmeActions.GET_PROGRAMME_DETAILS_ERROR:
      return {
        ...state,
        programmeError: actions.error,
        fetchingProgDetails: false,
      };

    case programmeActions.CREATE_PROGRAMME_REQUEST:
      return {
        ...state,
        creating: true,
        createProgrammeError: {},
        createdProgrammeData: {},
      };
    case programmeActions.CREATE_PROGRAMME_SUCCESS:
      return {
        ...state,
        creating: false,
        createdProgrammeData: actions.data,
      };
    case programmeActions.CREATE_PROGRAMME_ERROR:
      return {
        ...state,
        creating: false,
        createProgrammeError: actions.error,
      };

    case programmeActions.DELETE_PROGRAMME_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteProgrammeError: {},
        deleteProgrammeData: {},
      };
    case programmeActions.DELETE_PROGRAMME_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteProgrammeData: actions.data,
      };
    case programmeActions.DELETE_PROGRAMME_ERROR:
      return {
        ...state,
        deleting: false,
        deleteProgrammeError: actions.error,
      };

    case programmeActions.UPLOAD_PROGRAMME_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case programmeActions.UPLOAD_PROGRAMME_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadResponse: actions.data,
      };
    case programmeActions.UPLOAD_PROGRAMME_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case programmeActions.DOWNLOAD_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateSuccess: {},
        downloadTemplateError: {},
      };
    case programmeActions.DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case programmeActions.DOWNLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case programmeActions.UPDATE_PROGRAMME_REQUEST:
      return {
        ...state,
        updating: true,
        programmeError: {},
        createdProgrammeData: {},
      };
    case programmeActions.UPDATE_PROGRAMME_SUCCESS:
      return {
        ...state,
        updating: false,
        programme: actions.data,
      };
    case programmeActions.UPDATE_PROGRAMME_ERROR:
      return {
        ...state,
        updating: false,
        programmeError: actions.error,
      };

    case programmeActions.GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case programmeActions.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: actions.data,
      };
    case programmeActions.GET_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
        dashboardError: actions.error,
      };

    case programmeActions.GET_PROGRAMME_BY_DEPARTMENT_REQUEST:
      return {
        ...state,
        gettingDepartmentProgrammes: true,
        departmentProgrammeError: {},
      };
    case programmeActions.GET_PROGRAMME_BY_DEPARTMENT_SUCCESS: {
      const departmentProgrammes = actions.data;

      const newState = {
        ...state,
        gettingDepartmentProgrammes: false,
        departmentProgrammes: actions.data,
      };
      if (typeof departmentProgrammes === 'object') {
        const allProgrammes = departmentProgrammes.map(
          ({ programmes }) => programmes
        );
        newState.programmes = flattenDepth(allProgrammes, 1);
      }

      return newState;
    }

    case programmeActions.GET_PROGRAMME_BY_DEPARTMENT_ERROR:
      return {
        ...state,
        gettingDepartmentProgrammes: false,
        departmentProgrammeError: actions.error,
      };

    case programmeActions.GET_ADMISSIONS_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingAdmissionsProgrammes: true,
        admissionsProgrammesError: {},
      };
    case programmeActions.GET_ADMISSIONS_PROGRAMMES_SUCCESS: {
      const admissionProgrammes = actions.data;

      const newState = {
        ...state,
        gettingAdmissionsProgrammes: false,
        admissionsProgrammes: actions.data,
      };
      if (typeof admissionProgrammes === 'object') {
        const allProgrammes = admissionProgrammes.map(
          ({ programmes }) => programmes
        );
        newState.programmes = flattenDepth(allProgrammes, 1);
      }

      return newState;
    }

    case programmeActions.GET_ADMISSIONS_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingAdmissionsProgrammes: false,
        admissionsProgrammesError: actions.error,
      };

    case programmeActions.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingAcademicUnitProgrammes: true,
        academicUnitProgrammesError: {},
      };
    case programmeActions.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_SUCCESS: {
      return {
        ...state,
        gettingAcademicUnitProgrammes: false,
        academicUnitProgrammes: actions.data,
      };
    }

    case programmeActions.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_ERROR:
      return {
        ...state,
        gettingAcademicUnitProgrammes: false,
        academicUnitProgrammesError: actions.error,
      };
    default:
      return state;
  }
}

export default programme;
