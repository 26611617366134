import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { pujabReportActions } from '../../actions';
import pujabMgtInitialState from '../../initialState/pujab-mgt';

const pujabAdmissions = (state = pujabMgtInitialState.pujabReport, actions) => {
  switch (actions.type) {
    case pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_REQUEST:
      return {
        ...state,
        gettingApplicantsReport: true,
        applicantsReportError: {},
      };
    case pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_SUCCESS: {
      const { academicYear, data } = actions;
      const { applicantsReport } = state;

      const findContextIndex = findIndex(
        applicantsReport,
        (list) => list.academicYear === academicYear
      );

      const contextData = { academicYear, ...data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          applicantsReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          applicantsReport: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingApplicantsReport: false,
      };
    }
    case pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_ERROR:
      return {
        ...state,
        gettingApplicantsReport: false,
        applicantsReportError: actions.error,
      };

    case pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_REQUEST:
      return {
        ...state,
        downloadingApplicantsReport: true,
        downloadApplicantsReportError: {},
      };
    case pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_SUCCESS:
      return {
        ...state,
        downloadingApplicantsReport: false,
      };

    case pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_ERROR:
      return {
        ...state,
        downloadingApplicantsReport: false,
        downloadApplicantsReportError: actions.error,
      };

    case pujabReportActions.GET_PUJAB_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        gettingReportDetails: true,
        applicantsReportError: {},
      };
    case pujabReportActions.GET_PUJAB_REPORT_DETAILS_SUCCESS: {
      const { academicYear, data } = actions;
      const { reportDetails } = state;

      const findContextIndex = findIndex(
        reportDetails,
        (list) => list.academicYear === academicYear
      );

      const contextData = { academicYear, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          reportDetails: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          reportDetails: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingReportDetails: false,
      };
    }
    case pujabReportActions.GET_PUJAB_REPORT_DETAILS_ERROR:
      return {
        ...state,
        gettingReportDetails: false,
        reportDetailError: actions.error,
      };

    case pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_REQUEST:
      return {
        ...state,
        gettingProgrammeChoices: true,
        programmeChoiceError: {},
      };
    case pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_SUCCESS: {
      const { applicationId, data } = actions;
      const { programmeChoices } = state;

      const findContextIndex = findIndex(
        programmeChoices,
        (list) => list.applicationId === applicationId
      );

      const contextData = { applicationId, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          programmeChoices: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          programmeChoices: { [findContextIndex]: { $set: contextData } },
        });
      }
      return {
        ...newState,
        gettingProgrammeChoices: false,
      };
    }
    case pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_ERROR:
      return {
        ...state,
        gettingProgrammeChoices: false,
        programmeChoiceError: actions.error,
      };

    default:
      return state;
  }
};

export default pujabAdmissions;
