import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { searchResultsActions } from '../../actions';

function* searchStudentResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/nodes/search-results',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: searchResultsActions.SEARCH_STUDENT_RESULTS_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: searchResultsActions.SEARCH_STUDENT_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* editSearchedResults(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/nodes/deactivate-results',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_USER_ACTION_AUTH_MODAL', payload: false });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({
      type: searchResultsActions.COMPUTE_SEARCHED_RESULT_REQUEST,
      params: actions.context,
      roleId: actions.data.role_id,
    });
  } catch (error) {
    yield put({
      type: searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* computeSearchedResult(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/generate-student-grades`,
      method: 'POST',
      data: {
        students: [actions.params.student],
      },
    });
    yield put({
      type: searchResultsActions.COMPUTE_SEARCHED_RESULT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: searchResultsActions.SEARCH_STUDENT_RESULTS_REQUEST,
      params: actions.params,
    });
  } catch (error) {
    yield put({
      type: searchResultsActions.COMPUTE_SEARCHED_RESULT_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchStudentResults() {
  yield takeLatest(
    searchResultsActions.SEARCH_STUDENT_RESULTS_REQUEST,
    searchStudentResults
  );
}
function* watchEditSearchedResults() {
  yield takeLatest(
    searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_REQUEST,
    editSearchedResults
  );
}
function* watchComputeSearchedResult() {
  yield takeLatest(
    searchResultsActions.COMPUTE_SEARCHED_RESULT_REQUEST,
    computeSearchedResult
  );
}

export default [
  fork(watchSearchStudentResults),
  fork(watchEditSearchedResults),
  fork(watchComputeSearchedResult),
];
