import { NTCSubjectActions } from '../../actions';
import initialState from '../../initialState';

function NTCSubject(state = initialState.NTCSubject, actions) {
  switch (actions.type) {
    case NTCSubjectActions.GET_NTC_SUBJECTS_REQUEST:
      return {
        ...state,
        NTCSubjectError: {},
        createdNTCSubject: {},
        loading: true,
      };
    case NTCSubjectActions.GET_NTC_SUBJECTS_SUCCESS:
      return {
        ...state,
        NTCSubjects: actions.data,
        NTCSubjectError: {},
        loading: false,
      };
    case NTCSubjectActions.GET_NTC_SUBJECTS_ERROR:
      return {
        ...state,
        NTCSubjectError: actions.error,
        loading: false,
      };
    case NTCSubjectActions.GET_SINGLE_NTC_SUBJECT_REQUEST:
      return {
        ...state,
        NTCSubjectError: {},
        createdNTCSubject: {},
        fetching: true,
      };
    case NTCSubjectActions.GET_SINGLE_NTC_SUBJECT_SUCCESS:
      return {
        ...state,
        NTCSubjects: actions.data,
        NTCSubjectError: {},
        fetching: false,
      };
    case NTCSubjectActions.GET_SINGLE_NTC_SUBJECT_ERROR:
      return {
        ...state,
        NTCSubjectError: actions.error,
        fetching: false,
      };
    case NTCSubjectActions.CREATE_NTC_SUBJECT_REQUEST:
      return {
        ...state,
        creating: true,
        NTCSubjectError: {},
        createdNTCSubject: {},
      };
    case NTCSubjectActions.CREATE_NTC_SUBJECT_SUCCESS:
      return {
        ...state,
        creating: false,
        createdNTCSubject: actions.data,
      };
    case NTCSubjectActions.CREATE_NTC_SUBJECT_ERROR:
      return {
        ...state,
        creating: false,
        NTCSubjectError: actions.error,
      };

    case NTCSubjectActions.DELETE_NTC_SUBJECT_REQUEST:
      return {
        ...state,
        deletingNTCSubject: true,
        deleteNTCSubjectError: {},
      };
    case NTCSubjectActions.DELETE_NTC_SUBJECT_SUCCESS:
      return {
        ...state,
        deletingNTCSubject: false,
        deleteNTCSubjectSuccess: actions.data,
      };
    case NTCSubjectActions.DELETE_NTC_SUBJECT_ERROR:
      return {
        ...state,
        deletingNTCSubject: false,
        deleteNTCSubjectError: actions.error,
      };

    case NTCSubjectActions.UPDATE_NTC_SUBJECT_REQUEST:
      return {
        ...state,
        updating: true,
        NTCSubjectError: {},
        createdNTCSubject: {},
      };
    case NTCSubjectActions.UPDATE_NTC_SUBJECT_SUCCESS:
      return {
        ...state,
        updating: false,
        createdNTCSubject: actions.data,
      };
    case NTCSubjectActions.UPDATE_NTC_SUBJECT_ERROR:
      return {
        ...state,
        updating: false,
        NTCSubjectError: actions.error,
      };

    case NTCSubjectActions.DOWNLOAD_NTC_SUBJECTS_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case NTCSubjectActions.DOWNLOAD_NTC_SUBJECTS_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case NTCSubjectActions.DOWNLOAD_NTC_SUBJECTS_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case NTCSubjectActions.UPLOAD_NTC_SUBJECTS_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
        uploadTemplateSuccess: {},
      };
    case NTCSubjectActions.UPLOAD_NTC_SUBJECTS_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case NTCSubjectActions.UPLOAD_NTC_SUBJECTS_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    default:
      return state;
  }
}

export default NTCSubject;
