const pendingExemptedInvoice = {
  GET_PENDING_EXEMPTED_INVOICE_REQUEST: 'GET_PENDING_EXEMPTED_INVOICE_REQUEST',
  GET_PENDING_EXEMPTED_INVOICE_SUCCESS: 'GET_PENDING_EXEMPTED_INVOICE_SUCCESS',
  GET_PENDING_EXEMPTED_INVOICE_ERROR: 'GET_PENDING_EXEMPTED_INVOICE_ERROR',

  APPROVE_PENDING_EXEMPTED_INVOICE_REQUEST:
    'APPROVE_PENDING_EXEMPTED_INVOICE_REQUEST',
  APPROVE_PENDING_EXEMPTED_INVOICE_SUCCESS:
    'APPROVE_PENDING_EXEMPTED_INVOICE_SUCCESS',
  APPROVE_PENDING_EXEMPTED_INVOICE_ERROR:
    'APPROVE_PENDING_EXEMPTED_INVOICE_ERROR',

  SET_PENDING_EXEMPTED_INVOICE: 'SET_PENDING_EXEMPTED_INVOICE',

  getPendingExemptedInvoices: (studentId) => ({
    type: pendingExemptedInvoice.GET_PENDING_EXEMPTED_INVOICE_REQUEST,
    studentId,
  }),

  approvePendingExemptedInvoice: (data, studentId) => ({
    type: pendingExemptedInvoice.APPROVE_PENDING_EXEMPTED_INVOICE_REQUEST,
    data,
    studentId,
  }),
};
export default pendingExemptedInvoice;
