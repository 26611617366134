import axios from 'axios';
import FileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { registrationReportsActions } from '../../actions';

function* getSummarizedReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: registrationReportsActions.GET_SUMMARIZED_REPORTS_SUCCESS,
      responseData: !isEmpty(response.studentEnrollmentData.enrolledStudents)
        ? response.studentEnrollmentData.enrolledStudents[0]
        : {},
      requestData: actions.data,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_SUMMARIZED_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getDetailedReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/detailed-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: registrationReportsActions.GET_DETAILED_REPORTS_SUCCESS,
      data: response.EnrolledStudentsData.enrolledStudents,
      reportContext: actions.data,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_DETAILED_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getStudentsReportsRecords(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/student-enrollment-records',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: registrationReportsActions.GET_STUDENTS_REPORTS_RECORDS_SUCCESS,
      data: response.studentEnrollmentData.enrolledStudents,
      programmeContext: actions.data,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_STUDENTS_REPORTS_RECORDS_ERROR,
      error: error.data,
    });
  }
}

function* getModularSummarizedReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/modular-report',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: registrationReportsActions.GET_MODULAR_REGISTRATION_SUMMARY_SUCCESS,
      responseData: response.data,
      requestData: actions.context,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_MODULAR_REGISTRATION_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* getModularDetailedReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/modular-report',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: registrationReportsActions.GET_MODULAR_REGISTRATION_DETAILS_SUCCESS,
      data: response.data,
      reportContext: actions.context,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_MODULAR_REGISTRATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getModularStudentsRecords(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/modular-report',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: registrationReportsActions.GET_MODULAR_STUDENT_REGISTRATION_DETAILS_SUCCESS,
      data: response.data,
      programmeContext: actions.context,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_MODULAR_STUDENT_REGISTRATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* downloadFullyPaidRegisteredStudents(actions) {
  try {
    yield axios({
      url: `/registration/enrollment-registration/download-registered`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-${
          actions.data.report_type === 'allRegistered'
            ? 'ALL REGISTERED'
            : 'FULLY-PAID-REGISTERED'
        }-STUDENTS.xlsx`
      );
    });
    yield put({
      type: registrationReportsActions.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadUnRegisteredStudents(actions) {
  try {
    yield axios({
      url: `/registration/enrollment-registration/download-unregistered`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-UNREGISTERED-STUDENTS.xlsx`
      );
    });
    yield put({
      type: registrationReportsActions.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadFacultyRegistrationRecords(actions) {
  try {
    yield axios({
      url: `/registration/enrollment-registration/download-faculty`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-FACULTY-REGISTERED-STUDENTS.xlsx`
      );
    });
    yield put({
      type: registrationReportsActions.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_FACULTY_DOWNLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getSummaryOfRegistrationRecords(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/enrollment-status',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: registrationReportsActions.GET_REGISTRATION_REPORT_SUMMARY_SUCCESS,
      responseData: response.data,
      requestData: actions.data,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_REGISTRATION_REPORT_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* getRegistrationStatistics(actions) {
  try {
    const { data, url } = actions;
    const response = yield axios({
      url: `/registration/enrollment-registration/${url}`,
      method: 'GET',
      params: data,
    });
    yield put({
      type: registrationReportsActions.GET_REGISTRATION_STATISTICS_SUCCESS,
      params: data,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.GET_REGISTRATION_STATISTICS_ERROR,
      error: error.data,
    });
  }
}

function* downloadRegistrationStatistics(actions) {
  try {
    yield axios({
      url: `/registration/enrollment-registration/download-statistics`,
      method: 'POST',
      params: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-REGISTRATION-STATISTICS.xlsx`
      );
    });
    yield put({
      type: registrationReportsActions.DOWNLOAD_REGISTRATION_STATISTICS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: registrationReportsActions.DOWNLOAD_REGISTRATION_STATISTICS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetSummarizedReports() {
  yield takeLatest(
    registrationReportsActions.GET_SUMMARIZED_REPORTS_REQUEST,
    getSummarizedReports
  );
}

function* watchGetDetailedReports() {
  yield takeLatest(
    registrationReportsActions.GET_DETAILED_REPORTS_REQUEST,
    getDetailedReports
  );
}

function* watchGetStudentsReportsRecords() {
  yield takeLatest(
    registrationReportsActions.GET_STUDENTS_REPORTS_RECORDS_REQUEST,
    getStudentsReportsRecords
  );
}

function* watchGetModularSummarizedReports() {
  yield takeLatest(
    registrationReportsActions.GET_MODULAR_REGISTRATION_SUMMARY_REQUEST,
    getModularSummarizedReports
  );
}

function* watchGetModularDetailedReports() {
  yield takeLatest(
    registrationReportsActions.GET_MODULAR_REGISTRATION_DETAILS_REQUEST,
    getModularDetailedReports
  );
}

function* watchGetModularStudentsRecords() {
  yield takeLatest(
    registrationReportsActions.GET_MODULAR_STUDENT_REGISTRATION_DETAILS_REQUEST,
    getModularStudentsRecords
  );
}

function* watchDownloadFullyPaidRegisteredStudents() {
  yield takeLatest(
    registrationReportsActions.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_REQUEST,
    downloadFullyPaidRegisteredStudents
  );
}

function* watchDownloadUnRegisteredStudents() {
  yield takeLatest(
    registrationReportsActions.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_REQUEST,
    downloadUnRegisteredStudents
  );
}

function* watchDownloadFacultyRegistrationRecords() {
  yield takeLatest(
    registrationReportsActions.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_REQUEST,
    downloadFacultyRegistrationRecords
  );
}

function* watchDownloadRegistrationStatistics() {
  yield takeLatest(
    registrationReportsActions.DOWNLOAD_REGISTRATION_STATISTICS_REQUEST,
    downloadRegistrationStatistics
  );
}

function* watchGetSummaryOfRegistrationRecords() {
  yield takeLatest(
    registrationReportsActions.GET_REGISTRATION_REPORT_SUMMARY_REQUEST,
    getSummaryOfRegistrationRecords
  );
}

function* watchGetRegistrationStatistics() {
  yield takeLatest(
    registrationReportsActions.GET_REGISTRATION_STATISTICS_REQUEST,
    getRegistrationStatistics
  );
}

export default [
  fork(watchGetSummarizedReports),
  fork(watchGetDetailedReports),
  fork(watchGetStudentsReportsRecords),
  fork(watchDownloadFullyPaidRegisteredStudents),
  fork(watchDownloadFacultyRegistrationRecords),
  fork(watchDownloadUnRegisteredStudents),
  fork(watchGetSummaryOfRegistrationRecords),
  fork(watchGetRegistrationStatistics),
  fork(watchDownloadRegistrationStatistics),
  fork(watchGetModularSummarizedReports),
  fork(watchGetModularDetailedReports),
  fork(watchGetModularStudentsRecords),
];
