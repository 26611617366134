import update from 'immutability-helper';
import { runningAdmissionActions } from '../../actions';
import initialState from '../../initialState';

function runningAdmission(state = initialState.runningAdmission, actions) {
  switch (actions.type) {
    case runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        runningAdmissions: actions.data.runningAdmissions,
        loading: false,
      };
    case runningAdmissionActions.GET_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case runningAdmissionActions.CREATE_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        created: {},
      };
    case runningAdmissionActions.CREATE_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data.runningAdmission,
      };
    case runningAdmissionActions.CREATE_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case runningAdmissionActions.UPDATE_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updated: {},
      };
    case runningAdmissionActions.UPDATE_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: actions.data.runningAdmission,
      };
    case runningAdmissionActions.UPDATE_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case runningAdmissionActions.START_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        starting: true,
        startError: {},
      };
    case runningAdmissionActions.START_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        starting: false,
      };
    case runningAdmissionActions.START_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        starting: false,
        startError: actions.error.server,
      };

    case runningAdmissionActions.STOP_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        stopping: true,
        startError: {},
      };
    case runningAdmissionActions.STOP_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        stopping: false,
      };
    case runningAdmissionActions.STOP_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        stopping: false,
        stopError: actions.error.server,
      };

    case runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        gettingOne: true,
        getOneError: {},
        runningAdmission: {},
      };
    case runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_SUCCESS: {
      const { data } = actions;
      const { singleRunningAdmissions } = state;
      const findDataIndex = singleRunningAdmissions.findIndex(
        (admission) => admission.id === data.id
      );

      let newState = update(state, {
        singleRunningAdmissions: {
          $push: [data],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          singleRunningAdmissions: { [findDataIndex]: { $set: data } },
        });
      }
      return {
        ...newState,
        gettingOne: false,
        runningAdmission: data,
      };
    }

    case runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case runningAdmissionActions.DELETE_RUNNING_ADMISSION_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
        deleteResponse: {},
      };
    case runningAdmissionActions.DELETE_RUNNING_ADMISSION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case runningAdmissionActions.DELETE_RUNNING_ADMISSION_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case runningAdmissionActions.GET_MANAGED_PROG_DETAILS_REQUEST:
      return {
        ...state,
        managedDetailsError: {},
        fetchingManagedDetails: true,
      };
    case runningAdmissionActions.GET_MANAGED_PROG_DETAILS_SUCCESS: {
      const { managedProgrammeCampuses } = state;
      const { runningAdmissionProgrammeId, data } = actions;
      const findDataIndex = managedProgrammeCampuses.findIndex(
        (campus) =>
          campus.running_admission_programme_id === runningAdmissionProgrammeId
      );

      const newData = {
        data,
        running_admission_programme_id: runningAdmissionProgrammeId,
      };

      let newState = update(state, {
        managedProgrammeCampuses: {
          $push: [newData],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          managedProgrammeCampuses: { [findDataIndex]: { $set: newData } },
        });
      }
      return {
        ...newState,
        fetchingManagedDetails: false,
      };
    }
    case runningAdmissionActions.GET_MANAGED_PROG_DETAILS_ERROR:
      return {
        ...state,
        fetchingManagedDetails: false,
        managedDetailsError: actions.error,
      };

    case 'SET_SELECTED_RUNNING_ADMISSION':
      return {
        ...state,
        selectedRunningAdmission: actions.payload,
      };

    case runningAdmissionActions.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_REQUEST:
      return {
        ...state,
        downloadingSelectedStudents: true,
        downloadSelectedStudentsError: {},
      };
    case runningAdmissionActions.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_SUCCESS:
      return {
        ...state,
        downloadingSelectedStudents: false,
        downloadSelectedStudentsSuccess: actions.data,
      };
    case runningAdmissionActions.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_ERROR:
      return {
        ...state,
        downloadingSelectedStudents: false,
        downloadSelectedStudentsError: actions.data,
      };

    case runningAdmissionActions.DOWNLOAD_ADMISSION_NOT_SELECTED_STUDENTS_REQUEST:
      return {
        ...state,
        downloadingNonSelectedStudents: true,
        downloadNonSelectedStudentsError: {},
      };
    case runningAdmissionActions.DOWNLOAD_ADMISSION_NOT_SELECTED_STUDENTS_SUCCESS:
      return {
        ...state,
        downloadingNonSelectedStudents: false,
        downloadNonSelectedStudentsSuccess: actions.data,
      };
    case runningAdmissionActions.DOWNLOAD_ADMISSION_NOT_SELECTED_STUDENTS_ERROR:
      return {
        ...state,
        downloadingNonSelectedStudents: false,
        downloadNonSelectedStudentsError: actions.data,
      };

    default:
      return state;
  }
}

export default runningAdmission;
