import { departmentActions } from '../../actions';
import initialState from '../../initialState';

function department(state = initialState.department, actions) {
  switch (actions.type) {
    case departmentActions.GET_DEPARTMENTS_REQUEST:
      return {
        ...state,
        departmentError: {},
        createDepartmentError: {},
        loading: true,
      };

    case departmentActions.GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departments: actions.data.departments,
        departmentError: {},
        loading: false,
      };

    case departmentActions.GET_DEPARTMENTS_ERROR:
      return {
        ...state,
        departmentError: actions.error,
        loading: false,
      };

    case departmentActions.CREATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        creating: true,
        departmentError: {},
        createDepartmentError: {},
        createdDepartmentData: {},
      };

    case departmentActions.CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        creating: false,
        createdDepartmentData: actions.data,
      };

    case departmentActions.CREATE_DEPARTMENT_ERROR:
      return {
        ...state,
        creating: false,
        createDepartmentError: actions.error,
      };

    case departmentActions.DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case departmentActions.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case departmentActions.DELETE_DEPARTMENT_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case departmentActions.UPDATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case departmentActions.UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case departmentActions.UPDATE_DEPARTMENT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case departmentActions.DOWNLOAD_DEPARTMENT_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingDepartment: true,
        downloadDepartmentError: {},
      };
    case departmentActions.DOWNLOAD_DEPARTMENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingDepartment: false,
        downloadDepartmentResponse: actions.data,
      };
    case departmentActions.DOWNLOAD_DEPARTMENT_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingDepartment: false,
        downloadDepartmentError: actions.error,
      };

    case departmentActions.UPLOAD_DEPARTMENT_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingDepartment: true,
        uploadDepartmentError: {},
      };
    case departmentActions.UPLOAD_DEPARTMENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingDepartment: false,
        uploadDepartmentResponse: actions.data,
      };
    case departmentActions.UPLOAD_DEPARTMENT_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingDepartment: false,
        uploadDepartmentError: actions.error,
      };

    default:
      return state;
  }
}

export default department;
