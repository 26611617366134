import { uniqBy } from 'lodash';
import { programmeVersionActions } from '../../actions';
import initialState from '../../initialState';

function programmeVersion(state = initialState.programmeVersion, actions) {
  switch (actions.type) {
    case programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_REQUEST:
      return {
        ...state,
        singleVersionError: {},
        gettingSingleVersion: true,
      };
    case programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_SUCCESS:
      return {
        ...state,
        version: actions.data,
        programmeVersions: uniqBy(
          [actions.data, ...state.programmeVersions],
          'id'
        ),
        gettingSingleVersion: false,
      };
    case programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_ERROR:
      return {
        ...state,
        singleVersionError: actions.error,
        gettingSingleVersion: false,
      };

    case programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST:
      return {
        ...state,
        versionCourseUnitError: {},
        loading: true,
      };
    case programmeVersionActions.GET_VERSION_COURSE_UNITS_SUCCESS: {
      let versionCourseUnits = [...state.versionCourseUnits];
      const findIndex = versionCourseUnits.findIndex(
        (version) =>
          parseInt(version.id, 10) ===
          parseInt(actions.data?.programmeVersion.id, 10)
      );
      if (findIndex !== -1) {
        versionCourseUnits[findIndex] = actions.data.programmeVersion;
      } else {
        versionCourseUnits = [
          ...versionCourseUnits,
          actions.data.programmeVersion,
        ];
      }

      return {
        ...state,
        versionCourseUnits,
        loading: false,
      };
    }
    case programmeVersionActions.GET_VERSION_COURSE_UNITS_ERROR:
      return {
        ...state,
        versionCourseUnitError: actions.error,
        loading: false,
      };

    case programmeVersionActions.GET_VERSION_OPTIONS_REQUEST:
      return {
        ...state,
        versionOptionError: {},
        gettingVersionOptions: true,
      };
    case programmeVersionActions.GET_VERSION_OPTIONS_SUCCESS: {
      let versionOptions = [...state.versionOptions];
      const findIndex = versionOptions.findIndex(
        (version) =>
          parseInt(version.id, 10) ===
            parseInt(actions.programmeVersionId, 10) &&
          version.versionOption === actions.versionOption
      );
      if (findIndex !== -1) {
        versionOptions[findIndex] = {
          version: actions.data,
          versionOption: actions.versionOption,
        };
      } else {
        versionOptions = [
          ...versionOptions,
          { ...actions.data, versionOption: actions.versionOption },
        ];
      }

      return {
        ...state,
        versionOptions,
        gettingVersionOptions: false,
      };
    }
    case programmeVersionActions.GET_VERSION_OPTIONS_ERROR:
      return {
        ...state,
        versionOptionError: actions.error,
        gettingVersionOptions: false,
      };

    case programmeVersionActions.CREATE_PROGRAMME_VERSION_REQUEST:
      return {
        ...state,
        creating: true,
        versionError: {},
        createdVersion: {},
      };
    case programmeVersionActions.CREATE_PROGRAMME_VERSION_SUCCESS:
      return {
        ...state,
        creating: false,
        createdVersion: actions.data,
      };
    case programmeVersionActions.CREATE_PROGRAMME_VERSION_ERROR:
      return {
        ...state,
        creating: false,
        versionError: actions.error,
      };
    case programmeVersionActions.UPDATE_PROGRAMME_VERSION_REQUEST:
      return {
        ...state,
        updating: true,
        versionError: {},
        createdVersion: {},
      };
    case programmeVersionActions.UPDATE_PROGRAMME_VERSION_SUCCESS:
      return {
        ...state,
        updating: false,
        createdVersion: actions.data,
      };
    case programmeVersionActions.UPDATE_PROGRAMME_VERSION_ERROR:
      return {
        ...state,
        updating: false,
        versionError: actions.error,
      };

    case programmeVersionActions.DELETE_VERSION_COURSE_UNIT_REQUEST:
      return {
        ...state,
        deletingCourseUnit: true,
        deletingId: actions.id,
        deleteCourseUnitError: {},
      };

    case programmeVersionActions.DELETE_VERSION_COURSE_UNIT_SUCCESS:
      return {
        ...state,
        deletingCourseUnit: false,
        deleteCourseUnitSuccess: actions.data,
      };

    case programmeVersionActions.DELETE_VERSION_COURSE_UNIT_ERROR:
      return {
        ...state,
        deletingCourseUnit: false,
        deleteCourseUnitError: actions.error,
      };

    default:
      return state;
  }
}

export default programmeVersion;
