const applicationFeesPolicyActions = {
  GET_APPLICATION_FEES_POLICIES_REQUEST:
    'GET_APPLICATION_FEES_POLICIES_REQUEST',
  GET_APPLICATION_FEES_POLICIES_SUCCESS:
    'GET_APPLICATION_FEES_POLICIES_SUCCESS',
  GET_APPLICATION_FEES_POLICIES_ERROR: 'GET_APPLICATION_FEES_POLICIES_ERROR',

  CREATE_APPLICATION_FEES_POLICY_REQUEST:
    'CREATE_APPLICATION_FEES_POLICY_REQUEST',
  CREATE_APPLICATION_FEES_POLICY_SUCCESS:
    'CREATE_APPLICATION_FEES_POLICY_SUCCESS',
  CREATE_APPLICATION_FEES_POLICY_ERROR: 'CREATE_APPLICATION_FEES_POLICY_ERROR',

  DELETE_APPLICATION_FEES_POLICY_REQUEST:
    'DELETE_APPLICATION_FEES_POLICY_REQUEST',
  DELETE_APPLICATION_FEES_POLICY_SUCCESS:
    'DELETE_APPLICATION_FEES_POLICY_SUCCESS',
  DELETE_APPLICATION_FEES_POLICY_ERROR: 'DELETE_APPLICATION_FEES_POLICY_ERROR',

  UPDATE_APPLICATION_FEES_POLICY_REQUEST:
    'UPDATE_APPLICATION_FEES_POLICY_REQUEST',
  UPDATE_APPLICATION_FEES_POLICY_SUCCESS:
    'UPDATE_APPLICATION_FEES_POLICY_SUCCESS',
  UPDATE_APPLICATION_FEES_POLICY_ERROR: 'UPDATE_APPLICATION_FEES_POLICY_ERROR',

  SET_SELECTED_APPLICATION_POLICY: 'SET_SELECTED_APPLICATION_POLICY',
  SET_APPLICATION_FEES_POLICY_TO_EDIT: 'SET_APPLICATION_FEES_POLICY_TO_EDIT',

  getApplicationFeesPolicies: () => ({
    type: applicationFeesPolicyActions.GET_APPLICATION_FEES_POLICIES_REQUEST,
  }),

  createApplicationFeesPolicy: (data) => ({
    type: applicationFeesPolicyActions.CREATE_APPLICATION_FEES_POLICY_REQUEST,
    data,
  }),

  setSelectedPolicy: (selectedPolicy) => ({
    type: applicationFeesPolicyActions.SET_SELECTED_APPLICATION_POLICY,
    selectedPolicy,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: applicationFeesPolicyActions.SET_APPLICATION_FEES_POLICY_TO_EDIT,
    policyToEdit,
  }),

  deleteApplicationFeesPolicy: (policyId) => ({
    type: applicationFeesPolicyActions.DELETE_APPLICATION_FEES_POLICY_REQUEST,
    policyId,
  }),

  updateApplicationFeesPolicy: (id, data) => ({
    type: applicationFeesPolicyActions.UPDATE_APPLICATION_FEES_POLICY_REQUEST,
    id,
    data,
  }),
};

export default applicationFeesPolicyActions;
