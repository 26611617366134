const admissionReport = {
  gettingReport: false,
  admissionsReport: [],
  admissionReportError: {},

  gettingProgrammeReport: false,
  admissionsProgrammesReport: [],
  programmeReportError: {},

  gettingAgeReport: false,
  admissionsAgeReport: [],
  ageReportError: {},
};

export default admissionReport;
