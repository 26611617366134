import { programmeSearchActions } from '../../actions';
import initialState from '../../initialState';

function programmeSearch(state = initialState.programmeSearch, actions) {
  switch (actions.type) {
    case programmeSearchActions.SEARCH_BY_PROG_CODE_REQUEST:
      return {
        ...state,
        progSearchError: {},
        searchingProg: true,
      };
    case programmeSearchActions.SEARCH_BY_PROG_CODE_SUCCESS:
      return {
        ...state,
        progSearchResponse: actions.data,
        progSearchError: {},
        searchingProg: false,
      };
    case programmeSearchActions.SEARCH_BY_PROG_CODE_ERROR:
      return {
        ...state,
        progSearchError: actions.error,
        searchingProg: false,
      };
    case programmeSearchActions.SEARCH_BY_COURSE_CODE_REQUEST:
      return {
        ...state,
        courseSearchError: {},
        searchingCourse: true,
      };
    case programmeSearchActions.SEARCH_BY_COURSE_CODE_SUCCESS:
      return {
        ...state,
        courseSearchResponse: actions.data,
        courseSearchError: {},
        searchingCourse: false,
      };
    case programmeSearchActions.SEARCH_BY_COURSE_CODE_ERROR:
      return {
        ...state,
        courseSearchError: actions.error,
        searchingCourse: false,
      };

    case programmeSearchActions.SET_PROG_SEARCH_PARAMETER:
      return {
        ...state,
        searchParameter: actions.data,
      };

    default:
      return state;
  }
}

export default programmeSearch;
