import axios from 'axios';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { clearToken, setToken } from '../../services/storageService';

function* loginUser(actions) {
  try {
    const response = yield axios({
      url: '/users/auth/login',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: authActions.LOGIN_SUCCESS,
      data: response,
    });
    setToken(response.access_token);
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
  } catch (error) {
    yield put({
      type: authActions.LOGIN_ERROR,
      error: error.data,
    });
  }
}

function* logoutUser() {
  try {
    const response = yield axios({
      url: '/users/auth/logout',
      method: 'POST',
    });
    yield put({
      type: authActions.LOGOUT_SUCCESS,
      data: response,
    });
    clearToken();
    yield put({
      type: authActions.REMOVE_AUTH_USER,
    });
  } catch (error) {
    if (error.status === 403 || error.status === 401) clearToken();
    yield put({
      type: authActions.LOGOUT_ERROR,
      error: error.data,
    });
  }
}

function* checkPasswordExpiryStatus() {
  try {
    const response = yield axios({
      url: '/users/auth/check-password-expiry-status',
    });

    yield put({
      type: authActions.CHECK_PASSWORD_EXPIRY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.CHECK_PASSWORD_EXPIRY_ERROR,
      error: error.data,
    });
  }
}

function* fetchUsers(action) {
  try {
    const response = yield axios({
      url: '/users/user-data',
      method: 'GET',
      params: action.options,
    });

    yield put({
      type: authActions.GET_USERS_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.GET_AUTH_USER_APPS_REQUEST,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_USERS_ERROR,
      error: error.data,
    });
  }
}

function* changePassword(action) {
  try {
    const response = yield axios({
      url: '/users/user-data/change-password',
      method: 'PUT',
      data: action.data,
    });

    yield put({
      type: authActions.CHANGE_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.CHANGE_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* changeDefaultPassword(action) {
  try {
    const response = yield axios({
      url: '/users/user-data/change-otp',
      method: 'PUT',
      data: action.data,
    });

    yield put({
      type: authActions.CHANGE_DEFAULT_PASSWORD_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
  } catch (error) {
    yield put({
      type: authActions.CHANGE_DEFAULT_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* sendOTP(action) {
  try {
    const response = yield axios({
      url: '/users/auth/forgot-password/send-otp',
      method: 'POST',
      data: action.data,
    });

    yield put({
      type: authActions.SEND_OTP_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.SEND_OTP_ERROR,
      error: error.data,
    });
  }
}

function* resetOTPPassword(action) {
  try {
    const response = yield axios({
      url: '/users/auth/forgot-password/verify-otp',
      method: 'POST',
      data: action.data,
    });

    yield put({
      type: authActions.RESET_OTP_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.RESET_OTP_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* fetchAuthUser() {
  try {
    const response = yield axios({
      url: '/users/auth/profile',
      method: 'GET',
    });
    yield put({
      type: authActions.GET_AUTH_USER_SUCCESS,
      data: response?.user,
    });
    yield put({
      type: authActions.SET_AUTH_USER,
      user: response.user,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_AUTH_USER_ERROR,
      error: error.data,
    });
  }
}

function* fetchAuthUserApps() {
  try {
    const response = yield axios({
      url: 'users/user-data/user-app-functions',
      method: 'GET',
    });
    yield put({
      type: authActions.GET_AUTH_USER_APPS_SUCCESS,
      data: response.userRoles,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_AUTH_USER_APPS_ERROR,
      error: error.data,
    });
  }
}

function* getAuthUserDepartments() {
  try {
    const response = yield axios({
      url: 'users/user-data/departments',
      method: 'GET',
    });
    yield put({
      type: authActions.GET_AUTH_USER_DEPARTMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_AUTH_USER_DEPARTMENTS_ERROR,
      error: error.data,
    });
  }
}

function* fetchSingleUser(action) {
  try {
    const response = yield axios({
      url: `/users/auth/${action.userId}`,
      method: 'GET',
    });

    yield put({
      type: authActions.GET_SINGLE_USER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_SINGLE_USER_ERROR,
      error: error.data,
    });
  }
}

function* requestEmailVerificationLink(action) {
  try {
    const response = yield axios({
      url: `/email/resend-verification-link`,
      method: 'POST',
      data: action.data,
    });
    yield put({
      type: authActions.EMAIL_VERIFICATION_LINK_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.EMAIL_VERIFICATION_LINK_ERROR,
      error: error.data || error,
    });
  }
}

function* phoneVerificationOTP() {
  try {
    const response = yield axios({
      url: '/users/auth/send-verify-phone-code',
      method: 'POST',
    });
    yield put({
      type: authActions.PHONE_VERIFICATION_OTP_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.PHONE_VERIFICATION_OTP_ERROR,
      error: error.data || error,
    });
  }
}

function* verifyPhone(actions) {
  try {
    const { data } = actions;
    const response = yield axios({
      url: '/users/auth/verify-phone-security-code',
      method: 'POST',
      data,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: authActions.VERIFY_PHONE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.VERIFY_PHONE_ERROR,
      error: error.data || error,
    });
  }
}

function* verifyEmail(action) {
  try {
    const response = yield axios({
      url: `/email/verify/${action.token}`,
      method: 'POST',
    });
    yield put({
      type: authActions.VERIFY_EMAIL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.VERIFY_EMAIL_ERROR,
      error: error.data || error,
    });
  }
}

function* getUserRoleBounds(action) {
  try {
    const { roleId } = action;
    const response = yield axios({
      url: `users/user-role-groups/user-bounds`,
      method: 'GET',
      params: { roleId },
    });
    yield put({
      type: authActions.GET_USER_ROLE_BOUND_SUCCESS,
      data: response.data,
      roleId,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_USER_ROLE_BOUND_ERROR,
      error: error.data || error,
    });
  }
}

function* authenticateUserAction(actions) {
  try {
    const response = yield axios({
      url: `/app/two-factor-auth`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: authActions.AUTHENTICATE_USER_ACTION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: authActions.AUTHENTICATE_USER_ACTION_ERROR,
      error: error.data || error,
    });
  }
}

function* watchLoginUser() {
  yield takeLatest(authActions.LOGIN_REQUEST, loginUser);
}

function* watchLogout() {
  yield takeLatest(authActions.LOGOUT_REQUEST, logoutUser);
}

function* watchFetchUsers() {
  yield takeLatest(authActions.GET_USERS_REQUEST, fetchUsers);
}

function* watchChangePassword() {
  yield takeLatest(authActions.CHANGE_PASSWORD_REQUEST, changePassword);
}

function* watchChangeDefaultPassword() {
  yield takeLatest(
    authActions.CHANGE_DEFAULT_PASSWORD_REQUEST,
    changeDefaultPassword
  );
}

function* watchSendOTP() {
  yield takeLatest(authActions.SEND_OTP_REQUEST, sendOTP);
}

function* watchResetOTPPassword() {
  yield takeLatest(authActions.RESET_OTP_PASSWORD_REQUEST, resetOTPPassword);
}

function* watchFetchSingleUser() {
  yield takeLatest(authActions.GET_SINGLE_USER_REQUEST, fetchSingleUser);
}

function* watchFetchAuthUser() {
  yield takeLatest(authActions.GET_AUTH_USER_REQUEST, fetchAuthUser);
}

function* watchFetchAuthUserApps() {
  yield takeLatest(authActions.GET_AUTH_USER_APPS_REQUEST, fetchAuthUserApps);
}

function* watchGetAuthUserDepartments() {
  yield takeLatest(
    authActions.GET_AUTH_USER_DEPARTMENTS_REQUEST,
    getAuthUserDepartments
  );
}

function* watchRequestEmailVerificationLink() {
  yield takeLatest(
    authActions.EMAIL_VERIFICATION_LINK_REQUEST,
    requestEmailVerificationLink
  );
}

function* watchPhoneVerificationOTP() {
  yield takeLatest(
    authActions.PHONE_VERIFICATION_OTP_REQUEST,
    phoneVerificationOTP
  );
}

function* watchVerifyPhone() {
  yield takeLatest(authActions.VERIFY_PHONE_REQUEST, verifyPhone);
}

function* watchVerifyEmail() {
  yield takeLatest(authActions.VERIFY_EMAIL_REQUEST, verifyEmail);
}

function* watchCheckPasswordExpiryStatus() {
  yield takeLatest(
    authActions.CHECK_PASSWORD_EXPIRY_REQUEST,
    checkPasswordExpiryStatus
  );
}

function* watchGetUserRoleBounds() {
  yield takeEvery(authActions.GET_USER_ROLE_BOUND_REQUEST, getUserRoleBounds);
}

function* watchAuthenticateUserAction() {
  yield takeLatest(
    authActions.AUTHENTICATE_USER_ACTION_REQUEST,
    authenticateUserAction
  );
}

export default [
  fork(watchLoginUser),
  fork(watchLogout),
  fork(watchFetchUsers),
  fork(watchChangePassword),
  fork(watchSendOTP),
  fork(watchResetOTPPassword),
  fork(watchChangeDefaultPassword),
  fork(watchFetchAuthUserApps),
  fork(watchFetchSingleUser),
  fork(watchFetchAuthUser),
  fork(watchGetAuthUserDepartments),
  fork(watchRequestEmailVerificationLink),
  fork(watchPhoneVerificationOTP),
  fork(watchVerifyPhone),
  fork(watchVerifyEmail),
  fork(watchGetUserRoleBounds),
  fork(watchAuthenticateUserAction),
  fork(watchCheckPasswordExpiryStatus),
];
