import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { otherFeesPolicyActions, tabActions } from '../../actions';

function* getOtherFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/other-fees-policies',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createOtherFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/other-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateOtherFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/other-fees-policies/${actions.otherFeesPolicyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteOtherFeesPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/other-fees-policies/${actions.otherFeesPolicyId}`,
      method: 'DELETE',
    });
    yield put({
      type: otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetOtherFeesPolicies() {
  yield takeLatest(
    otherFeesPolicyActions.GET_OTHER_FEES_POLICIES_REQUEST,
    getOtherFeesPolicies
  );
}

function* watchCreateOtherFeesPolicy() {
  yield takeLatest(
    otherFeesPolicyActions.CREATE_OTHER_FEES_POLICY_REQUEST,
    createOtherFeesPolicy
  );
}

function* watchUpdateOtherFeesPolicy() {
  yield takeLatest(
    otherFeesPolicyActions.UPDATE_OTHER_FEES_POLICY_REQUEST,
    updateOtherFeesPolicy
  );
}

function* watchDeleteOtherFeesPolicy() {
  yield takeLatest(
    otherFeesPolicyActions.DELETE_OTHER_FEES_POLICY_REQUEST,
    deleteOtherFeesPolicy
  );
}

export default [
  fork(watchGetOtherFeesPolicies),
  fork(watchCreateOtherFeesPolicy),
  fork(watchUpdateOtherFeesPolicy),
  fork(watchDeleteOtherFeesPolicy),
];
