const resultReport = {
  selectedContext: {},

  downloadingResults: false,
  downloadResultSuccess: {},
  downloadResultError: {},

  gettingResultsReport: false,
  resultsReport: [],
  reportError: {},
};

export default resultReport;
