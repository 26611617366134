import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { gradingActions } from '../../actions';

function* getGradings(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/gradings',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: gradingActions.GET_GRADING_SUCCESS,
      data: response.gradings,
    });
  } catch (error) {
    yield put({
      type: gradingActions.GET_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* getSingleGrading(actions) {
  try {
    const response = yield axios({
      url: `/grading-mgt/gradings/${actions.gradingId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: gradingActions.GET_GRADING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: gradingActions.GET_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* createGrading(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/gradings',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: gradingActions.CREATE_GRADING_SUCCESS,
      data: response,
    });
    yield put({
      type: gradingActions.GET_GRADING_REQUEST,
    });
    yield put({
      type: gradingActions.SET_ACTIVE_GRADING_TAB,
      payload: 'view',
    });
  } catch (error) {
    yield put({
      type: gradingActions.CREATE_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* updateGrading(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/gradings/${actions.gradingId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: gradingActions.UPDATE_GRADING_SUCCESS,
      data: response,
    });
    yield put({
      type: gradingActions.GET_GRADING_REQUEST,
      data: response,
    });
    yield put({
      type: gradingActions.SET_ACTIVE_GRADING_TAB,
      payload: 'view',
    });
  } catch (error) {
    yield put({
      type: gradingActions.UPDATE_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* watchGetGradings() {
  yield takeLatest(gradingActions.GET_GRADING_REQUEST, getGradings);
}

function* watchCreateGradings() {
  yield takeLatest(gradingActions.CREATE_GRADING_REQUEST, createGrading);
}

function* watchUpdateGradings() {
  yield takeLatest(gradingActions.UPDATE_GRADING_REQUEST, updateGrading);
}

function* watchGetSingleGradings() {
  yield takeLatest(gradingActions.GET_SINGLE_GRADING_REQUEST, getSingleGrading);
}

export default [
  fork(watchGetGradings),
  fork(watchCreateGradings),
  fork(watchUpdateGradings),
  fork(watchGetSingleGradings),
];
