/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes, { any } from 'prop-types';

function Fieldset({ title, children, ...props }) {
  return (
    <fieldset {...props}>
      {title && <legend className="text-sm text-primary">{title}</legend>}
      {children}
    </fieldset>
  );
}
Fieldset.defaultProps = {
  title: null,
};

Fieldset.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([any]).isRequired,
};
export default Fieldset;
