const semesterCourseLoad = {
  GET_SEMESTER_COURSE_LOADS_REQUEST: 'GET_SEMESTER_COURSE_LOADS_REQUEST',
  GET_SEMESTER_COURSE_LOADS_SUCCESS: 'GET_SEMESTER_COURSE_LOADS_SUCCESS',
  GET_SEMESTER_COURSE_LOADS_ERROR: 'GET_SEMESTER_COURSE_LOADS_ERROR',

  CREATE_SEMESTER_COURSE_LOAD_SUCCESS: 'CREATE_SEMESTER_COURSE_LOAD_SUCCESS',
  CREATE_SEMESTER_COURSE_LOAD_REQUEST: 'CREATE_SEMESTER_COURSE_LOAD_REQUEST',
  CREATE_SEMESTER_COURSE_LOAD_ERROR: 'CREATE_SEMESTER_COURSE_LOAD_ERROR',

  DELETE_SEMESTER_COURSE_LOAD_SUCCESS: 'DELETE_SEMESTER_COURSE_LOAD_SUCCESS',
  DELETE_SEMESTER_COURSE_LOAD_REQUEST: 'DELETE_SEMESTER_COURSE_LOAD_REQUEST',
  DELETE_SEMESTER_COURSE_LOAD_ERROR: 'DELETE_SEMESTER_COURSE_LOAD_ERROR',

  UPDATE_SEMESTER_COURSE_LOAD_SUCCESS: 'UPDATE_SEMESTER_COURSE_LOAD_SUCCESS',
  UPDATE_SEMESTER_COURSE_LOAD_REQUEST: 'UPDATE_SEMESTER_COURSE_LOAD_REQUEST',
  UPDATE_SEMESTER_COURSE_LOAD_ERROR: 'UPDATE_SEMESTER_COURSE_LOAD_ERROR',

  SET_SEMESTER_COURSE_LOAD_TAB: 'SET_SEMESTER_COURSE_LOAD_TAB',
  SHOW_EDIT_SEMESTER_COURSE_LOAD: 'SHOW_EDIT_SEMESTER_COURSE_LOAD',

  getSemesterCourseLoads: (context) => ({
    type: semesterCourseLoad.GET_SEMESTER_COURSE_LOADS_REQUEST,
    context,
  }),

  createSemesterCourseLoad: (data, context) => ({
    type: semesterCourseLoad.CREATE_SEMESTER_COURSE_LOAD_REQUEST,
    data,
    context,
  }),

  deleteSemesterCourseLoad: (id) => ({
    type: semesterCourseLoad.DELETE_SEMESTER_COURSE_LOAD_REQUEST,
    id,
  }),

  updateSemesterCourseLoad: (id, data, context) => ({
    type: semesterCourseLoad.UPDATE_SEMESTER_COURSE_LOAD_REQUEST,
    context,
    id,
    data,
  }),

  setCurrentTab: (tab) => ({
    type: semesterCourseLoad.SET_SEMESTER_COURSE_LOAD_TAB,
    tab,
  }),

  showEditModal: (payload = false) => ({
    type: semesterCourseLoad.SHOW_EDIT_SEMESTER_COURSE_LOAD,
    payload,
  }),
};

export default semesterCourseLoad;
