const searchResultsActions = {
  SEARCH_STUDENT_RESULTS_REQUEST: 'SEARCH_STUDENT_RESULTS_REQUEST',
  SEARCH_STUDENT_RESULTS_SUCCESS: 'SEARCH_STUDENT_RESULTS_SUCCESS',
  SEARCH_STUDENT_RESULTS_ERROR: 'SEARCH_STUDENT_RESULTS_ERROR',

  EDIT_SEARCHED_STUDENT_RESULT_REQUEST: 'EDIT_SEARCHED_STUDENT_RESULT_REQUEST',
  EDIT_SEARCHED_STUDENT_RESULT_SUCCESS: 'EDIT_SEARCHED_STUDENT_RESULT_SUCCESS',
  EDIT_SEARCHED_STUDENT_RESULT_ERROR: 'EDIT_SEARCHED_STUDENT_RESULT_ERROR',

  COMPUTE_SEARCHED_RESULT_REQUEST: 'COMPUTE_SEARCHED_RESULT_REQUEST',
  COMPUTE_SEARCHED_RESULT_SUCCESS: 'COMPUTE_SEARCHED_RESULT_SUCCESS',
  COMPUTE_SEARCHED_RESULT_ERROR: 'COMPUTE_SEARCHED_RESULT_ERROR',

  SET_CURRENT_STUDENT_SEARCHED: 'SET_CURRENT_STUDENT_SEARCHED',

  searchStudentResults: (params, roleId) => ({
    type: searchResultsActions.SEARCH_STUDENT_RESULTS_REQUEST,
    params,
    roleId,
  }),

  editSearchedStudentResults: (data, context) => ({
    type: searchResultsActions.EDIT_SEARCHED_STUDENT_RESULT_REQUEST,
    data,
    context,
  }),

  computeSearchedResults: (params) => ({
    type: searchResultsActions.COMPUTE_SEARCHED_RESULT_REQUEST,
    params,
  }),

  setCurrentStudentSearched: (data) => ({
    type: searchResultsActions.SET_CURRENT_STUDENT_SEARCHED,
    data,
  }),
};

export default searchResultsActions;
