import update from 'immutability-helper';
import { admissionStudentServiceActions } from '../../actions';
import initialState from '../../initialState';

function admissionService(state = initialState.admissionService, actions) {
  switch (actions.type) {
    case admissionStudentServiceActions.GET_STUDENTS_SERVICES_REQUEST:
      return {
        ...state,
        loadServiceError: {},
        loadingServices: true,
      };
    case admissionStudentServiceActions.GET_STUDENTS_SERVICES_SUCCESS: {
      const { context, data } = actions;
      const { admissionServices } = state;

      const findContextIndex = admissionServices.findIndex(
        (currentContext) =>
          context.service === currentContext.service &&
          context.academicYear === currentContext.academicYear &&
          context.status === currentContext.status
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          admissionServices: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          admissionServices: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loadingServices: false,
      };
    }
    case admissionStudentServiceActions.GET_STUDENTS_SERVICES_ERROR:
      return {
        ...state,
        loadServiceError: actions.error,
        loadingServices: false,
      };

    case admissionStudentServiceActions.GET_SERVICE_PROGRAMMES_REQUEST:
      return {
        ...state,
        loadProgrammesError: {},
        loadingServiceProg: true,
      };
    case admissionStudentServiceActions.GET_SERVICE_PROGRAMMES_SUCCESS:
      return {
        ...state,
        serviceProgrammes: actions.data,
        loadingServiceProg: false,
      };
    case admissionStudentServiceActions.GET_SERVICE_PROGRAMMES_ERROR:
      return {
        ...state,
        loadProgrammesError: actions.error,
        loadingServiceProg: false,
      };

    case admissionStudentServiceActions.GET_SERVICES_EVENT_REQUEST:
      return {
        ...state,
        serviceEventError: {},
        loadingServiceEvent: true,
      };
    case admissionStudentServiceActions.GET_SERVICES_EVENT_SUCCESS: {
      const { service, studentId, data } = actions;
      const { serviceEvents } = state;

      const findContextIndex = serviceEvents.findIndex(
        (currentContext) =>
          service === currentContext.service &&
          studentId === currentContext.studentId
      );

      const contextData = { service, studentId, ...data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          serviceEvents: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          serviceEvents: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loadingServiceEvent: false,
      };
    }
    case admissionStudentServiceActions.GET_SERVICES_EVENT_ERROR:
      return {
        ...state,
        serviceEventError: actions.error,
        loadingServiceEvent: false,
      };

    case admissionStudentServiceActions.ADD_STUDENTS_SERVICES_REQUEST:
      return {
        ...state,
        submitApplicationError: {},
        submittingApplication: true,
      };
    case admissionStudentServiceActions.ADD_STUDENTS_SERVICES_SUCCESS:
      return {
        ...state,
        submittedApplication: actions.data,
        submittingApplication: false,
      };
    case admissionStudentServiceActions.ADD_STUDENTS_SERVICES_ERROR:
      return {
        ...state,
        submitApplicationError: actions.error,
        submittingApplication: false,
      };

    case admissionStudentServiceActions.APPROVE_SERVICE_REQUESTS_REQUEST:
      return {
        ...state,
        approveServiceError: {},
        approvingService: true,
      };
    case admissionStudentServiceActions.APPROVE_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        approveServiceResponse: actions.data,
        approvingService: false,
      };
    case admissionStudentServiceActions.APPROVE_SERVICE_REQUESTS_ERROR:
      return {
        ...state,
        approveServiceError: actions.error,
        approvingService: false,
      };

    case admissionStudentServiceActions.EDIT_SERVICE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        editServiceError: {},
        editingServiceAcadYear: true,
      };
    case admissionStudentServiceActions.EDIT_SERVICE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        editServiceAcadYearResponse: actions.data,
        editingServiceAcadYear: false,
      };
    case admissionStudentServiceActions.EDIT_SERVICE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        editServiceError: actions.error,
        editingServiceAcadYear: false,
      };

    case admissionStudentServiceActions.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_REQUEST:
      return {
        ...state,
        acceptOrDeclineServiceError: {},
        acceptingOrDecliningService: true,
      };
    case admissionStudentServiceActions.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        acceptOrDeclineServiceResponse: actions.data,
        acceptingOrDecliningService: false,
      };
    case admissionStudentServiceActions.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_ERROR:
      return {
        ...state,
        acceptOrDeclineServiceError: actions.error,
        acceptingOrDecliningService: false,
      };

    case admissionStudentServiceActions.DOWNLOAD_SERVICE_REQUEST_REPORT_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
        downloadedReport: {},
      };
    case admissionStudentServiceActions.DOWNLOAD_SERVICE_REQUEST_REPORT_SUCCESS:
      return {
        ...state,
        downloadingReport: false,
        downloadedReport: actions.data,
      };
    case admissionStudentServiceActions.DOWNLOAD_SERVICE_REQUEST_REPORT_ERROR:
      return {
        ...state,
        downloadingReport: false,
        downloadError: actions.data,
      };

    case admissionStudentServiceActions.SET_SHOW_CREATE_FORM:
      return {
        ...state,
        showCreateForm: actions.payload,
      };

    case admissionStudentServiceActions.SET_COP_CONTEXT:
      return {
        ...state,
        copContext: actions.data,
      };

    default:
      return state;
  }
}

export default admissionService;
