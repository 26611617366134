const admissionForm = {
  GET_ADMISSION_FORM_REQUEST: 'GET_ADMISSION_FORM_REQUEST',
  GET_ADMISSION_FORM_SUCCESS: 'GET_ADMISSION_FORM_SUCCESS',
  GET_ADMISSION_FORM_ERROR: 'GET_ADMISSION_FORM_ERROR',

  GET_SINGLE_ADMISSION_FORM_REQUEST: 'GET_SINGLE_ADMISSION_FORM_REQUEST',
  GET_SINGLE_ADMISSION_FORM_SUCCESS: 'GET_SINGLE_ADMISSION_FORM_SUCCESS',
  GET_SINGLE_ADMISSION_FORM_ERROR: 'GET_SINGLE_ADMISSION_FORM_ERROR',

  CREATE_ADMISSION_FORM_SUCCESS: 'CREATE_ADMISSION_FORM_SUCCESS',
  CREATE_ADMISSION_FORM_REQUEST: 'CREATE_ADMISSION_FORM_REQUEST',
  CREATE_ADMISSION_FORM_ERROR: 'CREATE_ADMISSION_FORM_ERROR',

  UPDATE_ADMISSION_FORM_SUCCESS: 'UPDATE_ADMISSION_FORM_SUCCESS',
  UPDATE_ADMISSION_FORM_REQUEST: 'UPDATE_ADMISSION_FORM_REQUEST',
  UPDATE_ADMISSION_FORM_ERROR: 'UPDATE_ADMISSION_FORM_ERROR',

  DELETE_ADMISSION_FORM_SUCCESS: 'DELETE_ADMISSION_FORM_SUCCESS',
  DELETE_ADMISSION_FORM_REQUEST: 'DELETE_ADMISSION_FORM_REQUEST',
  DELETE_ADMISSION_FORM_ERROR: 'DELETE_ADMISSION_FORM_ERROR',

  getAdmissionForms: (data) => ({
    type: admissionForm.GET_ADMISSION_FORM_REQUEST,
    data,
  }),

  createAdmissionForm: (data) => ({
    type: admissionForm.CREATE_ADMISSION_FORM_REQUEST,
    data,
  }),

  getSingleAdmissionForm: (admissionFormId, data) => ({
    type: admissionForm.GET_SINGLE_ADMISSION_FORM_REQUEST,
    admissionFormId,
    data,
  }),

  updateAdmissionForm: (admissionFormId, data) => ({
    type: admissionForm.UPDATE_ADMISSION_FORM_REQUEST,
    admissionFormId,
    data,
  }),

  deleteAdmissionForm: (admissionFormId) => ({
    type: admissionForm.DELETE_ADMISSION_FORM_REQUEST,
    admissionFormId,
  }),
};

export default admissionForm;
