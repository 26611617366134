import { feesElementsActions } from '../../actions';
import initialState from '../../initialState';

const feesElements = (state = initialState.feesElements, actions) => {
  switch (actions.type) {
    case feesElementsActions.GET_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case feesElementsActions.GET_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        feesElements: actions.data,
        error: {},
        loading: false,
      };
    case feesElementsActions.GET_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };
    case feesElementsActions.GET_FEES_PREVIEW_REQUEST:
      return {
        ...state,
        previewError: {},
        loadingPreview: true,
      };

    case feesElementsActions.GET_FEES_PREVIEW_SUCCESS:
      return {
        ...state,
        feesPreview: actions.data,
        previewError: {},
        loadingPreview: false,
      };
    case feesElementsActions.GET_FEES_PREVIEW_ERROR:
      return {
        ...state,
        previewError: actions.error,
        loadingPreview: false,
      };
    case feesElementsActions.CREATE_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };

    case feesElementsActions.CREATE_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };

    case feesElementsActions.CREATE_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case feesElementsActions.DELETE_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case feesElementsActions.DELETE_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case feesElementsActions.DELETE_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case feesElementsActions.UPDATE_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case feesElementsActions.UPDATE_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case feesElementsActions.UPDATE_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case feesElementsActions.MAKE_FEES_COPY_REQUEST:
      return {
        ...state,
        feesCopyError: {},
        feesCopyResponse: {},
        copying: true,
      };

    case feesElementsActions.MAKE_FEES_COPY_SUCCESS:
      return {
        ...state,
        copying: false,
        feesCopyResponse: actions.data,
      };

    case feesElementsActions.MAKE_FEES_COPY_ERROR:
      return {
        ...state,
        copying: false,
        feesCopyError: actions.error,
      };

    case feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadElementsResponse: {},
        downloading: true,
        downloadElementsError: {},
      };
    case feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
        downloadElementsResponse: actions.data,
      };
    case feesElementsActions.DOWNLOAD_ELEMENTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloading: false,
        downloadElementsError: actions.error,
      };

    case feesElementsActions.UPLOAD_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        uploadElementsResponse: {},
        uploading: true,
        uploadElementsError: {},
      };
    case feesElementsActions.UPLOAD_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadElementsResponse: actions.data,
      };
    case feesElementsActions.UPLOAD_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        uploading: false,
        uploadElementsError: actions.error,
      };

    default:
      return state;
  }
};
// registers to index.js of the reducer
export default feesElements;
