import { graduationFeesActions } from '../../actions';
import initialState from '../../initialState';

const graduationFees = (state = initialState.graduationFees, actions) => {
  switch (actions.type) {
    case graduationFeesActions.GET_GRADUATION_FEES_REQUEST:
      return {
        ...state,
        gettingGraduationFees: true,
        graduationFeesError: {},
      };

    case graduationFeesActions.GET_GRADUATION_FEES_SUCCESS:
      return {
        ...state,
        graduationFees: actions.data,
        gettingGraduationFees: false,
      };

    case graduationFeesActions.GET_GRADUATION_FEES_ERROR:
      return {
        ...state,
        graduationFeesError: actions.error,
        gettingGraduationFees: false,
      };

    case graduationFeesActions.CREATE_GRADUATION_FEES_REQUEST:
      return {
        ...state,
        creatingFee: true,
        createError: {},
      };
    case graduationFeesActions.CREATE_GRADUATION_FEES_SUCCESS:
      return {
        ...state,
        createdFee: actions.data,
        creatingFee: false,
      };
    case graduationFeesActions.CREATE_GRADUATION_FEES_ERROR:
      return {
        ...state,
        createError: actions.error,
        creatingFee: false,
      };

    case graduationFeesActions.UPDATE_GRADUATION_FEES_REQUEST:
      return {
        ...state,
        updating: true,
        updateResponse: {},
        updateError: {},
      };

    case graduationFeesActions.UPDATE_GRADUATION_FEES_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case graduationFeesActions.UPDATE_GRADUATION_FEES_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_REQUEST:
      return {
        ...state,
        deletingElement: true,
        deleteElementResponse: {},
        deleteElementError: {},
      };

    case graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_SUCCESS:
      return {
        ...state,
        deletingElement: false,
        deleteElementResponse: actions.data,
      };

    case graduationFeesActions.DELETE_GRADUATION_FEES_ELEMENT_ERROR:
      return {
        ...state,
        deletingElement: false,
        deleteElementError: actions.error,
      };

    case graduationFeesActions.SET_GRADUATION_FEES_CONTEXT:
      return {
        ...state,
        graduationFeesContext: actions.context,
      };

    case graduationFeesActions.SET_GRADUATION_FEES_TAB:
      return {
        ...state,
        graduationFeesTab: actions.data,
      };

    default:
      return state;
  }
};
export default graduationFees;
