import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { resultsReportActions } from '../../actions';

function* getResultsReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/enrollment-reports/result`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetResultsReport() {
  yield takeLatest(
    resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_REQUEST,
    getResultsReport
  );
}

export default [fork(watchGetResultsReport)];
