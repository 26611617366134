import React from 'react';
import PropTypes, { any } from 'prop-types';
import { Popover } from 'react-bootstrap';

function PopOver({ children, id, title }) {
  return (
    <Popover id={id}>
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );
}

PopOver.defaultProps = {
  children: null,
};

PopOver.propTypes = {
  children: PropTypes.oneOfType([any]),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PopOver;
