const userMgtInitialState = {
  securityProfile: {
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    updating: false,
    securityProfiles: [],
    updatedSecurityProfile: {},
    updateError: {},
    error: {},
    created: {},
  },

  userRole: {
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    singleRoleError: {},
    singleRole: {},
    singleRoles: [],
    deletingId: null,
    updatingId: null,
    updating: false,

    userRoles: [],
    error: {},
    created: {},
    updateResponse: {},

    deleting: false,
    deleteError: {},
    deleteSuccess: {},

    addingApps: false,
    addedApps: [],
    addAppError: {},

    droppingRoleApps: false,
    dropRoleApps: [],
    dropRoleAppError: {},

    droppingAppFunctions: false,
    droppedAppFunctionSuccess: {},
    dropAppFunctionError: {},

    assigningUserRoles: false,
    assignUserRoleSuccess: {},
    assignUserRoleError: {},

    removingUserRoles: false,
    removeUserRoleSuccess: {},
    removeUserRoleError: {},

    showRemoveUserRoleModal: false,

    updatingAccessDomain: false,
    updatedAccessDomain: {},
    updateAccessDomainError: {},

    userRoleBounds: [],
    gettingUserRoleBounds: false,
    userRoleBoundError: {},
  },

  roleGroup: {
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    singleRoleGroupError: {},
    singleRoleGroup: {},
    singleRoleGroups: [],
    updating: false,
    roleGroups: [],
    error: {},
    created: {},
    addingApps: false,
    addedApps: [],
    addAppError: {},
    droppingApps: false,
    droppedApps: [],
    dropError: {},
    addingAdmins: false,
    addAdminError: {},
    addAdminSuccess: {},
    gettingRoleGroupUsers: false,
    roleGroupUsers: [],
    getRoleGroupUserError: {},

    selectedRoleGroup: {},
    mySelectedRoleGroup: {},

    deleting: false,
    deleteError: {},
    deleteSuccess: {},

    activatingUser: false,
    activateUserSuccess: {},
    activateUserError: {},

    showDeactivateUserModal: false,
  },

  systemUser: {
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    deletingId: null,
    updatingId: null,
    deleting: false,
    getUsers: [],
    users: [],
    singleUser: {},
    error: {},
    createUserError: {},
    created: {},
    deleteResponse: {},

    showUserModal: false,

    updating: false,
    updateError: {},
    updateSuccess: {},

    gettingLecturers: false,
    getLecturerError: {},
    lecturers: [],

    departmentLecturers: [],
    gettingDepartmentLecturers: false,
    getDepartmentLecturerError: {},
  },

  systemApp: {
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    updating: false,
    apps: [],
    error: {},
    created: {},
  },
};

export default userMgtInitialState;
