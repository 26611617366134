const activeApp = {
  SET_ACTIVE_APP: 'SET_ACTIVE_APP',
  REMOVE_ACTIVE_APP: 'REMOVE_ACTIVE_APP',
  SET_APP_ERROR: 'SET_APP_ERROR',
  RESET_ACTIVE_APPS: 'RESET_ACTIVE_APPS',

  setActiveApp: (app) => ({
    type: activeApp.SET_ACTIVE_APP,
    app,
  }),

  removeActiveApp: (app) => ({
    type: activeApp.REMOVE_ACTIVE_APP,
    app,
  }),

  resetActiveApps: () => ({
    type: activeApp.RESET_ACTIVE_APPS,
  }),

  setAppError: (error) => ({
    type: activeApp.SET_APP_ERROR,
    error,
  }),
};

export default activeApp;
