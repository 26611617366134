import { bulkPaymentsActions } from '../../actions';
import initialState from '../../initialState';

const bulkPayments = (state = initialState.bulkPayments, actions) => {
  switch (actions.type) {
    case bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingBulkPayments: actions.data,
      };
    case bulkPaymentsActions.GET_PENDING_BULK_PAYMENTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case bulkPaymentsActions.ACKNOWLEDGE_BULK_PAYMENTS_REQUEST:
      return {
        ...state,
        loadError: {},
        approving: true,
      };
    case bulkPaymentsActions.ACKNOWLEDGE_BULK_PAYMENTS_SUCCESS:
      return {
        ...state,
        approving: false,
        approveSuccess: actions.data,
      };
    case bulkPaymentsActions.ACKNOWLEDGE_BULK_PAYMENTS_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    case bulkPaymentsActions.GET_ACKNOWLEDGED_PAYMENTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case bulkPaymentsActions.GET_ACKNOWLEDGED_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedBulkPayments: actions.data,
      };
    case bulkPaymentsActions.GET_ACKNOWLEDGED_PAYMENTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default bulkPayments;
