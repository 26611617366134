import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { feesApprovalsActions, functionalFeesActions } from '../../actions';

function* getPendingFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-approvals?fees_category=${actions.feesType}`,
    });
    yield put({
      type: feesApprovalsActions.GET_PENDING_FEES_SUCCESS,
      data: { feesType: actions.feesType, pendingFees: response.pendingFees },
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.GET_PENDING_FEES_ERROR,
      error: error.data,
    });
  }
}

function* getPendingOtherFeesAmounts(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-approvals?fees_category=${actions.feesType}`,
      params: actions.context,
    });
    yield put({
      type: feesApprovalsActions.GET_PENDING_OTHER_FEES_SUCCESS,
      data: response.pendingFees,
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.GET_PENDING_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}

function* getPendingFeesWaivers(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-approvals?fees_category=${actions.feesType}`,
      params: actions.context,
    });
    yield put({
      type: feesApprovalsActions.GET_PENDING_FEES_WAIVERS_SUCCESS,
      data: response.pendingFees,
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.GET_PENDING_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}

function* getPendingStudentFeesWaivers() {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waivers/student-waiver-requests/index`,
      method: 'GET',
    });
    yield put({
      type: feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingStudentFeesWaivers(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waivers/student-waiver-requests/update`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_ERROR,
      error: error.data,
    });
  }
}

function* getPendingTuitionFees(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-approvals?fees_category=${actions.feesType}`,
      params: actions.context,
    });
    yield put({
      type: feesApprovalsActions.GET_PENDING_TUITION_FEES_SUCCESS,
      data: response.pendingFees,
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.GET_PENDING_TUITION_FEES_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingFees(actions) {
  try {
    let feesUrl = '';
    if (actions.feesType === 'otherFees') feesUrl = 'other-fees-amounts';
    if (actions.feesType === 'tuitionFees') feesUrl = 'tuition-amounts';
    if (actions.feesType === 'feesWaivers') feesUrl = 'fees-waiver-discounts';
    if (actions.feesType === 'functionalFees')
      feesUrl = 'functional-fees-amounts';
    const response = yield axios({
      url: `/fees-mgt/${feesUrl}/approve`,
      method: 'POST',
      data: {
        requests: actions.data,
        approval_comments: actions.feesType,
      },
    });
    if (actions.feesType === 'tuitionFees') {
      yield put({
        type: feesApprovalsActions.GET_PENDING_TUITION_FEES_REQUEST,
        context: actions.context,
        feesType: actions.feesType,
      });
    }
    if (actions.feesType === 'functionalFees') {
      yield put({
        type: functionalFeesActions.GET_PENDING_FUNCTIONAL_FEES_REQUEST,
        context: actions.context,
      });
    }
    if (actions.feesType === 'feesWaivers') {
      yield put({
        type: feesApprovalsActions.GET_PENDING_FEES_WAIVERS_REQUEST,
        feesType: actions.feesType,
        context: actions.context,
      });
    }
    if (actions.feesType === 'otherFees') {
      yield put({
        type: feesApprovalsActions.GET_PENDING_OTHER_FEES_REQUEST,
        feesType: actions.feesType,
        context: actions.context,
      });
    }
    yield put({
      type: feesApprovalsActions.APPROVE_PENDING_FEES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: feesApprovalsActions.APPROVE_PENDING_FEES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPendingFees() {
  yield takeLatest(
    feesApprovalsActions.GET_PENDING_FEES_REQUEST,
    getPendingFees
  );
}

function* watchGetPendingOtherFees() {
  yield takeLatest(
    feesApprovalsActions.GET_PENDING_OTHER_FEES_REQUEST,
    getPendingOtherFeesAmounts
  );
}

function* watchGetPendingFeesWaivers() {
  yield takeLatest(
    feesApprovalsActions.GET_PENDING_FEES_WAIVERS_REQUEST,
    getPendingFeesWaivers
  );
}

function* watchGetPendingStudentFeesWaivers() {
  yield takeLatest(
    feesApprovalsActions.GET_PENDING_STUDENT_FEES_WAIVERS_REQUEST,
    getPendingStudentFeesWaivers
  );
}

function* watchApprovePendingStudentFeesWaivers() {
  yield takeLatest(
    feesApprovalsActions.APPROVE_PENDING_STUDENT_FEES_WAIVERS_REQUEST,
    approvePendingStudentFeesWaivers
  );
}

function* watchGetPendingTuitionFees() {
  yield takeLatest(
    feesApprovalsActions.GET_PENDING_TUITION_FEES_REQUEST,
    getPendingTuitionFees
  );
}

function* watchApprovePendingFees() {
  yield takeLatest(
    feesApprovalsActions.APPROVE_PENDING_FEES_REQUEST,
    approvePendingFees
  );
}

export default [
  fork(watchGetPendingFees),
  fork(watchApprovePendingFees),
  fork(watchGetPendingOtherFees),
  fork(watchGetPendingTuitionFees),
  fork(watchGetPendingFeesWaivers),
  fork(watchGetPendingStudentFeesWaivers),
  fork(watchApprovePendingStudentFeesWaivers),
];
