const itsDataActions = {
  SEARCH_ITS_DATA_REQUEST: 'SEARCH_ITS_DATA_REQUEST',
  SEARCH_ITS_DATA_SUCCESS: 'SEARCH_ITS_DATA_SUCCESS',
  SEARCH_ITS_DATA_ERROR: 'SEARCH_ITS_DATA_ERROR',

  DOWNLOAD_ITS_STATEMENT_REQUEST: 'DOWNLOAD_ITS_STATEMENT_REQUEST',
  DOWNLOAD_ITS_STATEMENT_SUCCESS: 'DOWNLOAD_ITS_STATEMENT_SUCCESS',
  DOWNLOAD_ITS_STATEMENT_ERROR: 'DOWNLOAD_ITS_STATEMENT_ERROR',

  searchItsData: (params) => ({
    type: itsDataActions.SEARCH_ITS_DATA_REQUEST,
    params,
  }),

  downloadItsData: (params) => ({
    type: itsDataActions.DOWNLOAD_ITS_STATEMENT_REQUEST,
    params,
  }),
};

export default itsDataActions;
