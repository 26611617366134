import { semesterLoadActions } from '../../actions';
import initialState from '../../initialState';

function semesterLoad(state = initialState.semesterLoad, actions) {
  switch (actions.type) {
    case semesterLoadActions.GET_SEMESTER_LOADS_REQUEST:
      return {
        ...state,
        semesterLoadError: {},
        loading: true,
      };
    case semesterLoadActions.GET_SEMESTER_LOADS_SUCCESS:
      return {
        ...state,
        semesterLoads: actions.data.semesterLoads,
        semesterLoadError: {},
        loading: false,
      };
    case semesterLoadActions.GET_SEMESTER_LOADS_ERROR:
      return {
        ...state,
        semesterLoadError: actions.error,
        loading: false,
      };
    case semesterLoadActions.CREATE_SEMESTER_LOAD_REQUEST:
      return {
        ...state,
        creating: true,
        semesterLoadError: {},
        createdData: {},
      };
    case semesterLoadActions.CREATE_SEMESTER_LOAD_SUCCESS:
      return {
        ...state,
        creating: false,
        createdData: actions.data,
      };
    case semesterLoadActions.CREATE_SEMESTER_LOAD_ERROR:
      return {
        ...state,
        creating: false,
        semesterLoadError: actions.error,
      };
    case semesterLoadActions.DELETE_SEMESTER_LOAD_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case semesterLoadActions.DELETE_SEMESTER_LOAD_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case semesterLoadActions.DELETE_SEMESTER_LOAD_ERROR:
      return {
        ...state,
        deleting: false,
        semesterLoadError: actions.error,
      };
    case semesterLoadActions.UPDATE_SEMESTER_LOAD_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case semesterLoadActions.UPDATE_SEMESTER_LOAD_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case semesterLoadActions.UPDATE_SEMESTER_LOAD_ERROR:
      return {
        ...state,
        updating: false,
        semesterLoadError: actions.error,
      };

    default:
      return state;
  }
}

export default semesterLoad;
