import { admissionFormActions } from '../../actions';
import initialState from '../../initialState';

function admissionForm(state = initialState.admissionForm, actions) {
  switch (actions.type) {
    case admissionFormActions.GET_ADMISSION_FORM_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case admissionFormActions.GET_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        admissionForms: actions.data.admissionForms,
        loading: false,
      };
    case admissionFormActions.GET_ADMISSION_FORM_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case admissionFormActions.CREATE_ADMISSION_FORM_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        created: {},
      };
    case admissionFormActions.CREATE_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data.admissionForm,
      };
    case admissionFormActions.CREATE_ADMISSION_FORM_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case admissionFormActions.UPDATE_ADMISSION_FORM_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updated: {},
      };
    case admissionFormActions.UPDATE_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: actions.data.admissionForm,
      };
    case admissionFormActions.UPDATE_ADMISSION_FORM_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case admissionFormActions.GET_SINGLE_ADMISSION_FORM_REQUEST:
      return {
        ...state,
        gettingOne: false,
        getOneError: {},
        admissionForm: {},
      };
    case admissionFormActions.GET_SINGLE_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        admissionForm: actions.data.admissionForm,
      };
    case admissionFormActions.GET_SINGLE_ADMISSION_FORM_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case admissionFormActions.DELETE_ADMISSION_FORM_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
        deleteResponse: {},
      };
    case admissionFormActions.DELETE_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case admissionFormActions.DELETE_ADMISSION_FORM_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    default:
      return state;
  }
}

export default admissionForm;
