const surchargePolicyActions = {
  GET_SURCHARGE_POLICIES_REQUEST: 'GET_SURCHARGE_POLICIES_REQUEST',
  GET_SURCHARGE_POLICIES_SUCCESS: 'GET_SURCHARGE_POLICIES_SUCCESS',
  GET_SURCHARGE_POLICIES_ERROR: 'GET_SURCHARGE_POLICIES_ERROR',

  CREATE_SURCHARGE_POLICY_REQUEST: 'CREATE_SURCHARGE_POLICY_REQUEST',
  CREATE_SURCHARGE_POLICY_SUCCESS: 'CREATE_SURCHARGE_POLICY_SUCCESS',
  CREATE_SURCHARGE_POLICY_ERROR: 'CREATE_SURCHARGE_POLICY_ERROR',

  UPDATE_SURCHARGE_POLICY_REQUEST: 'UPDATE_SURCHARGE_POLICY_REQUEST',
  UPDATE_SURCHARGE_POLICY_SUCCESS: 'UPDATE_SURCHARGE_POLICY_SUCCESS',
  UPDATE_SURCHARGE_POLICY_ERROR: 'UPDATE_SURCHARGE_POLICY_ERROR',

  DELETE_SURCHARGE_POLICY_REQUEST: 'DELETE_SURCHARGE_POLICY_REQUEST',
  DELETE_SURCHARGE_POLICY_SUCCESS: 'DELETE_SURCHARGE_POLICY_SUCCESS',
  DELETE_SURCHARGE_POLICY_ERROR: 'DELETE_SURCHARGE_POLICY_ERROR',

  SET_SURCHARGE_POLICY_TO_EDIT: 'SET_SURCHARGE_POLICY_TO_EDIT',

  getSurchargeFeesPolicies: (params) => ({
    type: surchargePolicyActions.GET_SURCHARGE_POLICIES_REQUEST,
    params,
  }),

  createSurchargeFeesPolicy: (data) => ({
    type: surchargePolicyActions.CREATE_SURCHARGE_POLICY_REQUEST,
    data,
  }),

  updateSurchargeFeesPolicy: (policyID, data) => ({
    type: surchargePolicyActions.UPDATE_SURCHARGE_POLICY_REQUEST,
    data,
    policyID,
  }),

  deleteSurchargeFeesPolicy: (policyID) => ({
    type: surchargePolicyActions.DELETE_SURCHARGE_POLICY_REQUEST,
    policyID,
  }),

  setPolicyToEdit: (surchargePolicyToEdit) => ({
    type: surchargePolicyActions.SET_SURCHARGE_POLICY_TO_EDIT,
    surchargePolicyToEdit,
  }),
};

export default surchargePolicyActions;
