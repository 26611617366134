const auditTrailsInitialState = {
  auditTrails: {
    gettingUserLogs: false,
    userLogs: [],
    getUserLogsError: {},

    gettingAppLogs: false,
    applicationLogs: [],
    getAppLogsError: {},
  },
};

export default auditTrailsInitialState;
