const notificationActions = {
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_ERROR: 'GET_REPORT_ERROR',

  getReport: (context, category) => ({
    type: notificationActions.GET_REPORT_REQUEST,
    context,
    category,
  }),
};

export default notificationActions;
