const admissionStudentService = {
  GET_STUDENTS_SERVICES_REQUEST: 'GET_STUDENTS_SERVICES_REQUEST',
  GET_STUDENTS_SERVICES_SUCCESS: 'GET_STUDENTS_SERVICES_SUCCESS',
  GET_STUDENTS_SERVICES_ERROR: 'GET_STUDENTS_SERVICES_ERROR',

  ADD_STUDENTS_SERVICES_REQUEST: 'ADD_STUDENTS_SERVICES_REQUEST',
  ADD_STUDENTS_SERVICES_SUCCESS: 'ADD_STUDENTS_SERVICES_SUCCESS',
  ADD_STUDENTS_SERVICES_ERROR: 'ADD_STUDENTS_SERVICES_ERROR',

  GET_SERVICES_EVENT_REQUEST: 'GET_SERVICES_EVENT_REQUEST',
  GET_SERVICES_EVENT_SUCCESS: 'GET_SERVICES_EVENT_SUCCESS',
  GET_SERVICES_EVENT_ERROR: 'GET_SERVICES_EVENT_ERROR',

  GET_SERVICE_PROGRAMMES_REQUEST: 'GET_SERVICE_PROGRAMMES_REQUEST',
  GET_SERVICE_PROGRAMMES_SUCCESS: 'GET_SERVICE_PROGRAMMES_SUCCESS',
  GET_SERVICE_PROGRAMMES_ERROR: 'GET_SERVICE_PROGRAMMES_ERROR',

  APPROVE_SERVICE_REQUESTS_REQUEST: 'APPROVE_SERVICE_REQUESTS_REQUEST',
  APPROVE_SERVICE_REQUESTS_SUCCESS: 'APPROVE_SERVICE_REQUESTS_SUCCESS',
  APPROVE_SERVICE_REQUESTS_ERROR: 'APPROVE_SERVICE_REQUESTS_ERROR',

  EDIT_SERVICE_ACADEMIC_YEAR_REQUEST: 'EDIT_SERVICE_ACADEMIC_YEAR_REQUEST',
  EDIT_SERVICE_ACADEMIC_YEAR_SUCCESS: 'EDIT_SERVICE_ACADEMIC_YEAR_SUCCESS',
  EDIT_SERVICE_ACADEMIC_YEAR_ERROR: 'EDIT_SERVICE_ACADEMIC_YEAR_ERROR',

  ACCEPT_OR_DECLINE_SERVICE_REQUESTS_REQUEST:
    'ACCEPT_OR_DECLINE_SERVICE_REQUESTS_REQUEST',
  ACCEPT_OR_DECLINE_SERVICE_REQUESTS_SUCCESS:
    'ACCEPT_OR_DECLINE_SERVICE_REQUESTS_SUCCESS',
  ACCEPT_OR_DECLINE_SERVICE_REQUESTS_ERROR:
    'ACCEPT_OR_DECLINE_SERVICE_REQUESTS_ERROR',

  DOWNLOAD_SERVICE_REQUEST_REPORT_REQUEST:
    'DOWNLOAD_SERVICE_REQUEST_REPORT_REQUEST',
  DOWNLOAD_SERVICE_REQUEST_REPORT_SUCCESS:
    'DOWNLOAD_SERVICE_REQUEST_REPORT_SUCCESS',
  DOWNLOAD_SERVICE_REQUEST_REPORT_ERROR:
    'DOWNLOAD_SERVICE_REQUEST_REPORT_ERROR',

  SET_SHOW_CREATE_FORM: 'SET_SHOW_CREATE_FORM',
  SET_COP_CONTEXT: 'SET_COP_CONTEXT',

  getStudentServices: (service, context) => ({
    type: admissionStudentService.GET_STUDENTS_SERVICES_REQUEST,
    service,
    context,
  }),

  setCOPContext: (data) => ({
    type: admissionStudentService.SET_COP_CONTEXT,
    data,
  }),

  getServiceEvent: (studentId, service) => ({
    type: admissionStudentService.GET_SERVICES_EVENT_REQUEST,
    studentId,
    service,
  }),

  getServiceProgrammes: () => ({
    type: admissionStudentService.GET_SERVICE_PROGRAMMES_REQUEST,
  }),

  addStudentServices: (studentId, data) => ({
    type: admissionStudentService.ADD_STUDENTS_SERVICES_REQUEST,
    studentId,
    data,
  }),

  setShowCreateForm: (payload) => ({
    type: admissionStudentService.SET_SHOW_CREATE_FORM,
    payload,
  }),

  approveServiceRequests: (data) => ({
    type: admissionStudentService.APPROVE_SERVICE_REQUESTS_REQUEST,
    data,
  }),

  editAcademicYear: (data, service, context) => ({
    type: admissionStudentService.EDIT_SERVICE_ACADEMIC_YEAR_REQUEST,
    data,
    service,
    context,
  }),

  acceptOrDeclineServiceRequests: (data) => ({
    type: admissionStudentService.ACCEPT_OR_DECLINE_SERVICE_REQUESTS_REQUEST,
    data,
  }),

  downloadServiceRequestReport: (context) => ({
    type: admissionStudentService.DOWNLOAD_SERVICE_REQUEST_REPORT_REQUEST,
    context,
  }),
};

export default admissionStudentService;
