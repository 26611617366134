import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { studentLedgerActions } from '../../actions';

function* studentLedger(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/ledger`,
      method: 'GET',
      params: actions.student,
    });
    yield put({
      type: studentLedgerActions.GET_STUDENT_LEDGER_SUCCESS,
      data: response.data,
      student: actions.student.student,
    });
  } catch (error) {
    yield put({
      type: studentLedgerActions.GET_STUDENT_LEDGER_ERROR,
      error: error.data,
    });
  }
}

function* watchStudentLedger() {
  yield takeLatest(
    studentLedgerActions.GET_STUDENT_LEDGER_REQUEST,
    studentLedger
  );
}

export default [fork(watchStudentLedger)];
