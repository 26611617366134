const runningAdmissionProgramme = {
  GET_RUNNING_ADMISSION_PROGRAMME_REQUEST:
    'GET_RUNNING_ADMISSION_PROGRAMME_REQUEST',
  GET_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
    'GET_RUNNING_ADMISSION_PROGRAMME_SUCCESS',
  GET_RUNNING_ADMISSION_PROGRAMME_ERROR:
    'GET_RUNNING_ADMISSION_PROGRAMME_ERROR',

  GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
    'GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_REQUEST',
  GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
    'GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_SUCCESS',
  GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_ERROR:
    'GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_ERROR',

  GET_PROGRAMME_SPECIAL_FEES_REQUEST: 'GET_PROGRAMME_SPECIAL_FEES_REQUEST',
  GET_PROGRAMME_SPECIAL_FEES_SUCCESS: 'GET_PROGRAMME_SPECIAL_FEES_SUCCESS',
  GET_PROGRAMME_SPECIAL_FEES_ERROR: 'GET_PROGRAMME_SPECIAL_FEES_ERROR',

  CREATE_PROGRAMME_SPECIAL_FEES_REQUEST:
    'CREATE_PROGRAMME_SPECIAL_FEES_REQUEST',
  CREATE_PROGRAMME_SPECIAL_FEES_SUCCESS:
    'CREATE_PROGRAMME_SPECIAL_FEES_SUCCESS',
  CREATE_PROGRAMME_SPECIAL_FEES_ERROR: 'CREATE_PROGRAMME_SPECIAL_FEES_ERROR',

  UPDATE_PROGRAMME_SPECIAL_FEES_REQUEST:
    'UPDATE_PROGRAMME_SPECIAL_FEES_REQUEST',
  UPDATE_PROGRAMME_SPECIAL_FEES_SUCCESS:
    'UPDATE_PROGRAMME_SPECIAL_FEES_SUCCESS',
  UPDATE_PROGRAMME_SPECIAL_FEES_ERROR: 'UPDATE_PROGRAMME_SPECIAL_FEES_ERROR',

  DELETE_PROGRAMME_SPECIAL_FEES_REQUEST:
    'DELETE_PROGRAMME_SPECIAL_FEES_REQUEST',
  DELETE_PROGRAMME_SPECIAL_FEES_SUCCESS:
    'DELETE_PROGRAMME_SPECIAL_FEES_SUCCESS',
  DELETE_PROGRAMME_SPECIAL_FEES_ERROR: 'DELETE_PROGRAMME_SPECIAL_FEES_ERROR',

  CREATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
    'CREATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS',
  CREATE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
    'CREATE_RUNNING_ADMISSION_PROGRAMME_REQUEST',
  CREATE_RUNNING_ADMISSION_PROGRAMME_ERROR:
    'CREATE_RUNNING_ADMISSION_PROGRAMME_ERROR',

  UPDATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
    'UPDATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS',
  UPDATE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
    'UPDATE_RUNNING_ADMISSION_PROGRAMME_REQUEST',
  UPDATE_RUNNING_ADMISSION_PROGRAMME_ERROR:
    'UPDATE_RUNNING_ADMISSION_PROGRAMME_ERROR',

  DELETE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
    'DELETE_RUNNING_ADMISSION_PROGRAMME_SUCCESS',
  DELETE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
    'DELETE_RUNNING_ADMISSION_PROGRAMME_REQUEST',
  DELETE_RUNNING_ADMISSION_PROGRAMME_ERROR:
    'DELETE_RUNNING_ADMISSION_PROGRAMME_ERROR',

  DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST:
    'DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST',
  DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS:
    'DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS',
  DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR:
    'DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR',

  UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST:
    'UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST',
  UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS:
    'UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS',
  UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR:
    'UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR',

  UPDATE_PROGRAMME_STATUS_REQUEST: 'UPDATE_PROGRAMME_STATUS_REQUEST',
  UPDATE_PROGRAMME_STATUS_SUCCESS: 'UPDATE_PROGRAMME_STATUS_SUCCESS',
  UPDATE_PROGRAMME_STATUS_ERROR: 'UPDATE_PROGRAMME_STATUS_ERROR',

  UPDATE_PROGRAMME_WEIGHTING_CRITERIA_REQUEST:
    'UPDATE_PROGRAMME_WEIGHTING_CRITERIA_REQUEST',
  UPDATE_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS:
    'UPDATE_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS',
  UPDATE_PROGRAMME_WEIGHTING_CRITERIA_ERROR:
    'UPDATE_PROGRAMME_WEIGHTING_CRITERIA_ERROR',

  UPDATE_PROGRAMME_SELECTION_CRITERIA_REQUEST:
    'UPDATE_PROGRAMME_SELECTION_CRITERIA_REQUEST',
  UPDATE_PROGRAMME_SELECTION_CRITERIA_SUCCESS:
    'UPDATE_PROGRAMME_SELECTION_CRITERIA_SUCCESS',
  UPDATE_PROGRAMME_SELECTION_CRITERIA_ERROR:
    'UPDATE_PROGRAMME_SELECTION_CRITERIA_ERROR',

  GET_PROGRAMME_WEIGHTING_CRITERIA_REQUEST:
    'GET_PROGRAMME_WEIGHTING_CRITERIA_REQUEST',
  GET_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS:
    'GET_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS',
  GET_PROGRAMME_WEIGHTING_CRITERIA_ERROR:
    'GET_PROGRAMME_WEIGHTING_CRITERIA_ERROR',

  GET_PROGRAMME_SELECTION_CRITERIA_REQUEST:
    'GET_PROGRAMME_SELECTION_CRITERIA_REQUEST',
  GET_PROGRAMME_SELECTION_CRITERIA_SUCCESS:
    'GET_PROGRAMME_SELECTION_CRITERIA_SUCCESS',
  GET_PROGRAMME_SELECTION_CRITERIA_ERROR:
    'GET_PROGRAMME_SELECTION_CRITERIA_ERROR',

  RUN_SELECTION_REQUEST: 'RUN_SELECTION_REQUEST',
  RUN_SELECTION_SUCCESS: 'RUN_SELECTION_SUCCESS',
  RUN_SELECTION_ERROR: 'RUN_SELECTION_ERROR',

  RUN_BULK_WEIGHTING_REQUEST: 'RUN_BULK_WEIGHTING_REQUEST',
  RUN_BULK_WEIGHTING_SUCCESS: 'RUN_BULK_WEIGHTING_SUCCESS',
  RUN_BULK_WEIGHTING_ERROR: 'RUN_BULK_WEIGHTING_ERROR',

  RUN_WEIGHTING_REQUEST: 'RUN_WEIGHTING_REQUEST',
  RUN_WEIGHTING_SUCCESS: 'RUN_WEIGHTING_SUCCESS',
  RUN_WEIGHTING_ERROR: 'RUN_WEIGHTING_ERROR',

  setSelectedRunningProgramme: (e) => ({
    type: 'SET_SELECTED_RUNNING_PROGRAMME',
    payload: e,
  }),

  getRunningAdmissionProgrammes: (data) => ({
    type: runningAdmissionProgramme.GET_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    data,
  }),

  createRunningAdmissionProgramme: (data) => ({
    type: runningAdmissionProgramme.CREATE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    data,
  }),

  getSingleRunningAdmissionProgramme: (runningAdmissionProgrammeId, data) => ({
    type: runningAdmissionProgramme.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    runningAdmissionProgrammeId,
    data,
  }),

  getProgrammeSpecialFees: (runningAdmissionProgrammeId, data) => ({
    type: runningAdmissionProgramme.GET_PROGRAMME_SPECIAL_FEES_REQUEST,
    runningAdmissionProgrammeId,
    data,
  }),

  createProgrammeSpecialFees: (data, runningAdmissionProgrammeId) => ({
    type: runningAdmissionProgramme.CREATE_PROGRAMME_SPECIAL_FEES_REQUEST,
    data,
    runningAdmissionProgrammeId,
  }),

  updateProgrammeSpecialFees: (
    specialFeeId,
    data,
    runningAdmissionProgrammeId
  ) => ({
    type: runningAdmissionProgramme.UPDATE_PROGRAMME_SPECIAL_FEES_REQUEST,
    specialFeeId,
    data,
    runningAdmissionProgrammeId,
  }),

  deleteProgrammeSpecialFees: (
    specialFeeId,
    data,
    runningAdmissionProgrammeId
  ) => ({
    type: runningAdmissionProgramme.DELETE_PROGRAMME_SPECIAL_FEES_REQUEST,
    specialFeeId,
    data,
    runningAdmissionProgrammeId,
  }),

  updateRunningAdmissionProgramme: (runningAdmissionProgrammeId, data) => ({
    type: runningAdmissionProgramme.UPDATE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    runningAdmissionProgrammeId,
    data,
  }),

  deleteRunningAdmissionProgramme: (
    runningAdmissionProgrammeId,
    runningAdmissionId
  ) => ({
    type: runningAdmissionProgramme.DELETE_RUNNING_ADMISSION_PROGRAMME_REQUEST,
    runningAdmissionProgrammeId,
    runningAdmissionId,
  }),

  downloadManageAdmissionsTemplate: (runningAdmissionId) => ({
    type: runningAdmissionProgramme.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST,
    runningAdmissionId,
  }),

  uploadManageAdmissionsTemplate: (id, data) => ({
    type: runningAdmissionProgramme.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST,
    data,
    id,
  }),

  updateProgrammeStatus: (programmeId, runningAdmissionId, data) => ({
    type: runningAdmissionProgramme.UPDATE_PROGRAMME_STATUS_REQUEST,
    data,
    programmeId,
    runningAdmissionId,
  }),

  getProgrammeWeightingCriteria: (programmeId) => ({
    type: runningAdmissionProgramme.GET_PROGRAMME_WEIGHTING_CRITERIA_REQUEST,
    programmeId,
  }),

  getProgrammeSelectionCriteria: (programmeId) => ({
    type: runningAdmissionProgramme.GET_PROGRAMME_SELECTION_CRITERIA_REQUEST,
    programmeId,
  }),

  updateProgrammeWeightingCriteria: (
    programmeId,
    criteriaId,
    runningAdmissionId
  ) => ({
    type: runningAdmissionProgramme.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_REQUEST,
    programmeId,
    criteriaId,
    runningAdmissionId,
  }),

  updateProgrammeSelectionCriteria: (
    programmeId,
    criteriaId,
    runningAdmissionId
  ) => ({
    type: runningAdmissionProgramme.UPDATE_PROGRAMME_SELECTION_CRITERIA_REQUEST,
    programmeId,
    criteriaId,
    runningAdmissionId,
  }),

  runBulkWeighting: (runningAdmissionId) => ({
    type: runningAdmissionProgramme.RUN_BULK_WEIGHTING_REQUEST,
    runningAdmissionId,
  }),

  runSelection: (runningAdmissionId) => ({
    type: runningAdmissionProgramme.RUN_SELECTION_REQUEST,
    runningAdmissionId,
  }),

  runWeighting: (advertisedProgrammeId, runningAdmissionId) => ({
    type: runningAdmissionProgramme.RUN_WEIGHTING_REQUEST,
    advertisedProgrammeId,
    runningAdmissionId,
  }),
};

export default runningAdmissionProgramme;
