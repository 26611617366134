import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { programmeActions } from '../../actions';

function* getProgrammes(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programmes/programmes-data',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}
function* getProgrammesWithYears(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programmes/programme-study-years',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_WITH_YEARS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_PROGRAMME_WITH_YEARS_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeDownload(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programmes/download',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_DOWNLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_PROGRAMME_DOWNLOAD_ERROR,
      error: error.data,
    });
  }
}

function* setProgrammeStudyYearSemester(actions) {
  try {
    const { data } = actions;

    const response = yield axios({
      url: '/programme-mgt/study-year-semesters',
      method: 'POST',
      data,
    });
    yield put({
      type: programmeActions.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: data.programme_id,
    });
  } catch (error) {
    yield put({
      type: programmeActions.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_ERROR,
      error: error.data,
    });
  }
}

function* getProgramsWithCombinations(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programmes/combinations',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_WITH_CATEGORY_SUCCESS,
      data: response.programmes,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_PROGRAMME_WITH_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammesByDepartment() {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/departments`,
      method: 'GET',
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_BY_DEPARTMENT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_PROGRAMME_BY_DEPARTMENT_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionsProgrammes() {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/admissions`,
      method: 'GET',
    });
    yield put({
      type: programmeActions.GET_ADMISSIONS_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_ADMISSIONS_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getAcademicUnitProgrammes() {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/academic-units`,
      method: 'GET',
    });
    yield put({
      type: programmeActions.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getSingleProgramme(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/${actions.programmeId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_SUCCESS,
      data: response.programme,
    });
    yield put({
      type: 'SET_SELECTED_PROGRAMME',
      payload: response.programme,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeDetails(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/programme-details/${actions.programmeId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_DETAILS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SET_SELECTED_PROGRAMME',
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_PROGRAMME_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* createProgramme(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programmes',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: programmeActions.CREATE_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_REQUEST,
    });
  } catch (error) {
    yield put({
      type: programmeActions.CREATE_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* deleteProgramme(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: programmeActions.DELETE_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_REQUEST,
    });
    yield put({
      type: 'SET_SELECTED_PROGRAMME',
      payload: {},
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: programmeActions.DELETE_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* uploadProgramme(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programmes/upload',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: programmeActions.UPLOAD_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_PROGRAMME_REQUEST,
    });
  } catch (error) {
    yield put({
      type: programmeActions.UPLOAD_PROGRAMME_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadProgrammeTemplate() {
  try {
    yield axios({
      url: '/programme-mgt/programmes/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PROGRAMME-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: programmeActions.DOWNLOAD_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: programmeActions.DOWNLOAD_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* updateProgramme(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/${actions.programmeId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: programmeActions.UPDATE_PROGRAMME_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: actions.programmeId,
    });
    yield put({
      type: programmeActions.SET_CURRENT_PROGRAMME_DETAIL_TAB,
      currentTab: 'details',
    });
  } catch (error) {
    yield put({
      type: programmeActions.UPDATE_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* getDashboardData() {
  try {
    const response = yield axios({
      url: '/programme-mgt/programme-reports',
      method: 'GET',
    });
    yield put({
      type: programmeActions.GET_DASHBOARD_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: programmeActions.GET_DASHBOARD_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetProgrammes() {
  yield takeLatest(programmeActions.GET_PROGRAMME_REQUEST, getProgrammes);
}

function* watchGetDashboardData() {
  yield takeLatest(
    programmeActions.GET_DASHBOARD_DATA_REQUEST,
    getDashboardData
  );
}

function* watchGetProgrammesWithYears() {
  yield takeLatest(
    programmeActions.GET_PROGRAMME_WITH_YEARS_REQUEST,
    getProgrammesWithYears
  );
}
function* watchGetProgrammeDownload() {
  yield takeLatest(
    programmeActions.GET_PROGRAMME_DOWNLOAD_REQUEST,
    getProgrammeDownload
  );
}
function* watchSetProgrammeStudyYearSemester() {
  yield takeLatest(
    programmeActions.SET_PROGRAMME_STUDY_YEAR_SEMESTERS_REQUEST,
    setProgrammeStudyYearSemester
  );
}

function* watchGetProgrammesWithCombinations() {
  yield takeLatest(
    programmeActions.GET_PROGRAMME_WITH_CATEGORY_REQUEST,
    getProgramsWithCombinations
  );
}

function* watchCreateProgrammes() {
  yield takeLatest(programmeActions.CREATE_PROGRAMME_REQUEST, createProgramme);
}

function* watchDeleteProgramme() {
  yield takeLatest(programmeActions.DELETE_PROGRAMME_REQUEST, deleteProgramme);
}

function* watchUploadProgrammes() {
  yield takeLatest(programmeActions.UPLOAD_PROGRAMME_REQUEST, uploadProgramme);
}

function* watchUpdateProgrammes() {
  yield takeLatest(programmeActions.UPDATE_PROGRAMME_REQUEST, updateProgramme);
}

function* watchDownloadProgrammeTemplate() {
  yield takeLatest(
    programmeActions.DOWNLOAD_TEMPLATE_REQUEST,
    downloadProgrammeTemplate
  );
}

function* watchGetSingleProgrammes() {
  yield takeLatest(
    programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
    getSingleProgramme
  );
}

function* watchGetProgrammesDetails() {
  yield takeLatest(
    programmeActions.GET_PROGRAMME_DETAILS_REQUEST,
    getProgrammeDetails
  );
}

function* watchGetProgrammesByDepartment() {
  yield takeLatest(
    programmeActions.GET_PROGRAMME_BY_DEPARTMENT_REQUEST,
    getProgrammesByDepartment
  );
}

function* watchGetAdmissionsProgrammes() {
  yield takeLatest(
    programmeActions.GET_ADMISSIONS_PROGRAMMES_REQUEST,
    getAdmissionsProgrammes
  );
}

function* watchGetAcademicUnitProgrammes() {
  yield takeLatest(
    programmeActions.GET_NEW_ACADEMIC_UNIT_PROGRAMMES_REQUEST,
    getAcademicUnitProgrammes
  );
}

export default [
  fork(watchGetProgrammes),
  fork(watchGetProgrammesWithYears),
  fork(watchGetProgrammeDownload),
  fork(watchCreateProgrammes),
  fork(watchDeleteProgramme),
  fork(watchUploadProgrammes),
  fork(watchUpdateProgrammes),
  fork(watchGetSingleProgrammes),
  fork(watchGetDashboardData),
  fork(watchDownloadProgrammeTemplate),
  fork(watchGetProgrammesWithCombinations),
  fork(watchGetProgrammesByDepartment),
  fork(watchGetAcademicUnitProgrammes),
  fork(watchGetProgrammesDetails),
  fork(watchGetAdmissionsProgrammes),
  fork(watchSetProgrammeStudyYearSemester),
];
