import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { eventActions, tabActions } from '../../actions';

function* getEvents(actions) {
  try {
    const response = yield axios({
      url: '/events-mgt/events',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: eventActions.GET_EVENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: eventActions.GET_EVENTS_ERROR,
      error: error.data,
    });
  }
}

function* getAcademicYearEvents(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/events/academic-year/${actions.academicYearId}`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: eventActions.GET_ACADEMIC_YEAR_EVENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: eventActions.GET_ACADEMIC_YEAR_EVENTS_ERROR,
      error: error.data,
    });
  }
}

function* getSemesterEvents(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/events/semester/${actions.semesterId}`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: eventActions.GET_SEMESTER_EVENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: eventActions.GET_SEMESTER_EVENTS_ERROR,
      error: error.data,
    });
  }
}

function* createEvent(actions) {
  try {
    const response = yield axios({
      url: '/events-mgt/events',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: eventActions.CREATE_EVENT_SUCCESS,
      data: response,
    });
    yield put({
      type: eventActions.GET_EVENTS_REQUEST,
    });
    yield put({
      type: tabActions.ACADEMIC_YEAR_EVENT_TAB,
      activeTab: 'semester',
    });
  } catch (error) {
    yield put({
      type: eventActions.CREATE_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* editEvent(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/events/${actions.eventId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: eventActions.EDIT_EVENT_SUCCESS,
      data: response,
    });
    yield put({
      type: tabActions.ACADEMIC_YEAR_EVENT_TAB,
      activeTab: 'semester',
    });
    yield put({
      type: eventActions.GET_SEMESTER_EVENTS_REQUEST,
      semesterId: response.event.semester_id,
    });
  } catch (error) {
    yield put({
      type: eventActions.EDIT_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* editEventEntryAcademicYrs(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/events/update-entry-years`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_SUCCESS,
      data: response,
    });
    yield put({
      type: tabActions.ACADEMIC_YEAR_EVENT_TAB,
      activeTab: 'semester',
    });
    yield put({
      type: eventActions.GET_SEMESTER_EVENTS_REQUEST,
      semesterId: response.event.semester_id,
    });
  } catch (error) {
    yield put({
      type: eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_ERROR,
      error: error.data,
    });
  }
}

function* deleteEvent(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/events/${actions.eventId}`,
      method: 'DELETE',
    });
    yield put({
      type: eventActions.DELETE_EVENT_SUCCESS,
      data: response,
    });
    yield put({
      type: eventActions.GET_SEMESTER_EVENTS_REQUEST,
      semesterId: actions.semesterId,
    });
    yield put({
      type: eventActions.GET_ACADEMIC_YEAR_EVENTS_REQUEST,
      academicYearId: actions.academicYearId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: eventActions.DELETE_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* getResultEvents(actions) {
  try {
    const { academicYearId } = actions;
    const response = yield axios({
      url: `/events-mgt/result-events/${academicYearId}`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: eventActions.GET_RESULT_EVENTS_SUCCESS,
      data: response.data,
      academicYearId,
    });
  } catch (error) {
    yield put({
      type: eventActions.GET_RESULT_EVENTS_ERROR,
      error: error.data,
    });
  }
}

function* createResultEvent(actions) {
  try {
    const { academicYearId, data } = actions;

    const response = yield axios({
      url: `/events-mgt/result-events/${academicYearId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: eventActions.CREATE_RESULT_EVENT_SUCCESS,
      data: response,
    });
    yield put({
      type: eventActions.GET_RESULT_EVENTS_REQUEST,
      academicYearId,
    });
  } catch (error) {
    yield put({
      type: eventActions.CREATE_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* editResultEvent(actions) {
  try {
    const { academicYearId, data } = actions;

    const response = yield axios({
      url: `/events-mgt/result-events/${academicYearId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: eventActions.EDIT_RESULT_EVENT_SUCCESS,
      data: response,
    });
    yield put({
      type: eventActions.GET_RESULT_EVENTS_REQUEST,
      academicYearId,
    });
  } catch (error) {
    yield put({
      type: eventActions.EDIT_RESULT_EVENT_ERROR,
      error: error.data,
    });
  }
}
function* deleteResultEvent(actions) {
  try {
    const { academicYearId } = actions;

    const response = yield axios({
      url: `/events-mgt/result-events/${academicYearId}`,
      method: 'DELETE',
    });
    yield put({
      type: eventActions.DELETE_RESULT_EVENT_SUCCESS,
      data: response,
    });
    yield put({
      type: eventActions.GET_RESULT_EVENTS_REQUEST,
      academicYearId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: eventActions.DELETE_RESULT_EVENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetEvents() {
  yield takeLatest(eventActions.GET_EVENTS_REQUEST, getEvents);
}

function* watchGetAcademicYearEvents() {
  yield takeLatest(
    eventActions.GET_ACADEMIC_YEAR_EVENTS_REQUEST,
    getAcademicYearEvents
  );
}

function* watchGetSemesterEvents() {
  yield takeLatest(eventActions.GET_SEMESTER_EVENTS_REQUEST, getSemesterEvents);
}

function* watchCreateEvent() {
  yield takeLatest(eventActions.CREATE_EVENT_REQUEST, createEvent);
}

function* watchEditEvent() {
  yield takeLatest(eventActions.EDIT_EVENT_REQUEST, editEvent);
}

function* watchEditEventEntryAcademicYrs() {
  yield takeLatest(
    eventActions.EDIT_EVENT_ENTRY_ACADEMIC_YEARS_REQUEST,
    editEventEntryAcademicYrs
  );
}

function* watchDeleteEvent() {
  yield takeLatest(eventActions.DELETE_EVENT_REQUEST, deleteEvent);
}

function* watchCreateResultEvent() {
  yield takeLatest(eventActions.CREATE_RESULT_EVENT_REQUEST, createResultEvent);
}

function* watchEditResultEvent() {
  yield takeLatest(eventActions.EDIT_RESULT_EVENT_REQUEST, editResultEvent);
}

function* watchGetResultEvents() {
  yield takeLatest(eventActions.GET_RESULT_EVENTS_REQUEST, getResultEvents);
}
function* watchDeleteResultEvent() {
  yield takeLatest(eventActions.DELETE_RESULT_EVENT_REQUEST, deleteResultEvent);
}

export default [
  fork(watchGetEvents),
  fork(watchGetAcademicYearEvents),
  fork(watchGetSemesterEvents),
  fork(watchEditEvent),
  fork(watchEditEventEntryAcademicYrs),
  fork(watchDeleteEvent),
  fork(watchCreateEvent),
  fork(watchCreateResultEvent),
  fork(watchEditResultEvent),
  fork(watchGetResultEvents),
  fork(watchDeleteResultEvent),
];
