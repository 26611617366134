const servicesPolicyActions = {
  GET_SERVICE_POLICIES_REQUEST: 'GET_SERVICE_POLICIES_REQUEST',
  GET_SERVICE_POLICIES_SUCCESS: 'GET_SERVICE_POLICIES_SUCCESS',
  GET_SERVICE_POLICIES_ERROR: 'GET_SERVICE_POLICIES_ERROR',

  CREATE_SERVICE_POLICY_REQUEST: 'CREATE_SERVICE_POLICY_REQUEST',
  CREATE_SERVICE_POLICY_SUCCESS: 'CREATE_SERVICE_POLICY_SUCCESS',
  CREATE_SERVICE_POLICY_ERROR: 'CREATE_SERVICE_POLICY_ERROR',

  UPDATE_SERVICE_POLICY_REQUEST: 'UPDATE_SERVICE_POLICY_REQUEST',
  UPDATE_SERVICE_POLICY_SUCCESS: 'UPDATE_SERVICE_POLICY_SUCCESS',
  UPDATE_SERVICE_POLICY_ERROR: 'UPDATE_SERVICE_POLICY_ERROR',

  DELETE_SERVICE_POLICY_REQUEST: 'DELETE_SERVICE_POLICY_REQUEST',
  DELETE_SERVICE_POLICY_SUCCESS: 'DELETE_SERVICE_POLICY_SUCCESS',
  DELETE_SERVICE_POLICY_ERROR: 'DELETE_SERVICE_POLICY_ERROR',

  SET_SERVICE_POLICY_TO_EDIT: 'SET_SERVICE_POLICY_TO_EDIT',

  getServicePolicies: () => ({
    type: servicesPolicyActions.GET_SERVICE_POLICIES_REQUEST,
  }),

  createServicePolicy: (data) => ({
    type: servicesPolicyActions.CREATE_SERVICE_POLICY_REQUEST,
    data,
  }),

  updateServicePolicy: (policyId, data) => ({
    type: servicesPolicyActions.UPDATE_SERVICE_POLICY_REQUEST,
    policyId,
    data,
  }),

  deleteServicePolicy: (policyId) => ({
    type: servicesPolicyActions.DELETE_SERVICE_POLICY_REQUEST,
    policyId,
  }),

  setServicePolicyToEdit: (setServicePolicy) => ({
    type: servicesPolicyActions.SET_SERVICE_POLICY_TO_EDIT,
    setServicePolicy,
  }),
};

export default servicesPolicyActions;
