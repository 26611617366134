import { systemSponsorActions } from '../../actions';
import systemManager from '../../initialState/system-mgt';

const acmisSponsors = (state = systemManager.systemSponsors, actions) => {
  switch (actions.type) {
    case systemSponsorActions.GET_ACMIS_SPONSORS_REQUEST:
      return {
        ...state,
        gettingSponsors: true,
        getSponsorError: {},
      };
    case systemSponsorActions.GET_ACMIS_SPONSORS_SUCCESS:
      return {
        ...state,
        gettingSponsors: false,
        acmisSponsors: actions.data,
      };
    case systemSponsorActions.GET_ACMIS_SPONSORS_ERROR:
      return {
        ...state,
        gettingSponsors: false,
        getSponsorError: actions.error,
      };

    case systemSponsorActions.GET_EMIS_SPONSORS_REQUEST:
      return {
        ...state,
        gettingSponsors: true,
        getSponsorError: {},
      };
    case systemSponsorActions.GET_EMIS_SPONSORS_SUCCESS:
      return {
        ...state,
        gettingSponsors: false,
        emisSponsors: actions.data,
      };
    case systemSponsorActions.GET_EMIS_SPONSORS_ERROR:
      return {
        ...state,
        gettingSponsors: false,
        getSponsorError: actions.error,
      };

    case systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_REQUEST:
      return {
        ...state,
        submittingSponsor: true,
        submitError: {},
      };
    case systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_SUCCESS:
      return {
        ...state,
        submittingSponsor: false,
        submitSuccess: actions.data,
      };
    case systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_ERROR:
      return {
        ...state,
        submittingSponsor: false,
        submitError: actions.error,
      };

    default:
      return state;
  }
};

export default acmisSponsors;
