import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { academicRegistrarActions } from '../../actions';

function* getGraduationStatistics(actions) {
  try {
    const response = yield axios({
      url: `/bi/result-reports/graduates`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: academicRegistrarActions.GET_GRADUATION_STATS_REPORT_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.GET_GRADUATION_STATS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getGraduationDetailedReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/result-reports/detailed`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_SUCCESS,
      data: response.report,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getEnrollmentReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/enrollment-reports`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: academicRegistrarActions.GET_ENROLLMENT_REPORT_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.GET_ENROLLMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getDemographicReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/enrollment-stat`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_SUCCESS,
      data: response.result,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* downloadDemographicReport(actions) {
  try {
    yield axios({
      url: `/bi/admission-reports/download-stat`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-GENDER-DEMOGRAPHICS-REPORT.xlsx`
      );
    });
    yield put({
      type: academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadGraduationStats(actions) {
  try {
    yield axios({
      url: `/bi/result-reports/download-statistics`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-GRADUATION-STATISTICS.xlsx`
      );
    });
    yield put({
      type: academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadSponsorshipGradStats(actions) {
  try {
    yield axios({
      url: `/bi/result-reports/download-graduate-report`,
      method: 'POST',
      params: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SPONSORSHIP-GRADUATION-STATISTICS.xlsx`
      );
    });
    yield put({
      type: academicRegistrarActions.DOWNLOAD_SPONSORSHIP_GRADUATION_STATISTICS_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.DOWNLOAD_SPONSORSHIP_GRADUATION_STATISTICS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadGraduationDetails(actions) {
  try {
    yield axios({
      url: `/bi/result-reports/download-graduation-list`,
      method: 'POST',
      params: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-GRADUATION-DETAILED-REPORT.xlsx`
      );
    });
    yield put({
      type: academicRegistrarActions.DOWNLOAD_GRADUATION_REPORT_DETAILS_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: academicRegistrarActions.DOWNLOAD_GRADUATION_REPORT_DETAILS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetGraduationStatistics() {
  yield takeLatest(
    academicRegistrarActions.GET_GRADUATION_STATS_REPORT_REQUEST,
    getGraduationStatistics
  );
}

function* watchGetGraduationDetailedReport() {
  yield takeLatest(
    academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_REQUEST,
    getGraduationDetailedReport
  );
}

function* watchGetEnrollmentReport() {
  yield takeLatest(
    academicRegistrarActions.GET_ENROLLMENT_REPORT_REQUEST,
    getEnrollmentReport
  );
}

function* watchGetDemographicReport() {
  yield takeLatest(
    academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_REQUEST,
    getDemographicReport
  );
}

function* watchDownloadDemographicReport() {
  yield takeLatest(
    academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_REQUEST,
    downloadDemographicReport
  );
}

function* watchDownloadGraduationStats() {
  yield takeLatest(
    academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_REQUEST,
    downloadGraduationStats
  );
}

function* watchDownloadSponsorshipGradStats() {
  yield takeLatest(
    academicRegistrarActions.DOWNLOAD_SPONSORSHIP_GRADUATION_STATISTICS_REQUEST,
    downloadSponsorshipGradStats
  );
}

function* watchDownloadGraduationDetails() {
  yield takeLatest(
    academicRegistrarActions.DOWNLOAD_GRADUATION_REPORT_DETAILS_REQUEST,
    downloadGraduationDetails
  );
}

export default [
  fork(watchGetGraduationStatistics),
  fork(watchGetGraduationDetailedReport),
  fork(watchGetEnrollmentReport),
  fork(watchDownloadGraduationStats),
  fork(watchGetDemographicReport),
  fork(watchDownloadGraduationDetails),
  fork(watchDownloadDemographicReport),
  fork(watchDownloadSponsorshipGradStats),
];
