import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { electivePositionActions } from '../../actions';

function* getElectivePositions() {
  try {
    const response = yield axios({
      url: '/e-voting/elective-positions',
      method: 'GET',
    });
    yield put({
      type: electivePositionActions.GET_ELECTIVE_POSITION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.GET_ELECTIVE_POSITION_ERROR,
      error: error.data,
    });
  }
}

function* getSingleElectivePosition(actions) {
  try {
    const response = yield axios({
      url: `/e-voting/elective-positions/${actions.id}`,
      method: 'GET',
    });
    yield put({
      type: electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_SUCCESS,
      data: response.data,
      positionId: actions.id,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_ERROR,
      error: error.data,
    });
  }
}

function* getEligibleVoters(actions) {
  try {
    const { positionId, programmeId } = actions;
    const response = yield axios({
      url: `/e-voting/elective-positions/eligible/${positionId}/${programmeId}`,
      method: 'GET',
    });
    yield put({
      type: electivePositionActions.GET_ELIGIBLE_VOTERS_SUCCESS,
      data: response.data,
      positionId,
      programmeId,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.GET_ELIGIBLE_VOTERS_ERROR,
      error: error.data,
    });
  }
}

function* getVerifiedVoters(actions) {
  try {
    const { positionId, programmeId } = actions;
    const response = yield axios({
      url: `/e-voting/elective-positions/verified/${positionId}/${programmeId}`,
      method: 'GET',
    });
    yield put({
      type: electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_SUCCESS,
      data: response.data,
      positionId,
      programmeId,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_ERROR,
      error: error.data,
    });
  }
}

function* markAsVerified(actions) {
  try {
    const { studentIDs, programmeId, positionId } = actions;

    const response = yield axios({
      url: `/e-voting/elective-positions/mark-as-verified/${positionId}/${programmeId}`,
      method: 'POST',
      data: { studentsIds: studentIDs },
    });
    yield put({
      type: electivePositionActions.MARK_STUDENT_VERIFIED_SUCCESS,
      data: response.data,
      positionId,
      programmeId,
    });
    yield put({
      type: electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_REQUEST,
      positionId,
      programmeId,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.MARK_STUDENT_VERIFIED_ERROR,
      error: error.data,
    });
  }
}

function* addElectivePosition(actions) {
  try {
    const response = yield axios({
      url: '/e-voting/elective-positions',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: electivePositionActions.ADD_ELECTIVE_POSITION_SUCCESS,
      data: response,
    });
    yield put({
      type: electivePositionActions.SET_CURRENT_ELECTIVE_POSITION_TAB,
      data: 'all-positions',
    });
    yield put({
      type: electivePositionActions.GET_ELECTIVE_POSITION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.ADD_ELECTIVE_POSITION_ERROR,
      error: error.data,
    });
  }
}

function* updateElectivePosition(actions) {
  try {
    const response = yield axios({
      url: `/e-voting/elective-positions/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: electivePositionActions.UPDATE_ELECTIVE_POSITION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: electivePositionActions.GET_ELECTIVE_POSITION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.UPDATE_ELECTIVE_POSITION_ERROR,
      error: error.data,
    });
  }
}

function* deleteElectivePosition(actions) {
  try {
    const response = yield axios({
      url: `/e-voting/elective-positions/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: electivePositionActions.DELETE_ELECTIVE_POSITION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: electivePositionActions.GET_ELECTIVE_POSITION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.DELETE_ELECTIVE_POSITION_ERROR,
      error: error.data,
    });
  }
}

function* uploadElectivePositions(actions) {
  try {
    const response = yield axios({
      url: '/e-voting/elective-positions/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: electivePositionActions.UPLOAD_ELECTIVE_POSITION_TEMPLATE_SUCCESS,
      data: response,
    });
    yield put({
      type: electivePositionActions.GET_ELECTIVE_POSITION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadElectivePositionTemplate() {
  try {
    yield axios({
      url: '/e-voting/elective-positions/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `ELECTIVE-POSITION-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetElectivePositions() {
  yield takeLatest(
    electivePositionActions.GET_ELECTIVE_POSITION_REQUEST,
    getElectivePositions
  );
}

function* watchGetSingleElectivePosition() {
  yield takeLatest(
    electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_REQUEST,
    getSingleElectivePosition
  );
}

function* watchGetEligibleVoters() {
  yield takeLatest(
    electivePositionActions.GET_ELIGIBLE_VOTERS_REQUEST,
    getEligibleVoters
  );
}

function* watchGetVerifiedVoter() {
  yield takeLatest(
    electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_REQUEST,
    getVerifiedVoters
  );
}

function* watchMarkAsVerified() {
  yield takeLatest(
    electivePositionActions.MARK_STUDENT_VERIFIED_REQUEST,
    markAsVerified
  );
}

function* watchAddElectivePosition() {
  yield takeLatest(
    electivePositionActions.ADD_ELECTIVE_POSITION_REQUEST,
    addElectivePosition
  );
}

function* watchUpdateElectivePosition() {
  yield takeLatest(
    electivePositionActions.UPDATE_ELECTIVE_POSITION_REQUEST,
    updateElectivePosition
  );
}

function* watchDeleteElectivePosition() {
  yield takeLatest(
    electivePositionActions.DELETE_ELECTIVE_POSITION_REQUEST,
    deleteElectivePosition
  );
}

function* watchUploadElectivePositions() {
  yield takeLatest(
    electivePositionActions.UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_REQUEST,
    uploadElectivePositions
  );
}

function* watchDownloadElectivePositionTemplate() {
  yield takeLatest(
    electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_REQUEST,
    downloadElectivePositionTemplate
  );
}

export default [
  fork(watchGetElectivePositions),
  fork(watchGetSingleElectivePosition),
  fork(watchAddElectivePosition),
  fork(watchUpdateElectivePosition),
  fork(watchDeleteElectivePosition),
  fork(watchUploadElectivePositions),
  fork(watchDownloadElectivePositionTemplate),
  fork(watchGetEligibleVoters),
  fork(watchGetVerifiedVoter),
  fork(watchMarkAsVerified),
];
