const financeClearance = {
  graduationContext: {},

  gettingGraduationStats: false,
  graduationStats: [],
  graduationStatsError: {},

  gettingGraduationReport: false,
  graduationReport: [],
  graduationReportError: {},

  downloadContext: {},

  downloadingReport: false,
  downloadedReport: {},
  downloadError: {},

  gettingStudents: false,
  getStudentsError: {},
  clearanceStudent: {},
};

export default financeClearance;
