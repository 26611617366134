const pujabApplicantActions = {
  GET_PUJAB_APPLICANTS_REQUEST: 'GET_PUJAB_APPLICANTS_REQUEST',
  GET_PUJAB_APPLICANTS_SUCCESS: 'GET_PUJAB_APPLICANTS_SUCCESS',
  GET_PUJAB_APPLICANTS_ERROR: 'GET_PUJAB_APPLICANTS_ERROR',

  GET_PROPOSED_ADMISSIONS_REQUEST: 'GET_PROPOSED_ADMISSIONS_REQUEST',
  GET_PROPOSED_ADMISSIONS_SUCCESS: 'GET_PROPOSED_ADMISSIONS_SUCCESS',
  GET_PROPOSED_ADMISSIONS_ERROR: 'GET_PROPOSED_ADMISSIONS_ERROR',

  UPDATE_PUJAB_APPLICANT_ADMISSION_REQUEST:
    'UPDATE_PUJAB_APPLICANT_ADMISSION_REQUEST',
  UPDATE_PUJAB_APPLICANT_ADMISSION_SUCCESS:
    'UPDATE_PUJAB_APPLICANT_ADMISSION_SUCCESS',
  UPDATE_PUJAB_APPLICANT_ADMISSION_ERROR:
    'UPDATE_PUJAB_APPLICANT_ADMISSION_ERROR',

  UPLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST:
    'UPLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST',
  UPLOAD_PUJAB_APPLICANTS_TEMPLATE_SUCCESS:
    'UPLOAD_PUJAB_APPLICANTS_TEMPLATE_SUCCESS',
  UPLOAD_PUJAB_APPLICANTS_TEMPLATE_ERROR:
    'UPLOAD_PUJAB_APPLICANTS_TEMPLATE_ERROR',

  DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST:
    'DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST',
  DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_SUCCESS:
    'DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_SUCCESS',
  DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_ERROR:
    'DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_ERROR',

  UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
    'UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST',
  UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
    'UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS',
  UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
    'UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR',

  DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST:
    'DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST',
  DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS:
    'DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS',
  DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR:
    'DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR',

  getPujabApplicants: () => ({
    type: pujabApplicantActions.GET_PUJAB_APPLICANTS_REQUEST,
  }),

  getProposedAdmissions: () => ({
    type: pujabApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST,
  }),

  uploadApplicants: (data) => ({
    type: pujabApplicantActions.UPLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST,
    data,
  }),

  updatePujabApplicantAdmission: (id, data, applicantType) => ({
    type: pujabApplicantActions.UPDATE_PUJAB_APPLICANT_ADMISSION_REQUEST,
    id,
    data,
    applicantType,
  }),

  downloadApplicantsTemplate: () => ({
    type: pujabApplicantActions.DOWNLOAD_PUJAB_APPLICANTS_TEMPLATE_REQUEST,
  }),

  uploadProposedAdmissions: (data) => ({
    type: pujabApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST,
    data,
  }),

  downloadProposedAdmissionsTemplate: () => ({
    type: pujabApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST,
  }),
};

export default pujabApplicantActions;
