const programmeMgtInitialState = {
  courseUnit: {
    courseUnits: [],
    courseUnitError: {},
    createdCourseUnitData: {},
    courseUnitDownloadData: [],
    createCourseUnitError: {},
    fetchingOne: false,
    updateResponse: {},
    deletingId: null,
    updatingId: null,
    updating: false,
    updateError: {},
    downloadError: {},
    loading: false,
    creating: false,
    editCourseTabVisible: false,
    courseToEdit: {},
    repoCourse: {},
    dataToEdit: {},

    uploading: false,
    uploadError: {},
    uploadSuccess: {},

    gettingProgrammes: false,
    getProgrammeError: {},
    courseUnitProgrammes: [],

    fetching: false,
    courseDetailsError: {},
    courseDetails: {},

    editing: false,
    editError: {},
    editSuccess: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    assigningDepartment: false,
    assignError: {},
    assignSuccess: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},
  },
  programme: {
    currentTab: 'details',
    loading: false,
    fetching: false,
    creating: false,
    fetchingOne: false,
    updating: false,
    loadError: {},
    settingCategory: false,
    setCategoryError: {},
    downloadError: {},
    createProgrammeError: {},
    createdProgrammeData: {},
    programmesWithYears: [],
    programmeYearsError: {},
    gettingProgrammesWithYears: false,
    programmesByCollege: [],
    programmesByCollegeError: {},
    programmesWithCategories: [],
    programmeDownloadData: [],
    programmes: [],
    groupedProgrammes: [],
    programme: {},
    singleProgrammes: [],
    programmesDetails: [],
    dashboardData: [],
    dashboardError: {},
    programmeError: {},
    uploading: false,
    uploadResponse: {},
    uploadError: {},
    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    gettingDepartmentProgrammes: false,
    departmentProgrammeError: {},
    departmentProgrammes: [],

    gettingAcademicUnitProgrammes: false,
    academicUnitProgrammesError: {},
    academicUnitProgrammes: [],

    deleting: false,
    deleteProgrammeError: {},
    deleteProgrammeData: {},

    gettingAdmissionsProgrammes: false,
    admissionsProgrammesError: {},
    admissionsProgrammes: [],

    fetchingProgDetails: false,
  },
  programmeAlias: {
    creating: false,
    updating: false,
    deleting: false,
    createError: {},
    updateError: {},
    deleteError: {},
    programmeAlias: {},
    deleteResponse: {},
  },
  programmeVersion: {
    loading: false,
    fetching: false,
    creating: false,
    versions: [],
    version: {},
    createdVersion: {},
    versionError: {},
    versionCourseUnits: [],
    programmeVersions: [],
    versionCourseUnitError: {},
    gettingSingleVersion: false,
    singleVersionError: {},

    deletingCourseUnit: false,
    deleteCourseUnitSuccess: {},
    deleteCourseUnitError: {},

    gettingVersionOptions: false,
    versionOptions: [],
    versionOptionError: {},
  },
  specialization: {
    fetching: false,
    specializations: [],
    specialization: {},
    getSpecializationError: {},
    createSpecializationError: {},
    specializationError: {},
    deleteResponse: {},
    updateResponse: {},
    deletingId: null,
    updatingId: null,
    updating: false,
    deleting: false,
    loading: false,
    creating: false,
  },
  grading: {
    loading: false,
    fetching: false,
    creating: false,
    updating: false,
    gradings: [],
    grading: {},
    getGradingError: {},
    createGradingError: {},
    activeTab: 'view',
  },
  subject: {
    subjects: [],
    subjectCombinations: [],
    subject: {},
    subjectError: {},
    subjectCombinationError: {},
    createdData: {},
    deleteResponse: {},
    deleteCombinationResponse: {},
    updateResponse: {},
    updateCombinationResponse: {},
    deletingId: null,
    updatingId: null,
    deletingCombinationId: null,
    updatingCombinationId: null,
    updating: false,
    deleting: false,
    updatingCombination: false,
    deletingCombination: false,
    loading: false,
    creating: false,
    createError: {},

    loadingCombinations: false,
    versionSubjectCombinations: {},
    versionCombinationError: {},
  },
  unebSubject: {
    unebSubjects: [],
    unebSubject: {},
    subjectError: {},
    createdData: {},
    loading: false,
    creating: false,
    updating: false,
    deleting: false,

    deletingUnebSubject: false,
    deleteUnebSubjectError: {},
    deleteUnebSubjectSuccess: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},
  },
  unebCenter: {
    unebCenters: [],
    unebCenter: {},
    centerError: {},
    createdData: {},
    loading: false,
    creating: false,
    updating: false,
    deleting: false,

    deletingUnebCenter: false,
    deleteUnebErrorError: {},
    deleteUnebCenterSuccess: {},
  },
  semesterLoad: {
    semesterLoads: [],
    semesterLoad: {},
    semesterLoadError: {},
    deleteResponse: {},
    updateResponse: {},
    createdData: {},
    deletingId: null,
    updatingId: null,
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
    createError: {},
  },
  programmeSearch: {
    progSearchResponse: {},
    progSearchError: {},
    searchingProg: false,

    courseSearchResponse: {},
    courseSearchError: {},
    searchingCourse: false,

    searchParameter: { prog: '', course: '' },
  },
};

export default programmeMgtInitialState;
