const courseUnit = {
  GET_COURSE_UNITS_REQUEST: 'GET_COURSE_UNITS_REQUEST',
  GET_COURSE_UNITS_SUCCESS: 'GET_COURSE_UNITS_SUCCESS',
  GET_COURSE_UNITS_ERROR: 'GET_COURSE_UNITS_ERROR',

  GET_COURSE_DETAILS_REQUEST: 'GET_COURSE_DETAILS_REQUEST',
  GET_COURSE_DETAILS_SUCCESS: 'GET_COURSE_DETAILS_SUCCESS',
  GET_COURSE_DETAILS_ERROR: 'GET_COURSE_DETAILS_ERROR',

  GET_COURSE_UNIT_PROGRAMMES_REQUEST: 'GET_COURSE_UNIT_PROGRAMMES_REQUEST',
  GET_COURSE_UNIT_PROGRAMMES_SUCCESS: 'GET_COURSE_UNIT_PROGRAMMES_SUCCESS',
  GET_COURSE_UNIT_PROGRAMMES_ERROR: 'GET_COURSE_UNIT_PROGRAMMES_ERROR',

  GET_COURSE_UNITS_DOWNLOAD_REQUEST: 'GET_COURSE_UNITS_DOWNLOAD_REQUEST',
  GET_COURSE_UNITS_DOWNLOAD_SUCCESS: 'GET_COURSE_UNITS_DOWNLOAD_SUCCESS',
  GET_COURSE_UNITS_DOWNLOAD_ERROR: 'GET_COURSE_UNITS_DOWNLOAD_ERROR',

  CREATE_COURSE_UNIT_SUCCESS: 'CREATE_COURSE_UNIT_SUCCESS',
  CREATE_COURSE_UNIT_REQUEST: 'CREATE_COURSE_UNIT_REQUEST',
  CREATE_COURSE_UNIT_ERROR: 'CREATE_COURSE_UNIT_ERROR',

  DELETE_COURSE_UNIT_REQUEST: 'DELETE_COURSE_UNIT_REQUEST',
  DELETE_COURSE_UNIT_SUCCESS: 'DELETE_COURSE_UNIT_SUCCESS',
  DELETE_COURSE_UNIT_ERROR: 'DELETE_COURSE_UNIT_ERROR',

  DELETE_REPO_COURSE_UNIT_REQUEST: 'DELETE_REPO_COURSE_UNIT_REQUEST',
  DELETE_REPO_COURSE_UNIT_SUCCESS: 'DELETE_REPO_COURSE_UNIT_SUCCESS',
  DELETE_REPO_COURSE_UNIT_ERROR: 'DELETE_REPO_COURSE_UNIT_ERROR',

  UPDATE_COURSE_UNIT_REQUEST: 'UPDATE_COURSE_UNIT_REQUEST',
  UPDATE_COURSE_UNIT_SUCCESS: 'UPDATE_COURSE_UNIT_SUCCESS',
  UPDATE_COURSE_UNIT_ERROR: 'UPDATE_COURSE_UNIT_ERROR',

  UPDATE_COURSE_DETAILS_REQUEST: 'UPDATE_COURSE_DETAILS_REQUEST',
  UPDATE_COURSE_DETAILS_SUCCESS: 'UPDATE_COURSE_DETAILS_SUCCESS',
  UPDATE_COURSE_DETAILS_ERROR: 'UPDATE_COURSE_DETAILS_ERROR',

  UPLOAD_COURSE_UNIT_REQUEST: 'UPLOAD_COURSE_UNIT_REQUEST',
  UPLOAD_COURSE_UNIT_SUCCESS: 'UPLOAD_COURSE_UNIT_SUCCESS',
  UPLOAD_COURSE_UNIT_ERROR: 'UPLOAD_COURSE_UNIT_ERROR',

  EDIT_REPO_COURSE_REQUEST: 'EDIT_REPO_COURSE_REQUEST',
  EDIT_REPO_COURSE_SUCCESS: 'EDIT_REPO_COURSE_SUCCESS',
  EDIT_REPO_COURSE_ERROR: 'EDIT_REPO_COURSE_ERROR',

  DOWNLOAD_COURSE_UNIT_TEMPLATE_SUCCESS:
    'DOWNLOAD_COURSE_UNIT_TEMPLATE_SUCCESS',
  DOWNLOAD_COURSE_UNIT_TEMPLATE_REQUEST:
    'DOWNLOAD_COURSE_UNIT_TEMPLATE_REQUEST',
  DOWNLOAD_COURSE_UNIT_TEMPLATE_ERROR: 'DOWNLOAD_COURSE_UNIT_TEMPLATE_ERROR',

  ASSIGN_COURSE_UNIT_DEPARTMENT_SUCCESS:
    'ASSIGN_COURSE_UNIT_DEPARTMENT_SUCCESS',
  ASSIGN_COURSE_UNIT_DEPARTMENT_REQUEST:
    'ASSIGN_COURSE_UNIT_DEPARTMENT_REQUEST',
  ASSIGN_COURSE_UNIT_DEPARTMENT_ERROR: 'ASSIGN_COURSE_UNIT_DEPARTMENT_ERROR',

  SET_REPO_COURSE_TO_EDIT: 'SET_REPO_COURSE_TO_EDIT',
  SET_EDIT_COURSE_DETAILS: 'SET_EDIT_COURSE_DETAILS',
  SET_COURSE_UNIT_EDIT_TAB: 'SET_COURSE_UNIT_TAB',

  getCourseUnits: (data) => ({
    type: courseUnit.GET_COURSE_UNITS_REQUEST,
    data,
  }),

  getCourseDetails: (courseId) => ({
    type: courseUnit.GET_COURSE_DETAILS_REQUEST,
    courseId,
  }),

  setCourseDetailsToEdit: (course) => ({
    type: courseUnit.SET_EDIT_COURSE_DETAILS,
    course,
  }),

  getCourseUnitsDownload: (data) => ({
    type: courseUnit.GET_COURSE_UNITS_DOWNLOAD_REQUEST,
    data,
  }),

  getCourseUnitProgrammes: (params) => ({
    type: courseUnit.GET_COURSE_UNIT_PROGRAMMES_REQUEST,
    params,
  }),

  createCourseUnit: (data) => ({
    type: courseUnit.CREATE_COURSE_UNIT_REQUEST,
    data,
  }),

  deleteCourseUnit: (id, programmeVersionId) => ({
    type: courseUnit.DELETE_COURSE_UNIT_REQUEST,
    id,
    programmeVersionId,
  }),

  deleteRepoCourseUnit: (courseId) => ({
    type: courseUnit.DELETE_REPO_COURSE_UNIT_REQUEST,
    courseId,
  }),

  updateCourseUnit: (id, programmeVersionId, data) => ({
    type: courseUnit.UPDATE_COURSE_UNIT_REQUEST,
    id,
    data,
    programmeVersionId,
  }),

  updateCourseDetails: (courseId, data) => ({
    type: courseUnit.UPDATE_COURSE_DETAILS_REQUEST,
    courseId,
    data,
  }),

  assignDepartment: (courseUnitId, departmentId, programmeVersionId) => ({
    type: courseUnit.ASSIGN_COURSE_UNIT_DEPARTMENT_REQUEST,
    courseUnitId,
    departmentId,
    programmeVersionId,
  }),

  uploadCourseUnits: (programmeId, version, files) => ({
    type: courseUnit.UPLOAD_COURSE_UNIT_REQUEST,
    programmeId,
    version,
    files,
  }),

  downloadCourseUnitTemplate: (programmeId, programmeVersionId) => ({
    type: courseUnit.DOWNLOAD_COURSE_UNIT_TEMPLATE_REQUEST,
    programmeId,
    programmeVersionId,
  }),

  setCourseUnitEditTab: (tabState, data) => ({
    type: courseUnit.SET_COURSE_UNIT_EDIT_TAB,
    tabState,
    data,
  }),

  setRepoCourseToEdit: (data) => ({
    type: courseUnit.SET_REPO_COURSE_TO_EDIT,
    data,
  }),

  editRepoCourse: (courseId, data) => ({
    type: courseUnit.EDIT_REPO_COURSE_REQUEST,
    courseId,
    data,
  }),
};

export default courseUnit;
