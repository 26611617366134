import { weightingAndSelectionActions } from '../../actions';
import initialState from '../../initialState';

function weightingAndSelection(
  state = initialState.weightingAndSelection,
  actions
) {
  switch (actions.type) {
    case weightingAndSelectionActions.CREATE_WEIGHTING_CRITERIA_REQUEST:
      return {
        ...state,
        createCriteriaError: {},
        creatingCriteria: true,
      };
    case weightingAndSelectionActions.CREATE_WEIGHTING_CRITERIA_SUCCESS:
      return {
        ...state,
        createdCriteria: actions.data,
        creatingCriteria: false,
      };
    case weightingAndSelectionActions.CREATE_WEIGHTING_CRITERIA_ERROR:
      return {
        ...state,
        createCriteriaError: actions.error,
        creatingCriteria: false,
      };

    case weightingAndSelectionActions.CREATE_SELECTION_CRITERIA_REQUEST:
      return {
        ...state,
        createSelectionCriteriaError: {},
        createdSelectionCriteria: {},
        creatingSelectionCriteria: true,
      };
    case weightingAndSelectionActions.CREATE_SELECTION_CRITERIA_SUCCESS:
      return {
        ...state,
        createdSelectionCriteria: actions.data,
        creatingSelectionCriteria: false,
      };
    case weightingAndSelectionActions.CREATE_SELECTION_CRITERIA_ERROR:
      return {
        ...state,
        createSelectionCriteriaError: actions.error,
        creatingSelectionCriteria: false,
      };

    case weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST:
      return {
        ...state,
        getCriteriaError: {},
        gettingCriteria: true,
      };
    case weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_SUCCESS:
      return {
        ...state,
        weightingCriteria: actions.data,
        gettingCriteria: false,
      };
    case weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_ERROR:
      return {
        ...state,
        getCriteriaError: actions.error,
        gettingCriteria: false,
      };

    case weightingAndSelectionActions.GET_SELECTION_CRITERIA_REQUEST:
      return {
        ...state,
        getSelectionError: {},
        gettingSelection: true,
      };
    case weightingAndSelectionActions.GET_SELECTION_CRITERIA_SUCCESS:
      return {
        ...state,
        weightingSelection: actions.data,
        gettingSelection: false,
      };
    case weightingAndSelectionActions.GET_SELECTION_CRITERIA_ERROR:
      return {
        ...state,
        getSelectionError: actions.error,
        gettingSelection: false,
      };

    case weightingAndSelectionActions.ADD_UNEB_SUBJECTS_REQUEST:
      return {
        ...state,
        addCriteriaSubjectsError: {},
        addingCriteriaSubjects: true,
      };
    case weightingAndSelectionActions.ADD_UNEB_SUBJECTS_SUCCESS:
      return {
        ...state,
        addedCriteriaSubjects: actions.data,
        addingCriteriaSubjects: false,
      };
    case weightingAndSelectionActions.ADD_UNEB_SUBJECTS_ERROR:
      return {
        ...state,
        addCriteriaSubjectsError: actions.error,
        addingCriteriaSubjects: false,
      };

    case weightingAndSelectionActions.ADD_NEW_CATEGORY_REQUEST:
      return {
        ...state,
        addNewCategoryError: {},
        addingNewCategory: true,
      };
    case weightingAndSelectionActions.ADD_NEW_CATEGORY_SUCCESS:
      return {
        ...state,
        addedCategory: actions.data,
        addingNewCategory: false,
      };
    case weightingAndSelectionActions.ADD_NEW_CATEGORY_ERROR:
      return {
        ...state,
        addNewCategoryError: actions.error,
        addingNewCategory: false,
      };

    case weightingAndSelectionActions.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        updateCategoryError: {},
        updatingCategory: true,
      };
    case weightingAndSelectionActions.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updatingCategory: false,
      };
    case weightingAndSelectionActions.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updateCategoryError: actions.error,
        updatingCategory: false,
      };

    case weightingAndSelectionActions.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        deleteCategoryError: {},
        deletingCategory: true,
      };
    case weightingAndSelectionActions.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deletingCategory: false,
      };
    case weightingAndSelectionActions.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteCategoryError: actions.error,
        deletingCategory: false,
      };

    case weightingAndSelectionActions.SET_CURRENT_CRITERIA_TAB:
      return {
        ...state,
        currentCriteriaTab: actions.tabName,
      };

    case weightingAndSelectionActions.SET_CURRENT_SELECTION_TAB:
      return {
        ...state,
        currentSelectionTab: actions.tabName,
      };

    case weightingAndSelectionActions.SET_SELECTED_CRITERIA:
      return {
        ...state,
        selectedCriteria: actions.data,
      };

    case weightingAndSelectionActions.SET_SELECTED_SELECTION_CRITERIA:
      return {
        ...state,
        selectionCriteria: actions.data,
      };

    case weightingAndSelectionActions.SET_CRITERIA_TO_ADD_SUBJECTS:
      return {
        ...state,
        criteriaToAddSubjects: actions.data,
      };

    case weightingAndSelectionActions.SET_CATEGORY_VIEW:
      return {
        ...state,
        categoryView: actions.tabName,
      };

    case weightingAndSelectionActions.SET_ACTION_TO_PERFORM:
      return {
        ...state,
        actionToPerform: actions.actionName,
      };

    default:
      return state;
  }
}

export default weightingAndSelection;
