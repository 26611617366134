const tuitionFeesPolicy = {
  GET_TUITION_FEES_POLICIES_REQUEST: 'GET_TUITION_FEES_POLICIES_REQUEST',
  GET_TUITION_FEES_POLICIES_SUCCESS: 'GET_TUITION_FEES_POLICIES_SUCCESS',
  GET_TUITION_FEES_POLICIES_ERROR: 'GET_TUITION_FEES_POLICIES_ERROR',

  CREATE_TUITION_FEES_POLICY_REQUEST: 'CREATE_TUITION_FEES_POLICY_REQUEST',
  CREATE_TUITION_FEES_POLICY_SUCCESS: 'CREATE_TUITION_FEES_POLICY_SUCCESS',
  CREATE_TUITION_FEES_POLICY_ERROR: 'CREATE_TUITION_FEES_POLICY_ERROR',

  UPDATE_TUITION_FEES_POLICY_REQUEST: 'UPDATE_TUITION_FEES_POLICY_REQUEST',
  UPDATE_TUITION_FEES_POLICY_SUCCESS: 'UPDATE_TUITION_FEES_POLICY_SUCCESS',
  UPDATE_TUITION_FEES_POLICY_ERROR: 'UPDATE_TUITION_FEES_POLICY_ERROR',

  DELETE_TUITION_FEES_POLICY_REQUEST: 'DELETE_TUITION_FEES_POLICY_REQUEST',
  DELETE_TUITION_FEES_POLICY_SUCCESS: 'DELETE_TUITION_FEES_POLICY_SUCCESS',
  DELETE_TUITION_FEES_POLICY_ERROR: 'DELETE_TUITION_FEES_POLICY_ERROR',

  SET_TUITION_FEES_POLICY_TO_EDIT: 'SET_TUITION_FEES_POLICY_TO_EDIT',

  getTuitionFeesPolicies: (params) => ({
    type: tuitionFeesPolicy.GET_TUITION_FEES_POLICIES_REQUEST,
    params,
  }),

  createTuitionFeesPolicy: (data) => ({
    type: tuitionFeesPolicy.CREATE_TUITION_FEES_POLICY_REQUEST,
    data,
  }),

  updateTuitionFeesPolicy: (tuitionFeesPolicyId, data) => ({
    type: tuitionFeesPolicy.UPDATE_TUITION_FEES_POLICY_REQUEST,
    data,
    tuitionFeesPolicyId,
  }),

  deleteTuitionFeesPolicy: (tuitionFeesPolicyId) => ({
    type: tuitionFeesPolicy.DELETE_TUITION_FEES_POLICY_REQUEST,
    tuitionFeesPolicyId,
  }),

  deleteTuitionFeesPolicyElement: (tuitionFeesPolicyId, elementsToDelete) => ({
    type: tuitionFeesPolicy.DELETE_TUITION_FEES_POLICY_ELEMENT_REQUEST,
    tuitionFeesPolicyId,
    elementsToDelete,
  }),

  setPolicyToEdit: (tuitionFeesPolicyToEdit) => ({
    type: tuitionFeesPolicy.SET_TUITION_FEES_POLICY_TO_EDIT,
    tuitionFeesPolicyToEdit,
  }),
};

export default tuitionFeesPolicy;
