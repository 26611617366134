import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { resultDashboardActions } from '../../actions';
import initialState from '../../initialState';

const resultDashboard = (state = initialState.resultDashboard, actions) => {
  switch (actions.type) {
    case resultDashboardActions.FETCH_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case resultDashboardActions.FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: actions.data,
        loading: false,
      };
    case resultDashboardActions.FETCH_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_REQUEST:
      return {
        ...state,
        downloadingResults: true,
        downloadResultError: {},
        downloadResultSuccess: [],
      };
    case resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_SUCCESS:
      return {
        ...state,
        downloadResultSuccess: actions.data,
        downloadingResults: false,
      };
    case resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_ERROR:
      return {
        ...state,
        downloadResultError: actions.error,
        downloadingResults: false,
      };

    case resultDashboardActions.GET_DETAILED_RESULT_REPORT_REQUEST:
      return {
        ...state,
        fetchError: {},
        fetching: true,
      };
    case resultDashboardActions.GET_DETAILED_RESULT_REPORT_SUCCESS: {
      const { detailedReports } = state;
      const { context } = actions;
      const findContextIndex = findIndex(
        detailedReports,
        (report) =>
          report.study_year_id === context.study_year_id &&
          report.programme_id === context.programme_id &&
          report.campus_id === context.campus_id &&
          report.intake_id === context.intake_id &&
          report.semester_id === context.semester_id &&
          report.reportCategory === context.reportCategory &&
          report.semester_id === context.semester_id &&
          report.academic_year_id === context.academic_year_id
      );

      let newState = state;
      const contextData = { ...context, ...actions.data };

      if (findContextIndex === -1) {
        newState = update(newState, {
          detailedReports: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          detailedReports: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        fetching: false,
      };
    }
    case resultDashboardActions.GET_DETAILED_RESULT_REPORT_ERROR:
      return {
        ...state,
        fetchError: actions.error,
        fetching: false,
      };

    case resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_REQUEST:
      return {
        ...state,
        submitProgrammeReportError: {},
        submittingProgrammeReport: true,
      };
    case resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_SUCCESS:
      return {
        ...state,
        submittingProgrammeReport: false,
        submittedProgrammeReport: actions.data,
      };

    case resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_ERROR:
      return {
        ...state,
        submitProgrammeReportError: actions.error,
        submittingProgrammeReport: false,
      };

    case resultDashboardActions.GET_RESULT_SUMMARY_REPORT_REQUEST: {
      const { category, context } = actions;

      if (category === 'programme') {
        return {
          ...state,
          semesterResultSummaryError: {},
          gettingSemesterSummary: true,
        };
      }
      if (category === 'senate') {
        return {
          ...state,
          senateSummaryError: {},
          gettingSenateSummary: true,
          senateContext: context,
        };
      }
      if (category === 'department') {
        return {
          ...state,
          departmentSummaryError: {},
          gettingDepartmentSummary: true,
        };
      }
      return {
        ...state,
        facultySummaryError: {},
        gettingFacultySummary: true,
        facultyContext: context,
      };
    }

    case resultDashboardActions.GET_RESULT_SUMMARY_REPORT_SUCCESS: {
      const { category, data, context } = actions;
      const contextData = { ...context, data };
      let newState = state;

      if (category === 'programme') {
        const { semesterResultSummary } = newState;
        const findContextIndex = findIndex(
          semesterResultSummary,
          (report) =>
            report.study_year_id === context.study_year_id &&
            report.programme_id === context.programme_id &&
            report.campus_id === context.campus_id &&
            report.intake_id === context.intake_id &&
            report.semester_id === context.semester_id &&
            report.reportCategory === context.reportCategory &&
            report.academic_year_id === context.academic_year_id
        );

        if (findContextIndex === -1) {
          newState = update(newState, {
            semesterResultSummary: { $push: [contextData] },
          });
        } else {
          newState = update(newState, {
            semesterResultSummary: {
              [findContextIndex]: { $set: contextData },
            },
          });
        }
      } else if (category === 'senate') {
        const { senateReports } = newState;
        const findContextIndex = findIndex(
          senateReports,
          (report) =>
            report.campus_id === context.campus_id &&
            report.intake_id === context.intake_id &&
            report.reportCategory === context.reportCategory &&
            report.academic_year_id === context.academic_year_id
        );
        if (findContextIndex === -1) {
          newState = update(newState, {
            senateReports: { $push: [contextData] },
          });
        } else {
          newState = update(newState, {
            senateReports: {
              [findContextIndex]: { $set: contextData },
            },
          });
        }
      } else if (category === 'department') {
        const { departmentReports } = newState;
        const findContextIndex = findIndex(
          departmentReports,
          (report) =>
            report.academic_unit === 'department' &&
            report.campus_id === context.campus_id &&
            report.intake_id === context.intake_id &&
            report.faculty_id === context.faculty_id &&
            report.reportCategory === context.reportCategory &&
            report.academic_year_id === context.academic_year_id
        );

        if (findContextIndex === -1) {
          newState = update(newState, {
            departmentReports: { $push: [contextData] },
          });
        } else {
          newState = update(newState, {
            departmentReports: {
              [findContextIndex]: { $set: contextData },
            },
          });
        }
      } else if (category === 'faculty') {
        const { facultyReports } = state;
        const findContextIndex = findIndex(
          facultyReports,
          (report) =>
            report.academic_unit === 'faculty' &&
            report.campus_id === context.campus_id &&
            report.intake_id === context.intake_id &&
            report.faculty_id === context.faculty_id &&
            report.reportCategory === context.reportCategory &&
            report.academic_year_id === context.academic_year_id
        );

        if (findContextIndex === -1) {
          newState = update(newState, {
            facultyReports: { $push: [contextData] },
          });
        } else {
          newState = update(newState, {
            facultyReports: {
              [findContextIndex]: { $set: contextData },
            },
          });
        }
      }

      return {
        ...newState,
        gettingSenateSummary: false,
        gettingSemesterSummary: false,
        gettingFacultySummary: false,
      };
    }

    case resultDashboardActions.GET_RESULT_SUMMARY_REPORT_ERROR: {
      const { category, error } = actions;

      if (category === 'programme') {
        return {
          ...state,
          semesterResultSummaryError: error,
          gettingSemesterSummary: false,
        };
      }
      if (category === 'senate') {
        return {
          ...state,
          senateSummaryError: error,
          gettingSenateSummary: false,
        };
      }
      if (category === 'department') {
        return {
          ...state,
          departmentSummaryError: error,
          gettingDepartmentSummary: false,
        };
      }
      return {
        ...state,
        facultySummaryError: error,
        gettingFacultySummary: false,
      };
    }

    case resultDashboardActions.GET_SEMESTER_PROGRAMMES_REQUEST:
      return {
        ...state,
        programmeError: {},
        fetchingProgramme: true,
      };
    case resultDashboardActions.GET_SEMESTER_PROGRAMMES_SUCCESS:
      return {
        ...state,
        programmes: actions.data,
        fetchingProgramme: false,
      };
    case resultDashboardActions.GET_SEMESTER_PROGRAMMES_ERROR:
      return {
        ...state,
        programmeError: actions.error,
        fetchingProgramme: false,
      };

    case resultDashboardActions.SET_DEPARTMENT_CONTEXT:
      return {
        ...state,
        departmentContext: actions.context,
      };

    case resultDashboardActions.SET_SELECTED_STUDY_YEAR:
      return {
        ...state,
        selectedStudyYear: actions.yearId,
      };

    case resultDashboardActions.SET_PROGRAMMES_TOGGLE:
      return {
        ...state,
        toggleProgrammes: actions.bol,
      };

    case resultDashboardActions.SET_SELECTED_TAB_CONTEXT:
      return {
        ...state,
        reportCategory: actions.context,
      };

    default:
      return state;
  }
};

export default resultDashboard;
