const electivePositionActions = {
  GET_ELECTIVE_POSITION_REQUEST: 'GET_ELECTIVE_POSITION_REQUEST',
  GET_ELECTIVE_POSITION_SUCCESS: 'GET_ELECTIVE_POSITION_SUCCESS',
  GET_ELECTIVE_POSITION_ERROR: 'GET_ELECTIVE_POSITION_ERROR',

  GET_SINGLE_ELECTIVE_POSITION_REQUEST: 'GET_SINGLE_ELECTIVE_POSITION_REQUEST',
  GET_SINGLE_ELECTIVE_POSITION_SUCCESS: 'GET_SINGLE_ELECTIVE_POSITION_SUCCESS',
  GET_SINGLE_ELECTIVE_POSITION_ERROR: 'GET_SINGLE_ELECTIVE_POSITION_ERROR',

  GET_ELIGIBLE_VOTERS_REQUEST: 'GET_ELIGIBLE_VOTERS_REQUEST',
  GET_ELIGIBLE_VOTERS_SUCCESS: 'GET_ELIGIBLE_VOTERS_SUCCESS',
  GET_ELIGIBLE_VOTERS_ERROR: 'GET_ELIGIBLE_VOTERS_ERROR',

  GET_VERIFIED_VOTER_REGISTRY_REQUEST: 'GET_VERIFIED_VOTER_REGISTRY_REQUEST',
  GET_VERIFIED_VOTER_REGISTRY_SUCCESS: 'GET_VERIFIED_VOTER_REGISTRY_SUCCESS',
  GET_VERIFIED_VOTER_REGISTRY_ERROR: 'GET_VERIFIED_VOTER_REGISTRY_ERROR',

  ADD_ELECTIVE_POSITION_REQUEST: 'ADD_ELECTIVE_POSITION_REQUEST',
  ADD_ELECTIVE_POSITION_SUCCESS: 'ADD_ELECTIVE_POSITION_SUCCESS',
  ADD_ELECTIVE_POSITION_ERROR: 'ADD_ELECTIVE_POSITION_ERROR',

  MARK_STUDENT_VERIFIED_REQUEST: 'MARK_STUDENT_VERIFIED_REQUEST',
  MARK_STUDENT_VERIFIED_SUCCESS: 'MARK_STUDENT_VERIFIED_SUCCESS',
  MARK_STUDENT_VERIFIED_ERROR: 'MARK_STUDENT_VERIFIED_ERROR',

  UPDATE_ELECTIVE_POSITION_REQUEST: 'UPDATE_ELECTIVE_POSITION_REQUEST',
  UPDATE_ELECTIVE_POSITION_SUCCESS: 'UPDATE_ELECTIVE_POSITION_SUCCESS',
  UPDATE_ELECTIVE_POSITION_ERROR: 'UPDATE_ELECTIVE_POSITION_ERROR',

  DELETE_ELECTIVE_POSITION_REQUEST: 'DELETE_ELECTIVE_POSITION_REQUEST',
  DELETE_ELECTIVE_POSITION_SUCCESS: 'DELETE_ELECTIVE_POSITION_SUCCESS',
  DELETE_ELECTIVE_POSITION_ERROR: 'DELETE_ELECTIVE_POSITION_ERROR',

  SET_CURRENT_ELECTIVE_POSITION_TAB: 'SET_CURRENT_ELECTIVE_POSITION_TAB',
  SET_SELECTED_ELECTIVE_POSITION_ROW: 'SET_SELECTED_ELECTIVE_POSITION_ROW',

  SET_SELECTED_ELECTIVE_POSITION_PROGRAMME:
    'SET_SELECTED_ELECTIVE_POSITION_PROGRAMME',

  UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_REQUEST:
    'UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_REQUEST',
  UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_SUCCESS:
    'UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_SUCCESS',
  UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_ERROR:
    'UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_ERROR',

  DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_REQUEST:
    'DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_REQUEST',
  DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_SUCCESS:
    'DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_SUCCESS',
  DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_ERROR:
    'DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_ERROR',

  getElectivePositions: (params) => ({
    type: electivePositionActions.GET_ELECTIVE_POSITION_REQUEST,
    params,
  }),

  getSingleElectivePosition: (id) => ({
    type: electivePositionActions.GET_SINGLE_ELECTIVE_POSITION_REQUEST,
    id,
  }),

  getEligibleVoters: (positionId, programmeId) => ({
    type: electivePositionActions.GET_ELIGIBLE_VOTERS_REQUEST,
    positionId,
    programmeId,
  }),

  getVerifiedVoters: (positionId, programmeId) => ({
    type: electivePositionActions.GET_VERIFIED_VOTER_REGISTRY_REQUEST,
    positionId,
    programmeId,
  }),

  markAsVerified: (positionId, programmeId, studentIDs) => ({
    type: electivePositionActions.MARK_STUDENT_VERIFIED_REQUEST,
    positionId,
    programmeId,
    studentIDs,
  }),

  addElectivePosition: (data) => ({
    type: electivePositionActions.ADD_ELECTIVE_POSITION_REQUEST,
    data,
  }),

  setCurrentTab: (data) => ({
    type: electivePositionActions.SET_CURRENT_ELECTIVE_POSITION_TAB,
    data,
  }),

  setSelectedRow: (data) => ({
    type: electivePositionActions.SET_SELECTED_ELECTIVE_POSITION_ROW,
    data,
  }),

  setSelectedProgramme: (data) => ({
    type: electivePositionActions.SET_SELECTED_ELECTIVE_POSITION_PROGRAMME,
    data,
  }),

  updateElectivePosition: (id, data) => ({
    type: electivePositionActions.UPDATE_ELECTIVE_POSITION_REQUEST,
    id,
    data,
  }),

  deleteElectivePosition: (id) => ({
    type: electivePositionActions.DELETE_ELECTIVE_POSITION_REQUEST,
    id,
  }),

  uploadElectivePositions: (data) => ({
    type: electivePositionActions.UPLOAD_ELECTIVE_POSITIONS_TEMPLATE_REQUEST,
    data,
  }),

  downloadElectivePositionsTemplate: () => ({
    type: electivePositionActions.DOWNLOAD_ELECTIVE_POSITION_TEMPLATE_REQUEST,
  }),
};

export default electivePositionActions;
