import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { NTCSubjectActions } from '../../actions';

function* getNTCSubjects(actions) {
  try {
    const response = yield axios({
      url: '/ntc-mgt/ntc-subjects',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: NTCSubjectActions.GET_NTC_SUBJECTS_SUCCESS,
      data: response.subjects,
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.GET_NTC_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* updateNTCSubject(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/ntc-subjects/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: NTCSubjectActions.UPDATE_NTC_SUBJECT_SUCCESS,
      data: response,
    });
    yield put({
      type: NTCSubjectActions.GET_NTC_SUBJECTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.UPDATE_NTC_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* getSingleNTCSubject(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/ntc-subjects/${actions.NTCSubjectId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: NTCSubjectActions.GET_SINGLE_NTC_SUBJECT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.GET_SINGLE_NTC_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* createNTCSubject(actions) {
  try {
    const response = yield axios({
      url: '/ntc-mgt/ntc-subjects',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: NTCSubjectActions.CREATE_NTC_SUBJECT_SUCCESS,
      data: response,
    });
    yield put({
      type: NTCSubjectActions.GET_NTC_SUBJECTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.CREATE_NTC_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* deleteNTCSubject(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/ntc-subjects/${actions.id}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: NTCSubjectActions.DELETE_NTC_SUBJECT_SUCCESS,
      data: response,
    });
    yield put({
      type: NTCSubjectActions.GET_NTC_SUBJECTS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.DELETE_NTC_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* downloadNTCSubjectsTemplate() {
  try {
    yield axios({
      url: '/ntc-mgt/ntc-subjects/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-NTC-SUBJECTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: NTCSubjectActions.DOWNLOAD_NTC_SUBJECTS_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.DOWNLOAD_NTC_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* uploadNTCSubjectsTemplate(actions) {
  try {
    const response = yield axios({
      url: '/ntc-mgt/ntc-subjects/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: NTCSubjectActions.UPLOAD_NTC_SUBJECTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: NTCSubjectActions.GET_NTC_SUBJECTS_REQUEST,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: NTCSubjectActions.UPLOAD_NTC_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetNTCSubjects() {
  yield takeLatest(NTCSubjectActions.GET_NTC_SUBJECTS_REQUEST, getNTCSubjects);
}

function* watchCreateNTCSubject() {
  yield takeLatest(
    NTCSubjectActions.CREATE_NTC_SUBJECT_REQUEST,
    createNTCSubject
  );
}

function* watchUpdateNTCSubject() {
  yield takeLatest(
    NTCSubjectActions.UPDATE_NTC_SUBJECT_REQUEST,
    updateNTCSubject
  );
}

function* watchDeleteNTCSubject() {
  yield takeLatest(
    NTCSubjectActions.DELETE_NTC_SUBJECT_REQUEST,
    deleteNTCSubject
  );
}

function* watchGetSingleNTCSubject() {
  yield takeLatest(
    NTCSubjectActions.GET_SINGLE_NTC_SUBJECT_REQUEST,
    getSingleNTCSubject
  );
}

function* watchDownloadNTCSubjectsTemplate() {
  yield takeLatest(
    NTCSubjectActions.DOWNLOAD_NTC_SUBJECTS_REQUEST,
    downloadNTCSubjectsTemplate
  );
}

function* watchUploadNTCSubjectsTemplate() {
  yield takeLatest(
    NTCSubjectActions.UPLOAD_NTC_SUBJECTS_REQUEST,
    uploadNTCSubjectsTemplate
  );
}

export default [
  fork(watchGetNTCSubjects),
  fork(watchCreateNTCSubject),
  fork(watchUpdateNTCSubject),
  fork(watchGetSingleNTCSubject),
  fork(watchDeleteNTCSubject),
  fork(watchUploadNTCSubjectsTemplate),
  fork(watchDownloadNTCSubjectsTemplate),
];
