import { surchargePolicyActions } from '../../actions';
import initialState from '../../initialState';

const surchargePolicy = (state = initialState.surchargePolicy, actions) => {
  switch (actions.type) {
    case surchargePolicyActions.GET_SURCHARGE_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        surchargePolicies: [],
        loading: true,
        created: {},
      };

    case surchargePolicyActions.GET_SURCHARGE_POLICIES_SUCCESS:
      return {
        ...state,
        surchargePolicies: actions.data.data,
        loading: false,
        error: {},
      };

    case surchargePolicyActions.CREATE_SURCHARGE_POLICY_REQUEST:
      return {
        ...state,
        error: {},
        creating: true,
      };

    case surchargePolicyActions.CREATE_SURCHARGE_POLICY_SUCCESS:
      return {
        ...state,
        surchargePolicies: [...state.surchargePolicies],
        creating: false,
      };

    case surchargePolicyActions.CREATE_SURCHARGE_POLICY_ERROR:
      return {
        ...state,
        surchargePolicies: [],
        creating: false,
        error: actions.error,
      };

    case surchargePolicyActions.UPDATE_SURCHARGE_POLICY_REQUEST:
      return {
        ...state,
        error: {},
        updating: true,
      };

    case surchargePolicyActions.UPDATE_SURCHARGE_POLICY_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case surchargePolicyActions.UPDATE_SURCHARGE_POLICY_ERROR:
      return {
        ...state,
        updating: false,
        error: actions.error,
      };

    case surchargePolicyActions.DELETE_SURCHARGE_POLICY_REQUEST:
      return {
        ...state,
        error: {},
        deleting: true,
      };

    case surchargePolicyActions.DELETE_SURCHARGE_POLICY_SUCCESS:
      return {
        ...state,
        surchargePolicies: state.surchargePolicies.filter(
          (item) => item.id !== actions.policyID
        ),
        deleting: false,
      };

    case surchargePolicyActions.DELETE_SURCHARGE_POLICY_ERROR:
      return {
        ...state,
        error: actions.error,
        deleting: false,
      };

    case surchargePolicyActions.SET_SURCHARGE_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.surchargePolicyToEdit,
      };

    default:
      return state;
  }
};

export default surchargePolicy;
