import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { academicDocumentActions, graduationListActions } from '../../actions';

function* getStudentTestimonial(actions) {
  const { params } = actions;

  try {
    const response = yield axios({
      url: '/results-mgt/nodes/testimonial-results',
      method: 'GET',
      params,
    });
    yield put({
      type: academicDocumentActions.GET_STUDENT_TESTIMONIAL_SUCCESS,
      data: response.data,
      studentNumber: params.student,
    });
    yield put({
      type: academicDocumentActions.SET_TESTIMONIAL_STUDENT,
      data: params.student,
    });
  } catch (error) {
    yield put({
      type: academicDocumentActions.GET_STUDENT_TESTIMONIAL_ERROR,
      error: error.data,
    });
  }
}

function* generateTranscript(actions) {
  const { student } = actions;

  try {
    const response = yield axios({
      url: '/results-mgt/nodes/generate-transcript',
      method: 'POST',
      data: { student },
    });

    yield put({
      type: academicDocumentActions.GET_STUDENT_TESTIMONIAL_REQUEST,
      params: { student },
    });

    yield put({
      type: academicDocumentActions.GENERATE_TRANSCRIPT_SUCCESS,
      data: response.data,
      studentNumber: student,
    });
  } catch (error) {
    yield put({
      type: academicDocumentActions.GENERATE_TRANSCRIPT_ERROR,
      error: error.data,
    });
  }
}

function* getGraduationProgrammes(actions) {
  const { academicYear } = actions;

  try {
    const response = yield axios({
      url: `/results-mgt/graduation/programmes/${academicYear}`,
      method: 'GET',
    });
    yield put({
      type: academicDocumentActions.GET_GRADUATION_PROGRAMMES_SUCCESS,
      data: response.data,
      academicYear,
    });
  } catch (error) {
    yield put({
      type: academicDocumentActions.GET_GRADUATION_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* printAcademicDocument(actions) {
  const { category, data, context } = actions;
  try {
    const response = yield axios({
      url: `/academic-documents/print/${category}`,
      method: 'POST',
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
      data,
    });
    yield put({
      type: academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.SHOW_PRINT_MODAL,
      e: false,
    });
  } catch (error) {
    yield put({
      type: academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_ERROR,
      error: error.data,
    });
  } finally {
    yield put({
      type: graduationListActions.GET_GRADUATED_STUDENTS_REQUEST,
      params: context,
    });
  }
}

function* downloadAcademicDocuments(actions) {
  const { category, data, context } = actions;
  try {
    const response = yield axios({
      url: `/academic-documents/download/${category}`,
      method: 'POST',
      data,
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
      responseType: 'blob',
    });

    const openInNewTab = (href) => {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href,
      }).click();
    };

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: 'application/pdf' });
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    // Open the URL on new Window
    openInNewTab(fileURL);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(file);
    }, 100);
    // window.open(fileURL, '_blank').focus();

    yield put({
      type: academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_SUCCESS,
      data: { message: 'Downloaded Successfully ' },
    });
    yield put({
      type: graduationListActions.SHOW_PRINT_MODAL,
      e: false,
    });
    yield put({
      type: graduationListActions.GET_GRADUATED_STUDENTS_REQUEST,
      params: context,
    });
  } catch (error) {
    yield put({
      type: academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* printSingleAcademicDocument(actions) {
  const { category, data } = actions;
  try {
    const response = yield axios({
      url: `/academic-documents/print/${category}`,
      method: 'POST',
      data,
    });
    yield put({
      type: academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.SHOW_PRINT_MODAL,
      e: false,
    });
  } catch (error) {
    yield put({
      type: academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_ERROR,
      error: error.data,
    });
  } finally {
    yield put({
      type: academicDocumentActions.GET_STUDENT_TESTIMONIAL_REQUEST,
      params: { student: data.studentNumbers[0] },
    });
  }
}

function* watchGetStudentTestimonial() {
  yield takeLatest(
    academicDocumentActions.GET_STUDENT_TESTIMONIAL_REQUEST,
    getStudentTestimonial
  );
}

function* watchGenerateTranscript() {
  yield takeLatest(
    academicDocumentActions.GENERATE_TRANSCRIPT_REQUEST,
    generateTranscript
  );
}

function* watchGetGraduationProgrammes() {
  yield takeLatest(
    academicDocumentActions.GET_GRADUATION_PROGRAMMES_REQUEST,
    getGraduationProgrammes
  );
}

function* watchPrintAcademicDocument() {
  yield takeLatest(
    academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_REQUEST,
    printAcademicDocument
  );
}

function* watchDownloadAcademicDocuments() {
  yield takeLatest(
    academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_REQUEST,
    downloadAcademicDocuments
  );
}

function* watchPrintSingleAcademicDocument() {
  yield takeLatest(
    academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_REQUEST,
    printSingleAcademicDocument
  );
}

export default [
  fork(watchGetStudentTestimonial),
  fork(watchGenerateTranscript),
  fork(watchPrintAcademicDocument),
  fork(watchGetGraduationProgrammes),
  fork(watchPrintSingleAcademicDocument),
  fork(watchDownloadAcademicDocuments),
];
