import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { resultLogActions } from '../../actions';

function* getUserResultLogs() {
  try {
    const response = yield axios({
      url: '/results-mgt/results/user-logs',
      method: 'GET',
    });
    yield put({
      type: resultLogActions.GET_USER_RESULT_LOGS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultLogActions.GET_USER_RESULT_LOGS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUserResultLogs() {
  yield takeLatest(
    resultLogActions.GET_USER_RESULT_LOGS_REQUEST,
    getUserResultLogs
  );
}

export default [fork(watchGetUserResultLogs)];
