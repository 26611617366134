import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { surchargePolicyActions, tabActions } from '../../actions';

function* getSurchargeFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/surcharge-policies',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: surchargePolicyActions.GET_SURCHARGE_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: surchargePolicyActions.GET_SURCHARGE_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createSurchargeFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/surcharge-policies',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: surchargePolicyActions.CREATE_SURCHARGE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: surchargePolicyActions.GET_SURCHARGE_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: surchargePolicyActions.CREATE_SURCHARGE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteSurchargeFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/surcharge-policies/${actions.policyID}`,
      method: 'DELETE',
    });
    yield put({
      type: surchargePolicyActions.DELETE_SURCHARGE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: surchargePolicyActions.GET_SURCHARGE_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: surchargePolicyActions.DELETE_SURCHARGE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateSurchargeFeesPolicies(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/surcharge-policies/${actions.policyID}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: surchargePolicyActions.UPDATE_SURCHARGE_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: surchargePolicyActions.GET_SURCHARGE_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: surchargePolicyActions.UPDATE_SURCHARGE_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSurchargeFeesPolicies() {
  yield takeLatest(
    surchargePolicyActions.GET_SURCHARGE_POLICIES_REQUEST,
    getSurchargeFeesPolicies
  );
}

function* watchCreateOtherFeesPolicies() {
  yield takeLatest(
    surchargePolicyActions.CREATE_SURCHARGE_POLICY_REQUEST,
    createSurchargeFeesPolicies
  );
}

function* watchDeleteOtherFeesPolicies() {
  yield takeLatest(
    surchargePolicyActions.DELETE_SURCHARGE_POLICY_REQUEST,
    deleteSurchargeFeesPolicies
  );
}

function* watchUpdateOtherFeesPolicies() {
  yield takeLatest(
    surchargePolicyActions.UPDATE_SURCHARGE_POLICY_REQUEST,
    updateSurchargeFeesPolicies
  );
}

export default [
  fork(watchGetSurchargeFeesPolicies),
  fork(watchCreateOtherFeesPolicies),
  fork(watchDeleteOtherFeesPolicies),
  fork(watchUpdateOtherFeesPolicies),
];
