import update from 'immutability-helper';
import { studentInvoiceActions } from '../../actions';
import initialState from '../../initialState';

const studentInvoice = (state = initialState.studentInvoice, actions) => {
  switch (actions.type) {
    case studentInvoiceActions.GET_STUDENT_INVOICES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case studentInvoiceActions.GET_STUDENT_INVOICES_SUCCESS: {
      const { allStudentInvoices } = state;

      const findStudentIndex = allStudentInvoices.findIndex(
        (invoice) => invoice.studentId === actions.studentId
      );

      let newState = state;

      const contextData = {
        studentId: actions.studentId,
        invoices: actions.data,
        updated: false,
      };

      if (findStudentIndex === -1) {
        newState = update(newState, {
          allStudentInvoices: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          allStudentInvoices: { [findStudentIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }
    case studentInvoiceActions.GET_STUDENT_INVOICES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case studentInvoiceActions.GET_STUDENT_GRADUATION_INVOICES_REQUEST:
      return {
        ...state,
        gettingGraduationInvoices: true,
        graduationInvoiceError: {},
      };
    case studentInvoiceActions.GET_STUDENT_GRADUATION_INVOICES_SUCCESS:
      return {
        ...state,
        gettingGraduationInvoices: false,
        studentGraduationInvoices: actions.data,
      };

    case studentInvoiceActions.GET_STUDENT_GRADUATION_INVOICES_ERROR:
      return {
        ...state,
        graduationInvoiceError: actions.error,
        gettingGraduationInvoices: false,
      };

    case studentInvoiceActions.GET_STUDENT_UNPAID_INVOICES_REQUEST:
      return {
        ...state,
        unpaidInvoiceError: {},
        gettingUnpaidInvoices: true,
      };
    case studentInvoiceActions.GET_STUDENT_UNPAID_INVOICES_SUCCESS: {
      let { allStudentUnpaidInvoices } = state;
      const findStudentIndex = allStudentUnpaidInvoices.findIndex(
        (invoice) => invoice.studentId === actions.studentId
      );

      if (findStudentIndex !== -1) {
        allStudentUnpaidInvoices[findStudentIndex] = {
          studentId: actions.studentId,
          invoices: actions.data,
          updated: true,
        };
      } else {
        allStudentUnpaidInvoices = [
          ...allStudentUnpaidInvoices,
          {
            studentId: actions.studentId,
            invoices: actions.data,
            updated: false,
          },
        ];
      }
      return {
        ...state,
        allStudentUnpaidInvoices,
        gettingUnpaidInvoices: false,
      };
    }
    case studentInvoiceActions.GET_STUDENT_UNPAID_INVOICES_ERROR:
      return {
        ...state,
        unpaidInvoiceError: actions.error,
        gettingUnpaidInvoices: false,
      };

    case studentInvoiceActions.GET_ALL_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        allFeesElementError: {},
        gettingAllFeesElements: true,
      };
    case studentInvoiceActions.GET_ALL_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        allFeesElements: actions.data,
        gettingAllFeesElements: false,
      };
    case studentInvoiceActions.GET_ALL_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        allFeesElementError: actions.error,
        gettingAllFeesElements: false,
      };

    case studentInvoiceActions.CREATE_MANUAL_INVOICE_REQUEST:
      return {
        ...state,
        createManualInvoiceError: {},
        creatingManualInvoice: true,
      };
    case studentInvoiceActions.CREATE_MANUAL_INVOICE_SUCCESS:
      return {
        ...state,
        createdManualInvoice: actions.data,
        creatingManualInvoice: false,
      };
    case studentInvoiceActions.CREATE_MANUAL_INVOICE_ERROR:
      return {
        ...state,
        createManualInvoiceError: actions.error,
        creatingManualInvoice: false,
      };

    case studentInvoiceActions.CREATE_BULK_MANUAL_INVOICE_REQUEST:
      return {
        ...state,
        createBulkManualInvoiceError: {},
        creatingBulkManualInvoice: true,
      };
    case studentInvoiceActions.CREATE_BULK_MANUAL_INVOICE_SUCCESS:
      return {
        ...state,
        createdBulkManualInvoice: actions.data,
        creatingBulkManualInvoice: false,
      };
    case studentInvoiceActions.CREATE_BULK_MANUAL_INVOICE_ERROR:
      return {
        ...state,
        createBulkManualInvoiceError: actions.error,
        creatingBulkManualInvoice: false,
      };

    case studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_REQUEST:
      return {
        ...state,
        referenceError: {},
        gettingReferences: true,
      };
    case studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_SUCCESS: {
      let { allStudentPaymentReferences } = state;
      const findStudentIndex = allStudentPaymentReferences.findIndex(
        (reference) => reference.studentId === actions.studentId
      );

      if (findStudentIndex !== -1) {
        allStudentPaymentReferences[findStudentIndex] = {
          studentId: actions.studentId,
          payment_references: actions.data,
          updated: true,
        };
      } else {
        allStudentPaymentReferences = [
          ...allStudentPaymentReferences,
          {
            studentId: actions.studentId,
            payment_references: actions.data,
            updated: false,
          },
        ];
      }
      return {
        ...state,
        allStudentPaymentReferences,
        gettingReferences: false,
      };
    }
    case studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_ERROR:
      return {
        ...state,
        referenceError: actions.error,
        gettingReferences: false,
      };

    case studentInvoiceActions.GENERATE_PAYMENT_REFERENCE_REQUEST:
      return {
        ...state,
        generateError: {},
        generating: true,
      };
    case studentInvoiceActions.GENERATE_PAYMENT_REFERENCE_SUCCESS:
      return {
        ...state,
        generateSuccess: actions.data,
        generating: false,
      };
    case studentInvoiceActions.GENERATE_PAYMENT_REFERENCE_ERROR:
      return {
        ...state,
        generateError: actions.error,
        generating: false,
      };

    case studentInvoiceActions.GET_INVOICE_ELEMENTS_REQUEST:
      return {
        ...state,
        gettingInvoiceDetails: true,
        invoiceDetails: [],
        invoiceDetailsError: {},
        invoicePay: {},
      };
    case studentInvoiceActions.GET_INVOICE_ELEMENTS_SUCCESS:
      return {
        ...state,
        invoiceDetails: actions.data.elements,
        invoicePay: actions.data.invoice,
        gettingInvoiceDetails: false,
      };
    case studentInvoiceActions.GET_INVOICE_ELEMENTS_ERROR:
      return {
        ...state,
        invoiceDetailsError: actions.error,
        gettingInvoiceDetails: false,
      };

    case studentInvoiceActions.OFFSET_INVOICE_REQUEST:
      return {
        ...state,
        offsetting: true,
        offsetError: {},
      };
    case studentInvoiceActions.OFFSET_INVOICE_SUCCESS:
      return {
        ...state,
        offsetSuccess: actions.data,
        offsetting: false,
      };
    case studentInvoiceActions.OFFSET_INVOICE_ERROR:
      return {
        ...state,
        offsetError: actions.error,
        offsetting: false,
      };

    case studentInvoiceActions.INVOICE_OPTION_ACTION_REQUEST:
      return {
        ...state,
        sendingAction: true,
        actionError: {},
      };
    case studentInvoiceActions.INVOICE_OPTION_ACTION_SUCCESS:
      return {
        ...state,
        actionSuccess: actions.data,
        sendingAction: false,
      };
    case studentInvoiceActions.INVOICE_OPTION_ACTION_ERROR:
      return {
        ...state,
        actionError: actions.error,
        sendingAction: false,
      };

    case studentInvoiceActions.SET_GENERATED_REFERENCE:
      return {
        ...state,
        generatedReference: actions.data,
      };

    case studentInvoiceActions.SET_MY_UNPAID_INVOICES:
      return {
        ...state,
        myUnpaidInvoices: actions.data,
      };

    case studentInvoiceActions.UPLOAD_BULK_INVOICE_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadError: {},
      };
    case studentInvoiceActions.UPLOAD_BULK_INVOICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploadingTemplate: false,
      };
    case studentInvoiceActions.UPLOAD_BULK_INVOICE_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadError: actions.error,
      };

    case studentInvoiceActions.DOWNLOAD_BULK_INVOICE_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadError: {},
      };
    case studentInvoiceActions.DOWNLOAD_BULK_INVOICE_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadSuccess: actions.data,
        downloadingTemplate: false,
      };
    case studentInvoiceActions.DOWNLOAD_BULK_INVOICE_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadError: actions.error,
      };

    default:
      return state;
  }
};
export default studentInvoice;
