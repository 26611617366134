import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { isEmpty, map, partialRight, pick } from 'lodash';
import PropTypes, { array, object } from 'prop-types';
import React, { useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import SubmitButton from './SubmitButton';

function ExcelExport({
  csvData,
  fileName,
  buttonName,
  type,
  className,
  partialPickFields,
  ...props
}) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [exporting, setExporting] = useState(false);

  const exportToCSV = async () => {
    setExporting(true);
    let dataToPrint = csvData;
    if (!isEmpty(partialPickFields)) {
      dataToPrint = await map(
        dataToPrint,
        partialRight(pick, partialPickFields)
      );
    }
    const ws = XLSX.utils.json_to_sheet(dataToPrint);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setExporting(false);
  };

  return type === 'link' ? (
    <Dropdown.Item
      {...props}
      className={`text-sm w-100  ${className}`}
      onClick={() => exportToCSV(csvData, fileName, buttonName)}
      disabled={exporting}
      as="button"
    >
      {exporting ? (
        <Spinner size="sm" animation="border" />
      ) : (
        <DownloadOutlined />
      )}
      {buttonName ? <>{buttonName}</> : 'Export'}
    </Dropdown.Item>
  ) : (
    <SubmitButton
      {...props}
      className={`text-sm ${className}`}
      onClick={() => exportToCSV(csvData, fileName, buttonName)}
      disabled={exporting}
      text={buttonName ? <>{buttonName}</> : 'Export to Excel'}
      htmlType="button"
      iconBefore={
        exporting ? (
          <Spinner size="sm" animation="border" />
        ) : (
          <DownloadOutlined />
        )
      }
    />
  );
}

ExcelExport.defaultProps = {
  type: null,
  buttonName: null,
  className: '',
  partialPickFields: [],
};

ExcelExport.propTypes = {
  csvData: PropTypes.oneOfType([array, object]).isRequired,
  partialPickFields: PropTypes.oneOfType([array, object]),
  fileName: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default ExcelExport;
