import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { paymentReportsActions } from '../../actions';
import initialState from '../../initialState';

const paymentsReports = (state = initialState.paymentsReports, actions) => {
  switch (actions.type) {
    case paymentReportsActions.GET_PAYMENTS_REPORTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case paymentReportsActions.GET_PAYMENTS_REPORTS_SUCCESS: {
      const { reportsContext, data } = actions;

      let newState = state;
      const reportContextData = {
        ...reportsContext,
        data,
      };

      const reportIndex = findIndex(
        state?.paymentsReports,
        (report) =>
          report.academic_unit_id === reportsContext.academic_unit_id &&
          report.academic_year_id === reportsContext.academic_year_id &&
          report.campus_id === reportsContext.campus_id &&
          report.intake_id === reportsContext.intake_id &&
          report.programme_type_id === reportsContext.programme_type_id &&
          report.semester_id === reportsContext.semester_id
      );

      if (reportIndex === -1) {
        newState = update(state, {
          paymentsReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          paymentsReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }

    case paymentReportsActions.GET_PAYMENTS_REPORTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case paymentReportsActions.GET_ACADEMIC_UNIT_REPORTS_REQUEST:
      return {
        ...state,
        academicUnitReportError: {},
        gettingAcademicUnitReports: true,
      };
    case paymentReportsActions.GET_ACADEMIC_UNIT_REPORTS_SUCCESS: {
      const { reportsContext, data } = actions;

      let newState = state;
      const reportContextData = {
        ...reportsContext,
        data,
      };

      const reportIndex = findIndex(
        state.academicUnitReports,
        (report) =>
          report.academic_year_id === reportsContext.academic_year_id &&
          report.campus_id === reportsContext.campus_id &&
          report.intake_id === reportsContext.intake_id &&
          report.semester_id === reportsContext.semester_id
      );

      if (reportIndex === -1) {
        newState = update(state, {
          academicUnitReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          academicUnitReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        gettingAcademicUnitReports: false,
      };
    }

    case paymentReportsActions.GET_ACADEMIC_UNIT_REPORTS_ERROR:
      return {
        ...state,
        gettingAcademicUnitReports: false,
        academicUnitReportError: actions.error,
      };

    case paymentReportsActions.GET_CAMPUS_REPORT_REQUEST:
      return {
        ...state,
        gettingCampusReports: true,
        campusReportError: {},
      };
    case paymentReportsActions.GET_CAMPUS_REPORT_SUCCESS: {
      const { reportsContext, data } = actions;

      let newState = state;
      const reportContextData = {
        ...reportsContext,
        data,
      };

      const reportIndex = findIndex(
        state.campusReports,
        (report) =>
          report.academic_year_id === reportsContext.academic_year_id &&
          report.intake_id === reportsContext.intake_id &&
          report.semester_id === reportsContext.semester_id
      );

      if (reportIndex === -1) {
        newState = update(state, {
          campusReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          campusReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        gettingCampusReports: false,
      };
    }

    case paymentReportsActions.GET_CAMPUS_REPORT_ERROR:
      return {
        ...state,
        gettingCampusReports: false,
        campusReportError: actions.error,
      };
    case paymentReportsActions.GET_FEES_DEPOSIT_REPORT_REQUEST:
      return {
        ...state,
        gettingFeesDepositReports: true,
        feesDepositReportError: {},
      };
    case paymentReportsActions.GET_FEES_DEPOSIT_REPORT_SUCCESS: {
      const { reportsContext, data } = actions;

      let newState = state;
      const reportContextData = {
        ...reportsContext,
        data,
      };

      const reportIndex = findIndex(
        state.feesDepositReports,
        (report) =>
          report.campus_id === reportsContext.campus_id &&
          report.intake_id === reportsContext.intake_id &&
          report.from_date === reportsContext.from_date &&
          report.to_date === reportsContext.to_date
      );

      if (reportIndex === -1) {
        newState = update(state, {
          feesDepositReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          feesDepositReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        gettingFeesDepositReports: false,
      };
    }

    case paymentReportsActions.GET_FEES_DEPOSIT_REPORT_ERROR:
      return {
        ...state,
        gettingFeesDepositReports: false,
        feesDepositReportError: actions.error,
      };

    case paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_REQUEST:
      return {
        ...state,
        gettingFinancialYearReports: true,
        financialYearReportError: {},
        financialYearContext: actions.data,
      };
    case paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_SUCCESS: {
      const { reportsContext, reportCategory, data } = actions;

      let newState = state;
      const reportContextData = {
        ...reportsContext,
        reportCategory,
        data,
      };

      const { financialYearReports } = state;

      const reportIndex = findIndex(
        financialYearReports,
        (report) =>
          report.payments_from === reportsContext.payments_from &&
          report.payments_to === reportsContext.payments_to &&
          report.reportCategory === reportCategory
      );

      if (reportIndex === -1) {
        newState = update(state, {
          financialYearReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          financialYearReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        downloadableFiles: actions.data?.downloadableFiles || [],
        gettingFinancialYearReports: false,
      };
    }

    case paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_ERROR:
      return {
        ...state,
        gettingFinancialYearReports: false,
        financialYearReportError: actions.error,
      };

    case paymentReportsActions.STREAM_FINANCIAL_YEAR_REPORT_REQUEST:
      return {
        ...state,
        streamingReports: true,
        streamError: {},
        streamContext: actions.data,
      };
    case paymentReportsActions.STREAM_FINANCIAL_YEAR_REPORT_SUCCESS: {
      const { context, data } = actions;

      let newState = state;
      const reportContextData = {
        ...context,
        data,
      };

      const { streamedReports } = state;

      const reportIndex = findIndex(
        streamedReports,
        (report) =>
          report.payments_from === context.payments_from &&
          report.payments_to === context.payments_to
      );

      if (reportIndex === -1) {
        newState = update(state, {
          streamedReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          streamedReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        streamingReports: false,
      };
    }

    case paymentReportsActions.STREAM_FINANCIAL__YEAR_REPORT_ERROR:
      return {
        ...state,
        streamingReports: false,
        streamError: actions.error,
      };

    case paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_BY_PROG_REQUEST:
      return {
        ...state,
        gettingProgrammeFYReports: true,
        programmeFYrReportError: {},
        financialYearContext: actions.data,
      };
    case paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_BY_PROG_SUCCESS: {
      const { reportsContext, programmeId, data } = actions;

      let newState = state;
      const reportContextData = {
        ...reportsContext,
        programmeId,
        reportCategory: 'detailed',
        data,
      };

      const { programmeFYReports } = state;

      const reportIndex = findIndex(
        programmeFYReports,
        (report) =>
          report.payments_from === reportsContext.payments_from &&
          report.payments_to === reportsContext.payments_to &&
          report.reportCategory === 'detailed' &&
          report.programmeId === programmeId
      );

      if (reportIndex === -1) {
        newState = update(state, {
          programmeFYReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(state, {
          programmeFYReports: { $set: [reportContextData] },
        });
      }

      return {
        ...newState,
        gettingProgrammeFYReports: false,
      };
    }

    case paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_BY_PROG_ERROR:
      return {
        ...state,
        gettingProgrammeFYReports: false,
        programmeFYReportError: actions.error,
      };

    case paymentReportsActions.GET_DETAILED_PREPAYMENT_REPORT_REQUEST:
      return {
        ...state,
        gettingDetailedDepositReports: true,
        detailedDepositReportError: {},
      };
    case paymentReportsActions.GET_DETAILED_PREPAYMENT_REPORT_SUCCESS: {
      return {
        ...state,
        gettingDetailedDepositReports: false,
        detailedDepositReports: actions.data,
      };
    }

    case paymentReportsActions.GET_DETAILED_PREPAYMENT_REPORT_ERROR:
      return {
        ...state,
        gettingDetailedDepositReports: false,
        detailedDepositReportError: actions.error,
      };

    case paymentReportsActions.GET_DETAILED_PER_PROGRAMME_REPORT_REQUEST:
      return {
        ...state,
        gettingPerProgReports: true,
        perProgReportError: {},
      };

    case paymentReportsActions.GET_DETAILED_PER_PROGRAMME_REPORT_SUCCESS:
      return {
        ...state,
        gettingPerProgReports: false,
        perProgrammeReport: actions.data,
      };

    case paymentReportsActions.GET_DETAILED_PER_PROGRAMME_REPORT_ERROR:
      return {
        ...state,
        gettingPerProgReports: false,
        perProgReportError: actions.error,
      };

    case paymentReportsActions.GET_STUDENTS_PAYMENT_RECORDS_REQUEST:
      return {
        ...state,
        loadError: {},
        studentSPaymentsRecords: [],
        gettingStudents: true,
      };
    case paymentReportsActions.GET_STUDENTS_PAYMENT_RECORDS_SUCCESS:
      return {
        ...state,
        gettingStudents: false,
        studentSPaymentsRecords: actions.data,
      };
    case paymentReportsActions.GET_STUDENTS_PAYMENT_RECORDS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        gettingStudents: false,
      };

    case paymentReportsActions.DOWNLOAD_DETAILED_PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        downloadingDetailedReport: true,
        downloadDetailedReportError: {},
        downloadDetailedReportSuccess: {},
      };
    case paymentReportsActions.DOWNLOAD_DETAILED_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        downloadingDetailedReport: false,
        downloadDetailedReportSuccess: actions.data,
      };
    case paymentReportsActions.DOWNLOAD_DETAILED_PAYMENT_REPORT_ERROR:
      return {
        ...state,
        downloadDetailedReportError: actions.error,
        downloadingDetailedReport: false,
      };

    case paymentReportsActions.DOWNLOAD_FACULTY_PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        downloadingFacultyReport: true,
        downloadFacultyReportError: {},
        downloadFacultyReportSuccess: {},
      };
    case paymentReportsActions.DOWNLOAD_FACULTY_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        downloadingFacultyReport: false,
        downloadFacultyReportSuccess: actions.data,
      };
    case paymentReportsActions.DOWNLOAD_FACULTY_PAYMENT_REPORT_ERROR:
      return {
        ...state,
        downloadFacultyReportError: actions.error,
        downloadingFacultyReport: false,
      };

    case paymentReportsActions.DOWNLOAD_PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        downloadingPaymentReports: true,
        downloadPaymentReportError: {},
        downloadPaymentReportSuccess: {},
      };
    case paymentReportsActions.DOWNLOAD_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        downloadingPaymentReports: false,
        downloadPaymentReportSuccess: actions.data,
      };
    case paymentReportsActions.DOWNLOAD_PAYMENT_REPORT_ERROR:
      return {
        ...state,
        downloadPaymentReportError: actions.error,
        downloadingPaymentReports: false,
      };

    case paymentReportsActions.DOWNLOAD_PREPAYMENT_REPORT_REQUEST:
      return {
        ...state,
        downloadingPrepaymentReport: true,
        downloadPrepaymentReportError: {},
        downloadPrepaymentReportSuccess: {},
      };
    case paymentReportsActions.DOWNLOAD_PREPAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        downloadingPrepaymentReport: false,
        downloadPrepaymentReportSuccess: actions.data,
      };
    case paymentReportsActions.DOWNLOAD_PREPAYMENT_REPORT_ERROR:
      return {
        ...state,
        downloadPrepaymentReportError: actions.error,
        downloadingPrepaymentReport: false,
      };

    case paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_REPORT_REQUEST:
      return {
        ...state,
        downloadingFinancialYearReport: true,
        downloadFinancialYearReportError: {},
      };
    case paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        downloadingFinancialYearReport: false,
      };
    case paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_ERROR:
      return {
        ...state,
        downloadFinancialYearReportError: actions.error,
        downloadingFinancialYearReport: false,
      };

    case paymentReportsActions.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        generatingSummaryReport: true,
        generateSummaryReportError: {},
      };
    case paymentReportsActions.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_SUCCESS:
      return {
        ...state,
        generatingSummaryReport: false,
      };
    case paymentReportsActions.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_ERROR:
      return {
        ...state,
        generateSummaryReportError: actions.error,
        generatingSummaryReport: false,
      };

    case paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_FILE_REQUEST:
      return {
        ...state,
        downloadingFinancialYearFile: true,
        downloadFinancialYearFileError: {},
        fileName: actions.fileName,
      };
    case paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_FILE_SUCCESS:
      return {
        ...state,
        downloadingFinancialYearFile: false,
      };
    case paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_FILE_ERROR:
      return {
        ...state,
        downloadFinancialYearFileError: actions.error,
        downloadingFinancialYearFile: false,
      };

    case paymentReportsActions.DELETE_FINANCIAL_YEAR_FILE_REQUEST:
      return {
        ...state,
        deletingFinancialYearFile: true,
        deleteFinancialYearFileError: {},
        deleteFileName: actions.fileName,
      };
    case paymentReportsActions.DELETE_FINANCIAL_YEAR_FILE_SUCCESS:
      return {
        ...state,
        deletingFinancialYearFile: false,
      };
    case paymentReportsActions.DELETE_FINANCIAL_YEAR_FILE_ERROR:
      return {
        ...state,
        deleteFinancialYearFileError: actions.error,
        deletingFinancialYearFile: false,
      };

    case paymentReportsActions.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_REQUEST:
      return {
        ...state,
        downloadingPerProgrammeReport: true,
        downloadPerProgrammeReportError: {},
      };
    case paymentReportsActions.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_SUCCESS:
      return {
        ...state,
        downloadingPerProgrammeReport: false,
      };
    case paymentReportsActions.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_ERROR:
      return {
        ...state,
        downloadPerProgrammeReportError: actions.error,
        downloadingPerProgrammeReport: false,
      };

    case paymentReportsActions.DOWNLOAD_BILLING_PER_CAMPUS_REPORT_REQUEST:
      return {
        ...state,
        downloadingPerProgrammeReport: true,
        downloadPerProgrammeReportError: {},
      };
    case paymentReportsActions.DOWNLOAD_BILLING_PER_CAMPUS_REPORT_SUCCESS:
      return {
        ...state,
        downloadingPerProgrammeReport: false,
      };
    case paymentReportsActions.DOWNLOAD_BILLING_PER_CAMPUS_REPORT_ERROR:
      return {
        ...state,
        downloadPerProgrammeReportError: actions.error,
        downloadingPerProgrammeReport: false,
      };

    case paymentReportsActions.SET_ACADEMIC_UNIT_REPORT_CONTEXT:
      return {
        ...state,
        academicUnitReportContext: actions.data,
      };

    case paymentReportsActions.SET_CAMPUS_REPORT_CONTEXT:
      return {
        ...state,
        campusReportContext: actions.data,
      };

    case paymentReportsActions.SET_FEES_DEPOSIT_REPORT_CONTEXT:
      return {
        ...state,
        feesDepositReportContext: actions.data,
      };

    default:
      return state;
  }
};
export default paymentsReports;
