import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { pujabInstitutionActions } from '../../actions';

function* getInstitutions() {
  try {
    const response = yield axios({
      url: '/pujab/institutions',
      method: 'GET',
    });
    yield put({
      type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_ERROR,
      error: error.data,
    });
  }
}

function* getSingleInstitution(actions) {
  try {
    const response = yield axios({
      url: `/pujab/institutions/${actions.id}`,
      method: 'GET',
    });
    yield put({
      type: pujabInstitutionActions.GET_SINGLE_INSTITUTION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.GET_SINGLE_INSTITUTION_ERROR,
      error: error.data,
    });
  }
}

function* addInstitution(actions) {
  try {
    const response = yield axios({
      url: '/pujab/institutions',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_ERROR,
      error: error.data,
    });
  }
}

function* updateInstitution(actions) {
  try {
    const response = yield axios({
      url: `/pujab/institutions/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_ERROR,
      error: error.data,
    });
  }
}

function* deleteInstitution(actions) {
  try {
    const response = yield axios({
      url: `/pujab/institutions/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_ERROR,
      error: error.data,
    });
  }
}

function* uploadInstitutions(actions) {
  try {
    const response = yield axios({
      url: '/pujab/institutions/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_SUCCESS,
      data: response,
    });
    yield put({
      type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadInstitutionTemplate() {
  try {
    yield axios({
      url: '/pujab/institutions/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PUJAB-INSTITUTION-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetInstitutions() {
  yield takeLatest(
    pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST,
    getInstitutions
  );
}

function* watchGetSingleInstitution() {
  yield takeLatest(
    pujabInstitutionActions.GET_SINGLE_INSTITUTION_REQUEST,
    getSingleInstitution
  );
}

function* watchAddInstitution() {
  yield takeLatest(
    pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_REQUEST,
    addInstitution
  );
}

function* watchUpdateInstitution() {
  yield takeLatest(
    pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_REQUEST,
    updateInstitution
  );
}

function* watchDeleteInstitution() {
  yield takeLatest(
    pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_REQUEST,
    deleteInstitution
  );
}

function* watchUploadInstitutions() {
  yield takeLatest(
    pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_REQUEST,
    uploadInstitutions
  );
}

function* watchDownloadInstitutionTemplate() {
  yield takeLatest(
    pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_REQUEST,
    downloadInstitutionTemplate
  );
}

export default [
  fork(watchGetInstitutions),
  fork(watchGetSingleInstitution),
  fork(watchAddInstitution),
  fork(watchUpdateInstitution),
  fork(watchDeleteInstitution),
  fork(watchUploadInstitutions),
  fork(watchDownloadInstitutionTemplate),
];
