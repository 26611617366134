import update from 'immutability-helper';
import { semesterCourseLoadActions } from '../../actions';
import initialState from '../../initialState';

function semesterCourseLoad(state = initialState.semesterCourseLoad, actions) {
  switch (actions.type) {
    case semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_REQUEST:
      return {
        ...state,
        loadError: {},
        context: actions.context,
        loading: true,
      };
    case semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_SUCCESS: {
      const { semesterCourseLoads } = state;
      const { context, data } = actions;

      const findContextIndex = semesterCourseLoads.findIndex(
        (currentContext) =>
          context.programme_id === currentContext.programme_id &&
          context.academic_year_id === currentContext.academic_year_id &&
          context.programme_study_year_id ===
            currentContext.programme_study_year_id &&
          context.semester_id === currentContext.semester_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          semesterCourseLoads: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          semesterCourseLoads: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }

    case semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case semesterCourseLoadActions.CREATE_SEMESTER_COURSE_LOAD_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
      };
    case semesterCourseLoadActions.CREATE_SEMESTER_COURSE_LOAD_SUCCESS:
      return {
        ...state,
        creating: false,
        createdData: actions.data,
      };
    case semesterCourseLoadActions.CREATE_SEMESTER_COURSE_LOAD_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case semesterCourseLoadActions.DELETE_SEMESTER_COURSE_LOAD_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };
    case semesterCourseLoadActions.DELETE_SEMESTER_COURSE_LOAD_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case semesterCourseLoadActions.DELETE_SEMESTER_COURSE_LOAD_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case semesterCourseLoadActions.UPDATE_SEMESTER_COURSE_LOAD_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };
    case semesterCourseLoadActions.UPDATE_SEMESTER_COURSE_LOAD_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };
    case semesterCourseLoadActions.UPDATE_SEMESTER_COURSE_LOAD_ERROR:
      return {
        ...state,
        updating: false,
        semesterCourseLoadError: actions.error,
      };

    case semesterCourseLoadActions.SET_SEMESTER_COURSE_LOAD_TAB:
      return {
        ...state,
        currentTab: actions.tab,
      };

    case semesterCourseLoadActions.SHOW_EDIT_SEMESTER_COURSE_LOAD:
      return {
        ...state,
        showEditModal: actions.payload,
      };

    default:
      return state;
  }
}

export default semesterCourseLoad;
