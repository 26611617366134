const pujabSchoolAdminActions = {
  GET_PUJAB_SCHOOL_ADMINS_REQUEST: 'GET_PUJAB_SCHOOL_ADMINS_REQUEST',
  GET_PUJAB_SCHOOL_ADMINS_SUCCESS: 'GET_PUJAB_SCHOOL_ADMINS_SUCCESS',
  GET_PUJAB_SCHOOL_ADMINS_ERROR: 'GET_PUJAB_SCHOOL_ADMINS_ERROR',

  CREATE_PUJAB_SCHOOL_ADMIN_REQUEST: 'CREATE_PUJAB_SCHOOL_ADMIN_REQUEST',
  CREATE_PUJAB_SCHOOL_ADMIN_SUCCESS: 'CREATE_PUJAB_SCHOOL_ADMIN_SUCCESS',
  CREATE_PUJAB_SCHOOL_ADMIN_ERROR: 'CREATE_PUJAB_SCHOOL_ADMIN_ERROR',

  UPDATE_PUJAB_SCHOOL_ADMIN_REQUEST: 'UPDATE_PUJAB_SCHOOL_ADMIN_REQUEST',
  UPDATE_PUJAB_SCHOOL_ADMIN_SUCCESS: 'UPDATE_PUJAB_SCHOOL_ADMIN_SUCCESS',
  UPDATE_PUJAB_SCHOOL_ADMIN_ERROR: 'UPDATE_PUJAB_SCHOOL_ADMIN_ERROR',

  getPujabSchoolAdmins: (context) => ({
    type: pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_REQUEST,
    context,
  }),

  createPujabSchoolAdmin: (data) => ({
    type: pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_REQUEST,
    data,
  }),

  updatePujabSchoolAdmin: (id, data, context) => ({
    type: pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_REQUEST,
    id,
    data,
    context,
  }),
};

export default pujabSchoolAdminActions;
