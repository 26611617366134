import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes, { any } from 'prop-types';

function DataSpinner({ text, children }) {
  return (
    <div className="p-4">
      <div className="text-center my-auto mx-auto text-warning">
        <Spinner
          variant="info"
          animation="border"
          size="lg"
          className="p-2 mb-2"
        />
        <div className="text-primary text-sm text-uppercase fw-bold">
          {text}
        </div>
        {children}
      </div>
    </div>
  );
}

DataSpinner.defaultProps = {
  text: 'Loading Data...',
  children: null,
};

DataSpinner.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([any]),
};

export default DataSpinner;
