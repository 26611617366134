import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { programmeSearchActions } from '../../actions';

function* searchProgrammeByCode(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programmes/search/${actions.data.search_code}`,
      method: 'GET',
    });
    yield put({
      type: programmeSearchActions.SEARCH_BY_PROG_CODE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeSearchActions.SEARCH_BY_PROG_CODE_ERROR,
      error: error.data,
    });
  }
}

function* searchCourseByCode(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/course-units/search/${actions.data.search_code}`,
      method: 'GET',
    });
    yield put({
      type: programmeSearchActions.SEARCH_BY_COURSE_CODE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: programmeSearchActions.SEARCH_BY_COURSE_CODE_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchProgrammeByCode() {
  yield takeLatest(
    programmeSearchActions.SEARCH_BY_PROG_CODE_REQUEST,
    searchProgrammeByCode
  );
}
function* watchSearchCourseByCode() {
  yield takeLatest(
    programmeSearchActions.SEARCH_BY_COURSE_CODE_REQUEST,
    searchCourseByCode
  );
}

export default [
  fork(watchSearchProgrammeByCode),
  fork(watchSearchCourseByCode),
];
