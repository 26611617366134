const feesStructure = {
  GET_FEES_STRUCTURE_REQUEST: 'GET_FEES_STRUCTURE_REQUEST',
  GET_FEES_STRUCTURE_SUCCESS: 'GET_FEES_STRUCTURE_SUCCESS',
  GET_FEES_STRUCTURE_ERROR: 'GET_FEES_STRUCTURE_ERROR',

  getFeesStructure: (studentProgrammeId) => ({
    type: feesStructure.GET_FEES_STRUCTURE_REQUEST,
    studentProgrammeId,
  }),
};

export default feesStructure;
