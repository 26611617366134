const otherFeesActions = {
  GET_OTHER_FEES_REQUEST: 'GET_OTHER_FEES_REQUEST',
  GET_OTHER_FEES_SUCCESS: 'GET_OTHER_FEES_SUCCESS',
  GET_OTHER_FEES_ERROR: 'GET_OTHER_FEES_ERROR',

  GET_OTHER_FEES_ELEMENTS_REQUEST: 'GET_OTHER_FEES_ELEMENTS_REQUEST',
  GET_OTHER_FEES_ELEMENTS_SUCCESS: 'GET_OTHER_FEES_ELEMENTS_SUCCESS',
  GET_OTHER_FEES_ELEMENTS_ERROR: 'GET_OTHER_FEES_ELEMENTS_ERROR',

  ADD_OTHER_FEES_ELEMENTS_REQUEST: 'ADD_OTHER_FEES_ELEMENTS_REQUEST',
  ADD_OTHER_FEES_ELEMENTS_SUCCESS: 'ADD_OTHER_FEES_ELEMENTS_SUCCESS',
  ADD_OTHER_FEES_ELEMENTS_ERROR: 'ADD_OTHER_FEES_ELEMENTS_ERROR',

  GET_OTHER_FEES_BY_CONTEXT_REQUEST: 'GET_OTHER_FEES_BY_CONTEXT_REQUEST',
  GET_OTHER_FEES_BY_CONTEXT_SUCCESS: 'GET_OTHER_FEES_BY_CONTEXT_SUCCESS',
  GET_OTHER_FEES_BY_CONTEXT_ERROR: 'GET_OTHER_FEES_BY_CONTEXT_ERROR',

  CREATE_OTHER_FEES_SUCCESS: 'CREATE_OTHER_FEES_SUCCESS',
  CREATE_OTHER_FEES_REQUEST: 'CREATE_OTHER_FEES_REQUEST',
  CREATE_OTHER_FEES_ERROR: 'CREATE_OTHER_FEES_ERROR',

  UPDATE_OTHER_FEES_SUCCESS: 'UPDATE_OTHER_FEES_SUCCESS',
  UPDATE_OTHER_FEES_REQUEST: 'UPDATE_OTHER_FEES_REQUEST',
  UPDATE_OTHER_FEES_ERROR: 'UPDATE_OTHER_FEES_ERROR',

  DELETE_OTHER_FEES_SUCCESS: 'DELETE_OTHER_FEES_SUCCESS',
  DELETE_OTHER_FEES_REQUEST: 'DELETE_OTHER_FEES_REQUEST',
  DELETE_OTHER_FEES_ERROR: 'DELETE_OTHER_FEES_ERROR',

  UPDATE_OTHER_FEES_AMOUNT_SUCCESS: 'UPDATE_OTHER_FEES_AMOUNT_SUCCESS',
  UPDATE_OTHER_FEES_AMOUNT_REQUEST: 'UPDATE_OTHER_FEES_AMOUNT_REQUEST',
  UPDATE_OTHER_FEES_AMOUNT_ERROR: 'UPDATE_OTHER_FEES_AMOUNT_ERROR',

  DELETE_OTHER_FEES_AMOUNT_SUCCESS: 'DELETE_OTHER_FEES_AMOUNT_SUCCESS',
  DELETE_OTHER_FEES_AMOUNT_REQUEST: 'DELETE_OTHER_FEES_AMOUNT_REQUEST',
  DELETE_OTHER_FEES_AMOUNT_ERROR: 'DELETE_OTHER_FEES_AMOUNT_ERROR',

  ADD_OTHER_FEES_TO_STUDENT_SUCCESS: 'ADD_OTHER_FEES_TO_STUDENT_SUCCESS',
  ADD_OTHER_FEES_TO_STUDENT_REQUEST: 'ADD_OTHER_FEES_TO_STUDENT_REQUEST',
  ADD_OTHER_FEES_TO_STUDENT_ERROR: 'ADD_OTHER_FEES_TO_STUDENT_ERROR',

  DOWNLOAD_OTHER_FEES_REQUEST: 'DOWNLOAD_OTHER_FEES_REQUEST',
  DOWNLOAD_OTHER_FEES_SUCCESS: 'DOWNLOAD_OTHER_FEES_SUCCESS',
  DOWNLOAD_OTHER_FEES_ERROR: 'DOWNLOAD_OTHER_FEES_ERROR',

  UPLOAD_OTHER_FEES_REQUEST: 'UPLOAD_OTHER_FEES_REQUEST',
  UPLOAD_OTHER_FEES_SUCCESS: 'UPLOAD_OTHER_FEES_SUCCESS',
  UPLOAD_OTHER_FEES_ERROR: 'UPLOAD_OTHER_FEES_ERROR',

  SET_LOADED_FEES: 'SET_LOADED_FEES',

  SET_OTHER_FEES_CONTEXT: 'SET_OTHER_FEES_CONTEXT',

  SET_PENDING_FEES_CONTEXT: 'SET_PENDING_FEES_CONTEXT',

  getOtherFees: (data) => ({
    type: otherFeesActions.GET_OTHER_FEES_REQUEST,
    data,
  }),

  addOtherFeesElements: (contextId, data, context) => ({
    type: otherFeesActions.ADD_OTHER_FEES_ELEMENTS_REQUEST,
    contextId,
    data,
    context,
  }),

  getOtherFeesElements: (data) => ({
    type: otherFeesActions.GET_OTHER_FEES_ELEMENTS_REQUEST,
    data,
  }),

  getOtherFeesByContext: (context) => ({
    type: otherFeesActions.GET_OTHER_FEES_BY_CONTEXT_REQUEST,
    context,
  }),

  setLoadedFees: (data) => ({
    type: otherFeesActions.SET_LOADED_FEES,
    data,
  }),

  setOtherFeesContext: (context) => ({
    type: otherFeesActions.SET_OTHER_FEES_CONTEXT,
    context,
  }),

  setPendingFeesContext: (context) => ({
    type: otherFeesActions.SET_PENDING_FEES_CONTEXT,
    context,
  }),

  createOtherFees: (data, context) => ({
    type: otherFeesActions.CREATE_OTHER_FEES_REQUEST,
    data,
    context,
  }),

  deleteOtherFeesItem: (id) => ({
    type: otherFeesActions.DELETE_OTHER_FEES_REQUEST,
    id,
  }),

  updateOtherFees: (data) => ({
    type: otherFeesActions.UPDATE_OTHER_FEES_REQUEST,
    data,
  }),

  deleteOtherFeesAmountItem: (id, context) => ({
    type: otherFeesActions.DELETE_OTHER_FEES_AMOUNT_REQUEST,
    id,
    context,
  }),

  updateOtherFeesAmount: (id, data, context) => ({
    type: otherFeesActions.UPDATE_OTHER_FEES_AMOUNT_REQUEST,
    id,
    data,
    context,
  }),

  addOtherFeesToStudent: (data) => ({
    type: otherFeesActions.ADD_OTHER_FEES_TO_STUDENT_REQUEST,
    data,
  }),

  downloadOtherFeesTemplate: () => ({
    type: otherFeesActions.DOWNLOAD_OTHER_FEES_REQUEST,
  }),

  uploadOtherFeesAmounts: (data, context) => ({
    type: otherFeesActions.UPLOAD_OTHER_FEES_REQUEST,
    data,
    context,
  }),
};

export default otherFeesActions;
