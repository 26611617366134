const pujabInstitutionActions = {
  GET_PUJAB_INSTITUTIONS_REQUEST: 'GET_PUJAB_INSTITUTIONS_REQUEST',
  GET_PUJAB_INSTITUTIONS_SUCCESS: 'GET_PUJAB_INSTITUTIONS_SUCCESS',
  GET_PUJAB_INSTITUTIONS_ERROR: 'GET_PUJAB_INSTITUTIONS_ERROR',

  GET_SINGLE_INSTITUTION_REQUEST: 'GET_SINGLE_INSTITUTION_REQUEST',
  GET_SINGLE_INSTITUTION_SUCCESS: 'GET_SINGLE_INSTITUTION_SUCCESS',
  GET_SINGLE_INSTITUTION_ERROR: 'GET_SINGLE_INSTITUTION_ERROR',

  ADD_PUJAB_INSTITUTIONS_REQUEST: 'ADD_PUJAB_INSTITUTIONS_REQUEST',
  ADD_PUJAB_INSTITUTIONS_SUCCESS: 'ADD_PUJAB_INSTITUTIONS_SUCCESS',
  ADD_PUJAB_INSTITUTIONS_ERROR: 'ADD_PUJAB_INSTITUTIONS_ERROR',

  UPDATE_PUJAB_INSTITUTIONS_REQUEST: 'UPDATE_PUJAB_INSTITUTIONS_REQUEST',
  UPDATE_PUJAB_INSTITUTIONS_SUCCESS: 'UPDATE_PUJAB_INSTITUTIONS_SUCCESS',
  UPDATE_PUJAB_INSTITUTIONS_ERROR: 'UPDATE_PUJAB_INSTITUTIONS_ERROR',

  DELETE_PUJAB_INSTITUTIONS_REQUEST: 'DELETE_PUJAB_INSTITUTIONS_REQUEST',
  DELETE_PUJAB_INSTITUTIONS_SUCCESS: 'DELETE_PUJAB_INSTITUTIONS_SUCCESS',
  DELETE_PUJAB_INSTITUTIONS_ERROR: 'DELETE_PUJAB_INSTITUTIONS_ERROR',

  UPLOAD_INSTITUTIONS_TEMPLATE_REQUEST: 'UPLOAD_INSTITUTIONS_TEMPLATE_REQUEST',
  UPLOAD_INSTITUTIONS_TEMPLATE_SUCCESS: 'UPLOAD_INSTITUTIONS_TEMPLATE_SUCCESS',
  UPLOAD_INSTITUTIONS_TEMPLATE_ERROR: 'UPLOAD_INSTITUTIONS_TEMPLATE_ERROR',

  DOWNLOAD_INSTITUTION_TEMPLATE_REQUEST:
    'DOWNLOAD_INSTITUTION_TEMPLATE_REQUEST',
  DOWNLOAD_INSTITUTION_TEMPLATE_SUCCESS:
    'DOWNLOAD_INSTITUTION_TEMPLATE_SUCCESS',
  DOWNLOAD_INSTITUTION_TEMPLATE_ERROR: 'DOWNLOAD_INSTITUTION_TEMPLATE_ERROR',

  getPujabInstitutions: (params) => ({
    type: pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST,
    params,
  }),

  getSingleInstitution: (id) => ({
    type: pujabInstitutionActions.GET_SINGLE_INSTITUTION_REQUEST,
    id,
  }),

  addInstitution: (data) => ({
    type: pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_REQUEST,
    data,
  }),

  updateInstitution: (id, data) => ({
    type: pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_REQUEST,
    id,
    data,
  }),

  deleteInstitution: (id) => ({
    type: pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_REQUEST,
    id,
  }),

  uploadInstitutions: (data) => ({
    type: pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_REQUEST,
    data,
  }),

  downloadInstitutionsTemplate: () => ({
    type: pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_REQUEST,
  }),
};

export default pujabInstitutionActions;
