const studentEnrollment = {
  ENROLL_STUDENT_REQUEST: 'ENROLL_STUDENT_REQUEST',
  ENROLL_STUDENT_SUCCESS: 'ENROLL_STUDENT_SUCCESS',
  ENROLL_STUDENT_ERROR: 'ENROLL_STUDENT_ERROR',

  RE_ENROLL_WITHDRAW_STUDENT_REQUEST: 'RE_ENROLL_WITHDRAW_STUDENT_REQUEST',
  RE_ENROLL_WITHDRAW_STUDENT_SUCCESS: 'RE_ENROLL_WITHDRAW_STUDENT_SUCCESS',
  RE_ENROLL_WITHDRAW_STUDENT_ERROR: 'RE_ENROLL_WITHDRAW_STUDENT_ERROR',

  GET_PENDING_REINSTATED_STUDENTS_REQUEST:
    'GET_PENDING_REINSTATED_STUDENTS_REQUEST',
  GET_PENDING_REINSTATED_STUDENTS_SUCCESS:
    'GET_PENDING_REINSTATED_STUDENTS_SUCCESS',
  GET_PENDING_REINSTATED_STUDENTS_ERROR:
    'GET_PENDING_REINSTATED_STUDENTS_ERROR',

  APPROVE_PENDING_REINSTATED_STUDENT_REQUEST:
    'APPROVE_PENDING_REINSTATED_STUDENT_REQUEST',
  APPROVE_PENDING_REINSTATED_STUDENT_SUCCESS:
    'APPROVE_PENDING_REINSTATED_STUDENT_SUCCESS',
  APPROVE_PENDING_REINSTATED_STUDENT_ERROR:
    'APPROVE_PENDING_REINSTATED_STUDENT_ERROR',

  DE_ENROLL_STUDENT_REQUEST: 'DE_ENROLL_STUDENT_REQUEST',
  DE_ENROLL_STUDENT_SUCCESS: 'DE_ENROLL_STUDENT_SUCCESS',
  DE_ENROLL_STUDENT_ERROR: 'DE_ENROLL_STUDENT_ERROR',

  ADD_LATE_ENROLLMENT_REQUEST: 'ADD_LATE_ENROLLMENT_REQUEST',
  ADD_LATE_ENROLLMENT_SUCCESS: 'ADD_LATE_ENROLLMENT_SUCCESS',
  ADD_LATE_ENROLLMENT_ERROR: 'ADD_LATE_ENROLLMENT_ERROR',

  GET_ENROLL_HISTORY_REQUEST: 'GET_ENROLL_HISTORY_REQUEST',
  GET_ENROLL_HISTORY_SUCCESS: 'GET_ENROLL_HISTORY_SUCCESS',
  GET_ENROLL_HISTORY_ERROR: 'GET_ENROLL_HISTORY_ERROR',

  enrollStudent: (data, studentId, studentProgrammeId, status) => ({
    type: studentEnrollment.ENROLL_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
    status,
  }),

  reEnrollWithdrawStudent: (studentId, data) => ({
    type: studentEnrollment.RE_ENROLL_WITHDRAW_STUDENT_REQUEST,
    studentId,
    data,
  }),

  getPendingReinstatedStudents: () => ({
    type: studentEnrollment.GET_PENDING_REINSTATED_STUDENTS_REQUEST,
  }),

  approvePendingReinstatedStudents: (data) => ({
    type: studentEnrollment.APPROVE_PENDING_REINSTATED_STUDENT_REQUEST,
    data,
  }),

  deEnrollStudent: (data, studentId, studentProgrammeId) => ({
    type: studentEnrollment.DE_ENROLL_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  studentLateEnrollment: (data, studentId, studentProgrammeId) => ({
    type: studentEnrollment.ADD_LATE_ENROLLMENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  getEnrollmentHistory: (studentId, studentProgrammeId) => ({
    type: studentEnrollment.GET_ENROLL_HISTORY_REQUEST,
    studentId,
    studentProgrammeId,
  }),
};
export default studentEnrollment;
