import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  programmeActions,
  programmeVersionActions,
  subjectActions,
} from '../../actions';

function* getSingleProgrammeVersion(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-versions/${actions.programmeVersionId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_SUCCESS,
      data: response.programmeVersion,
    });
  } catch (error) {
    yield put({
      type: programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_ERROR,
      error: error.data,
    });
  }
}

function* getVersionWithCourseUnits(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-versions/course-units/${actions.programmeVersionId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_ERROR,
      error: error.data,
    });
  }
}

function* getVersionOptions(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-versions/options/${actions.programmeVersionId}`,
      method: 'GET',
      params: { versionOption: actions.params },
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_OPTIONS_SUCCESS,
      data: response.data,
      programmeVersionId: actions.programmeVersionId,
      versionOption: actions.params,
    });
  } catch (error) {
    yield put({
      type: programmeVersionActions.GET_VERSION_OPTIONS_ERROR,
      error: error.data,
    });
  }
}

function* createProgrammeVersion(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/programme-versions',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: programmeVersionActions.CREATE_PROGRAMME_VERSION_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: response.programmeVersion.programme_id,
    });
    yield put({
      type: subjectActions.GET_SUBJECT_COMBINATIONS_REQUEST,
    });
    yield put({
      type: programmeActions.SET_CURRENT_PROGRAMME_DETAIL_TAB,
      currentTab: 'view-courses',
    });
  } catch (error) {
    yield put({
      type: programmeVersionActions.CREATE_PROGRAMME_VERSION_ERROR,
      error: error.data,
    });
  }
}

function* updateProgrammeVersion(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/programme-versions/${actions.programmeVersionId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: programmeVersionActions.UPDATE_PROGRAMME_VERSION_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeActions.GET_SINGLE_PROGRAMME_REQUEST,
      programmeId: actions.data?.programme_id,
    });
    yield put({
      type: programmeActions.SET_CURRENT_PROGRAMME_DETAIL_TAB,
      currentTab: 'view-version',
    });
  } catch (error) {
    yield put({
      type: programmeVersionActions.UPDATE_PROGRAMME_VERSION_ERROR,
      error: error.data,
    });
  }
}

function* deleteVersionCourseUnit(actions) {
  try {
    const { deleteModalAction, courseUnitId } = actions;

    let url = `/programme-mgt/programme-versions/delete-version-course-unit/${courseUnitId}`;

    if (deleteModalAction === 'activate')
      url = `/programme-mgt/programme-versions/activate-version-course-unit/${courseUnitId}`;

    const response = yield axios({
      url,
      method: deleteModalAction === 'activate' ? 'PUT' : 'DELETE',
    });
    yield put({
      type: programmeVersionActions.DELETE_VERSION_COURSE_UNIT_SUCCESS,
      data: response,
    });
    yield put({
      type: programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST,
      programmeVersionId: actions.versionId,
    });
    yield put({
      type: 'SHOW_DELETE_VERSION_CU_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: programmeVersionActions.DELETE_VERSION_COURSE_UNIT_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateProgrammeVersion() {
  yield takeLatest(
    programmeVersionActions.CREATE_PROGRAMME_VERSION_REQUEST,
    createProgrammeVersion
  );
}

function* watchUpdateProgrammeVersion() {
  yield takeLatest(
    programmeVersionActions.UPDATE_PROGRAMME_VERSION_REQUEST,
    updateProgrammeVersion
  );
}

function* watchGetSingleProgrammeVersion() {
  yield takeLatest(
    programmeVersionActions.GET_SINGLE_PROGRAMME_VERSION_REQUEST,
    getSingleProgrammeVersion
  );
}

function* watchGetVersionWithCourseUnits() {
  yield takeLatest(
    programmeVersionActions.GET_VERSION_COURSE_UNITS_REQUEST,
    getVersionWithCourseUnits
  );
}

function* watchGetVersionOptions() {
  yield takeLatest(
    programmeVersionActions.GET_VERSION_OPTIONS_REQUEST,
    getVersionOptions
  );
}

function* watchGetDeleteVersionCourseUnit() {
  yield takeLatest(
    programmeVersionActions.DELETE_VERSION_COURSE_UNIT_REQUEST,
    deleteVersionCourseUnit
  );
}

export default [
  fork(watchCreateProgrammeVersion),
  fork(watchUpdateProgrammeVersion),
  fork(watchGetSingleProgrammeVersion),
  fork(watchGetVersionWithCourseUnits),
  fork(watchGetVersionOptions),
  fork(watchGetDeleteVersionCourseUnit),
];
