import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { admissionApplicantActions } from '../../actions';

function* getAdmissionProgrammes(actions) {
  try {
    const { params } = actions;
    const response = yield axios({
      url: '/admissions/running-admission-programmes/programmes',
      method: 'GET',
      params,
    });

    yield put({
      type: admissionApplicantActions.GET_PROGRAMMES_BY_CONTEXT_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_PROGRAMMES_BY_CONTEXT_ERROR,
      error: error.data,
    });
  }
}

function* searchForApplicants(actions) {
  const {
    academic_year_id: acadYr,
    intake_id: intake,
    admission_scheme_id: scheme,
    search_value: value,
    parameter,
  } = actions.params;
  try {
    const response = yield axios({
      url: `/admissions/search-applicants?academic_year_id=${acadYr}&intake_id=${intake}&admission_scheme_id=${scheme}&${parameter}=${value}`,
      method: 'GET',
    });

    yield put({
      type: admissionApplicantActions.SEARCH_FOR_APPLICANTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.SEARCH_FOR_APPLICANTS_ERROR,
      error: error.data,
    });
  }
}

function* administrativelyAdmitApplicant(actions) {
  let url = 'administratively-admit';
  if (actions.context === 'Selected Applicants') url = 'admit-selected';
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/${url}`,
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: admissionApplicantActions.ADMINISTRATIVELY_ADMIT_APPLICANT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.ADMINISTRATIVELY_ADMIT_APPLICANT_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionApplicants(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/applicants/${actions.programmeCampusId}`,
      method: 'GET',
    });
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_APPLICANTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_APPLICANTS_ERROR,
      error: error.data,
    });
  }
}

function* getWeightedApplicants(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admitted/weighted-applicants`,
      method: 'GET',
      params: { programmeCampusId: actions.programmeCampusId },
    });
    yield put({
      type: admissionApplicantActions.GET_WEIGHTED_APPLICANTS_SUCCESS,
      data: response.data,
      programmeCampusId: actions.programmeCampusId,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_WEIGHTED_APPLICANTS_ERROR,
      error: error.data,
    });
  }
}

function* getSelectedApplicants(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admitted/selected-applicants`,
      method: 'GET',
      params: { programmeCampusId: actions.programmeCampusId },
    });
    yield put({
      type: admissionApplicantActions.GET_SELECTED_APPLICANTS_SUCCESS,
      data: response.data,
      programmeCampusId: actions.programmeCampusId,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_SELECTED_APPLICANTS_ERROR,
      error: error.data,
    });
  }
}

function* getNotSelectedApplicants(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admitted/not-selected-applicants`,
      method: 'GET',
      params: { programmeCampusId: actions.programmeCampusId },
    });
    yield put({
      type: admissionApplicantActions.GET_NOT_SELECTED_APPLICANTS_SUCCESS,
      data: response.data,
      programmeCampusId: actions.programmeCampusId,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_NOT_SELECTED_APPLICANTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadWeightedApplicants(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-weighted-applicants`,
      method: 'POST',
      params: { programmeCampusId: actions.programmeCampusId },
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-WEIGHTED-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_WEIGHTED_APPLICANTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_WEIGHTED_APPLICANTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadSelectedApplicants(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-selected-applicants`,
      method: 'POST',
      params: { programmeCampusId: actions.programmeCampusId },
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SELECTED-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_SELECTED_APPLICANTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_SELECTED_APPLICANTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadSchemeSelectedApplicants(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-all-selected-applicants`,
      method: 'POST',
      params: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-SELECTED-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_SCHEME_SELECTED_APPLICANTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_SCHEME_SELECTED_APPLICANTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadNotSelectedApplicants(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-not-selected-applicants`,
      method: 'POST',
      params: { programmeCampusId: actions.programmeCampusId },
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-NOT-SELECTED-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_NOT_SELECTED_APPLICANTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_NOT_SELECTED_APPLICANTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* generateNotSelectedApplicantsReason(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/generate-not-selected-applicant-report`,
      method: 'POST',
      params: { programmeCampusId: actions.programmeCampusId },
      timeout: 3600000,
    });
    yield put({
      type: admissionApplicantActions.GENERATE_NOT_SELECTED_APPLICANTS_REASON_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GENERATE_NOT_SELECTED_APPLICANTS_REASON_ERROR,
      error: error.data,
    });
  }
}

function* downloadAdmissionApplicants(actions) {
  try {
    const { programmeCampusId, context } = actions;
    yield axios({
      url: `/admissions/running-admission-applicant/download-applicants/${programmeCampusId}`,
      method: 'POST',
      data: context,
      timeout: 1200000,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ADMISSION-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_ADMISSION_APPLICANTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_ADMISSION_APPLICANTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadApplicantsSubjectCombinations(actions) {
  try {
    const { context } = actions;
    yield axios({
      url: `/admissions/admission-reports/applicant-subjects`,
      method: 'POST',
      data: context,
      timeout: 1200000,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ADMISSION-APPLICANTS-SUBJECT-COMBINATIONS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadAllApplicantsSubjects(actions) {
  try {
    const { context } = actions;
    yield axios({
      url: `/admissions/admission-reports/applicant-subjects`,
      method: 'POST',
      data: context,
      timeout: 1200000,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ADMISSION-ALL-APPLICANTS-SUBJECTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadFormAttachment(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-applicant-attachment/${actions.attachmentId}`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${actions.title}`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_FORM_ATTACHMENT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_FORM_ATTACHMENT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getAdmissionSchemeReports(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-reports`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_SCHEME_REPORTS_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_SCHEME_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getDetailedAdmissionSchemeReports(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-reports/graduate-applicant-report`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: admissionApplicantActions.GET_DETAILED_ADMISSION_SCHEME_REPORTS_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_DETAILED_ADMISSION_SCHEME_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* generateApplicantPRN(actions) {
  const { formId, programmeCampusId, data } = actions;
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/generate-prn/${formId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: admissionApplicantActions.GENERATE_APPLICANT_PRN_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_APPLICANTS_REQUEST,
      programmeCampusId,
    });
    yield put({
      type: 'SHOW_GENERATE_PRN_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GENERATE_APPLICANT_PRN_ERROR,
      error: error.data,
    });
  }
}

function* updateApplicantDetails(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/update-bio-data/${actions.applicantId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: admissionApplicantActions.UPDATE_APPLICANT_DETAILS_SUCCESS,
      data: response,
    });
    yield put({ type: 'SHOW_EDIT_MODAL', payload: false });
    if (actions.formId) {
      yield put({
        type: admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_REQUEST,
        formId: actions.formId,
      });
    }
    if (actions.searchParams) {
      yield put({
        type: admissionApplicantActions.SEARCH_FOR_APPLICANTS_REQUEST,
        params: actions.searchParams,
      });
    } else {
      yield put({
        type: admissionApplicantActions.GET_ADMISSION_APPLICANTS_REQUEST,
        programmeCampusId: actions.programmeCampusId,
      });
    }
  } catch (error) {
    yield put({
      type: admissionApplicantActions.UPDATE_APPLICANT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionProgrammeDetails(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admission-programmes/programmes-context',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_PROGRAMME_DETAILS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_PROGRAMME_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* getApplicationFormDetails(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admission-applicant/applicant-form/${actions.formId}`,
      method: 'GET',
    });
    yield put({
      type: admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_SUCCESS,
      data: response.data,
      formId: actions.formId,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* recommendApplicant(actions) {
  try {
    const { data, programmeCampusId, formId } = actions;

    const response = yield axios({
      url: `admissions/running-admission-applicant/recommend-applicants`,
      method: 'POST',
      data,
    });
    yield put({
      type: admissionApplicantActions.RECOMMEND_APPLICANT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admissionApplicantActions.GET_ADMISSION_APPLICANTS_REQUEST,
      programmeCampusId,
    });
    yield put({
      type: 'OPEN_MODAL',
      payload: false,
    });
    yield put({
      type: 'SHOW_DETAILS_MODAL',
      payload: false,
    });
    yield put({
      type: admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_REQUEST,
      formId,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.RECOMMEND_APPLICANT_ERROR,
      error: error.data,
    });
  }
}

function* downloadUNEBReports(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/generate-uneb-report`,
      method: 'POST',
      data: actions.params,
      responseType: 'blob',
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-UNEB-REPORTS.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_UNEB_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_UNEB_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadDiplomaSchemeReport(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-diploma-report/${
        actions.programmeCampusId || '1'
      }`,
      method: 'POST',
      data: actions.context,
      responseType: 'blob',
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DIPLOMA-SCHEME-REPORT.xlsx`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_DIPLOMA_SCHEME_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL',
      payload: false,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_DIPLOMA_SCHEME_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadGraduateApplicants(actions) {
  const url =
    actions.docType === 'PDF'
      ? `/admissions/running-admission-applicant/download-graduate-pdf/${actions.programmeCampusId}`
      : `/admissions/running-admission-applicant/download-graduate-prog/${actions.programmeCampusId}`;
  try {
    yield axios({
      url,
      method: 'POST',
      data: actions.context,
      responseType: 'blob',
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: `${
            actions.docType === 'PDF'
              ? 'application/pdf'
              : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }`,
        }),
        `${
          process.env.REACT_APP_INSTITUTION_NAME
        }-GRADUATE-PROG-APPLICANTS-REPORT.${
          actions.docType === 'PDF' ? 'pdf' : 'xlsx'
        }`
      );
    });
    yield put({
      type: admissionApplicantActions.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionApplicantActions.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchFetchApplicantsByContext() {
  yield takeLatest(
    admissionApplicantActions.GET_PROGRAMMES_BY_CONTEXT_REQUEST,
    getAdmissionProgrammes
  );
}

function* watchSearchForApplicants() {
  yield takeLatest(
    admissionApplicantActions.SEARCH_FOR_APPLICANTS_REQUEST,
    searchForApplicants
  );
}
function* watchAdministrativelyAdmitApplicant() {
  yield takeLatest(
    admissionApplicantActions.ADMINISTRATIVELY_ADMIT_APPLICANT_REQUEST,
    administrativelyAdmitApplicant
  );
}

function* watchGetAdmissionApplicants() {
  yield takeLatest(
    admissionApplicantActions.GET_ADMISSION_APPLICANTS_REQUEST,
    getAdmissionApplicants
  );
}

function* watchGetWeightedApplicants() {
  yield takeLatest(
    admissionApplicantActions.GET_WEIGHTED_APPLICANTS_REQUEST,
    getWeightedApplicants
  );
}

function* watchGetSelectedApplicants() {
  yield takeLatest(
    admissionApplicantActions.GET_SELECTED_APPLICANTS_REQUEST,
    getSelectedApplicants
  );
}

function* watchGetNotSelectedApplicants() {
  yield takeLatest(
    admissionApplicantActions.GET_NOT_SELECTED_APPLICANTS_REQUEST,
    getNotSelectedApplicants
  );
}

function* watchGenerateNotSelectedApplicantsReason() {
  yield takeLatest(
    admissionApplicantActions.GENERATE_NOT_SELECTED_APPLICANTS_REASON_REQUEST,
    generateNotSelectedApplicantsReason
  );
}

function* watchRecommendApplicant() {
  yield takeLatest(
    admissionApplicantActions.RECOMMEND_APPLICANT_REQUEST,
    recommendApplicant
  );
}

function* watchDownloadWeightedApplicants() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_WEIGHTED_APPLICANTS_REQUEST,
    downloadWeightedApplicants
  );
}

function* watchDownloadSelectedApplicants() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_SELECTED_APPLICANTS_REQUEST,
    downloadSelectedApplicants
  );
}

function* watchDownloadSchemeSelectedApplicants() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_SCHEME_SELECTED_APPLICANTS_REQUEST,
    downloadSchemeSelectedApplicants
  );
}

function* watchDownloadNotSelectedApplicants() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_NOT_SELECTED_APPLICANTS_REQUEST,
    downloadNotSelectedApplicants
  );
}

function* watchDownloadFormAttachment() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_FORM_ATTACHMENT_REQUEST,
    downloadFormAttachment
  );
}

function* watchDownloadAdmissionApplicants() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_ADMISSION_APPLICANTS_REQUEST,
    downloadAdmissionApplicants
  );
}

function* watchDownloadGraduateProgrammeApplicants() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_REQUEST,
    downloadGraduateApplicants
  );
}

function* watchDownloadApplicantsSubjectCombinations() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_REQUEST,
    downloadApplicantsSubjectCombinations
  );
}

function* watchDownloadAllApplicantsSubjects() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_REQUEST,
    downloadAllApplicantsSubjects
  );
}

function* watchGetAdmissionSchemeReports() {
  yield takeLatest(
    admissionApplicantActions.GET_ADMISSION_SCHEME_REPORTS_REQUEST,
    getAdmissionSchemeReports
  );
}

function* watchGetDetailedAdmissionSchemeReports() {
  yield takeLatest(
    admissionApplicantActions.GET_DETAILED_ADMISSION_SCHEME_REPORTS_REQUEST,
    getDetailedAdmissionSchemeReports
  );
}

function* watchUpdateApplicantDetails() {
  yield takeLatest(
    admissionApplicantActions.UPDATE_APPLICANT_DETAILS_REQUEST,
    updateApplicantDetails
  );
}

function* watchGetAdmissionProgrammeDetails() {
  yield takeLatest(
    admissionApplicantActions.GET_ADMISSION_PROGRAMME_DETAILS_REQUEST,
    getAdmissionProgrammeDetails
  );
}

function* watchGetApplicationFormDetails() {
  yield takeLatest(
    admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_REQUEST,
    getApplicationFormDetails
  );
}

function* watchDownloadUNEBReports() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_UNEB_REPORTS_REQUEST,
    downloadUNEBReports
  );
}

function* watchGenerateApplicantPRN() {
  yield takeLatest(
    admissionApplicantActions.GENERATE_APPLICANT_PRN_REQUEST,
    generateApplicantPRN
  );
}

function* watchDownloadDiplomaSchemeReport() {
  yield takeLatest(
    admissionApplicantActions.DOWNLOAD_DIPLOMA_SCHEME_REQUEST,
    downloadDiplomaSchemeReport
  );
}

export default [
  fork(watchFetchApplicantsByContext),
  fork(watchSearchForApplicants),
  fork(watchAdministrativelyAdmitApplicant),
  fork(watchGetAdmissionApplicants),
  fork(watchUpdateApplicantDetails),
  fork(watchRecommendApplicant),
  fork(watchGetAdmissionProgrammeDetails),
  fork(watchGetAdmissionSchemeReports),
  fork(watchGetDetailedAdmissionSchemeReports),
  fork(watchGetApplicationFormDetails),
  fork(watchDownloadUNEBReports),
  fork(watchDownloadAdmissionApplicants),
  fork(watchDownloadApplicantsSubjectCombinations),
  fork(watchDownloadAllApplicantsSubjects),
  fork(watchDownloadFormAttachment),
  fork(watchGetWeightedApplicants),
  fork(watchGetSelectedApplicants),
  fork(watchGetNotSelectedApplicants),
  fork(watchDownloadWeightedApplicants),
  fork(watchDownloadSelectedApplicants),
  fork(watchDownloadSchemeSelectedApplicants),
  fork(watchDownloadNotSelectedApplicants),
  fork(watchGenerateNotSelectedApplicantsReason),
  fork(watchGenerateApplicantPRN),
  fork(watchDownloadDiplomaSchemeReport),
  fork(watchDownloadGraduateProgrammeApplicants),
];
