/* eslint-disable react/button-has-type */
import PropTypes, { any } from 'prop-types';
import React from 'react';
import PrintComponents from 'react-print-components';

function PrintComponent({ Print }) {
  return (
    <div style={{ display: 'none' }}>
      <PrintComponents trigger={<button id="btnPrint">Print</button>}>
        {Print}
      </PrintComponents>
    </div>
  );
}

PrintComponent.propTypes = {
  Print: PropTypes.oneOfType([any]).isRequired,
};

export default PrintComponent;
