import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { previousPaymentsActions, semesterEventActions } from '../../actions';

function* downloadPaymentsTemplate() {
  try {
    yield axios({
      url: '/universal-payments/previous-transactions/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PAYMENTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: previousPaymentsActions.DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.DOWNLOAD_PAYMENTS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadPaymentsTemplate(actions) {
  try {
    const response = yield axios({
      url: '/universal-payments/previous-transactions/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: previousPaymentsActions.UPLOAD_PAYMENTS_TEMPLATE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.UPLOAD_PAYMENTS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* getPreviousPayments(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/previous-transactions/transactions`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_PAYMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* pushToPrepayments(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/previous-transactions/transfer-tuition`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: previousPaymentsActions.PUSH_TO_PREPAYMENTS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_DEPOSITS_REQUEST,
      params: { student_number: actions.data.studentNumber },
    });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_PAYMENTS_REQUEST,
      params: { student_number: actions.data.studentNumber },
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.PUSH_TO_PREPAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* deletePreviousTuitionDeposits(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/previous-transactions/tuition-payments`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: previousPaymentsActions.DELETE_PREVIOUS_TUITION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_PAYMENTS_REQUEST,
      params: { student_number: actions.studentNumber },
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.DELETE_PREVIOUS_TUITION_ERROR,
      error: error.data,
    });
  }
}

function* deletePreviousPrePaymentsDeposits(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/previous-transactions/pre-payment-deposits`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: previousPaymentsActions.DELETE_PREVIOUS_PREPAYMENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_DEPOSITS_REQUEST,
      params: { student_number: actions.studentNumber },
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.DELETE_PREVIOUS_PREPAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* pushToStudentAccount(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/previous-transactions/push-to-student-account`,
      method: 'POST',
      data: actions.payload,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({
      type: previousPaymentsActions.PUSH_TO_STUDENT_ACCOUNT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_DEPOSITS_REQUEST,
      params: { student_number: actions.studentNo },
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentProgrammeId: actions.studentProgId,
      studentId: actions.studentId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentProgrammeId: actions.studentProgId,
      studentId: actions.studentId,
    });
    yield put({
      type: previousPaymentsActions.SET_SELECTED_FEES_DEPOSITS,
      rows: [],
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.PUSH_TO_STUDENT_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* fetchStudentDeposits(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/previous-transactions/deposits`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_DEPOSITS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: previousPaymentsActions.GET_PREVIOUS_DEPOSITS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadPaymentsTemplate() {
  yield takeLatest(
    previousPaymentsActions.DOWNLOAD_PAYMENTS_TEMPLATE_REQUEST,
    downloadPaymentsTemplate
  );
}

function* watchUploadPaymentsTemplate() {
  yield takeLatest(
    previousPaymentsActions.UPLOAD_PAYMENTS_TEMPLATE_REQUEST,
    uploadPaymentsTemplate
  );
}

function* watchGetPreviousPayments() {
  yield takeLatest(
    previousPaymentsActions.GET_PREVIOUS_PAYMENTS_REQUEST,
    getPreviousPayments
  );
}

function* watchPushToStudentAccount() {
  yield takeLatest(
    previousPaymentsActions.PUSH_TO_STUDENT_ACCOUNT_REQUEST,
    pushToStudentAccount
  );
}

function* watchPushToPrepayments() {
  yield takeLatest(
    previousPaymentsActions.PUSH_TO_PREPAYMENTS_REQUEST,
    pushToPrepayments
  );
}

function* watchDeletePreviousTuitionDeposits() {
  yield takeLatest(
    previousPaymentsActions.DELETE_PREVIOUS_TUITION_REQUEST,
    deletePreviousTuitionDeposits
  );
}

function* watchDeletePreviousPrePaymentsDeposits() {
  yield takeLatest(
    previousPaymentsActions.DELETE_PREVIOUS_PREPAYMENTS_REQUEST,
    deletePreviousPrePaymentsDeposits
  );
}

function* watchGetStudentDeposits() {
  yield takeLatest(
    previousPaymentsActions.GET_PREVIOUS_DEPOSITS_REQUEST,
    fetchStudentDeposits
  );
}

export default [
  fork(watchDeletePreviousPrePaymentsDeposits),
  fork(watchDeletePreviousTuitionDeposits),
  fork(watchDownloadPaymentsTemplate),
  fork(watchUploadPaymentsTemplate),
  fork(watchPushToStudentAccount),
  fork(watchPushToPrepayments),
  fork(watchGetPreviousPayments),
  fork(watchGetStudentDeposits),
];
