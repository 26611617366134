const resultsBlockingPolicyActions = {
  GET_RESULT_BLOCKING_POLICY_REQUEST: 'GET_RESULT_BLOCKING_POLICY_REQUEST',
  GET_RESULT_BLOCKING_POLICY_SUCCESS: 'GET_RESULT_BLOCKING_POLICY_SUCCESS',
  GET_RESULT_BLOCKING_POLICY_ERROR: 'GET_RESULT_BLOCKING_POLICY_ERROR',

  UPDATE_RESULT_BLOCKING_POLICY_REQUEST:
    'UPDATE_RESULT_BLOCKING_POLICY_REQUEST',
  UPDATE_RESULT_BLOCKING_POLICY_SUCCESS:
    'UPDATE_RESULT_BLOCKING_POLICY_SUCCESS',
  UPDATE_RESULT_BLOCKING_POLICY_ERROR: 'UPDATE_RESULT_BLOCKING_POLICY_ERROR',

  GET_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST:
    'GET_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST',
  GET_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS:
    'GET_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS',
  GET_VIEW_RESULTS_REGISTRATION_POLICY_ERROR:
    'GET_VIEW_RESULTS_REGISTRATION_POLICY_ERROR',

  UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST:
    'UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST',
  UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS:
    'UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS',
  UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_ERROR:
    'UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_ERROR',

  GET_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST:
    'GET_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST',
  GET_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS:
    'GET_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS',
  GET_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR:
    'GET_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR',

  UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST:
    'UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST',
  UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS:
    'UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS',
  UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR:
    'UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR',

  GET_PENDING_RESULTS_BLOCKING_POLICY_REQUEST:
    'GET_PENDING_RESULTS_BLOCKING_POLICY_REQUEST',
  GET_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS:
    'GET_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS',
  GET_PENDING_RESULTS_BLOCKING_POLICY_ERROR:
    'GET_PENDING_RESULTS_BLOCKING_POLICY_ERROR',

  APPROVE_PENDING_RESULTS_BLOCKING_POLICY_REQUEST:
    'APPROVE_PENDING_RESULTS_BLOCKING_POLICY_REQUEST',
  APPROVE_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS:
    'APPROVE_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS',
  APPROVE_PENDING_RESULTS_BLOCKING_POLICY_ERROR:
    'APPROVE_PENDING_RESULTS_BLOCKING_POLICY_ERROR',

  SET_POLICY_ACTIVE_TAB: 'SET_POLICY_ACTIVE_TAB',

  getResultBlockingPolicy: (category) => ({
    type: resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_REQUEST,
    category,
  }),

  updateOrCreateResultBlockingPolicy: (data, category) => ({
    type: resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_REQUEST,
    data,
    category,
  }),

  getViewResultsRegistrationPolicy: () => ({
    type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST,
  }),

  updateViewResultsRegistrationPolicy: (data) => ({
    type: resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST,
    data,
  }),

  getViewResultsFeesBalancePolicy: () => ({
    type: resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST,
  }),

  updateViewResultsFeesBalancePolicy: (data) => ({
    type: resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST,
    data,
  }),

  getPendingResultsBlockingPolicies: () => ({
    type: resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_REQUEST,
  }),

  approvePendingResultsBlockingPolicy: (policyId) => ({
    type: resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_REQUEST,
    policyId,
  }),

  setPolicyActiveTab: (activeTab) => ({
    type: resultsBlockingPolicyActions.SET_POLICY_ACTIVE_TAB,
    activeTab,
  }),
};

export default resultsBlockingPolicyActions;
