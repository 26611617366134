const admissionScheme = {
  GET_ADMISSION_SCHEME_REQUEST: 'GET_ADMISSION_SCHEME_REQUEST',
  GET_ADMISSION_SCHEME_SUCCESS: 'GET_ADMISSION_SCHEME_SUCCESS',
  GET_ADMISSION_SCHEME_ERROR: 'GET_ADMISSION_SCHEME_ERROR',

  GET_SINGLE_ADMISSION_SCHEME_REQUEST: 'GET_SINGLE_ADMISSION_SCHEME_REQUEST',
  GET_SINGLE_ADMISSION_SCHEME_SUCCESS: 'GET_SINGLE_ADMISSION_SCHEME_SUCCESS',
  GET_SINGLE_ADMISSION_SCHEME_ERROR: 'GET_SINGLE_ADMISSION_SCHEME_ERROR',

  CREATE_ADMISSION_SCHEME_SUCCESS: 'CREATE_ADMISSION_SCHEME_SUCCESS',
  CREATE_ADMISSION_SCHEME_REQUEST: 'CREATE_ADMISSION_SCHEME_REQUEST',
  CREATE_ADMISSION_SCHEME_ERROR: 'CREATE_ADMISSION_SCHEME_ERROR',

  UPDATE_ADMISSION_SCHEME_SUCCESS: 'UPDATE_ADMISSION_SCHEME_SUCCESS',
  UPDATE_ADMISSION_SCHEME_REQUEST: 'UPDATE_ADMISSION_SCHEME_REQUEST',
  UPDATE_ADMISSION_SCHEME_ERROR: 'UPDATE_ADMISSION_SCHEME_ERROR',

  DELETE_ADMISSION_SCHEME_SUCCESS: 'DELETE_ADMISSION_SCHEME_SUCCESS',
  DELETE_ADMISSION_SCHEME_REQUEST: 'DELETE_ADMISSION_SCHEME_REQUEST',
  DELETE_ADMISSION_SCHEME_ERROR: 'DELETE_ADMISSION_SCHEME_ERROR',

  GET_EMIS_ADMISSION_SCHEME_CATEGORIES_REQUEST:
    'GET_EMIS_ADMISSION_SCHEME_CATEGORIES_REQUEST',
  GET_EMIS_ADMISSION_SCHEME_CATEGORIES_SUCCESS:
    'GET_EMIS_ADMISSION_SCHEME_CATEGORIES_SUCCESS',
  GET_EMIS_ADMISSION_SCHEME_CATEGORIES_ERROR:
    'GET_EMIS_ADMISSION_SCHEME_CATEGORIES_ERROR',

  getAdmissionSchemes: (data) => ({
    type: admissionScheme.GET_ADMISSION_SCHEME_REQUEST,
    data,
  }),

  createAdmissionScheme: (data) => ({
    type: admissionScheme.CREATE_ADMISSION_SCHEME_REQUEST,
    data,
  }),

  getSingleAdmissionScheme: (admissionSchemeId, data) => ({
    type: admissionScheme.GET_SINGLE_ADMISSION_SCHEME_REQUEST,
    admissionSchemeId,
    data,
  }),

  updateAdmissionScheme: (admissionSchemeId, data) => ({
    type: admissionScheme.UPDATE_ADMISSION_SCHEME_REQUEST,
    admissionSchemeId,
    data,
  }),

  deleteAdmissionScheme: (admissionSchemeId) => ({
    type: admissionScheme.DELETE_ADMISSION_SCHEME_REQUEST,
    admissionSchemeId,
  }),

  getEmisAdmissionSchemeCategories: () => ({
    type: admissionScheme.GET_EMIS_ADMISSION_SCHEME_CATEGORIES_REQUEST,
  }),
};

export default admissionScheme;
