const sharedInitialSTate = {
  downloadingExcelFile: false,
  downloadExcelFileError: {},

  downloadingPDFFile: false,
  downloadPDFFileError: {},

  uploadingExcelFile: false,
  uploadExcelFileError: {},
  uploadExcelFileSuccess: {},
};

export default sharedInitialSTate;
