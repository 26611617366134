const academicDocuments = {
  academicDocument: {
    gettingTestimonial: false,
    getTestimonialError: {},
    multipleTestimonials: [],
    singleTestimonial: {},
    singleTestimonials: [],

    studentNoOrReg: null,

    selectedProgramme: {},
    selectedAcademicYear: null,

    printingDocument: false,
    printDocumentError: {},
    printedDocument: {},

    graduationProgrammes: [],
    gettingProgrammes: false,
    getProgrammeError: {},

    downloadingDocuments: false,
    downloadDocumentError: {},
    downloadedDocument: {},

    printingSingleDocument: false,
    printSingleDocumentError: {},
    printedSingleDocument: {},

    generatingTranscript: false,
    transcriptError: {},
  },
};

export default academicDocuments;
