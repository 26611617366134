const studentsInitialState = {
  // Students initial state.
  students: {
    students: [],
    allStudents: [],
    studentsContext: {},
    studentContext: {},
    contextStudents: [],
    enrolmentHistory: [],
    registrationHistory: [],
    multipleRegistrations: [],
    enrolmentStatus: {},
    registrationStatus: {},
    gettingProgrammeCourses: false,
    programmeCourseError: {},
    programmeCourses: [],
    studentCoursesToEdit: [],
    studentCoursesToEditError: {},
    selectedStudent: {},
    uploadStudent: {},
    studentIdentifier: null,
    currentRegistration: {},
    semesterBoundRegistration: {},
    identifyingStudent: {},
    selectedProgramme: {},
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    dashboardData: {},
    dashboardError: {},
    creating: false,
    loading: false,
    fetching: false,
    fetchingOne: false,
    updating: false,
    deleting: false,

    uploading: false,
    uploaded: {},
    uploadError: {},

    uploadStudentPhoto: false,
    uploadedStudentPhoto: {},
    uploadStudentPhotoError: {},

    uploadStudentIdCardImage: false,
    uploadedStudentIdCardImage: {},
    uploadStudentIdCardImageError: {},

    uploadIdImageContext: {},

    gettingContextStudents: false,
    showStudentProfileModal: false,

    lateProgrammeCourseError: {},
    lateProgrammeCourses: [],

    checkingExamPermit: false,
    examPermitError: {},
    allExamPermits: [],

    downloadingStudents: false,
    downloadingStudentsError: {},
    downloadingStudentsSuccess: {},

    showDeleteFromSRMModal: false,
    deletingFromSRM: false,
    deleteFromSRMError: {},
    deleteFromSRMSuccess: {},

    fetchedProgStudents: [],
    fetchProgStudentsError: {},
    studentsProgContext: [],
    fetchingProgStudents: true,

    updatingVersion: false,
    showEditVersion: false,
    updateVersionResponse: {},
    updateVersionError: {},

    showSearchAccount: false,
    showEditAccount: false,
    editingStatus: false,
    editStatusError: {},
    editStatusSuccess: {},

    gettingWaiverStudents: false,
    waiverStudents: [],
    getWaiverStudentsError: {},

    updatingWaiverLimit: false,
    updateLimitSuccess: {},
    updateLimitError: {},

    addingStudentToWaiver: false,
    addStudentSuccess: {},
    addStudentError: {},

    studentList: [],
  },
  studentSupport: {
    generateOTPError: {},
    generatedOTP: [],
    generatingOTP: false,

    studentQuery: null,

    fetchedStudent: {},
    fetchStudentError: {},
    fetchingStudent: false,

    updatingStudentSupportContacts: false,
    updateStudentSupportContactsSuccess: {},
    updateStudentSupportContactsError: {},

    staffQuery: {},
    fetchingStaff: false,
    fetchStaffError: {},
    fetchedStaff: {},
  },
  student: {
    selectedStudent: {},
    enrolmentHistory: [],
    updateError: {},
    updateResponse: {},
    deleteResponse: {},
    error: {},
    creating: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    downloadingTemplate: false,
    downloadingTemplateError: {},
    downloadingTemplateSuccess: {},

    downloadingUpdateTemplate: false,
    downloadUpdateError: {},
    downloadUpdateTemplateSuccess: {},

    uploadingUpdateTemplate: false,
    uploadUpdateError: {},
    uploadUpdateTemplateSuccess: {},

    uploading: false,
    uploadError: {},
    uploadSuccess: {},

    verifyingTemplate: false,
    verifyTemplateSuccess: [],
    verifyTemplateError: {},

    gettingPendingStudents: false,
    pendingError: {},
    pendingStudents: [],

    gettingPendingStatusChanges: false,
    pendingStatusChangeRequestsError: {},
    pendingStatusChangeRequests: [],

    gettingBatchStudents: false,
    batchError: {},
    batchStudents: [],

    gettingDissertations: false,
    getDissertationError: {},
    dissertations: [],

    updatingDissertations: false,
    updateDissertationError: {},

    approving: false,
    approveError: {},
    approveSuccess: {},

    deleteAvatarOrSignature: false,
    deleteAvatarOrSignatureError: {},
    deleteAvatarOrSignatureSuccess: {},

    searching: false,
    searchError: {},
    searchedStudents: [],
    searchedContext: {},

    gettingMyBatch: false,
    myBatchError: {},
    myStudentBatches: [],

    gettingPrintedCards: false,
    printedCardsError: {},
    printedCards: [],

    myBatchContext: {},
  },
};
export default studentsInitialState;
