import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { documentSettingActions } from '../../actions';

function* getDocumentSettings(actions) {
  try {
    const response = yield axios({
      url: '/app/document-settings',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: documentSettingActions.GET_DOCUMENT_SETTING_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: documentSettingActions.GET_DOCUMENT_SETTING_ERROR,
      error: error.data,
    });
  }
}

function* getDocumentPreview(actions) {
  const { category, params } = actions;
  try {
    const response = yield axios({
      url: `/app/document-settings/preview/${category}`,
      method: 'GET',
      params,
    });
    yield put({
      type: documentSettingActions.STUDENT_DOCUMENT_PREVIEW_SUCCESS,
      data: response.data,
      category,
      params,
    });
  } catch (error) {
    yield put({
      type: documentSettingActions.STUDENT_DOCUMENT_PREVIEW_ERROR,
      error: error.data,
      category,
      params,
    });
  }
}

function* createDocumentSetting(actions) {
  try {
    const response = yield axios({
      url: `/app/document-settings`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    yield put({
      type: documentSettingActions.CREATE_DOCUMENT_SETTING_SUCCESS,
      data: response.data,
    });
    yield put({
      type: documentSettingActions.GET_DOCUMENT_SETTING_REQUEST,
    });
  } catch (error) {
    yield put({
      type: documentSettingActions.CREATE_DOCUMENT_SETTING_ERROR,
      error: error.data,
    });
  }
}

function* watchGetDocumentSetting() {
  yield takeLatest(
    documentSettingActions.GET_DOCUMENT_SETTING_REQUEST,
    getDocumentSettings
  );
}

function* watchGetDocumentPreview() {
  yield takeLatest(
    documentSettingActions.STUDENT_DOCUMENT_PREVIEW_REQUEST,
    getDocumentPreview
  );
}

function* watchCreateDocumentSetting() {
  yield takeLatest(
    documentSettingActions.CREATE_DOCUMENT_SETTING_REQUEST,
    createDocumentSetting
  );
}

export default [
  fork(watchGetDocumentSetting),
  fork(watchCreateDocumentSetting),
  fork(watchGetDocumentPreview),
];
