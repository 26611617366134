import { pujabInstitutionActions } from '../../actions';
import pujabMgtInitialState from '../../initialState/pujab-mgt';

const pujabInstitutions = (
  state = pujabMgtInitialState.pujabInstitution,
  actions
) => {
  switch (actions.type) {
    case pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_REQUEST:
      return {
        ...state,
        gettingInstitutions: true,
        getInstitutionError: {},
      };
    case pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        gettingInstitutions: false,
        institutions: actions.data,
      };
    case pujabInstitutionActions.GET_PUJAB_INSTITUTIONS_ERROR:
      return {
        ...state,
        gettingInstitutions: false,
        getInstitutionError: actions.error,
      };

    case pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_REQUEST:
      return {
        ...state,
        addingInstitution: true,
        addError: {},
      };
    case pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        addingInstitution: false,
        addSuccess: actions.data,
      };
    case pujabInstitutionActions.ADD_PUJAB_INSTITUTIONS_ERROR:
      return {
        ...state,
        addingInstitution: false,
        addError: actions.error,
      };

    case pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_REQUEST:
      return {
        ...state,
        updatingInstitution: true,
        updateError: {},
      };
    case pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        updatingInstitution: false,
        updateSuccess: actions.data,
      };
    case pujabInstitutionActions.UPDATE_PUJAB_INSTITUTIONS_ERROR:
      return {
        ...state,
        updatingInstitution: false,
        updateError: actions.error,
      };

    case pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_REQUEST:
      return {
        ...state,
        deletingInstitution: true,
        deleteError: {},
      };
    case pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        deletingInstitution: false,
        deleteSuccess: actions.data,
      };
    case pujabInstitutionActions.DELETE_PUJAB_INSTITUTIONS_ERROR:
      return {
        ...state,
        deletingInstitution: false,
        deleteError: actions.error,
      };

    case pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
      };
    case pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case pujabInstitutionActions.UPLOAD_INSTITUTIONS_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case pujabInstitutionActions.DOWNLOAD_INSTITUTION_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };
    default:
      return state;
  }
};

export default pujabInstitutions;
