const semesterLoad = {
  GET_SEMESTER_LOADS_REQUEST: 'GET_SEMESTER_LOADS_REQUEST',
  GET_SEMESTER_LOADS_SUCCESS: 'GET_SEMESTER_LOADS_SUCCESS',
  GET_SEMESTER_LOADS_ERROR: 'GET_SEMESTER_LOADS_ERROR',

  CREATE_SEMESTER_LOAD_SUCCESS: 'CREATE_SEMESTER_LOAD_SUCCESS',
  CREATE_SEMESTER_LOAD_REQUEST: 'CREATE_SEMESTER_LOAD_REQUEST',
  CREATE_SEMESTER_LOAD_ERROR: 'CREATE_SEMESTER_LOAD_ERROR',

  DELETE_SEMESTER_LOAD_SUCCESS: 'DELETE_SEMESTER_LOAD_SUCCESS',
  DELETE_SEMESTER_LOAD_REQUEST: 'DELETE_SEMESTER_LOAD_REQUEST',
  DELETE_SEMESTER_LOAD_ERROR: 'DELETE_SEMESTER_LOAD_ERROR',

  UPDATE_SEMESTER_LOAD_SUCCESS: 'UPDATE_SEMESTER_LOAD_SUCCESS',
  UPDATE_SEMESTER_LOAD_REQUEST: 'UPDATE_SEMESTER_LOAD_REQUEST',
  UPDATE_SEMESTER_LOAD_ERROR: 'UPDATE_SEMESTER_LOAD_ERROR',

  getSemesterLoads: (data) => ({
    type: semesterLoad.GET_SEMESTER_LOADS_REQUEST,
    data,
  }),

  createSemesterLoad: (data) => ({
    type: semesterLoad.CREATE_SEMESTER_LOAD_REQUEST,
    data,
  }),
  deleteSemesterLoad: (id) => ({
    type: semesterLoad.DELETE_SEMESTER_LOAD_REQUEST,
    id,
  }),

  updateSemesterLoad: (id, data) => ({
    type: semesterLoad.UPDATE_SEMESTER_LOAD_REQUEST,
    id,
    data,
  }),
};

export default semesterLoad;
