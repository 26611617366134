const pujabAdmissionActions = {
  GET_PUJAB_ADMISSIONS_REQUEST: 'GET_PUJAB_ADMISSIONS_REQUEST',
  GET_PUJAB_ADMISSIONS_SUCCESS: 'GET_PUJAB_ADMISSIONS_SUCCESS',
  GET_PUJAB_ADMISSIONS_ERROR: 'GET_PUJAB_ADMISSIONS_ERROR',

  GET_PUJAB_ADMISSION_INSTITUTION_REQUEST:
    'GET_PUJAB_ADMISSION_INSTITUTION_REQUEST',
  GET_PUJAB_ADMISSION_INSTITUTION_SUCCESS:
    'GET_PUJAB_ADMISSION_INSTITUTION_SUCCESS',
  GET_PUJAB_ADMISSION_INSTITUTION_ERROR:
    'GET_PUJAB_ADMISSION_INSTITUTION_ERROR',

  CREATE_PUJAB_ADMISSION_REQUEST: 'CREATE_PUJAB_ADMISSION_REQUEST',
  CREATE_PUJAB_ADMISSION_SUCCESS: 'CREATE_PUJAB_ADMISSION_SUCCESS',
  CREATE_PUJAB_ADMISSION_ERROR: 'CREATE_PUJAB_ADMISSION_ERROR',

  UPDATE_PUJAB_ADMISSION_REQUEST: 'UPDATE_PUJAB_ADMISSION_REQUEST',
  UPDATE_PUJAB_ADMISSION_SUCCESS: 'UPDATE_PUJAB_ADMISSION_SUCCESS',
  UPDATE_PUJAB_ADMISSION_ERROR: 'UPDATE_PUJAB_ADMISSION_ERROR',

  DELETE_PUJAB_ADMISSION_REQUEST: 'DELETE_PUJAB_ADMISSION_REQUEST',
  DELETE_PUJAB_ADMISSION_SUCCESS: 'DELETE_PUJAB_ADMISSION_SUCCESS',
  DELETE_PUJAB_ADMISSION_ERROR: 'DELETE_PUJAB_ADMISSION_ERROR',

  ASSIGN_PUJAB_ADMISSION_PROGRAMME_REQUEST:
    'ASSIGN_PUJAB_ADMISSION_PROGRAMME_REQUEST',
  ASSIGN_PUJAB_ADMISSION_PROGRAMME_SUCCESS:
    'ASSIGN_PUJAB_ADMISSION_PROGRAMME_SUCCESS',
  ASSIGN_PUJAB_ADMISSION_PROGRAMME_ERROR:
    'ASSIGN_PUJAB_ADMISSION_PROGRAMME_ERROR',

  CREATE_PUJAB_ADMISSION_PROGRAMMES_REQUEST:
    'CREATE_PUJAB_ADMISSION_PROGRAMMES_REQUEST',
  CREATE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS:
    'CREATE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS',
  CREATE_PUJAB_ADMISSION_PROGRAMMES_ERROR:
    'CREATE_PUJAB_ADMISSION_PROGRAMMES_ERROR',

  UPDATE_PUJAB_ADMISSION_PROGRAMMES_REQUEST:
    'UPDATE_PUJAB_ADMISSION_PROGRAMMES_REQUEST',
  UPDATE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS:
    'UPDATE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS',
  UPDATE_PUJAB_ADMISSION_PROGRAMMES_ERROR:
    'UPDATE_PUJAB_ADMISSION_PROGRAMMES_ERROR',

  DELETE_PUJAB_ADMISSION_PROGRAMMES_REQUEST:
    'DELETE_PUJAB_ADMISSION_PROGRAMMES_REQUEST',
  DELETE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS:
    'DELETE_PUJAB_ADMISSION_PROGRAMMES_SUCCESS',
  DELETE_PUJAB_ADMISSION_PROGRAMMES_ERROR:
    'DELETE_PUJAB_ADMISSION_PROGRAMMES_ERROR',

  UPDATE_PUJAB_ADMISSION_STATUS_REQUEST:
    'UPDATE_PUJAB_ADMISSION_STATUS_REQUEST',
  UPDATE_PUJAB_ADMISSION_STATUS_SUCCESS:
    'UPDATE_PUJAB_ADMISSION_STATUS_SUCCESS',
  UPDATE_PUJAB_ADMISSION_STATUS_ERROR: 'UPDATE_PUJAB_ADMISSION_STATUS_ERROR',

  SET_SELECTED_PUJAB_ADMISSION: 'SET_SELECTED_PUJAB_ADMISSION',
  SET_SELECTED_ADMISSION_INSTITUTION: 'SET_SELECTED_ADMISSION_INSTITUTION',

  setSelectedAdmission: (data) => ({
    type: pujabAdmissionActions.SET_SELECTED_PUJAB_ADMISSION,
    data,
  }),

  setSelectedAdmissionInstitution: (data) => ({
    type: pujabAdmissionActions.SET_SELECTED_ADMISSION_INSTITUTION,
    data,
  }),

  getPujabAdmissions: () => ({
    type: pujabAdmissionActions.GET_PUJAB_ADMISSIONS_REQUEST,
  }),

  getPujabAdmissionsInstitution: (admissionInstitutionId) => ({
    type: pujabAdmissionActions.GET_PUJAB_ADMISSION_INSTITUTION_REQUEST,
    admissionInstitutionId,
  }),

  createPujabAdmission: (payload) => ({
    type: pujabAdmissionActions.CREATE_PUJAB_ADMISSION_REQUEST,
    payload,
  }),

  updatePujabAdmission: (admissionId, data) => ({
    type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_REQUEST,
    admissionId,
    data,
  }),

  updatePujabAdmissionStatus: (admissionId, status) => ({
    type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_STATUS_REQUEST,
    admissionId,
    status,
  }),

  deletePujabAdmission: (admissionId) => ({
    type: pujabAdmissionActions.DELETE_PUJAB_ADMISSION_REQUEST,
    admissionId,
  }),

  assignPujabAdmissionProgramme: (admissionInstitutionId, data) => ({
    type: pujabAdmissionActions.ASSIGN_PUJAB_ADMISSION_PROGRAMME_REQUEST,
    admissionInstitutionId,
    data,
  }),

  createPujabProgramme: (admissionId, data) => ({
    type: pujabAdmissionActions.CREATE_PUJAB_ADMISSION_PROGRAMMES_REQUEST,
    admissionId,
    data,
  }),

  updatePujabAdmissionProgramme: (id, data, admissionId) => ({
    type: pujabAdmissionActions.UPDATE_PUJAB_ADMISSION_PROGRAMMES_REQUEST,
    id,
    data,
    admissionId,
  }),

  deletePujabAdmissionProgramme: (institutionId, data) => ({
    type: pujabAdmissionActions.DELETE_PUJAB_ADMISSION_PROGRAMMES_REQUEST,
    admissionInstitutionId: institutionId,
    data,
  }),
};

export default pujabAdmissionActions;
