import { isEmpty, map } from 'lodash';
import PropTypes, { any, array } from 'prop-types';
import React, { useEffect } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../config/actions';

function TabMenu({
  menus,
  currentMenu,
  setCurrentMenu,
  navCode,
  children,
  ...props
}) {
  const dispatch = useDispatch();

  const { appNavigation, selectedMenu } = useSelector(
    (state) => state.settings
  );

  const setNavigation = (title) => {
    if (navCode) {
      dispatch(
        settingActions.setAppNavigation({
          ...appNavigation,
          [navCode]: title || null,
        })
      );
    }
  };

  useEffect(() => {
    const tab = !isEmpty(menus) ? [0]?.title : null;
    if (!isEmpty(currentMenu)) setNavigation(tab);
  }, [selectedMenu]);

  const handleClick = (action) => {
    setCurrentMenu(action);
    setNavigation(action);
  };

  return (
    <Tabs
      activeKey={currentMenu}
      id="uncontrolled-tab"
      className="fw-bold text-uppercase mb-1"
      onSelect={handleClick}
      {...props}
      as={Card.Header}
    >
      {map(menus, (menu) => {
        if (menu.hidden !== true) {
          return (
            <Tab
              eventKey={menu.action}
              unmountOnExit
              key={menu.action}
              tabClassName="text-uppercase"
              {...menu}
              title={
                <>
                  {menu.icon} {menu.title}
                </>
              }
            >
              {menu.children}
            </Tab>
          );
        }
        return null;
      })}
    </Tabs>
  );
}

TabMenu.defaultProps = {
  children: null,
  navCode: null,
};

TabMenu.propTypes = {
  menus: PropTypes.oneOfType([array]).isRequired,
  children: PropTypes.oneOfType([any]),
  currentMenu: PropTypes.oneOfType([any]).isRequired,
  setCurrentMenu: PropTypes.func.isRequired,
  navCode: PropTypes.string,
};

export default TabMenu;
