import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { applicationHistoryActions } from '../../actions';

function* getApplicationHistory(actions) {
  try {
    const response = yield axios({
      url: '/admissions/application-history',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: applicationHistoryActions.GET_ADMISSION_HISTORY_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: applicationHistoryActions.GET_ADMISSION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* updateApplicationHistory(actions) {
  try {
    const response = yield axios({
      url: `/admissions/application-history/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: applicationHistoryActions.UPDATE_ADMISSION_HISTORY_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationHistoryActions.GET_ADMISSION_HISTORY_REQUEST,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: applicationHistoryActions.UPDATE_ADMISSION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* getSingleApplicationHistory(actions) {
  try {
    const response = yield axios({
      url: `/admissions/application-history/${actions.applicationHistoryId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: applicationHistoryActions.GET_SINGLE_ADMISSION_HISTORY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: applicationHistoryActions.GET_SINGLE_ADMISSION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* deleteApplicationHistory(actions) {
  try {
    const response = yield axios({
      url: `/admissions/application-history/${actions.id}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: applicationHistoryActions.DELETE_ADMISSION_HISTORY_SUCCESS,
      data: response,
    });
    yield put({
      type: applicationHistoryActions.GET_ADMISSION_HISTORY_REQUEST,
      context: actions.context,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: applicationHistoryActions.DELETE_ADMISSION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetApplicationHistory() {
  yield takeLatest(
    applicationHistoryActions.GET_ADMISSION_HISTORY_REQUEST,
    getApplicationHistory
  );
}

function* watchUpdateApplicationHistory() {
  yield takeLatest(
    applicationHistoryActions.UPDATE_ADMISSION_HISTORY_REQUEST,
    updateApplicationHistory
  );
}

function* watchDeleteApplicationHistory() {
  yield takeLatest(
    applicationHistoryActions.DELETE_ADMISSION_HISTORY_REQUEST,
    deleteApplicationHistory
  );
}

function* watchGetSingleApplicationHistory() {
  yield takeLatest(
    applicationHistoryActions.GET_SINGLE_ADMISSION_HISTORY_REQUEST,
    getSingleApplicationHistory
  );
}

export default [
  fork(watchGetApplicationHistory),
  fork(watchUpdateApplicationHistory),
  fork(watchGetSingleApplicationHistory),
  fork(watchDeleteApplicationHistory),
];
