import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { resultDashboardActions } from '../../actions';

function* fetchDashboardData(actions) {
  try {
    const { params } = actions;

    const response = yield axios({
      url: '/results-mgt/reports',
      method: 'GET',
      params,
    });

    yield put({
      type: resultDashboardActions.FETCH_DASHBOARD_DATA_SUCCESS,
      data: response.data,
      params,
    });
  } catch (error) {
    yield put({
      type: resultDashboardActions.FETCH_DASHBOARD_DATA_ERROR,
      error: error.data,
    });
  }
}

function* getResultDetailedReports(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/reports/detailed',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultDashboardActions.GET_DETAILED_RESULT_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: resultDashboardActions.GET_DETAILED_RESULT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* submitProgrammeReport(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/senate-reports/generate-senate-report',
      method: 'POST',
      data: actions.context,
    });
    yield put({
      type: resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getDepartmentProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/reports/programmes`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: resultDashboardActions.GET_SEMESTER_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: resultDashboardActions.GET_SEMESTER_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getResultSummaryReport(actions) {
  let URL = '/results-mgt/senate-reports';

  if (actions.category === 'programme') {
    URL = '/results-mgt/reports/summary';
  } else if (actions.category === 'senate') {
    URL = '/results-mgt/senate-reports/senate';
  }

  try {
    const response = yield axios({
      url: URL,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: resultDashboardActions.GET_RESULT_SUMMARY_REPORT_SUCCESS,
      data: response.data || response.report,
      context: actions.context,
      category: actions.category,
    });
  } catch (error) {
    yield put({
      type: resultDashboardActions.GET_RESULT_SUMMARY_REPORT_ERROR,
      error: error.data,
      category: actions.category,
    });
  }
}

function* downloadResultReports(actions) {
  try {
    yield axios({
      url: '/results-mgt/reports/download-detailed',
      method: 'GET',
      params: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-RESULT-REPORT.xlsx`
      );
    });
    yield put({
      type: resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchFetchDashboardData() {
  yield takeEvery(
    resultDashboardActions.FETCH_DASHBOARD_DATA_REQUEST,
    fetchDashboardData
  );
}

function* watchGetResultsReports() {
  yield takeLatest(
    resultDashboardActions.GET_DETAILED_RESULT_REPORT_REQUEST,
    getResultDetailedReports
  );
}

function* watchGetSubmitProgrammeReport() {
  yield takeLatest(
    resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_REQUEST,
    submitProgrammeReport
  );
}

function* watchGetDepartmentProgrammes() {
  yield takeLatest(
    resultDashboardActions.GET_SEMESTER_PROGRAMMES_REQUEST,
    getDepartmentProgrammes
  );
}

function* watchGetResultSummaryReport() {
  yield takeLatest(
    resultDashboardActions.GET_RESULT_SUMMARY_REPORT_REQUEST,
    getResultSummaryReport
  );
}

function* watchDownloadResultReport() {
  yield takeLatest(
    resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_REQUEST,
    downloadResultReports
  );
}

export default [
  fork(watchFetchDashboardData),
  fork(watchGetResultsReports),
  fork(watchGetSubmitProgrammeReport),
  fork(watchGetDepartmentProgrammes),
  fork(watchGetResultSummaryReport),
  fork(watchDownloadResultReport),
];
