import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { admissionFormActions, tabActions } from '../../actions';

function* getAdmissionFormData(actions) {
  try {
    const response = yield axios({
      url: '/admissions/admission-forms',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: admissionFormActions.GET_ADMISSION_FORM_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: admissionFormActions.GET_ADMISSION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* createAdmissionForm(actions) {
  try {
    const response = yield axios({
      url: '/admissions/admission-forms',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: admissionFormActions.CREATE_ADMISSION_FORM_SUCCESS,
      data: response,
    });
    yield put({
      type: admissionFormActions.GET_ADMISSION_FORM_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: admissionFormActions.CREATE_ADMISSION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* getSingleAdmissionForm(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-forms/${actions.admissionFormId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: admissionFormActions.GET_SINGLE_ADMISSION_FORM_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: admissionFormActions.GET_SINGLE_ADMISSION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* updateAdmissionForm(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-forms/${actions.admissionFormId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: admissionFormActions.UPDATE_ADMISSION_FORM_SUCCESS,
      data: response,
    });
    yield put({
      type: admissionFormActions.GET_ADMISSION_FORM_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: admissionFormActions.UPDATE_ADMISSION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* deleteAdmissionForm(actions) {
  try {
    const response = yield axios({
      url: `/admissions/admission-forms/${actions.admissionFormId}`,
      method: 'DELETE',
    });
    yield put({
      type: admissionFormActions.DELETE_ADMISSION_FORM_SUCCESS,
      data: response,
    });
    yield put({
      type: admissionFormActions.GET_ADMISSION_FORM_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admissionFormActions.DELETE_ADMISSION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdmissionFormData() {
  yield takeLatest(
    admissionFormActions.GET_ADMISSION_FORM_REQUEST,
    getAdmissionFormData
  );
}

function* watchCreateAdmissionForm() {
  yield takeLatest(
    admissionFormActions.CREATE_ADMISSION_FORM_REQUEST,
    createAdmissionForm
  );
}

function* watchGetSingleAdmissionForm() {
  yield takeLatest(
    admissionFormActions.GET_SINGLE_ADMISSION_FORM_REQUEST,
    getSingleAdmissionForm
  );
}

function* watchUpdateAdmissionForm() {
  yield takeLatest(
    admissionFormActions.UPDATE_ADMISSION_FORM_REQUEST,
    updateAdmissionForm
  );
}

function* watchDeleteAdmissionForm() {
  yield takeLatest(
    admissionFormActions.DELETE_ADMISSION_FORM_REQUEST,
    deleteAdmissionForm
  );
}

export default [
  fork(watchCreateAdmissionForm),
  fork(watchGetAdmissionFormData),
  fork(watchUpdateAdmissionForm),
  fork(watchGetSingleAdmissionForm),
  fork(watchDeleteAdmissionForm),
];
