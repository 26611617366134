import { systemSchemeActions } from '../../actions';
import systemManager from '../../initialState/system-mgt';

const systemSchemes = (state = systemManager.systemSchemes, actions) => {
  switch (actions.type) {
    case systemSchemeActions.GET_SYSTEM_SCHEMES_REQUEST:
      return {
        ...state,
        gettingSystemSchemes: true,
        getSystemSchemeError: {},
      };
    case systemSchemeActions.GET_SYSTEM_SCHEMES_SUCCESS:
      return {
        ...state,
        gettingSystemSchemes: false,
        entrySchemes: actions.data,
      };
    case systemSchemeActions.GET_SYSTEM_SCHEMES_ERROR:
      return {
        ...state,
        gettingSystemSchemes: false,
        getSystemSchemeError: actions.error,
      };

    case systemSchemeActions.ON_SUBMIT_SCHEMES_REQUEST:
      return {
        ...state,
        submittingEntrySchemes: true,
        submitEntrySchemeError: {},
      };
    case systemSchemeActions.ON_SUBMIT_SCHEMES_SUCCESS:
      return {
        ...state,
        submittingEntrySchemes: false,
        submitEntrySchemeResponse: actions.data,
      };
    case systemSchemeActions.ON_SUBMIT_SCHEMES_ERROR:
      return {
        ...state,
        submittingEntrySchemes: false,
        submitEntrySchemeError: actions.error,
      };

    case systemSchemeActions.SHOW_SUBMIT_SCHEME_MODAL:
      return {
        ...state,
        showSubmitModal: actions.payload,
      };

    default:
      return state;
  }
};

export default systemSchemes;
