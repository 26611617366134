const faculty = {
  GET_FACULTIES_REQUEST: 'GET_FACULTIES_REQUEST',
  GET_FACULTIES_SUCCESS: 'GET_FACULTIES_SUCCESS',
  GET_FACULTIES_ERROR: 'GET_FACULTIES_ERROR',

  CREATE_FACULTY_REQUEST: 'CREATE_FACULTY_REQUEST',
  CREATE_FACULTY_SUCCESS: 'CREATE_FACULTY_SUCCESS',
  CREATE_FACULTY_ERROR: 'CREATE_FACULTY_ERROR',

  DELETE_FACULTY_REQUEST: 'DELETE_FACULTY_REQUEST',
  DELETE_FACULTY_SUCCESS: 'DELETE_FACULTY_SUCCESS',
  DELETE_FACULTY_ERROR: 'DELETE_FACULTY_ERROR',

  UPDATE_FACULTY_REQUEST: 'UPDATE_FACULTY_REQUEST',
  UPDATE_FACULTY_SUCCESS: 'UPDATE_FACULTY_SUCCESS',
  UPDATE_FACULTY_ERROR: 'UPDATE_FACULTY_ERROR',

  DOWNLOAD_FACULTY_TEMPLATE_REQUEST: 'DOWNLOAD_FACULTY_TEMPLATE_REQUEST',
  DOWNLOAD_FACULTY_TEMPLATE_SUCCESS: 'DOWNLOAD_FACULTY_TEMPLATE_SUCCESS',
  DOWNLOAD_FACULTY_TEMPLATE_ERROR: 'DOWNLOAD_FACULTY_TEMPLATE_ERROR',

  UPLOAD_FACULTY_TEMPLATE_REQUEST: 'UPLOAD_FACULTY_TEMPLATE_REQUEST',
  UPLOAD_FACULTY_TEMPLATE_SUCCESS: 'UPLOAD_FACULTY_TEMPLATE_SUCCESS',
  UPLOAD_FACULTY_TEMPLATE_ERROR: 'UPLOAD_FACULTY_TEMPLATE_ERROR',

  getFaculties: (data) => ({
    type: faculty.GET_FACULTIES_REQUEST,
    data,
  }),

  createFaculty: (data) => ({
    type: faculty.CREATE_FACULTY_REQUEST,
    data,
  }),

  deleteFaculty: (id) => ({
    type: faculty.DELETE_FACULTY_REQUEST,
    id,
  }),

  updateFaculty: (id, data) => ({
    type: faculty.UPDATE_FACULTY_REQUEST,
    id,
    data,
  }),

  downloadFacultyTemplate: () => ({
    type: faculty.DOWNLOAD_FACULTY_TEMPLATE_REQUEST,
  }),

  uploadFacultyTemplate: (data) => ({
    type: faculty.UPLOAD_FACULTY_TEMPLATE_REQUEST,
    data,
  }),
};

export default faculty;
