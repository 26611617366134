import {
  difference,
  find,
  filter,
  forEach,
  includes,
  isArray,
  isEmpty,
  map,
  orderBy,
  toUpper,
  uniqBy,
  uniq,
} from 'lodash';

export const getSubRoute = (subRoutes, app, authUserApps) => {
  let entrySubRoute = null;

  // TODO: to be removed once Permission is fully allowed to enforce
  const permittedSubRoutes = [];

  if (!isEmpty(subRoutes)) {
    const currentRoleApp = authUserApps.find(
      (authUserApp) => authUserApp.app_code === app.app_code
    );

    subRoutes.forEach((subRoute) => {
      if (currentRoleApp) {
        const appPermissions = map(
          currentRoleApp.app_functions,
          'function_name'
        );
        if (!isEmpty(subRoute.permissions)) {
          if (difference(subRoute.permissions, appPermissions).length === 0) {
            permittedSubRoutes.push(subRoute);
          }
        } else permittedSubRoutes.push(subRoute);
      }
    });

    if (!isEmpty(permittedSubRoutes)) {
      const orderedRoutes = orderBy(permittedSubRoutes, 'order');

      const { 0: firstSubRoute } = orderedRoutes;

      entrySubRoute = firstSubRoute;
    } else if (app.app_code === 'MY_PROFILE' && !isEmpty(subRoutes)) {
      const { 0: firstSubRoute } = subRoutes;
      entrySubRoute = firstSubRoute;
    }
  }

  return entrySubRoute;
};

export const userCan = (permissions, appCodes, currentRole) => {
  if (currentRole && isArray(currentRole?.role_apps)) {
    if (isArray(appCodes)) {
      const findPermissions = filter(appCodes, (appCode) => {
        const currentApp = find(
          currentRole?.role_apps,
          (app) => toUpper(app.app_code) === toUpper(appCode)
        );

        if (!isEmpty(currentApp?.app_functions)) {
          const appPermissions = map(currentApp.app_functions, 'function_name');
          const funcDifferences = difference(permissions, appPermissions);

          if (funcDifferences.length === 0) {
            return true;
          }
        }
        return false;
      });

      if (!isEmpty(findPermissions)) return true;
    }
  }

  return false;
};

export const userBoundSelectOptions = (
  userRoleBounds,
  currentRole,
  currentUser
) => {
  const responseData = {
    campuses: [],
    departments: [],
    faculties: [],
    programmes: [],
    colleges: [],
  };

  if (isArray(userRoleBounds) && !isEmpty(currentRole)) {
    const findUserRoleBound = find(
      userRoleBounds,
      (userRoleBound) =>
        parseInt(userRoleBound.roleId, 10) === parseInt(currentRole.role_id, 10)
    );

    if (!isEmpty(findUserRoleBound?.data) && isArray(findUserRoleBound.data)) {
      forEach(findUserRoleBound?.data, (bound) => {
        if (
          bound.bound_level === 'CAMPUSES' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.campuses = uniqBy(
            orderBy(
              map(bound.campuses, (campus) => ({
                label: campus.campus,
                value: campus.campus_id,
              })),
              ['label']
            ),
            'label'
          );
        }

        if (
          bound.bound_level === 'DEPARTMENTS' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.departments = uniqBy(
            orderBy(
              map(bound.departments, (data) => ({
                label: `${data.department_code} - ${data.department_title}`,
                value: data.department_id,
              })),
              ['label']
            ),
            'label'
          );
        }

        if (
          bound.bound_level === 'COLLEGES' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.colleges = uniqBy(
            orderBy(
              map(bound.colleges, (data) => ({
                label: `${data.college_code} - ${data.college_title}`,
                value: data.college_id,
              })),
              ['label']
            ),
            'label'
          );
        }

        if (
          (bound.bound_level === 'SCHOOLS' ||
            bound.bound_level === 'FACULTIES') &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.faculties = uniqBy(
            orderBy(
              map(bound.faculties, (data) => ({
                label: `${data.faculty_code} - ${data.faculty_title}`,
                value: data.faculty_id,
              })),
              ['label']
            ),
            'label'
          );
        }

        if (
          bound.bound_level === 'PROGRAMMES' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.programmes = uniqBy(
            orderBy(
              map(bound.programmes, (data) => ({
                label: `${data.programme_code} - ${data.programme_title}`,
                value: data.programme_id,
              })),
              ['label']
            ),
            'label'
          );
        }
      });
    }
  }
  return responseData;
};

export const getUserBoundValues = (
  userRoleBounds,
  currentRole,
  currentUser
) => {
  const responseData = {
    campuses: [],
    departments: [],
    faculties: [],
    programmes: [],
    colleges: [],
  };

  if (isArray(userRoleBounds) && !isEmpty(currentRole)) {
    const findUserRoleBound = find(
      userRoleBounds,
      (userRoleBound) =>
        parseInt(userRoleBound.roleId, 10) === parseInt(currentRole.role_id, 10)
    );

    if (!isEmpty(findUserRoleBound?.data) && isArray(findUserRoleBound.data)) {
      forEach(findUserRoleBound.data, (bound) => {
        if (
          bound.bound_level === 'CAMPUSES' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.campuses = uniqBy(
            orderBy(bound.campuses, ['campus']),
            'campus_id'
          );
        }

        if (
          bound.bound_level === 'DEPARTMENTS' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.departments = uniqBy(
            orderBy(bound.departments, ['department_title']),
            'department_id'
          );
        }

        if (
          bound.bound_level === 'COLLEGES' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.colleges = uniqBy(
            orderBy(bound.colleges, ['college_title']),
            'college_id'
          );
        }

        if (
          (bound.bound_level === 'SCHOOLS' ||
            bound.bound_level === 'FACULTIES') &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.faculties = uniqBy(
            orderBy(bound.faculties, ['faculty_title']),
            'faculty_id'
          );
        }

        if (
          bound.bound_level === 'PROGRAMMES' &&
          parseInt(bound.user_id, 10) === parseInt(currentUser?.id, 10)
        ) {
          responseData.programmes = uniqBy(
            orderBy(bound.programmes, ['programme_title']),
            'programme_id'
          );
        }
      });
    }
  }
  return responseData;
};

export const getGroupedColleges = (
  groupedColleges,
  userRoleBounds,
  currentRole,
  currentUser
) => {
  let newData = [];

  const findUserRoleBound = find(
    userRoleBounds,
    (userRoleBound) =>
      parseInt(userRoleBound.roleId, 10) === parseInt(currentRole.role_id, 10)
  );

  if (!isEmpty(findUserRoleBound?.data)) {
    const userData = getUserBoundValues(
      userRoleBounds,
      currentRole,
      currentUser
    );

    const facultyIds = map(userData.faculties, (e) =>
      parseInt(e.faculty_id, 10)
    );
    const departmentIds = map(userData.departments, (e) =>
      parseInt(e.department_id, 10)
    );

    const collegeData = userData.colleges;

    if (isEmpty(collegeData)) {
      return [];
    }

    const collegeIds = map(collegeData, (e) => parseInt(e.college_id, 10));

    newData = map(
      filter(groupedColleges, (college) =>
        includes(collegeIds, parseInt(college.college_id, 10))
      ),
      (college) => ({
        ...college,
        faculties: map(
          filter(college.faculties, (faculty) =>
            includes(facultyIds, parseInt(faculty.faculty_id, 10))
          ),
          (faculty) => ({
            ...faculty,
            departments: filter(faculty.departments, (department) =>
              includes(departmentIds, parseInt(department.department_id, 10))
            ),
          })
        ),
      })
    );
  }

  return newData;
};

export const getGroupedFaculties = (
  groupedFaculties,
  userRoleBounds,
  currentRole,
  currentUser
) => {
  let newData = [];

  const userData = getUserBoundValues(userRoleBounds, currentRole, currentUser);
  const departmentIds = map(userData.departments, (e) =>
    parseInt(e.department_id, 10)
  );
  const facultyData = userData.faculties;

  if (isEmpty(facultyData)) {
    return [];
  }

  const facultyIds = map(facultyData, (e) => parseInt(e.faculty_id, 10));

  newData = map(
    filter(groupedFaculties, (faculty) =>
      includes(facultyIds, parseInt(faculty.faculty_id, 10))
    ),
    (faculty) => ({
      ...faculty,
      departments: filter(faculty.departments, (department) =>
        includes(departmentIds, parseInt(department.department_id, 10))
      ),
    })
  );

  return newData;
};

export const getGroupedDepartments = (
  groupedDepartments,
  userRoleBounds,
  currentRole,
  currentUser
) => {
  let newData = [];

  const userData = getUserBoundValues(userRoleBounds, currentRole, currentUser);

  if (isEmpty(userData.departments)) {
    return [];
  }

  const departmentIds = map(userData.departments, (e) =>
    parseInt(e.department_id, 10)
  );

  newData = map(
    filter(groupedDepartments, (department) =>
      includes(departmentIds, parseInt(department.department_id, 10))
    )
  );

  return newData;
};

export const getDepartmentProgrammes = (
  groupedDepartments,
  userRoleBounds,
  currentRole,
  currentUser
) => {
  let newData = [];

  const userData = getUserBoundValues(userRoleBounds, currentRole, currentUser);

  if (isEmpty(userData.departments)) {
    return [];
  }

  const departmentIds = uniq(
    map(userData.departments, (e) => parseInt(e.department_id, 10))
  );

  const programmeIds = uniq(
    map(userData.programmes, (e) => parseInt(e.programme_id, 10))
  );

  newData = map(
    filter(groupedDepartments, (department) =>
      includes(departmentIds, parseInt(department.department_id, 10))
    ),
    (department) => ({
      ...department,
      programmes: filter(department.programmes, (programme) =>
        includes(
          programmeIds,
          parseInt(programme.id || programme.programme_id, 10)
        )
      ),
    })
  );

  return newData;
};

export const getUserDepartments = (
  groupedDepartments,
  userRoleBounds,
  currentRole,
  currentUser
) => {
  let newData = [];

  const userData = getUserBoundValues(userRoleBounds, currentRole, currentUser);

  if (isEmpty(userData.departments)) {
    return [];
  }

  const departmentIds = uniq(
    map(userData?.departments, (e) => parseInt(e.department_id, 10))
  );

  newData = filter(groupedDepartments, (department) =>
    includes(
      departmentIds,
      parseInt(department.department_id || department.id, 10)
    )
  );

  return newData;
};

export const getUserProgrammes = (
  programmes,
  userRoleBounds,
  currentRole,
  currentUser
) => {
  let newData = [];
  const userData = getUserBoundValues(userRoleBounds, currentRole, currentUser);

  if (isEmpty(userData.programmes)) {
    return [];
  }

  const programmeIds = uniq(
    map(userData.programmes, (e) => parseInt(e.programme_id, 10))
  );

  newData = filter(programmes, (programme) =>
    includes(programmeIds, parseInt(programme.programme_id || programme.id, 10))
  );

  return newData;
};
