const batchResultActions = {
  GET_BATCH_RESULTS_REQUEST: 'GET_BATCH_RESULTS_REQUEST',
  GET_BATCH_RESULTS_SUCCESS: 'GET_BATCH_RESULTS_SUCCESS',
  GET_BATCH_RESULTS_ERROR: 'GET_BATCH_RESULTS_ERROR',

  GET_BATCH_DETAILS_REQUEST: 'GET_BATCH_DETAILS_REQUEST',
  GET_BATCH_DETAILS_SUCCESS: 'GET_BATCH_DETAILS_SUCCESS',
  GET_BATCH_DETAILS_ERROR: 'GET_BATCH_DETAILS_ERROR',

  GET_BATCH_APPROVAL_RESULTS_REQUEST: 'GET_BATCH_APPROVAL_RESULTS_REQUEST',
  GET_BATCH_APPROVAL_RESULTS_SUCCESS: 'GET_BATCH_APPROVAL_RESULTS_SUCCESS',
  GET_BATCH_APPROVAL_RESULTS_ERROR: 'GET_BATCH_APPROVAL_RESULTS_ERROR',

  APPROVE_BATCH_RESULTS_REQUEST: 'APPROVE_BATCH_RESULTS_REQUEST',
  APPROVE_BATCH_RESULTS_SUCCESS: 'APPROVE_BATCH_RESULTS_SUCCESS',
  APPROVE_BATCH_RESULTS_ERROR: 'APPROVE_BATCH_RESULTS_ERROR',

  APPROVE_SELECTED_RESULTS_REQUEST: 'APPROVE_SELECTED_RESULTS_REQUEST',
  APPROVE_SELECTED_RESULTS_SUCCESS: 'APPROVE_SELECTED_RESULTS_SUCCESS',
  APPROVE_SELECTED_RESULTS_ERROR: 'APPROVE_SELECTED_RESULTS_ERROR',

  SUBMIT_BATCH_RESULTS_REQUEST: 'SUBMIT_BATCH_RESULTS_REQUEST',
  SUBMIT_BATCH_RESULTS_SUCCESS: 'SUBMIT_BATCH_RESULTS_SUCCESS',
  SUBMIT_BATCH_RESULTS_ERROR: 'SUBMIT_BATCH_RESULTS_ERROR',

  SEARCH_RESULT_BATCH_REQUEST: 'SEARCH_RESULT_BATCH_REQUEST',
  SEARCH_RESULT_BATCH_SUCCESS: 'SEARCH_RESULT_BATCH_SUCCESS',
  SEARCH_RESULT_BATCH_ERROR: 'SEARCH_RESULT_BATCH_ERROR',

  SUBMIT_SELECTED_RESULTS_REQUEST: 'SUBMIT_SELECTED_RESULTS_REQUEST',
  SUBMIT_SELECTED_RESULTS_SUCCESS: 'SUBMIT_SELECTED_RESULTS_SUCCESS',
  SUBMIT_SELECTED_RESULTS_ERROR: 'SUBMIT_SELECTED_RESULTS_ERROR',

  DELETE_BATCH_RESULTS_REQUEST: 'DELETE_BATCH_RESULTS_REQUEST',
  DELETE_BATCH_RESULTS_SUCCESS: 'DELETE_BATCH_RESULTS_SUCCESS',
  DELETE_BATCH_RESULTS_ERROR: 'DELETE_BATCH_RESULTS_ERROR',

  UPDATE_BATCH_DETAILS_REQUEST: 'UPDATE_BATCH_DETAILS_REQUEST',
  UPDATE_BATCH_DETAILS_SUCCESS: 'UPDATE_BATCH_DETAILS_SUCCESS',
  UPDATE_BATCH_DETAILS_ERROR: 'UPDATE_BATCH_DETAILS_ERROR',

  DELETE_BATCH_DETAILS_REQUEST: 'DELETE_BATCH_DETAILS_REQUEST',
  DELETE_BATCH_DETAILS_SUCCESS: 'DELETE_BATCH_DETAILS_SUCCESS',
  DELETE_BATCH_DETAILS_ERROR: 'DELETE_BATCH_DETAILS_ERROR',

  DOWNLOAD_BATCH_RESULTS_REQUEST: 'DOWNLOAD_BATCH_RESULTS_REQUEST',
  DOWNLOAD_BATCH_RESULTS_SUCCESS: 'DOWNLOAD_BATCH_RESULTS_SUCCESS',
  DOWNLOAD_BATCH_RESULTS_ERROR: 'DOWNLOAD_BATCH_RESULTS_ERROR',

  SET_BATCH_RESULTS_PARAMS: 'SET_BATCH_RESULTS_PARAMS',
  SET_RESULT_TO_EDIT: 'SET_RESULT_TO_EDIT',

  getBatchResults: (params) => ({
    type: batchResultActions.GET_BATCH_RESULTS_REQUEST,
    params,
  }),

  getBatchDetails: (params) => ({
    type: batchResultActions.GET_BATCH_DETAILS_REQUEST,
    params,
  }),

  getBatchApprovalResults: (params) => ({
    type: batchResultActions.GET_BATCH_APPROVAL_RESULTS_REQUEST,
    params,
  }),

  deleteBatchResult: (batchId, params, data) => ({
    type: batchResultActions.DELETE_BATCH_RESULTS_REQUEST,
    batchId,
    params,
    data,
  }),

  updateBatchDetails: (batchId, data, params) => ({
    type: batchResultActions.UPDATE_BATCH_DETAILS_REQUEST,
    batchId,
    data,
    params,
  }),

  deleteResultDetail: (batchId, params, data) => ({
    type: batchResultActions.DELETE_BATCH_DETAILS_REQUEST,
    batchId,
    params,
    data,
  }),

  searchResultBatch: (params) => ({
    type: batchResultActions.SEARCH_RESULT_BATCH_REQUEST,
    params,
  }),

  setBatchParams: (params) => ({
    type: batchResultActions.SET_BATCH_RESULTS_PARAMS,
    params,
  }),

  setResultToEdit: (data) => ({
    type: batchResultActions.SET_RESULT_TO_EDIT,
    data,
  }),

  approveBatchResults: (data) => ({
    type: batchResultActions.APPROVE_BATCH_RESULTS_REQUEST,
    data,
  }),

  approveSelectedResults: (data) => ({
    type: batchResultActions.APPROVE_SELECTED_RESULTS_REQUEST,
    data,
  }),

  submitBatchResultsForApproval: (data) => ({
    type: batchResultActions.SUBMIT_BATCH_RESULTS_REQUEST,
    data,
  }),

  submitSelectedResultsForApproval: (data) => ({
    type: batchResultActions.SUBMIT_SELECTED_RESULTS_REQUEST,
    data,
  }),

  downloadBatchResults: (batchId) => ({
    type: batchResultActions.DOWNLOAD_BATCH_RESULTS_REQUEST,
    batchId,
  }),
};

export default batchResultActions;
