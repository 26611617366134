import update from 'immutability-helper';
import { runningAdmissionProgrammeActions } from '../../actions';
import initialState from '../../initialState';

function runningAdmissionProgramme(
  state = initialState.runningAdmissionProgramme,
  actions
) {
  switch (actions.type) {
    case runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        runningAdmissionProgrammes: actions.data.runningAdmissionProgrammes,
        loading: false,
      };
    case runningAdmissionProgrammeActions.GET_RUNNING_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_REQUEST:
      return {
        ...state,
        specialFeesError: {},
        gettingSpecialFees: true,
      };
    case runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_SUCCESS: {
      const { specialFees } = state;
      const { runningAdmissionProgrammeId, data } = actions;
      const findDataIndex = specialFees.findIndex(
        (specialFee) =>
          specialFee.running_admission_programme_id ===
          runningAdmissionProgrammeId
      );

      const newData = {
        data,
        running_admission_programme_id: runningAdmissionProgrammeId,
      };

      let newState = update(state, {
        specialFees: {
          $push: [newData],
        },
      });
      if (findDataIndex !== -1) {
        newState = update(state, {
          specialFees: { [findDataIndex]: { $set: newData } },
        });
      }

      return {
        ...newState,
        gettingSpecialFees: false,
      };
    }
    case runningAdmissionProgrammeActions.GET_PROGRAMME_SPECIAL_FEES_ERROR:
      return {
        ...state,
        specialFeesError: actions.error,
        gettingSpecialFees: false,
      };

    case runningAdmissionProgrammeActions.CREATE_PROGRAMME_SPECIAL_FEES_REQUEST:
      return {
        ...state,
        createSpecialFeesError: {},
        creatingSpecialFees: true,
      };
    case runningAdmissionProgrammeActions.CREATE_PROGRAMME_SPECIAL_FEES_SUCCESS: {
      const { data } = actions;

      return {
        ...state,
        createdSpecialFees: data,
        creatingSpecialFees: false,
      };
    }
    case runningAdmissionProgrammeActions.CREATE_PROGRAMME_SPECIAL_FEES_ERROR:
      return {
        ...state,
        createSpecialFeesError: actions.error,
        creatingSpecialFees: false,
      };

    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SPECIAL_FEES_REQUEST:
      return {
        ...state,
        updateSpecialFeesError: {},
        updatingSpecialFeesId: actions.specialFeeId,
        updatingSpecialFees: true,
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SPECIAL_FEES_SUCCESS: {
      const { data } = actions;

      return {
        ...state,
        updatedSpecialFees: data,
        updatingSpecialFees: false,
      };
    }
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SPECIAL_FEES_ERROR:
      return {
        ...state,
        updateSpecialFeesError: actions.error,
        updatingSpecialFees: false,
      };

    case runningAdmissionProgrammeActions.DELETE_PROGRAMME_SPECIAL_FEES_REQUEST:
      return {
        ...state,
        deleteSpecialFeesError: {},
        deletingSpecialFeesId: actions.specialFeeId,
        deletingSpecialFees: true,
      };
    case runningAdmissionProgrammeActions.DELETE_PROGRAMME_SPECIAL_FEES_SUCCESS: {
      const { data } = actions;

      return {
        ...state,
        deletedSpecialFees: data,
        deletingSpecialFees: false,
      };
    }
    case runningAdmissionProgrammeActions.DELETE_PROGRAMME_SPECIAL_FEES_ERROR:
      return {
        ...state,
        deleteSpecialFeesError: actions.error,
        deletingSpecialFees: false,
      };

    case runningAdmissionProgrammeActions.CREATE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        created: {},
      };
    case runningAdmissionProgrammeActions.CREATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case runningAdmissionProgrammeActions.CREATE_RUNNING_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case runningAdmissionProgrammeActions.UPDATE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updated: {},
      };
    case runningAdmissionProgrammeActions.UPDATE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: actions.data.runningAdmissionProgramme,
      };
    case runningAdmissionProgrammeActions.UPDATE_RUNNING_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case runningAdmissionProgrammeActions.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        gettingOne: true,
        getOneError: {},
        runningAdmissionProgramme: {},
      };
    case runningAdmissionProgrammeActions.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        runningAdmissionProgramme: actions.data.runningAdmissionProgramme,
      };
    case runningAdmissionProgrammeActions.GET_SINGLE_RUNNING_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case runningAdmissionProgrammeActions.DELETE_RUNNING_ADMISSION_PROGRAMME_REQUEST:
      return {
        ...state,
        deleteError: {},
        deletingProgramme: true,
        runningAdmissionProgrammeId: actions.runningAdmissionProgrammeId,
        deleteResponse: {},
      };
    case runningAdmissionProgrammeActions.DELETE_RUNNING_ADMISSION_PROGRAMME_SUCCESS:
      return {
        ...state,
        deletingProgramme: false,
        deleteResponse: actions.data,
      };
    case runningAdmissionProgrammeActions.DELETE_RUNNING_ADMISSION_PROGRAMME_ERROR:
      return {
        ...state,
        deletingProgramme: false,
        deleteError: actions.error,
      };

    case runningAdmissionProgrammeActions.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadAdmissionsError: {},
        uploadingAdmissions: true,
      };
    case runningAdmissionProgrammeActions.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingAdmissions: false,
        uploadAdmissionsResponse: actions.data,
      };
    case runningAdmissionProgrammeActions.UPLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingAdmissions: false,
        uploadAdmissionsError: actions.error,
      };

    case runningAdmissionProgrammeActions.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadAdmissionsError: {},
        downloadingAdmissions: true,
      };
    case runningAdmissionProgrammeActions.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadingAdmissions: false,
        downloadAdmissionsResponse: actions.data,
      };
    case runningAdmissionProgrammeActions.DOWNLOAD_MANAGE_PROGRAMMES_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingAdmissions: false,
        downloadAdmissionsError: actions.error,
      };

    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_STATUS_REQUEST:
      return {
        ...state,
        updateProgrammeStatusError: {},
        updatingProgrammeStatus: true,
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_STATUS_SUCCESS:
      return {
        ...state,
        updatingProgrammeStatus: false,
        updatedProgrammeStatus: actions.data,
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_STATUS_ERROR:
      return {
        ...state,
        updatingProgrammeStatus: false,
        updateProgrammeStatusError: actions.error,
      };

    case 'SET_SELECTED_RUNNING_PROGRAMME':
      return {
        ...state,
        selectedRunningProgramme: actions.payload,
      };

    case runningAdmissionProgrammeActions.GET_PROGRAMME_WEIGHTING_CRITERIA_REQUEST:
      return {
        ...state,
        fetchingWeightingCriteria: true,
        fetchWeightingCriteriaError: {},
      };
    case runningAdmissionProgrammeActions.GET_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS:
      return {
        ...state,
        fetchingWeightingCriteria: false,
        fetchedWeightingCriteria: actions.data,
      };
    case runningAdmissionProgrammeActions.GET_PROGRAMME_WEIGHTING_CRITERIA_ERROR:
      return {
        ...state,
        fetchingWeightingCriteria: false,
        fetchWeightingCriteriaError: actions.error,
      };

    case runningAdmissionProgrammeActions.GET_PROGRAMME_SELECTION_CRITERIA_REQUEST:
      return {
        ...state,
        fetchingSelectionCriteria: true,
        fetchSelectionCriteriaError: {},
      };
    case runningAdmissionProgrammeActions.GET_PROGRAMME_SELECTION_CRITERIA_SUCCESS:
      return {
        ...state,
        fetchingSelectionCriteria: false,
        fetchedSelectionCriteria: actions.data,
      };
    case runningAdmissionProgrammeActions.GET_PROGRAMME_SELECTION_CRITERIA_ERROR:
      return {
        ...state,
        fetchingSelectionCriteria: false,
        fetchSelectionCriteriaError: actions.error,
      };

    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_REQUEST:
      return {
        ...state,
        updatingWeightingCriteria: true,
        updateWeightingCriteriaError: {},
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_SUCCESS:
      return {
        ...state,
        updatingWeightingCriteria: false,
        updatedWeightingCriteria: actions.data,
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_WEIGHTING_CRITERIA_ERROR:
      return {
        ...state,
        updatingWeightingCriteria: false,
        updateWeightingCriteriaError: actions.error,
      };

    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SELECTION_CRITERIA_REQUEST:
      return {
        ...state,
        updatingSelectionCriteria: true,
        updateSelectionCriteriaError: {},
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SELECTION_CRITERIA_SUCCESS:
      return {
        ...state,
        updatingSelectionCriteria: false,
        updatedSelectionCriteria: actions.data,
      };
    case runningAdmissionProgrammeActions.UPDATE_PROGRAMME_SELECTION_CRITERIA_ERROR:
      return {
        ...state,
        updatingSelectionCriteria: false,
        updateSelectionCriteriaError: actions.error,
      };

    case runningAdmissionProgrammeActions.RUN_SELECTION_REQUEST:
      return {
        ...state,
        runningSelection: true,
        runningSelectionSuccess: {},
        runningSelectionError: {},
        runningWeightingError: {},
      };
    case runningAdmissionProgrammeActions.RUN_SELECTION_SUCCESS:
      return {
        ...state,
        runningSelection: false,
        runningSelectionSuccess: actions.data,
      };
    case runningAdmissionProgrammeActions.RUN_SELECTION_ERROR:
      return {
        ...state,
        runningSelection: false,
        runningSelectionError: actions.error,
      };

    case runningAdmissionProgrammeActions.RUN_BULK_WEIGHTING_REQUEST:
      return {
        ...state,
        runningBulkWeighting: true,
        bulkWeightingSuccess: {},
        bulkWeightingError: {},
      };
    case runningAdmissionProgrammeActions.RUN_BULK_WEIGHTING_SUCCESS:
      return {
        ...state,
        runningBulkWeighting: false,
        bulkWeightingSuccess: actions.data,
      };
    case runningAdmissionProgrammeActions.RUN_BULK_WEIGHTING_ERROR:
      return {
        ...state,
        runningBulkWeighting: false,
        bulkWeightingError: actions.error,
      };

    case runningAdmissionProgrammeActions.RUN_WEIGHTING_REQUEST:
      return {
        ...state,
        runningWeighting: true,
        runningWeightingSuccess: {},
        runningWeightingError: {},
        runningSelectionError: {},
      };
    case runningAdmissionProgrammeActions.RUN_WEIGHTING_SUCCESS:
      return {
        ...state,
        runningWeighting: false,
        runningWeightingSuccess: actions.data,
      };
    case runningAdmissionProgrammeActions.RUN_WEIGHTING_ERROR:
      return {
        ...state,
        runningWeighting: false,
        runningWeightingError: actions.error,
      };

    default:
      return state;
  }
}

export default runningAdmissionProgramme;
