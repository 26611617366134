import { pendingExemptedInvoiceActions } from '../../actions';
import initialState from '../../initialState';

const pendingExemptedInvoice = (
  state = initialState.pendingExemptedInvoice,
  actions
) => {
  switch (actions.type) {
    case pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };

    case pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_SUCCESS:
      return {
        ...state,
        pendingExemptedInvoices: actions.data,
        error: {},
        loading: false,
      };

    case pendingExemptedInvoiceActions.GET_PENDING_EXEMPTED_INVOICE_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case pendingExemptedInvoiceActions.APPROVE_PENDING_EXEMPTED_INVOICE_REQUEST:
      return {
        ...state,
        approveError: {},
        approving: true,
      };

    case pendingExemptedInvoiceActions.APPROVE_PENDING_EXEMPTED_INVOICE_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };

    case pendingExemptedInvoiceActions.APPROVE_PENDING_EXEMPTED_INVOICE_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    default:
      return state;
  }
};
export default pendingExemptedInvoice;
