import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { studyLevelPolicyActions, tabActions } from '../../actions';

function* getStudyLevelPolicies() {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-pass-mark-policy`,
      method: 'GET',
    });
    yield put({
      type: studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createStudyLevelPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-pass-mark-policy`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studyLevelPolicyActions.CREATE_STUDY_LEVEL_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: studyLevelPolicyActions.CREATE_STUDY_LEVEL_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updatePolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-pass-mark-policy/${actions.policyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: studyLevelPolicyActions.UPDATE_STUDY_LEVEL_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: studyLevelPolicyActions.UPDATE_STUDY_LEVEL_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deletePolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/results-policies/study-level-pass-mark-policy/${actions.policyId}`,
      method: 'DELETE',
    });
    yield put({
      type: studyLevelPolicyActions.DELETE_STUDY_LEVEL_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studyLevelPolicyActions.DELETE_STUDY_LEVEL_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStudyLevelPolicies() {
  yield takeLatest(
    studyLevelPolicyActions.GET_STUDY_LEVEL_POLICIES_REQUEST,
    getStudyLevelPolicies
  );
}

function* watchCreateStudyLevelPolicy() {
  yield takeLatest(
    studyLevelPolicyActions.CREATE_STUDY_LEVEL_POLICY_REQUEST,
    createStudyLevelPolicy
  );
}

function* watchUpdatePolicy() {
  yield takeLatest(
    studyLevelPolicyActions.UPDATE_STUDY_LEVEL_POLICY_REQUEST,
    updatePolicy
  );
}

function* watchDeletePolicy() {
  yield takeLatest(
    studyLevelPolicyActions.DELETE_STUDY_LEVEL_POLICY_REQUEST,
    deletePolicy
  );
}

export default [
  fork(watchGetStudyLevelPolicies),
  fork(watchCreateStudyLevelPolicy),
  fork(watchUpdatePolicy),
  fork(watchDeletePolicy),
];
