import update from 'immutability-helper';
import { resultNodeActions } from '../../actions';
import initialState from '../../initialState';

const resultNode = (state = initialState.resultNode, actions) => {
  switch (actions.type) {
    case resultNodeActions.GET_ASSIGNED_COURSES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case resultNodeActions.GET_ASSIGNED_COURSES_SUCCESS:
      return {
        ...state,
        assignedCourses: actions.data,
        loading: false,
      };
    case resultNodeActions.GET_ASSIGNED_COURSES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case resultNodeActions.GET_COURSE_NODES_REQUEST:
      return {
        ...state,
        loadError: {},
        fetching: true,
      };
    case resultNodeActions.GET_COURSE_NODES_SUCCESS:
      return {
        ...state,
        courseNodes: actions.data,
        fetching: false,
      };
    case resultNodeActions.GET_COURSE_NODES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        fetching: false,
      };

    case resultNodeActions.GET_NODE_DETAILS_REQUEST:
      return {
        ...state,
        loadError: {},
        fetching: true,
      };
    case resultNodeActions.GET_NODE_DETAILS_SUCCESS:
      return {
        ...state,
        nodeDetails: actions.data,
        fetching: false,
      };
    case resultNodeActions.GET_NODE_DETAILS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        fetching: false,
      };

    case resultNodeActions.CREATE_RESULT_NODES_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case resultNodeActions.CREATE_RESULT_NODES_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case resultNodeActions.CREATE_RESULT_NODES_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case resultNodeActions.GET_ASSIGNED_LECTURERS_REQUEST:
      return {
        ...state,
        fetchError: {},
        fetching: true,
      };
    case resultNodeActions.GET_ASSIGNED_LECTURERS_SUCCESS:
      return {
        ...state,
        assignedLecturers: actions.data.course_lecturers,
        fetching: false,
      };
    case resultNodeActions.GET_ASSIGNED_LECTURERS_ERROR:
      return {
        ...state,
        fetchError: actions.error,
        fetching: false,
      };

    case resultNodeActions.DOWNLOAD_NODES_RESULTS_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case resultNodeActions.DOWNLOAD_NODES_RESULTS_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case resultNodeActions.DOWNLOAD_NODES_RESULTS_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
      };
    case resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case resultNodeActions.DOWNLOAD_RESULTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case resultNodeActions.DOWNLOAD_GPA_TEMPLATE_REQUEST:
      return {
        ...state,
        downloading: true,
        downloadError: {},
        downloadSuccess: {},
      };
    case resultNodeActions.DOWNLOAD_GPA_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadSuccess: actions.data,
        downloading: false,
      };
    case resultNodeActions.DOWNLOAD_GPA_TEMPLATE_ERROR:
      return {
        ...state,
        downloading: false,
        downloadError: actions.error,
      };

    case resultNodeActions.UPLOAD_NODES_RESULTS_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case resultNodeActions.UPLOAD_NODES_RESULTS_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case resultNodeActions.UPLOAD_NODES_RESULTS_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case resultNodeActions.UPLOAD_RESULTS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case resultNodeActions.UPLOAD_RESULTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case resultNodeActions.UPLOAD_RESULTS_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case resultNodeActions.UPLOAD_GPA_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadError: {},
      };
    case resultNodeActions.UPLOAD_GPA_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadSuccess: actions.data,
        uploading: false,
      };
    case resultNodeActions.UPLOAD_GPA_TEMPLATE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case resultNodeActions.DELETE_SINGLE_RESULT_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteError: {},
      };
    case resultNodeActions.DELETE_SINGLE_RESULT_SUCCESS:
      return {
        ...state,
        deleteSuccess: actions.data,
        deleting: false,
      };
    case resultNodeActions.DELETE_SINGLE_RESULT_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case resultNodeActions.UPDATE_SINGLE_RESULT_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case resultNodeActions.UPDATE_SINGLE_RESULT_SUCCESS:
      return {
        ...state,
        updateSuccess: actions.data,
        updating: false,
      };
    case resultNodeActions.UPDATE_SINGLE_RESULT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_REQUEST:
      return {
        ...state,
        evaluatingRemarks: true,
        evaluateError: {},
      };
    case resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_SUCCESS:
      return {
        ...state,
        evaluatingRemarks: false,
      };
    case resultNodeActions.EVALUATE_COURSE_RESULT_REMARKS_ERROR:
      return {
        ...state,
        evaluatingRemarks: false,
        evaluateError: actions.error,
      };

    case resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        updatingAcademicYears: true,
        updateAYError: {},
        updateAYSuccess: false,
      };
    case resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        updateAYSuccess: true,
        updatingAcademicYears: false,
      };
    case resultNodeActions.UPDATE_SINGLE_RESULT_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        updatingAcademicYears: false,
        updateAYError: actions.error,
      };

    case resultNodeActions.GET_NODE_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        nodeResults: {},
        error: {},
      };
    case resultNodeActions.GET_NODE_RESULTS_SUCCESS:
      return {
        ...state,
        nodeResults: actions.data,
        loading: false,
      };
    case resultNodeActions.GET_NODE_RESULTS_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.error,
      };

    case resultNodeActions.SUBMIT_NODE_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        nodeResults: {},
        error: {},
      };
    case resultNodeActions.SUBMIT_NODE_RESULTS_SUCCESS:
      return {
        ...state,
        nodeResults: actions.data,
        loading: false,
      };
    case resultNodeActions.SUBMIT_NODE_RESULTS_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.error,
      };

    case resultNodeActions.PUBLISH_COURSE_RESULT_REQUEST:
      return {
        ...state,
        publishing: true,
        publishResponse: {},
        publishError: {},
      };
    case resultNodeActions.PUBLISH_COURSE_RESULT_SUCCESS:
      return {
        ...state,
        publishResponse: actions.data,
        publishing: false,
      };
    case resultNodeActions.PUBLISH_COURSE_RESULT_ERROR:
      return {
        ...state,
        publishing: false,
        publishError: actions.error,
      };

    case resultNodeActions.GET_RESULT_PROGRAMME_COURSES_REQUEST:
      return {
        ...state,
        gettingCourses: true,
        resultCourseError: {},
      };
    case resultNodeActions.GET_RESULT_PROGRAMME_COURSES_SUCCESS: {
      const { resultCourses } = state;
      const { context, data } = actions;

      const findContextIndex = resultCourses.findIndex(
        (currentContext) =>
          context.programme_id === currentContext.programme_id &&
          context.academic_year_id === currentContext.academic_year_id &&
          context.semester_id === currentContext.semester_id &&
          context.campus_id === currentContext.campus_id &&
          context.study_year_id === currentContext.study_year_id &&
          context?.category === currentContext?.category
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          resultCourses: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          resultCourses: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingCourses: false,
      };
    }
    case resultNodeActions.GET_RESULT_PROGRAMME_COURSES_ERROR:
      return {
        ...state,
        gettingCourses: false,
        resultCourseError: actions.error,
      };

    case resultNodeActions.GET_COURSE_RESULTS_REQUEST:
      return {
        ...state,
        gettingCourseResults: true,
        courseResultError: {},
      };
    case resultNodeActions.GET_COURSE_RESULTS_SUCCESS: {
      const { courseResults } = state;
      const { context, data } = actions;

      const findContextIndex = courseResults.findIndex(
        (currentContext) =>
          context.programme_id === currentContext.programme_id &&
          context.course_id === currentContext.course_id &&
          context.academic_year_id === currentContext.academic_year_id &&
          context.semester_id === currentContext.semester_id &&
          context.campus_id === currentContext.campus_id &&
          context.study_year_id === currentContext.study_year_id &&
          context.category === currentContext.category
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          courseResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          courseResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingCourseResults: false,
      };
    }

    case resultNodeActions.GET_COURSE_RESULTS_ERROR:
      return {
        ...state,
        gettingCourseResults: false,
        courseResultError: actions.error,
      };

    case resultNodeActions.GET_CONTEXT_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case resultNodeActions.GET_CONTEXT_RESULTS_SUCCESS: {
      const { contextResults } = state;
      const { context, data } = actions;

      const findContextIndex = contextResults.findIndex(
        (currentContext) =>
          parseInt(context.campus_id, 10) ===
            parseInt(currentContext.campus_id, 10) &&
          parseInt(context.academic_year_id, 10) ===
            parseInt(currentContext.academic_year_id, 10) &&
          parseInt(context.semester_id, 10) ===
            parseInt(currentContext.semester_id, 10) &&
          parseInt(context.programme_id, 10) ===
            parseInt(currentContext.programme_id, 10) &&
          parseInt(context.intake_id, 10) ===
            parseInt(currentContext.intake_id, 10) &&
          parseInt(context.study_year_id, 10) ===
            parseInt(currentContext.study_year_id, 10)
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          contextResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          contextResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }

    case resultNodeActions.GET_CONTEXT_RESULTS_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.error,
      };

    case resultNodeActions.SET_SELECTED_NODE:
      return {
        ...state,
        selectedNode: actions.node,
      };

    case resultNodeActions.SET_NODES_CONTEXT:
      return {
        ...state,
        nodesContext: actions.context,
      };

    case resultNodeActions.SET_RESULTS_CONTEXT:
      return {
        ...state,
        resultsContext: actions.context,
      };

    case resultNodeActions.SET_PUBLISH_RESULTS_CONTEXT:
      return {
        ...state,
        publishContext: actions.context,
      };

    case resultNodeActions.SET_DATA_TO_EDIT:
      return {
        ...state,
        dataToEdit: actions.data,
      };

    case resultNodeActions.SET_CONTEXT_PROGRAMME:
      return {
        ...state,
        selectedProgramme: actions.data,
      };

    default:
      return state;
  }
};

export default resultNode;
