import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { retakerPolicyActions, tabActions } from '../../actions';

function* getRetakerPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/retaker-fees-policies',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createRetakerPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/retaker-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: retakerPolicyActions.CREATE_RETAKER_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: retakerPolicyActions.CREATE_RETAKER_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* addRetakerPolicyElement(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/retaker-fees-policies/add-more-elements/${actions.retakerPolicyId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* updateRetakerPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/retaker-fees-policies/${actions.retakerPolicyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: retakerPolicyActions.UPDATE_RETAKER_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: retakerPolicyActions.UPDATE_RETAKER_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteRetakerPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/retaker-fees-policies/${actions.retakerPolicyId}`,
      method: 'DELETE',
    });
    yield put({
      type: retakerPolicyActions.DELETE_RETAKER_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: retakerPolicyActions.DELETE_RETAKER_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteRetakerPolicyElement(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/retaker-fees-policies/delete-elements/${actions.retakerPolicyId}`,
      method: 'DELETE',
      data: actions.elementsToDelete,
    });
    yield put({
      type: retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRetakerPolicies() {
  yield takeLatest(
    retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST,
    getRetakerPolicies
  );
}

function* watchCreateRetakerPolicy() {
  yield takeLatest(
    retakerPolicyActions.CREATE_RETAKER_POLICY_REQUEST,
    createRetakerPolicy
  );
}

function* watchUpdateRetakerPolicy() {
  yield takeLatest(
    retakerPolicyActions.UPDATE_RETAKER_POLICY_REQUEST,
    updateRetakerPolicy
  );
}

function* watchAddRetakerPolicyElements() {
  yield takeLatest(
    retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_REQUEST,
    addRetakerPolicyElement
  );
}

function* watchDeleteRetakerPolicy() {
  yield takeLatest(
    retakerPolicyActions.DELETE_RETAKER_POLICY_REQUEST,
    deleteRetakerPolicy
  );
}

function* watchDeleteRetakerPolicyElement() {
  yield takeLatest(
    retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_REQUEST,
    deleteRetakerPolicyElement
  );
}

export default [
  fork(watchGetRetakerPolicies),
  fork(watchCreateRetakerPolicy),
  fork(watchUpdateRetakerPolicy),
  fork(watchAddRetakerPolicyElements),
  fork(watchDeleteRetakerPolicy),
  fork(watchDeleteRetakerPolicyElement),
];
