import { LinkOutlined, LogoutOutlined } from '@ant-design/icons';
import { Alert, Image, Result, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '../../assets/images/undraw_mail_sent_re_0ofv.svg';
import { ReloadButton, SubmitButton } from '../../components/common';
import { authActions } from '../../config/actions';

function EmailVerificationRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    authUser,
    requesting,
    loggingOut,
    requestEmailSuccess,
    logoutData,
    requestEmailError,
    gettingAuthUser,
  } = useSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (isEmpty(authUser)) navigate('/');
  }, []);

  useEffect(() => {
    if (
      !isEmpty(logoutData) &&
      isEmpty(authUser) &&
      logoutData.server.status === true
    ) {
      navigate('/');
    }
    if (authUser?.email_verified === true) navigate('/');
  }, [logoutData, authUser]);

  useEffect(() => {
    if (!isEmpty(requestEmailError) && requestEmailError.server)
      setErrorMessage(requestEmailError.server.message);

    if (!isEmpty(requestEmailSuccess) && requestEmailSuccess.server) {
      setSuccessMessage(requestEmailSuccess.server.message);
    }
  }, [requestEmailError, requestEmailSuccess]);

  const resendVerificationLink = () => {
    dispatch(
      authActions.requestEmailVerificationLink({ email: authUser.email })
    );
  };

  const logoutUser = () => {
    dispatch(authActions.logoutUser());
  };

  return (
    <div className="d-flex min-vh-100">
      <Helmet>
        <title> Email Verification</title>
      </Helmet>
      <div className="h-100 my-auto p-4 w-100">
        <Row className="justify-content-center">
          <Col md={4}>
            <div className="text-center">
              <Image
                height={80}
                size="large"
                preview={false}
                draggable={false}
                src={EmailIcon}
              />
              <h6 className="my-3 fw-bold text-uppercase text-primary">
                Verify your E-mail address to proceed
              </h6>
              {isEmpty(errorMessage) && isEmpty(successMessage) && (
                <>
                  <Alert
                    type="info"
                    message={
                      <div>
                        <p>
                          Click on the link sent to your email
                          <strong>{` ${authUser.email}`}</strong> to activate
                          your account
                        </p>
                        <p>
                          If your link is EXPIRED or you DID NOT receive the
                          email, click the BUTTON below to request again.
                        </p>
                      </div>
                    }
                  />
                  <Space className="my-3">
                    <SubmitButton
                      loading={requesting}
                      htmlType="button"
                      onClick={resendVerificationLink}
                      text="Resend Verification Link"
                      loadingText="Sending Link..."
                      type="link"
                      iconBefore={<LinkOutlined />}
                    />
                    <SubmitButton
                      htmlType="button"
                      danger
                      type="link"
                      onClick={logoutUser}
                      loading={loggingOut}
                      text="Log out"
                      loadingText="Logging out..."
                      iconBefore={<LogoutOutlined />}
                    />
                  </Space>
                </>
              )}
              {errorMessage && isEmpty(successMessage) && (
                <div className="text-danger">
                  <i className="fa fa-close fa-3x mb-3" />
                  <p>{errorMessage}</p>
                </div>
              )}
              {successMessage && (
                <Result
                  status="success"
                  title="VERIFICATION LINK SENT!"
                  subTitle={successMessage}
                  extra={[
                    <SubmitButton
                      loading={requesting}
                      htmlType="button"
                      onClick={resendVerificationLink}
                      text="Resend Verification Link"
                      loadingText="Sending Link..."
                    />,
                    <SubmitButton
                      className="mt-4 text-uppercase text-sm fw-bold"
                      htmlType="button"
                      danger
                      onClick={logoutUser}
                      loading={loggingOut}
                      text="Log out"
                      loadingText="Logging out..."
                    />,
                  ]}
                />
              )}

              <div className="mt-4">
                <ReloadButton
                  loading={gettingAuthUser}
                  text="Reload Profile"
                  variant="primary"
                  className="d-inline me-2 text-uppercase text-md fw-bold"
                  onClick={() => dispatch(authActions.getAuthUser())}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default EmailVerificationRequest;
