const subject = {
  GET_SUBJECTS_REQUEST: 'GET_SUBJECTS_REQUEST',
  GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_ERROR: 'GET_SUBJECTS_ERROR',

  CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
  CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
  CREATE_SUBJECT_ERROR: 'CREATE_SUBJECT_ERROR',

  DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
  DELETE_SUBJECT_REQUEST: 'DELETE_SUBJECT_REQUEST',
  DELETE_SUBJECT_ERROR: 'DELETE_SUBJECT_ERROR',

  UPDATE_SUBJECT_SUCCESS: 'UPDATE_SUBJECT_SUCCESS',
  UPDATE_SUBJECT_REQUEST: 'UPDATE_SUBJECT_REQUEST',
  UPDATE_SUBJECT_ERROR: 'UPDATE_SUBJECT_ERROR',

  getSubjects: (data) => ({
    type: subject.GET_SUBJECTS_REQUEST,
    data,
  }),

  createSubject: (data) => ({
    type: subject.CREATE_SUBJECT_REQUEST,
    data,
  }),

  deleteSubject: (id) => ({
    type: subject.DELETE_SUBJECT_REQUEST,
    id,
  }),

  updateSubject: (id, data) => ({
    type: subject.UPDATE_SUBJECT_REQUEST,
    id,
    data,
  }),

  // SubjectCombinations
  GET_SUBJECT_COMBINATIONS_REQUEST: 'GET_SUBJECT_COMBINATIONS_REQUEST',
  GET_SUBJECT_COMBINATIONS_SUCCESS: 'GET_SUBJECT_COMBINATIONS_SUCCESS',
  GET_SUBJECT_COMBINATIONS_ERROR: 'GET_SUBJECT_COMBINATIONS_ERROR',

  CREATE_SUBJECT_COMBINATION_SUCCESS: 'CREATE_SUBJECT_COMBINATION_SUCCESS',
  CREATE_SUBJECT_COMBINATION_REQUEST: 'CREATE_SUBJECT_COMBINATION_REQUEST',
  CREATE_SUBJECT_COMBINATION_ERROR: 'CREATE_SUBJECT_COMBINATION_ERROR',

  DELETE_SUBJECT_COMBINATION_SUCCESS: 'DELETE_SUBJECT_COMBINATION_SUCCESS',
  DELETE_SUBJECT_COMBINATION_REQUEST: 'DELETE_SUBJECT_COMBINATION_REQUEST',
  DELETE_SUBJECT_COMBINATION_ERROR: 'DELETE_SUBJECT_COMBINATION_ERROR',

  GET_VERSION_COMBINATIONS_REQUEST: 'GET_VERSION_COMBINATIONS_REQUEST',
  GET_VERSION_COMBINATIONS_SUCCESS: 'GET_VERSION_COMBINATIONS_SUCCESS',
  GET_VERSION_COMBINATIONS_ERROR: 'GET_VERSION_COMBINATIONS_ERROR',

  UPDATE_SUBJECT_COMBINATION_SUCCESS: 'UPDATE_SUBJECT_COMBINATION_SUCCESS',
  UPDATE_SUBJECT_COMBINATION_REQUEST: 'UPDATE_SUBJECT_COMBINATION_REQUEST',
  UPDATE_SUBJECT_COMBINATION_ERROR: 'UPDATE_SUBJECT_COMBINATION_ERROR',

  getSubjectCombinations: () => ({
    type: subject.GET_SUBJECT_COMBINATIONS_REQUEST,
  }),

  createSubjectCombination: (data) => ({
    type: subject.CREATE_SUBJECT_COMBINATION_REQUEST,
    data,
  }),

  deleteSubjectCombination: (id) => ({
    type: subject.DELETE_SUBJECT_COMBINATION_REQUEST,
    id,
  }),

  getVersionSubjectCombination: (id) => ({
    type: subject.GET_VERSION_COMBINATIONS_REQUEST,
    id,
  }),

  updateSubjectCombination: (id, data) => ({
    type: subject.UPDATE_SUBJECT_COMBINATION_REQUEST,
    id,
    data,
  }),
};
export default subject;
