import { takeLatest, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { metadataActions } from '../../actions';

function* getAllMetadata(actions) {
  try {
    const response = yield axios({
      url: '/app/meta-data',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: metadataActions.GET_META_DATA_SUCCESS,
      data: response.metadata,
    });
  } catch (error) {
    yield put({
      type: metadataActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* createMetadata(actions) {
  try {
    const response = yield axios({
      url: '/app/meta-data',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: metadataActions.CREATE_META_DATA_SUCCESS,
      data: response,
    });
    yield put({
      type: metadataActions.SET_CURRENT_VIEW,
      view: 'view',
    });
    yield put({
      type: metadataActions.GET_META_DATA_REQUEST,
      data: response,
    });
    yield put({
      type: 'SHOW_CREATE_METADATA_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: metadataActions.CREATE_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* updateMetadata(actions) {
  try {
    const response = yield axios({
      url: `/app/meta-data/${actions.metadataId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: metadataActions.UPDATE_META_DATA_SUCCESS,
      data: response,
    });
    yield put({
      type: metadataActions.SET_CURRENT_VIEW,
      view: 'view',
    });
    yield put({
      type: metadataActions.GET_META_DATA_REQUEST,
      data: response,
    });
  } catch (error) {
    yield put({
      type: metadataActions.UPDATE_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* createMetadataValue(actions) {
  try {
    const response = yield axios({
      url: '/app/meta-data-values',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: metadataActions.CREATE_META_DATA_VALUE_SUCCESS,
      data: response,
    });
    yield put({
      type: metadataActions.GET_META_DATA_REQUEST,
      data: response,
    });
  } catch (error) {
    yield put({
      type: metadataActions.CREATE_META_DATA_VALUE_ERROR,
      error: error.data,
    });
  }
}

function* updateMetadataValue(actions) {
  try {
    const response = yield axios({
      url: `/app/meta-data-values/${actions.metadataValueId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: metadataActions.UPDATE_META_DATA_VALUE_SUCCESS,
      data: response,
    });
    yield put({
      type: metadataActions.GET_META_DATA_REQUEST,
      data: response,
    });
    yield put({
      type: 'SHOW_EDIT_METADATA_VALUE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: metadataActions.UPDATE_META_DATA_VALUE_ERROR,
      error: error.data,
    });
  }
}

function* deleteMetadataValue(actions) {
  try {
    const response = yield axios({
      url: `/app/meta-data-values/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: metadataActions.DELETE_META_DATA_VALUE_SUCCESS,
      data: response,
    });

    yield put({
      type: metadataActions.GET_META_DATA_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: metadataActions.DELETE_META_DATA_VALUE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetadata() {
  yield takeEvery(metadataActions.GET_META_DATA_REQUEST, getAllMetadata);
}

function* watchCreateMetadata() {
  yield takeLatest(metadataActions.CREATE_META_DATA_REQUEST, createMetadata);
}

function* watchUpdateMetadata() {
  yield takeLatest(metadataActions.UPDATE_META_DATA_REQUEST, updateMetadata);
}

function* watchCreateMetadataValue() {
  yield takeLatest(
    metadataActions.CREATE_META_DATA_VALUE_REQUEST,
    createMetadataValue
  );
}

function* watchUpdateMetadataValue() {
  yield takeLatest(
    metadataActions.UPDATE_META_DATA_VALUE_REQUEST,
    updateMetadataValue
  );
}
function* watchDeleteMetadataValue() {
  yield takeLatest(
    metadataActions.DELETE_META_DATA_VALUE_REQUEST,
    deleteMetadataValue
  );
}

export default [
  fork(watchGetMetadata),
  fork(watchCreateMetadata),
  fork(watchUpdateMetadata),
  fork(watchCreateMetadataValue),
  fork(watchUpdateMetadataValue),
  fork(watchDeleteMetadataValue),
];
