const NTCResultActions = {
  CREATE_NTC_RESULT_REQUEST: 'CREATE_NTC_RESULT_REQUEST',
  CREATE_NTC_RESULT_SUCCESS: 'CREATE_NTC_RESULT_SUCCESS',
  CREATE_NTC_RESULT_ERROR: 'CREATE_NTC_RESULT_ERROR',

  DOWNLOAD_NTC_RESULTS_TEMPLATE_REQUEST:
    'DOWNLOAD_NTC_RESULTS_TEMPLATE_REQUEST',
  DOWNLOAD_NTC_RESULTS_TEMPLATE_SUCCESS:
    'DOWNLOAD_NTC_RESULTS_TEMPLATE_SUCCESS',
  DOWNLOAD_NTC_RESULTS_TEMPLATE_ERROR: 'DOWNLOAD_NTC_RESULTS_TEMPLATE_ERROR',

  GET_NTC_RESULTS_REQUEST: 'GET_NTC_RESULTS_REQUEST',
  GET_NTC_RESULTS_SUCCESS: 'GET_NTC_RESULTS_SUCCESS',
  GET_NTC_RESULTS_ERROR: 'GET_NTC_RESULTS_ERROR',

  GET_NTC_TERMLY_RESULTS_REQUEST: 'GET_NTC_TERMLY_RESULTS_REQUEST',
  GET_NTC_TERMLY_RESULTS_SUCCESS: 'GET_NTC_TERMLY_RESULTS_SUCCESS',
  GET_NTC_TERMLY_RESULTS_ERROR: 'GET_NTC_TERMLY_RESULTS_ERROR',

  GET_NTC_STUDENTS_DOCUMENTS_REQUEST: 'GET_NTC_STUDENTS_DOCUMENTS_REQUEST',
  GET_NTC_STUDENTS_DOCUMENTS_SUCCESS: 'GET_NTC_STUDENTS_DOCUMENTS_SUCCESS',
  GET_NTC_STUDENTS_DOCUMENTS_ERROR: 'GET_NTC_STUDENTS_DOCUMENTS_ERROR',

  GENERATE_NTC_STUDENTS_DOCUMENTS_REQUEST:
    'GENERATE_NTC_STUDENTS_DOCUMENTS_REQUEST',
  GENERATE_NTC_STUDENTS_DOCUMENTS_SUCCESS:
    'GENERATE_NTC_STUDENTS_DOCUMENTS_SUCCESS',
  GENERATE_NTC_STUDENTS_DOCUMENTS_ERROR:
    'GENERATE_NTC_STUDENTS_DOCUMENTS_ERROR',

  DOWNLOAD_NTC_STUDENTS_DOCUMENTS_REQUEST:
    'DOWNLOAD_NTC_STUDENTS_DOCUMENTS_REQUEST',
  DOWNLOAD_NTC_STUDENTS_DOCUMENTS_SUCCESS:
    'DOWNLOAD_NTC_STUDENTS_DOCUMENTS_SUCCESS',
  DOWNLOAD_NTC_STUDENTS_DOCUMENTS_ERROR:
    'DOWNLOAD_NTC_STUDENTS_DOCUMENTS_ERROR',

  UPLOAD_NTC_RESULTS_TEMPLATE_REQUEST: 'UPLOAD_NTC_RESULTS_TEMPLATE_REQUEST',
  UPLOAD_NTC_RESULTS_TEMPLATE_SUCCESS: 'UPLOAD_NTC_RESULTS_TEMPLATE_SUCCESS',
  UPLOAD_NTC_RESULTS_TEMPLATE_ERROR: 'UPLOAD_NTC_RESULTS_TEMPLATE_ERROR',

  DELETE_NTC_RESULTS_REQUEST: 'DELETE_NTC_RESULTS_REQUEST',
  DELETE_NTC_RESULTS_SUCCESS: 'DELETE_NTC_RESULTS_SUCCESS',
  DELETE_NTC_RESULTS_ERROR: 'DELETE_NTC_RESULTS_ERROR',

  UPDATE_NTC_RESULT_REQUEST: 'UPDATE_NTC_RESULT_REQUEST',
  UPDATE_NTC_RESULT_SUCCESS: 'UPDATE_NTC_RESULT_SUCCESS',
  UPDATE_NTC_RESULT_ERROR: 'UPDATE_NTC_RESULT_ERROR',

  GET_NTC_PROGRAMMES_REQUEST: 'GET_NTC_PROGRAMMES_REQUEST',
  GET_NTC_PROGRAMMES_SUCCESS: 'GET_NTC_PROGRAMMES_SUCCESS',
  GET_NTC_PROGRAMMES_ERROR: 'GET_NTC_PROGRAMMES_ERROR',

  SET_NTC_RESULT_CONTEXT: 'SET_NTC_RESULT_CONTEXT',

  SET_NTC_SELECTED_PROGRAMME: 'SET_NTC_SELECTED_PROGRAMME',

  SHOW_NTC_MODAL: 'SHOW_NTC_MODAL',

  getNTCResults: (params) => ({
    type: NTCResultActions.GET_NTC_RESULTS_REQUEST,
    params,
  }),

  downloadNTCResultsTemplate: (params) => ({
    type: NTCResultActions.DOWNLOAD_NTC_RESULTS_TEMPLATE_REQUEST,
    params,
  }),

  uploadNTCResultsTemplate: (data) => ({
    type: NTCResultActions.UPLOAD_NTC_RESULTS_TEMPLATE_REQUEST,
    data,
  }),

  createNTCResult: (data) => ({
    type: NTCResultActions.CREATE_NTC_RESULT_REQUEST,
    data,
  }),

  updateNTCResult: (resultId, data) => ({
    type: NTCResultActions.UPDATE_NTC_RESULT_REQUEST,
    data,
    resultId,
  }),

  deleteNTCResult: (resultId, data) => ({
    type: NTCResultActions.DELETE_NTC_RESULTS_REQUEST,
    data,
    resultId,
  }),

  setNTCResultContext: (context) => ({
    type: NTCResultActions.SET_NTC_RESULT_CONTEXT,
    context,
  }),

  setNTCSelectedProgramme: (programme) => ({
    type: NTCResultActions.SET_NTC_SELECTED_PROGRAMME,
    programme,
  }),

  showNTCModal: (payload) => ({
    type: NTCResultActions.SHOW_NTC_MODAL,
    payload,
  }),

  getNTCProgrammes: (context) => ({
    type: NTCResultActions.GET_NTC_PROGRAMMES_REQUEST,
    context,
  }),

  getNTCTermlyResults: (context) => ({
    type: NTCResultActions.GET_NTC_TERMLY_RESULTS_REQUEST,
    context,
  }),

  getNTCStudentsDocuments: (context) => ({
    type: NTCResultActions.GET_NTC_STUDENTS_DOCUMENTS_REQUEST,
    context,
  }),

  generateNTCStudentsDocuments: (actionType, studentIDs, context) => ({
    type: NTCResultActions.GENERATE_NTC_STUDENTS_DOCUMENTS_REQUEST,
    context,
    studentIDs,
    actionType,
  }),

  downloadNTCStudentsDocuments: (actionType, studentIDs, context) => ({
    type: NTCResultActions.DOWNLOAD_NTC_STUDENTS_DOCUMENTS_REQUEST,
    context,
    studentIDs,
    actionType,
  }),
};

export default NTCResultActions;
