import { courseResittingPolicyActions } from '../../actions';
import initialState from '../../initialState';

const courseResittingPolicy = (
  state = initialState.courseResittingPolicy,
  actions
) => {
  switch (actions.type) {
    case courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_SUCCESS:
      return {
        ...state,
        allPolicies: actions.data,
        loading: false,
      };
    case courseResittingPolicyActions.GET_COURSE_SITTING_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case courseResittingPolicyActions.CREATE_RESITTING_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case courseResittingPolicyActions.CREATE_RESITTING_POLICY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case courseResittingPolicyActions.CREATE_RESITTING_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case courseResittingPolicyActions.UPDATE_RESITTING_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case courseResittingPolicyActions.UPDATE_RESITTING_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case courseResittingPolicyActions.UPDATE_RESITTING_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case courseResittingPolicyActions.DELETE_RESITTING_POLICY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };
    case courseResittingPolicyActions.DELETE_RESITTING_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case courseResittingPolicyActions.DELETE_RESITTING_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case courseResittingPolicyActions.SET_RESITTING_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policyToEdit,
      };

    default:
      return state;
  }
};

export default courseResittingPolicy;
