import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { isEmpty, isFunction } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../config/actions';
import SubmitButton from './SubmitButton';

function DeletePopUp({
  handleConfirm,
  disableSubmit,
  deleteData,
  deleting,
  itemName,
  showDelete,
  closeDelete,
  deleteError,
  loadingText,
  customTitle,
  children,
}) {
  const dispatch = useDispatch();
  const { showDeleteModal } = useSelector((state) => state.settings);

  const onClickConfirm = () => handleConfirm(deleteData);

  const handleClose = () => {
    if (!isFunction(closeDelete)) {
      dispatch(settingActions.showDeleteModal(false));
    } else {
      closeDelete();
    }
  };

  return (
    <Modal
      size="md"
      open={showDelete !== null ? showDelete : showDeleteModal}
      closable={false}
      onCancel={handleClose}
      centered
      footer={[
        <SubmitButton
          text="CANCEL"
          key="cancel"
          danger
          htmlType="button"
          ghost
          onClick={handleClose}
          iconBefore={<CloseCircleOutlined />}
        />,
        <SubmitButton
          text="I UNDERSTAND, PROCEED"
          key="submit"
          danger
          onClick={onClickConfirm}
          loading={deleting}
          loadingText="Processing..."
          disabled={disableSubmit || deleting}
          iconBefore={<CheckCircleOutlined />}
        />,
      ]}
    >
      <div className="text-center">
        {deleting ? (
          <div className="text-muted fs-6 fw-bolder my-4">{loadingText}</div>
        ) : (
          <span className="text-danger text-sm fw-bold text-uppercase">
            {!isEmpty(deleteError) ? (
              <div className="text-danger fs-6 fw-bold my-4">{deleteError}</div>
            ) : (
              <div className="fw-bold d-block">
                <div>
                  <FaExclamationTriangle
                    size="4rem"
                    className="mb-3 text-center"
                  />
                </div>
                <div className="fs-6 text-muted fw-bold">
                  {customTitle || `YOU ARE ABOUT TO DELETE THIS ${itemName}`}
                </div>
                <div className="text-danger fs-6 fw-bolder my-4">
                  Are you sure you want to proceed?
                </div>
              </div>
            )}
          </span>
        )}
      </div>
      {children}
    </Modal>
  );
}

DeletePopUp.defaultProps = {
  deleting: false,
  disableSubmit: false,
  deleteData: null,
  deleteError: null,
  showDelete: null,
  closeDelete: null,
  loadingText: 'Deleting in progress...',
  customTitle: null,
  children: null,
};
DeletePopUp.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  closeDelete: PropTypes.func,
  deleteData: PropTypes.oneOfType([any]),
  children: PropTypes.oneOfType([any]),
  deleting: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  showDelete: PropTypes.bool,
  itemName: PropTypes.string.isRequired,
  deleteError: PropTypes.string,
  loadingText: PropTypes.string,
  customTitle: PropTypes.string,
};

export default DeletePopUp;
