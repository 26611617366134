import { systemCampusActions } from '../../actions';
import systemManager from '../../initialState/system-mgt';

const acmisCampuses = (state = systemManager.systemCampuses, actions) => {
  switch (actions.type) {
    case systemCampusActions.GET_ACMIS_CAMPUS_REQUEST:
      return {
        ...state,
        gettingCampuses: true,
        getCampusError: {},
      };
    case systemCampusActions.GET_ACMIS_CAMPUS_SUCCESS:
      return {
        ...state,
        gettingCampuses: false,
        acmisCampuses: actions.data,
      };
    case systemCampusActions.GET_ACMIS_CAMPUS_ERROR:
      return {
        ...state,
        gettingCampuses: false,
        getCampusError: actions.error,
      };

    case systemCampusActions.GET_EMIS_CAMPUSES_REQUEST:
      return {
        ...state,
        gettingCampuses: true,
        getCampusError: {},
      };
    case systemCampusActions.GET_EMIS_CAMPUSES_SUCCESS:
      return {
        ...state,
        gettingCampuses: false,
        emisCampuses: actions.data,
      };
    case systemCampusActions.GET_EMIS_CAMPUSES_ERROR:
      return {
        ...state,
        gettingCampuses: false,
        getCampusError: actions.error,
      };

    case systemCampusActions.SUBMIT_SYSTEM_CAMPUS_REQUEST:
      return {
        ...state,
        submittingCampus: true,
        submitError: {},
      };
    case systemCampusActions.SUBMIT_SYSTEM_CAMPUS_SUCCESS:
      return {
        ...state,
        submittingCampus: false,
        submitSuccess: actions.data,
      };
    case systemCampusActions.SUBMIT_SYSTEM_CAMPUS_ERROR:
      return {
        ...state,
        submittingCampus: false,
        submitError: actions.error,
      };
    case systemCampusActions.UPDATE_ACMIS_CAMPUS_REQUEST:
      return {
        ...state,
        updatingCampus: true,
        updateError: {},
      };
    case systemCampusActions.UPDATE_ACMIS_CAMPUS_SUCCESS:
      return {
        ...state,
        updatingCampus: false,
        updateSuccess: actions.data,
      };
    case systemCampusActions.UPDATE_ACMIS_CAMPUS_ERROR:
      return {
        ...state,
        updatingCampus: false,
        updateError: actions.error,
      };

    case systemCampusActions.GET_EMIS_ADMIN_REGIONS_REQUEST:
      return {
        ...state,
        gettingAdminRegions: true,
        getAdminUnitError: {},
      };
    case systemCampusActions.GET_EMIS_ADMIN_REGIONS_SUCCESS:
      return {
        ...state,
        gettingAdminRegions: false,
        adminRegions: actions.data,
      };
    case systemCampusActions.GET_EMIS_ADMIN_REGIONS_ERROR:
      return {
        ...state,
        gettingAdminRegions: false,
        getAdminUnitError: actions.error,
      };

    case systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_REQUEST:
      return {
        ...state,
        gettingAdminDistricts: true,
        getAdminUnitError: {},
      };
    case systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_SUCCESS:
      return {
        ...state,
        gettingAdminDistricts: false,
        adminDistricts: actions.data,
      };
    case systemCampusActions.GET_EMIS_ADMIN_DISTRICTS_ERROR:
      return {
        ...state,
        gettingAdminDistricts: false,
        getAdminUnitError: actions.error,
      };

    case systemCampusActions.GET_EMIS_ADMIN_COUNTIES_REQUEST:
      return {
        ...state,
        gettingAdminCounties: true,
        getAdminUnitError: {},
      };
    case systemCampusActions.GET_EMIS_ADMIN_COUNTIES_SUCCESS:
      return {
        ...state,
        gettingAdminCounties: false,
        adminCounties: actions.data,
      };
    case systemCampusActions.GET_EMIS_ADMIN_COUNTIES_ERROR:
      return {
        ...state,
        gettingAdminCounties: false,
        getAdminUnitError: actions.error,
      };

    case systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_REQUEST:
      return {
        ...state,
        gettingAdminSubCounties: true,
        getAdminUnitError: {},
      };
    case systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_SUCCESS:
      return {
        ...state,
        gettingAdminSubCounties: false,
        adminSubCounties: actions.data,
      };
    case systemCampusActions.GET_EMIS_ADMIN_SUB_COUNTIES_ERROR:
      return {
        ...state,
        gettingAdminSubCounties: false,
        getAdminUnitError: actions.error,
      };

    case systemCampusActions.GET_EMIS_ADMIN_PARISHES_REQUEST:
      return {
        ...state,
        gettingAdminParishes: true,
        getAdminUnitError: {},
      };
    case systemCampusActions.GET_EMIS_ADMIN_PARISHES_SUCCESS:
      return {
        ...state,
        gettingAdminParishes: false,
        adminParishes: actions.data,
      };
    case systemCampusActions.GET_EMIS_ADMIN_PARISHES_ERROR:
      return {
        ...state,
        gettingAdminParishes: false,
        getAdminUnitError: actions.error,
      };

    default:
      return state;
  }
};

export default acmisCampuses;
