import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EmptyImage from '../../assets/images/undraw_air_support_wy1q.svg';

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <div className="vh-100 d-flex p-5">
      <div className="my-auto text-center mx-auto">
        <Image
          src={EmptyImage}
          height={300}
          className="mx-auto"
          alt="No MODULE FOUND"
          title="404 - MODULE NOT FOUND"
        />

        <div className="fw-bold text-danger text-uppercase h6 my-4">
          THIS PAGE HAS BEEN MOVED PERMANENTLY
        </div>
        <div className="fw-bold">REDIRECTING YOU...</div>
      </div>
    </div>
  );
}

export default NotFound;
