import { Spin } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AntDInputText from './AntDInputText';

function RemoteSearch({ fetchCenterList, ...props }) {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      if (value) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchCenterList(value)
          .then((newOptions) => {
            if (fetchId !== fetchRef.current) return;

            setOptions(newOptions);
            setFetching(false);
          })
          .catch(() => toast.error('Unable to search center names'));
      }
    };
    return debounce(loadOptions, 800);
  }, [fetchCenterList]);

  return (
    <AntDInputText
      type="select"
      options={options}
      itemAttributes={{ extra: 'Type to search...' }}
      inputAttributes={{
        filterOption: false,
        onSearch: debounceFetcher,
        notFoundContent: fetching ? <Spin size="small" /> : null,
      }}
      {...props}
    />
  );
}

RemoteSearch.propTypes = {
  fetchCenterList: PropTypes.func.isRequired,
};

export default RemoteSearch;
