import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { weightingAndSelectionActions } from '../../actions';

function* createWeightingCriteria(actions) {
  try {
    const response = yield axios({
      url: '/admissions/weighting-criteria',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: weightingAndSelectionActions.CREATE_WEIGHTING_CRITERIA_SUCCESS,
      data: response.data,
    });
    yield put({
      type: weightingAndSelectionActions.SET_CURRENT_CRITERIA_TAB,
      tabName: 'view',
    });
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST,
    });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.CREATE_WEIGHTING_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* createSelectionCriteria(actions) {
  try {
    const response = yield axios({
      url: '/admissions/selection-criteria',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: weightingAndSelectionActions.CREATE_SELECTION_CRITERIA_SUCCESS,
      data: response.data,
    });
    yield put({
      type: weightingAndSelectionActions.GET_SELECTION_CRITERIA_REQUEST,
    });
    yield put({ type: 'SET_CURRENT_SELECTION_TAB', tabName: 'view' });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.CREATE_SELECTION_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* getWeightingCriteria() {
  try {
    const response = yield axios({
      url: '/admissions/weighting-criteria',
      method: 'GET',
    });
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* getSelectionCriteria() {
  try {
    const response = yield axios({
      url: '/admissions/selection-criteria',
      method: 'GET',
    });
    yield put({
      type: weightingAndSelectionActions.GET_SELECTION_CRITERIA_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.GET_SELECTION_CRITERIA_ERROR,
      error: error.data,
    });
  }
}

function* addUnebSubjects(actions) {
  try {
    const response = yield axios({
      url: '/admissions/weighting-criteria/add-category-subjects',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: weightingAndSelectionActions.ADD_UNEB_SUBJECTS_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST,
    });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.ADD_UNEB_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* addNewCategory(actions) {
  try {
    const response = yield axios({
      url: '/admissions/weighting-criteria/add-new-category',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: weightingAndSelectionActions.ADD_NEW_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST,
    });
    yield put({ type: 'SET_CATEGORY_VIEW', tabName: 'view' });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.ADD_NEW_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* updateCategory(actions) {
  try {
    const response = yield axios({
      url: `/admissions/weighting-criteria/update-criteria-category/${actions.criteriaId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: weightingAndSelectionActions.UPDATE_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.UPDATE_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* deleteCategory(actions) {
  try {
    const response = yield axios({
      url: `/admissions/weighting-criteria/delete-criteria-category/${actions.categoryId}`,
      method: 'DELETE',
    });
    yield put({
      type: weightingAndSelectionActions.DELETE_CATEGORY_SUCCESS,
      data: response.data,
    });
    yield put({
      type: weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: weightingAndSelectionActions.DELETE_CATEGORY_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateWeightingCriteria() {
  yield takeLatest(
    weightingAndSelectionActions.CREATE_WEIGHTING_CRITERIA_REQUEST,
    createWeightingCriteria
  );
}

function* watchCreateSelectionCriteria() {
  yield takeLatest(
    weightingAndSelectionActions.CREATE_SELECTION_CRITERIA_REQUEST,
    createSelectionCriteria
  );
}

function* watchGetWeightingCriteria() {
  yield takeLatest(
    weightingAndSelectionActions.GET_WEIGHTING_CRITERIA_REQUEST,
    getWeightingCriteria
  );
}

function* watchGetSelectionCriteria() {
  yield takeLatest(
    weightingAndSelectionActions.GET_SELECTION_CRITERIA_REQUEST,
    getSelectionCriteria
  );
}

function* watchAddUnebSubjects() {
  yield takeLatest(
    weightingAndSelectionActions.ADD_UNEB_SUBJECTS_REQUEST,
    addUnebSubjects
  );
}

function* watchAddNewCategory() {
  yield takeLatest(
    weightingAndSelectionActions.ADD_NEW_CATEGORY_REQUEST,
    addNewCategory
  );
}

function* watchUpdateCategory() {
  yield takeLatest(
    weightingAndSelectionActions.UPDATE_CATEGORY_REQUEST,
    updateCategory
  );
}

function* watchDeleteCategory() {
  yield takeLatest(
    weightingAndSelectionActions.DELETE_CATEGORY_REQUEST,
    deleteCategory
  );
}

export default [
  fork(watchCreateWeightingCriteria),
  fork(watchCreateSelectionCriteria),
  fork(watchGetWeightingCriteria),
  fork(watchGetSelectionCriteria),
  fork(watchAddUnebSubjects),
  fork(watchAddNewCategory),
  fork(watchUpdateCategory),
  fork(watchDeleteCategory),
];
