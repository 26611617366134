import { extensionPolicyActions } from '../../actions';
import initialState from '../../initialState';

const extensionPolicy = (state = initialState.extensionPolicy, actions) => {
  switch (actions.type) {
    case extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case extensionPolicyActions.GET_EXTENSION_POLICIES_SUCCESS:
      return {
        ...state,
        extensionPolicies: actions.data.data,
        loading: false,
      };
    case extensionPolicyActions.GET_EXTENSION_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case extensionPolicyActions.CREATE_EXTENSION_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case extensionPolicyActions.CREATE_EXTENSION_POLICY_SUCCESS:
      return {
        ...state,
        extensionPolicy: actions.data.extensionPolicy,
        creating: false,
      };
    case extensionPolicyActions.CREATE_EXTENSION_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case extensionPolicyActions.UPDATE_EXTENSION_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case extensionPolicyActions.UPDATE_EXTENSION_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case extensionPolicyActions.UPDATE_EXTENSION_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        addRetakePolicyElementError: {},
        addingRetakePolicyElement: true,
      };
    case extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        addingRetakePolicyElement: false,
      };
    case extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        addRetakePolicyElementError: actions.error,
        addingRetakePolicyElement: false,
      };

    case extensionPolicyActions.DELETE_EXTENSION_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        deleting: true,
      };
    case extensionPolicyActions.DELETE_EXTENSION_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case extensionPolicyActions.DELETE_EXTENSION_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_REQUEST:
      return {
        ...state,
        deletingElementError: {},
        deletingElements: true,
      };
    case extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_SUCCESS:
      return {
        ...state,
        deletingElementSuccess: actions.data,
        deletingElements: false,
      };
    case extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_ERROR:
      return {
        ...state,
        deletingElementError: actions.error,
        deletingElements: false,
      };

    case extensionPolicyActions.SET_EXTENSION_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.extensionPolicy,
      };
    default:
      return state;
  }
};

export default extensionPolicy;
