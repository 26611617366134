import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

function PDFButtons({ pageNumber, nextPage, numPages, previousPage }) {
  return (
    <ButtonGroup className="text-center mx-auto">
      <Button
        type="button"
        size="sm"
        variant="outline-primary"
        disabled={pageNumber <= 1}
        onClick={previousPage}
        className="fw-bold ms-2 px-3"
      >
        <FaAngleDoubleLeft />
        Previous
      </Button>
      <Button
        type="button"
        size="sm"
        disabled={pageNumber >= numPages}
        onClick={nextPage}
        variant="outline-info"
        className="fw-bold ms-2 px-3"
      >
        Next
        <FaAngleDoubleRight className="ms-1" />
      </Button>
    </ButtonGroup>
  );
}

PDFButtons.propTypes = {
  numPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default PDFButtons;
