const financeClearanceActions = {
  GET_FC_GRADUATION_STATS_REPORT_REQUEST:
    'GET_FC_GRADUATION_STATS_REPORT_REQUEST',
  GET_FC_GRADUATION_STATS_REPORT_SUCCESS:
    'GET_FC_GRADUATION_STATS_REPORT_SUCCESS',
  GET_FC_GRADUATION_STATS_REPORT_ERROR: 'GET_FC_GRADUATION_STATS_REPORT_ERROR',

  GET_FC_GRADUATION_DETAILED_REPORT_REQUEST:
    'GET_FC_GRADUATION_DETAILED_REPORT_REQUEST',
  GET_FC_GRADUATION_DETAILED_REPORT_SUCCESS:
    'GET_FC_GRADUATION_DETAILED_REPORT_SUCCESS',
  GET_FC_GRADUATION_DETAILED_REPORT_ERROR:
    'GET_FC_GRADUATION_DETAILED_REPORT_ERROR',

  GET_FC_STUDENT_REQUEST: 'GET_FC_STUDENT_REQUEST',
  GET_FC_STUDENT_SUCCESS: 'GET_FC_STUDENT_SUCCESS',
  GET_FC_STUDENT_ERROR: 'GET_FC_STUDENT_ERROR',

  CLEAR_STUDENTS_FINANCE_REQUEST: 'CLEAR_STUDENTS_FINANCE_REQUEST',
  CLEAR_STUDENTS_FINANCE_SUCCESS: 'CLEAR_STUDENTS_FINANCE_SUCCESS',
  CLEAR_STUDENTS_FINANCE_ERROR: 'CLEAR_STUDENTS_FINANCE_ERROR',

  DOWNLOAD_FC_GRADUATION_STATISTICS_REQUEST:
    'DOWNLOAD_FC_GRADUATION_STATISTICS_REQUEST',
  DOWNLOAD_FC_GRADUATION_STATISTICS_SUCCESS:
    'DOWNLOAD_FC_GRADUATION_STATISTICS_SUCCESS',
  DOWNLOAD_FC_GRADUATION_STATISTICS_ERROR:
    'DOWNLOAD_FC_GRADUATION_STATISTICS_ERROR',

  getGraduationStatistics: (data) => ({
    type: financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_REQUEST,
    data,
  }),

  clearStudents: (data, context, category) => ({
    type: financeClearanceActions.CLEAR_STUDENTS_FINANCE_REQUEST,
    data,
    context,
    category,
  }),

  getGraduationDetailedReport: (data) => ({
    type: financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_REQUEST,
    data,
  }),

  getFinanceClearanceStudent: (data) => ({
    type: financeClearanceActions.GET_FC_STUDENT_REQUEST,
    data,
  }),

  downloadGraduationStatistics: (data) => ({
    type: financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_REQUEST,
    data,
  }),
};

export default financeClearanceActions;
