import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { specializationActions } from '../../actions';

function* getSpecializations(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/specializations',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: specializationActions.GET_SPECIALIZATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: specializationActions.GET_SPECIALIZATIONS_ERROR,
      error: error.data,
    });
  }
}

function* createSpecializations(actions) {
  try {
    const response = yield axios({
      url: '/programme-mgt/specializations',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: specializationActions.CREATE_SPECIALIZATION_SUCCESS,
      data: response,
    });
    yield put({
      type: specializationActions.GET_SPECIALIZATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specializationActions.CREATE_SPECIALIZATION_ERROR,
      error: error.data,
    });
  }
}

function* deleteSpecialization(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/specializations/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: specializationActions.DELETE_SPECIALIZATION_SUCCESS,
      data: response,
    });

    yield put({
      type: specializationActions.GET_SPECIALIZATIONS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: specializationActions.DELETE_SPECIALIZATION_ERROR,
      error: error.data,
    });
  }
}
function* updateSpecialization(actions) {
  try {
    const response = yield axios({
      url: `/programme-mgt/specializations/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: specializationActions.UPDATE_SPECIALIZATION_SUCCESS,
      data: response,
    });

    yield put({
      type: specializationActions.GET_SPECIALIZATIONS_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_SPECIALIZATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: specializationActions.UPDATE_SPECIALIZATION_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSpecializations() {
  yield takeLatest(
    specializationActions.GET_SPECIALIZATIONS_REQUEST,
    getSpecializations
  );
}

function* watchCreateSpecializations() {
  yield takeLatest(
    specializationActions.CREATE_SPECIALIZATION_REQUEST,
    createSpecializations
  );
}
function* watchDeleteSpecialization() {
  yield takeLatest(
    specializationActions.DELETE_SPECIALIZATION_REQUEST,
    deleteSpecialization
  );
}

function* watchUpdateSpecialization() {
  yield takeLatest(
    specializationActions.UPDATE_SPECIALIZATION_REQUEST,
    updateSpecialization
  );
}
export default [
  fork(watchGetSpecializations),
  fork(watchCreateSpecializations),
  fork(watchDeleteSpecialization),
  fork(watchUpdateSpecialization),
];
