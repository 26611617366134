import axios from 'axios';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { semesterCourseLoadActions } from '../../actions';

function* getSemesterLoads(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/semester-course-loads',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_ERROR,
      error: error.data,
    });
  }
}

function* createSemesterLoads(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/semester-course-loads',
      method: 'POST',
      data: actions.data,
      timeout: 1200000,
    });
    yield put({
      type: semesterCourseLoadActions.CREATE_SEMESTER_COURSE_LOAD_SUCCESS,
      data: response,
      context,
    });

    yield put({
      type: semesterCourseLoadActions.SET_SEMESTER_COURSE_LOAD_TAB,
      tab: 'semester-course-loads',
    });

    if (!isEmpty(context)) {
      yield put({
        type: semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_REQUEST,
        context,
      });
    }
  } catch (error) {
    yield put({
      type: semesterCourseLoadActions.CREATE_SEMESTER_COURSE_LOAD_ERROR,
      error: error.data,
    });
  }
}
function* deleteSemesterLoad(actions) {
  try {
    const response = yield axios({
      url: `/course-assignment/semester-course-loads/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: semesterCourseLoadActions.DELETE_SEMESTER_COURSE_LOAD_SUCCESS,
      data: response,
    });
    yield put({
      type: semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: semesterCourseLoadActions.DELETE_SEMESTER_COURSE_LOAD_ERROR,
      error: error.data,
    });
  }
}
function* updateSemesterLoad(actions) {
  try {
    const { context, id } = actions;
    const response = yield axios({
      url: `/course-assignment/semester-course-loads/${id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: semesterCourseLoadActions.UPDATE_SEMESTER_COURSE_LOAD_SUCCESS,
      data: response.data,
      context,
    });

    yield put({
      type: semesterCourseLoadActions.SHOW_EDIT_SEMESTER_COURSE_LOAD,
      payload: false,
    });

    yield put({
      type: semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_REQUEST,
      context,
    });
  } catch (error) {
    yield put({
      type: semesterCourseLoadActions.UPDATE_SEMESTER_COURSE_LOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSemesterLoads() {
  yield takeLatest(
    semesterCourseLoadActions.GET_SEMESTER_COURSE_LOADS_REQUEST,
    getSemesterLoads
  );
}

function* watchCreateSemesterLoads() {
  yield takeLatest(
    semesterCourseLoadActions.CREATE_SEMESTER_COURSE_LOAD_REQUEST,
    createSemesterLoads
  );
}
function* watchDeleteSemesterLoad() {
  yield takeLatest(
    semesterCourseLoadActions.DELETE_SEMESTER_COURSE_LOAD_REQUEST,
    deleteSemesterLoad
  );
}
function* watchUpdateSemesterLoad() {
  yield takeLatest(
    semesterCourseLoadActions.UPDATE_SEMESTER_COURSE_LOAD_REQUEST,
    updateSemesterLoad
  );
}

export default [
  fork(watchGetSemesterLoads),
  fork(watchCreateSemesterLoads),
  fork(watchDeleteSemesterLoad),
  fork(watchUpdateSemesterLoad),
];
