import axios from 'axios';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  authActions,
  roleGroupActions,
  systemUserActions,
  userRoleActions,
} from '../../actions';

function* getRoleUser(actions) {
  try {
    const response = yield axios({
      url: 'users/user-roles',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.GET_USER_ROLES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.GET_USER_ROLES_ERROR,
      error: error.data,
    });
  }
}

function* getSingleUserRole(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/${actions.roleId}`,
      method: 'GET',
    });
    yield put({
      type: userRoleActions.GET_SINGLE_USER_ROLE_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_SELECTED_USER_ROLE',
      payload: response.userRole,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.GET_SINGLE_USER_ROLE_ERROR,
      error: error.data,
    });
  }
}

function* createUserRole(actions) {
  try {
    const response = yield axios({
      url: '/users/user-roles',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.CREATE_USER_ROLE_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST,
      roleGroupId: actions.data?.user_role_group_id,
    });
    yield put({
      type: 'SHOW_CREATE_USER_ROLE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.CREATE_USER_ROLE_ERROR,
      error: error.data,
    });
  }
}

function* updateRole(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.UPDATE_USER_ROLE_SUCCESS,
      data: response,
    });
    yield put({
      type: userRoleActions.GET_USER_ROLES_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_USER_ROLE_MODAL',
      payload: false,
    });
    yield put({
      type: userRoleActions.GET_SINGLE_USER_ROLE_REQUEST,
      roleId: actions.id,
    });
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.UPDATE_USER_ROLE_ERROR,
      error: error.data,
    });
  }
}

function* updateRoleProfile(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/profile/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.UPDATE_USER_ROLE_PROFILE_SUCCESS,
      data: response,
    });
    yield put({
      type: userRoleActions.GET_USER_ROLES_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_USER_ROLE_PROFILE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.UPDATE_USER_ROLE_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* deleteRoleProfile(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/${actions.id}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.DELETE_USER_ROLE_SUCCESS,
      data: response,
    });
    yield put({
      type: userRoleActions.GET_USER_ROLES_REQUEST,
    });
    yield put({
      type: roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.DELETE_USER_ROLE_ERROR,
      error: error.data,
    });
  }
}

function* addUserRoleApps(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/apps/${actions.userRoleId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.INSERT_USER_ROLE_APPS_SUCCESS,
      data: response,
    });
    yield put({
      type: userRoleActions.GET_USER_ROLES_REQUEST,
    });
    yield put({
      type: 'SHOW_ADD_USER_ROLE_APPLICATION_MODAL',
      payload: false,
    });
    yield put({
      type: userRoleActions.GET_SINGLE_USER_ROLE_REQUEST,
      roleId: actions.userRoleId,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.INSERT_USER_ROLE_APPS_ERROR,
      error: error.data,
    });
  }
}

function* dropUserRoleApps(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/remove-group-role-apps`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.DROP_USER_ROLE_APPS_SUCCESS,
      data: response,
    });
    yield put({
      type: userRoleActions.GET_SINGLE_USER_ROLE_REQUEST,
      roleId: actions.userRoleId,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.DROP_USER_ROLE_APPS_ERROR,
      error: error.data,
    });
  }
}

function* dropUserRoleAppFunctions(actions) {
  try {
    const response = yield axios({
      url: '/users/user-roles/remove-role-app-functions',
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.DROP_USER_ROLE_FUNCTIONS_SUCCESS,
      data: response,
    });
    yield put({
      type: userRoleActions.GET_SINGLE_USER_ROLE_REQUEST,
      roleId: actions.userRoleId,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.DROP_USER_ROLE_FUNCTIONS_ERROR,
      error: error.data,
    });
  }
}

function* assignUserRoles(actions) {
  try {
    const response = yield axios({
      url: `/users/user-roles/role/${actions.roleId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.ASSIGN_ROLE_USER_SUCCESS,
      data: response,
    });
    yield put({
      type: systemUserActions.GET_USERS_REQUEST,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
    yield put({
      type: 'SHOW_ASSIGN_ROLE_TO_USER_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.ASSIGN_ROLE_USER_ERROR,
      error: error.data,
    });
  }
}

function* removeUserRoles(actions) {
  try {
    const response = yield axios({
      url: `/users/user-data/remove-roles/${actions.userId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: userRoleActions.REMOVE_USER_ROLES_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_REMOVE_USER_ROLE_MODAL',
      payload: false,
    });
    yield put({
      type: 'SHOW_USER_MODAL',
      payload: false,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.REMOVE_USER_ROLES_ERROR,
      error: error.data,
    });
  }
}

function* updateAccessDomain(action) {
  try {
    const { roleGroupId, data } = action;
    const response = yield axios({
      url: `/users/user-roles/update-access-domain/`,
      method: 'PUT',
      data,
    });
    yield put({
      type: userRoleActions.UPDATE_ACCESS_DOMAIN_SUCCESS,
      data: response.data,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
      roleGroupId,
    });
    yield put({
      type: 'SHOW_EDIT_ACCESS_DOMAIN_MODAL',
      payload: false,
    });
    yield put({
      type: 'SHOW_USER_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.UPDATE_ACCESS_DOMAIN_ERROR,
      error: error.data || error,
    });
  }
}

function* getUserRoleBounds(action) {
  try {
    const { roleId, userId } = action;
    const response = yield axios({
      url: `/users/user-roles/user-bounds/${userId}/${roleId}`,
      method: 'GET',
    });
    yield put({
      type: userRoleActions.GET_GROUP_USER_ROLE_BOUND_SUCCESS,
      data: response.data,
      roleId,
      userId,
    });
  } catch (error) {
    yield put({
      type: userRoleActions.GET_GROUP_USER_ROLE_BOUND_ERROR,
      error: error.data || error,
    });
  }
}

function* watchGetRoleUser() {
  yield takeLatest(userRoleActions.GET_USER_ROLES_REQUEST, getRoleUser);
}

function* watchGetSingleRoleUser() {
  yield takeLatest(
    userRoleActions.GET_SINGLE_USER_ROLE_REQUEST,
    getSingleUserRole
  );
}

function* watchCreateUserRole() {
  yield takeLatest(userRoleActions.CREATE_USER_ROLE_REQUEST, createUserRole);
}

function* watchUpdateRole() {
  yield takeLatest(userRoleActions.UPDATE_USER_ROLE_REQUEST, updateRole);
}

function* watchUpdateRoleProfile() {
  yield takeLatest(
    userRoleActions.UPDATE_USER_ROLE_PROFILE_REQUEST,
    updateRoleProfile
  );
}

function* watchDeleteRoleProfile() {
  yield takeLatest(userRoleActions.DELETE_USER_ROLE_REQUEST, deleteRoleProfile);
}

function* watchAddUserRoleApps() {
  yield takeLatest(
    userRoleActions.INSERT_USER_ROLE_APPS_REQUEST,
    addUserRoleApps
  );
}

function* watchDropUserRoleApps() {
  yield takeLatest(
    userRoleActions.DROP_USER_ROLE_APPS_REQUEST,
    dropUserRoleApps
  );
}

function* watchDropUserRoleAppFunctions() {
  yield takeLatest(
    userRoleActions.DROP_USER_ROLE_FUNCTIONS_REQUEST,
    dropUserRoleAppFunctions
  );
}

function* watchAssignUserRoles() {
  yield takeLatest(userRoleActions.ASSIGN_ROLE_USER_REQUEST, assignUserRoles);
}

function* watchRemoveUserRoles() {
  yield takeLatest(userRoleActions.REMOVE_USER_ROLES_REQUEST, removeUserRoles);
}

function* watchUpdateAccessDomain() {
  yield takeEvery(
    userRoleActions.UPDATE_ACCESS_DOMAIN_REQUEST,
    updateAccessDomain
  );
}

function* watchGetUserRoleBounds() {
  yield takeLatest(
    userRoleActions.GET_GROUP_USER_ROLE_BOUND_REQUEST,
    getUserRoleBounds
  );
}

export default [
  fork(watchGetRoleUser),
  fork(watchGetSingleRoleUser),
  fork(watchCreateUserRole),
  fork(watchUpdateRole),
  fork(watchUpdateRoleProfile),
  fork(watchDeleteRoleProfile),
  fork(watchAddUserRoleApps),
  fork(watchDropUserRoleApps),
  fork(watchDropUserRoleAppFunctions),
  fork(watchAssignUserRoles),
  fork(watchRemoveUserRoles),
  fork(watchUpdateAccessDomain),
  fork(watchGetUserRoleBounds),
];
