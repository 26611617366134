const academicDocumentActions = {
  GET_STUDENT_TESTIMONIAL_REQUEST: 'GET_STUDENT_TESTIMONIAL_REQUEST',
  GET_STUDENT_TESTIMONIAL_SUCCESS: 'GET_STUDENT_TESTIMONIAL_SUCCESS',
  GET_STUDENT_TESTIMONIAL_ERROR: 'GET_STUDENT_TESTIMONIAL_ERROR',

  GENERATE_TRANSCRIPT_REQUEST: 'GENERATE_TRANSCRIPT_REQUEST',
  GENERATE_TRANSCRIPT_SUCCESS: 'GENERATE_TRANSCRIPT_SUCCESS',
  GENERATE_TRANSCRIPT_ERROR: 'GENERATE_TRANSCRIPT_ERROR',

  GET_GRADUATION_PROGRAMMES_REQUEST: 'GET_GRADUATION_PROGRAMMES_REQUEST',
  GET_GRADUATION_PROGRAMMES_SUCCESS: 'GET_GRADUATION_PROGRAMMES_SUCCESS',
  GET_GRADUATION_PROGRAMMES_ERROR: 'GET_GRADUATION_PROGRAMMES_ERROR',

  PRINT_ACADEMIC_DOCUMENT_REQUEST: 'PRINT_ACADEMIC_DOCUMENT_REQUEST',
  PRINT_ACADEMIC_DOCUMENT_SUCCESS: 'PRINT_ACADEMIC_DOCUMENT_SUCCESS',
  PRINT_ACADEMIC_DOCUMENT_ERROR: 'PRINT_ACADEMIC_DOCUMENT_ERROR',

  DOWNLOAD_ACADEMIC_DOCUMENTS_REQUEST: 'DOWNLOAD_ACADEMIC_DOCUMENTS_REQUEST',
  DOWNLOAD_ACADEMIC_DOCUMENTS_SUCCESS: 'DOWNLOAD_ACADEMIC_DOCUMENTS_SUCCESS',
  DOWNLOAD_ACADEMIC_DOCUMENTS_ERROR: 'DOWNLOAD_ACADEMIC_DOCUMENTS_ERROR',

  PRINT_SINGLE_ACADEMIC_DOCUMENT_REQUEST:
    'PRINT_SINGLE_ACADEMIC_DOCUMENT_REQUEST',
  PRINT_SINGLE_ACADEMIC_DOCUMENT_SUCCESS:
    'PRINT_SINGLE_ACADEMIC_DOCUMENT_SUCCESS',
  PRINT_SINGLE_ACADEMIC_DOCUMENT_ERROR: 'PRINT_SINGLE_ACADEMIC_DOCUMENT_ERROR',

  SET_TESTIMONIAL_STUDENT: 'SET_TESTIMONIAL_STUDENT',
  SET_SELECTED_DOCUMENT_PROGRAMME: 'SET_SELECTED_DOCUMENT_PROGRAMME',
  SET_SELECTED_DOCUMENT_ACADEMIC_YEAR: 'SET_SELECTED_DOCUMENT_ACADEMIC_YEAR',

  getStudentTestimonial: (params) => ({
    type: academicDocumentActions.GET_STUDENT_TESTIMONIAL_REQUEST,
    params,
  }),

  getGraduationProgrammes: (academicYear) => ({
    type: academicDocumentActions.GET_GRADUATION_PROGRAMMES_REQUEST,
    academicYear,
  }),

  setStudent: (student) => ({
    type: academicDocumentActions.SET_TESTIMONIAL_STUDENT,
    student,
  }),

  generateTranscript: (student) => ({
    type: academicDocumentActions.GENERATE_TRANSCRIPT_REQUEST,
    student,
  }),

  setSelectedProgramme: (data) => ({
    type: academicDocumentActions.SET_SELECTED_DOCUMENT_PROGRAMME,
    data,
  }),

  setSelectedAcademicYear: (data) => ({
    type: academicDocumentActions.SET_SELECTED_DOCUMENT_ACADEMIC_YEAR,
    data,
  }),

  printAcademicDocument: (category, data, context) => ({
    type: academicDocumentActions.PRINT_ACADEMIC_DOCUMENT_REQUEST,
    data,
    category,
    context,
  }),

  downloadAcademicDocuments: (category, data, context) => ({
    type: academicDocumentActions.DOWNLOAD_ACADEMIC_DOCUMENTS_REQUEST,
    data,
    category,
    context,
  }),

  printSingleAcademicDocument: (category, data) => ({
    type: academicDocumentActions.PRINT_SINGLE_ACADEMIC_DOCUMENT_REQUEST,
    data,
    category,
  }),
};

export default academicDocumentActions;
