import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { financeClearanceActions } from '../../actions';

function* getGraduationStatistics(actions) {
  try {
    const response = yield axios({
      url: `/clearance-mgt/finance-clearance/graduates`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getGraduationDetailedReport(actions) {
  try {
    const response = yield axios({
      url: `/clearance-mgt/finance-clearance/detailed`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getFCStudent(actions) {
  try {
    const response = yield axios({
      url: `/clearance-mgt/finance-clearance/student`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: financeClearanceActions.GET_FC_STUDENT_SUCCESS,
      data: response.data,
      context: actions.data,
    });
  } catch (error) {
    yield put({
      type: financeClearanceActions.GET_FC_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* clearStudents(actions) {
  try {
    const { data, context, category } = actions;
    const response = yield axios({
      url: `/clearance-mgt/finance-clearance/clear-finance`,
      method: 'POST',
      data,
      params: context,
    });
    yield put({
      type: financeClearanceActions.CLEAR_STUDENTS_FINANCE_SUCCESS,
      data: response.data,
    });

    yield put({ type: 'SHOW_CLEAR_STUDENT_MODAL', payload: false });

    if (category === 'single') {
      yield put({
        type: financeClearanceActions.GET_FC_STUDENT_REQUEST,
        data: { student_query: data.studentNumbers[0] },
      });
    } else {
      yield put({
        type: financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_REQUEST,
        data: context,
      });
    }
  } catch (error) {
    yield put({
      type: financeClearanceActions.CLEAR_STUDENTS_FINANCE_ERROR,
      error: error.data,
    });
  }
}

function* downloadGraduationStats(actions) {
  try {
    yield axios({
      url: `/clearance-mgt/finance-clearance/download-statistics`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-GRADUATION-STATISTICS.xlsx`
      );
    });
    yield put({
      type: financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetGraduationStatistics() {
  yield takeLatest(
    financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_REQUEST,
    getGraduationStatistics
  );
}

function* watchGetGraduationDetailedReport() {
  yield takeLatest(
    financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_REQUEST,
    getGraduationDetailedReport
  );
}

function* watchGetFCStudent() {
  yield takeLatest(
    financeClearanceActions.GET_FC_STUDENT_REQUEST,
    getFCStudent
  );
}

function* watchClearStudents() {
  yield takeLatest(
    financeClearanceActions.CLEAR_STUDENTS_FINANCE_REQUEST,
    clearStudents
  );
}

function* watchDownloadGraduationStats() {
  yield takeLatest(
    financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_REQUEST,
    downloadGraduationStats
  );
}

export default [
  fork(watchGetGraduationStatistics),
  fork(watchGetGraduationDetailedReport),
  fork(watchDownloadGraduationStats),
  fork(watchClearStudents),
  fork(watchGetFCStudent),
];
