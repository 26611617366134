const studentLedger = {
  GET_STUDENT_LEDGER_REQUEST: 'GET_STUDENT_LEDGER_REQUEST',
  GET_STUDENT_LEDGER_SUCCESS: 'GET_STUDENT_LEDGER_SUCCESS',
  GET_STUDENT_LEDGER_ERROR: 'GET_STUDENT_LEDGER_ERROR',

  getStudentLedger: (student) => ({
    type: studentLedger.GET_STUDENT_LEDGER_REQUEST,
    student: { student },
  }),
};

export default studentLedger;
