import PropTypes, { array } from 'prop-types';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function BarChart({
  backgroundColor,
  borderColor,
  data,
  labels,
  label,
  position,
  indexAxis,
  titleText,
  maintainAspectRatio,
  ...props
}) {
  return (
    <Bar
      data={{
        labels,
        label,
        datasets: [
          {
            data,
            label,
            backgroundColor,
            borderColor,
            borderWidth: 1,
          },
        ],
      }}
      options={{
        indexAxis,
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position,
          },
          title: {
            display: true,
            text: titleText,
          },
        },
        maintainAspectRatio,
      }}
      {...props}
    />
  );
}

BarChart.defaultProps = {
  indexAxis: 'y',
  titleText: 'Chart.js Horizontal Bar Chart',
  position: 'right',
  backgroundColor: [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
  ],
  borderColor: [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
  ],
  label: '',
  maintainAspectRatio: false,
};

BarChart.propTypes = {
  data: PropTypes.oneOfType([array]).isRequired,
  labels: PropTypes.oneOfType([array]).isRequired,
  backgroundColor: PropTypes.oneOfType([array]),
  borderColor: PropTypes.oneOfType([array]),
  position: PropTypes.string,
  label: PropTypes.string,
  indexAxis: PropTypes.string,
  titleText: PropTypes.string,
  maintainAspectRatio: PropTypes.bool,
};

export default BarChart;
