import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { registrationPolicyActions, tabActions } from '../../actions';

function* getRegistrationPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/registration-policies',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: registrationPolicyActions.GET_REGISTRATION_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: registrationPolicyActions.GET_REGISTRATION_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createRegistrationPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/registration-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: registrationPolicyActions.CREATE_REGISTRATION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: registrationPolicyActions.GET_REGISTRATION_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: registrationPolicyActions.CREATE_REGISTRATION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* updateRegistrationPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/registration-policies/${actions.registrationPolicyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: registrationPolicyActions.UPDATE_REGISTRATION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: registrationPolicyActions.GET_REGISTRATION_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: registrationPolicyActions.UPDATE_REGISTRATION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteRegistrationPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/registration-policies/${actions.registrationPolicyId}`,
      method: 'DELETE',
    });
    yield put({
      type: registrationPolicyActions.DELETE_REGISTRATION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: registrationPolicyActions.GET_REGISTRATION_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: registrationPolicyActions.DELETE_REGISTRATION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRegistrationPolicies() {
  yield takeLatest(
    registrationPolicyActions.GET_REGISTRATION_POLICIES_REQUEST,
    getRegistrationPolicies
  );
}

function* watchCreateRegistrationPolicy() {
  yield takeLatest(
    registrationPolicyActions.CREATE_REGISTRATION_POLICY_REQUEST,
    createRegistrationPolicy
  );
}

function* watchUpdateRegistrationPolicy() {
  yield takeLatest(
    registrationPolicyActions.UPDATE_REGISTRATION_POLICY_REQUEST,
    updateRegistrationPolicy
  );
}

function* watchDeleteRegistrationPolicy() {
  yield takeLatest(
    registrationPolicyActions.DELETE_REGISTRATION_POLICY_REQUEST,
    deleteRegistrationPolicy
  );
}

export default [
  fork(watchGetRegistrationPolicies),
  fork(watchCreateRegistrationPolicy),
  fork(watchUpdateRegistrationPolicy),
  fork(watchDeleteRegistrationPolicy),
];
