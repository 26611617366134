import { servicesPolicyActions } from '../../actions';
import initialState from '../../initialState';

const servicesPolicy = (state = initialState.servicesPolicy, actions) => {
  switch (actions.type) {
    case servicesPolicyActions.GET_SERVICE_POLICIES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case servicesPolicyActions.GET_SERVICE_POLICIES_SUCCESS:
      return {
        ...state,
        servicePolicies: actions.data,
        loading: false,
      };
    case servicesPolicyActions.GET_SERVICE_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case servicesPolicyActions.CREATE_SERVICE_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creatingServicePolicy: true,
      };
    case servicesPolicyActions.CREATE_SERVICE_POLICY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data.registrationPolicy,
        creatingServicePolicy: false,
      };
    case servicesPolicyActions.CREATE_SERVICE_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creatingServicePolicy: false,
      };

    case servicesPolicyActions.UPDATE_SERVICE_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updatingServicePolicy: true,
      };
    case servicesPolicyActions.UPDATE_SERVICE_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updatingServicePolicy: false,
      };
    case servicesPolicyActions.UPDATE_SERVICE_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updatingServicePolicy: false,
      };

    case servicesPolicyActions.DELETE_SERVICE_POLICY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deletingServicePolicy: true,
      };
    case servicesPolicyActions.DELETE_SERVICE_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deletingServicePolicy: false,
      };
    case servicesPolicyActions.DELETE_SERVICE_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deletingServicePolicy: false,
      };

    case servicesPolicyActions.SET_SERVICE_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.setServicePolicy,
      };

    default:
      return state;
  }
};

export default servicesPolicy;
