import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { waiverDiscountActions } from '../../actions';

function* getWaiverDiscounts(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-waiver-discounts/fees-elements/filter',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: waiverDiscountActions.GET_WAIVER_DISCOUNTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: waiverDiscountActions.GET_WAIVER_DISCOUNTS_ERROR,
      error: error.data,
    });
  }
}

function* editWaiverDiscount(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waiver-discounts/update-element-amount/${actions.dataId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: waiverDiscountActions.EDIT_WAIVER_DISCOUNTS_SUCCESS,
      data: response,
    });
    yield put({
      type: waiverDiscountActions.GET_WAIVER_DISCOUNTS_REQUEST,
      context: actions.context,
    });
    yield put({
      type: 'SHOW_EDIT_FEES_DISCOUNT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: waiverDiscountActions.EDIT_WAIVER_DISCOUNTS_ERROR,
      error: error.data,
    });
  }
}

function* deleteWaiverDiscounts(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waiver-discounts/delete-element-amount/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: waiverDiscountActions.DELETE_WAIVER_DISCOUNTS_SUCCESS,
      data: response,
    });
    yield put({
      type: waiverDiscountActions.GET_WAIVER_DISCOUNTS_REQUEST,
      context: actions.context,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: waiverDiscountActions.DELETE_WAIVER_DISCOUNTS_ERROR,
      error: error.data,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  }
}

function* updateWaiverDiscounts(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waiver-discounts/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: waiverDiscountActions.UPDATE_OTHER_FEES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: waiverDiscountActions.UPDATE_OTHER_FEES_ERROR,
      error: error.data,
    });
  }
}

function* createWaiverDiscounts(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/fees-waiver-discounts',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: waiverDiscountActions.CREATE_WAIVER_DISCOUNTS_SUCCESS,
      data: response,
    });
    yield put({
      type: waiverDiscountActions.GET_WAIVER_DISCOUNTS_REQUEST,
      context: actions.context,
    });
    yield put({ type: 'WAIVER_DISCOUNTS_TAB', activeTab: 'view' });
  } catch (error) {
    yield put({
      type: waiverDiscountActions.CREATE_WAIVER_DISCOUNTS_ERROR,
      error: error.data,
    });
  }
}

function* addWaiverDiscountElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-waiver-discounts/add-element-amounts/${actions.contextId}`,
      method: 'POST',
      data: { discountedElements: actions.data },
    });
    yield put({
      type: waiverDiscountActions.ADD_WAIVER_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: waiverDiscountActions.GET_WAIVER_DISCOUNTS_REQUEST,
      context: actions.context,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: waiverDiscountActions.ADD_WAIVER_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetWaiverDiscounts() {
  yield takeLatest(
    waiverDiscountActions.GET_WAIVER_DISCOUNTS_REQUEST,
    getWaiverDiscounts
  );
}

function* watchEditWaiverDiscount() {
  yield takeLatest(
    waiverDiscountActions.EDIT_WAIVER_DISCOUNTS_REQUEST,
    editWaiverDiscount
  );
}

function* watchCreateWaiverDiscounts() {
  yield takeLatest(
    waiverDiscountActions.CREATE_WAIVER_DISCOUNTS_REQUEST,
    createWaiverDiscounts
  );
}

function* watchAddWaiverDiscountElements() {
  yield takeLatest(
    waiverDiscountActions.ADD_WAIVER_ELEMENTS_REQUEST,
    addWaiverDiscountElements
  );
}

function* watchDeleteWaiverDiscounts() {
  yield takeLatest(
    waiverDiscountActions.DELETE_WAIVER_DISCOUNTS_REQUEST,
    deleteWaiverDiscounts
  );
}
function* watchUpdateWaiverDiscounts() {
  yield takeLatest(
    waiverDiscountActions.UPDATE_WAIVER_DISCOUNTS_REQUEST,
    updateWaiverDiscounts
  );
}

export default [
  fork(watchGetWaiverDiscounts),
  fork(watchCreateWaiverDiscounts),
  fork(watchDeleteWaiverDiscounts),
  fork(watchEditWaiverDiscount),
  fork(watchUpdateWaiverDiscounts),
  fork(watchAddWaiverDiscountElements),
];
