const roleGroup = {
  GET_ROLE_GROUP_REQUEST: 'GET_ROLE_GROUP_REQUEST',
  GET_ROLE_GROUP_SUCCESS: 'GET_ROLE_GROUP_SUCCESS',
  GET_ROLE_GROUP_ERROR: 'GET_ROLE_GROUP_ERROR',

  GET_ROLE_GROUP_USERS_REQUEST: 'GET_ROLE_GROUP_USERS_REQUEST',
  GET_ROLE_GROUP_USERS_SUCCESS: 'GET_ROLE_GROUP_USERS_SUCCESS',
  GET_ROLE_GROUP_USERS_ERROR: 'GET_ROLE_GROUP_USERS_ERROR',

  GET_SINGLE_ROLE_GROUP_REQUEST: 'GET_SINGLE_ROLE_GROUP_REQUEST',
  GET_SINGLE_ROLE_GROUP_SUCCESS: 'GET_SINGLE_ROLE_GROUP_SUCCESS',
  GET_SINGLE_ROLE_GROUP_ERROR: 'GET_SINGLE_ROLE_GROUP_ERROR',

  CREATE_ROLE_GROUP_SUCCESS: 'CREATE_ROLE_GROUP_SUCCESS',
  CREATE_ROLE_GROUP_REQUEST: 'CREATE_ROLE_GROUP_REQUEST',
  CREATE_ROLE_GROUP_ERROR: 'CREATE_ROLE_GROUP_ERROR',

  UPDATE_ROLE_GROUP_SUCCESS: 'UPDATE_ROLE_GROUP_SUCCESS',
  UPDATE_ROLE_GROUP_REQUEST: 'UPDATE_ROLE_GROUP_REQUEST',
  UPDATE_ROLE_GROUP_ERROR: 'UPDATE_ROLE_GROUP_ERROR',

  DELETE_ROLE_GROUP_SUCCESS: 'DELETE_ROLE_GROUP_SUCCESS',
  DELETE_ROLE_GROUP_REQUEST: 'DELETE_ROLE_GROUP_REQUEST',
  DELETE_ROLE_GROUP_ERROR: 'DELETE_ROLE_GROUP_ERROR',

  INSERT_ROLE_GROUP_APPS_SUCCESS: 'INSERT_ROLE_GROUP_APPS_SUCCESS',
  INSERT_ROLE_GROUP_APPS_REQUEST: 'INSERT_ROLE_GROUP_APPS_REQUEST',
  INSERT_ROLE_GROUP_APPS_ERROR: 'INSERT_ROLE_GROUP_APPS_ERROR',

  ADD_ROLE_GROUP_ADMIN_SUCCESS: 'ADD_ROLE_GROUP_ADMIN_SUCCESS',
  ADD_ROLE_GROUP_ADMIN_REQUEST: 'ADD_ROLE_GROUP_ADMIN_REQUEST',
  ADD_ROLE_GROUP_ADMIN_ERROR: 'ADD_ROLE_GROUP_ADMIN_ERROR',

  DROP_ROLE_GROUP_APPS_SUCCESS: 'DROP_ROLE_GROUP_APPS_SUCCESS',
  DROP_ROLE_GROUP_APPS_REQUEST: 'DROP_ROLE_GROUP_APPS_REQUEST',
  DROP_ROLE_GROUP_APPS_ERROR: 'DROP_ROLE_GROUP_APPS_ERROR',

  SET_SELECTED_ROLE_GROUP: 'SET_SELECTED_ROLE_GROUP',
  SET_MY_SELECTED_ROLE_GROUP: 'SET_MY_SELECTED_ROLE_GROUP',

  ACTIVATE_OR_DEACTIVATE_USER_SUCCESS: 'ACTIVATE_OR_DEACTIVATE_USER_SUCCESS',
  ACTIVATE_OR_DEACTIVATE_USER_REQUEST: 'ACTIVATE_OR_DEACTIVATE_USER_REQUEST',
  ACTIVATE_OR_DEACTIVATE_USER_ERROR: 'ACTIVATE_OR_DEACTIVATE_USER_ERROR',

  SHOW_DEACTIVATE_USER_MODAL: 'SHOW_DEACTIVATE_USER_MODAL',

  getRoleGroup: (data) => ({
    type: roleGroup.GET_ROLE_GROUP_REQUEST,
    data,
  }),

  getRoleGroupUsers: (roleGroupId) => ({
    type: roleGroup.GET_ROLE_GROUP_USERS_REQUEST,
    roleGroupId,
  }),

  getSingleRoleGroup: (roleGroupId) => ({
    type: roleGroup.GET_SINGLE_ROLE_GROUP_REQUEST,
    roleGroupId,
  }),

  createRoleGroup: (data) => ({
    type: roleGroup.CREATE_ROLE_GROUP_REQUEST,
    data,
  }),

  updateRoleGroup: (data, roleGroupId) => ({
    type: roleGroup.UPDATE_ROLE_GROUP_REQUEST,
    roleGroupId,
    data,
  }),

  deleteRoleGroup: (roleGroupId) => ({
    type: roleGroup.DELETE_ROLE_GROUP_REQUEST,
    roleGroupId,
  }),

  insertRoleGroupApps: (data, roleGroupId) => ({
    type: roleGroup.INSERT_ROLE_GROUP_APPS_REQUEST,
    data,
    roleGroupId,
  }),

  dropRoleGroupApps: (data, roleGroupId) => ({
    type: roleGroup.DROP_ROLE_GROUP_APPS_REQUEST,
    data,
    roleGroupId,
  }),

  addRoleGroupAdmin: (data, roleGroupId, adminId) => ({
    type: roleGroup.ADD_ROLE_GROUP_ADMIN_REQUEST,
    data,
    roleGroupId,
    adminId,
  }),

  setSelectedRoleGroup: (e) => ({
    type: roleGroup.SET_SELECTED_ROLE_GROUP,
    payload: e,
  }),

  setMySelectedRoleGroup: (e) => ({
    type: roleGroup.SET_MY_SELECTED_ROLE_GROUP,
    payload: e,
  }),

  activateOrDeactivateUser: (data, category, roleGroupId) => ({
    type: roleGroup.ACTIVATE_OR_DEACTIVATE_USER_REQUEST,
    data,
    category,
    roleGroupId,
  }),

  showDeactivateUserModal: (payload) => ({
    type: roleGroup.SHOW_DEACTIVATE_USER_MODAL,
    payload,
  }),
};

export default roleGroup;
