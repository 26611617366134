import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { runningAdmissionActions } from '../../actions';

function* getRunningAdmissionData(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admissions',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* createRunningAdmission(actions) {
  try {
    const response = yield axios({
      url: '/admissions/running-admissions',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: runningAdmissionActions.CREATE_RUNNING_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
    yield put({
      type: 'SHOW_OPEN_ADMISSION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.CREATE_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* getSingleRunningAdmission(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admissions/${actions.runningAdmissionId}`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_SUCCESS,
      data: response.runningAdmission,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* updateRunningAdmission(actions) {
  try {
    const { runningAdmissionId, data, admissionEditAction } = actions;

    let url = `/admissions/running-admissions/${runningAdmissionId}`;

    if (admissionEditAction === 'DEEP COPY') {
      url = `/admissions/running-admissions/deep-copy/${runningAdmissionId}`;
    }

    const response = yield axios({
      url,
      method: admissionEditAction === 'DEEP COPY' ? 'POST' : 'PUT',
      data,
      timeout: 300000,
    });
    yield put({
      type: runningAdmissionActions.UPDATE_RUNNING_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
    yield put({
      type: 'SHOW_OPEN_ADMISSION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.UPDATE_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* startRunningAdmission(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admissions/start/${actions.runningAdmissionId}`,
      method: 'PUT',
    });
    yield put({
      type: runningAdmissionActions.START_RUNNING_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.START_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* stopRunningAdmission(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admissions/stop/${actions.runningAdmissionId}`,
      method: 'PUT',
    });
    yield put({
      type: runningAdmissionActions.STOP_RUNNING_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.STOP_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* deleteRunningAdmission(actions) {
  try {
    const response = yield axios({
      url: `/admissions/running-admissions/${actions.runningAdmissionId}`,
      method: 'DELETE',
    });
    yield put({
      type: runningAdmissionActions.DELETE_RUNNING_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.DELETE_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* fetchManagedDetails(actions) {
  try {
    const { contextId } = actions;
    const response = yield axios({
      url: `/admissions/running-admission-programme-campuses/fetch-by-running-admission-programme/${contextId}`,
    });
    yield put({
      type: runningAdmissionActions.GET_MANAGED_PROG_DETAILS_SUCCESS,
      data: response.data,
      runningAdmissionProgrammeId: contextId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_MANAGED_PROG_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* downloadSelectedRunningAdmissionStudents(actions) {
  try {
    yield axios({
      url: `/admissions/running-admissions/download-selected-applicants/${actions.runningAdmissionId}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-RUNNING-ADMISSION-SELECTED-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: runningAdmissionActions.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_SUCCESS,
      data: { server: { message: 'Document downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadNonSelectedRunningAdmissionStudents(actions) {
  try {
    yield axios({
      url: `/admissions/running-admission-applicant/download-not-selected-applicants/${actions.runningAdmissionId}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-RUNNING-ADMISSION-NON-SELECTED-APPLICANTS.xlsx`
      );
    });
    yield put({
      type: runningAdmissionActions.DOWNLOAD_ADMISSION_NOT_SELECTED_STUDENTS_SUCCESS,
      data: { server: { message: 'Document downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.DOWNLOAD_ADMISSION_NOT_SELECTED_STUDENTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetRunningAdmissionData() {
  yield takeLatest(
    runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    getRunningAdmissionData
  );
}

function* watchCreateRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.CREATE_RUNNING_ADMISSION_REQUEST,
    createRunningAdmission
  );
}

function* watchGetSingleRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
    getSingleRunningAdmission
  );
}

function* watchUpdateRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.UPDATE_RUNNING_ADMISSION_REQUEST,
    updateRunningAdmission
  );
}

function* watchStartRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.START_RUNNING_ADMISSION_REQUEST,
    startRunningAdmission
  );
}

function* watchStopRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.STOP_RUNNING_ADMISSION_REQUEST,
    stopRunningAdmission
  );
}

function* watchDeleteRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.DELETE_RUNNING_ADMISSION_REQUEST,
    deleteRunningAdmission
  );
}

function* watchFetchManagedDetails() {
  yield takeLatest(
    runningAdmissionActions.GET_MANAGED_PROG_DETAILS_REQUEST,
    fetchManagedDetails
  );
}

function* watchDownloadSelectedRunningAdmissionStudents() {
  yield takeLatest(
    runningAdmissionActions.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_REQUEST,
    downloadSelectedRunningAdmissionStudents
  );
}

function* watchDownloadNonSelectedRunningAdmissionStudents() {
  yield takeLatest(
    runningAdmissionActions.DOWNLOAD_ADMISSION_NOT_SELECTED_STUDENTS_REQUEST,
    downloadNonSelectedRunningAdmissionStudents
  );
}
export default [
  fork(watchCreateRunningAdmission),
  fork(watchGetRunningAdmissionData),
  fork(watchUpdateRunningAdmission),
  fork(watchStartRunningAdmission),
  fork(watchStopRunningAdmission),
  fork(watchGetSingleRunningAdmission),
  fork(watchDeleteRunningAdmission),
  fork(watchFetchManagedDetails),
  fork(watchDownloadSelectedRunningAdmissionStudents),
  fork(watchDownloadNonSelectedRunningAdmissionStudents),
];
