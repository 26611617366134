const eventsInitialState = {
  academicYear: {
    academicYears: [],
    academicYearsByCampus: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    createdAcademicYear: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    gettingAcademicYearByCampus: false,

    createSemesterError: {},
    createdSemester: {},
    creatingSemester: false,

    updateSemesterError: {},
    updatedSemester: {},
    updatingSemester: false,

    updateAcademicYrError: {},
    updatedAcademicYr: {},
    updatingAcademicYr: false,

    deleteAcademicYrError: {},
    deletedAcademicYr: {},
    deletingAcademicYr: false,

    gettingAcademicYrModules: false,
    academicYearModules: [],
    getModulesError: {},

    creatingModule: false,
    createdModule: {},
    createModuleError: {},

    updatingModule: false,
    updatedModule: {},
    updateModuleError: {},

    selectedAcademicYear: null,
    editAcadYrData: {},
  },
  event: {
    events: [],
    academicYearEvents: [],
    semesterEvents: [],
    created: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    createdEvent: {},
    createError: {},
    gettingAcademicYearEvents: false,
    gettingSemesterEvents: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deleteError: {},
    editError: {},
    editedEvent: {},

    semesterEventData: {},

    academicYearContext: {},

    creatingResultEvents: false,
    loadingResultEvents: false,
    editingResultEVents: false,
    deletingResultEVents: false,

    editResultEventError: {},
    deleteResultEventError: {},
    createResultEventError: {},
    loadResultEventError: {},

    resultEvents: [],
  },
};

export default eventsInitialState;
