import { unebSubjectActions } from '../../actions';
import initialState from '../../initialState';

function unebSubject(state = initialState.unebSubject, actions) {
  switch (actions.type) {
    case unebSubjectActions.GET_UNEB_SUBJECTS_REQUEST:
      return {
        ...state,
        unebSubjectError: {},
        createdUnebSubject: {},
        loading: true,
      };
    case unebSubjectActions.GET_UNEB_SUBJECTS_SUCCESS:
      return {
        ...state,
        unebSubjects: actions.data,
        unebSubjectError: {},
        loading: false,
      };
    case unebSubjectActions.GET_UNEB_SUBJECTS_ERROR:
      return {
        ...state,
        unebSubjectError: actions.error,
        loading: false,
      };
    case unebSubjectActions.GET_SINGLE_UNEB_SUBJECT_REQUEST:
      return {
        ...state,
        unebSubjectError: {},
        createdUnebSubject: {},
        fetching: true,
      };
    case unebSubjectActions.GET_SINGLE_UNEB_SUBJECT_SUCCESS:
      return {
        ...state,
        unebSubjects: actions.data,
        unebSubjectError: {},
        fetching: false,
      };
    case unebSubjectActions.GET_SINGLE_UNEB_SUBJECT_ERROR:
      return {
        ...state,
        unebSubjectError: actions.error,
        fetching: false,
      };
    case unebSubjectActions.CREATE_UNEB_SUBJECT_REQUEST:
      return {
        ...state,
        creating: true,
        unebSubjectError: {},
        createdUnebSubject: {},
      };
    case unebSubjectActions.CREATE_UNEB_SUBJECT_SUCCESS:
      return {
        ...state,
        creating: false,
        createdUnebSubject: actions.data,
      };
    case unebSubjectActions.CREATE_UNEB_SUBJECT_ERROR:
      return {
        ...state,
        creating: false,
        unebSubjectError: actions.error,
      };

    case unebSubjectActions.DELETE_UNEB_SUBJECT_REQUEST:
      return {
        ...state,
        deletingUnebSubject: true,
        deleteUnebSubjectError: {},
      };
    case unebSubjectActions.DELETE_UNEB_SUBJECT_SUCCESS:
      return {
        ...state,
        deletingUnebSubject: false,
        deleteUnebSubjectSuccess: actions.data,
      };
    case unebSubjectActions.DELETE_UNEB_SUBJECT_ERROR:
      return {
        ...state,
        deletingUnebSubject: false,
        deleteUnebSubjectError: actions.error,
      };

    case unebSubjectActions.UPDATE_UNEB_SUBJECT_REQUEST:
      return {
        ...state,
        updating: true,
        unebSubjectError: {},
        createdUnebSubject: {},
      };
    case unebSubjectActions.UPDATE_UNEB_SUBJECT_SUCCESS:
      return {
        ...state,
        updating: false,
        createdUnebSubject: actions.data,
      };
    case unebSubjectActions.UPDATE_UNEB_SUBJECT_ERROR:
      return {
        ...state,
        updating: false,
        unebSubjectError: actions.error,
      };

    case unebSubjectActions.DOWNLOAD_UNEB_SUBJECTS_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case unebSubjectActions.DOWNLOAD_UNEB_SUBJECTS_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case unebSubjectActions.DOWNLOAD_UNEB_SUBJECTS_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case unebSubjectActions.UPLOAD_UNEB_SUBJECTS_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateError: {},
        uploadTemplateSuccess: {},
      };
    case unebSubjectActions.UPLOAD_UNEB_SUBJECTS_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case unebSubjectActions.UPLOAD_UNEB_SUBJECTS_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    default:
      return state;
  }
}

export default unebSubject;
