const department = {
  GET_DEPARTMENTS_REQUEST: 'GET_DEPARTMENTS_REQUEST',
  GET_DEPARTMENTS_SUCCESS: 'GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_ERROR: 'GET_DEPARTMENTS_ERROR',

  CREATE_DEPARTMENT_REQUEST: 'CREATE_DEPARTMENT_REQUEST',
  CREATE_DEPARTMENT_SUCCESS: 'CREATE_DEPARTMENT_SUCCESS',
  CREATE_DEPARTMENT_ERROR: 'CREATE_DEPARTMENT_ERROR',

  DELETE_DEPARTMENT_REQUEST: 'DELETE_DEPARTMENT_REQUEST',
  DELETE_DEPARTMENT_SUCCESS: 'DELETE_DEPARTMENT_SUCCESS',
  DELETE_DEPARTMENT_ERROR: 'DELETE_DEPARTMENT_ERROR',

  UPDATE_DEPARTMENT_REQUEST: 'UPDATE_DEPARTMENT_REQUEST',
  UPDATE_DEPARTMENT_SUCCESS: 'UPDATE_DEPARTMENT_SUCCESS',
  UPDATE_DEPARTMENT_ERROR: 'UPDATE_DEPARTMENT_ERROR',

  DOWNLOAD_DEPARTMENT_TEMPLATE_REQUEST: 'DOWNLOAD_DEPARTMENT_TEMPLATE_REQUEST',
  DOWNLOAD_DEPARTMENT_TEMPLATE_SUCCESS: 'DOWNLOAD_DEPARTMENT_TEMPLATE_SUCCESS',
  DOWNLOAD_DEPARTMENT_TEMPLATE_ERROR: 'DOWNLOAD_DEPARTMENT_TEMPLATE_ERROR',

  UPLOAD_DEPARTMENT_TEMPLATE_REQUEST: 'UPLOAD_DEPARTMENT_TEMPLATE_REQUEST',
  UPLOAD_DEPARTMENT_TEMPLATE_SUCCESS: 'UPLOAD_DEPARTMENT_TEMPLATE_SUCCESS',
  UPLOAD_DEPARTMENT_TEMPLATE_ERROR: 'UPLOAD_DEPARTMENT_TEMPLATE_ERROR',

  getDepartments: (data) => ({
    type: department.GET_DEPARTMENTS_REQUEST,
    data,
  }),

  createDepartment: (data) => ({
    type: department.CREATE_DEPARTMENT_REQUEST,
    data,
  }),

  deleteDepartment: (id) => ({
    type: department.DELETE_DEPARTMENT_REQUEST,
    id,
  }),

  updateDepartment: (id, data) => ({
    type: department.UPDATE_DEPARTMENT_REQUEST,
    id,
    data,
  }),

  downloadDepartmentTemplate: () => ({
    type: department.DOWNLOAD_DEPARTMENT_TEMPLATE_REQUEST,
  }),

  uploadDepartmentTemplate: (data) => ({
    type: department.UPLOAD_DEPARTMENT_TEMPLATE_REQUEST,
    data,
  }),
};

export default department;
