import { chartOfAccountActions } from '../../actions';
import initialState from '../../initialState';

function chartOfAccount(state = initialState.chartOfAccount, actions) {
  switch (actions.type) {
    case chartOfAccountActions.GET_CHART_OF_ACCOUNTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case chartOfAccountActions.GET_CHART_OF_ACCOUNTS_SUCCESS:
      return {
        ...state,
        chartOfAccounts: actions.data,
        loading: false,
      };
    case chartOfAccountActions.GET_CHART_OF_ACCOUNTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case chartOfAccountActions.CREATE_CHART_OF_ACCOUNT_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        createSuccess: {},
      };
    case chartOfAccountActions.CREATE_CHART_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        creating: false,
        createSuccess: actions.data,
      };
    case chartOfAccountActions.CREATE_CHART_OF_ACCOUNT_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case chartOfAccountActions.UPDATE_CHART_OF_ACCOUNT_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updated: {},
      };
    case chartOfAccountActions.UPDATE_CHART_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: actions.data,
      };
    case chartOfAccountActions.UPDATE_CHART_OF_ACCOUNT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case chartOfAccountActions.GET_SINGLE_CHART_OF_ACCOUNT_REQUEST:
      return {
        ...state,
        gettingOne: true,
        getOneError: {},
        chartOfAccount: {},
      };
    case chartOfAccountActions.GET_SINGLE_CHART_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        chartOfAccount: actions.data,
      };
    case chartOfAccountActions.GET_SINGLE_CHART_OF_ACCOUNT_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case chartOfAccountActions.DELETE_CHART_OF_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };
    case chartOfAccountActions.DELETE_CHART_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: actions.data,
      };
    case chartOfAccountActions.DELETE_CHART_OF_ACCOUNT_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case chartOfAccountActions.DOWNLOAD_CHART_TEMPLATE_REQUEST:
      return {
        ...state,
        downloading: true,
        downloadResponse: {},
        downloadError: {},
      };
    case chartOfAccountActions.DOWNLOAD_CHART_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadResponse: actions.data,
        downloading: false,
      };
    case chartOfAccountActions.DOWNLOAD_CHART_TEMPLATE_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloading: false,
      };

    case chartOfAccountActions.UPLOAD_CHART_TEMPLATE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case chartOfAccountActions.UPLOAD_CHART_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadResponse: actions.data,
        uploading: false,
      };
    case chartOfAccountActions.UPLOAD_CHART_TEMPLATE_ERROR:
      return {
        ...state,
        uploadError: actions.error,
        uploading: false,
      };

    case chartOfAccountActions.SET_CURRENT_CHART_OF_ACCOUNT_TAB:
      return {
        ...state,
        currentTab: actions.currentTab,
      };

    case chartOfAccountActions.SHOW_DELETE_CHART_OF_ACCOUNT_MODAL:
      return {
        ...state,
        showDeleteChartOfAccountModal: actions.payload,
      };
    case chartOfAccountActions.SHOW_CREATE_CHART_OF_ACCOUNT_MODAL:
      return {
        ...state,
        showCreateChartOfAccountModal: actions.payload,
      };

    default:
      return state;
  }
}

export default chartOfAccount;
