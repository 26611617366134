const feesWaiversActions = {
  GET_FEES_WAIVERS_REQUEST: 'GET_FEES_WAIVERS_REQUEST',
  GET_FEES_WAIVERS_SUCCESS: 'GET_FEES_WAIVERS_SUCCESS',
  GET_FEES_WAIVERS_ERROR: 'GET_FEES_WAIVERS_ERROR',

  GET_WAIVERS_BY_CONTEXT_REQUEST: 'GET_WAIVERS_BY_CONTEXT_REQUEST',
  GET_WAIVERS_BY_CONTEXT_SUCCESS: 'GET_WAIVERS_BY_CONTEXT_SUCCESS',
  GET_WAIVERS_BY_CONTEXT_ERROR: 'GET_WAIVERS_BY_CONTEXT_ERROR',

  CREATE_FEES_WAIVERS_REQUEST: 'CREATE_FEES_WAIVERS_REQUEST',
  CREATE_FEES_WAIVERS_SUCCESS: 'CREATE_FEES_WAIVERS_SUCCESS',
  CREATE_FEES_WAIVERS_ERROR: 'CREATE_FEES_WAIVERS_ERROR',

  UPDATE_FEES_WAIVERS_REQUEST: 'UPDATE_FEES_WAIVERS_REQUEST',
  UPDATE_FEES_WAIVERS_SUCCESS: 'UPDATE_FEES_WAIVERS_SUCCESS',
  UPDATE_FEES_WAIVERS_ERROR: 'UPDATE_FEES_WAIVERS_ERROR',

  DELETE_FEES_WAIVERS_REQUEST: 'DELETE_FEES_WAIVERS_REQUEST',
  DELETE_FEES_WAIVERS_SUCCESS: 'DELETE_FEES_WAIVERS_SUCCESS',
  DELETE_FEES_WAIVERS_ERROR: 'DELETE_FEES_WAIVERS_ERROR',

  SET_LOADED_WAIVERS: 'SET_LOADED_WAIVERS',

  SET_WAIVERS_CONTEXT: 'SET_WAIVERS_CONTEXT',

  getFeesWaivers: () => ({
    type: feesWaiversActions.GET_FEES_WAIVERS_REQUEST,
  }),

  getOtherFeesByContext: (context) => ({
    type: feesWaiversActions.GET_WAIVERS_BY_CONTEXT_REQUEST,
    context,
  }),

  setLoadedWaivers: (data) => ({
    type: feesWaiversActions.SET_LOADED_WAIVERS,
    data,
  }),

  setPendingContext: (context) => ({
    type: feesWaiversActions.SET_WAIVERS_CONTEXT,
    context,
  }),

  createFeesWaiver: (data) => ({
    type: feesWaiversActions.CREATE_FEES_WAIVERS_REQUEST,
    data,
  }),
  deleteFeesWaiver: (id) => ({
    type: feesWaiversActions.DELETE_FEES_WAIVERS_REQUEST,
    id,
  }),
  updateFeesWaiver: (id, data) => ({
    type: feesWaiversActions.UPDATE_FEES_WAIVERS_REQUEST,
    id,
    data,
  }),
};
export default feesWaiversActions;
