const lecturerManager = {
  lecturerCourse: {
    gettingCourses: false,
    lecturerCourses: [],
    getCoursesError: {},

    showComputeModal: false,
    showAliasModal: false,
    aliasData: {},

    showEditComputedResultModal: false,
    showSubmitModal: false,

    howEditResultModal: false,
    resultToEdit: {},

    editingResults: false,
    editResultError: {},

    deletingResults: false,
    deleteResultError: {},

    submittingResults: false,
    submitResultError: {},

    computingResults: false,
    computeResultError: false,

    rawResults: [],
    gettingRawResults: false,
    getRawResultError: {},

    computedResults: [],
    gettingComputedResults: false,
    getComputedResultError: {},

    gettingStudents: false,
    courseRegisteredStudents: [],
    getStudentsError: {},

    selectedCourse: {},

    departmentProgrammes: [],
    gettingDepartmentProgrammes: false,
    departmentProgrammeError: {},

    departmentContext: {},
    assignmentContext: {},

    gettingLecturerResultCategoryAliases: false,
    lecturerResultCategoryAliases: [],
    lecturerResultCategoryAliasesError: {},

    creatingLecturerResultCategoryAlias: false,
    createLecturerResultCategoryAliasError: {},
    createLecturerResultCategoryAliasSuccess: {},

    editResultCategoryAlias: {},
    editingResultCategoryAlias: false,
    editResultCategoryAliasError: {},

    editResultAlias: {},
    selectedResultCategoryAliases: [],

    deletingResultCategoryAlias: false,
    deleteResultCategoryAliasError: {},
    deleteResultCategoryAliasSuccess: {},

    showDeleteResultCategoryAliasModal: false,
    showDeleteRawResultModal: false,

    selectedResultAlias: {},

    assignmentAnalytics: [],
    gettingAssignmentAnalytics: false,
    getAssignmentAnalyticsError: {},
  },
};

export default lecturerManager;
