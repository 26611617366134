import { admissionFeesPolicyActions } from '../../actions';
import initialState from '../../initialState';

const admissionFeesPolicy = (
  state = initialState.admissionFeesPolicy,
  actions
) => {
  switch (actions.type) {
    case admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
      };
    case admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_SUCCESS:
      return {
        ...state,
        admissionFeesPolicies: actions.data,
        loading: false,
      };
    case admissionFeesPolicyActions.GET_ADMISSION_FEES_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case admissionFeesPolicyActions.CREATE_ADMISSION_FEES_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_REQUEST:
      return {
        ...state,
        updateAdmissionPolicyError: {},
        updatingAdmissionPolicy: true,
      };
    case admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        updateAdmissionPolicyResponse: actions.data,
        updatingAdmissionPolicy: false,
      };
    case admissionFeesPolicyActions.UPDATE_ADMISSION_FEES_POLICY_ERROR:
      return {
        ...state,
        updateAdmissionPolicyError: actions.error,
        updatingAdmissionPolicy: false,
      };

    case admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_REQUEST:
      return {
        ...state,
        deleteAdmissionPolicyError: {},
        deletingAdmissionPolicy: true,
      };
    case admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_SUCCESS:
      return {
        ...state,
        deleteAdmissionPolicyResponse: actions.data,
        deletingAdmissionPolicy: false,
      };
    case admissionFeesPolicyActions.DELETE_ADMISSION_FEES_POLICY_ERROR:
      return {
        ...state,
        deleteAdmissionPolicyError: actions.error,
        deletingAdmissionPolicy: false,
      };

    case admissionFeesPolicyActions.SET_SELECTED_ADMISSION_POLICY:
      return {
        ...state,
        selectedPolicy: actions.selectedPolicy,
      };

    case admissionFeesPolicyActions.SET_ADMISSION_FEES_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policyToEdit,
      };

    default:
      return state;
  }
};

export default admissionFeesPolicy;
