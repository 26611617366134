const itsDataInitialState = {
  itsData: {
    searching: false,
    searchResponse: [],
    searchError: {},

    downloading: false,
    downloadSuccess: {},
    downloadError: {},
  },
};

export default itsDataInitialState;
