const paymentAndTransaction = {
  financialTransactions: {
    loading: false,
    updating: false,
    deleting: false,
    creatingDirectPost: false,
    createdDirectPost: {},
    createDirectPostError: {},
    studentTransactions: [],
    transaction: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    allocating: false,
    allocateSuccess: {},
    allocateError: {},

    transferringFunds: false,
    transferError: {},
    transferredFunds: {},

    gettingTransfers: false,
    pendingTransfers: {},
    pendingTransfersError: {},

    approvingTransfer: false,
    approveTransfersError: {},
    approvedTransfers: {},

    deletingFundTransfer: false,
    deleteTransfersError: {},
    deletedTransfer: {},
  },
  studentRecord: {
    loading: false,
    studentRecords: [],
    currentStudent: {},
    currentStudentProgramme: {},
    loadError: {},

    settingAcademicStatus: false,
    academicStatusError: {},
    academicStatusSuccess: {},

    getVerifiedStudentDoc: false,
    verifiedStudentDocSuccess: {},
    verifiedStudentDocError: {},
    selectedVerificationDocs: [],

    updateCurrentProgSuccess: {},
    updateCurrentProgError: {},
    updatingCurrentProg: false,
  },
  pendingDirectPost: {
    loading: false,
    pendingDirectPosts: [],
    pendingDirectPost: {},
    reportContext: {},
    loadError: {},

    approving: false,
    approveError: {},
    approveSuccess: {},

    deleteDepositSuccess: {},
    deleteDepositError: {},
    deletingDeposit: false,

    directPostReport: {},
    directPostReportError: {},
  },
  pendingVoidedInvoice: {
    loading: false,
    pendingVoidedInvoices: [],
    pendingVoidedInvoice: {},
    loadError: {},
    approving: false,
    approveError: {},
    approveSuccess: {},
    rejecting: false,
    rejectError: {},
    rejectSuccess: {},
  },
  pendingExemptedInvoice: {
    loading: false,
    pendingExemptedInvoices: [],
    pendingExemptedInvoice: {},
    loadError: {},
    approving: false,
    approveError: {},
    approveSuccess: {},
  },
  studentInvoice: {
    loading: false,
    allStudentInvoices: [],
    loadError: {},

    approving: false,
    approveError: {},
    approveSuccess: {},

    creatingManualInvoice: false,
    createManualInvoiceError: {},
    createdManualInvoice: {},

    creatingBulkManualInvoice: false,
    createBulkManualInvoiceError: {},
    createdBulkManualInvoice: {},

    gettingAllFeesElements: false,
    allFeesElements: [],
    allFeesElementError: {},

    generating: false,
    gettingReferences: false,
    allStudentPaymentReferences: [],
    referenceError: {},
    generateError: {},
    generateSuccess: {},

    gettingUnpaidInvoices: false,
    allStudentUnpaidInvoices: [],
    unpaidInvoiceError: {},

    gettingGraduationInvoices: false,
    studentGraduationInvoices: {},
    graduationInvoiceError: {},

    gettingInvoiceDetails: false,
    invoiceDetails: [],
    invoicePay: {},
    invoiceDetailsError: {},

    offsetting: false,
    offsetError: {},
    offsetSuccess: {},

    sendingAction: false,
    actionError: {},
    actionSuccess: {},

    uploadingTemplate: false,
    uploadError: {},
    uploadSuccess: {},

    downloadingTemplate: false,
    downloadError: {},
    downloadSuccess: {},

    generatedReference: {},
    myUnpaidInvoices: [],
  },
  creditNote: {
    allCreditNotes: [],
    loading: false,
    loadError: {},
    loadSuccess: {},

    creating: false,
    createCreditNoteError: {},
    createCreditNoteSuccess: {},

    downloading: false,
    downloadError: {},
    downloadResponse: {},

    gettingPending: false,
    getError: {},
    pendingCreditNotes: [],

    approving: false,
    approveError: {},
    approveSuccess: {},

    fetchPendingCreditError: {},
    pendingStudentCreditNotes: [],
    fetchingPendingCredit: false,

    creditNotesReports: {},
    notesReportDate: {},
    loadNotesReportsError: {},
    loadingNotesReports: false,
  },
  debitNote: {
    allDebitNotes: [],
    loading: false,
    loadError: {},
    loadSuccess: {},

    creatingDebitNote: false,
    createDebitNoteError: {},
    createDebitNoteSuccess: {},

    gettingPending: false,
    getError: {},
    pendingCreditNotes: [],

    downloading: false,
    downloadError: {},
    downloadResponse: {},

    approving: false,
    approveError: {},
    approveSuccess: {},

    declining: false,
    declineError: {},
    declineSuccess: {},

    fetchPendingDebitError: {},
    pendingStudentDebitNotes: [],
    fetchingPendingDebit: false,

    clearTableRows: false,

    debitNotesReports: {},
    notesReportDate: {},
    loadNotesReportsError: {},
    loadingNotesReports: false,
  },
  paymentsReports: {
    paymentsReports: [],
    gettingStudents: false,
    loadError: {},
    studentSPaymentsRecords: [],

    academicUnitReports: [],
    gettingAcademicUnitReports: false,
    academicUnitReportError: {},

    campusReports: [],
    gettingCampusReports: false,
    campusReportError: {},

    streamedReports: [],
    streamingReports: false,
    streamContext: {},
    streamError: {},

    perProgrammeReport: [],
    gettingPerProgReports: false,
    perProgReportError: {},

    feesDepositReports: [],
    gettingFeesDepositReports: false,
    feesDepositReportError: {},

    financialYearReports: [],
    gettingFinancialYearReports: false,
    gettingFinancialYearReportDetails: false,
    financialYearReportError: {},
    financialYearContext: {},

    generateFyReport: false,
    generateFyReportSuccess: {},
    generateFyReportError: {},

    programmeFYReports: [],
    gettingProgrammeFYReports: false,
    programmeFYReportError: {},

    detailedDepositReports: [],
    gettingDetailedDepositReports: false,
    detailedDepositReportError: {},

    academicUnitReportContext: [],
    campusReportContext: [],
    feesDepositReportContext: [],

    downloadingPerProgrammeReport: false,
    downloadPerProgrammeReportError: {},
    downloadPerProgrammeReportSuccess: {},

    downloadingDetailedReport: false,
    downloadDetailedReportError: {},
    downloadDetailedReportSuccess: {},

    downloadingFacultyReport: false,
    downloadFacultyReportError: {},
    downloadFacultyReportSuccess: {},

    downloadingPrepaymentReport: false,
    downloadPrepaymentReportError: {},
    downloadPrepaymentReportSuccess: {},

    downloadingFinancialYearReport: false,
    downloadFinancialYearReportError: {},

    generatingSummaryReport: false,
    generateSummaryReportError: {},

    downloadingFinancialYearFile: false,
    downloadFinancialYearFileError: {},
    fileName: '',

    downloadingPaymentReports: false,
    downloadPaymentReportError: {},
    downloadPaymentReportSuccess: {},
  },
  previousPayments: {
    downloadingTemplate: false,
    DownloadTemplateError: {},
    DownloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateError: {},
    uploadTemplateSuccess: {},

    gettingPreviousTuitionPayments: false,
    fetchPaymentsError: {},
    previousTuitionPayments: [],

    gettingPreviousDeposits: false,
    fetchPrevDepositsError: {},
    previousStudentDeposits: [],

    pushingPayments: false,
    pushPaymentsError: {},
    pushPaymentsSuccess: {},

    deletingTuitionPayments: false,
    deleteTuitionPaymentsError: {},
    deleteTuitionPaymentsSuccess: {},

    deletingPrePayments: false,
    deletePrePaymentsError: {},
    deletePrePaymentsSuccess: {},

    pushPrepaymentsError: {},
    pushPrepaymentsSuccess: {},

    paymentToPush: {},
  },
  refundTransaction: {
    fetchingTransaction: false,
    fetchTransactionError: {},
    fetchTransaction: {},

    fetchingRefundedTransactions: false,
    fetchRefundedTransactionsError: {},
    fetchedRefundedTransactions: [],

    requestingPaymentRefund: false,
    requestPaymentRefundError: {},
    requestRefundSuccess: {},

    gettingPendingPaymentRefunds: false,
    pendingPaymentRefundsError: {},
    pendingPaymentRefunds: [],

    approvingPaymentRefundsRequest: false,
    approvingPaymentRefundsError: {},
    approvedPaymentRefunds: {},

    deletingPendingPaymentRefunds: false,
    deletePaymentRefundsError: {},
    deletedPaymentRefunds: {},

    currentStudentPaymentRefunds: [],
    showRefundModal: false,
    refundApproval: {},
  },
  bulkPayments: {
    loading: false,
    pendingBulkPayments: [],
    approvedBulkPayments: [],
    loadError: {},

    approving: false,
    approveError: {},
    approveSuccess: {},
  },
  sponsors: {
    gettingSponsors: false,
    sponsors: [],
    getSponsorsError: {},

    creatingSponsor: false,
    createSponsorError: {},
    createdSponsor: {},

    updatingSponsor: false,
    updateSponsorError: {},
    updatedSponsor: {},

    deletingSponsor: false,
    deleteSponsorError: {},
    deletedSponsor: {},

    sponsorsByContext: [],
    gettingSponsorsByContext: false,
    getSponsorByContextError: {},

    downloadingTemplate: false,
    downloadTemplateSuccess: {},
    downloadTemplateError: {},

    uploadingTemplate: false,
    uploadTemplateSuccess: {},
    uploadTemplateError: {},

    generateSponsorInvoiceError: {},
    generatedSponsorInvoice: {},
    generatingSponsorInvoice: false,

    gettingSponsorInvoices: false,
    getSponsorInvoicesError: {},
    sponsorInvoices: [],

    getSponsorPaymentsError: {},
    sponsorPayments: {},
    gettingSponsorPayments: false,

    getSponsorStudentsError: {},
    sponsorStudents: [],
    gettingSponsorStudents: false,

    allocatingFunds: false,
    allocateFundsError: {},
    allocateFundsSuccess: {},

    reGeneratePRNError: {},
    reGeneratedPRN: {},
    reGeneratingPRN: false,

    selectedSponsor: {},
    selectedContext: {},

    showSponsorModal: false,
    sponsorInvoiceToAllocate: {},

    getSponsorAllocationError: {},
    gettingSponsorAllocation: false,
    allSponsorAllocations: [],

    deAllocateSponsorPaymentsError: {},
    deAllocateSponsorPaymentsSuccess: {},
    deAllocatingSponsorPayments: false,

    sponsorCurrentMenu: '',
    selectedSponsorAllocation: {},
  },
  feesStructure: {
    gettingFeesStructure: false,
    feesStructures: [],
    feesStructureError: {},
  },
  financialStatement: {
    gettingFinancialStatement: false,
    financialStatements: [],
    financialStatementError: {},

    gettingStudentAccountStatement: false,
    accountStatements: [],
    accountStatementError: {},

    pushingStudentCredit: false,
    pushCreditSuccess: {},
    pushCreditError: {},

    gettingPushCreditRequests: false,
    pushedCreditRequests: [],
    getPushedCreditError: {},

    approving: false,
    approveSuccess: {},
    approveError: {},

    downloading: false,
    downloadSuccess: {},
    downloadError: {},
  },
  studentLedger: {
    gettingStudentLedger: false,
    studentLedgerError: {},
    studentLedgers: [],
  },
};

export default paymentAndTransaction;
