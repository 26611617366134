const sponsor = {
  GET_SPONSORS_REQUEST: 'GET_SPONSORS_REQUEST',
  GET_SPONSORS_SUCCESS: 'GET_SPONSORS_SUCCESS',
  GET_SPONSORS_ERROR: 'GET_SPONSORS_ERROR',

  GET_SPONSOR_BY_CONTEXT_REQUEST: 'GET_SPONSOR_BY_CONTEXT_REQUEST',
  GET_SPONSOR_BY_CONTEXT_SUCCESS: 'GET_SPONSOR_BY_CONTEXT_SUCCESS',
  GET_SPONSOR_BY_CONTEXT_ERROR: 'GET_SPONSOR_BY_CONTEXT_ERROR',

  CREATE_SPONSOR_REQUEST: 'CREATE_SPONSOR_REQUEST',
  CREATE_SPONSOR_SUCCESS: 'CREATE_SPONSOR_SUCCESS',
  CREATE_SPONSOR_ERROR: 'CREATE_SPONSOR_ERROR',

  GENERATE_SPONSOR_INVOICE_REQUEST: 'GENERATE_SPONSOR_INVOICE_REQUEST',
  GENERATE_SPONSOR_INVOICE_SUCCESS: 'GENERATE_SPONSOR_INVOICE_SUCCESS',
  GENERATE_SPONSOR_INVOICE_ERROR: 'GENERATE_SPONSOR_INVOICE_ERROR',

  RE_GENERATE_SPONSOR_PRN_REQUEST: 'RE_GENERATE_SPONSOR_PRN_REQUEST',
  RE_GENERATE_SPONSOR_PRN_SUCCESS: 'RE_GENERATE_SPONSOR_PRN_SUCCESS',
  RE_GENERATE_SPONSOR_PRN_ERROR: 'RE_GENERATE_SPONSOR_PRN_ERROR',

  GET_SPONSOR_INVOICES_REQUEST: 'GET_SPONSOR_INVOICES_REQUEST',
  GET_SPONSOR_INVOICES_SUCCESS: 'GET_SPONSOR_INVOICES_SUCCESS',
  GET_SPONSOR_INVOICES_ERROR: 'GET_SPONSOR_INVOICES_ERROR',

  GET_SPONSOR_PAYMENTS_REQUEST: 'GET_SPONSOR_PAYMENTS_REQUEST',
  GET_SPONSOR_PAYMENTS_SUCCESS: 'GET_SPONSOR_PAYMENTS_SUCCESS',
  GET_SPONSOR_PAYMENTS_ERROR: 'GET_SPONSOR_PAYMENTS_ERROR',

  GET_SPONSOR_ALLOCATIONS_REQUEST: 'GET_SPONSOR_ALLOCATIONS_REQUEST',
  GET_SPONSOR_ALLOCATIONS_SUCCESS: 'GET_SPONSOR_ALLOCATIONS_SUCCESS',
  GET_SPONSOR_ALLOCATIONS_ERROR: 'GET_SPONSOR_ALLOCATIONS_ERROR',

  DEALLOCATE_SPONSOR_PAYMENTS_REQUEST: 'DEALLOCATE_SPONSOR_PAYMENTS_REQUEST',
  DEALLOCATE_SPONSOR_PAYMENTS_SUCCESS: 'DEALLOCATE_SPONSOR_PAYMENTS_SUCCESS',
  DEALLOCATE_SPONSOR_PAYMENTS_ERROR: 'DEALLOCATE_SPONSOR_PAYMENTS_ERROR',

  GET_SPONSOR_STUDENTS_REQUEST: 'GET_SPONSOR_STUDENTS_REQUEST',
  GET_SPONSOR_STUDENTS_SUCCESS: 'GET_SPONSOR_STUDENTS_SUCCESS',
  GET_SPONSOR_STUDENTS_ERROR: 'GET_SPONSOR_STUDENTS_ERROR',

  UPDATE_SPONSOR_REQUEST: 'UPDATE_SPONSOR_REQUEST',
  UPDATE_SPONSOR_SUCCESS: 'UPDATE_SPONSOR_SUCCESS',
  UPDATE_SPONSOR_ERROR: 'UPDATE_SPONSOR_ERROR',

  DELETE_SPONSOR_REQUEST: 'DELETE_SPONSOR_REQUEST',
  DELETE_SPONSOR_SUCCESS: 'DELETE_SPONSOR_SUCCESS',
  DELETE_SPONSOR_ERROR: 'DELETE_SPONSOR_ERROR',

  DOWNLOAD_SPONSOR_TEMPLATE_REQUEST: 'DOWNLOAD_SPONSOR_TEMPLATE_REQUEST',
  DOWNLOAD_SPONSOR_TEMPLATE_SUCCESS: 'DOWNLOAD_SPONSOR_TEMPLATE_SUCCESS',
  DOWNLOAD_SPONSOR_TEMPLATE_ERROR: 'DOWNLOAD_SPONSOR_TEMPLATE_ERROR',

  UPLOAD_SPONSOR_TEMPLATE_REQUEST: 'UPLOAD_SPONSOR_TEMPLATE_REQUEST',
  UPLOAD_SPONSOR_TEMPLATE_SUCCESS: 'UPLOAD_SPONSOR_TEMPLATE_SUCCESS',
  UPLOAD_SPONSOR_TEMPLATE_ERROR: 'UPLOAD_SPONSOR_TEMPLATE_ERROR',

  ALLOCATE_SPONSOR_FUNDS_REQUEST: 'ALLOCATE_SPONSOR_FUNDS_REQUEST',
  ALLOCATE_SPONSOR_FUNDS_SUCCESS: 'ALLOCATE_SPONSOR_FUNDS_SUCCESS',
  ALLOCATE_SPONSOR_FUNDS_ERROR: 'ALLOCATE_SPONSOR_FUNDS_ERROR',

  SET_SELECTED_SPONSOR: 'SET_SELECTED_SPONSOR',
  SET_SELECTED_CONTEXT: 'SET_SELECTED_CONTEXT',
  SHOW_SPONSOR_MODAL: 'SHOW_SPONSOR_MODAL',
  SET_SPONSOR_INVOICE_TO_ALLOCATE: 'SET_SPONSOR_INVOICE_TO_ALLOCATE',
  SET_CURRENT_SPONSOR_ALLOCATION: 'SET_CURRENT_SPONSOR_ALLOCATION',
  SET_SPONSOR_CURRENT_MENU: 'SET_SPONSOR_CURRENT_MENU',

  getSponsors: () => ({
    type: sponsor.GET_SPONSORS_REQUEST,
  }),

  getSponsorByContext: (context) => ({
    type: sponsor.GET_SPONSOR_BY_CONTEXT_REQUEST,
    context,
  }),

  setSelectedSponsor: (context) => ({
    type: sponsor.SET_SELECTED_SPONSOR,
    context,
  }),

  showSponsorModal: (payload) => ({
    type: sponsor.SHOW_SPONSOR_MODAL,
    payload,
  }),

  setSelectedContext: (context) => ({
    type: sponsor.SET_SELECTED_CONTEXT,
    context,
  }),

  createSponsors: (data) => ({
    type: sponsor.CREATE_SPONSOR_REQUEST,
    data,
  }),

  generateSponsorInvoice: (data) => ({
    type: sponsor.GENERATE_SPONSOR_INVOICE_REQUEST,
    data,
  }),

  regeneratePRN: (invoiceId, sponsorId) => ({
    type: sponsor.RE_GENERATE_SPONSOR_PRN_REQUEST,
    sponsorId,
    invoiceId,
  }),

  getSponsorInvoices: (sponsorId) => ({
    type: sponsor.GET_SPONSOR_INVOICES_REQUEST,
    sponsorId,
  }),

  getSponsorPayments: (sponsorId) => ({
    type: sponsor.GET_SPONSOR_PAYMENTS_REQUEST,
    sponsorId,
  }),

  getSponsorAllocations: (allocationId) => ({
    type: sponsor.GET_SPONSOR_ALLOCATIONS_REQUEST,
    allocationId,
  }),

  deAllocateSponsorPayments: (data, allocationId) => ({
    type: sponsor.DEALLOCATE_SPONSOR_PAYMENTS_REQUEST,
    allocationId,
    data,
  }),

  getSponsorStudents: (sponsorId) => ({
    type: sponsor.GET_SPONSOR_STUDENTS_REQUEST,
    sponsorId,
  }),

  updateSponsors: (sponsorId, payload) => ({
    type: sponsor.UPDATE_SPONSOR_REQUEST,
    sponsorId,
    payload,
  }),

  downloadSponsorTemplate: (param) => ({
    type: sponsor.DOWNLOAD_SPONSOR_TEMPLATE_REQUEST,
    param,
  }),

  uploadSponsorTemplate: (data, param) => ({
    type: sponsor.UPLOAD_SPONSOR_TEMPLATE_REQUEST,
    data,
    param,
  }),

  allocateSponsorFunds: (data, transactionId, sponsorId) => ({
    type: sponsor.ALLOCATE_SPONSOR_FUNDS_REQUEST,
    transactionId,
    sponsorId,
    data,
  }),

  setSponsorInvoiceToAllocate: (data) => ({
    type: sponsor.SET_SPONSOR_INVOICE_TO_ALLOCATE,
    data,
  }),

  setCurrentMenu: (payload) => ({
    type: sponsor.SET_SPONSOR_CURRENT_MENU,
    payload,
  }),

  setCurrentAllocation: (payload) => ({
    type: sponsor.SET_CURRENT_SPONSOR_ALLOCATION,
    payload,
  }),
};

export default sponsor;
