import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { systemUserActions } from '../../actions';
import initialState from '../../initialState';

function systemUser(state = initialState.systemUser, actions) {
  switch (actions.type) {
    case systemUserActions.GET_USERS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case systemUserActions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: actions.data.users,
        error: {},
        loading: false,
      };
    case systemUserActions.GET_USERS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };
    case systemUserActions.CREATE_USER_REQUEST:
      return {
        ...state,
        creating: true,
        createUserError: {},
        created: {},
      };
    case systemUserActions.CREATE_USER_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case systemUserActions.CREATE_USER_ERROR:
      return {
        ...state,
        creating: false,
        createUserError: actions.error,
      };

    case systemUserActions.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
        updatingId: actions.id,
      };
    case systemUserActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: actions.data,
      };
    case systemUserActions.UPDATE_USER_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };
    case systemUserActions.DELETE_USER_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: false,
        getUpdateId: actions.id,
      };
    case systemUserActions.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case systemUserActions.DELETE_USER_ERROR:
      return {
        ...state,
        deleting: false,
        error: actions.error,
      };

    case systemUserActions.GET_LECTURERS_REQUEST:
      return {
        ...state,
        gettingLecturers: true,
        getLecturerError: {},
      };
    case systemUserActions.GET_LECTURERS_SUCCESS:
      return {
        ...state,
        lecturers: actions.data,
        gettingLecturers: false,
      };
    case systemUserActions.GET_LECTURERS_ERROR:
      return {
        ...state,
        getLecturerError: actions.error,
        gettingLecturers: false,
      };

    case systemUserActions.GET_DEPARTMENT_LECTURERS_REQUEST:
      return {
        ...state,
        gettingDepartmentLecturers: true,
        getDepartmentLecturerError: {},
      };
    case systemUserActions.GET_DEPARTMENT_LECTURERS_SUCCESS: {
      const { departmentId, data } = actions;
      const { departmentLecturers } = state;

      const currentIndex = findIndex(
        departmentLecturers,
        (group) => group.departmentId === departmentId
      );

      const contentData = { data, departmentId };
      let newState = state;

      if (currentIndex === -1) {
        newState = update(departmentLecturers, { $push: [contentData] });
      } else {
        newState = update(departmentLecturers, {
          [currentIndex]: { $set: contentData },
        });
      }

      return {
        ...newState,
        gettingDepartmentLecturers: false,
      };
    }
    case systemUserActions.GET_DEPARTMENT_LECTURERS_ERROR:
      return {
        ...state,
        getDepartmentLecturerError: actions.error,
        gettingDepartmentLecturers: false,
      };

    case systemUserActions.SHOW_USER_MODAL:
      return {
        ...state,
        showUserModal: actions.payload,
      };

    default:
      return state;
  }
}

export default systemUser;
