import update from 'immutability-helper';
import { pujabSchoolAdminActions } from '../../actions';
import pujabMgtInitialState from '../../initialState/pujab-mgt';

const pujabApplicants = (
  state = pujabMgtInitialState.pujabSchoolAdmin,
  actions
) => {
  switch (actions.type) {
    case pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_REQUEST:
      return {
        ...state,
        gettingSchoolAdmins: true,
        context: actions.context,
        getSchoolAdminError: {},
      };
    case pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_SUCCESS: {
      const { context, data } = actions;
      const { schoolAdmins } = state;

      const findContextIndex = schoolAdmins.findIndex(
        (item) => item.uneb_center_id === context.uneb_center_id
      );

      const contextData = { ...context, ...data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          schoolAdmins: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          schoolAdmins: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingSchoolAdmins: false,
      };
    }
    case pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_ERROR:
      return {
        ...state,
        gettingSchoolAdmins: false,
        getSchoolAdminError: actions.error,
      };

    case pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
      };
    case pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_SUCCESS:
      return {
        ...state,
        creating: false,
        createdSchoolAdmin: actions.data,
      };
    case pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
      };
    case pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_SUCCESS:
      return {
        ...state,
        updating: false,
        updateSuccess: actions.data,
      };
    case pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    default:
      return state;
  }
};

export default pujabApplicants;
