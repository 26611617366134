const Receivable = {
  GET_RECEIVABLES_REQUEST: 'GET_RECEIVABLES_REQUEST',
  GET_RECEIVABLES_SUCCESS: 'GET_RECEIVABLES_SUCCESS',
  GET_RECEIVABLES_ERROR: 'GET_RECEIVABLES_ERROR',

  GET_SINGLE_RECEIVABLE_REQUEST: 'GET_SINGLE_RECEIVABLE_REQUEST',
  GET_SINGLE_RECEIVABLE_SUCCESS: 'GET_SINGLE_RECEIVABLE_SUCCESS',
  GET_SINGLE_RECEIVABLE_ERROR: 'GET_SINGLE_RECEIVABLE_ERROR',

  CREATE_RECEIVABLE_SUCCESS: 'CREATE_RECEIVABLE_SUCCESS',
  CREATE_RECEIVABLE_REQUEST: 'CREATE_RECEIVABLE_REQUEST',
  CREATE_RECEIVABLE_ERROR: 'CREATE_RECEIVABLE_ERROR',

  UPDATE_RECEIVABLE_SUCCESS: 'UPDATE_RECEIVABLE_SUCCESS',
  UPDATE_RECEIVABLE_REQUEST: 'UPDATE_RECEIVABLE_REQUEST',
  UPDATE_RECEIVABLE_ERROR: 'UPDATE_RECEIVABLE_ERROR',

  DELETE_RECEIVABLE_SUCCESS: 'DELETE_RECEIVABLE_SUCCESS',
  DELETE_RECEIVABLE_REQUEST: 'DELETE_RECEIVABLE_REQUEST',
  DELETE_RECEIVABLE_ERROR: 'DELETE_RECEIVABLE_ERROR',

  DOWNLOAD_RECEIVABLE_REPORT_SUCCESS: 'DOWNLOAD_RECEIVABLE_REPORT_SUCCESS',
  DOWNLOAD_RECEIVABLE_REPORT_REQUEST: 'DOWNLOAD_RECEIVABLE_REPORT_REQUEST',
  DOWNLOAD_RECEIVABLE_REPORT_ERROR: 'DOWNLOAD_RECEIVABLE_REPORT_ERROR',

  GET_RECEIVABLE_REPORT_REQUEST: 'GET_RECEIVABLE_REPORT_REQUEST',
  GET_RECEIVABLE_REPORT_SUCCESS: 'GET_RECEIVABLE_REPORT_SUCCESS',
  GET_RECEIVABLE_REPORT_ERROR: 'GET_RECEIVABLE_REPORT_ERROR',

  SET_CURRENT_RECEIVABLE_TAB: 'SET_CURRENT_RECEIVABLE_TAB',
  SET_RECEIVABLES_REPORT_CONTEXT: 'SET_RECEIVABLES_REPORT_CONTEXT',
  SHOW_DELETE_RECEIVABLE_MODAL: 'SHOW_DELETE_RECEIVABLE_MODAL',
  SHOW_CREATE_RECEIVABLE_MODAL: 'SHOW_CREATE_RECEIVABLE_MODAL',

  getReceivables: (data) => ({
    type: Receivable.GET_RECEIVABLES_REQUEST,
    data,
  }),

  createReceivable: (data) => ({
    type: Receivable.CREATE_RECEIVABLE_REQUEST,
    data,
  }),

  getSingleReceivable: (receivableId, data) => ({
    type: Receivable.GET_SINGLE_RECEIVABLE_REQUEST,
    receivableId,
    data,
  }),

  updateReceivable: (receivableId, data) => ({
    type: Receivable.UPDATE_RECEIVABLE_REQUEST,
    receivableId,
    data,
  }),

  deleteReceivable: (receivableId) => ({
    type: Receivable.DELETE_RECEIVABLE_REQUEST,
    receivableId,
  }),

  getReceivableReport: (data) => ({
    type: Receivable.GET_RECEIVABLE_REPORT_REQUEST,
    data,
  }),

  setCurrentTab: (currentTab) => ({
    type: Receivable.SET_CURRENT_RECEIVABLE_TAB,
    currentTab,
  }),

  downloadReceivableReport: (data) => ({
    type: Receivable.DOWNLOAD_RECEIVABLE_REPORT_REQUEST,
    data,
  }),

  setReceivablesContext: (context) => ({
    type: Receivable.SET_RECEIVABLES_REPORT_CONTEXT,
    context,
  }),

  showDeleteModal: (payload) => ({
    type: Receivable.SHOW_DELETE_RECEIVABLE_MODAL,
    payload,
  }),

  showCreateModal: (payload) => ({
    type: Receivable.SHOW_CREATE_RECEIVABLE_MODAL,
    payload,
  }),
};

export default Receivable;
