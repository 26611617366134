const grading = {
  GET_GRADING_REQUEST: 'GET_GRADING_REQUEST',
  GET_GRADING_SUCCESS: 'GET_GRADING_SUCCESS',
  GET_GRADING_ERROR: 'GET_GRADING_ERROR',

  CREATE_GRADING_SUCCESS: 'CREATE_GRADING_SUCCESS',
  CREATE_GRADING_REQUEST: 'CREATE_GRADING_REQUEST',
  CREATE_GRADING_ERROR: 'CREATE_GRADING_ERROR',

  GET_SINGLE_GRADING_SUCCESS: 'GET_SINGLE_GRADING_SUCCESS',
  GET_SINGLE_GRADING_REQUEST: 'GET_SINGLE_GRADING_REQUEST',
  GET_SINGLE_GRADING_ERROR: 'GET_SINGLE_GRADING_ERROR',

  UPDATE_GRADING_SUCCESS: 'UPDATE_GRADING_SUCCESS',
  UPDATE_GRADING_REQUEST: 'UPDATE_GRADING_REQUEST',
  UPDATE_GRADING_ERROR: 'UPDATE_GRADING_ERROR',

  SET_ACTIVE_GRADING_TAB: 'SET_ACTIVE_GRADING_TAB',

  getGradings: (data) => ({
    type: grading.GET_GRADING_REQUEST,
    data,
  }),

  createGrading: (data) => ({
    type: grading.CREATE_GRADING_REQUEST,
    data,
  }),

  getSingleGrading: (programmeId) => ({
    type: grading.GET_SINGLE_GRADING_REQUEST,
    programmeId,
  }),

  updateGrading: (gradingId, data) => ({
    type: grading.UPDATE_GRADING_REQUEST,
    gradingId,
    data,
  }),

  setActiveTab: (payload) => ({
    type: grading.SET_ACTIVE_GRADING_TAB,
    payload,
  }),
};

export default grading;
