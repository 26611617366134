const pujabProgrammeActions = {
  GET_INSTITUTION_PROGRAMMES_REQUEST: 'GET_INSTITUTION_PROGRAMMES_REQUEST',
  GET_INSTITUTION_PROGRAMMES_SUCCESS: 'GET_INSTITUTION_PROGRAMMES_SUCCESS',
  GET_INSTITUTION_PROGRAMMES_ERROR: 'GET_INSTITUTION_PROGRAMMES_ERROR',

  GET_SINGLE_PUJAB_PROGRAMME_REQUEST: 'GET_SINGLE_PUJAB_PROGRAMME_REQUEST',
  GET_SINGLE_PUJAB_PROGRAMME_SUCCESS: 'GET_SINGLE_PUJAB_PROGRAMME_SUCCESS',
  GET_SINGLE_PUJAB_PROGRAMME_ERROR: 'GET_SINGLE_PUJAB_PROGRAMME_ERROR',

  ADD_INSTITUTION_PROGRAMME_REQUEST: 'ADD_INSTITUTION_PROGRAMME_REQUEST',
  ADD_INSTITUTION_PROGRAMME_SUCCESS: 'ADD_INSTITUTION_PROGRAMME_SUCCESS',
  ADD_INSTITUTION_PROGRAMME_ERROR: 'ADD_INSTITUTION_PROGRAMME_ERROR',

  UPDATE_INSTITUTION_PROGRAMME_REQUEST: 'UPDATE_INSTITUTION_PROGRAMME_REQUEST',
  UPDATE_INSTITUTION_PROGRAMME_SUCCESS: 'UPDATE_INSTITUTION_PROGRAMME_SUCCESS',
  UPDATE_INSTITUTION_PROGRAMME_ERROR: 'UPDATE_INSTITUTION_PROGRAMME_ERROR',

  DELETE_INSTITUTION_PROGRAMME_REQUEST: 'DELETE_INSTITUTION_PROGRAMME_REQUEST',
  DELETE_INSTITUTION_PROGRAMME_SUCCESS: 'DELETE_INSTITUTION_PROGRAMME_SUCCESS',
  DELETE_INSTITUTION_PROGRAMME_ERROR: 'DELETE_INSTITUTION_PROGRAMME_ERROR',

  UPLOAD_INSTITUTION_PROGRAMMES_REQUEST:
    'UPLOAD_INSTITUTION_PROGRAMMES_REQUEST',
  UPLOAD_INSTITUTION_PROGRAMMES_SUCCESS:
    'UPLOAD_INSTITUTION_PROGRAMMES_SUCCESS',
  UPLOAD_INSTITUTION_PROGRAMMES_ERROR: 'UPLOAD_INSTITUTION_PROGRAMMES_ERROR',

  DOWNLOAD_PROGRAMMES_TEMPLATE_REQUEST: 'DOWNLOAD_PROGRAMMES_TEMPLATE_REQUEST',
  DOWNLOAD_PROGRAMMES_TEMPLATE_SUCCESS: 'DOWNLOAD_PROGRAMMES_TEMPLATE_SUCCESS',
  DOWNLOAD_PROGRAMMES_TEMPLATE_ERROR: 'DOWNLOAD_PROGRAMMES_TEMPLATE_ERROR',

  SET_SELECTED_INSTITUTION: 'SET_SELECTED_INSTITUTION',

  setSelectedInstitution: (data) => ({
    type: pujabProgrammeActions.SET_SELECTED_INSTITUTION,
    data,
  }),

  getInstitutionProgrammes: (institutionId) => ({
    type: pujabProgrammeActions.GET_INSTITUTION_PROGRAMMES_REQUEST,
    institutionId,
  }),

  getSingleProgramme: (programmeId) => ({
    type: pujabProgrammeActions.GET_SINGLE_PUJAB_PROGRAMME_REQUEST,
    programmeId,
  }),

  addInstitutionProgramme: (institutionId, data) => ({
    type: pujabProgrammeActions.ADD_INSTITUTION_PROGRAMME_REQUEST,
    institutionId,
    data,
  }),

  updateInstitutionProgramme: (id, data, institutionId) => ({
    type: pujabProgrammeActions.UPDATE_INSTITUTION_PROGRAMME_REQUEST,
    id,
    data,
    institutionId,
  }),

  deleteInstitutionProgramme: (id, institutionId) => ({
    type: pujabProgrammeActions.DELETE_INSTITUTION_PROGRAMME_REQUEST,
    id,
    institutionId,
  }),

  uploadInstitutionProgrammes: (data) => ({
    type: pujabProgrammeActions.UPLOAD_INSTITUTION_PROGRAMMES_REQUEST,
    data,
  }),

  downloadProgrammesTemplate: (data) => ({
    type: pujabProgrammeActions.DOWNLOAD_PROGRAMMES_TEMPLATE_REQUEST,
    data,
  }),
};

export default pujabProgrammeActions;
