import { feesWaiversActions } from '../../actions';
import initialState from '../../initialState';

const feesWaivers = (state = initialState.feesWaivers, actions) => {
  switch (actions.type) {
    case feesWaiversActions.GET_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case feesWaiversActions.GET_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        feesWaivers: actions.data,
        error: {},
        loading: false,
      };
    case feesWaiversActions.GET_FEES_WAIVERS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };
    case feesWaiversActions.CREATE_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };

    case feesWaiversActions.CREATE_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };

    case feesWaiversActions.CREATE_FEES_WAIVERS_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case feesWaiversActions.DELETE_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case feesWaiversActions.DELETE_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case feesWaiversActions.DELETE_FEES_WAIVERS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case feesWaiversActions.UPDATE_FEES_WAIVERS_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case feesWaiversActions.UPDATE_FEES_WAIVERS_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case feesWaiversActions.UPDATE_FEES_WAIVERS_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };
    case feesWaiversActions.SET_WAIVERS_CONTEXT:
      return {
        ...state,
        pendingContext: actions.context,
      };

    default:
      return state;
  }
};
export default feesWaivers;
