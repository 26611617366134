import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { admissionReportActions } from '../../actions';

function* getAdmissionReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: admissionReportActions.GET_ADMISSION_BI_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: admissionReportActions.GET_ADMISSION_BI_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionProgrammeReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/programmes`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionAgesReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/age`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdmissionReport() {
  yield takeLatest(
    admissionReportActions.GET_ADMISSION_BI_REPORT_REQUEST,
    getAdmissionReport
  );
}

function* watchGetAdmissionProgrammeReport() {
  yield takeLatest(
    admissionReportActions.GET_ADMISSION_BI_PROGRAMME_REPORT_REQUEST,
    getAdmissionProgrammeReport
  );
}

function* watchGetAdmissionAgesReport() {
  yield takeLatest(
    admissionReportActions.GET_ADMISSION_BI_AGE_REPORT_REQUEST,
    getAdmissionAgesReport
  );
}

export default [
  fork(watchGetAdmissionReport),
  fork(watchGetAdmissionProgrammeReport),
  fork(watchGetAdmissionAgesReport),
];
