const tabState = {
  currentTab: 'view',
  applicationPolicyTab: 'view',

  programmeTab: {
    active: 'programmes',
    activeView: 'details',
  },

  programmeVersionTab: {
    active: 'view',
  },

  feesWaiversTab: {
    active: 'view',
  },

  tuitionTab: {
    active: 'view',
  },

  functionalFeesTab: {
    active: 'view',
  },

  otherFeesTab: {
    active: 'view',
  },

  waiverDiscountsTab: {
    active: 'view',
  },

  academicYearTab: 'view-table',

  academicYearEventTab: {
    active: 'view-table',
  },

  viewProgrammeTab: {
    active: 'running-programmes',
  },

  selectedRunningProgrammeTab: {
    active: 'admission-details',
  },
  selectionListTab: {
    active: 'list_tab',
  },
  registrationPolicyTab: 'view',
};

export default tabState;
