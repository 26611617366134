import update from 'immutability-helper';
import { find, findIndex, isEmpty } from 'lodash';
import { studentRecordActions } from '../../actions';
import initialState from '../../initialState';

const studentRecord = (state = initialState.studentRecord, actions) => {
  switch (actions.type) {
    case studentRecordActions.GET_STUDENT_RECORD_REQUEST: {
      const { studentId } = actions;
      return {
        ...state,
        loadError: {},
        searchKey: studentId,
        loading: true,
      };
    }

    case studentRecordActions.GET_STUDENT_RECORD_SUCCESS: {
      const { studentRecords } = state;
      const { data, studentId } = actions;

      let newState = state;

      if (!isEmpty(data)) {
        const findStudentIndex = findIndex(
          studentRecords,
          (record) => record?.id === data?.id
        );

        if (findStudentIndex === -1) {
          newState = update(newState, {
            studentRecords: { $push: [data] },
          });
        } else {
          newState = update(newState, {
            studentRecords: { [findStudentIndex]: { $set: data } },
          });
        }
      }

      const findActiveProgramme = find(
        actions?.data?.academic_records,
        (p) => p.is_current_programme === true
      );
      const currentStudentProgramme =
        findActiveProgramme || actions.data?.academic_records?.[0] || {};

      return {
        ...newState,
        currentStudent: data || {},
        currentStudentProgramme,
        searchKey: studentId,
        error: {},
        loading: false,
      };
    }
    case studentRecordActions.GET_STUDENT_RECORD_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_REQUEST:
      return {
        ...state,
        updateCurrentProgError: {},
        updatingCurrentProg: true,
      };

    case studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_SUCCESS:
      return {
        ...state,
        updateCurrentProgSuccess: actions.data,
        updatingCurrentProg: false,
      };

    case studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_ERROR:
      return {
        ...state,
        updateCurrentProgError: actions.error,
        updatingCurrentProg: false,
      };

    case studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_REQUEST:
      return {
        ...state,
        academicStatusError: {},
        settingAcademicStatus: true,
      };

    case studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_SUCCESS:
      return {
        ...state,
        academicStatusSuccess: actions.data,
        settingAcademicStatus: false,
      };

    case studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_ERROR:
      return {
        ...state,
        academicStatusError: actions.error,
        settingAcademicStatus: false,
      };

    case studentRecordActions.CHANGE_VERIFICATION_STATUS_REQUEST:
      return {
        ...state,
        updateDocVerifyError: {},
        updatingDocVerifyStatus: true,
      };
    case studentRecordActions.CHANGE_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        updateDocVerifySuccess: actions.data,
        updatingDocVerifyStatus: false,
      };
    case studentRecordActions.CHANGE_VERIFICATION_STATUS_ERROR:
      return {
        ...state,
        updateDocVerifyError: actions.error,
        updatingDocVerifyStatus: false,
      };
    case studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_REQUEST:
      return {
        ...state,
        getVerifiedStudentDoc: true,
        verifiedStudentDocError: {},
      };
    case studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        getVerifiedStudentDoc: false,
        verifiedStudentDocSuccess: actions.data,
      };
    case studentRecordActions.GET_DOCUMENT_VERIFICATION_STATUS_ERROR:
      return {
        ...state,
        getVerifiedStudentDoc: false,
        verifiedStudentDocError: actions.error,
      };
    case studentRecordActions.SET_SELECTED_VERIFICATION_DOCUMENTS:
      return {
        ...state,
        selectedVerificationDocs: actions.selectedDocuments,
      };

    case studentRecordActions.SET_STUDENT_RECORD:
      return {
        ...state,
        currentStudent: actions.studentRecordToSet,
      };

    case studentRecordActions.SET_CURRENT_STUDENT_PROGRAMME:
      return {
        ...state,
        currentStudentProgramme: actions.currentStudentProgramme,
      };

    default:
      return state;
  }
};
export default studentRecord;
