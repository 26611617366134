import { retakerPolicyActions } from '../../actions';
import initialState from '../../initialState';

const retakerPolicy = (state = initialState.retakerPolicy, actions) => {
  switch (actions.type) {
    case retakerPolicyActions.GET_RETAKER_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case retakerPolicyActions.GET_RETAKER_POLICIES_SUCCESS:
      return {
        ...state,
        retakerPolicies: actions.data.data,
        loading: false,
      };
    case retakerPolicyActions.GET_RETAKER_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case retakerPolicyActions.CREATE_RETAKER_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };

    case retakerPolicyActions.CREATE_RETAKER_POLICY_SUCCESS:
      return {
        ...state,
        retakerPolicy: actions.data.retakerPolicy,
        creating: false,
      };

    case retakerPolicyActions.CREATE_RETAKER_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case retakerPolicyActions.UPDATE_RETAKER_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case retakerPolicyActions.UPDATE_RETAKER_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case retakerPolicyActions.UPDATE_RETAKER_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        addRetakePolicyElementError: {},
        addingRetakePolicyElement: true,
      };
    case retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        addingRetakePolicyElement: false,
      };
    case retakerPolicyActions.ADD_RETAKER_POLICY_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        addRetakePolicyElementError: actions.error,
        addingRetakePolicyElement: false,
      };

    case retakerPolicyActions.DELETE_RETAKER_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        deleting: true,
      };

    case retakerPolicyActions.DELETE_RETAKER_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };

    case retakerPolicyActions.DELETE_RETAKER_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_REQUEST:
      return {
        ...state,
        deletingElementError: {},
        deletingElements: true,
      };

    case retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_SUCCESS:
      return {
        ...state,
        deletingElementSuccess: actions.data,
        deletingElements: false,
      };

    case retakerPolicyActions.DELETE_RETAKER_POLICY_ELEMENT_ERROR:
      return {
        ...state,
        deletingElementError: actions.error,
        deletingElements: false,
      };

    case retakerPolicyActions.SET_RETAKE_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.retakerPolicy,
      };

    default:
      return state;
  }
};

export default retakerPolicy;
