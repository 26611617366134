const resultsCategoryPolicyActions = {
  CREATE_RESULT_CATEGORY_REQUEST: 'CREATE_RESULT_CATEGORY_POLICY_REQUEST',
  CREATE_RESULT_CATEGORY_SUCCESS: 'CREATE_RESULT_CATEGORY_POLICY_SUCCESS',
  CREATE_RESULT_CATEGORY_ERROR: 'CREATE_RESULT_CATEGORY_POLICY_ERROR',

  GET_RESULT_CATEGORY_POLICY_REQUEST: 'GET_RESULT_CATEGORY_POLICY_REQUEST',
  GET_RESULT_CATEGORY_POLICY_SUCCESS: 'GET_RESULT_CATEGORY_POLICY_SUCCESS',
  GET_RESULT_CATEGORY_POLICY_ERROR: 'GET_RESULT_CATEGORY_POLICY_ERROR',

  EDIT_RESULT_CATEGORY_POLICY_REQUEST: 'EDIT_RESULT_CATEGORY_POLICY_REQUEST',
  EDIT_RESULT_CATEGORY_POLICY_SUCCESS: 'EDIT_RESULT_CATEGORY_POLICY_SUCCESS',
  EDIT_RESULT_CATEGORY_POLICY_ERROR: 'EDIT_RESULT_CATEGORY_POLICY_ERROR',

  EDIT_DEGREE_CLASS_POLICY_REQUEST: 'EDIT_DEGREE_CLASS_POLICY_REQUEST',
  EDIT_DEGREE_CLASS_POLICY_SUCCESS: 'EDIT_DEGREE_CLASS_POLICY_SUCCESS',
  EDIT_DEGREE_CLASS_POLICY_ERROR: 'EDIT_DEGREE_CLASS_POLICY_ERROR',

  DELETE_RESULT_CATEGORY_REQUEST: 'DELETE_RESULT_CATEGORY_POLICY_REQUEST',
  DELETE_RESULT_CATEGORY_SUCCESS: 'DELETE_RESULT_CATEGORY_POLICY_SUCCESS',
  DELETE_RESULT_CATEGORY_ERROR: 'DELETE_RESULT_CATEGORY_POLICY_ERROR',

  DELETE_DEGREE_CLASS_POLICY_REQUEST: 'DELETE_DEGREE_CLASS_POLICY_REQUEST',
  DELETE_DEGREE_CLASS_POLICY_SUCCESS: 'DELETE_DEGREE_CLASS_POLICY_SUCCESS',
  DELETE_DEGREE_CLASS_POLICY_ERROR: 'DELETE_DEGREE_CLASS_POLICY_ERROR',

  CREATE_DEGREE_CLASS_REQUEST: 'CREATE_DEGREE_CLASS_POLICY_REQUEST',
  CREATE_DEGREE_CLASS_SUCCESS: 'CREATE_DEGREE_CLASS_POLICY_SUCCESS',
  CREATE_DEGREE_CLASS_ERROR: 'CREATE_DEGREE_CLASS_POLICY_ERROR',

  GET_DEGREE_CLASS_REQUEST: 'GET_DEGREE_CLASS_REQUEST',
  GET_DEGREE_CLASS_SUCCESS: 'GET_DEGREE_CLASS_SUCCESS',
  GET_DEGREE_CLASS_ERROR: 'GET_DEGREE_CLASS_ERROR',

  SET_RESULT_CATEGORY_POLICY_TO_EDIT: 'SET_RESULT_CATEGORY_POLICY_TO_EDIT',
  SET_SELECTED_STUDY_LEVEL: 'SET_SELECTED_STUDY_LEVEL',

  CreatePolicy: (data) => ({
    type: resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_REQUEST,
    data,
  }),

  createDegreeClassPolicy: (data, actionType) => ({
    type: resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_REQUEST,
    data,
    actionType,
  }),

  getResultsCategoryPolicies: () => ({
    type: resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_REQUEST,
  }),

  getDegreeClassPolicies: () => ({
    type: resultsCategoryPolicyActions.GET_DEGREE_CLASS_REQUEST,
  }),

  UpdatePolicy: (policyId, data) => ({
    type: resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_REQUEST,
    policyId,
    data,
  }),

  UpdateDegreeClassPolicy: (policyId, data) => ({
    type: resultsCategoryPolicyActions.EDIT_DEGREE_CLASS_POLICY_REQUEST,
    policyId,
    data,
  }),

  deletePolicy: (policyId) => ({
    type: resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_REQUEST,
    policyId,
  }),

  deleteDegreeClassPolicy: (policyId) => ({
    type: resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_REQUEST,
    policyId,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: resultsCategoryPolicyActions.SET_RESULT_CATEGORY_POLICY_TO_EDIT,
    policyToEdit,
  }),

  setSelectedLevel: (level) => ({
    type: resultsCategoryPolicyActions.SET_SELECTED_STUDY_LEVEL,
    level,
  }),
};

export default resultsCategoryPolicyActions;
