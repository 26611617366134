import { unebCenterActions } from '../../actions';
import initialState from '../../initialState';

function unebCenter(state = initialState.unebCenter, actions) {
  switch (actions.type) {
    case unebCenterActions.GET_UNEB_CENTERS_REQUEST:
      return {
        ...state,
        unebCenterError: {},
        createdUnebCenter: {},
        loading: true,
      };
    case unebCenterActions.GET_UNEB_CENTERS_SUCCESS:
      return {
        ...state,
        unebCenters: actions.data,
        unebCenterError: {},
        loading: false,
      };
    case unebCenterActions.GET_UNEB_CENTERS_ERROR:
      return {
        ...state,
        unebCenterError: actions.error,
        loading: false,
      };
    case unebCenterActions.GET_SINGLE_UNEB_CENTER_REQUEST:
      return {
        ...state,
        unebCenterError: {},
        createdUnebCenter: {},
        fetching: true,
      };
    case unebCenterActions.GET_SINGLE_UNEB_CENTER_SUCCESS:
      return {
        ...state,
        unebCenters: actions.data,
        unebCenterError: {},
        fetching: false,
      };
    case unebCenterActions.GET_SINGLE_UNEB_CENTER_ERROR:
      return {
        ...state,
        unebCenterError: actions.error,
        fetching: false,
      };
    case unebCenterActions.CREATE_UNEB_CENTER_REQUEST:
      return {
        ...state,
        creating: true,
        unebCenterError: {},
        createdUnebCenter: {},
      };
    case unebCenterActions.CREATE_UNEB_CENTER_SUCCESS:
      return {
        ...state,
        creating: false,
        createdUnebCenter: actions.data,
      };
    case unebCenterActions.CREATE_UNEB_CENTER_ERROR:
      return {
        ...state,
        creating: false,
        unebCenterError: actions.error,
      };

    case unebCenterActions.DELETE_UNEB_CENTER_REQUEST:
      return {
        ...state,
        deletingUnebCenter: true,
        deleteUnebCenterError: {},
      };
    case unebCenterActions.DELETE_UNEB_CENTER_SUCCESS:
      return {
        ...state,
        deletingUnebCenter: false,
        deleteUnebCenterSuccess: actions.data,
      };
    case unebCenterActions.DELETE_UNEB_CENTER_ERROR:
      return {
        ...state,
        deletingUnebCenter: false,
        deleteUnebCenterError: actions.error,
      };

    case unebCenterActions.UPDATE_UNEB_CENTER_REQUEST:
      return {
        ...state,
        updating: true,
        unebCenterError: {},
        createdUnebCenter: {},
      };
    case unebCenterActions.UPDATE_UNEB_CENTER_SUCCESS:
      return {
        ...state,
        updating: false,
        createdUnebCenter: actions.data,
      };
    case unebCenterActions.UPDATE_UNEB_CENTER_ERROR:
      return {
        ...state,
        updating: false,
        unebCenterError: actions.error,
      };

    default:
      return state;
  }
}

export default unebCenter;
