import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { userLogsActions } from '../../actions';

function* getUserLogs() {
  try {
    const response = yield axios({
      url: `/app/audit-trails/recent`,
      method: 'GET',
    });
    yield put({
      type: userLogsActions.GET_USER_LOGS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: userLogsActions.GET_USER_LOGS_ERROR,
      error: error.data,
    });
  }
}

function* getApplicationLogs(actions) {
  try {
    const response = yield axios({
      url: `/app/audit-trails/all-logs`,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: userLogsActions.GET_APPLICATION_LOGS_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: userLogsActions.GET_APPLICATION_LOGS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUserLogs() {
  yield takeLatest(userLogsActions.GET_USER_LOGS_REQUEST, getUserLogs);
}

function* watchGetApplicationLogs() {
  yield takeLatest(
    userLogsActions.GET_APPLICATION_LOGS_REQUEST,
    getApplicationLogs
  );
}

export default [fork(watchGetUserLogs), fork(watchGetApplicationLogs)];
