const academicRegistrar = {
  graduationContext: {},

  gettingGraduationStats: false,
  graduationStats: [],
  graduationStatsError: {},

  gettingGraduationReport: false,
  graduationReport: [],
  graduationReportError: {},

  gettingEnrollmentReport: false,
  enrollmentReport: [],
  enrollmentReportError: {},

  downloadContext: {},

  downloadingReport: false,
  downloadedReport: {},
  downloadError: {},

  gettingDemographicReport: false,
  demographicReport: [],
  demographicReportError: {},
};

export default academicRegistrar;
