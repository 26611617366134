const systemSponsorActions = {
  GET_ACMIS_SPONSORS_REQUEST: 'GET_ACMIS_SPONSORS_REQUEST',
  GET_ACMIS_SPONSORS_SUCCESS: 'GET_ACMIS_SPONSORS_SUCCESS',
  GET_ACMIS_SPONSORS_ERROR: 'GET_ACMIS_SPONSORS_ERROR',

  GET_EMIS_SPONSORS_REQUEST: 'GET_EMIS_SPONSORS_REQUEST',
  GET_EMIS_SPONSORS_SUCCESS: 'GET_EMIS_SPONSORS_SUCCESS',
  GET_EMIS_SPONSORS_ERROR: 'GET_EMIS_SPONSORS_ERROR',

  SUBMIT_SYSTEM_SPONSORS_REQUEST: 'SUBMIT_SYSTEM_SPONSORS_REQUEST',
  SUBMIT_SYSTEM_SPONSORS_SUCCESS: 'SUBMIT_SYSTEM_SPONSORS_SUCCESS',
  SUBMIT_SYSTEM_SPONSORS_ERROR: 'SUBMIT_SYSTEM_SPONSORS_ERROR',

  getAcmisSponsors: () => ({
    type: systemSponsorActions.GET_ACMIS_SPONSORS_REQUEST,
  }),

  getEmisSponsors: () => ({
    type: systemSponsorActions.GET_EMIS_SPONSORS_REQUEST,
  }),

  submitSponsors: (data) => ({
    type: systemSponsorActions.SUBMIT_SYSTEM_SPONSORS_REQUEST,
    data,
  }),
};

export default systemSponsorActions;
