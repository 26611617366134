import { registrationPolicyActions } from '../../actions';
import initialState from '../../initialState';

const registrationPolicy = (
  state = initialState.registrationPolicy,
  actions
) => {
  switch (actions.type) {
    case registrationPolicyActions.GET_REGISTRATION_POLICIES_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case registrationPolicyActions.GET_REGISTRATION_POLICIES_SUCCESS:
      return {
        ...state,
        registrationPolicies: actions.data.data,
        loading: false,
      };
    case registrationPolicyActions.GET_REGISTRATION_POLICIES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case registrationPolicyActions.CREATE_REGISTRATION_POLICY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case registrationPolicyActions.CREATE_REGISTRATION_POLICY_SUCCESS:
      return {
        ...state,
        registrationPolicy: actions.data.registrationPolicy,
        creating: false,
      };
    case registrationPolicyActions.CREATE_REGISTRATION_POLICY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case registrationPolicyActions.UPDATE_REGISTRATION_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case registrationPolicyActions.UPDATE_REGISTRATION_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case registrationPolicyActions.UPDATE_REGISTRATION_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case registrationPolicyActions.DELETE_REGISTRATION_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        deleting: true,
      };
    case registrationPolicyActions.DELETE_REGISTRATION_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case registrationPolicyActions.DELETE_REGISTRATION_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case registrationPolicyActions.SET_REGISTRATION_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.registrationPolicy,
      };

    default:
      return state;
  }
};

export default registrationPolicy;
