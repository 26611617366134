import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import FileSaver from 'file-saver';
import { specialEntryApplicantActions } from '../../actions';

function* getApplicants() {
  try {
    const response = yield axios({
      url: '/mature-age/uneb-report/applicants-by-first-choice',
      method: 'GET',
    });
    yield put({
      type: specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_ERROR,
      error: error.data,
    });
  }
}

function* getMeritAdmissions() {
  try {
    const response = yield axios({
      url: '/mature-age/uneb-report/proposed-merit-admissions',
      method: 'GET',
    });
    yield put({
      type: specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* updateSpecialEntryApplicantAdmission(actions) {
  try {
    const response = yield axios({
      url: `/mature-age/uneb-report/${actions.applicantType}/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_SUCCESS,
      data: response.data,
    });
    if (actions.applicantType === 'applicants-by-first-choice') {
      yield put({
        type: specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST,
      });
    } else {
      yield put({
        type: specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST,
      });
    }
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* uploadApplicants(actions) {
  try {
    const response = yield axios({
      url: '/mature-age/uneb-report/upload-applicant-by-first-choice-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS,
      data: response,
    });
    yield put({
      type: specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadApplicantsTemplate() {
  try {
    yield axios({
      url: '/mature-age/uneb-report/download-applicant-by-first-choice-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-MATURE_AGE-APPLICANTS-FIRST-CHOICE-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadMeritAdmissions(actions) {
  try {
    const response = yield axios({
      url: '/mature-age/uneb-report/upload-proposed-merit-admission-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS,
      data: response,
    });
    yield put({
      type: specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadMeritAdmissionsTemplate() {
  try {
    yield axios({
      url: '/mature-age/uneb-report/download-proposed-merit-admission-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-MATURE_AGE-MERIT-ADMISSIONS-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetAdmissions() {
  yield takeLatest(
    specialEntryApplicantActions.GET_SPECIAL_ENTRY_EXAM_APPLICANTS_REQUEST,
    getApplicants
  );
}

function* watchGetMeritAdmissions() {
  yield takeLatest(
    specialEntryApplicantActions.GET_PROPOSED_ADMISSIONS_REQUEST,
    getMeritAdmissions
  );
}

function* watchUpdateSpecialEntryApplicantAdmission() {
  yield takeLatest(
    specialEntryApplicantActions.UPDATE_SPECIAL_ENTRY_EXAM_APPLICANT_ADMISSION_REQUEST,
    updateSpecialEntryApplicantAdmission
  );
}

function* watchUploadApplicants() {
  yield takeLatest(
    specialEntryApplicantActions.UPLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST,
    uploadApplicants
  );
}

function* watchDownloadApplicantsTemplate() {
  yield takeLatest(
    specialEntryApplicantActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANTS_TEMPLATE_REQUEST,
    downloadApplicantsTemplate
  );
}

function* watchUploadMeritAdmissions() {
  yield takeLatest(
    specialEntryApplicantActions.UPLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST,
    uploadMeritAdmissions
  );
}

function* watchDownloadMeritAdmissionsTemplate() {
  yield takeLatest(
    specialEntryApplicantActions.DOWNLOAD_PROPOSED_ADMISSION_TEMPLATE_REQUEST,
    downloadMeritAdmissionsTemplate
  );
}

export default [
  fork(watchGetAdmissions),
  fork(watchGetMeritAdmissions),
  fork(watchUploadApplicants),
  fork(watchDownloadApplicantsTemplate),
  fork(watchUploadMeritAdmissions),
  fork(watchUpdateSpecialEntryApplicantAdmission),
  fork(watchDownloadMeritAdmissionsTemplate),
];
