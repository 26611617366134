import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { systemProgrammeActions } from '../../actions';

function* getAcmisProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/acmis-programmes`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemProgrammeActions.GET_ACMIS_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: systemProgrammeActions.GET_ACMIS_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getEmisProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/courses`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemProgrammeActions.GET_EMIS_PROGRAMMES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemProgrammeActions.GET_EMIS_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getEmisProgrammeCategories(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/course-categories`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_ERROR,
      error: error.data,
    });
  }
}

function* submitProgramme(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/programmes`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: systemProgrammeActions.GET_ACMIS_PROGRAMMES_REQUEST,
    });
  } catch (error) {
    yield put({
      type: systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* manageProgramme(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/manage-programmes`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
    yield put({
      type: systemProgrammeActions.GET_ACMIS_PROGRAMMES_REQUEST,
    });
  } catch (error) {
    yield put({
      type: systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcmisProgrammes() {
  yield takeLatest(
    systemProgrammeActions.GET_ACMIS_PROGRAMMES_REQUEST,
    getAcmisProgrammes
  );
}
function* watchGetEmisProgrammes() {
  yield takeLatest(
    systemProgrammeActions.GET_EMIS_PROGRAMMES_REQUEST,
    getEmisProgrammes
  );
}
function* watchGetEmisProgrammeCategories() {
  yield takeLatest(
    systemProgrammeActions.GET_EMIS_COURSE_CATEGORIES_REQUEST,
    getEmisProgrammeCategories
  );
}
function* watchSubmitProgramme() {
  yield takeLatest(
    systemProgrammeActions.SUBMIT_SYSTEM_PROGRAMMES_REQUEST,
    submitProgramme
  );
}
function* watchManageProgramme() {
  yield takeLatest(
    systemProgrammeActions.UPDATE_ACMIS_PROGRAMMES_REQUEST,
    manageProgramme
  );
}

export default [
  fork(watchGetAcmisProgrammes),
  fork(watchSubmitProgramme),
  fork(watchGetEmisProgrammes),
  fork(watchManageProgramme),
  fork(watchGetEmisProgrammeCategories),
];
