const pujabReportActions = {
  GET_PUJAB_APPLICANT_REPORTS_REQUEST: 'GET_PUJAB_APPLICANT_REPORTS_REQUEST',
  GET_PUJAB_APPLICANT_REPORTS_SUCCESS: 'GET_PUJAB_APPLICANT_REPORTS_SUCCESS',
  GET_PUJAB_APPLICANT_REPORTS_ERROR: 'GET_PUJAB_APPLICANT_REPORTS_ERROR',

  DOWNLOAD_PUJAB_APPLICANT_REPORTS_REQUEST:
    'DOWNLOAD_PUJAB_APPLICANT_REPORTS_REQUEST',
  DOWNLOAD_PUJAB_APPLICANT_REPORTS_SUCCESS:
    'DOWNLOAD_PUJAB_APPLICANT_REPORTS_SUCCESS',
  DOWNLOAD_PUJAB_APPLICANT_REPORTS_ERROR:
    'DOWNLOAD_PUJAB_APPLICANT_REPORTS_ERROR',

  GET_PUJAB_REPORT_DETAILS_REQUEST: 'GET_PUJAB_REPORT_DETAILS_REQUEST',
  GET_PUJAB_REPORT_DETAILS_SUCCESS: 'GET_PUJAB_REPORT_DETAILS_SUCCESS',
  GET_PUJAB_REPORT_DETAILS_ERROR: 'GET_PUJAB_REPORT_DETAILS_ERROR',

  GET_PUJAB_PROGRAMME_CHOICES_REQUEST: 'GET_PUJAB_PROGRAMME_CHOICES_REQUEST',
  GET_PUJAB_PROGRAMME_CHOICES_SUCCESS: 'GET_PUJAB_PROGRAMME_CHOICES_SUCCESS',
  GET_PUJAB_PROGRAMME_CHOICES_ERROR: 'GET_PUJAB_PROGRAMME_CHOICES_ERROR',

  getPujabApplicationReport: (academicYear) => ({
    type: pujabReportActions.GET_PUJAB_APPLICANT_REPORTS_REQUEST,
    academicYear,
  }),

  downloadPujabApplicationReport: (academicYear, category) => ({
    type: pujabReportActions.DOWNLOAD_PUJAB_APPLICANT_REPORTS_REQUEST,
    academicYear,
    category,
  }),

  getPujabReportDetail: (academicYear) => ({
    type: pujabReportActions.GET_PUJAB_REPORT_DETAILS_REQUEST,
    academicYear,
  }),

  getProgrammeChoices: (applicationId) => ({
    type: pujabReportActions.GET_PUJAB_PROGRAMME_CHOICES_REQUEST,
    applicationId,
  }),
};

export default pujabReportActions;
