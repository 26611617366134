const revenueReportActions = {
  GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST:
    'GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST',
  GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS:
    'GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS',
  GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR:
    'GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR',

  GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST:
    'GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST',
  GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS:
    'GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS',
  GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR:
    'GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR',

  GET_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST:
    'GET_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST',
  GET_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS:
    'GET_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS',
  GET_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR:
    'GET_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR',

  DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST:
    'DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST',
  DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS:
    'DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_SUCCESS',
  DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR:
    'DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_ERROR',

  getAcadYrCollectionReport: (params) => ({
    type: revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST,
    params,
  }),

  getAcadUnitCollectionReport: (params) => ({
    type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST,
    params,
  }),

  getAcadUnitCollectionDetails: (params) => ({
    type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST,
    params,
  }),

  downloadAcadUnitCollectionDetails: (params) => ({
    type: revenueReportActions.DOWNLOAD_ACADEMIC_UNIT_COLLECTION_DETAILS_REQUEST,
    params,
  }),
};

export default revenueReportActions;
