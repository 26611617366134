import { isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import InputText from './InputText';

function SelectField({ setValue, returnWithLabel, isMulti, ...props }) {
  const onSelectOption = (data) => {
    if (isMulti && Array.isArray(data)) {
      const valueList = data.map(({ value }) => value);
      return setValue(valueList);
    }
    if (returnWithLabel === true) {
      return !isEmpty(data) ? setValue(data) : setValue('');
    }
    return !isEmpty(data) ? setValue(data.value) : setValue('');
  };

  return (
    <InputText
      type="select"
      onChange={onSelectOption}
      isMulti={isMulti}
      {...props}
    />
  );
}
SelectField.defaultProps = {
  props: {},
  returnWithLabel: false,
  isMulti: false,
  setValue: null,
};

SelectField.propTypes = {
  setValue: PropTypes.func,
  props: PropTypes.oneOfType([object]),
  returnWithLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default SelectField;
