import update from 'immutability-helper';
import { financialStatementActions } from '../../actions';
import initialState from '../../initialState';

const financialStatement = (
  state = initialState.financialStatement,
  actions
) => {
  switch (actions.type) {
    case financialStatementActions.GET_FINANCIAL_STATEMENT_REQUEST:
      return {
        ...state,
        gettingFinancialStatement: true,
        financialStatementError: {},
      };
    case financialStatementActions.GET_FINANCIAL_STATEMENT_SUCCESS: {
      const { data, studentProgrammeId } = actions;

      const { financialStatements } = state;

      let newState = state;

      const studentData = {
        studentProgrammeId,
        data,
      };

      const statementIndex = financialStatements.findIndex(
        (structure) => structure.studentProgrammeId === studentProgrammeId
      );

      if (statementIndex === -1) {
        newState = update(newState, {
          financialStatements: { $push: [studentData] },
        });
      } else {
        newState = update(newState, {
          financialStatements: {
            [statementIndex]: { $set: studentData },
          },
        });
      }

      return { ...newState, gettingFinancialStatement: false };
    }

    case financialStatementActions.GET_FINANCIAL_STATEMENT_ERROR:
      return {
        ...state,
        gettingFinancialStatement: false,
        financialStatementError: actions.data,
      };

    case financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_REQUEST:
      return {
        ...state,
        gettingStudentAccountStatement: true,
        accountStatementError: {},
      };
    case financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_SUCCESS: {
      const { data, studentProgrammeId } = actions;

      const { accountStatements } = state;

      let newState = state;

      const studentData = {
        studentProgrammeId,
        data,
      };

      const statementIndex = accountStatements.findIndex(
        (structure) => structure.studentProgrammeId === studentProgrammeId
      );

      if (statementIndex === -1) {
        newState = update(newState, {
          accountStatements: { $push: [studentData] },
        });
      } else {
        newState = update(newState, {
          accountStatements: {
            [statementIndex]: { $set: studentData },
          },
        });
      }

      return { ...newState, gettingStudentAccountStatement: false };
    }

    case financialStatementActions.GET_STUDENT_ACCOUNT_STATEMENT_ERROR:
      return {
        ...state,
        gettingStudentAccountStatement: false,
        accountStatementError: actions.data,
      };

    case financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_REQUEST:
      return {
        ...state,
        pushingStudentCredit: true,
        pushCreditSuccess: {},
        pushCreditError: {},
      };
    case financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        pushingStudentCredit: false,
        pushCreditSuccess: actions.data,
      };
    case financialStatementActions.PUSH_STUDENT_CREDIT_TO_ACCOUNT_ERROR:
      return {
        ...state,
        pushingStudentCredit: false,
        pushCreditError: actions.error,
      };

    case financialStatementActions.GET_PENDING_PUSHED_CREDIT_REQUEST:
      return {
        ...state,
        gettingPushCreditRequests: true,
        getPushedCreditError: {},
      };
    case financialStatementActions.GET_PENDING_PUSHED_CREDIT_SUCCESS:
      return {
        ...state,
        gettingPushCreditRequests: false,
        pushedCreditRequests: actions.data,
      };
    case financialStatementActions.GET_PENDING_PUSHED_CREDIT_ERROR:
      return {
        ...state,
        gettingPushCreditRequests: false,
        getPushedCreditError: actions.error,
      };

    case financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_REQUEST:
      return {
        ...state,
        approving: true,
        approveSuccess: {},
        approveError: {},
      };
    case financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_SUCCESS:
      return {
        ...state,
        approving: false,
        approveSuccess: actions.data,
      };
    case financialStatementActions.APPROVE_PENDING_PUSHED_CREDIT_ERROR:
      return {
        ...state,
        approving: false,
        approveError: actions.error,
      };

    case financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_REQUEST:
      return {
        ...state,
        downloading: true,
        downloadSuccess: {},
        downloadError: {},
      };
    case financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS:
      return {
        ...state,
        downloading: false,
        downloadSuccess: actions.data,
      };
    case financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_ERROR:
      return {
        ...state,
        downloading: false,
        downloadError: actions.error,
      };

    default:
      return state;
  }
};

export default financialStatement;
