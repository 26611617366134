import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { resultsBlockingPolicyActions } from '../../actions';
import initialState from '../../initialState';

const resultsBlockingPolicy = (
  state = initialState.resultsBlockingPolicy,
  actions
) => {
  switch (actions.type) {
    case resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_REQUEST:
      return {
        ...state,
        resultBlockingPolicyError: {},
        gettingResultBlockingPolicy: true,
      };

    case resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_SUCCESS: {
      const { category, data } = actions;
      const { resultBlockingPolicies } = state;
      const contextData = { category, data };

      const findContextIndex = findIndex(
        resultBlockingPolicies,
        (item) => item.category === category
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          resultBlockingPolicies: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          resultBlockingPolicies: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingResultBlockingPolicy: false,
      };
    }

    case resultsBlockingPolicyActions.GET_RESULT_BLOCKING_POLICY_ERROR:
      return {
        ...state,
        resultBlockingPolicyError: actions.error,
        gettingResultBlockingPolicy: false,
      };

    case resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_REQUEST:
      return {
        ...state,
        updatePolicyError: {},
        updatingPolicy: true,
      };

    case resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_SUCCESS:
      return {
        ...state,
        updatePolicySuccess: actions.data,
        updatingPolicy: false,
      };
    case resultsBlockingPolicyActions.UPDATE_RESULT_BLOCKING_POLICY_ERROR:
      return {
        ...state,
        updatePolicyError: actions.error,
        updatingPolicy: false,
      };

    case resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST:
      return {
        ...state,
        resultRegistrationPoliciesError: {},
        gettingResultRegistrationPolicy: true,
      };

    case resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS:
      return {
        ...state,
        resultsRegistrationPolicies: actions.data.data,
        gettingResultRegistrationPolicy: false,
      };
    case resultsBlockingPolicyActions.GET_VIEW_RESULTS_REGISTRATION_POLICY_ERROR:
      return {
        ...state,
        resultRegistrationPoliciesError: actions.error,
        gettingResultRegistrationPolicy: false,
      };

    case resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_REQUEST:
      return {
        ...state,
        updatePolicyError: {},
        updatingPolicy: true,
      };

    case resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_SUCCESS:
      return {
        ...state,
        updatePolicySuccess: actions.data,
        updatingPolicy: false,
      };
    case resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_REGISTRATION_POLICY_ERROR:
      return {
        ...state,
        updatePolicyError: actions.error,
        updatingPolicy: false,
      };

    case resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST:
      return {
        ...state,
        resultFeesBalancePoliciesError: {},
        gettingResultFeesBalancePolicy: true,
      };

    case resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS:
      return {
        ...state,
        resultsFeesBalancePolicies: actions.data.data,
        gettingResultFeesBalancePolicy: false,
      };
    case resultsBlockingPolicyActions.GET_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR:
      return {
        ...state,
        resultFeesBalancePoliciesError: actions.error,
        gettingResultFeesBalancePolicy: false,
      };

    case resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_REQUEST:
      return {
        ...state,
        updatePolicyError: {},
        updatingPolicy: true,
      };

    case resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_SUCCESS:
      return {
        ...state,
        updatePolicySuccess: actions.data,
        updatingPolicy: false,
      };
    case resultsBlockingPolicyActions.UPDATE_VIEW_RESULTS_FEES_BALANCE_POLICY_ERROR:
      return {
        ...state,
        updatePolicyError: actions.error,
        updatingPolicy: false,
      };

    case resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_REQUEST:
      return {
        ...state,
        pendingPoliciesError: {},
        gettingPendingPolicies: true,
      };

    case resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS:
      return {
        ...state,
        pendingPolicies: actions.data.data,
        gettingPendingPolicies: false,
      };
    case resultsBlockingPolicyActions.GET_PENDING_RESULTS_BLOCKING_POLICY_ERROR:
      return {
        ...state,
        pendingPoliciesError: actions.error,
        gettingPendingPolicies: false,
      };

    case resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_REQUEST:
      return {
        ...state,
        approvePendingPolicyError: {},
        approvingPendingPolicy: true,
      };

    case resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_SUCCESS:
      return {
        ...state,
        approvePendingPolicySuccess: actions.data,
        approvingPendingPolicy: false,
      };
    case resultsBlockingPolicyActions.APPROVE_PENDING_RESULTS_BLOCKING_POLICY_ERROR:
      return {
        ...state,
        approvePendingPolicyError: actions.error,
        approvingPendingPolicy: false,
      };

    case resultsBlockingPolicyActions.SET_POLICY_ACTIVE_TAB:
      return {
        ...state,
        activeTab: actions.activeTab,
      };

    default:
      return state;
  }
};

export default resultsBlockingPolicy;
