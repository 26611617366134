const specialEntryReportActions = {
  GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR',

  DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST:
    'DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST',
  DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS:
    'DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_SUCCESS',
  DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR:
    'DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_ERROR',

  GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_ERROR',

  GET_SPECIAL_ENTRY_EXAM_RESULTS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_RESULTS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_RESULTS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_RESULTS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_RESULTS_ERROR: 'GET_SPECIAL_ENTRY_EXAM_RESULTS_ERROR',

  GET_SPECIAL_ENTRY_EXAM_APPEALS_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_APPEALS_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_APPEALS_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_APPEALS_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_APPEALS_ERROR: 'GET_SPECIAL_ENTRY_EXAM_APPEALS_ERROR',

  GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_ERROR',

  GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_ERROR',

  GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_REQUEST:
    'GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_REQUEST',
  GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_SUCCESS:
    'GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_SUCCESS',
  GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_ERROR:
    'GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_ERROR',

  getSpecialEntryApplicationReport: (params) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST,
    params,
  }),

  downloadSpecialEntryApplicationReport: (params) => ({
    type: specialEntryReportActions.DOWNLOAD_SPECIAL_ENTRY_EXAM_APPLICANT_REPORTS_REQUEST,
    params,
  }),

  getSpecialEntryReportDetail: (params) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_REPORT_DETAILS_REQUEST,
    params,
  }),

  getResults: (params) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_RESULTS_REQUEST,
    params,
  }),

  getAppeals: (params) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_APPEALS_REQUEST,
    params,
  }),

  getRoomAllocations: (params) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_ROOM_ALLOCATION_REQUEST,
    params,
  }),

  getSpecialEntryPaymentReport: (params) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PAYMENT_REPORT_REQUEST,
    params,
  }),

  getProgrammeChoices: (applicationId) => ({
    type: specialEntryReportActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMME_CHOICES_REQUEST,
    applicationId,
  }),
};

export default specialEntryReportActions;
