import { resultLogActions } from '../../actions';
import initialState from '../../initialState';

const resultsUserLogs = (state = initialState.resultLogs, actions) => {
  switch (actions.type) {
    case resultLogActions.GET_USER_RESULT_LOGS_REQUEST:
      return {
        ...state,
        gettingLogs: true,
        getLogsError: {},
      };
    case resultLogActions.GET_USER_RESULT_LOGS_SUCCESS:
      return {
        ...state,
        gettingLogs: false,
        userLogs: actions.data,
      };
    case resultLogActions.GET_USER_RESULT_LOGS_ERROR:
      return {
        ...state,
        gettingLogs: false,
        getLogsError: actions.error,
      };

    default:
      return state;
  }
};

export default resultsUserLogs;
