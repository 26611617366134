import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { systemStudyLevelActions } from '../../actions';

function* getAcmisStudyLevels(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/acmis-study-levels`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_ERROR,
      error: error.data,
    });
  }
}

function* getEmisStudyLevels(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/study-levels`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_SUCCESS,
      data: response?.response?.data,
    });
  } catch (error) {
    yield put({
      type: systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_ERROR,
      error: error.data,
    });
  }
}

function* submitStudyLevel(actions) {
  try {
    const response = yield axios({
      url: `/developer/courses/study-levels`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_REQUEST,
    });
  } catch (error) {
    yield put({
      type: systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcmisStudyLevels() {
  yield takeLatest(
    systemStudyLevelActions.GET_ACMIS_STUDY_LEVEL_REQUEST,
    getAcmisStudyLevels
  );
}
function* watchGetEmisStudyLevels() {
  yield takeLatest(
    systemStudyLevelActions.GET_EMIS_STUDY_LEVEL_REQUEST,
    getEmisStudyLevels
  );
}
function* watchSubmitStudyLevel() {
  yield takeLatest(
    systemStudyLevelActions.SUBMIT_SYSTEM_STUDY_LEVEL_REQUEST,
    submitStudyLevel
  );
}

export default [
  fork(watchGetAcmisStudyLevels),
  fork(watchSubmitStudyLevel),
  fork(watchGetEmisStudyLevels),
];
