import update from 'immutability-helper';
import { senateActions } from '../../actions';
import initialState from '../../initialState';

const senate = (state = initialState.senate, actions) => {
  switch (actions.type) {
    case senateActions.GET_SENATE_GRADUATION_LIST_REQUEST:
      return {
        ...state,
        gettingGradList: true,
        graduationContext: actions.params,
        getGradListError: {},
      };
    case senateActions.GET_SENATE_GRADUATION_LIST_SUCCESS: {
      const { context, data } = actions;
      const { senateGradList } = state;

      const contextIndex = senateGradList.findIndex(
        (list) =>
          list.academic_unit_id === context.academic_unit_id &&
          list.academic_unit_id === context.academic_unit_id &&
          list.academic_unit === context.academic_unit
      );

      const contextData = { ...context, data };

      let newState = state;

      if (contextIndex === -1) {
        newState = update(newState, {
          senateGradList: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          senateGradList: { [contextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingGradList: false,
      };
    }
    case senateActions.GET_SENATE_GRADUATION_LIST_ERROR:
      return {
        ...state,
        gettingGradList: false,
        getGradListError: actions.error,
      };

    case senateActions.SET_SENATE_GRADUATION_CONTEXT:
      return {
        ...state,
        graduationContext: actions.context,
      };

    default:
      return state;
  }
};

export default senate;
