import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { extensionPolicyActions, tabActions } from '../../actions';

function* getExtensionPolicies(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/graduate-fees-policies',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* createExtensionPolicy(actions) {
  try {
    const response = yield axios({
      url: '/institution-policy-mgt/graduate-fees-policies',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: extensionPolicyActions.CREATE_EXTENSION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: extensionPolicyActions.CREATE_EXTENSION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* addExtensionPolicyElement(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/graduate-fees-policies/add-more-elements/${actions.extensionPolicyId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* updateExtensionPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/graduate-fees-policies/${actions.extensionPolicyId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: extensionPolicyActions.UPDATE_EXTENSION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    });
    yield put({
      type: tabActions.CURRENT_REGISTRATION_POLICY_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: extensionPolicyActions.UPDATE_EXTENSION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteExtensionPolicy(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/graduate-fees-policies/${actions.extensionPolicyId}`,
      method: 'DELETE',
    });
    yield put({
      type: extensionPolicyActions.DELETE_EXTENSION_POLICY_SUCCESS,
      data: response,
    });
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: extensionPolicyActions.DELETE_EXTENSION_POLICY_ERROR,
      error: error.data,
    });
  }
}

function* deleteExtensionPolicyElement(actions) {
  try {
    const response = yield axios({
      url: `/institution-policy-mgt/graduate-fees-policies/delete-elements/${actions.extensionPolicyId}`,
      method: 'DELETE',
      data: actions.elementsToDelete,
    });
    yield put({
      type: extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetExtensionPolicies() {
  yield takeLatest(
    extensionPolicyActions.GET_EXTENSION_POLICIES_REQUEST,
    getExtensionPolicies
  );
}

function* watchCreateExtensionPolicy() {
  yield takeLatest(
    extensionPolicyActions.CREATE_EXTENSION_POLICY_REQUEST,
    createExtensionPolicy
  );
}

function* watchUpdateExtensionPolicy() {
  yield takeLatest(
    extensionPolicyActions.UPDATE_EXTENSION_POLICY_REQUEST,
    updateExtensionPolicy
  );
}

function* watchAddExtensionPolicyElements() {
  yield takeLatest(
    extensionPolicyActions.ADD_EXTENSION_POLICY_FEES_ELEMENTS_REQUEST,
    addExtensionPolicyElement
  );
}

function* watchDeleteExtensionPolicy() {
  yield takeLatest(
    extensionPolicyActions.DELETE_EXTENSION_POLICY_REQUEST,
    deleteExtensionPolicy
  );
}

function* watchDeleteExtensionPolicyElement() {
  yield takeLatest(
    extensionPolicyActions.DELETE_EXTENSION_POLICY_ELEMENT_REQUEST,
    deleteExtensionPolicyElement
  );
}

export default [
  fork(watchGetExtensionPolicies),
  fork(watchCreateExtensionPolicy),
  fork(watchUpdateExtensionPolicy),
  fork(watchAddExtensionPolicyElements),
  fork(watchDeleteExtensionPolicy),
  fork(watchDeleteExtensionPolicyElement),
];
