import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { NTCStudentActions } from '../../actions';

function* getNTCStudents(actions) {
  try {
    const response = yield axios({
      url: '/ntc-mgt/ntc-students',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: NTCStudentActions.GET_NTC_STUDENTS_SUCCESS,
      students: response.students,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: NTCStudentActions.GET_NTC_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* createNTCStudent(actions) {
  try {
    const response = yield axios({
      url: 'ntc-mgt/ntc-students',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: NTCStudentActions.CREATE_NTC_STUDENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: NTCStudentActions.GET_NTC_STUDENTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: NTCStudentActions.CREATE_NTC_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* downloadNTCStudentsTemplate() {
  try {
    yield axios({
      url: `/ntc-mgt/ntc-students/download-template`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-NTC-STUDENTS-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: NTCStudentActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: NTCStudentActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadNTCStudentsTemplate(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/ntc-students/upload-template`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: NTCStudentActions.UPLOAD_NTC_STUDENTS_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_UPLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: NTCStudentActions.UPLOAD_NTC_STUDENTS_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* deleteNTCStudent(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/results/${actions.studentId}`,
      method: 'DELETE',
    });
    yield put({
      type: NTCStudentActions.DELETE_NTC_STUDENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: NTCStudentActions.GET_NTC_STUDENTS_REQUEST,
      params: actions.params,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: NTCStudentActions.DELETE_NTC_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* updateNTCStudent(actions) {
  try {
    const response = yield axios({
      url: `/ntc-mgt/results/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: NTCStudentActions.UPDATE_NTC_STUDENT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: NTCStudentActions.UPDATE_NTC_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetNTCStudents() {
  yield takeLatest(NTCStudentActions.GET_NTC_STUDENTS_REQUEST, getNTCStudents);
}

function* watchCreateNTCStudent() {
  yield takeLatest(
    NTCStudentActions.CREATE_NTC_STUDENT_REQUEST,
    createNTCStudent
  );
}

function* watchDownloadNTCStudentsTemplate() {
  yield takeLatest(
    NTCStudentActions.DOWNLOAD_NTC_STUDENTS_TEMPLATE_REQUEST,
    downloadNTCStudentsTemplate
  );
}

function* watchUploadNTCStudentsTemplate() {
  yield takeLatest(
    NTCStudentActions.UPLOAD_NTC_STUDENTS_TEMPLATE_REQUEST,
    uploadNTCStudentsTemplate
  );
}

function* watchDeleteNTCStudent() {
  yield takeLatest(
    NTCStudentActions.DELETE_NTC_STUDENTS_REQUEST,
    deleteNTCStudent
  );
}

function* watchUpdateNTCStudent() {
  yield takeLatest(
    NTCStudentActions.UPDATE_NTC_STUDENT_REQUEST,
    updateNTCStudent
  );
}

export default [
  fork(watchGetNTCStudents),
  fork(watchCreateNTCStudent),
  fork(watchDownloadNTCStudentsTemplate),
  fork(watchUploadNTCStudentsTemplate),
  fork(watchDeleteNTCStudent),
  fork(watchUpdateNTCStudent),
];
