import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { specialEntryAdmissionActions } from '../../actions';

function* getSpecialEntryProgrammes() {
  try {
    const response = yield axios({
      url: '/mature-age/running-admissions/programmes',
      method: 'GET',
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_ERROR,
      error: error.data,
    });
  }
}

function* getSpecialEntryRooms() {
  try {
    const response = yield axios({
      url: '/mature-age/running-admissions/exam-rooms',
      method: 'GET',
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOM_ERROR,
      error: error.data,
    });
  }
}

function* getSpecialEntryAdmissions() {
  try {
    const response = yield axios({
      url: '/mature-age/running-admissions',
      method: 'GET',
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* createSpecialEntryAdmission(actions) {
  try {
    const response = yield axios({
      url: '/mature-age/running-admissions',
      method: 'POST',
      data: actions.payload,
    });
    yield put({
      type: specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* updateSpecialEntryAdmission(actions) {
  try {
    const response = yield axios({
      url: `/mature-age/running-admissions/${actions.admissionId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SET_CURRENT_SPECIAL_ENTRY_EXAM_TAB',
      payload: 'all-admissions',
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR,
      error: error.data,
    });
  }
}
function* updateSpecialEntryAdmissionStatus(actions) {
  try {
    const response = yield axios({
      url: `/mature-age/running-admissions/${actions.status}/${actions.admissionId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_SUCCESS,
      data: response,
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* deleteSpecialEntryAdmission(actions) {
  try {
    const response = yield axios({
      url: `/mature-age/running-admissions/${actions.admissionId}`,
      method: 'DELETE',
    });
    yield put({
      type: specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* getSpecialEntryAdmissionProgrammes(actions) {
  try {
    const { runningAdmissionId } = actions;

    const response = yield axios({
      url: `/mature-age/running-admissions/advertised-programmes/${runningAdmissionId}`,
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* assignSpecialEntryAdmissionProgramme(actions) {
  try {
    const { runningAdmissionId } = actions;
    const response = yield axios({
      url: `/mature-age/running-admissions/add-programmes/${runningAdmissionId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_SUCCESS,
      data: response,
    });

    yield put({
      type: specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSpecialEntryAdmissions() {
  yield takeLatest(
    specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSIONS_REQUEST,
    getSpecialEntryAdmissions
  );
}

function* watchGetSpecialEntryProgrammes() {
  yield takeLatest(
    specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_PROGRAMMES_TO_ADVERTISE_REQUEST,
    getSpecialEntryProgrammes
  );
}

function* watchGetSpecialEntryExamRooms() {
  yield takeLatest(
    specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_EXAM_ROOMS_REQUEST,
    getSpecialEntryRooms
  );
}

function* watchCreateSpecialEntryAdmission() {
  yield takeLatest(
    specialEntryAdmissionActions.CREATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST,
    createSpecialEntryAdmission
  );
}

function* watchUpdateSpecialEntryAdmission() {
  yield takeLatest(
    specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST,
    updateSpecialEntryAdmission
  );
}

function* watchUpdateSpecialEntryAdmissionStatus() {
  yield takeLatest(
    specialEntryAdmissionActions.UPDATE_SPECIAL_ENTRY_EXAM_ADMISSION_STATUS_REQUEST,
    updateSpecialEntryAdmissionStatus
  );
}

function* watchDeleteSpecialEntryAdmission() {
  yield takeLatest(
    specialEntryAdmissionActions.DELETE_SPECIAL_ENTRY_EXAM_ADMISSION_REQUEST,
    deleteSpecialEntryAdmission
  );
}

function* watchGetSpecialEntryAdmissionProgrammes() {
  yield takeLatest(
    specialEntryAdmissionActions.GET_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMMES_REQUEST,
    getSpecialEntryAdmissionProgrammes
  );
}

function* watchAssignSpecialEntryAdmissionProgramme() {
  yield takeLatest(
    specialEntryAdmissionActions.ASSIGN_SPECIAL_ENTRY_EXAM_ADMISSION_PROGRAMME_REQUEST,
    assignSpecialEntryAdmissionProgramme
  );
}

export default [
  fork(watchGetSpecialEntryAdmissions),
  fork(watchGetSpecialEntryProgrammes),
  fork(watchCreateSpecialEntryAdmission),
  fork(watchGetSpecialEntryExamRooms),
  fork(watchUpdateSpecialEntryAdmission),
  fork(watchDeleteSpecialEntryAdmission),
  fork(watchUpdateSpecialEntryAdmissionStatus),
  fork(watchAssignSpecialEntryAdmissionProgramme),
  fork(watchGetSpecialEntryAdmissionProgrammes),
];
